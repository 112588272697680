import React, { useState } from 'react';
import { Table, Typography } from 'antd';

import CustomButton from '../../../../components/CustomButton';
import PdfViewer from '../../../../components/PdfViewer';
import {get as getDocumentUrl} from "../../../../api/module/document_repository";

const { Paragraph } = Typography;

const HistoryList = ({ dataSource }) => {
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showClick = async (documento_nombre) => {
    setIsLoadingDoc(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    setPdfUrl(response.data.url);
    setShowPdf(true);
    setIsLoadingDoc(false);
  };

  const columns = [
    {
      title: 'Fecha consulta',
      dataIndex: 'fecha_consulta_lista',
      width: '12em',
      align: 'center',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion_lista',
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      width: 125,
      render: (_, record) => (
        <CustomButton
          block
          type="default"
          className={'ant-btn-info'}
          htmlType="button"
          text="Ver"
          loading={isLoadingDoc}
          onClick={() => {
            showClick(record.documento_lista_nombre);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Historial de consultas realizadas a listas restrictivas
          </Paragraph>
        )}
      />
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
};

export default HistoryList;
