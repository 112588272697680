import httpClient from '../httpClient';

const get = async (id) => {
  return await httpClient.get(`/mass_modification_budget/${id}/`);
};

const patch = async (id, data) => {
  return await httpClient.put(`/mass_modification_budget/${id}/`, data);
};

const getContractors = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/mass_modification_budget/${query}/contractors_export/${
        search ? `?filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/mass_modification_budget/${query}/contractors/?page[number]=${page}&page[size]=${pageSize}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

export { get, patch, getContractors };
