import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/announcement_invite/`, data);
};

const getAll = async (announcement_id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_invite/?filter[convocatoria]=${announcement_id}`);
};

const getAllResumeNotNull = async (announcement_id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_invite/?filter[convocatoria]=${announcement_id}&filter[tercero__isnull]=false`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_invite/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/announcement_invite/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/announcement_invite/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_invite${query}`);
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  filter,
  getAllResumeNotNull,
};
