import React, { useState, useEffect } from 'react';
import { Table, Typography, Skeleton, Layout } from 'antd';

import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import AnnouncementDetailsForm from '../../containers/AnnouncementDetailsForm/index';
import { get } from '../../api/module/announcement';
import { filter } from '../../api/module/announcement_reject';
import { getCounters } from '../../api/module/announcement_rol';

import './style.scss';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias en curso',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`,
  },
  {
    text: 'Detalle convocatoria',
  },
];

const breadcrumbItemsExpired = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias caducadas',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT_FINISH}`,
  },
  {
    text: 'Detalle convocatoria',
  },
];

const AnnouncementDetails = ({ history, match }) => {
  const [announcement_id] = useState(match.params.announcement_id);
  const [initialValues, setInitialValues] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const getAnnouncement = async () => {
    setLoadingDetail(true);
    const response = await get(announcement_id);
    setLoadingDetail(false);
    response.data.tipo = response.data.tipo === true ? 1 : 0;
    setInitialValues(response.data);
  };

  const getAnnouncementRejected = async () => {
    const response = await filter(`?filter[convocatoria]=${announcement_id}`);
    const data = response.data.results;
    setRejected(data.length);
  };

  const getRolAnnouncement = async () => {
    setLoading(true);
    const response = await getCounters(announcement_id);
    setDataSource(response.data.results);
    setLoading(false);
  };

  useEffect(async () => {
    try {
      if (announcement_id) {
        Promise.all([
          getAnnouncement(),
          getAnnouncementRejected(),
          getRolAnnouncement(),
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const columns = [
    {
      title: 'Cargo / Rol',
      dataIndex: 'cargo',
      key: 'cargo',
    },
    {
      title: 'Total vacantes',
      dataIndex: 'total_vacantes',
      key: 'total_vacantes',
    },
    {
      title: 'Total aplicantes',
      dataIndex: 'aplicantes',
      key: 'aplicantes',
    },
    {
      title: 'Preseleccionados',
      dataIndex: 'preseleccionados',
      key: 'preseleccionados',
    },
    {
      title: 'Seleccionados',
      dataIndex: 'seleccionados',
      key: 'seleccionados',
    },
  ];

  const back = () => {
    history.goBack();
  };

  return (
    <Content>
      {initialValues != null && initialValues.estado_convocatoria === 9 && (
        <BreadcrumbContainer items={breadcrumbItemsExpired} />
      )}
      {initialValues != null && initialValues.estado_convocatoria === 8 && (
        <BreadcrumbContainer items={breadcrumbItems} />
      )}
      <Title className={'text-uppercase'} level={2}>
        Detalle de convocatoria
      </Title>
      <Skeleton loading={loadingDetail}>
        {initialValues != null && (
          <AnnouncementDetailsForm
            initialValues={initialValues}
            announcementId={announcement_id}
            rejected={rejected}
          />
        )}
      </Skeleton>
      <SpinLoad loading={loading}>
        <Table
          rowKey="id"
          scroll={{ x: 400 }}
          dataSource={dataSource}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de cargos de convocatorias para selección
            </Paragraph>
          )}
        />
      </SpinLoad>
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </Content>
  );
};

export default AnnouncementDetails;
