import React, { useState } from 'react';
import {
  Popconfirm,
  notification,
  Modal,
  Typography,
  Layout,
} from 'antd';

import CustomButton from '../../../../components/CustomButton';
import INTERNAL_LINKS from '../../../../enums/InternalLinks';
import MESSAGES from '../../../../enums/Messages';

import { patch } from '../../../../api/module/announcement_selection';

import { useHistory } from 'react-router-dom';
import SpinLoad from '../../../../components/SpinLoad';
import AjaxTable from "../../../../components/AjaxTable";
import {ajaxTable as filterAjaxTable} from "../../../../api/module/announcement_selection";

const { Paragraph } = Typography;
const { Content } = Layout;

const Preselected = ({
  dataSource,
  announcement_id,
  announcement_rol_id,
  loading,
  loadData,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');

  const saveStatus = async (record) => {
    try {
      setIsLoading(true);
      let data = {};
      let pendingStaus = false;
      data.id = record.id;

      if (record.tercero_data.tipo_persona === 1) {
        if (
          record.estado_educacion_seleccion == null ||
          record.estado_laboral_seleccion == null ||
          record.estado_lista_restrictiva == null ||
          record.resultado_final == null
        ) {
          pendingStaus = true;
        }
        if (
          record.estado_educacion_seleccion === 25 &&
          record.estado_laboral_seleccion === 25 &&
          record.estado_lista_restrictiva === 25 &&
          record.resultado_final === 25
        ) {
          data.estado_seleccion = 23;
        } else if (
          record.estado_educacion_seleccion === 26 ||
          record.estado_laboral_seleccion === 26 ||
          record.estado_lista_restrictiva === 26 ||
          record.resultado_final === 26
        ) {
          data.estado_seleccion = 22;
        } else if (
          record.estado_educacion_seleccion === 24 ||
          record.estado_laboral_seleccion === 24 ||
          record.estado_lista_restrictiva === 24 ||
          record.resultado_final === 24
        ) {
          data.estado_seleccion = 21;
        }
      } else if (record.tercero_data.tipo_persona === 2) {
        if (
          record.estado_documentos_seleccion == null ||
          record.estado_lista_restrictiva == null ||
          record.resultado_final == null
        ) {
          pendingStaus = true;
        }
        if (
          record.estado_documentos_seleccion === 25 &&
          record.estado_lista_restrictiva === 25 &&
          record.resultado_final === 25
        ) {
          data.estado_seleccion = 23;
        } else if (
          record.estado_documentos_seleccion === 26 ||
          record.estado_lista_restrictiva === 26 ||
          record.resultado_final === 26
        ) {
          data.estado_seleccion = 22;
        } else if (
          record.estado_documentos_seleccion === 24 ||
          record.estado_lista_restrictiva === 24 ||
          record.resultado_final === 24
        ) {
          data.estado_seleccion = 21;
        }
      }

      if (pendingStaus === true) {
        setIsLoading(false);
        Modal.confirm({
          title: 'Información SIGA',
          content: 'Falta(n) módulo(s) por revisar',
          okText: 'Cerrar',
          cancelButtonProps: { style: { display: 'none' } },
        });
      } else {
        const response = await patch(data.id, data);
        setSortBy('id')
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            loadData();
            setIsLoading(false);
          }, MESSAGES.TIME);
        }
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero_data', 'notificacion_email'],
      key: ['tercero_data', 'notificacion_email'],
      align: 'center',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero_data', 'nombre_completo'],
      key: ['tercero_data', 'nombre_completo'],
      align: 'center',
    },
    {
      title: 'Tipo Identificación',
      dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      key: ['tercero_data', 'tipo_identificacion_nombre'],
      align: 'center',
    },
    {
      title: 'Doc Identificación',
      dataIndex: ['tercero_data', 'numero_identificacion'],
      key: ['tercero_data', 'numero_identificacion'],
    },
    {
      title: 'Educación',
      dataIndex: 'estudio',
      key: 'estudio',
      align: 'center',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 1) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={'Validar'}
              onClick={() => {
                let path = `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_EDUCATION}/${announcement_id}/${record.id}/${record.convocatoria_tercero}/${announcement_rol_id}/${record.tercero}`;

                if (record.cargo_aplicado)
                  Modal.error({
                    okText: 'Entendido',
                    title: 'Atención',
                    onOk: () => {
                      history.push(path);
                    },
                    content: (
                      <Paragraph>{`El usuario también se encuentra aplicando al cargo ${record.cargo_aplicado} de esta convocatoria.
                      ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}</Paragraph>
                    ),
                  })
                else
                  history.push(path);
              }}
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado educación',
      key: 'estado_educacion_seleccion',
      dataIndex: 'estado_educacion_nombre',
      align: 'center',
    },
    {
      title: 'Experiencia',
      dataIndex: 'experiencia',
      key: 'experiencia',
      align: 'center',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 1) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={'Validar'}
              onClick={() => {
                let path = `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_LABORAL}/${announcement_id}/${record.id}/${record.convocatoria_tercero}/${announcement_rol_id}/${record.tercero}`;

                if (record.cargo_aplicado)
                  Modal.error({
                    okText: 'Entendido',
                    title: 'Atención',
                    onOk: () => {
                      history.push(path);
                    },
                    content: (
                      <Paragraph>{`El usuario también se encuentra aplicando al cargo ${record.cargo_aplicado} de esta convocatoria.
                      ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}</Paragraph>
                    ),
                  })
                else
                  history.push(path);
              }}
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado experiencia',
      key: 'estado_laboral_seleccion',
      dataIndex: 'estado_laboral_nombre',
      align: 'center',
    },
    {
      title: 'Otras validaciones',
      dataIndex: 'otras_validaciones',
      align: 'center',
      render: (_, record) => (
        <CustomButton
          block
          htmlType={'button'}
          text={'Validar'}
          onClick={() => {
            let path = `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_TESTS}/${announcement_id}/${record.id}/${record.convocatoria_tercero}/${announcement_rol_id}/${record.tercero}`;

            if (record.cargo_aplicado)
              Modal.error({
                okText: 'Entendido',
                title: 'Atención',
                onOk: () => {
                  history.push(path);
                },
                content: (
                  <Paragraph>{`El usuario también se encuentra aplicando al cargo ${record.cargo_aplicado} de esta convocatoria.
                      ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}</Paragraph>
                ),
              })
            else
              history.push(path);
          }}
        />
      ),
    },
    {
      title: 'Estado validaciones',
      key: 'resultado_final',
      dataIndex: 'resultado_final_nombre',
      align: 'center',
    },
    {
      title: 'Listas restrictivas',
      dataIndex: 'otras_validaciones',
      align: 'center',
      render: (_, record) => (
        <CustomButton
          block
          htmlType={'button'}
          text={'Validar'}
          onClick={() => {
            let path = `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_RESTRICTIVE}/${announcement_id}/${record.id}/${record.convocatoria_tercero}/${announcement_rol_id}/${record.tercero}`;

            if (record.cargo_aplicado)
              Modal.error({
                okText: 'Entendido',
                title: 'Atención',
                onOk: () => {
                  history.push(path);
                },
                content: (
                  <Paragraph>{`El usuario también se encuentra aplicando al cargo ${record.cargo_aplicado} de esta convocatoria.
                      ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}</Paragraph>
                ),
              })
            else
              history.push(path);
          }}
        />
      ),
    },
    {
      title: 'Estado listas',
      dataIndex: 'estado_lista_restrictiva_nombre',
      key: 'estado_lista_restrictiva',
      align: 'center',
    },
    {
      title: 'Documentos',
      dataIndex: 'documentos',
      key: 'documentos',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 2) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={'Validar'}
              onClick={() => {
                let path = `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_DOCUMENTOS}/${announcement_id}/${record.id}/${record.convocatoria_tercero}/${announcement_rol_id}/${record.tercero}`;

                if (record.cargo_aplicado)
                  Modal.error({
                    okText: 'Entendido',
                    title: 'Atención',
                    onOk: () => {
                      history.push(path);
                    },
                    content: (
                      <Paragraph>{`El usuario también se encuentra aplicando al cargo ${record.cargo_aplicado} de esta convocatoria.
                      ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}</Paragraph>
                    ),
                  })
                else
                  history.push(path);
              }}
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado documentos',
      key: 'estado_documentos_seleccion',
      dataIndex: 'estado_documentos_nombre',
      align: 'center',
    },
    {
      title: 'Estado Final',
      dataIndex: 'estado',
      align: 'center',
      render: (_, record) => {
        let status = '';
        if (record.tercero_data.tipo_persona === 1) {
          if (
            record.estado_educacion_seleccion === 25 &&
            record.estado_laboral_seleccion === 25 &&
            record.estado_lista_restrictiva === 25 &&
            record.resultado_final === 25
          ) {
            status = 'Seleccionado';
          } else if (
            record.estado_educacion_seleccion === 26 ||
            record.estado_laboral_seleccion === 26 ||
            record.estado_lista_restrictiva === 26 ||
            record.resultado_final === 26
          ) {
            status = 'Rechazado';
          } else if (
            record.estado_educacion_seleccion === 24 ||
            record.estado_laboral_seleccion === 24 ||
            record.estado_lista_restrictiva === 24 ||
            record.resultado_final === 24
          ) {
            status = 'Pendiente';
          }
        } else if (record.tercero_data.tipo_persona === 2) {
          if (
            record.estado_documentos_seleccion === 25 &&
            record.estado_lista_restrictiva === 25 &&
            record.resultado_final === 25
          ) {
            status = 'Seleccionado';
          } else if (
            record.estado_documentos_seleccion === 26 ||
            record.estado_lista_restrictiva === 26 ||
            record.resultado_final === 26
          ) {
            status = 'Rechazado';
          } else if (
            record.estado_documentos_seleccion === 24 ||
            record.estado_lista_restrictiva === 24 ||
            record.resultado_final === 24
          ) {
            status = 'Pendiente';
          }
        }
        return (
          <Content>
            {status !== '' && <Paragraph>{status}</Paragraph>}
            <CustomButton
              block
              htmlType={'button'}
              loading={isLoading}
              text={
                <Popconfirm
                  title="Recuerde que en caso de que el estado final sea Rechazado y lo guarde, esta persona no podrá continuar con el proceso de Selección y no se podrá habilitar de nuevo ¿Está seguro(a) de guardar este registro?"
                  okText="Si"
                  cancelText="No"
                  onConfirm={() => saveStatus(record)}
                >
                  Guardar
                </Popconfirm>
              }
            />
          </Content>
        );
      },
    },
  ];

  return (
    <SpinLoad loading={loading || isLoading}>
      {dataSource != null && (
        <AjaxTable
          columns={columns}
          pageSize={10}
          sortBy={sortBy}
          tableTitle={'Listado de contratistas preseleccionados'}
          endpoint={filterAjaxTable}
          searchInput
          exportCSV
          reloadButton
          query={`/?filter[convocatoria_rol]=${announcement_rol_id}&filter[estado_seleccion]=20`}
        />
      )}
    </SpinLoad>
  );
};

export default Preselected;
