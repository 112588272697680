import httpClient from '../httpClient';

const getGroupsAssign = async (id) => {
  return await httpClient.get(`/assignments_postcontractual/${id}/`);
};

const getUsersAssign = async (group_id) => {
  return await httpClient.get(
    `/assignments_postcontractual/get_users/?filter[rol]=${group_id}`,
  );
};

const sendObservations = async (id, data) => {
  return await httpClient.patch(
    `/assignments_postcontractual/${id}/add_observations/`,
    data,
  );
};

const sendSign = async (id, data) => {
  return await httpClient.patch(
    `/assignments_postcontractual/${id}/send_director/`,
    data,
  );
};

const massSendSign = async (id, data) => {
  return await httpClient.patch(
    `/assignments_postcontractual/${id}/mass_send_director/`,
    data,
  );
};

export {
  getGroupsAssign,
  getUsersAssign,
  sendObservations,
  sendSign,
  massSendSign,
};
