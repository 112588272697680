import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  Typography,
  notification,
  Skeleton,
  Space,
  Form,
  Row,
  Col,
  Table,
} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { Link } from 'react-router-dom';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import {
  get,
  send,
  getDocUrl,
} from '../../../api/module/insurance_policy_contractor';
import BetterUpload from '../../../components/BetterUpload';
import { CustomButton } from '../../../components';
import moment from 'moment';
import 'moment/locale/es';

const { Title, Paragraph } = Typography;

const breadcrumbItems = [
  {
    text: 'Pólizas',
  },
];

const columns = [
  {
    title: 'Tipo de documento',
    dataIndex: 'created_at',
    render: (t, record) =>
      `${record.tipo === 0 ? 'Póliza' : 'Recibo de pago'} ${moment(t).format(
        'DD MMMM YYYY h:mm a',
      )}`,
  },
  {
    title: 'Consultar documento',
    dataIndex: 'id',
    render: (t, record) => (
      <CustomButton
        type="default"
        htmlType="button"
        text="Ver"
        onClick={async () => {
          try {
            const res = await getDocUrl(t, record.tipo);
            window.open(res.data.url, '_blank');
          } catch (error) {
            console.log(error);
          }
        }}
      />
    ),
  },
  {
    title: 'Estado',
    dataIndex: 'estado',
  },
];

export default function InsuranceContractorForm({ match, history }) {
  const [insuranceData, setInsuranceData] = useState(undefined);

  const [loading, setLoading] = useState(false);

  async function fetchData() {
    try {
      const res = await get(match.params.insurance_id);
      setInsuranceData(res.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.insurance_id]);

  async function onFinish(values) {
    setLoading(true);
    try {
      const formData = new FormData();
      for (const prop in values) formData.append(prop, values[prop]);
      await send(match.params.insurance_id, formData);
      history.push(INTERNAL_LINKS.CONTRACTOR_INSURANCE);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO PÓLIZAS
      </Title>
      {insuranceData ? (
        <>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Proyecto">
              {insuranceData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo / rol">
              {insuranceData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="No. Contrato">
              {insuranceData.contrato_num}
            </Descriptions.Item>
            <Descriptions.Item label="No. de la modificación">
              {insuranceData.modificacion_num}
            </Descriptions.Item>
          </Descriptions>
          <br />
          {insuranceData.estado === 0 ? (
            <>
              {!insuranceData.to_fix ? (
                <Space direction={'vertical'} style={{ width: '100%' }}>
                  <Title level={3}>
                    Póliza firmada y recibo de pago por cargar
                  </Title>
                  <Paragraph>
                    Por favor, cargue la póliza firmada y el recibo de pago en
                    formato PDF. El tamaño máximo por archivo es de 2MB.{' '}
                    <b>Su cargue es obligatorio</b>
                  </Paragraph>
                  <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    colon={false}
                    onFinish={onFinish}
                  >
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          name="documento_poliza"
                          label="Póliza firmada"
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <BetterUpload pdf />
                        </Form.Item>
                      </Col>
                      <Col offset={4}>
                        <Form.Item noStyle dependencies={['documento_poliza']}>
                          {({ getFieldValue }) => {
                            const dp = getFieldValue('documento_poliza');
                            if (!dp) return null;
                            return (
                              <CustomButton
                                type="default"
                                htmlType="button"
                                onClick={() => {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    const url = reader.result;
                                    const newWindow = window.open(
                                      url,
                                      '_blank',
                                    );
                                    if (newWindow) {
                                      newWindow.document.write(
                                        `<iframe src="${url}" width="100%" height="100%"></iframe>`,
                                      );
                                    }
                                  };
                                  reader.readAsDataURL(dp);
                                }}
                                text="Ver"
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <Form.Item
                          name="documento_recibo"
                          label="Recibo de pago"
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <BetterUpload pdf />
                        </Form.Item>
                      </Col>
                      <Col offset={4}>
                        <Form.Item noStyle dependencies={['documento_recibo']}>
                          {({ getFieldValue }) => {
                            const dp = getFieldValue('documento_recibo');
                            if (!dp) return null;
                            return (
                              <CustomButton
                                type="default"
                                htmlType="button"
                                onClick={() => {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    const url = reader.result;
                                    const newWindow = window.open(
                                      url,
                                      '_blank',
                                    );
                                    if (newWindow) {
                                      newWindow.document.write(
                                        `<iframe src="${url}" width="100%" height="100%"></iframe>`,
                                      );
                                    }
                                  };
                                  reader.readAsDataURL(dp);
                                }}
                                text="Ver"
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <CustomButton
                        type="primary"
                        htmlType="submit"
                        text="Notificar cargue"
                        loading={loading}
                      />
                    </Row>
                  </Form>
                </Space>
              ) : (
                <Space direction={'vertical'} style={{ width: '100%' }}>
                  <Title level={3}>Documentación por ajustar</Title>
                  <Paragraph>
                    Por favor, <b>lea atentamente</b> el mensaje del correo y
                    proceda a actualizar/ajustar la póliza y/o recibo de pago,
                    según lo informado por Corpoeducación.
                  </Paragraph>
                  {insuranceData.message && (
                    <Descriptions layout="vertical" column={1} bordered>
                      <Descriptions.Item label={<b>Notificación</b>}>
                        <Title level={5}>
                          Enviado el:{' '}
                          {moment(insuranceData.message.created_at).format(
                            'DD MMMM YYYY h:mm a',
                          )}
                        </Title>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: insuranceData.message.mensaje,
                          }}
                        ></div>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  <br />
                  <Form
                    // labelCol={{ lg: 24, xl: 8 }}
                    // wrapperCol={{ lg: 24, xl: 16 }}
                    layout="vertical"
                    colon={false}
                    onFinish={onFinish}
                  >
                    {insuranceData.to_fix.includes('p') && (
                      <Row align="middle">
                        <Col span={17}>
                          <Form.Item
                            name="documento_poliza"
                            label="Adjunte la póliza firmada ajustada"
                            labelAlign="left"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <BetterUpload pdf />
                          </Form.Item>
                        </Col>
                        <Col offset={1} span={3}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const dp = getFieldValue('documento_poliza');
                              if (!dp) return null;
                              return (
                                <CustomButton
                                  type="default"
                                  htmlType="button"
                                  onClick={() => {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      const url = reader.result;
                                      const newWindow = window.open(
                                        url,
                                        '_blank',
                                      );
                                      if (newWindow) {
                                        newWindow.document.write(
                                          `<iframe src="${url}" width="100%" height="100%"></iframe>`,
                                        );
                                      }
                                    };
                                    reader.readAsDataURL(dp);
                                  }}
                                  text="Ver"
                                />
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue, setFieldsValue }) => {
                              const dp = getFieldValue('documento_poliza');
                              if (!dp) return null;
                              return (
                                <CustomButton
                                  type="default"
                                  danger
                                  onClick={() => {
                                    setFieldsValue({
                                      documento_poliza: undefined,
                                    });
                                  }}
                                  text="Eliminar"
                                />
                              );
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {insuranceData.to_fix.includes('r') && (
                      <Row align="middle">
                        <Col span={17}>
                          <Form.Item
                            name="documento_recibo"
                            label="Adjunte el recibo de pago ajustado"
                            labelAlign="left"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <BetterUpload pdf />
                          </Form.Item>
                        </Col>
                        <Col offset={1} span={3}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const dp = getFieldValue('documento_recibo');
                              if (!dp) return null;
                              return (
                                <CustomButton
                                  type="default"
                                  htmlType="button"
                                  onClick={() => {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      const url = reader.result;
                                      const newWindow = window.open(
                                        url,
                                        '_blank',
                                      );
                                      if (newWindow) {
                                        newWindow.document.write(
                                          `<iframe src="${url}" width="100%" height="100%"></iframe>`,
                                        );
                                      }
                                    };
                                    reader.readAsDataURL(dp);
                                  }}
                                  text="Ver"
                                />
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue, setFieldsValue }) => {
                              const dp = getFieldValue('documento_recibo');
                              if (!dp) return null;
                              return (
                                <CustomButton
                                  type="default"
                                  danger
                                  onClick={() => {
                                    setFieldsValue({
                                      documento_recibo: undefined,
                                    });
                                  }}
                                  text="Eliminar"
                                />
                              );
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row justify="center">
                      <CustomButton
                        type="primary"
                        htmlType="submit"
                        text={
                          !insuranceData.to_fix
                            ? 'Notificar cargue'
                            : 'Guardar y notificar ajuste'
                        }
                        loading={loading}
                      />
                    </Row>
                  </Form>
                </Space>
              )}
            </>
          ) : (
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Title level={3}>Documentos cargados</Title>
              <Table
                dataSource={insuranceData.docs}
                columns={columns}
                pagination={null}
              />
            </Space>
          )}
          <CustomButton
            type="default"
            htmlType="button"
            className={'back-button'}
            text={<Link to={INTERNAL_LINKS.CONTRACTOR_INSURANCE}>Volver</Link>}
          />
        </>
      ) : (
        <Skeleton loading={true} />
      )}
    </>
  );
}
