import React, { useState, useEffect } from 'react';
import {
  Table,
  notification,
  Popconfirm,
  Space,
  Typography,
  Layout,
} from 'antd';
import { Link } from 'react-router-dom';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import PdfViewer from '../../../components/PdfViewer';

import { filter, remove } from '../../../api/module/resume_certifications';
import { get as getDocumentUrl } from '../../../api/module/document_repository';
import { catchException } from '../../../utils/helpers/helpers';

const { Paragraph } = Typography;
const { Content } = Layout;

const OtherCertificationsList = ({ idTercero, readOnly }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showClick = async (documento_nombre) => {
    setLoadingDocument(true);

    const response = await getDocumentUrl(documento_nombre);

    setPdfUrl(response.data.url);
    setShowPdf(true);
    setLoadingDocument(false);
  };

  useEffect(async () => {
    if (idTercero !== undefined) await getDataSource();
  }, []);

  const getDataSource = async () => {
    try {
      setLoading(true);
      const response = await filter('?filter[tercero]=' + idTercero);
      setDataSource(response.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const deleteDataSource = async (id) => {
    try {
      setLoadingDelete(true);
      const response = await remove(id);
      setLoadingDelete(false);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: 2,
        });
        await getDataSource();
      }
    } catch (err) {
      console.log(err);
      setLoadingDelete(false);
      catchException(err, MESSAGES.DESC_ERROR_DELETE_MSG);
    }
  };

  const columns = [
    {
      title: 'Nombre certificación',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Fecha de terminación',
      dataIndex: 'fecha_terminacion',
      key: 'fecha_terminacion',
      align: 'center',
    },
    {
      title: 'Consultar documento',
      dataIndex: 'tarjeta_profesional',
      key: 'tarjeta_profesional',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        if (record.documento_nombre) {
          return (
            <CustomButton
              block
              type="default"
              text="Ver"
              htmlType="button"
              loading={loadingDocument}
              onClick={() => {
                showClick(record.documento_nombre);
              }}
            />
          );
        }
      },
    },
    {
      title: 'Acción',
      key: 'accion',
      fixed: 'right',
      width: 200,
      render: (text, record) =>
        dataSource.length >= 1 && !readOnly ? (
          <Space>
            <CustomButton
              type={'default'}
              className={'ant-btn-info'}
              htmlType="button"
              text={
                <Link
                  to={`${INTERNAL_LINKS.OTHER_CERTIFICATIONS}/${idTercero}/${record.id}`}
                >
                  Editar
                </Link>
              }
            />
            <CustomButton
              danger
              type={'default'}
              htmlType="button"
              loading={loadingDelete}
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSource(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </Space>
        ) : null,
    },
  ];

  return (
    <Content>
      <SpinLoad loading={loading}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Otras certificaciones
            </Paragraph>
          )}
        />
      </SpinLoad>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </Content>
  );
};

export default OtherCertificationsList;
