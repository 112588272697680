import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_resume_validation/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_resume_validation/${id}`);
};

const getAllByContract = async (id) => {
  return await httpClient.get(
    `/contract_document_search_detail/?page[size]=1000&filter[contrato]=${id}`,
  );
};

const getAllBySelfAndTerm = async (term) => {
  return await httpClient.get(
    `/term_resume_validation/?page[size]=1000&filter[tercero]=${window.localStorage['tercero']}&filter[termino]=${term}`,
  );
};

const getAllBySelfAndTermVal = async (query) => {
  return await httpClient.get(`/term_resume_validation/${query}`);
};

const getAllByPersonAndTerm = async (person, term) => {
  return await httpClient.get(
    `/term_resume_validation/?page[size]=1000&filter[tercero]=${person}&filter[termino]=${term}`,
  );
};

const filter = async (query) => {
  return await httpClient.get(`/term_resume_validation${query}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/term_resume_validation/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/term_resume_validation/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_resume_validation/${id}`);
};

const multiple = async (data) => {
  return await httpClient.post(`/term_resume_validation/multiple/`, data);
};

const reject = async (query) => {
  return await httpClient.get(`/term_resume_validation/rejected${query}`);
};

const sign = async (id) => {
  return await httpClient.post(`/term_resume_validation/${id}/sign/`);
};

export {
  post,
  get,
  getAllBySelfAndTerm,
  getAllBySelfAndTermVal,
  put,
  patch,
  remove,
  multiple,
  reject,
  filter,
  getAllByPersonAndTerm,
  getAllByContract,
  sign
};
