import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
  Row,
  Col,
  Form,
  Select,
  Alert,
  Modal,
  notification,
  Input,
  Space,
} from 'antd';

import { CustomButton, CustomSelector } from '../../../components';

import AjaxTable from '../../../components/AjaxTable';
import {
  massContractSearch,
  newInfo,
  post,
  getTermsSelector,
  getIdentifierSelector,
  selectAll,
} from '../../../api/module/mass_postcontractual';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import MESSAGES from '../../../enums/Messages';
import SpinLoad from '../../../components/SpinLoad';

const { Title } = Typography;
const { TextArea } = Input;

const breadcrumbItems = [
  {
    text: 'Módulo Poscontractual',
    to: `${INTERNAL_LINKS.POSTCONTRACTUAL}`,
  },
  {
    text: 'Formulario Poscontractual Masivo',
  },
];

const NewMassPostContractualForm = ({ match, history }) => {
  const [data, setData] = useState();
  const [query, setQuery] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [newType, setNewType] = useState();
  const [loadingSA, setLoadingSA] = useState(false);

  const [loadingNext, setLoadingNext] = useState();
  const [selected, setSelected] = useState([]);

  const [errorMassID, setErrorMassID] = useState(false);
  const [nextForm] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      setData();
      try {
        const res = await newInfo(match.params.project_id);
        setData({ ...res.data, project_id: match.params.project_id });
      } catch (error) {
        console.log(error);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.project_id]);

  useEffect(() => {
    setQuery();
  }, [newType]);

  useEffect(() => {
    setSelected([]);
  }, [query]);

  const onSelectAll = async () => {
    setLoadingSA(true);
    try {
      const res = await selectAll(query);
      setSelected(res.data.results);
      setLoadingSA(false);
    } catch (error) {
      setLoadingSA(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const rowSelection = {
    // columnTitle: '',
    fixed: true,
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selected,
    onChange: (_selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        setSelected(
          selectedRows
            .filter((v) => !(v.mod_draft || v.status_pc))
            .map((v) => v.id),
        );
      } else {
        setSelected([]);
      }
    },
    renderCell: (_checked, record, _index, originNode) => {
      if (record.mod_draft || record.status_pc) return null;
      if (!newType) return null;
      if (record.mod_num > 0 && !record.mod_ii) return null;
      return originNode;
    },
  };

  const next = async (values) => {
    setLoadingNext(true);
    const newData = {
      ...values,
      tipo_terminacion: newType,
      contratos: selected,
    };
    try {
      const res = await post(newData);
      history.push(
        `${INTERNAL_LINKS.MASS_POSTCONTRACTUAL}-form/${res.data.id}`,
      );
    } catch (error) {
      try {
        const ed = error.response.data.errors[0].detail;
        if (ed.startsWith('Los siguiente(s) contrato(s) ya finalizaron:')) {
          Modal.confirm({
            title: 'Error',
            content: (
              <div>
                {ed.split('\n').map((v) => (
                  <p>{v}</p>
                ))}
                <p>¿Quiere continuar con el proceso de liquidación de acta?</p>
              </div>
            ),
            onCancel: () => setLoadingNext(),
            onOk: async () => {
              try {
                const res = await post({ ...newData, ignore_date: true });
                history.push(
                  `${INTERNAL_LINKS.MASS_POSTCONTRACTUAL}-form/${res.data.id}`,
                );
              } catch (error) {
                console.log(error);
                notification.error({
                  message: MESSAGES.ERROR_MSG,
                  description: MESSAGES.DESC_ERROR_POST_MSG,
                  duration: 2,
                });
                setLoadingNext();
                if (
                  error?.response?.data?.errors[0]?.detail ===
                  'El identificador masivo ya existe.'
                )
                  setErrorMassID(true);
              }
            },
          });
        } else {
          Modal.error({
            title: 'Error',
            content: (
              <div>
                {ed.split('\n').map((v) => (
                  <p>{v}</p>
                ))}
              </div>
            ),
          });
          setLoadingNext();
          if (ed === 'El identificador masivo ya existe.') setErrorMassID(true);
        }
      } catch (e) {
        setLoadingNext();
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
        console.log(error, '\n', e);
      }
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const q = Object.entries(values || {}).reduce((pv, cv) => {
      if ((cv[0] === 'modificacion') & (cv[1] === 1))
        pv.push(`filter[modificacion__isnull]=true`);
      else if (cv[1]) pv.push(`filter[${cv[0]}]=${cv[1]}`);
      return pv;
    }, []);
    q.push(`filter[proyecto]=${data.project_id}`);
    setSelected([]);
    setQuery(q.join('&'));
    setLoading(false);
  };

  const columns = [
    {
      title: 'Cargo/Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'Identificador',
      dataIndex: 'termino_ii',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: 'Nombre Contratista / Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: '# de modificaciones realizadas',
      dataIndex: 'mod_num',
      width: '1rem',
      align: 'center',
    },
    {
      title: 'Identificador interno última Modificación',
      dataIndex: 'mod_ii',
      render: (t, record) =>
        record.mod_draft
          ? 'Modificación en curso'
          : record.mod_num > 0 && !record.mod_ii
          ? 'Modificación individual'
          : t,
    },
    {
      title: 'Terminación realizada',
      dataIndex: 'status_pc',
      render: (t, record) => (
        <>
          {t === 1
            ? 'Terminación en curso'
            : t === 2
            ? 'Terminación finalizada'
            : t === -1
            ? 'No habilitado'
            : null}
        </>
      ),
    },
  ];

  return (
    <div>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO POSCONTRACTUAL
      </Title>
      <Title level={4}>Proyecto: {data?.project_name}</Title>
      {!newType && (
        <Alert
          showIcon
          type="info"
          message="Información importante"
          description={
            <>
              <b>Terminaciones y Liquidaciones contractuales:</b>
              <br />
              <b>Ordinaria: </b>Cuando el contrato (incluidas sus
              modificaciones) finaliza en la fecha pactada.
              <br />
              <b>Anticipada: </b>Cuando el contrato (incluidas sus
              modificaciones) finaliza antes de la fecha pactada por mutuo
              acuerdo y/o renuncias.
              <br />
              <b>Unilateral: </b>Cuando el contrato (incluidas sus
              modificaciones) finaliza por decisión de la Organización
              contratante.
            </>
          }
        />
      )}
      <br />

      {data && (
        <>
          <Title level={5}>Selección de tipo de terminación a generar</Title>
          <Row gutter={50}>
            <Col>Tipo de terminación</Col>
            <Col flex="auto">
              <Select
                options={data.tipos}
                onChange={setNewType}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

          <br />
          {newType && (
            <>
              <Title level={5}>Búsqueda por cargo</Title>
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFinish}
                preserve={false}
                onValuesChange={(cv) => {
                  if ('cargo' in cv) {
                    form.setFieldsValue({
                      termino: undefined,
                      modificacion: undefined,
                    });
                  }
                  if ('termino' in cv) {
                    form.setFieldsValue({
                      modificacion: undefined,
                    });
                  }
                }}
              >
                <Form.Item
                  label="Cargo"
                  name="cargo"
                  rules={[
                    { required: true, message: 'Este campo es requerido' },
                  ]}
                >
                  <Select
                    placeholder="Seleccione un cargo/rol..."
                    allowClear
                    options={data.positions}
                  />
                </Form.Item>
                <Form.Item noStyle dependencies={['cargo']}>
                  {({ getFieldValue }) => {
                    const cargo = getFieldValue('cargo');
                    if (cargo) {
                      return (
                        <Form.Item
                          label="Identificador interno del TDR"
                          name="termino"
                          rules={[
                            {
                              required: newType === 2,
                              message: 'Este campo es requerido',
                            },
                          ]}
                        >
                          <CustomSelector
                            selectorProps={{
                              style: { width: '100%' },
                              placeholder:
                                'Seleccione el identificador único del termino de referencia... ',
                            }}
                            endpointFetch={async () => {
                              return await getTermsSelector(cargo);
                            }}
                            selectorKey={cargo}
                          />
                        </Form.Item>
                      );
                    } else return null;
                  }}
                </Form.Item>
                <Form.Item noStyle dependencies={['cargo', 'termino']}>
                  {({ getFieldValue }) => {
                    const termino = getFieldValue('termino');
                    if (termino) {
                      return (
                        <Form.Item
                          label="Identificador interno de la Modificación masiva"
                          name="modificacion"
                          rules={[
                            {
                              required: newType === 2,
                              message: 'Este campo es requerido',
                            },
                          ]}
                        >
                          <CustomSelector
                            selectorProps={{
                              style: { width: '100%' },
                              placeholder:
                                'Seleccione el identificador interno de la modificación masiva... ',
                            }}
                            endpointFetch={async () => {
                              return await getIdentifierSelector(termino);
                            }}
                            selectorKey={termino}
                          />
                        </Form.Item>
                      );
                    } else return null;
                  }}
                </Form.Item>
                <Row gutter={8} justify="end">
                  <Col>
                    <CustomButton loading={loading} text="Buscar" />
                  </Col>
                  <Col>
                    <CustomButton
                      htmlType="button"
                      type="default"
                      onClick={() => {
                        form.resetFields();
                      }}
                      danger={true}
                      text="Borrar filtros"
                    />
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </>
      )}
      <br />
      {data && query && newType && (
        <>
          <SpinLoad loading={loadingSA}>
            <AjaxTable
              columns={columns}
              tableTitle={'Listado de resultados'}
              endpoint={massContractSearch}
              query={query}
              rowSelection={rowSelection}
              searchInput
              exportCSV
            />
          </SpinLoad>
          <CustomButton
            type="primary"
            text="Seleccionar todo"
            onClick={onSelectAll}
            loading={loadingSA}
          />
          <br />
          <br />
          <Form
            form={nextForm}
            onFinish={next}
            onValuesChange={(cv) => {
              if ('identificador_masivo' in cv && errorMassID)
                setErrorMassID(false);
            }}
          >
            <Form.Item
              name="identificador_masivo"
              label="Identificador de liquidación masiva"
              rules={[{ required: true }]}
            >
              <TextArea
                placeholder="Digite aquí"
                maxLength={50}
                showCount
                autoSize={{ minRows: 1, maxRows: 1 }}
              />
            </Form.Item>
          </Form>

          {errorMassID && (
            <>
              <Alert
                showIcon
                message="El identificador masivo ya existe."
                type="error"
              />
              <br />
            </>
          )}
          <Space style={{ justifyContent: 'space-evenly', width: '100%' }}>
            <CustomButton
              type="primary"
              text="Iniciar"
              onClick={() => {
                if (selected.length < 2) {
                  Modal.error({
                    title: 'Error',
                    okText: 'Ok',
                    content:
                      'Recuerde que debe seleccionar dos o más personas para continuar, de lo contrario, seleccione la opción de "Nueva terminación - Individual"',
                  });
                } else nextForm.submit();
              }}
              loading={loadingNext || loadingSA}
            />
          </Space>
        </>
      )}
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        text={
          <Link
            to={`${INTERNAL_LINKS.POSTCONTRACTUAL}/0/${match.params.project_id}`}
          >
            Volver
          </Link>
        }
      />
    </div>
  );
};

export default NewMassPostContractualForm;
