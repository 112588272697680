import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, DatePicker, Row, Col } from 'antd';

import CustomButton from '../../components/CustomButton';
import SelectOrganizationsActive from '../../components/SelectOrganizationsActive/index';
import CustomUpload from '../../components/CustomUpload';
import { PlusOutlined } from '@ant-design/icons';

const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const AnnouncementOpenForm = ({
  save,
  loading,
  initialValues,
  showCLick,
  linkPush,
  announcement_id,
  form,
  isLoadingDoc,
}) => {
  const organizationRef = useRef();
  const [initialDate, setInitialDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);

  function disabledDate(current) {
    // Can not select days before today and today
    return (
      current &&
      current <
        (initialValues.estado_lanzamiento
          ? initialValues.fecha_fin
          : initialValues.fecha_inicio)
    );
  }

  useEffect(() => {
    if (initialValues.fecha_inicio) {
      setInitialDate(initialValues.fecha_inicio);
    }
    if (initialValues.fecha_fin) {
      setFinishDate(initialValues.fecha_fin);
    }
  }, []);

  return (
    <Form
      form={form}
      onFinish={save}
      layout="vertical"
      initialValues={initialValues}
      autoComplete="off"
      validateMessages={validateMessages}
      scrollToFirstError
    >
      <Row gutter={12}>
        <Col span={12} ref={organizationRef}>
          <Form.Item
            name="organizacion"
            label="Organización ejecutora"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (value) {
                    return Promise.resolve();
                  } else {
                    organizationRef.current.scrollIntoView();
                    return Promise.reject(new Error('Es requerido'));
                  }
                },
              }),
            ]}
          >
            <SelectOrganizationsActive
              organization={initialValues.organizacion}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="proyecto_nombre"
            label="Proyecto, licitación, concurso u otro"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (!value || value.length <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Solo puede ingresar 100 caracteres!');
                },
              }),
            ]}
          >
            <Input
              style={{ marginLeft: '5px' }}
              placeholder="Nombre de proyecto, licitación, concurso u otro..."
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item
            name="numero"
            label="Número de convocatoria"
            tooltip={{
              placement: 'top',
              title: `Una vez guarde la convocatoria, automáticamente se generará el número de convocatoria.`,
            }}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="nombre"
            label="Nombre de la convocatoria"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (!value || value.length <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Solo puede ingresar 100 caracteres!');
                },
              }),
            ]}
          >
            <Input placeholder="Nombre de la convocatoria..." allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={9}>
          <Form.Item
            style={{ marginBottom: 10 }}
            name="fecha_inicio"
            label="Fecha de inicio de la convocatoria"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              disabled={initialValues.estado_lanzamiento}
              placeholder="Fecha inicio..."
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              onChange={(value) => {
                setInitialDate(value);
              }}
            />
          </Form.Item>
          {initialDate && (
            <Form.Item noStyle>
              Fecha de inicio seleccionada:
              <strong> {initialDate.format('DD MMMM YYYY')}</strong>
            </Form.Item>
          )}
        </Col>
        <Col span={9}>
          <Form.Item
            style={{ marginBottom: 10 }}
            name="fecha_fin"
            label="Fecha de finalización de la convocatoria"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    getFieldValue('fecha_inicio') != null &&
                    getFieldValue('fecha_fin') != null
                  ) {
                    if (
                      !getFieldValue('fecha_inicio').isAfter(
                        getFieldValue('fecha_fin'),
                        'day',
                      )
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          'La fecha de fin no puede ser menor a la fecha de inicio',
                        ),
                      );
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={disabledDate}
              placeholder="Fecha fin..."
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              onChange={(value) => {
                setFinishDate(value);
              }}
            />
          </Form.Item>
          {finishDate && (
            <Form.Item noStyle>
              Fecha de finalización seleccionada:
              <strong> {finishDate.format('DD MMMM YYYY')}</strong>
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Form.Item
            name="descripcion"
            label="Descripción de la convocatoria"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea
              placeholder="Descripción de la convocatoria..."
              rows={5}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} align={'middle'}>
        <Col span={20}>
          <CustomUpload
            file_type={1}
            upload_type={2}
            name="anexo"
            label="Cargue archivo anexo convocatoria"
          />
        </Col>
        <Col span={4}>
          {initialValues.documento_anexo_nombre != null ? (
            <CustomButton
              block
              text="Ver"
              htmlType="button"
              type="default"
              loading={isLoadingDoc}
              onClick={() => {
                showCLick(initialValues.documento_anexo_nombre);
              }}
            />
          ) : null}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Row justify={'center'}>
            <CustomButton
              loading={loading}
              htmlType="submit"
              text="Guardar convocatoria"
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          {announcement_id != null && (
            <CustomButton
              style={{ marginTop: 10 }}
              type={'default'}
              className={'ant-btn-info'}
              htmlType="button"
              onClick={linkPush}
              text={
                <>
                  <PlusOutlined /> Agregar Cargo/Rol
                </>
              }
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

AnnouncementOpenForm.propTypes = {
  save: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  showCLick: PropTypes.func,
  linkPush: PropTypes.func,
  announcement_id: PropTypes.string,
};

AnnouncementOpenForm.defaultProps = {
  save: () => {},
  initialValues: {},
  loading: false,
  showCLick: () => {},
  linkPush: () => {},
  announcement_id: null,
};

export default AnnouncementOpenForm;
