import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`/template_term/`);
};

const filter = async (query) => {
  return await httpClient.get(`/template_term/?${query}`);
};

export {
  getAll,
  filter,
};
