import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `/term_legal_validation_document_supplementary/`,
    data,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `/term_legal_validation_document_supplementary/${id}/`,
  );
};

const filter = async (query, page, pageSize) => {
  return await httpClient.get(
    `/term_legal_validation_document_supplementary${query}&page[number]=${page}&page[size]=${pageSize}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `/term_legal_validation_document_supplementary/${id}/`,
    data,
  );
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `/term_legal_validation_document_supplementary/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `/term_legal_validation_document_supplementary/${id}`,
  );
};

const multiple = async (data) => {
  return await httpClient.post(
    `/term_legal_validation_document_supplementary/multiple/`,
    data,
  );
};

export { post, get, filter, put, patch, remove, multiple };
