import React, { useState, useEffect } from 'react';
import { Table, Pagination, Empty, Row, Col, Input, Typography } from 'antd';

import SpinLoad from '../../components/SpinLoad';

import { catchException } from '../../utils/helpers/helpers';
import CustomButton from '../CustomButton';
import { ExportOutlined, ReloadOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const AjaxTable = ({
  rowKey = 'id',
  scroll = { x: 'max-content' },
  columns,
  tableTitle,
  emptyTextDefault = 'No hay datos',
  endpoint,
  query,
  sortBy,
  searchInput = false,
  exportCSV = false,
  reloadButton = false,
  extraColumns = [],
  endpointFetch,
  pageSize = 10,
  rowSelection = undefined,
  reloadSource = false,
  showSizeChanger = false,
  setCount = () => {},
  expandedRowRender = () => {},
  onReload = () => {},
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(false);
  const [emptyText, setEmptyText] = useState(emptyTextDefault);

  const onPageChange = async (page, pageSize, searchBy = false) => {
    try {
      let res;
      searchBy = searchBy === '' ? '' : searchBy || search;
      setDataLoading(true);
      if (endpointFetch !== undefined)
        res = await endpointFetch(page, pageSize);
      else res = await endpoint(query, page, pageSize, sortBy, searchBy);

      if (searchBy && res.data.meta.pagination.count === 0)
        setEmptyText('No se encontraron resultados');

      setDataLoading(false);
      setCurrentPage(page);
      setDataSource(res.data.results);
      setPagination(res.data.meta.pagination);
      setCount(res.data.meta.pagination.count);
    } catch (exception) {
      setDataLoading(false);
      if (
        exception?.response?.data?.errors &&
        exception.response.data.errors[0]?.detail === 'Página inválida.' &&
        page !== 1
      )
        onPageChange(1, pageSize, searchBy);
      else catchException(exception);
    }
  };

  const reloadTable = async () => {
    setDataSource([]);
    onReload();
    await onPageChange(1, pagination.size || pageSize);
  };

  const searchTable = async (event) => {
    setSearch(event.target.value ?? false);
    if (event.type === 'keydown' && event.keyCode === 13)
      await onPageChange(
        currentPage,
        pagination.size || pageSize,
        event.target.value,
      );
    if (event.target.value === '')
      await onPageChange(currentPage, pagination.size || pageSize, '');
  };

  const exportTable = async () => {
    try {
      setExportLoading(true);
      let response = await endpoint(
        query,
        1,
        pagination.size || pageSize,
        sortBy,
        search,
        true,
      );

      const file = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');

      setExportLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    setDataSource([]);
    await onPageChange(1, pagination?.size || pageSize);
  }, [sortBy, reloadSource, query]);

  return (
    <SpinLoad loading={dataLoading}>
      <Table
        rowKey={rowKey}
        scroll={scroll}
        dataSource={dataSource}
        columns={[...columns, ...extraColumns]}
        pagination={false}
        rowSelection={rowSelection}
        expandable={expandedRowRender}
        title={() => (
          <Row gutter={12} justify={'space-between'} align={'bottom'}>
            <Col>
              <Paragraph className={'margin-5'} strong>
                {tableTitle}
              </Paragraph>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              <Row gutter={12} justify={'space-between'} align={'bottom'}>
                <Col>
                  {exportCSV && dataSource.length > 0 && (
                    <CustomButton
                      type={'default'}
                      htmlType="button"
                      className={'ant-btn-success'}
                      onClick={exportTable}
                      loading={exportLoading}
                      text={
                        <>
                          <ExportOutlined /> Exportar
                        </>
                      }
                    />
                  )}
                </Col>
                <Col>
                  {searchInput && (
                    <Input
                      onPressEnter={searchTable}
                      onChange={searchTable}
                      placeholder={`Buscar`}
                      allowClear
                      inputMode={'text'}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
        }}
      />
      <Row
        style={{ marginBottom: 10 }}
        gutter={12}
        justify={'space-between'}
        align={'bottom'}
      >
        <Col>
          {reloadButton && (
            <CustomButton
              type={'default'}
              htmlType="button"
              onClick={reloadTable}
              text={
                <>
                  <ReloadOutlined /> Recargar listado
                </>
              }
            />
          )}
        </Col>
        <Col>
          {pagination && (
            <Pagination
              className={'ant-pagination-ajax'}
              defaultCurrent={1}
              current={pagination.page}
              onChange={onPageChange}
              total={pagination.count}
              pageSize={pagination?.size || pageSize}
              showSizeChanger={showSizeChanger}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} de ${total} registros`
              }
            />
          )}
        </Col>
      </Row>
    </SpinLoad>
  );
};

export default AjaxTable;
