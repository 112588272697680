import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert, Descriptions, Skeleton, Card } from 'antd';
import moment from 'moment';

import { getPublicInfo } from '../../api/module/announcement';

import { IsLoggedIn } from '../../utils/helpers/helpers';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';
import CustomButton from '../../components/CustomButton';
import Title from 'antd/lib/typography/Title';

const PublicAnnouncement = ({ history, match }) => {
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDocument, setLoadingDocument] = useState(false);

  const periodOptions = {
    1: 'Mensual',
    2: 'Total contrato',
    3: 'Entrega de productos',
    4: 'Otro',
  };

  useEffect(() => {
    if (IsLoggedIn()) {
      history.push(`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${match.params.id}`);
    } else {
      load();
    }
  }, []);

  const load = async () => {
    try {
      const response = await getPublicInfo(match.params.id);
      setAnnouncement(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const showDocument = (url) => {
    window.open(url);
  };

  return (
    <div className="public-announcement-page">
      {loading === true && (
        <Alert
          message="Cargando información de la convocatoria."
          description="Por favor espere..."
          type="info"
          showIcon
        />
      )}
      <Skeleton loading={loading} active>
        {announcement ? (
          <>
            <Title className={'text-uppercase'} level={2}>
              PREVISUALIZACIÓN de CONVOCATORIA
            </Title>
            <Card
              size={'small'}
              bordered={false}
              title={
                <Title level={3} style={{ whiteSpace: 'normal' }}>
                  {announcement.nombre}
                </Title>
              }
              extra={
                <img
                  src={announcement.organizacion_logo}
                  alt="Logo Organización"
                  style={{ width: 'calc(250px - 20px)' }}
                />
              }
            >
              <Descriptions
                className="description-label-style"
                title="Información de la convocatoria"
                bordered
                column={1}
              >
                <Descriptions.Item label="Organización">
                  {announcement.organizacion_nombre}
                  {announcement.organizacion_sigla &&
                    ` - ${announcement.organizacion_sigla}`}
                </Descriptions.Item>
                <Descriptions.Item label="Proyecto, licitación, concurso u otro">
                  {announcement.proyecto_nombre}
                </Descriptions.Item>
                <Descriptions.Item label="Número de convocatoria">
                  {announcement.numero}
                </Descriptions.Item>
                <Descriptions.Item label="Nombre de la convocatoria">
                  {announcement.nombre}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha inicio de la convocatoria">
                  {moment(announcement.fecha_inicio).format('DD - MMMM - YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha fin de la convocatoria">
                  {moment(announcement.fecha_fin).format('DD - MMMM - YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="Descripción de la convocatoria">
                  {announcement.descripcion.split('\n').map((value, index) => (
                    <>
                      {value} <br />
                    </>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="Archivo anexo de la convocatoria">
                  {announcement.documento_anexo_url ? (
                    <CustomButton
                      block
                      type="default"
                      text="Ver"
                      htmlType="button"
                      loading={loadingDocument}
                      onClick={() => {
                        showDocument(announcement.documento_anexo_url);
                      }}
                    />
                  ) : (
                    'Sin adjunto'
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            {announcement.convocatoria_rol.map((item, index) => (
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={5}>Cargo / Rol No. {index + 1}</Title>}
              >
                <Descriptions
                  className="description-label-style"
                  bordered
                  column={1}
                >
                  <Descriptions.Item label="Cargo / Rol">
                    {item.cargo.split('\n').map((value, index) => (
                      <>
                        {value} <br />
                      </>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={`Remuneración ${
                      item.remuneracion_variable != null ? 'variable' : 'fija'
                    }`}
                  >
                    {item.remuneracion_variable != null
                      ? item.remuneracion_variable
                          .split('\n')
                          .map((value, index) => (
                            <>
                              {value} <br />
                            </>
                          ))
                      : item.remuneracion_fija != null
                      ? `$ ${item.remuneracion_fija}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ',',
                        )
                      : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Período">
                    {periodOptions[item.periodo]}
                  </Descriptions.Item>
                  {item.periodo === 4 && (
                    <Descriptions.Item label="¿Cuál?">
                      {item.periodo_cual}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Tipo contrato">
                    {item.tipo_contrato_nombre}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duración del proyecto">
                    {item.duracion} (Meses)
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  style={{ marginTop: 20 }}
                  className="description-label-style"
                  title="Lugar de realización de labores"
                  bordered
                  column={1}
                >
                  <Descriptions.Item label="País">
                    {item.pais_nombre}
                  </Descriptions.Item>
                  <Descriptions.Item label="Departamento">
                    {item.departamento_nombre}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ciudad">
                    {item.ciudad_nombre}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  style={{ marginTop: 20 }}
                  className="description-label-style"
                  title="Requisitos"
                  column={3}
                  bordered
                >
                  <Descriptions.Item label="Estudios" span={3}>
                    {item.estudios.split('\n').map((value, index) => (
                      <>
                        {value} <br />
                      </>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Tipo de experiencia laboral"
                    span={3}
                  >
                    {item.tipo_experiencia_laboral
                      .split('\n')
                      .map((value, index) => (
                        <>
                          {value} <br />
                        </>
                      ))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: '50px' }}
                    label="Disponibilidad para viajar"
                  >
                    {item.disponibilidad_viajar ? 'Si' : 'No'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Posibilidad de radicarse en otra ciudad durante el proyecto">
                    {item.radicarse_otra_ciudad ? 'Si' : 'No'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: '50px' }}
                    label="Tiempo de experiencia laboral (meses)"
                  >
                    {item.tiempo_experiencia_laboral}{' '}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={3} bordered>
                  <Descriptions.Item
                    label="Aceptar y aplicar a cargo/rol"
                    span={3}
                  >
                    <Button type="primary">
                      <Link to="/">Aceptar y aplicar a cargo rol</Link>
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ))}
          </>
        ) : (
          <Alert
            message="Error"
            description="No ha sido posible mostrar la información de la convocatoria."
            type="warning"
            showIcon
            closable
          />
        )}
      </Skeleton>
    </div>
  );
};

export default PublicAnnouncement;
