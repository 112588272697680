import React from 'react';
import { Typography, Layout } from 'antd';
import { Link } from 'react-router-dom';

import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';

import { ajaxTable } from '../../api/module/insurance_policy_contractor';

import AjaxTable from '../../components/AjaxTable';

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Pólizas',
  },
];

const InsurancePolicyContractorHome = () => {
  const columns = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
      // width: '3rem',
    },
    {
      title: '# Modificación',
      dataIndex: 'modificacion_num',
      // width: '2rem',
      align: 'center',
    },
  ];

  const extraColumnsToCheck = [
    {
      title: 'Acciones',
      dataIndex: 'fix',
      align: 'center',
      fixed: 'right',
      // width: '20rem',
      render: (t, record) => {
        return t ? (
          <CustomButton
            block
            danger
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/${record.id}`}
              >
                Ajustar
              </Link>
            }
          />
        ) : (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/${record.id}`}
              >
                Cargar
              </Link>
            }
          />
        );
      },
    },
  ];

  const extraColumnsReview = [
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (_t, record) => (
        <CustomButton
          block
          type="default"
          htmlType="button"
          className="ant-btn-info"
          text={
            <Link
              to={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/${record.id}`}
            >
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  const extraColumnsView = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type="default"
          htmlType="button"
          className="ant-btn-info"
          text={
            <Link to={`${INTERNAL_LINKS.RESUME_CONTRACT}/${record.contrato}`}>
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO PÓLIZAS
      </Title>
      <br />
      <br />
      <Title level={4}>Pólizas por cargar / ajustar</Title>
      <AjaxTable
        columns={[...columns, ...extraColumnsToCheck]}
        endpoint={ajaxTable}
        query={'filter[estado]=0'}
        reloadButton
        searchInput
        exportCSV
      />
      <br />
      <br />
      <Title level={4}>Pólizas en revisión</Title>
      <AjaxTable
        columns={[...columns, ...extraColumnsReview]}
        endpoint={ajaxTable}
        query={'filter[estado]=1'}
        reloadButton
        searchInput
        exportCSV
      />
      <br />
      <br />
      <Title level={4}>Pólizas aprobadas</Title>
      <AjaxTable
        columns={[...columns, ...extraColumnsView]}
        endpoint={ajaxTable}
        query={'filter[estado]=2'}
        reloadButton
        searchInput
        exportCSV
      />
    </Content>
  );
};

export default InsurancePolicyContractorHome;
