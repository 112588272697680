import React, { useEffect, useRef, useState } from 'react';
import { Form, Layout, notification, Skeleton } from 'antd';

import OrganizationForm from '../../../containers/OrganizationForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import PdfViewer from '../../../components/PdfViewer';

import { get, getAll, post, put } from '../../../api/module/organizations';
import { getAll as getOrganizationsTypes } from '../../../api/module/organizations_types';
import {
  getAllByID as getOrganizationPartner,
  post as postOrganizationPartner,
} from '../../../api/module/organization_partner';
import {getAll as getAllIdentiesTypes} from '../../../api/module/identities_types';
import {get as getDocUrl} from '../../../api/module/document_repository';

import './style.scss';
import * as PropTypes from "prop-types";

const { Content } = Layout;

const OrganizationsForms = ({ history, match }) => {
    const [organizationID, setOrganizationID] = useState(
      parseInt(match.params.id),
    );
    const [title, setTitle] = useState('Creación de Organizaciones ejecutoras');
    const [initialValues, setInitialValues] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [organizations, setOrganizations] = useState(null);
    const [organizationsTypes, setOrgaizationsTypes] = useState(null);
    const [identiesTypes, setIdentiesTypes] = useState(null);
    const titleRef = useRef(null);
    const [titlRef, setTitlRef] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [organizationForm] = Form.useForm();

    useEffect(() => {
      load();
    }, []);

    const load = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          handleGet(),
          handleGetOrganizations(),
          handleGetOrganizationsTypes(),
          handleGetIdentiesTypes(),
        ]);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    const handleGetOrganizations = async () => {
      const response = await getAll();
      setOrganizations(response.data.results);
    };

    const handleGetOrganizationsTypes = async () => {
      const response = await getOrganizationsTypes();
      setOrgaizationsTypes(response.data.results);
    };

    const handleGetIdentiesTypes = async () => {
      const response = await getAllIdentiesTypes();
      setIdentiesTypes(response.data.results);
    };

    /**
     * Get organization by ID
     */
    const handleGet = async () => {
      try {
        if (organizationID) {
          let response = await get(organizationID);
          let data = response.data;
          if (data['pais'] == null) {
            data['pais'] = 45;
          }
          if (data['estado'] == null) {
            data['estado'] = 3;
          }
          if (data['ciudad'] == null) {
            data['ciudad'] = 149;
          }
          if (data['sigla'] === 'undefined') {
            data['sigla'] = '';
          }
          data['esta_activa'] = data.esta_activa === true ? 1 : 0;
          const partnersResponse = await getOrganizationPartner(organizationID);
          const partners = partnersResponse.data.results;

          let newPartners = [];
          partners.map((item) => {
            newPartners.push(item.integrante);
          });
          data['integrante'] = newPartners;
          organizationForm.setFieldsValue(data);

          if (data.logo_organizacion_nombre) {
            data.logo_organizacion_nombre = await getLogoUrl(
              data.logo_organizacion_nombre,
            );
          }

          setInitialValues({...data});
          setTitle('Actualización de Organizaciones ejecutoras');
        } else {
          const data = {
            pais: 45,
            estado: 3,
            ciudad: 149,
          };
          organizationForm.setFieldsValue({...data});
          setInitialValues({...data});
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    };

    const getLoadedTemplate = async (name) => {
      const response = await getDocUrl(name);
      return response.data.url;
    };

    const showCLick = async (name) => {
      try {
        const url = await getLoadedTemplate(name);
        setPdfUrl(url);
        setShowPdf(!showPdf);
      } catch (err) {
        console.log(err);
      }
    };

    const getLogoUrl = async (name) => {
      return await getLoadedTemplate(name);
    };

    const save = async (data) => {
      try {
        let response;
        const formData = new FormData();

        const fileToUpload = [
          'documento_representante',
          'rut_organizacion',
          'ccb_organizacion',
          'firma_representante',
          'logo_organizacion',
          'certificacion_organizacion',
        ];

        for (let item of fileToUpload) {
          if (data[item] != null && data[item] !== undefined) {
            if (data[item].fileList.length > 0) {
              data[item] = data[item].fileList[0].originFileObj;
            }
          }
        }

        data['esta_activa'] = data.esta_activa === 1;

        if (data['sigla'] !== undefined && data['sigla'] !== null && data['sigla'].length > 0)
          data['sigla'] = data['sigla'].replace('undefined', '').replace('null', '')

        if (data['sigla'] === undefined || data['sigla'] == null || data['sigla'].length === 0)
          delete data['sigla']

        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }

        setLoading(true);

        if (!organizationID) {
          response = await post(formData);
          data['organizacion'] = response.data.id;
          let partner = data['integrante'];
          if (partner) {
            if (partner.length >= 1) {
              for (let i = 0; i < partner.length; i++) {
                data['integrante'] = partner[i];
                response = await postOrganizationPartner(data);
              }
            }
          }
        } else {
          response = await put(organizationID, formData);
          data['organizacion'] = organizationID;
          let partner = data['integrante'];
          if (partner) {
            if (partner.length >= 1) {
              for (let i = 0; i < partner.length; i++) {
                data['integrante'] = partner[i];
                response = await postOrganizationPartner(data);
              }
            }
          }
        }

        setLoading(false);

        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });

          setTimeout(() => {
            history.push(`${INTERNAL_LINKS.ORGANIZATIONS}`);
          }, MESSAGES.TIME);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    };

    return (
      <Content>
        <Skeleton loading={isLoading}>
          {initialValues != null && (
            <OrganizationForm
              organizationForm={organizationForm}
              organization_id={organizationID}
              title={title}
              onFinish={save}
              initialValues={initialValues}
              organizations={organizations}
              organizationsTypes={organizationsTypes}
              identiesTypes={identiesTypes}
              loading={loading}
              showCLick={showCLick}
              titlRef={titlRef}
            />
          )}
        </Skeleton>
        <PdfViewer
          pdf={pdfUrl}
          onCancel={() => setShowPdf(false)}
          visible={showPdf}
        />
      </Content>
    );
}

OrganizationsForms.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any
}

export default OrganizationsForms;
