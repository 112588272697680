import httpClient from '../httpClient';

const postDocument = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/pdf_helpers/`, data, {
    headers: {
      'Content-Type': `multipart/form-data`,
    }
  });
};

const getDocuments = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/pdf_helpers/`);
};

const getDocumentByInitial = async (initial) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/pdf_helpers/?filter[sigla]=${initial}`);
};

const putDocuments = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/pdf_helpers/${id}/`, data, {
    headers: {
      'Content-Type': `multipart/form-data`,
    }
  });
};

export {
  postDocument,
  getDocuments,
  putDocuments,
  getDocumentByInitial,
};
