import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/project_category/`, data);
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/project_category/`);
};

const getByProject = async (projectId) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/project_category/?filter[proyecto]=${projectId}`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/project_category/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/project_category/${id}/`, data);
};

const patch = async (id) => {
  return await httpClient.patch(`${process.env.REACT_APP_API_HOST}/project_category/${id}`);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/project_category/${id}`);
};

export {
  post,
  getAll,
  get,
  put,
  patch,
  remove,
  getByProject,
};
