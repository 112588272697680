import httpClient from '../httpClient';

const filter = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = '',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  if (sortBy) sortBy = '&sort=' + sortBy;
  return await httpClient.get(
    `/mass_postcontractual/${query}&page[number]=${page}&page[size]=${pageSize}${sortBy}${searchBy}`,
  );
};

const get = async (id) => {
  return await httpClient.get(`/mass_postcontractual/${id}/`);
};

const remove = async (id) => {
  return await httpClient.delete(`/mass_postcontractual/${id}/`);
};

const getCounters = async (projectId) => {
  return await httpClient.get(
    `/mass_postcontractual/counters/?filter[proyecto]=${projectId}`,
  );
};

const massContractSearch = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/mass_pc_contract_search/export/?${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/mass_pc_contract_search/?page[number]=${page}&page[size]=${pageSize}&${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

const getTermsSelector = async (cargo) => {
  return await httpClient.get(`term_selector/?filter[cargo]=${cargo}`);
};

const getIdentifierSelector = async (term) => {
  return await httpClient.get(`mass_ctm_selector/?filter[termino]=${term}`);
};

const newInfo = async (project_id) => {
  return await httpClient.get(`/mass_postcontractual/${project_id}/new_info/`);
};

const selectAll = async (query) => {
  return await httpClient.get(`/mass_pc_contract_search/select_all/?${query}`);
};

const post = async (data) => {
  return await httpClient.post('/mass_postcontractual/', data);
};

const put = async (id, data) => {
  return await httpClient.put(`/mass_postcontractual/${id}/`, data);
};

const getContractors = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/mass_postcontractual/${query}/contractors_export/${
        search ? `?filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/mass_postcontractual/${query}/contractors/?page[number]=${page}&page[size]=${pageSize}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

export {
  filter,
  get,
  remove,
  getCounters,
  getTermsSelector,
  getIdentifierSelector,
  massContractSearch,
  newInfo,
  selectAll,
  post,
  put,
  getContractors,
};
