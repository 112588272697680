import React, { useState, useEffect, memo, useCallback } from 'react';
import { Table, Popconfirm, notification, Alert, Space } from 'antd';

import CustomButton from '../../../components/CustomButton';
import MESSAGES from '../../../enums/Messages';

import { getAllByTerm as getAllResponsibilitiesProducts } from '../../../api/module/term_pay_responsibility_product';
import { getAllByTerm, remove } from '../../../api/module/term_payment';

import './style.scss';
import AjaxTable from '../../../components/AjaxTable';
import { ajaxTable as filterAjaxTable } from '../../../api/module/term_payment';

const PayResponsibilityProductList = memo(
  ({ ids, handleEdit, totalValueTerm }) => {
    const [dataSource, setDataSource] = useState([]);
    const [responsibilitiesProducts, setResponsibilitiesProducts] = useState(
      [],
    );
    const [loading, setLoading] = useState(false);
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
      getDataSource();
      getResponsibilitiesProducts();
    }, []);

    const getResponsibilitiesProducts = async () => {
      const response = await getAllResponsibilitiesProducts(ids.term_id);
      setResponsibilitiesProducts(response.data.results);
    };

    const getDataSource = async () => {
      try {
        setLoading(true);
        const response = await getAllByTerm(ids.term_id);
        setDataSource(response.data.results);
        setLoading(false);
        let tmpTotal = 0;
        response.data.results.map((item) => {
          tmpTotal = tmpTotal + item.valor;
        });
        setTotalValue(tmpTotal);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
        setLoading(false);
      }
    };

    // Delete Object by Id of List
    const deleteDataSource = useCallback(
      async (id) => {
        try {
          const response = await remove(id);
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
              duration: 2,
            });
          }
          setDataSource(dataSource.filter((item) => item.id !== id));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (err) {
          console.log(err);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_DELETE_MSG,
            duration: 2,
          });
        }
      },
      [dataSource],
    );

    const columns = [
      {
        title: 'Pago No.',
        dataIndex: 'numero',
        key: 'numero',
      },
      {
        title: 'Valor de pago',
        dataIndex: 'valor',
        key: 'valor',
        render: (text) => {
          return `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
      {
        title: 'Acciones',
        key: 'acciones',
        fixed: 'right',
        width: 125,
        render: (_, record) => {
          return (
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <CustomButton
                block
                htmlType={'button'}
                text="Editar"
                onClick={() => handleEdit(record)}
              />
              <CustomButton
                block
                type={'default'}
                htmlType={'button'}
                danger
                text={
                  <Popconfirm
                    title={MESSAGES.DELETE_CONFIRM_MSG}
                    onConfirm={() => deleteDataSource(record.id)}
                  >
                    Eliminar
                  </Popconfirm>
                }
              />
            </Space>
          );
        },
      },
    ];

    const expandedRowRender = (record) => {
      const columns = [
        { title: 'Tipo', dataIndex: 'tipo', key: 'tipo' },
        { title: 'No.', dataIndex: 'tipo_codigo', key: 'tipo_codigo' },
        {
          title: 'Descripción',
          dataIndex: 'tipo_descripcion',
          key: 'tipo_descripcion',
        },
      ];

      const newDataSource = responsibilitiesProducts.filter(
        (item) => item.id_pago == record.id,
      );
      return (
        <Table
          columns={columns}
          rowKey="id"
          dataSource={newDataSource}
          pagination={false}
        />
      );
    };

    return (
      <>
        <Alert
          message="Información:"
          description={
            <>
              <ul>
                <li>
                  El valor total del contrato es: ${' '}
                  {totalValueTerm
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  .
                </li>
                <li>
                  Ha asignado: ${' '}
                  {totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </li>
                {totalValueTerm - totalValue > 0 && (
                  <li>
                    Valor pendiente por asignar: ${' '}
                    {(totalValueTerm - totalValue)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    .
                  </li>
                )}
                {totalValueTerm - totalValue < 0 && (
                  <li>
                    Ha sobrepasado el valor total del contrato. Por favor,
                    verifique los datos.
                  </li>
                )}
              </ul>
            </>
          }
          type={
            totalValueTerm - totalValue > 0
              ? 'success'
              : totalValueTerm - totalValue < 0
              ? 'error'
              : 'info'
          }
        />
        <AjaxTable
          columns={columns}
          searchInput
          tableTitle={'Listado de pagos'}
          expandedRowRender={{ expandedRowRender }}
          endpoint={filterAjaxTable}
          query={'/?filter[termino]=' + ids.term_id}
        />
      </>
    );
  },
);
export default PayResponsibilityProductList;
