import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/term_document_contract_document_email/`,
    data,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_document_contract_document_email/${id}`,
  );
};

const getAllByValidation = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_document_contract_document_email/?filter[validacion_documentos_contractuales]=${id}`,
  );
};

export { post, get, getAllByValidation };
