import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Row, Col, Input } from 'antd';

import { getAll as getCountries } from '../../api/module/countries';
import { getByCountry } from '../../api/module/states';
import {
  getByState,
  getByCountry as getCitiesByCountry,
} from '../../api/module/cities';

const { Option } = Select;

const CountryStateCity = ({
  onChange,
  required,
  country,
  state,
  city,
  countryKey,
  stateKey,
  cityKey,
  countryLabel,
  stateLabel,
  cityLabel,
  disabled,
}) => {
  const [countryOptions, setCountryOptions] = useState(null);
  const [countrySelect, setCountrySelect] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(async () => {
    await getCountriesOptions();
    setCountrySelect(country);
    if (country != null) {
      if (country !== 45) {
        await getCityByCountryOptions(country);
      } else {
        await getStateOptions(country);
      }
      if (state != null) {
        await getCityOptions(state);
      }
    }
  }, []);

  const getCountryName = () => {
    if (countryOptions != null && country != null) {
      let dataName = countryOptions.filter((item) => country === item.id);
      if (dataName.length > 0) {
        return dataName[0].nombre;
      }
    }
  };

  const getStateName = () => {
    if (stateOptions != null && state != null) {
      let dataName = stateOptions.filter((item) => state === item.id);
      if (dataName.length > 0) {
        return dataName[0].nombre;
      }
    }
  };

  const getCityName = () => {
    if (cityOptions != null && city != null) {
      let dataName = cityOptions.filter((item) => city === item.id);
      if (dataName.length > 0) {
        return dataName[0].nombre;
      }
    }
  };

  const onChangeCountry = async (value) => {
    setCityOptions([]);
    setStateOptions([]);
    setCountrySelect(value);
    await getStateOptions(value);

    if (value !== 45) {
      await getCityByCountryOptions(value);
    }
  };

  const onChangeState = async (value) => {
    setCityOptions([]);
    await getCityOptions(value);
  };

  const onChangeCity = (value) => {
    // onChange([country, state, city]);
  };

  const getCountriesOptions = async () => {
    const response = await getCountries();
    setCountryOptions(response.data.results);
  };

  const getStateOptions = async (countryId) => {
    const response = await getByCountry(countryId);
    setStateOptions(response.data.results);
  };

  const getCityOptions = async (stateId) => {
    const response = await getByState(stateId);
    setCityOptions(response.data.results);
  };

  const getCityByCountryOptions = async (countryId) => {
    const response = await getCitiesByCountry(countryId);
    setCityOptions(response.data.results);
  };

  return (
    disabled ? (
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item label={countryLabel}>
            <Input disabled value={getCountryName()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={stateLabel}>
            <Input disabled value={getStateName()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={cityLabel}>
            <Input disabled value={getCityName()} />
          </Form.Item>
        </Col>
      </Row>
    ) : (
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            label={countryLabel}
            name={countryKey}
            rules={[
              {
                required: required,
              },
            ]}
          >
            <Select
              allowClear
              disabled={disabled}
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                onChangeCountry(value);
              }}
              placeholder="Seleccione País"
            >
              {countryOptions != null &&
                countryOptions.map((item) => (
                  <Option key={`country-key-${item.id}`} value={item.id}>
                    {item.nombre}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {countrySelect === 45 && (
          <Col span={8}>
            <Form.Item
              label={stateLabel}
              name={stateKey}
              rules={[
                {
                  required: required,
                },
              ]}
            >
              <Select
                disabled={disabled}
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  onChangeState(value);
                }}
                placeholder="Seleccione Estado"
              >
                {stateOptions.map((item) => (
                  <Option key={`state-key-${item.id}`} value={item.id}>
                    {item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            label={cityLabel}
            name={cityKey}
            rules={[
              {
                required: required,
              },
            ]}
          >
            <Select
              disabled={disabled}
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                onChangeCity(value);
              }}
              placeholder="Seleccione Ciudad"
            >
              {cityOptions.map((item) => (
                <Option key={`city-key-${item.id}`} value={item.id}>
                  {item.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    )
  );
};
CountryStateCity.propTypes = {
  country: PropTypes.number,
  state: PropTypes.number,
  city: PropTypes.number,
  countryKey: PropTypes.string,
  stateKey: PropTypes.string,
  cityKey: PropTypes.string,
  countryLabel: PropTypes.string,
  stateLabel: PropTypes.string,
  cityLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

CountryStateCity.defaultProps = {
  country: null,
  state: null,
  city: null,
  disabled: false,
};

export default CountryStateCity;
