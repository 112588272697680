import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Typography, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import PdfViewer from '../../../components/PdfViewer';
import AjaxTable from '../../../components/AjaxTable';
import CustomButton from '../../../components/CustomButton'; //CARL20220927
import { get } from '../../../api/module/term_resume_contract';
import { getAllByContract } from '../../../api/module/term_resume_validation';
import { get as getDocumentUrl } from '../../../api/module/document_repository';
import {
  filter as getModificationsByContract,
  insuranceTable,
  insuranceDocs,
  getInsuranceDoc,
} from '../../../api/module/ctm_signed';
import { approvedTable as pcTable } from '../../../api/module/postcontractual';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import 'moment/locale/es';

const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const breadcrumbItems = [
  {
    text: 'Búsqueda de Documentos Contractuales',
    to: `${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}`,
  },
  {
    text: 'Documentos de contrato',
  },
];

const InsuranceChildTable = ({ record, columns }) => {
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    async function fetchData() {
      setDataSource();
      try {
        const res = await insuranceDocs(record.id);
        setDataSource(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [record.id]);

  return (
    <Table
      scroll={{ x: 'max-content' }}
      loading={!dataSource}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );
};

const ResumeContractSearchDetail = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const columns = [
    {
      title: 'Tipo Documento',
      dataIndex: 'plantilla_nombre_completo',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <Button
            type="default"
            onClick={async () => {
              const response = await getDocumentUrl(
                record.documento_firmado_nombre,
              );
              setPdfUrl(response.data.url);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  const modColumns = [
    {
      title: 'Nombre',
      dataIndex: 'numero',
      render: (text) => (
        <b>Modificación contractual {text.toString().padStart(3, '0')}</b>
      ),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      render: (_, record) =>
        record.estado_firma === 4 ? (
          <div style={{ textAlign: 'center' }}>
            <Button type="primary">Ver</Button>
          </div>
        ) : (
          <>
            <Paragraph>
              El proceso de modificación contractual esta en curso
            </Paragraph>
            <Paragraph strong>
              Recargue el listado mínimo pasados 5 minutos
            </Paragraph>
          </>
        ),
    },
  ];

  const modChildColumns = [
    {
      title: 'Tipo Documento',
      dataIndex: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            type="default"
            onClick={async () => {
              const response = await getDocumentUrl(record.documento);
              setPdfUrl(response.data.url);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  const insuranceColumns = [
    {
      title: 'Nombre',
      dataIndex: 'num',
      render: (t) =>
        t ? (
          <b>Modificación contractual {t.toString().padStart(3, '0')}</b>
        ) : (
          <b>Contrato</b>
        ),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}>
          <Button type="primary">Ver</Button>
        </div>
      ),
    },
  ];

  const insuranceChildColumns = [
    {
      title: 'Tipo de documento',
      dataIndex: 'created_at',
      render: (t, record) =>
        `${record.tipo === 0 ? 'Póliza' : 'Recibo de pago'} ${moment(t).format(
          'DD MMMM YYYY h:mm a',
        )}`,
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      fixed: 'right',
      render: (t, record) => {
        return (
          <Button
            type="default"
            onClick={async () => {
              const response = await getInsuranceDoc(t, record.tipo);
              setPdfUrl(response.data.url);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  const pcColumns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'documento',
      render: (text) => {
        return (
          <Button
            type="default"
            onClick={async () => {
              const response = await getDocumentUrl(text);
              setPdfUrl(response.data.url);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getContractInformation();
  }, []);

  const getContractInformation = async () => {
    try {
      setLoading(true);
      const response = await get(match.params.id);
      setContract(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const back = () => {
    if (match.params.tercero)
      history.push(
        `${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-person/${match.params.tercero}`,
      );
    else
      history.push(
        `${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}/0/${match.params.proyecto}/${
          match.params.cargo != undefined ? match.params.cargo : ''
        }`,
      );
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />

      <div className="box-title">
        <Title>DOCUMENTOS CONTRACTUALES</Title>
      </div>

      {loading === true ? (
        <div className="spin-container">
          <Spin indicator={antIcon}></Spin>
        </div>
      ) : (
        <>
          {contract && (
            <Descriptions bordered={true} column={2}>
              <Descriptions.Item
                label="Nombre completo / Razón social"
                span={2}
              >
                {contract.tercero.nombre_completo}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo de documento">
                {contract.tercero.tipo_identificacion_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Número de documento">
                {contract.tercero.numero_identificacion}
              </Descriptions.Item>
              <Descriptions.Item label="Proyecto" span={2}>
                {contract.termino.proyecto_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Nro. Contrato">
                {contract.numero_contrato}
              </Descriptions.Item>
              <Descriptions.Item label="Cargo">
                {contract.termino.cargo_nombre}
              </Descriptions.Item>
            </Descriptions>
          )}
          <br></br>
          <br></br>

          <Title level={3}>DOCUMENTOS DEL CONTRATO INICIAL</Title>

          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              return await getAllByContract(contract.id);
            }}
            columns={columns}
          ></AjaxTable>

          <Title level={3}>DOCUMENTOS MODIFICACIONES CONTRACTUALES</Title>

          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              return await getModificationsByContract(
                contract.id,
                page,
                pageSize,
              );
            }}
            columns={modColumns}
            expandedRowRender={{
              expandedRowRender: (record) => {
                return record.estado_firma === 4 ? (
                  <Table
                    scroll={{ x: 'max-content' }}
                    dataSource={record.document_validations}
                    columns={modChildColumns}
                    rowKey="id"
                    pagination={false}
                  />
                ) : (
                  <>
                    <Paragraph>
                      El proceso de modificación contractual esta en curso
                    </Paragraph>
                    <Paragraph strong>
                      Recargue el listado mínimo pasados 5 minutos
                    </Paragraph>
                  </>
                );
              },
              expandRowByClick: true,
            }}
            reloadButton
          ></AjaxTable>
          <br />
          <Title level={3}>PÓLIZAS DEL PROCESO CONTRACTUAL</Title>
          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              return await insuranceTable(contract.id, page, pageSize);
            }}
            columns={insuranceColumns}
            expandedRowRender={{
              expandedRowRender: (record) => (
                <InsuranceChildTable
                  record={record}
                  columns={insuranceChildColumns}
                />
              ),
              expandRowByClick: true,
            }}
            reloadButton
          />
          <br />
          <Title level={3}>TERMINACIÓN Y LIQUIDACIÓN CONTRACTUAL</Title>
          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              return await pcTable(contract.id, page, pageSize);
            }}
            columns={pcColumns}
            reloadButton
          />
          <CustomButton
            type={'default'}
            className={'back-button'}
            htmlType="button"
            onClick={() => back()}
            text="Volver"
          />
          <PdfViewer
            pdf={pdfUrl}
            onCancel={() => {
              setPdfUrl(null);
            }}
            visible={pdfUrl}
          />
        </>
      )}
    </>
  );
};

export default ResumeContractSearchDetail;
