import React from 'react';
import { Descriptions, Space } from 'antd';

import './style.scss';
import CustomButton from '../../components/CustomButton';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';

const AnnouncementDetailsForm = ({
  initialValues,
  announcementId,
  rejected = false,
}) => {
  return (
    <Descriptions
      title="Información de la convocatoria"
      bordered
      column={1}
    >
      <Descriptions.Item label="Organización">
        {initialValues.organizacion_nombre}
        {initialValues.organizacion_sigla &&
          ` - ${initialValues.organizacion_sigla}`}
      </Descriptions.Item>
      <Descriptions.Item label="Proyecto, licitación, concurso u otro">
        {initialValues.proyecto_nombre
          ? initialValues.proyecto_nombre
          : initialValues.nombre_proyecto}
      </Descriptions.Item>
      <Descriptions.Item label="Número de convocatoria">
        {initialValues.numero}
      </Descriptions.Item>
      <Descriptions.Item label="Nombre de la convocatoria">
        {initialValues.nombre}
      </Descriptions.Item>
      <Descriptions.Item label="Tipo de convocatoria">
        {initialValues.tipo ? 'Abierta' : 'Cerrada'}
      </Descriptions.Item>
      {rejected !== false && (
        <Descriptions.Item label="Rechazos">
          <Space align={rejected > 0 ? 'baseline' : 'end'}>
            {rejected}
            {rejected > 0 ? (
              <CustomButton
                text={
                  <Link
                    to={`${INTERNAL_LINKS.ANNOUNCEMENT_REJECTED}/${announcementId}`}
                  >
                    Ver rechazos
                  </Link>
                }
              />
            ) : null}
          </Space>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default AnnouncementDetailsForm;
