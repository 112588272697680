import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { notification, Skeleton } from 'antd';

import { useQuery, IsRole } from '../../../utils/helpers/helpers';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import ExperiencieIcfesForm from '../../../containers/ExperiencieIcfesForm/index';
import PdfViewer from '../../../components/PdfViewer';

import { put, post, get } from '../../../api/module/resume_experience_icfes';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const ExperiencieIcfes = ({ history, match }) => {
  const [terceroID] = useState(parseInt(match.params.tercero_id));

  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const ids = {
    person_id: terceroID,
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };

  const [coordinator] = useState(IsRole(2));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));

  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [otrosProcesoIcfes, setOtrosProcesoIcfes] = useState(false);
  const [otrosRolIcfes, setOtrosRolIcfes] = useState(false);
  const [semestre, setSemestre] = useState(0);
  const icfesExperienceRef = useRef(null);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [document, setDocument] = useState(true);

  const showClick = async (documento_nombre) => {
    setIsLoadingDoc(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    setPdfUrl(response.data.url);
    setShowPdf(true);
    setIsLoadingDoc(false);
  };

  useEffect(() => {
    handleGetExperienceIcfes();
  }, []);

  const handleGetExperienceIcfes = async () => {
    try {
      if (match.params.id) {
        let response = await get(match.params.id);
        let anyo = response.data.anyo;
        response.data.anyo = moment(anyo.toString());
        if (response.data.documento_nombre) {
          setDocument(false);
        }
        setSemestre(response.data.semestre);
        setInitialValues(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setInitialValues({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (data) => {
    try {
      if (data.certificacion != undefined) {
        if (data.certificacion.fileList.length > 0) {
          if (data.certificacion.fileList[0].type == 'application/pdf') {
            data.certificacion = data.certificacion.fileList[0].originFileObj;
            save(data);
          } else {
            notification.error({
              message: MESSAGES.ERROR_FILE_PDF,
              description: MESSAGES.DESC_ERROR_FILE_PDF,
              duration: 3,
            });
          }
        } else {
          save(data);
        }
      } else {
        save(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const save = async (data) => {
    setIsSave(true);
    let response;
    try {
      if (data.proceso_icfes.hasOwnProperty('value')) {
        data.proceso_icfes = data.proceso_icfes.value;
      }
      if (data.rol_icfes.hasOwnProperty('value')) {
        data.rol_icfes = data.rol_icfes.value;
      }
      data.anyo = parseInt(data['anyo'].format('YYYY'));
      data.tercero = parseInt(match.params.tercero_id);

      const formData = new FormData();
      formData.append('certificacion', data);

      for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
          formData.append(prop, data[prop]);
        }
      }

      if (match.params.id) {
        response = await put(match.params.id, formData);
      } else {
        response = await post(formData);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${terceroID}`, {
            icfesExperienceRef: icfesExperienceRef,
          });
        }, MESSAGES.TIME);
      }
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += ` ${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
    }
    setIsSave(false);
  };

  const back = () => {
    history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${terceroID}`, {
      icfesExperienceRef: icfesExperienceRef,
    });
  };

  return (
    <>
      <Skeleton loading={isLoading}>
        <ExperiencieIcfesForm
          ids={ids}
          back={back}
          onFinish={onFinish}
          otrosProcesoIcfes={otrosProcesoIcfes}
          setOtrosProcesoIcfes={setOtrosProcesoIcfes}
          otrosRolIcfes={otrosRolIcfes}
          initialValues={initialValues}
          setOtrosRolIcfes={setOtrosRolIcfes}
          semestre={semestre}
          isSave={isSave}
          showClick={showClick}
          document={document}
          isLoadingDoc={isLoadingDoc}
        />
      </Skeleton>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
};

export default ExperiencieIcfes;
