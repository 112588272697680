import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  notification,
  Form,
  Space,
  Row,
  Col,
  Input,
  Typography,
  Card,
  Skeleton,
  Select,
  Popconfirm,
} from 'antd';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import CustomButton from '../../../components/CustomButton';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  getModificationDocuments,
  patchRegisterDocs,
  getCurrentDocuments,
} from '../../../api/module/request_mod_documents';
import { IsRole } from '../../../utils/helpers/helpers';
import '../Form/style.scss';

const { Title } = Typography;

const ModificationDocuments = ({ onCancel = () => {} }) => {
  var { modification_id } = useParams();
  const [projectId, setPorjectId] = useState(0);
  const [isLegal] = useState(IsRole(3));
  const [formDocumentsModifieds] = Form.useForm();
  const [formDocumentsCurrent] = Form.useForm();
  const [errors, setErrors] = useState({});

  const [docsTemplates, setDocsTemplates] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [docsModified, setDocsModified] = useState(null);
  const [docsCurrent, setDocsCurrent] = useState(null);
  const [loadingFormRequest, setLoadingFormRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCur, setLoadingCur] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasLoadedMod, setHasLoadedMod] = useState(false);
  let history = useHistory();

  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${projectId}/${modification_id}`,
    },

    {
      text: 'Modificacion Documentos Adicionales',
    },
  ];

  useEffect(() => {
    async function fetchDataInfo() {
      setLoading(true);
      if (modification_id) {
        const response = await getModificationDocuments(modification_id);
        if (response) {
          let resInfo = response.data.info ? response.data.info : {};
          let resTemplates = response.data.templates
            ? response.data.templates
            : [];
          let resModified = response.data.docsModified
            ? response.data.docsModified
            : [];
          let justification = response.data.justification
            ? response.data.justification
            : '';
          resInfo['justification'] = justification;
          const restTempl = resTemplates.map((templ) => {
            if (templ.codigo_plantilla != null) {
              return {
                ...templ,
                nombre_plantilla:
                  templ.codigo_plantilla + ' - ' + templ.nombre_plantilla,
              };
            }
            return templ;
          });
          setDocsModified(resModified);
          setDocsTemplates(restTempl);
          setDataInfo(resInfo);
          setHasLoaded(true);
          setLoading(false);
        }
      }
    }

    async function fetchDataCurrent() {
      setLoadingCur(true);
      if (modification_id) {
        const response = await getCurrentDocuments(modification_id);
        if (response) {
          let resCurrent = response.data ? response.data : [];
          setDocsCurrent(resCurrent);
          setHasLoadedMod(true);
          setLoadingCur(false);
        }
      }
    }
    fetchDataInfo();
    fetchDataCurrent();
  }, [modification_id]);

  useEffect(() => {
    if (hasLoadedMod) {
      let options = docsCurrent ? docsCurrent : [];
      options = options.map((item) => item.id);
      formDocumentsCurrent.setFieldsValue({
        documents_current: options,
      });
    }

    if (hasLoaded) {
      let options = docsModified ? docsModified : [];
      formDocumentsModifieds.setFieldsValue({
        documents_modifieds: options,
        justification: dataInfo.justification,
      });
      setPorjectId(dataInfo.proyecto_id);
    }
  }, [
    hasLoadedMod,
    hasLoaded,
    docsCurrent,
    docsModified,
    formDocumentsCurrent,
    formDocumentsModifieds,
    dataInfo,
  ]);

  const validateFormRequest = async () => {
    try {
      const values = await formDocumentsModifieds.validateFields();
      setErrors({});
      sendFormModified(values);
    } catch (err) {
      setErrors(
        err.errorFields.reduce(
          (acc, curr) => ({ ...acc, [curr.name[0]]: curr.errors[0] }),
          {},
        ),
      );
    }
  };

  const atentionOk = () => {
    validateFormRequest();
  };

  const sendFormModified = async (inputs) => {
    setLoadingFormRequest(true);
    const templates = inputs.documents_modifieds
      ? inputs.documents_modifieds
      : [];
    const justification = inputs.justification ? inputs.justification : '';

    const data = {
      templates: templates,
      justification: justification,
    };

    try {
      const response = await patchRegisterDocs(modification_id, data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: 'Módulo actualizado correctamente.',
          duration: 2,
        });
      }
      setLoadingFormRequest(false);
      setTimeout(() => {
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
        );
      }, MESSAGES.TIME);
    } catch (error) {
      setLoadingFormRequest(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  //const hasErrors = Object.keys(errors).some((key) => errors[key]);

  const partialDocumentsRequest = (
    <>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Card
          size={'middle'}
          bordered={false}
          title={
            <Title level={4}>Documentos complementarios a solicitar</Title>
          }
          className="fade-in"
        >
          <Form
            form={formDocumentsModifieds}
            name="form_documents_request"
            layout="vertical"
            autoComplete="off"
          >
            <Skeleton loading={loading} active paragraph={true}>
              <Form.Item
                name="documents_modifieds"
                rules={[
                  {
                    required: true,
                    message: 'Seleccione el documento',
                  },
                ]}
                validateStatus={errors.justification && 'error'}
                help={errors.justification}
                className="fade-in"
              >
                <Select mode="multiple" allowClear>
                  {docsTemplates.map((item) => (
                    <Select.Option
                      key={`document-types-key-${item.id}`}
                      value={item.id}
                    >
                      {item.nombre_plantilla}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Justificación"
                name="justification"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa la Justificación',
                  },
                ]}
                validateStatus={errors.justification && 'error'}
                help={errors.justification}
                className="fade-in"
              >
                <Input.TextArea allowClear />
              </Form.Item>

              <Space>
                <CustomButton
                  htmlType="button"
                  className={'back-button'}
                  type="default"
                  text={
                    <Link
                      to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`}
                    >
                      Volver
                    </Link>
                  }
                />
                <CustomButton
                  loading={loadingFormRequest}
                  text={
                    <Popconfirm
                      placement="topLeft"
                      title="¿Está seguro(a) de guardar los ajustes realizados?"
                      okText="Sí"
                      cancelText="No"
                      onConfirm={atentionOk}
                    >
                      {' '}
                      Guardar Modificación
                    </Popconfirm>
                  }
                />
              </Space>
            </Skeleton>
          </Form>
        </Card>
      </Space>
    </>
  );

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title className={'text-uppercase'} level={2}>
          MODIFICACIÓN DOCUMENTOS ADICIONALES
        </Title>
      </div>
      <Row align="middle" gutter={8}>
        <Col flex="auto">
          <Space direction={'vertical'} style={{ width: '100%' }}>
            {dataInfo && (
              <Skeleton loading={loading} active>
                <Descriptions
                  layout="horizontal"
                  column={2}
                  bordered
                  labelStyle={{ maxWidth: '5rem' }}
                  className="fade-in"
                >
                  <Descriptions.Item label="Contratista / Proveedor" span={2}>
                    <Row align="middle">
                      <Col flex="auto">{dataInfo.contratista_nombre}</Col>
                      <Col flex="0 0">
                        <CustomButton
                          htmlType="button"
                          text={
                            <Link
                              to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${dataInfo.id}/${dataInfo.proyecto_id}/${dataInfo.cargo_id}/`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver históricos contractuales
                            </Link>
                          }
                        />
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label="Cargo/Rol" span={2}>
                    {dataInfo.cargo_nombre}
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            )}
          </Space>

          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>7. Documentos adicionales</Title>}
              className="fade-in"
            >
              <p>Documentos complementarios VIGENTES</p>
              <Form
                form={formDocumentsCurrent}
                name="form_documents_current"
                layout="vertical"
                autoComplete="off"
                className="fade-in"
              >
                {dataInfo && (
                  <Skeleton loading={loadingCur} active paragraph={false}>
                    <Form.Item name="documents_current">
                      <Select mode="multiple" allowClear disabled>
                        {docsTemplates.map((item) => (
                          <Select.Option
                            key={`document-types-key-${item.id}`}
                            value={item.id}
                          >
                            {item.nombre_plantilla}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Skeleton>
                )}
              </Form>
            </Card>
          </Space>

          {partialDocumentsRequest}
        </Col>
      </Row>
    </>
  );
};

export default ModificationDocuments;
