import React, { useState, useEffect } from 'react';
import {
  Table,
  Typography,
  Button,
  notification,
  Modal,
  Space,
  Form,
  Empty,
  Layout,
} from 'antd';

import { post } from '../../api/module/contractor_search';
import {
  getAll,
  postMultiple as postEmployees,
  put,
} from '../../api/module/employees';

import WorkTeamSearchForm from '../../containers/WorkTeamSearchForm';
import EmployeeRolForm from '../../containers/EmployeRolForm';

import MESSAGES from '../../enums/Messages';

import './style.scss';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import SpinLoad from '../../components/SpinLoad';
import Paragraph from 'antd/es/typography/Paragraph';
import { CustomButton } from '../../components';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Content } = Layout;

const EmployeesPage = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [knowledgeArea] = useState(undefined);
  const [emptyText, setEmptyText] = useState('No hay datos');
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataSourceEmployees, setDataSourceEmployees] = useState(false);
  const [formEmployeeRol] = Form.useForm();
  const [selectedId, setSelectedId] = useState(false);

  const columns = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'num_documento',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
  ];

  const columnsEmployees = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 150,
      render: (_, record) => {
        return (
          <Space direction={'vertical'}>
            <Button
              block
              onClick={() => {
                setSelectedId(record.id);
                formEmployeeRol.setFieldsValue({
                  cargo: record.cargo,
                  depende_de: record.depende_de,
                  le_reportan: record.le_reportan,
                });
                setShowModal(true);
              }}
              type="primary"
            >
              Agregar cargo
            </Button>
            {record.estado_interventoria === true ? (
              <Button
                block
                loading={loadingUpdate}
                onClick={() => {
                  updateEmployee(record.id, { estado_interventoria: 0 });
                }}
                type="default"
                danger
              >
                Inactivar
              </Button>
            ) : (
              <Button
                block
                loading={loadingUpdate}
                type={'default'}
                className={'ant-btn-info'}
                onClick={() => {
                  updateEmployee(record.id, { estado_interventoria: 1 });
                }}
              >
                Activar
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const getAllEmployees = async () => {
    try {
      setLoadingEmployees(true);
      const response = await getAll();
      setDataSourceEmployees(response.data.results);
      setLoadingEmployees(false);
    } catch (err) {
      setLoadingEmployees(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  const onFinish = async (data) => {
    try {
      if (data.documento === '') {
        data.documento = undefined;
      }
      if (data.nombre === '') {
        data.nombre = undefined;
      }
      if (data.email === '') {
        data.email = undefined;
      }
      if (data.tipo_cargo === '') {
        data.tipo_cargo = undefined;
      }
      if (data.meses_experiencia === '') {
        data.meses_experiencia = undefined;
      }
      if (data.pregrado != null) {
        data.nivel_educativo = 7;
        delete data.pregrado;
      }
      if (data.especializacion != null) {
        data.nivel_educativo = 8;
        delete data.especializacion;
      }
      if (data.maestria != null) {
        data.nivel_educativo = 9;
        delete data.maestria;
      }
      if (data.doctorado != null) {
        data.nivel_educativo = 10;
        delete data.doctorado;
      }
      if (data.posdoctorado != null) {
        data.nivel_educativo = 11;
        delete data.posdoctorado;
      }
      data.area_conocimiento = knowledgeArea;
      data.tipo_persona = 0;
      data.termino = 0;
      setLoading(true);
      const response = await post(data);
      setLoading(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
        setEmptyText(
          'No se han encontrado elementos para la búsqueda realizada.',
        );
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const saveEmployees = async () => {
    setSaveLoading(true);
    try {
      const response = await postEmployees({ terceros: selectedRows });
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        getAllEmployees();
      }
      setSaveLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setSaveLoading(false);
    }
  };

  const updateEmployee = async (employee_id, data) => {
    setLoadingUpdate(true);
    try {
      const response = await put(employee_id, data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        getAllEmployees();
        formEmployeeRol.resetFields();
        setShowModal(false);
      }
      setLoadingUpdate(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoadingUpdate(false);
    }
  };

  const breadcrumbItems = [
    {
      text: 'Módulo de empleados de Corpoeducación',
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de empleados de Corpoeducación
      </Title>
      <WorkTeamSearchForm
        onlyIdentificationSection={true}
        onFinish={onFinish}
      />
      <SpinLoad loading={loading}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={emptyText}
              />
            ),
          }}
          dataSource={dataSource}
          rowKey="tercero"
          scroll={{ x: 1500 }}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Contratistas encontrados
            </Paragraph>
          )}
        />
      </SpinLoad>
      {dataSource.length > 0 && (
        <CustomButton
          type={'default'}
          className={'ant-btn-info'}
          loading={saveLoading}
          onClick={saveEmployees}
          text={
            <>
              <PlusOutlined /> Agregar empleados
            </>
          }
        />
      )}
      <SpinLoad loading={loadingEmployees}>
        <Table
          style={{ width: '100%' }}
          scroll={{ x: 1500 }}
          columns={columnsEmployees}
          locale={{ emptyText: emptyText }}
          dataSource={dataSourceEmployees}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Contratistas agregados
            </Paragraph>
          )}
        />
      </SpinLoad>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Asignación de cargo a empleado
          </Title>
        }
        okText="Guardar"
        okCancel="Cancelar"
        onOk={() => {
          formEmployeeRol.submit();
        }}
        okButtonProps={{ loading: loadingUpdate }}
        onCancel={() => {
          setShowModal(false);
        }}
        visible={showModal}
      >
        <EmployeeRolForm
          onFinish={(data) => {
            updateEmployee(selectedId, data);
          }}
          form={formEmployeeRol}
        />
      </Modal>
    </Content>
  );
};

export default EmployeesPage;
