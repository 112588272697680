import httpClient from '../httpClient';

const getModificationBudget = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/modification_budget/${id}/`,
  );
};

const patchUpdateBudget = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/modification_budget/${id}/`,
    data,
  );
};

export { getModificationBudget, patchUpdateBudget };
