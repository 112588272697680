import React, { useState, useEffect } from 'react';
import { Select, Skeleton } from 'antd';
import PropTypes from 'prop-types';

import { getTermsByRole } from '../../api/module/terms';

const SelectTermByUniqueID = ({
  className = '',
  onChange = () => {},
  onChangeData = null,
  onLoad = null,
  cargo_id = undefined,
  term = undefined,
  disabled,
  style = {},
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(term);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (data) setValue(undefined);
      let res = await getTermsByRole(cargo_id);
      setData(res.data.results);
      if (onLoad) {
        onLoad(
          term ? res.data.results.find((item) => item.id === term) : undefined,
        );
      }
      setIsLoading(false);
    }
    fetchData();
  }, [cargo_id]);

  const onSelfChange = (value) => {
    if (!onChangeData) onChange(value);
    else onChangeData(value ? data.find((item) => item.id === value) : null);
    setValue(value);
  };

  return (
    <>
      <Skeleton loading={isLoading} active paragraph={false} style={style}>
        <Select
          placeholder="Elija el identificador único del termino de referencia..."
          className={className}
          onChange={onSelfChange}
          loading={isLoading}
          value={value}
          disabled={disabled}
          allowClear
          showSearch
          style={style}
          key={cargo_id}
        >
          {data &&
            data.map((item) => (
              <Select.Option
                key={`term-unique-id-key-${item.id}`}
                value={item.id}
              >
                {item.identificador_interno}
              </Select.Option>
            ))}
        </Select>
      </Skeleton>
    </>
  );
};

SelectTermByUniqueID.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeData: PropTypes.func,
  onLoad: PropTypes.func,
  cargo_id: PropTypes.number,
  term: PropTypes.number,
  style: PropTypes.object,
};

SelectTermByUniqueID.defaultProps = {
  className: '',
  disabled: false,
  onChange: () => {},
  cargo_id: undefined,
  style: {},
};

export default SelectTermByUniqueID;
