import React, {   useState, useEffect,  } from 'react';
import moment from 'moment';
import { Layout, Table, Typography,notification, } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';

import './style.scss';
import { CustomTooltip } from '../../components/CustomTooltip';
import Paragraph from 'antd/lib/typography/Paragraph';
import  {getAll,getExcel } from '../../api/module/reports';
import MESSAGES from '../../enums/Messages';


const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Reportes',
  },
];

export default function Reports({ history, match }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // GetData List
  const getDataSource = () => {
    try {
      setLoading(true);
      getAll().then((res) => {
        setDataSource(res.data.results);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getDataSource();
  }, []);


  const generarExcel = async (url,report_name) => {
    try {
      setDataLoading(true);
      let response = await  getExcel(`${process.env.REACT_APP_API_HOST}/reports/data?filter[vista]=${url}`);
      const file = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });
  
      //const fileURL = URL.createObjectURL(file);
     //window.open(fileURL, '_blank');
  
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download =report_name+'_'+ moment(new Date()).format('YYYYMMDD_HHmm')+'.xls';
      link.click();
      document.body.removeChild(link);
      setDataLoading(false);
  
  
    } catch (err) {
      setDataLoading(false);
      console.log(err);
    }
  };

  const columns = [
    {
      title: 'Reporte',
      key: 'nombre',
      dataIndex: 'nombre',
      align: 'Left',
      render: (_, record) => {
        if (record.descripcion !== undefined)
          return (
            <>
              {record.nombre}
              <CustomTooltip title={record.descripcion} />
            </>
          );
        return record.nombre;
      },
    },
    {
      title: 'Acción',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Generar"
            block
            className={'ant-btn-info'}
            onClick={() => {
              generarExcel(record.nombre_vista,record.nombre);
            }}
            htmlType="button"
            loading={dataLoading}
          />
  
        );
      },
    },
  ];
  
  const reports = [
    {
      id: 1,
      name: 'De que manera se puede saber el cargo rol del TDR que envié a revisar, en que estado esta?',
      name_btn: 'Generar',
      report_name: 'ListadoDatosIdentificacionProcesoSeleccionAplicantesConvocatoria',
      link:  `${process.env.REACT_APP_API_HOST}/reports/export`,
    },
    {
      id: 2,
      name: 'Como el lider o los gerentes pueden descargar el reporte de seguimiento de los asistentes/ validadores?',
      name_btn: 'Generar',
      report_name: 'ListadoFormacionAplicantesConvocatoria',
      link: `${process.env.REACT_APP_API_HOST}/reports/exportReporteFormacion`,
    },
    {
      id: 3,
      name: 'Reportes de contratos',
      name_btn: 'Generar',
      report_name: 'ListadoExperienciaAplicantesConvocatoria',
      link: `${process.env.REACT_APP_API_HOST}/reports/exportReporteExperiencia`,
    },
    {
      id: 4,
      name: 'Una base general de lo que se diligencia en el perfil (perfil, experiencia laboral, formación , otros)',
      name_btn: 'Generar',
      report_name: 'ListadoExperienciaIcfesAplicantesConvocatoria',
      link: `${process.env.REACT_APP_API_HOST}/reports/exportReporteExperienciaIcfes`,
    },
  ];




  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Reportes
      </Title>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de reportes
          </Paragraph>
        )}
      />
    </Content>
  );
}
