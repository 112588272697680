import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Select,
  notification,
  Input,
  Space,
  Descriptions,
  Layout,
  Alert,
  Skeleton,
  Card,
} from 'antd';

//Components
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AnnouncementValidationExperiencieForm from '../../../containers/AnnouncementPreselectionExperiencieForm/index';

// API modules
import { get as Announcement } from '../../../api/module/announcement';
import { getAll as Experiencias } from '../../../api/module/experience_areas';
import { getAll as Cargos } from '../../../api/module/job_type';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { get as getResume } from '../../../api/module/contractor';
import { filter } from '../../../api/module/resume_experience';
import { put, get } from '../../../api/module/announcement_preselection';

import './style.scss';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const AnnouncementValidationExperiencie = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [experiencie, setExperiencie] = useState(null);
  const [tipoCargo, setTipoCargo] = useState(null);
  const [areaExperiencia, setAreaExperiencia] = useState(null);
  const [name, setName] = useState('');

  const optionPreselection = [
    { id: 24, nombre: 'Pendiente' },
    { id: 25, nombre: 'Cumple' },
    { id: 26, nombre: 'No cumple' },
  ];

  const breadcrumbItems = [
    {
      text: 'Preselección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Prevalidación experiencia',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      initialValues.observacion_experiencia = data.observacion_experiencia;
      initialValues.estado_experiencia = data.estado_experiencia;
      const response = await put(match.params.preselection_id, initialValues);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const getExperiencias = async () => {
    const response = await Experiencias();
    setAreaExperiencia(response.data.results);
  };

  const getCargos = async () => {
    const response = await Cargos();
    setTipoCargo(response.data.results);
  };

  useEffect(async () => {
    setLoadingForm(true);
    await Promise.all([
      getExperiencias(),
      getCargos(),
      getName(),
      getAnnouncement(),
      getExperience(),
      getPreselection(),
    ]);
    setLoadingForm(false);
  }, []);

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(match.params.announcement_id);
      const responseCargo = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseCargo.data.cargo;
      response.data.estudios = responseCargo.data.estudios;
      response.data.tipo_experiencia_laboral =
        responseCargo.data.tipo_experiencia_laboral;
      response.data.tiempo_experiencia_laboral =
        responseCargo.data.tiempo_experiencia_laboral;
      setDataSource(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPreselection = async () => {
    try {
      const response = await get(match.params.preselection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const setExperiencias = (id) => {
    let data = areaExperiencia.filter((item) => item.id == id);
    return data[0].nombre;
  };

  const setCargos = (id) => {
    let data = tipoCargo.filter((item) => item.id == id);
    return data[0].nombre;
  };

  const getExperience = async () => {
    const response = await filter('?filter[tercero]=' + match.params.person_id);
    setExperiencie(response.data.results);
  };

  const tailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const showDocument = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );

    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Preselección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Prevalidación de experiencia</Title>}
        >
          <AnnouncementValidationExperiencieForm
            initialValues={dataSource}
            name={name}
            useDescriptions={true}
            descriptionsTitle="Información de convocatoria"
            descriptionsColumns={2}
          />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>{name}</Title>}
        >
          {experiencie != null && experiencie.length > 0
            ? experiencie.map((item, index) => (
                <Card
                  size={'small'}
                  bordered={false}
                  title={
                    <Title level={5} className={'title-default'}>
                      Experiencia No. {index + 1}
                    </Title>
                  }
                >
                  <Descriptions layout="vertical" column={3} bordered>
                    <Descriptions.Item label="Cargo">
                      {item.cargo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Area de experiencia">
                      {setExperiencias(item.area_experiencia)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo de cargo">
                      {setCargos(item.tipo_cargo)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estado">
                      {item.finalizado === false ? 'Actual' : 'Finalizado'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha de Ingreso">
                      {item.fecha_ingreso}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha de terminación">
                      {item.fecha_terminacion}
                    </Descriptions.Item>
                    <Descriptions.Item label="Meses de trabajo">
                      {item.meses.toFixed(2)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Certificado">
                      {item.documento_nombre ? (
                        <CustomButton
                          loading={loadingDocument}
                          onClick={() => {
                            showDocument(item.documento_nombre);
                          }}
                          text={'Ver documento'}
                        />
                      ) : (
                        <Text>No se encontró documento</Text>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              ))
            : <Paragraph type={"danger"}>Sin información</Paragraph>}
        </Card>
        {initialValues != null && (
          <Card size={'small'} bordered={false}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <Form.Item
                name="estado_experiencia"
                label="Estado preselección experiencia"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select disabled={initialValues.estado_tercero === 12}>
                  {optionPreselection.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...tailLayout}
                name="observacion_experiencia"
                label="Observación general"
              >
                <TextArea
                  rows={4}
                  disabled={initialValues.estado_tercero === 12}
                />
              </Form.Item>
              <Space>
                {initialValues.estado_tercero !== 12 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Card>
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementValidationExperiencie;
