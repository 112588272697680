import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Divider,
  Descriptions,
  Button,
  Skeleton,
  notification,
} from 'antd';
import moment from 'moment';

import CustomButton from '../../components/CustomButton';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import {
  getAdjustInfo as AnnouncementRol,
  notify,
} from '../../api/module/announcement_rol';
import { get as AnnouncementPreselection } from '../../api/module/announcement_preselection';
import { filter as AnnouncementPreselectionEmail } from '../../api/module/announcement_preselection_email';
import { get as AnnouncementSelection } from '../../api/module/announcement_selection';
import { filter as AnnouncementSelectionEmail } from '../../api/module/announcement_selection_email';

import './style.scss';

const { Title, Paragraph } = Typography;

const AnnouncementAdjustInformation = ({ history, match }) => {
  const [announcementRol, setAnnouncementRol] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [stageDataEmail, setStageDataEmail] = useState(null);
  const [stage] = useState(parseInt(match.params.stage));
  const [loading, setLoading] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(false);

  const breadcrumbItems = [
    {
      text: 'Convocatorias para ajustar información',
    },
  ];

  useEffect(() => {
    Promise.all([getAnnouncementRol(), getStageData(), getStageDataEmail()]);
    setLoading(false);
  }, []);

  const getAnnouncementRol = async () => {
    try {
      let response;
      response = await AnnouncementRol(match.params.announcement_rol_id);
      setAnnouncementRol(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getStageData = async () => {
    let response;
    if (stage === 1) {
      response = await AnnouncementPreselection(match.params.id);
    } else {
      response = await AnnouncementSelection(match.params.id);
    }
    setStageData(response.data);
  };

  const getStageDataEmail = async () => {
    let response;

    if (stage === 1) {
      response = await AnnouncementPreselectionEmail(
        `?filter[convocatoria_tercero]=${match.params.id}`,
      );
    } else {
      response = await AnnouncementSelectionEmail(
        `?filter[tercero]=${match.params.person_id}&filter[convocatoria_rol]=${match.params.announcement_rol_id}`,
      );
    }
    setStageDataEmail(response.data.results);
  };

  const notifyUser = async () => {
    setLoadingNotification(true);
    try {
      const response = await notify(match.params.announcement_rol_id, stage);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          setLoadingNotification(false);
          history.push(`/`);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoadingNotification(false);
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title>Convocatorias para ajustar información</Title>
      <Skeleton loading={loading} active>
        {announcementRol && (
          <Descriptions column={2} bordered>
            <Descriptions.Item
              key={`announcement-rol-1`}
              label="Número de convocatoria"
            >
              {announcementRol.convocatoria_numero}
            </Descriptions.Item>
            <Descriptions.Item
              key={`announcement-rol-2`}
              label="Nombre de convocatoria"
            >
              {announcementRol.convocatoria_nombre}
            </Descriptions.Item>
            <Descriptions.Item key={`announcement-rol-3`} label="Organización">
              {announcementRol.organizacion_nombre}
            </Descriptions.Item>
            <Descriptions.Item
              key={`announcement-rol-4`}
              label="Proyecto, licitación concurso u otro"
            >
              {announcementRol.nombre_proyecto}
            </Descriptions.Item>
            <Descriptions.Item key={`announcement-rol-5`} label="Cargo/Rol">
              {announcementRol.cargo}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Divider></Divider>

        <Descriptions column={1} bordered layout="vertical">
          <Descriptions.Item key={`announcement-rol-6`} label="Notificación">
            {stageDataEmail &&
              stageDataEmail.map((item, index) => {
                return (
                  <>
                    <Title level={5}>
                      Email No. {index + 1} - Enviado el:{' '}
                      {moment(item.created_at).format('DD MMMM YYYY h:mm a')}
                    </Title>
                    <div
                      key={`announcement-rol-1-${index}`}
                      dangerouslySetInnerHTML={{ __html: item.mensaje }}
                    ></div>
                    <Divider></Divider>
                  </>
                );
              })}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">
          <Title level={4}>Paso No. 1</Title>
        </Divider>
        <br></br>
        <div style={{ textAlign: 'center' }}>
          {stageData && (
            <Descriptions column={1} layout="vertical" bordered>
              <Descriptions.Item
                key={`announcement-rol-8`}
                labelStyle={{ fontWeight: 'bold' }}
                label={
                  <>
                    <Paragraph>
                      Para actualizar/ajustar siga las siguientes indicaciones:
                    </Paragraph>
                    <ul className="steps-list">
                      <li>
                        Hacer clic en el botón "Actualizar información
                        requerida".
                      </li>
                      <li>
                        Se abrirá en una nueva pestaña de su navegador su
                        perfil.
                      </li>
                      <li>
                        En esa nueva pestaña debe actualizar la información que
                        se le solicita.
                      </li>
                      <li>
                        Luego de actualizar y guardar, debe cerrar esa pestaña.
                      </li>
                      <li>Continúe al paso 2</li>
                    </ul>
                  </>
                }
              >
                {stageData.tercero_data.tipo_persona === 1 ? (
                  <Button type="primary">
                    <a
                      target="_blank"
                      href={`${INTERNAL_LINKS.NATURAL_PERSON}/${stageData.tercero}`}
                    >
                      Actualizar información requerida
                    </a>
                  </Button>
                ) : (
                  <Button type="primary">
                    <a
                      target="_blank"
                      href={`${INTERNAL_LINKS.LEGAL_PERSON}/${stageData.tercero}`}
                    >
                      Actualizar información requerida
                    </a>
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>
          )}
        </div>
        <Divider orientation="left">
          <Title level={4}>Paso No. 2</Title>
        </Divider>
        <Descriptions column={2} bordered>
          <Descriptions.Item
            key={`announcement-rol-9`}
            labelStyle={{ fontWeight: 'bold' }}
            label="Una vez actualizada la información indicada en el paso No. 1, notifique a la organización haciendo clic en el botón 'Notificar ajuste', de lo contrario no será tenido en cuenta"
          >
            <Button
              loading={loadingNotification}
              type="primary"
              onClick={() => {
                notifyUser();
              }}
            >
              Notificar ajuste
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Skeleton>
      <br></br>
      <br></br>
      <CustomButton type="default" danger text={<Link to="/">Volver</Link>} />
    </>
  );
};

export default AnnouncementAdjustInformation;
