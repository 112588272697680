import React, { memo } from 'react';

import {Row, Form, Col, Input, Typography, Card, Space} from 'antd';
import CustomButton from '../../components/CustomButton';
import { PersonSearchTable } from '../../components/PersonSearchTable';

const { Title } = Typography;

const RolesAssignmentSearchForm = memo(
  ({ dataSource, setDataSource, loading, onFinish, rowSelection }) => {
    const [form] = Form.useForm();

    return (
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={5}>Búsqueda por identificación</Title>}
      >
        <Form
          form={form}
          name="location_form"
          layout="vertical"
          className="mt-8"
          onFinish={onFinish}
        >
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item label="Nombre" name="nombre">
                <Input allowClear placeholder="Búsqueda por nombre" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Número de documento" name="documento">
                <Input
                  type="number"
                  allowClear
                  placeholder="Búsqueda por documento"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Correo electrónico" name="correo">
                <Input allowClear placeholder="Correo electrónico" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row justify="space-between">
            <Form.Item>
              <div className="box__btn-center">
                <CustomButton
                  htmlType="submit"
                  loading={loading}
                  text="Buscar"
                />
              </div>
            </Form.Item>
            <CustomButton
              htmlType="button"
              onClick={() => {
                form.resetFields();
              }}
              danger={true}
              text="Borrar filtros"
            />
          </Row> */}
          <Row gutter={12}>
            <Col span={12}>
              <Space>
                <CustomButton loading={loading} text="Buscar" />
                <CustomButton
                  htmlType="button"
                  onClick={() => {
                    form.resetFields();
                    setDataSource([]);
                  }}
                  type="default"
                  danger
                  text="Borrar filtros"
                />
              </Space>
            </Col>
          </Row>
        </Form>
        <PersonSearchTable
          dataSource={dataSource}
          loading={loading}
          rowSelection={rowSelection}
        />
      </Card>
    );
  },
);

export default RolesAssignmentSearchForm;
