import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Form, Input, Space, Row, Col, Layout } from 'antd';
import { Link } from 'react-router-dom';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Países',
    to: `${INTERNAL_LINKS.COUNTRIES}`,
  },
  {
    text: 'Formulario de Países',
  },
];

const validateMessages = {
  required: '${label} Es Requerido!',
  types: {
    nombre: '${label} No es un nombre Valido !',
    sigla: '${label} No es un sigla Valida!',
  },
};

const CountriesForm = ({ title, onFinish, initialValues, loading }) => {
  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Paragraph>
        A continuación, diligencie el formulario "{title}".
        <br />
        Recuerde que los campos obligatorios están marcados con asterisco (*).
      </Paragraph>
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nombre"
              label="Nombre del país"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Solo puede ingresar 100 caracteres!',
                    );
                  },
                }),
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="codigo"
              label="Código prefijo teléfonico del país"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Space>
              <CustomButton text="Guardar" loading={loading} />
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                text={<Link to={INTERNAL_LINKS.COUNTRIES}> Volver </Link>}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

CountriesForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};

CountriesForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
};
export default CountriesForm;
