import httpClient from '../httpClient';

const filter = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = '',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  if (sortBy) sortBy = '&sort=' + sortBy;
  return await httpClient.get(
    `/contract_modifications/${query}&page[number]=${page}&page[size]=${pageSize}${sortBy}${searchBy}`,
  );
};

const get = async (id) => {
  return await httpClient.get(`/contract_modifications/${id}/`);
};

const post = async (data) => {
  return await httpClient.post('/contract_modifications/', data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/contract_modifications/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/contract_modifications/${id}/`);
};

const getCounters = async (projectId) => {
  return await httpClient.get(
    `/contract_modifications/counters/?filter[proyecto]=${projectId}`,
  );
};

const getNewInfo = async (projectId) => {
  return await httpClient.get(`/contract_modifications/${projectId}/new_info/`);
};

const getDate = async (id) => {
  return await httpClient.get(`/contract_modifications/${id}/get_date/`);
};

const setEight = async (id, data) => {
  return await httpClient.patch(
    `/contract_modifications/${id}/set_eight/`,
    data,
  );
};

const contractSearch = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/contract_search/export/?${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/contract_search/?page[number]=${page}&page[size]=${pageSize}&${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

export {
  filter,
  get,
  post,
  patch,
  remove,
  getCounters,
  getNewInfo,
  contractSearch,
  getDate,
  setEight,
};
