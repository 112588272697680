import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';

import IdentityTypeForm from '../../../containers/IdentityTypeForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/identities_types';

const IdentitiesTypesForms = ({ history, match }) => {
  const [identityTypeID] = useState(
    parseInt(match.params.id),
  );
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(
    'Crear Tipo de documento de identificación',
  );
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      setLoading(true);

      if (!identityTypeID) {
        response = await post(data);
      } else {
        response = await put(identityTypeID, data);
      }

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.IDENTITIES_TYPES);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      if (identityTypeID) {
        get(identityTypeID).then((res) => setData(res.data));
        setTitle('Actualización tipo de documento de identificación');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <Skeleton loading={data === null}>
      <IdentityTypeForm
        title={title}
        onFinish={save}
        initialValues={data}
        loading={loading}
      />
    </Skeleton>
  );
};

export default IdentitiesTypesForms;
