import httpClient from '../httpClient';

const filter = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  _exportCSV = false,
) => {
  return await httpClient.get(
    `/ct_modifications_signed/?page[number]=${page}&page[size]=${pageSize}&filter[contrato]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const filterContractor = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  _exportCSV = false,
) => {
  return await httpClient.get(
    `/ct_modifications_signed_contractor/?page[number]=${page}&page[size]=${pageSize}&filter[contrato]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const insuranceTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = 'id',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  return await httpClient.get(
    `ct_insurance_signed/?filter[contrato]=${query}&page[number]=${page}&page[size]=${pageSize}${searchBy}`,
  );
};

const insuranceDocs = async (id) => {
  return await httpClient.get(`ct_insurance_signed/${id}/approved_docs/`);
};

const getInsuranceDoc = async (id, type) => {
  return await httpClient.get(
    `ct_insurance_signed/${id}/get_url/?filter[tipo]=${type}`,
  );
};

export {
  filter,
  filterContractor,
  insuranceTable,
  insuranceDocs,
  getInsuranceDoc,
};
