import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  Alert,
  Modal,
  notification,
} from 'antd';

import { CustomButton } from '../../../components';

import AjaxTable from '../../../components/AjaxTable';
import {
  contractSearch,
  newInfo,
  post,
} from '../../../api/module/postcontractual';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import MESSAGES from '../../../enums/Messages';

const { Title } = Typography;

const breadcrumbItems = [
  {
    text: 'Módulo Poscontractual',
    to: `${INTERNAL_LINKS.POSTCONTRACTUAL}`,
  },
  {
    text: 'Formulario de Poscontractual',
  },
];

const NewPostContractualForm = ({ match, history }) => {
  const [data, setData] = useState();
  const [query, setQuery] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [newData, setNewData] = useState();
  const [loadingNext, setLoadingNext] = useState();

  useEffect(() => {
    async function fetchData() {
      setData();
      try {
        const res = await newInfo(match.params.project_id);
        setData({ ...res.data, project_id: match.params.project_id });
      } catch (error) {
        console.log(error);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.project_id]);

  const onChange = (record, value) => {
    if (value) setNewData({ tipo_terminacion: value, contrato: record.id });
    else setNewData();
  };

  const next = async () => {
    if (!newData) {
      Modal.error({
        title: 'Error',
        content: 'Seleccione el tipo de terminación.',
      });
      return;
    }
    setLoadingNext(newData.contrato);
    try {
      const res = await post(newData);
      history.push(`${INTERNAL_LINKS.POSTCONTRACTUAL}-form/${res.data.id}`);
    } catch (error) {
      try {
        const ed = error.response.data.errors[0].detail;
        if (ed === 'Este contrato ya terminó.') {
          Modal.confirm({
            title: 'Error',
            content:
              'Este contrato ya terminó. ¿Quiere continuar con el proceso de liquidación de acta del tipo seleccionado?',
            onCancel: () => setLoadingNext(),
            onOk: async () => {
              try {
                const res = await post({ ...newData, ignore_date: true });
                history.push(
                  `${INTERNAL_LINKS.POSTCONTRACTUAL}-form/${res.data.id}`,
                );
              } catch (error) {
                console.log(error);
                notification.error({
                  message: MESSAGES.ERROR_MSG,
                  description: MESSAGES.DESC_ERROR_POST_MSG,
                  duration: 2,
                });
                setLoadingNext();
              }
            },
          });
        } else {
          Modal.error({
            title: 'Error',
            content: ed,
          });
          setLoadingNext();
        }
      } catch (e) {
        setLoadingNext();
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
        console.log(error, '\n', e);
      }
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const q = Object.entries(values || {}).reduce((pv, cv) => {
      if (cv[1]) pv.push(`filter[${cv[0]}]=${cv[1]}`);
      return pv;
    }, []);
    q.push(`filter[proyecto]=${data.project_id}`);
    setQuery(q.join('&'));
    setLoading(false);
  };

  const columns = [
    {
      title: 'Nombre Contratista/Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'Cargo/Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: '# de modificaciones realizadas',
      dataIndex: 'mod_num',
      width: '1rem',
      align: 'center',
    },
    {
      title: 'Seleccione terminación',
      key: 'selector',
      render: (_, record) =>
        record.mod_draft || record.status_pc ? (
          <>
            {record.mod_draft
              ? 'Modificación en curso'
              : record.status_pc === 1
              ? 'Terminación en curso'
              : record.status_pc === 2
              ? 'Terminación finalizada'
              : 'No habilitado'}
          </>
        ) : (
          <Select
            options={data.tipos}
            onChange={(value) => onChange(record, value)}
            allowClear
            disabled={newData && newData.contrato !== record.id}
            style={{ width: '100%' }}
          />
        ),
    },
    {
      title: 'Acciones',
      key: 'accion',
      align: 'center',
      fixed: 'right',
      render: (_, record) =>
        record.mod_draft || record.status_pc ? (
          <CustomButton
            type="primary"
            text="Ver motivo"
            onClick={() => {
              if (record.mod_draft)
                Modal.error({
                  title: 'Error',
                  content:
                    'Este contrato tiene una modificación en curso, por lo tanto, no se puede crear una nueva terminación.',
                });
              else if (record.status_pc) {
                if (record.status_pc === 1)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene una terminación en curso, por lo tanto, no se puede crear una nueva terminación.',
                  });
                else if (record.status_pc === 2)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene una terminación finalizada, por lo tanto, no se puede crear una nueva terminación.',
                  });
                else if (record.status_pc === -1)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene pagos incompletos, por lo tanto, no se puede crear una nueva terminación.',
                  });
              }
            }}
            disabled={newData}
          />
        ) : (
          <CustomButton
            type="default"
            text="Crear terminación"
            onClick={next}
            loading={loadingNext === record.id}
            disabled={newData && newData.contrato !== record.id}
          />
        ),
    },
  ];

  return (
    <div>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO POSCONTRACTUAL
      </Title>
      <Title level={4}>Proyecto: {data?.project_name}</Title>
      <br />
      <Alert
        showIcon
        type="info"
        message="Información importante"
        description={
          <>
            <b>Terminaciones y Liquidaciones contractuales:</b>
            <br />
            <b>Ordinaria: </b>Cuando el contrato (incluidas sus modificaciones)
            finaliza en la fecha pactada.
            <br />
            <b>Anticipada: </b>Cuando el contrato (incluidas sus modificaciones)
            finaliza antes de la fecha pactada por mutuo acuerdo y/o renuncias.
            <br />
            <b>Unilateral: </b>Cuando el contrato (incluidas sus modificaciones)
            finaliza por decisión de la Organización contratante.
          </>
        }
      />
      <br />
      {data && (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Title level={5}>Búsqueda por identificación</Title>
          <Row align="bottom" gutter={8}>
            <Col span={8}>
              <Form.Item label="Nombre" name="nombre">
                <Input allowClear placeholder="Búsqueda por nombre" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Número de documento" name="documento">
                <Input
                  type="number"
                  allowClear
                  placeholder="Búsqueda por documento"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Correo electrónico" name="correo">
                <Input allowClear placeholder="Correo electrónico" />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5}>Búsqueda por contrato</Title>
          <Row align="bottom" gutter={8}>
            <Col span={12}>
              <Form.Item label="Cargo" name="cargo">
                <Select
                  placeholder="Seleccione un cargo/rol..."
                  allowClear
                  options={data.positions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Número de Contrato" name="contrato_num">
                <Input allowClear placeholder="Búsqueda por contrato" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} justify="end">
            <Col>
              <CustomButton loading={loading} text="Buscar" />
            </Col>
            <Col>
              <CustomButton
                htmlType="button"
                type="default"
                onClick={() => {
                  form.resetFields();
                }}
                danger={true}
                text="Borrar filtros"
              />
            </Col>
          </Row>
        </Form>
      )}
      <br />
      {data && query && (
        <AjaxTable
          columns={columns}
          tableTitle={'Listado de resultados'}
          endpoint={contractSearch}
          query={query}
          searchInput
          exportCSV
        />
      )}
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        text={
          <Link
            to={`${INTERNAL_LINKS.POSTCONTRACTUAL}/0/${match.params.project_id}`}
          >
            Volver
          </Link>
        }
      />
    </div>
  );
};

export default NewPostContractualForm;
