import httpClient from '../httpClient';

const confirm = async (id) => {
  return await httpClient.post(`/term_director_rejected/${id}/confirm/`);
};

const reactivate = async (id) => {
  return await httpClient.post(`/term_director_rejected/${id}/reactivate/`);
};

const notify = async (id, data) => {
  return await httpClient.post(`/term_director_rejected/${id}/notify/`, data);
};

const counters = async () => {
  return await httpClient.get(`/term_director_rejected/counters`);
};

const filter = async (query) => {
  return await httpClient.get(`/term_director_rejected${query}`);
};

export { confirm, reactivate, notify, counters, filter };
