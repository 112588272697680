import React, { useState, useEffect } from 'react';
import { Typography, Alert, Table, notification, Card } from 'antd';

import MESSAGES from '../../enums/Messages';
import CustomButton from '../../components/CustomButton';
import { CustomTooltip } from '../../components/CustomTooltip';
import CustomUpload from '../../components/CustomUpload';

import {
  post,
  filter as getDocumentByPerson,
} from '../../api/module/resume_documents';
import SpinLoad from '../../components/SpinLoad';
import {get as getDocumentUrl} from "../../api/module/document_repository";

const { Title, Paragraph } = Typography;

const localDataSource = [
  {
    key: '1',
    tipoDocumento:
      'Certificado de existencia y representación legal (menor a 30 Días)',
    label: 'Certificado de existencia',
    tooltip: '',
    documentName: 'documento_16',
  },
  {
    key: '2',
    tipoDocumento: 'Copia de documento de identidad del Representante Legal',
    label: 'Copia de documento de identidad',
    tooltip: '',
    documentName: 'documento_17',
  },
  {
    key: '3',
    tipoDocumento:
      'Certificación bancaria donde se indique el No. de cuenta en la que se consignarán los pagos',
    label: 'Certificación bancaria',
    tooltip:
      'Cuando se trate de cuentas en el exterior, debe especificar el código SWIFT/ABA o IBAN, y datos del banco intermediario (si se requiere). Para mayor información haga click en el botón Ayuda de la parte superior.',
    documentName: 'documento_18',
  },
  {
    key: '4',
    tipoDocumento:
      'Certificaciones de experiencia relacionadas con el objeto contractual o RUP',
    label: 'Certificaciones de experiencia',
    tooltip: '',
    documentName: 'documento_19',
  },
  {
    key: '5',
    tipoDocumento:
      'RUT actualizado y con actividad económica correspondiente al objeto del contrato',
    label: 'RUT actualizado',
    tooltip: '',
    documentName: 'documento_20',
  },
  {
    key: '6',
    tipoDocumento:
      'Certificado de antecedentes disciplinarios de la Procuraduría General de la Nación',
    label: 'Certificado de antecedentes',
    tooltip:
      'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
    documentName: 'documento_21',
  },
  {
    key: '7',
    tipoDocumento:
      'Certificado de antecedentes fiscales de la Contraloría General de la República',
    label: 'Certificado de antecedentes fiscales',
    tooltip:
      'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
    documentName: 'documento_22',
  },
];

const AdditionalDocumentsLegalForm = ({
  showClick,
  readOnly,
  pdfHelp,
  personId,
  loadingPDF,
}) => {
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [dataSource, setDatasource] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);

  const columns = [
    {
      title: 'Tipo documento',
      dataIndex: 'tipoDocumento',
      render: (text, record) =>
        record.tooltip !== '' ? (
          <Paragraph>
            {record.tipoDocumento} <CustomTooltip title={record.tooltip} />
          </Paragraph>
        ) : (
          <Paragraph>{record.tipoDocumento} </Paragraph>
        ),
    },
    {
      title: 'Cargar documento',
      dataIndex: 'cargarDocumento',
      key: 'cargarDocumento',
      width: 300,
      render: (_, record) =>
        !readOnly ? (
          <CustomUpload
            file_type={1}
            upload_type={1}
            onChange={(e) => saveDocument(e, record.documentName)}
          />
        ) : null,
    },
    {
      title: 'Consultar documento',
      dataIndex: 'consultarDocumento',
      key: 'consultarDocumento',
      width: 120,
      render: (_, record) => {
        if (documents != null) {
          const doc = documents.find(
            (item) => item.documento_tipo === record.documentName,
          );
          if (doc) {
            return (
              <CustomButton
                loading={loadingPDF === doc.id}
                block
                type="default"
                className={'ant-btn-info'}
                htmlType="button"
                onClick={() => {
                  showClick(`${doc.documento}.pdf`, doc.id);
                }}
                text="Ver"
              />
            );
          }
        }
      },
    },
  ];

  useEffect(() => {
    setDatasource(localDataSource);
    getDocuments();
  }, []);

  const getDocuments = async () => {
    try {
      setLoadingDocuments(true);
      const response = await getDocumentByPerson(personId);
      setDocuments(response.data.results);
      setLoadingDocuments(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoadingDocuments(false);
    }
  };

  const saveDocument = async (file, documentName) => {
    const formData = new FormData();
    try {
      setLoadingDocuments(true);
      let response;
      if (file.fileList.length > 0) {
        if (file.fileList[0].type == 'application/pdf') {
          let data = file.fileList[0].originFileObj;
          data.tercero = parseInt(personId);

          formData.append(documentName, data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          response = await post(formData);
        }
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            const res = response.data;
          }, MESSAGES.TIME);
        }
      }
      setLoadingDocuments(false);
      setDatasource([]);
      setDatasource(localDataSource);
      getDocuments();
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_FILE_PDF;

      if (err.response !== undefined) {
        if (err.response.data.errors) {
          for (const item of err.response.data.errors) {
            description += ` ${item.detail} - `;
          }
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
      setLoadingDocuments(false);
    }
  };

  const showHelp = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Card
      size={'small'}
      bordered={false}
      title={<Title level={3}>Documentos</Title>}
    >
      <Paragraph>
        Por favor, cargue los documentos (si aplica) en formato PDF y con un
        tamaño del archivo máximo de 2MB.
      </Paragraph>
      {pdfHelp && (
        <Alert
          message="Nota"
          action={
            <CustomButton
              type={'default'}
              className={'back-button'}
              htmlType="button"
              loading={loadingDocument}
              onClick={() => {
                showHelp(pdfHelp.documento);
              }}
              text={'Descargar ayuda'}
            />
          }
          description={
            'Descargue la ayuda con la información orientadora sobre los requerimientos de los documentos solicitados a continuación'
          }
          type="info"
          showIcon
        />
      )}
      <SpinLoad loading={loadingDocuments}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Documentos
            </Paragraph>
          )}
        />
      </SpinLoad>
    </Card>
  );
};

AdditionalDocumentsLegalForm.defaultProps = {
  showCLick: () => {},
  readOnly: false,
  pdfHelp: null,
};

export default AdditionalDocumentsLegalForm;
