import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Typography, Space, Card } from 'antd';

import CustomButton from '../../components/CustomButton/';
import { SelectKnowledgeArea } from '../../components/SelectKnowledgeArea/';
import { SelectExperienceArea } from '../../components/SelectExperienceArea';
import { SelectJobType } from '../../components/SelectJobType';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const WorkTeamSearchForm = memo(
  ({
    loading,
    onFinish,
    selectedKnowledge,
    allowCreation,
    personType = 1,
    onlyIdentificationSection = false,
  }) => {
    const [form] = Form.useForm();

    return (
      <Form
        form={form}
        name="work_team_search__form"
        layout="vertical"
        onFinish={onFinish}
      >
        {personType === 1 ? (
          <>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={5}>Búsqueda por identificación</Title>}
            >
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label="Nombre" name="nombre">
                    <Input allowClear placeholder="Búsqueda por nombre" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Número de documento" name="documento">
                    <Input
                      type="number"
                      allowClear
                      placeholder="Búsqueda por documento"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Correo electrónico" name="correo">
                    <Input
                      allowClear
                      type={'email'}
                      placeholder="Búsqueda por correo electrónico"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {!onlyIdentificationSection && (
              <>
                <Card
                  size={'small'}
                  bordered={false}
                  title={<Title level={5}>Búsqueda por educación</Title>}
                >
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item label="Pregrado" name="pregrado">
                        <SelectKnowledgeArea
                          onChange={(value) => selectedKnowledge(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Especialización" name="especializacion">
                        <SelectKnowledgeArea
                          onChange={(value) => selectedKnowledge(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Maestría" name="maestria">
                        <SelectKnowledgeArea
                          onChange={(value) => selectedKnowledge(value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item label="Doctorado" name="doctorado">
                        <SelectKnowledgeArea
                          onChange={(value) => selectedKnowledge(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Posdoctorado" name="posdoctorado">
                        <SelectKnowledgeArea
                          onChange={(value) => selectedKnowledge(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Titulo obtenido" name="titulo_obtenido">
                        <Input
                          allowClear
                          placeholder="Búsqueda por titulo obtenido"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card
                  size={'small'}
                  bordered={false}
                  title={<Title level={5}>Búsqueda por experiencia</Title>}
                >
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item
                        label="Área de experiencia"
                        name="area_experiencia"
                      >
                        <SelectExperienceArea />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Meses de experiencia"
                        name="meses_experiencia"
                      >
                        <Input
                          type="number"
                          allowClear
                          placeholder="Búsqueda por meses de experiencia"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Tipo de cargo" name="tipo_cargo">
                        <SelectJobType />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item label="Cargo" name="cargo">
                        <Input allowClear placeholder="Búsqueda por cargo" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Experiencia laboral"
                        name="experiencia_laboral"
                      >
                        <Input
                          allowClear
                          placeholder="Búsqueda por experiencia laboral"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </>
            )}
          </>
        ) : (
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={5}>Búsqueda por identificación</Title>}
          >
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Razón social" name="nombre">
                  <Input allowClear placeholder="Búsqueda por Razón social" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="NIT o número de documento equivalente"
                  name="documento"
                >
                  <Input
                    type="number"
                    allowClear
                    placeholder="Búsqueda por NIT o número de documento equivalente"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Sigla o Nombre comercial"
                  name="sigla_comercial"
                >
                  <Input
                    allowClear
                    placeholder="Búsqueda por Sigla o Nombre comercial"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Correo electrónico" name="correo">
                  <Input allowClear placeholder="Correo electrónico" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )}
        <Card size={'small'} bordered={false}>
          <Row gutter={12}>
            <Col span={12}>
              <Space>
                <CustomButton loading={loading} text="Buscar" />
                {allowCreation && personType === 1 && (
                  <CustomButton
                    type="default"
                    className={'ant-btn-info'}
                    text={
                      <a
                        target="_blank"
                        href={`${INTERNAL_LINKS.NATURAL_PERSON}`}
                      >
                        <PlusOutlined /> Crear contratista
                      </a>
                    }
                  />
                )}
                {allowCreation && personType === 2 && (
                  <CustomButton
                    type="default"
                    className={'ant-btn-info'}
                    htmlType="button"
                    text={
                      <a
                        target="_blank"
                        href={`${INTERNAL_LINKS.LEGAL_PERSON}`}
                      >
                        <PlusOutlined /> Crear contratista
                      </a>
                    }
                  />
                )}
                <CustomButton
                  danger
                  type="default"
                  htmlType="button"
                  text="Borrar filtros"
                  onClick={() => {
                    form.resetFields();
                    selectedKnowledge(null);
                  }}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  },
);

WorkTeamSearchForm.propTypes = {
  dataSource: PropTypes.array,
  ids: PropTypes.object,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  selectedKnowledge: PropTypes.func,
};

WorkTeamSearchForm.defaultProps = {
  dataSource: [],
  ids: {},
  loading: false,
  rowSelection: {},
  selectedKnowledge: () => {},
};

export default WorkTeamSearchForm;
