import httpClient from '../httpClient';

const info = async (id) => {
  return await httpClient.get(`/modification_product/${id}/info/`);
};

const post = async (data) => {
  return await httpClient.post('/modification_product/', data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/modification_product/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/modification_product/${id}/`);
};

const swap = async (a, b) => {
  return await httpClient.post('/modification_product/swap/', {
    a: a,
    b: b,
  });
};

const view_home = async (query, page = 1, pageSize = 10, search = false) => {
  return await httpClient.get(
    `/modification_product/view_home/?page[number]=${page}&page[size]=${pageSize}&filter[modulo_modificacion]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const filter = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/modification_product/export/?filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/modification_product/?page[number]=${page}&page[size]=${pageSize}&filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

const preview = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/modification_product/export_preview/?filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/modification_product/preview/?page[number]=${page}&page[size]=${pageSize}&filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

const patchModule = async (id, data) => {
  return await httpClient.patch(
    `/modification_product/${id}/update_module/`,
    data,
  );
};

export {
  info,
  post,
  patch,
  remove,
  filter,
  swap,
  preview,
  view_home,
  patchModule,
};
