import React from 'react';
import { Descriptions, Typography, List, Space, Layout } from 'antd';
import moment from 'moment';
import 'moment/locale/es';

const { Title } = Typography;
const { Content } = Layout;

export default function ValidationEmailHistory({
  type = 'validation',
  validationData = {},
  dataSource = [],
  key = 'validation-email-history',
}) {
  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Descriptions layout="vertical" column={2} bordered>
        <Descriptions.Item label="Proyecto a revisar">
          {type === 'validation' && validationData.proyecto_nombre}
          {(type === 'preselection' || type === 'selection') &&
            validationData.nombre_proyecto}
        </Descriptions.Item>
        <Descriptions.Item label="Cargo/rol a revisar">
          {type === 'validation' && validationData.cargo_nombre}
          {(type === 'preselection' || type === 'selection') &&
            validationData.cargo}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre completo/Razón social">
          {type === 'validation' && validationData.tercero.nombre_completo}
          {(type === 'preselection' || type === 'selection') &&
            validationData.tercero_data.nombre_completo}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo y número de documento">
          {type === 'validation' &&
            `${validationData.tercero.tipo_identificacion_nombre} - ${validationData.tercero.numero_identificacion}`}
          {(type === 'preselection' || type === 'selection') &&
            `${validationData.tercero_data.tipo_identificacion_nombre} - ${validationData.tercero_data.numero_identificacion}`}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={1} bordered layout="vertical">
        <Descriptions.Item key={key} label="Notificación">
          <List
            dataSource={dataSource}
            grid={{ gutter: [0, 24], column: 1 }}
            renderItem={(item, index) => (
              <Content>
                <Title level={5}>
                  Email No. {index + 1} - Enviado el:{' '}
                  {moment(item.created_at).format('DD MMMM YYYY h:mm a')}
                </Title>
                <div
                  key={`${key}-${index}`}
                  dangerouslySetInnerHTML={{ __html: item.mensaje }}
                />
              </Content>
            )}
            pagination={false}
          />
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
}
