import httpClient from '../httpClient';

const getType = async (user_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_assignments/${user_id}/`,
  );
};

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/project_assignments/`,
    data,
  );
};

const filterAssigned = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  _exportCSV = false,
) => {
  return await httpClient.get(
    `/project_assignments/?page[number]=${page}&page[size]=${pageSize}&filter[user]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const filterActive = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  _exportCSV = false,
) => {
  return await httpClient.get(
    `/project_assignments/active_list/?page[number]=${page}&page[size]=${pageSize}&filter[user]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const remove = async (id, user_id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/project_assignments/${id}/?filter[user]=${user_id}`,
  );
};

export { getType, post, filterAssigned, filterActive, remove };
