import React, { useState, useEffect } from 'react';
import {
  notification,
  Typography,
  Layout,
  Descriptions,
  Space,
  List,
  Skeleton,
} from 'antd';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import { Link } from 'react-router-dom';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { get as getSVDP } from '../../../api/module/term_document_contract_validation';
import { getAllByValidation } from '../../../api/module/term_document_contract_document_email';
import moment from 'moment';

const breadcrumbItems = [
  {
    text: 'Seguimiento Validación Documentación Parcial',
    to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}`,
  },
  {
    text: 'Historial de Correo',
  },
];

const { Title } = Typography;
const { Content } = Layout;

export default function DocumentValidationPartialEmailHistory({ match }) {
  const [validationData, setValidationData] = useState(undefined);
  const [dataSource, setDataSource] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const validationId = match.params.validation_id;
        const res = await getSVDP(validationId);
        const eList = await getAllByValidation(res.data.id);
        setValidationData(res.data);
        setDataSource(eList.data.results);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.validation_id]);

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Seguimiento Validación Documentación Parcial
      </Title>
      {validationData ? (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Proyecto a revisar">
              {validationData.termino.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/rol a revisar">
              {validationData.termino.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.tercero.nombre_completo}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tercero.tipo_identificacion_nombre} - ${validationData.tercero.numero_identificacion}`}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={1} bordered layout="vertical">
            <Descriptions.Item label="Notificación">
              <List
                dataSource={dataSource}
                grid={{ gutter: [0, 24], column: 1 }}
                renderItem={(item, index) => (
                  <Content>
                    <Title level={5}>
                      Email No. {index + 1} - Enviado el:{' '}
                      {moment(item.created_at).format('DD MMMM YYYY h:mm a')}
                    </Title>
                    <div
                      key={`partial-email-history-${index}`}
                      dangerouslySetInnerHTML={{ __html: item.mensaje }}
                    />
                  </Content>
                )}
                pagination={false}
              />
            </Descriptions.Item>
          </Descriptions>
          <CustomButton
            type="default"
            htmlType="button"
            className={'back-button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}/${validationData.termino.id}`}
              >
                Volver
              </Link>
            }
          />
        </Space>
      ) : (
        <Skeleton loading active />
      )}
    </Content>
  );
}
