import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import SiderApp from '../../containers/Sider';
import ContentApp from '../../containers/Content';

import './style.scss';

const PrivateLayout = () => {
  return (
    <Layout className="private-layout">
      <Router>
        <Layout className="private-secondary-layout">
          <SiderApp/>
          <ContentApp/>
        </Layout>
      </Router>
    </Layout>
  );
};

export default PrivateLayout;
