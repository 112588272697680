import React from 'react';
import {Typography} from 'antd';
//Component imports
import BreadcrumbContainer from '../../containers/Breadcrumb';
import ResumeSearchViewer from '../../components/ResumeSearchViewer';

const { Title } = Typography;

const ResumeSearchPage = () => {
  const breadcrumbItems = [
    {
      text: 'Módulo de búsqueda de hojas de vida',
    },
  ];
  return(
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={"text-uppercase"} level={2}>Módulo de búsqueda de hojas de vida</Title>
      <ResumeSearchViewer isModal={false}/>
    </>
  );
};

export default ResumeSearchPage;
