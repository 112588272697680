import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Table, Typography } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';
import { CustomTooltip } from '../../components/CustomTooltip';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Convocatorias',
  },
];

const columns = [
  {
    title: 'Opciones de convocatoria',
    key: 'name',
    dataIndex: 'name',
    align: 'center',
    render: (_, record) => {
      if (record.tooltip !== undefined)
        return (
          <>
            {record.name}
            <CustomTooltip title={record.tooltip} />
          </>
        );
      return record.name;
    },
  },
  {
    title: 'Acciones',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <CustomButton
          type={'default'}
          className={'ant-btn-info'}
          htmlType="button"
          text={<Link to={`${record.link}`}>{record.name_btn}</Link>}
        />
      );
    },
  },
];

const announcement = [
  {
    id: 1,
    name: 'Crear Nueva Convocatoria',
    name_btn: 'Crear',
    link: `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}`,
  },
  {
    id: 2,
    name: 'Convocatorias En Curso',
    name_btn: 'Ver',
    tooltip: 'Aquellas que están en período de recepción de aplicación',
    link: `${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`,
  },
  {
    id: 3,
    name: 'Convocatorias Caducadas',
    name_btn: 'Ver',
    link: `${INTERNAL_LINKS.ANNOUNCEMENT_FINISH}`,
  },
  {
    id: 4,
    name: 'Histórico Convocatorias',
    name_btn: 'Ver',
    link: `${INTERNAL_LINKS.ANNOUNCEMENT_HISTORY}`,
  },
];

export default function Announcement({ history, match }) {
  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Convocatorias
      </Title>
      <Table
        columns={columns}
        dataSource={announcement}
        pagination={false}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Tipos de Convocatorias
          </Paragraph>
        )}
      />
    </Content>
  );
}
