import React, { useEffect, useReducer, useState } from 'react';

import {
  Row,
  Col,
  Typography,
  Skeleton,
  notification,
  Tabs,
  Badge,
  Space,
  Divider,
  Popconfirm,
  Modal,
  Descriptions,
  Input,
  Form,
} from 'antd';
import { EditOutlined, SelectOutlined } from '@ant-design/icons';

import CustomSelector from '../../components/CustomSelector';

import { CustomButton } from '../../components';
import MESSAGES from '../../enums/Messages';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import AjaxTable from '../../components/AjaxTable';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  getContractTypes,
  getCounters,
  getProjects,
  getRoles,
  getMassIDs,
  reject,
  sign,
  signMassive,
  signSelected,
  ajaxTable as filterAjaxTable,
  doReturn,
  getGroups,
  getUsers,
  rejectMassive,
  rejectSelected,
  getTerms,
  returnSelected,
  returnMassive,
} from '../../api/module/pc_director';
import { generateWordTemplateURL } from '../../api/module/templates';
import PdfViewer from '../../components/PdfViewer';

import '../../styles/main.scss';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const breadcrumbItems = [
  {
    text: 'Firma de liquidaciones poscontractuales de Contratistas y Proveedores',
  },
];

const columns = [
  {
    title: 'Tipo liquidación',
    dataIndex: 'tipo_nombre',
  },
  {
    title: 'Proyecto',
    dataIndex: 'project_name',
  },
  {
    title: 'Nombre completo / Razón social',
    dataIndex: 'contratista_nombre',
  },
  {
    title: 'Tipo documento',
    dataIndex: 'tipo_identificacion',
  },
  {
    title: 'Nro. Documento',
    dataIndex: 'identificacion',
  },
  {
    title: 'Cargo',
    dataIndex: 'cargo_nombre',
  },
  {
    title: 'No. Contrato',
    dataIndex: 'contrato_num',
  },
  {
    title: 'Identificador masivo',
    dataIndex: 'identificador_masivo',
  },
];

const initModalState = {
  onFinish: async () => {},
  visible: false,
  record: undefined,
  type: undefined,
};

const initSelectors = {
  proyecto: undefined,
  tipo_contrato: undefined,
  cargo: undefined,
  termino: undefined,
  mass_pc: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case 'project':
      return { ...initSelectors, proyecto: action.payload };
    case 'ct':
      return {
        ...initSelectors,
        proyecto: state.proyecto,
        tipo_contrato: action.payload,
      };
    case 'role':
      return {
        ...state,
        cargo: action.payload,
        termino: initSelectors.termino,
        mass_pc: initSelectors.mass_pc,
      };
    case 'termino':
      return {
        ...state,
        termino: action.payload,
        mass_pc: initSelectors.mass_pc,
      };
    case 'mass_pc':
      return { ...state, mass_pc: action.payload };

    default:
      return initSelectors;
  }
}

function PostContractualDirectorSelectors({ match, setQuery }) {
  const [state, dispatch] = useReducer(reducer, initSelectors);

  useEffect(() => {
    if (!match.params.type || Object.values(state).some((value) => !!value)) {
      let q = '';
      if (q === '' && state.mass_pc) {
        q = `filter[mass_pc]=${state.mass_pc}`;
      }
      if (q === '' && state.termino) {
        q = `filter[termino]=${state.termino}`;
      }
      if (q === '' && state.cargo) {
        q = `filter[cargo]=${state.cargo}&filter[tipo_contrato]=${state.tipo_contrato}`;
      }
      if (q === '' && state.tipo_contrato) {
        q = `filter[tipo_contrato]=${state.tipo_contrato}&filter[proyecto]=${state.proyecto}`;
      }
      if (q === '' && state.proyecto) {
        q = `filter[proyecto]=${state.proyecto}`;
      }
      setQuery({ query: q, param: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="box-description" key="vdd-selectors">
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Administración:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            endpointFetch={async () => {
              return { data: { results: [] } };
            }}
          />
        </Col>
      </Row>
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Proyecto a revisar:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            onChange={(value) => dispatch({ type: 'project', payload: value })}
            initialValue={
              match.params.type && match.params.type === 'project'
                ? parseInt(match.params.id)
                : undefined
            }
            onLoad={(value) => {
              if (match.params.type === 'project')
                dispatch({
                  type: 'project',
                  payload: value ? value.id : undefined,
                });
            }}
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            endpointFetch={async () => {
              return await getProjects();
            }}
            key={match.url}
          />
        </Col>
      </Row>
      {state.proyecto && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Tipo de contrato:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'ct', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'ct'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'ct')
                  dispatch({
                    type: 'ct',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el tipo de contrato a revisar... ',
              }}
              endpointFetch={async () => {
                return await getContractTypes({
                  'filter[proyecto]': state.proyecto,
                });
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
      {state.tipo_contrato && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo/rol a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'role', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'role'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'role')
                  dispatch({
                    type: 'role',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo / Rol a revisar... ',
              }}
              endpointFetch={async () => {
                return await getRoles({
                  'filter[proyecto]': state.proyecto,
                  'filter[tipo_contrato]': state.tipo_contrato,
                });
              }}
              key={`rs-${state.tipo_contrato}`}
            />
          </Col>
        </Row>
      )}
      {state.cargo && (
        <Row className="box-selectors" align="middle">
          <Col span={6}>
            <Title level={4} style={{ paddingRight: '15px' }}>
              Identificador del término a revisar:
            </Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) =>
                dispatch({ type: 'termino', payload: value })
              }
              initialValue={
                match.params.type && match.params.type === 'termino'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'termino')
                  dispatch({
                    type: 'termino',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el identificador del término... ',
              }}
              endpointFetch={async () => {
                return await getTerms({
                  'filter[cargo]': state.cargo,
                  'filter[tipo_contrato]': state.tipo_contrato,
                });
              }}
              key={`rs-${state.cargo}`}
            />
          </Col>
        </Row>
      )}
      {state.termino && (
        <Row className="box-selectors" align="middle">
          <Col span={6}>
            <Title level={4} style={{ paddingRight: '15px' }}>
              Identificador masivo de la liquidación a revisar:
            </Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) =>
                dispatch({ type: 'mass_pc', payload: value })
              }
              initialValue={
                match.params.type && match.params.type === 'mass_pc'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'mass_pc')
                  dispatch({
                    type: 'mass_pc',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder:
                  'Seleccione el identificador masivo de la liquidación... ',
              }}
              endpointFetch={async () => {
                return await getMassIDs({
                  'filter[termino]': state.termino,
                });
              }}
              labelName="value"
              key={`rs-${state.termino}`}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

function ReturnModal({ record, onFinish, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  if (!record) return null;

  async function onSelfFinish(values) {
    setLoading(record.id);
    await onFinish(values);
    setLoading();
  }

  return (
    <Modal
      centered
      okText="Enviar"
      cancelText="volver"
      title={<Title level={4}>Devolución Individual</Title>}
      closable={false}
      visible={true}
      footer={
        <>
          <CustomButton
            text="Cancelar"
            type="default"
            htmlType="button"
            onClick={onCancel}
          />
          <CustomButton
            text="Devolver"
            type="primary"
            className="background-bigaro"
            htmlType="button"
            loading={loading === record.id}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      {record.identificador_masivo && (
        <p>
          Este registro pertenece a la terminación / liquidación masiva:{' '}
          <b>{record.identificador_masivo}</b>.
        </p>
      )}
      <Form form={form} layout="vertical" onFinish={onSelfFinish}>
        <Form.Item
          label="Selección del rol que revisará"
          name="rol"
          rules={[{ required: true }]}
        >
          <CustomSelector
            endpointFetch={async () => {
              if (record.identificador_masivo)
                return await getGroups({
                  terminacion__identificador_masivo:
                    record.identificador_masivo,
                });
              return await getGroups({ terminacion: record.id });
            }}
            key={`rs_${record.id}`}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['rol']}>
          {({ getFieldValue, setFieldsValue }) => {
            const group = getFieldValue('rol');
            setFieldsValue({
              usuario_destino: undefined,
            });
            return (
              <Form.Item
                label="Selección de la persona que revisará"
                name="usuario_destino"
                rules={[{ required: true }]}
              >
                <CustomSelector
                  endpointFetch={async () => {
                    if (!group) return { data: { results: [] } };
                    if (record.identificador_masivo)
                      return await getUsers({
                        terminacion__identificador_masivo:
                          record.identificador_masivo,
                        grupo: group,
                      });
                    return await getUsers({
                      terminacion: record.id,
                      grupo: group,
                    });
                  }}
                  disabled={!group}
                  key={`rs_${record.id}_${group}`}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Justifique la devolución de esta terminación / liquidación individual"
          name="observacion"
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function MultipleReturnModal({ massID, onFinish, onCancel, asGroup = false }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function onSelfFinish(values) {
    setLoading(true);
    await onFinish(values);
    setLoading();
  }

  if (!massID) return null;

  return (
    <Modal
      centered
      okText="Enviar"
      cancelText="volver"
      title={
        <Title level={4}>
          Devolución {asGroup ? 'Masiva' : 'Seleccionados'}
        </Title>
      }
      closable={false}
      visible={true}
      footer={
        <>
          <CustomButton
            text="Cancelar"
            type="default"
            htmlType="button"
            onClick={onCancel}
          />
          <CustomButton
            text="Devolver"
            type="primary"
            className="background-bigaro"
            htmlType="button"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      <p>
        Liquidación masiva seleccionada: <b>{massID}</b>.
      </p>
      <Form form={form} layout="vertical" onFinish={onSelfFinish}>
        <Form.Item
          label="Selección del rol que revisará"
          name="rol"
          rules={[{ required: true }]}
        >
          <CustomSelector
            endpointFetch={async () => {
              return await getGroups({
                terminacion__identificador_masivo: massID,
              });
            }}
            key={`rs_${massID}`}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['rol']}>
          {({ getFieldValue, setFieldsValue }) => {
            const group = getFieldValue('rol');
            setFieldsValue({
              usuario_destino: undefined,
            });
            return (
              <Form.Item
                label="Selección de la persona que revisará"
                name="usuario_destino"
                rules={[{ required: true }]}
              >
                <CustomSelector
                  endpointFetch={async () => {
                    if (!group) return { data: { results: [] } };
                    return await getUsers({
                      terminacion__identificador_masivo: massID,
                      grupo: group,
                    });
                  }}
                  disabled={!group}
                  key={`rs_${massID}_${group}`}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        {asGroup && (
          <Form.Item
            label="Nuevo Identificador de liquidación masiva"
            name="identificador_masivo"
            rules={[{ required: true }]}
          >
            <TextArea
              placeholder="Digite aquí"
              maxLength={50}
              showCount
              autoSize={{ minRows: 1, maxRows: 1 }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Justifique la devolución de esta terminación / liquidación masiva"
          name="observacion"
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function MultipleRejectModal({ visible, onFinish, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function onSelfFinish(values) {
    setLoading(true);
    await onFinish(values);
    setLoading();
  }

  return (
    <Modal
      centered
      title={<Title level={4}>Rechazo masivo</Title>}
      closable={false}
      visible={visible}
      footer={
        <>
          <CustomButton
            type="default"
            htmlType="button"
            text="Cancelar"
            onClick={onCancel}
          />
          <CustomButton
            type="danger"
            text="Rechazar"
            htmlType="button"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSelfFinish}>
        <Form.Item
          label="Justifique el rechazo de esta terminación / liquidación masiva"
          name="observacion"
          rules={[{ required: true, message: 'Ingrese una justificación' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function RejectModal({ record, visible, onFinish, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function onSelfFinish(values) {
    setLoading(record.id);
    await onFinish(values);
    setLoading();
  }

  if (!record) return null;

  return (
    <Modal
      centered
      title={<Title level={4}>Rechazo Individual</Title>}
      closable={false}
      visible={visible}
      footer={
        <>
          <CustomButton
            type="default"
            htmlType="button"
            text="Cancelar"
            onClick={onCancel}
          />
          <CustomButton
            type="danger"
            text="Rechazar"
            htmlType="button"
            loading={loading === record.id}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      {record.identificador_masivo && (
        <p>
          Este registro pertenece a la terminación / liquidación masiva:{' '}
          <b>{record.identificador_masivo}</b>.
        </p>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={onSelfFinish}
        initialValues={{ observacion: record.observaciones_director }}
      >
        <Form.Item
          label="Justifique el rechazo de esta terminación / liquidación individual"
          name="observacion"
          rules={[{ required: true, message: 'Ingrese una justificación' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function PostContractualDirector({ match }) {
  const [query, setQuery] = useState({ q: '', param: {} });
  const [counters, setCounters] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const [activeTab, setActiveTab] = useState('review');
  const [massiveLoad, setMassiveLoad] = useState(false);

  const [modalState, setModalState] = useState(initModalState);

  const [loadingIDs, setLoadingIDs] = useState([]);

  const [reloadReview, setReloadReview] = useState(false);

  const [pdfUrl, setPDFUrl] = useState(undefined);
  const [loadingPDF, setLoadingPDF] = useState(undefined);

  const [returnRecord, setReturnRecord] = useState(undefined);
  const [returnMassID, setReturnMassID] = useState(undefined);

  const [actionType, setActionType] = useState(undefined);

  const getURLPDF = async (record) => {
    try {
      setLoadingPDF(record.id);
      let response = await generateWordTemplateURL({
        person: record.tercero,
        term: record.termino,
        person_type: record.tipo_persona,
        contract: record.contrato_id,
        postcontractual: record.id,
        template:
          record.tipo_terminacion === 1
            ? 7
            : record.tipo_terminacion === 2
            ? 6
            : 10,
      });
      setPDFUrl(response.data.url);
      setLoadingPDF(null);
    } catch (err) {
      setLoadingPDF(null);
      console.log(err);
    }
  };

  function onMassiveSign() {
    async function onOk() {
      setMassiveLoad(true);
      setActionType('sign');
      try {
        const res = await signMassive({
          ...query.param,
        });
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setMassiveLoad(false);
      setActionType(undefined);
    }

    if (counters.pending > 0) {
      Modal.confirm({
        title: `Se firmarán ${counters.pending} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  function onSelectedSign() {
    async function onOk() {
      setActionType('sign');
      const data = {
        validaciones: selected.map((x) => x.id),
        proyecto: query.param.proyecto,
      };
      setLoadingIDs(data.validaciones);
      try {
        const res = await signSelected(data, { ...query.param });
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setLoadingIDs([]);
      setActionType(undefined);
    }

    if (selected.length > 0) {
      Modal.confirm({
        title: `Se firmarán ${selected.length} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  function onMassiveReject() {
    async function onOk(values) {
      setMassiveLoad(true);
      try {
        const res = await rejectMassive(values, {
          ...query.param,
        });
        if (res.data.result === false)
          throw new Error('El rechazo masivo no resulto correctamente');
        changeQuery(query, 'rejected');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setMassiveLoad(false);
      setActionType(undefined);
      setModalState(initModalState);
    }

    if (counters.pending > 0) {
      Modal.confirm({
        title: `Se rechazarán ${counters.pending} registros.`,
        content: '¿Está seguro(a)?',
        onOk: () => {
          setActionType('reject');
          setModalState({
            type: 'm_reject',
            visible: true,
            onFinish: onOk,
          });
        },
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  function onSelectedReject() {
    async function onOk(values) {
      const data = {
        ...values,
        validaciones: selected.map((x) => x.id),
        proyecto: query.param.proyecto,
      };
      setLoadingIDs(data.validaciones);
      setActionType('reject');
      try {
        const res = await rejectSelected(data, { ...query.param });
        if (res.data.result === false)
          throw new Error('El rechazo masivo no resulto correctamente');
        changeQuery(query, 'rejected');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setLoadingIDs([]);
      setActionType(undefined);
    }

    if (selected.length > 0) {
      Modal.confirm({
        title: `Se rechazarán ${selected.length} registros.`,
        content: '¿Está seguro(a)?',
        onOk: () => {
          setActionType('reject');
          setModalState({
            type: 'm_reject',
            visible: true,
            onFinish: onOk,
          });
        },
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  async function changeQuery(
    q = { query: '', param: undefined },
    newTab = undefined,
  ) {
    try {
      const res = await getCounters(`/?${q.query}`);
      if (q !== query) setQuery(q);
      setCounters(res.data.results);
      if (newTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setCounters(undefined);
    }
  }

  async function onSave(record) {
    try {
      setLoadingIDs([record.id]);
      await sign(record.id);
      setLoadingIDs([]);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('approved');
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onRejectedStatusSave(id, values) {
    try {
      await reject(id, values);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('review');
      setReloadReview({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onReturn(values) {
    try {
      setLoadingIDs([returnRecord.id]);
      await doReturn(returnRecord.id, values);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('review');
      setReloadReview({});
      setLoadingIDs([]);
      setReturnRecord();
    } catch (err) {
      setLoadingIDs([]);
      setReturnRecord();
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onMassiveReturn(values) {
    setMassiveLoad(true);
    try {
      const res = await returnMassive(values, {
        ...query.param,
      });
      if (res.data.result === false)
        throw new Error('El retorno masivo no resulto correctamente');
      changeQuery(query, 'returned');
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
    setReturnMassID(undefined);
    setMassiveLoad(false);
    setActionType(undefined);
  }

  async function onSelectedReturn(values) {
    const data = {
      ...values,
      validaciones: selected.map((x) => x.id),
    };
    setLoadingIDs(data.validaciones);
    setActionType('s_return');
    try {
      const res = await returnSelected(data, { ...query.param });
      if (res.data.result === false)
        throw new Error('El retorno masivo no resulto correctamente');
      changeQuery(query, 'returned');
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
    setLoadingIDs([]);
    setActionType(undefined);
  }

  const rowSelectionToCheck = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelected(selectedRows);
    },
  };

  const extraColumnsToCheck = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space split={<Divider type="vertical" />}>
            <CustomButton
              type="primary"
              text="Ver Documento"
              onClick={() => {
                getURLPDF(record);
              }}
              loading={loadingPDF === record.id}
            />
            <Popconfirm
              onConfirm={() => {
                onSave(record);
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton
                type="default"
                text="Firmar"
                loading={loadingIDs.includes(record.id)}
                disabled={loadingIDs.length > 0}
              />
            </Popconfirm>
            <Popconfirm
              onConfirm={() => {
                setModalState({
                  type: 'i_reject',
                  visible: true,
                  onFinish: async (values) => {
                    await onRejectedStatusSave(record.id, values);
                    setModalState(initModalState);
                  },
                  record: record,
                });
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton
                type="danger"
                text="Rechazar"
                disabled={loadingIDs.length > 0}
              />
            </Popconfirm>
            <Popconfirm
              onConfirm={() => {
                setReturnRecord(record);
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton
                type="primary"
                text="Devolver"
                loading={returnRecord?.id === record.id}
                disabled={loadingIDs.length > 0}
                className="background-bigaro"
              />
            </Popconfirm>
            <CustomButton
              type="default"
              htmlType="button"
              text={
                <Link
                  to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${record.contrato_id}/${record.proyecto_id}/${record.cargo_id}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver históricos contractuales
                </Link>
              }
            />
          </Space>
        );
      },
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <CustomButton
            type="primary"
            text="Ver Documento"
            onClick={() => {
              getURLPDF(record);
            }}
            loading={loadingPDF === record.id}
          />
        );
      },
    },
  ];

  const extraColumnsRejected = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Motivo"
            onClick={() => {
              Modal.info({
                title: 'Motivo Rechazo',
                centered: true,
                content: (
                  <TextArea
                    rows={4}
                    value={record.observaciones_director}
                    disabled
                  />
                ),
              });
            }}
          />
        );
      },
    },
  ];

  const extraColumnsReturned = [
    {
      title: 'Enviado a',
      dataIndex: 'enviado_a',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Motivo"
            onClick={() => {
              Modal.info({
                title: 'Motivo Devolución',
                centered: true,
                content: (
                  <TextArea
                    rows={4}
                    value={record.observaciones_director}
                    disabled
                  />
                ),
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Firma de liquidaciones poscontractuales de Contratistas y Proveedores
      </Title>
      <PostContractualDirectorSelectors match={match} setQuery={changeQuery} />

      {counters ? (
        <>
          <Descriptions
            bordered
            labelStyle={{ display: 'block', textAlign: 'center' }}
            contentStyle={{ display: 'block', textAlign: 'center' }}
            style={{ marginTop: '15px' }}
            layout="vertical"
            column={2}
          >
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<EditOutlined />}
                  text={
                    <>
                      Firmar masivos{' '}
                      <Badge
                        count={counters.pending}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onMassiveSign}
                  loading={actionType === 'sign' && massiveLoad}
                  disabled={
                    actionType ||
                    counters.pending < 1 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto ||
                      query.param.cargo ||
                      query.param.tipo_contrato ||
                      query.param.termino ||
                      query.param.mass_pc
                    )
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar masivos</Text> por favor
                seleccione los filtros de Proyecto, Cargo/Rol, Tipo de contrato,
                Identificador del término a revisar o Identificador masivo de la
                liquidación a revisar. El número indica los registros que serán
                firmados.
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<SelectOutlined />}
                  text={
                    <>
                      Firmar seleccionados{' '}
                      <Badge
                        count={selected.length}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onSelectedSign}
                  loading={
                    actionType === 'sign' &&
                    (massiveLoad || loadingIDs.length > 0)
                  }
                  disabled={
                    actionType || selected.length < 1 || activeTab !== 'review'
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar seleccionados</Text>, por
                favor seleccione los registros deseados de la tabla a
                continuación. El número indica los registros que serán firmados.
              </Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            bordered
            labelStyle={{ display: 'block', textAlign: 'center' }}
            contentStyle={{ display: 'block', textAlign: 'center' }}
            style={{ marginTop: '15px' }}
            layout="vertical"
            column={2}
          >
            <Descriptions.Item
              label={
                <CustomButton
                  type="danger"
                  size="large"
                  icon={<EditOutlined />}
                  text={
                    <>
                      Rechazo masivo{' '}
                      <Badge
                        count={counters.pending}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onMassiveReject}
                  loading={actionType === 'reject' && massiveLoad}
                  disabled={
                    actionType ||
                    counters.pending < 1 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto ||
                      query.param.cargo ||
                      query.param.tipo_contrato ||
                      query.param.termino ||
                      query.param.mass_pc
                    )
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Rechazo masivo</Text> por favor
                seleccione los filtros de Proyecto, Cargo/Rol, Tipo de contrato,
                Identificador del término a revisar o Identificador masivo de la
                liquidación a revisar. El número indica los registros que serán
                rechazados.
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  type="danger"
                  size="large"
                  icon={<SelectOutlined />}
                  text={
                    <>
                      Rechazo seleccionados{' '}
                      <Badge
                        count={selected.length}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onSelectedReject}
                  loading={
                    actionType === 'reject' &&
                    (massiveLoad || loadingIDs.length > 0)
                  }
                  disabled={
                    actionType || selected.length < 2 || activeTab !== 'review'
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Rechazo seleccionados</Text>, por
                favor seleccione los registros deseados de la tabla a
                continuación. El número indica los registros que serán
                rechazados.
              </Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            bordered
            labelStyle={{ display: 'block', textAlign: 'center' }}
            contentStyle={{ display: 'block', textAlign: 'center' }}
            style={{ marginTop: '15px' }}
            layout="vertical"
            column={2}
          >
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<EditOutlined />}
                  text={
                    <>
                      Devolución Masiva{' '}
                      <Badge
                        count={counters.pending}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={() => {
                    if (counters.pending > 0) {
                      Modal.confirm({
                        title: `Se devolverán ${counters.pending} registros.`,
                        content: '¿Está seguro(a)?',
                        onOk: () => {
                          setActionType('m_return');
                          setReturnMassID(query.param.mass_pc);
                        },
                        cancelText: 'No',
                        okText: 'Sí',
                      });
                    }
                  }}
                  loading={actionType === 'm_return' && massiveLoad}
                  disabled={
                    actionType ||
                    counters.pending < 1 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto &&
                      query.param.cargo &&
                      query.param.tipo_contrato &&
                      query.param.termino &&
                      query.param.mass_pc
                    )
                  }
                  className="background-bigaro"
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Devolución masiva</Text> por favor
                seleccione los filtros de Proyecto, Cargo/Rol, Tipo de contrato,
                Identificador del término a revisar e Identificador masivo de la
                liquidación a revisar. El número indica los registros que serán
                devueltos.
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<SelectOutlined />}
                  text={
                    <>
                      Devolución seleccionados{' '}
                      <Badge
                        count={selected.length}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={() => {
                    if (selected.length > 0) {
                      Modal.confirm({
                        title: `Se devolverán ${selected.length} registros.`,
                        content: '¿Está seguro(a)?',
                        onOk: () => {
                          setActionType('s_return');
                          setReturnMassID(query.param.mass_pc);
                        },
                        cancelText: 'No',
                        okText: 'Sí',
                      });
                    }
                  }}
                  loading={
                    actionType === 's_return' &&
                    (massiveLoad || loadingIDs.length > 0)
                  }
                  disabled={
                    actionType ||
                    selected.length < 2 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto &&
                      query.param.cargo &&
                      query.param.tipo_contrato &&
                      query.param.termino &&
                      query.param.mass_pc
                    )
                  }
                  className="background-bigaro"
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Devolución seleccionados</Text>, por
                favor seleccione los filtros de Proyecto, Cargo/Rol, Tipo de
                contrato, Identificador del término a revisar e Identificador
                masivo de la liquidación a revisar. Luego, seleccione los
                registros deseados de la tabla a continuación. El número indica
                los registros que serán devueltos.
                <br />
                <Text strong>Nota:</Text> Esta opción hará que el(los)
                registro(s) que pertenezcan a un grupo masivo, se devuelva(n) y
                convierta(n) en una terminación individual.
              </Text>
            </Descriptions.Item>
          </Descriptions>
          {massiveLoad ? (
            <Skeleton active loading={true} />
          ) : (
            <Tabs
              style={{ marginTop: 15 }}
              defaultActiveKey={'review'}
              activeKey={activeTab}
              onTabClick={(key, _) => {
                setActiveTab(key);
              }}
              destroyInactiveTabPane={true}
              type="card"
              key={`vdd-home-tabs-${query.query}`}
            >
              <TabPane
                tab={
                  <Paragraph>
                    Por revisar
                    <Badge showZero count={counters.pending} />
                  </Paragraph>
                }
                key="review"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsToCheck]}
                  tableTitle={'Listado de contratistas para revisar'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__isnull]=True`}
                  rowSelection={rowSelectionToCheck}
                  reloadSource={reloadReview}
                  searchInput
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados
                    <Badge showZero count={counters.approved} />
                  </Paragraph>
                }
                key="approved"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsApproved]}
                  tableTitle={'Listado de contratistas aprobados'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__sigla]=CT-PC-D-A`}
                  searchInput
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Rechazados
                    <Badge showZero count={counters.rejected} />
                  </Paragraph>
                }
                key="rejected"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsRejected]}
                  tableTitle={'Listado de contratistas rechazados'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__sigla]=CT-PC-D-R&filter[rd]=2`}
                  searchInput
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Devueltos
                    <Badge showZero count={counters.returned} />
                  </Paragraph>
                }
                key="returned"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsReturned]}
                  tableTitle={'Listado de contratistas devueltos'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__isnull]=True&filter[rd]=3`}
                  searchInput
                />
              </TabPane>
            </Tabs>
          )}
        </>
      ) : (
        <Skeleton active loading={true} />
      )}
      {modalState?.type === 'i_reject' && (
        <RejectModal
          visible={modalState.visible}
          onFinish={modalState.onFinish}
          onCancel={() => {
            setModalState(initModalState);
          }}
          record={modalState.record}
          key={modalState?.record?.id || 'no-key'}
        />
      )}
      {modalState?.type === 'm_reject' && (
        <MultipleRejectModal
          visible={modalState.visible}
          onFinish={modalState.onFinish}
          onCancel={() => {
            setModalState(initModalState);
            setActionType(undefined);
          }}
        />
      )}
      {actionType === 'm_return' && (
        <MultipleReturnModal
          onFinish={onMassiveReturn}
          massID={returnMassID}
          onCancel={() => {
            setReturnMassID();
            setActionType(undefined);
          }}
          asGroup={true}
        />
      )}
      {actionType === 's_return' && (
        <MultipleReturnModal
          onFinish={onSelectedReturn}
          massID={returnMassID}
          onCancel={() => {
            setReturnMassID();
            setActionType(undefined);
          }}
        />
      )}
      <ReturnModal
        record={returnRecord}
        onFinish={onReturn}
        onCancel={() => setReturnRecord()}
      />
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setPDFUrl(undefined)}
        visible={pdfUrl}
        isWord={true}
      />
    </>
  );
}
