import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Form, Input, Select, Row, Col, Space, Layout } from 'antd';
import { Link } from 'react-router-dom';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';

import { getByCountry as getStates } from '../../api/module/states';
import { getAll as getCountries } from '../../api/module/countries';
import CustomSelector from '../../components/CustomSelector';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Ciudades',
    to: `${INTERNAL_LINKS.CITIES}`,
  },
  {
    text: 'Formulario de Ciudades',
  },
];

const validateMessages = {
  required: '${label} Es Requerido!',
  types: {
    nombre: '${label} No es un nombre Valido !',
    codigo: '${label} No es un sigla Valida!',
  },
};

const CitiesForm = ({ title, onFinish, initialValues, loading }) => {
  console.log(initialValues);
  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Paragraph>
        A continuación, diligencie el formulario "{title}".
        <br />
        Recuerde que los campos obligatorios están marcados con asterisco (*).
      </Paragraph>
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Form.Item
          name="pais"
          label="País"
          rules={[
            {
              required: true,
            },
          ]}
          valuePropName="initialValue"
        >
          <CustomSelector
            endpointFetch={getCountries}
            valueName="id"
            labelName="nombre"
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['pais']}>
          {({ getFieldValue }) => {
            const country = getFieldValue('pais');
            return country ? (
              <Form.Item
                name="estado"
                label="Departamento"
                rules={[
                  {
                    required: true,
                  },
                ]}
                valuePropName="initialValue"
              >
                <CustomSelector
                  endpointFetch={async () => await getStates(country)}
                  valueName="id"
                  labelName="nombre"
                  selectorKey={country}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="estado"
                label="Departamento"
                rules={[
                  {
                    required: true,
                  },
                ]}
                valuePropName="initialValue"
              >
                <Select options={[]} disabled />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name="nombre"
          label="Nombre"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value.length <= 100) {
                  return Promise.resolve();
                }
                return Promise.reject('Solo puede ingresar 100 caracteres!');
              },
            }),
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="codigo"
          label="Código"
          rules={[
            {
              required: true,
            },
            {
              max: 5,
              message: 'Solo puede ingresar 5 caracteres!',
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Space>
          <CustomButton text="Guardar" loading={loading} />
          <CustomButton
            type={'default'}
            className={'back-button'}
            htmlType="button"
            text={<Link to={INTERNAL_LINKS.CITIES}> Volver </Link>}
          />
        </Space>
      </Form>
    </Content>
  );
};

CitiesForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  countries: PropTypes.array,
  states: PropTypes.array,
};

CitiesForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
  countries: [],
  states: [],
};
export default CitiesForm;
