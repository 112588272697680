import React, { useState } from 'react';
import {
  Typography,
  Radio,
  Row,
  Col,
  notification,
  Table,
  Empty,
  Button,
} from 'antd';
//Component imports
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomSelector from '../../components/CustomSelector';
import { getSelector } from '../../api/module/projects';
import { getRoleByProject } from '../../api/module/project_positions';
import AjaxTable from '../../components/AjaxTable';
import { getByProjectOrPosition } from '../../api/module/resume_contrat_search';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import WorkTeamSearchForm from '../../containers/WorkTeamSearchForm';
import { getPerson } from '../../api/module/contractor_search';
import MESSAGES from '../../enums/Messages';

const { Title, Text, Paragraph } = Typography;

const ResumeContractSearchPage = ({ match }) => {
  const breadcrumbItems = [
    {
      text: 'Búsqueda de Documentos Contractuales',
    },
  ];

  const [searchType, setSearchType] = useState(parseInt(match.params.type));

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title level={2}>Búsqueda de Documentos Contractuales</Title>
      <Text>Seleccione la forma de búsqueda de los contratos</Text>

      <Radio.Group
        onChange={(e) => setSearchType(e.target.value)}
        value={searchType}
        style={{ width: '100%' }}
      >
        <Row className="box-selectors">
          <Col span={10} offset={2}>
            <Radio value={0}>Por proyecto</Radio>
          </Col>
          <Col span={12}>
            <Radio value={1}>Por persona</Radio>
          </Col>
        </Row>
      </Radio.Group>
      {searchType === 0 && (
        <ResumeContractSearchByProjectOrPosition
          idProyecto={match.params.param1}
          idCargo={match.params.param2}
        />
      )}
      {searchType === 1 && (
        <ResumeContractSearchByResume
          idOpcion={match.params.param1}
          idValor={match.params.param2}
        />
      )}
    </>
  );
};

const ResumeContractSearchByResume = (parametro) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [emptyText, setEmptyText] = useState('No hay datos');

  const columns = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_documento',
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'num_documento',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      width: 125,
      render: (_, record) => {
        return (
          <Button type="primary">
            <Link
              to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-person/${record.id}`}
            >
              Ver
            </Link>
          </Button>
        );
      },
    },
  ];

  const onFinish = async (data) => {
    try {
      setLoading(true);

      if (data.documento == '') {
        data.documento = undefined;
      }

      if (data.nombre == '') {
        data.nombre = undefined;
      }
      if (data.correo == '') {
        data.correo = undefined;
      }

      const response = await getPerson(
        data.nombre,
        data.documento,
        data.correo,
      );
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
        setEmptyText(
          'No se han encontrado elementos para la búsqueda realizada.',
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };
  return (
    <>
      <WorkTeamSearchForm
        onFinish={onFinish}
        onlyIdentificationSection
        loading={loading}
      />
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={columns}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de Hojas de vida
          </Paragraph>
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
        }}
      />
    </>
  );
};

const ResumeContractSearchByProjectOrPosition = (parametro) => {
  const [endpointParams, setEndpointParams] = useState({
    proyecto: parametro.idProyecto,
    cargo: parametro.idCargo,
  });

  const columns = [
    {
      title: 'Tipo persona',
      dataIndex: 'tipo_persona_nombre',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo_q',
    },
    {
      title: 'Nro. Contrato',
      dataIndex: 'numero_contrato',
    },
    {
      title: 'Cargo/Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion_nombre',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      width: 125,
      render: (_, record) =>
        record.estado_proceso === 4 ? (
          <Button type="primary">
            <Link
              to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${
                record.contrato
              }/${endpointParams.proyecto}/${
                endpointParams.cargo != undefined ? endpointParams.cargo : ''
              }`}
            >
              Ver
            </Link>
          </Button>
        ) : (
          <>
            <Paragraph>El proceso de contratación esta en curso</Paragraph>
            {/* <Paragraph strong>
              Recargue el listado mínimo pasados 5 minutos
            </Paragraph> */}
          </>
        ),
    },
  ];
  return (
    <>
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Proyecto </Title>
        </Col>

        <Col span={17} offset={0.5}>
          <CustomSelector
            onChange={(v) => setEndpointParams({ proyecto: v })}
            endpointFetch={async () => {
              return await getSelector();
            }}
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el Proyecto...',
            }}
            initialValue={
              parametro.idProyecto != undefined
                ? parseInt(parametro.idProyecto)
                : undefined
            }
          />
        </Col>
      </Row>
      {endpointParams.proyecto && (
        <>
          <Row className="box-selectors">
            <Col span={6}>
              <Title level={4}>Cargo/Rol </Title>
            </Col>
            <Col span={17} offset={0.5}>
              <CustomSelector
                onChange={(v) => setEndpointParams((s) => ({ ...s, cargo: v }))}
                endpointFetch={async () => {
                  return await getRoleByProject(endpointParams.proyecto);
                }}
                selectorProps={{
                  style: { width: '100%' },
                  placeholder: 'Seleccione el Cargo/Rol...',
                }}
                valueName="id"
                labelName="cargo"
                key={endpointParams.proyecto}
                initialValue={
                  parametro.idCargo != undefined
                    ? parseInt(parametro.idCargo)
                    : undefined
                }
              />
            </Col>
          </Row>
          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              return await getByProjectOrPosition({
                ...endpointParams,
                'page[number]': page,
                'page[size]': pageSize,
              });
            }}
            columns={columns}
            reloadButton
            reloadSource={endpointParams}
            rowKey={'numero_contrato'}
          />
        </>
      )}
    </>
  );
};

export default ResumeContractSearchPage;
