import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Typography, notification } from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { getAll, remove } from '../../../api/module/document_types';

import './style.scss';

const { Title, Paragraph } = Typography;
const breadcrumbItems = [
  {
    text: 'Tipos de Documentos',
  },
];

export default function DocumentsTypeList() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataSource = () => {
    try {
      setLoading(true);
      getAll().then((res) => {
        setDataSource(res.data.results);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getDataSource();
  }, []);

  // Delete Object by Id of List
  const deleteDataSource = useCallback(
    async (id) => {
      try {
        const response = await remove(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
          setDataSource(dataSource.filter((item) => item.id !== id));
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [dataSource],
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <div className="box-btn-space">
            <CustomButton
              type="default"
              text={
                <Link to={`${INTERNAL_LINKS.DOCUMENT_TYPE}/${record.id}`}>
                  Editar
                </Link>
              }
            />
            <CustomButton
              danger="true"
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSource(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </div>
        ) : null,
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>Lista Tipos de Documentos</Title>
      </div>
      <div className="box-description">
        <Paragraph>
          A continuación podrá realizar acciones como la creación, edición y
          eliminación de tipos de documentos.
        </Paragraph>
      </div>
      <div className="box-new">
        <CustomButton
          text={
            <Link to={INTERNAL_LINKS.DOCUMENT_TYPE}>Crear nuevo elemento</Link>
          }
        />
      </div>
      <div className="box-table">
        <SpinLoad loading={loading}>
          <Table
            bordered
            rowKey="id"
            scroll={{ x: 400 }}
            dataSource={dataSource}
            columns={columns}
          />
        </SpinLoad>
      </div>
      <CustomButton danger="true" text={<Link to="/">Volver</Link>} />
    </>
  );
}
