import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/`, data);
};

const getAll = async () => {
  return (await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/`).then(res => res.data));
};

const getAllByTerm = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/?filter[termino]=${id}&filter[tiene_pagos]=1`);
};

const getResponsibilitiesByPay = async (id) => {  
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/?filter[pago]=${id}`);
};

const get = async (id) => {
  return (await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/${id}`).then(res => res.data));
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/term_payments_responsibility/${id}`);
};

export {
  post,
  getAll,
  getAllByTerm,
  getResponsibilitiesByPay,
  get,
  put,
  remove
}