import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';

import CustomButton from '../../../../components/CustomButton';
import INTERNAL_LINKS from '../../../../enums/InternalLinks';
import SpinLoad from "../../../../components/SpinLoad";
import AjaxTable from "../../../../components/AjaxTable";
import {ajaxTable as filterAjaxTable} from "../../../../api/module/announcement_preselection";

const { Paragraph } = Typography;

const Preseleccionados = ({
  dataSource,
  announcement_id,
  announcement_rol_id,
  loading,
}) => {
  const columns = [
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero_data', 'notificacion_email'],
      key: ['tercero_data', 'notificacion_email'],
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero_data', 'nombre_completo'],
      key: ['tercero_data', 'nombre_completo'],
    },
    {
      title: 'Tipo Identificación',
      dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      key: ['tercero_data', 'tipo_identificacion_nombre'],
    },
    {
      title: 'Doc Identificación',
      dataIndex: ['tercero_data', 'numero_identificacion'],
      key: ['tercero_data', 'numero_identificacion'],
    },
    {
      title: 'Correo notificación',
      dataIndex: 'estado_preseleccion',
      key: 'estado_preseleccion',
      fixed: 'right',
      render: (_, record) => (
        <Space>
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_EMAIL}/${announcement_id}/${record.id}/${announcement_rol_id}/${record.tercero}?email=preselected`}
              >
                Generar correo{' '}
              </Link>
            }
          />
          {record.estado_envio_preseleccionado && (
            <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
          )}
        </Space>
      ),
    },
  ];

  return (
    <SpinLoad loading={loading}>
      {dataSource != null && (
        <AjaxTable
          columns={columns}
          pageSize={10}
          tableTitle={'Listado de contratistas preseleccionados'}
          endpoint={filterAjaxTable}
          searchInput
          exportCSV
          reloadButton
          query={`/?filter[convocatoria_rol]=${announcement_rol_id}&filter[estado_tercero]=11`}
        />
      )}
    </SpinLoad>
  );
};

export default Preseleccionados;
