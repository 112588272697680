import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/gender';

import './style.scss';

const SelectGenders = ({
  className = '',
  onChange = () => {},
  value,
  disabled = false,
}) => {
  const [data, setData] = useState(null);

  const getGenders = () => {
    getAll().then((res) => setData(res.data.results));
  };

  useEffect(() => {
    getGenders();
  }, []);

  return (
    <Select
      placeholder="Sexo"
      className={className}
      value={value}
      onChange={onChange}
      allowClear
      showSearch
      optionFilterProp="children"
      disabled={disabled}
      loading={!data}
    >
      {data &&
        data.map((item) => (
          <Select.Option key={`gender-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
export default SelectGenders;
