import axios from 'axios';
import {LogOut, setUserSession} from "../utils/helpers/helpers";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: window.localStorage.access_token ? {
    Authorization: `JWT ${window.localStorage.access_token}`
  } : {}
});

const refresh = async (error) => {
  // try {
  //   const response = await axios.post(`${process.env.REACT_APP_API_HOST}/token/refresh/`, {
  //     token: window.localStorage.getItem('access_token')
  //   }, {
  //     headers: {
  //       Authorization: `JWT ${window.localStorage.access_token}`
  //     }
  //   });
  //
  //   setUserSession(response.data.data);
  // }
  // catch (e) {
    LogOut();

    return Promise.reject(error);
  // }
}

instance.interceptors.response.use((value) => {
  return value;
}, async (error) => {
  try {
    const error_text = error.response.data.errors;

    if (error_text) {
      if (Array.isArray(error_text))
        for (const item of error_text) {
          if (item.detail === 'Signature has expired.')
            await refresh(error);
        }
      else
        if (error_text.non_field_errors.includes('Signature has expired.'))
          await refresh(error);
    }

    return Promise.reject(error);
  }
  catch (e) {
    return Promise.reject(error);
  }
});

export default instance;
