import React from 'react';
import { Select } from 'antd';

export const SelectUserGroup = ({
  className = '',
  onChange = () => {},
  group = 0,
  groupName = '',
  data = null,
  disabled = false,
  loading = false,
}) => {
  return (
    <>
      <Select
        placeholder="Seleccione el rol"
        className={className}
        onChange={onChange}
        defaultValue={group > 0 ? groupName : null}
        disabled={disabled}
        allowClear
        showSearch
        loading={loading}
        optionFilterProp="children"
      >
        {data != null &&
          data.map((item) => (
            <Select.Option key={`group-key-${item.id}`} value={item.id}>
              {item.nombre}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default SelectUserGroup;
