import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Badge,
  Col,
  Form,
  notification,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
  Select,
  Divider,
  Popconfirm,
  Input,
} from 'antd';

import AjaxTable from '../../../components/AjaxTable';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import { SelectProjects } from '../../../components/SelectProjects';
import PdfViewer from '../../../components/PdfViewer';

import BreadcrumbContainer from '../../../containers/Breadcrumb';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import {
  filter,
  remove,
  duplicate,
  getTermCounters,
  ajaxTable as filterTDR,
} from '../../../api/module/terms';
import { getAll as getAllContractTypes } from '../../../api/module/contract_types';
import { generateWordTemplateURL as generateTemplateURL } from '../../../api/module/templates';
import { getAll as getAllProjects } from '../../../api/module/projects';
import { getRoleByProject } from '../../../api/module/project_positions';

import './style.scss';
import { IsRole } from '../../../utils/helpers/helpers';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const TDRList = ({
  project,
  deleteDataSource = () => {},
  duplicateOnClick,
}) => {
  const [menu, setMenu] = useState('borrador');
  const [userId] = useState(parseInt(window.localStorage['userId']));
  const [counters, setCounters] = useState({
    approval: 0,
    created: 0,
    drafts: 0,
    return: 0,
    returned: 0,
    validated: 0,
  });

  const [manager] = useState(IsRole(1));
  const [coordinator] = useState(IsRole(2));
  const [legal] = useState(IsRole(3));
  const [administrative] = useState(IsRole(4));
  const [superUser] = useState(IsRole(6));
  const [lead] = useState(IsRole(8));
  const [principal] = useState(IsRole(9));

  const [activeTab, setActiveTab] = useState(0);
  const [pdfUrl, setPDFUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(null);

  const getCountersData = async () => {
    const response = await getTermCounters(project.id);
    setCounters(response.data.results);
  };

  useEffect(async () => {
    try {
      await getCountersData();
    } catch (err) {
      console.log(err);
    }
  }, [project]);

  const getURLPDF = async (id, person_type) => {
    try {
      setPDFUrl(null);
      setLoadingPDF(id);
      let response = await generateTemplateURL({
        term: id,
        template: 11,
        person_type: person_type,
      });
      setPDFUrl(response.data.url);
      setShowPdf(true);
      setLoadingPDF(null);
    } catch (err) {
      setLoadingPDF(null);
      console.log(err);
    }
  };

  const columns = [
    {
      title: 'Identificador Interno',
      dataIndex: 'identificador_interno',
      key: 'identificador_interno',
    },
    {
      title: 'Tipo de contrato',
      dataIndex: 'tipo_contrato_texto',
      key: 'tipo_contrato_texto',
    },
    {
      title: 'Área de Corpoeducación',
      dataIndex: 'area_corpo_texto',
      key: 'area_corpo_texto',
    },
    {
      title: 'Centro de costos',
      dataIndex: 'centro_costo_nombre',
      key: 'centro_costo_nombre',
    },
    {
      title: 'Cargo / Rol',
      dataIndex: 'cargo_nombre',
      key: 'cargo',
    },
    {
      title: 'Fecha inicio contrato',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
    },
    {
      title: 'Fecha fin contrato',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: activeTab === 5 ? 300 : 200,
      render: (_, record) => (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          {record.area && record.centro_costo && record.tipo_persona && (
            <CustomButton
              block
              text="Ver TDR"
              onClick={() => {
                getURLPDF(record.id, record.tipo_persona);
              }}
              loading={loadingPDF === record.id}
              style={{ width: '100%' }}
            />
          )}
          {activeTab !== 5 && activeTab !== 4 && activeTab !== 1 && (
            <CustomButton
              block
              type="default"
              style={{ width: '100%' }}
              text={
                <Link
                  to={`${INTERNAL_LINKS.TERM}/${record.id}/${project.id}/1`}
                >
                  Editar
                </Link>
              }
            />
          )}
          {activeTab === 0 && (manager || coordinator || lead || principal) && (
            <Popconfirm
              title={MESSAGES.DELETE_CONFIRM_MSG}
              onConfirm={() => {
                deleteDataSource(record.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <CustomButton
                style={{ width: '100%' }}
                danger
                block
                type="default"
                text="Eliminar"
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: '10px' }}>
        {project.num_cargos === 0 ? (
          <Col span={24}>
            <Alert
              message="Error"
              description="No se puede crear un TDR de un proyecto que no tenga un equipo de trabajo con al menos un
              cargo/rol definido"
              type="error"
              showIcon
            />
          </Col>
        ) : (
          (coordinator || lead || manager) && (
            <>
              <Col span={5}>
                <CustomButton
                  style={{ width: '100%' }}
                  text={
                    <Link to={`${INTERNAL_LINKS.CONTRACT_TYPE}/${project.id}`}>
                      Crear Nuevo TDR
                    </Link>
                  }
                />
              </Col>
              {menu == 'borrador' && (
                <Col span={6}>
                  <CustomButton
                    style={{ width: '100%' }}
                    className="box__space-left"
                    onClick={() => {
                      duplicateOnClick(project.id);
                    }}
                    text="Crear desde un TDR ya existente"
                  />
                </Col>
              )}
            </>
          )
        )}
      </Row>

      <Tabs
        type="card"
        defaultActiveKey={activeTab}
        destroyInactiveTabPane={true}
        onChange={(value) => {
          setActiveTab(parseInt(value));
        }}
        style={{ marginTop: 15 }}
      >
        {(manager ||
          coordinator ||
          administrative ||
          superUser ||
          lead ||
          principal) && (
          <TabPane
            tab={
              <Paragraph>
                Borrador
                <Badge showZero count={counters ? counters.drafts : 0} />
              </Paragraph>
            }
            key={0}
          >
            <AjaxTable
              endpointFetch={async (page, pageSize) => {
                const thisQuery = `filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-B&filter[usuario]=${userId}`;
                return await filter(`?${thisQuery}`, page, pageSize);
              }}
              columns={columns}
            />
          </TabPane>
        )}
        {(manager || coordinator || superUser || lead || principal) && (
          <TabPane
            tab={
              <Paragraph>
                En revisión
                <Badge showZero count={counters ? counters.created : 0} />
              </Paragraph>
            }
            key={1}
          >
            <AjaxTable
              endpointFetch={async (page, pageSize) => {
                const thisQuery = `filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-R&filter[usuario_origen]=${userId}`;
                return await filter(`?${thisQuery}`, page, pageSize);
              }}
              columns={columns}
            />
          </TabPane>
        )}
        {(manager || coordinator || lead || superUser || principal) && (
          <TabPane
            tab={
              <Paragraph>
                Devueltos a mí
                <Badge showZero count={counters ? counters.return : 0} />
              </Paragraph>
            }
            key={2}
          >
            <AjaxTable
              endpointFetch={async (page, pageSize) => {
                const thisQuery = `filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-D&filter[usuario_destino]=${userId}`;
                return await filter(`?${thisQuery}`, page, pageSize);
              }}
              columns={columns}
            />
          </TabPane>
        )}
        {(manager || lead || legal || superUser || principal) && (
          <TabPane
            tab={
              <Paragraph>
                Para aprobación
                <Badge showZero count={counters ? counters.approval : 0} />
              </Paragraph>
            }
            key={3}
          >
            <AjaxTable
              endpointFetch={async (page, pageSize) => {
                const thisQuery = `filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-R&filter[usuario_destino]=${userId}`;
                return await filter(`?${thisQuery}`, page, pageSize);
              }}
              columns={columns}
            />
          </TabPane>
        )}
        {(manager || lead || legal || superUser || principal) && (
          <TabPane
            tab={
              <Paragraph>
                Devueltos por mí
                <Badge showZero count={counters ? counters.returned : 0} />
              </Paragraph>
            }
            key={4}
          >
            <AjaxTable
              endpointFetch={async (page, pageSize) => {
                const thisQuery = `filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-D&filter[usuario_origen]=${userId}`;
                return await filter(`?${thisQuery}`, page, pageSize);
              }}
              columns={columns}
            />
          </TabPane>
        )}
        {(legal || superUser) && (
          <TabPane
            tab={
              <Paragraph>
                Validados
                <Badge showZero count={counters ? counters.validated : 0} />
              </Paragraph>
            }
            key={5}
          >
            <AjaxTable
              endpoint={filterTDR}
              query={`filter[proyecto]=${project.id}&filter[estado_proceso__sigla]=T-V&filter[usuario]=${userId}`}
              columns={columns}
              searchInput
            />
          </TabPane>
        )}
      </Tabs>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
        isWord={true}
      />
    </>
  );
};

const TDRHome = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [rolesByProject, setRolesByProject] = useState([]);
  const [searchTermsData, setSearchTermsData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchLoadingDuplicate, setSearchLoadingDuplicate] = useState(false);
  const [projects, setProjects] = useState([]);
  const [showDuplcateOption, setShowDuplcateOption] = useState(false);
  const [tdrList, setTdrList] = useState(null);

  const [pdfUrl, setPDFUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(null);

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
    },
  ];

  const getURLPDF = async (id, person_type) => {
    try {
      setPDFUrl(null);
      setLoadingPDF(id);
      let response = await generateTemplateURL({
        term: id,
        template: 11,
        person_type: person_type,
      });
      setPDFUrl(response.data.url);
      setShowPdf(true);
      setLoadingPDF(null);
    } catch (err) {
      setLoadingPDF(null);
      console.log(err);
    }
  };

  const searchColumns = [
    {
      title: 'Tipo de contrato',
      dataIndex: 'tipo_contrato_texto',
      key: 'tipo_contrato_texto',
    },
    {
      title: 'Área de Corpoeducación',
      dataIndex: 'area_corpo_texto',
      key: 'area_corpo_texto',
    },
    {
      title: 'Centro de costos',
      dataIndex: 'centro_costo_nombre',
      key: 'centro_costo_nombre',
    },
    {
      title: 'Cargo / Rol',
      dataIndex: 'cargo_nombre',
      key: 'cargo',
    },
    {
      title: 'Identificador interno',
      dataIndex: 'identificador_interno',
      key: 'identificador_interno',
    },
    {
      title: 'Fecha inicio contrato',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
    },
    {
      title: 'Fecha fin contrato',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          {record.area && record.centro_costo && record.tipo_persona && (
            <CustomButton
              type="info"
              block
              text="Ver TDR"
              onClick={() => {
                getURLPDF(record.id, record.tipo_persona);
              }}
              loading={loadingPDF === record.id}
              style={{ width: '100%' }}
            />
          )}
          <CustomButton
            block
            loading={searchLoadingDuplicate}
            style={{ width: '100%' }}
            text={
              <Popconfirm
                title={'Está seguro(a) que desea duplicar este TDR?'}
                okText="Si"
                cancelText="No"
                onConfirm={() => duplicateTRD(record.id, project.id)}
              >
                Duplicar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  const onSearchTerms = async (data) => {
    setSearchLoading(true);
    try {
      let filterParams = '';
      if (data.contract_type) {
        filterParams += `filter[tipo_contrato]=${data.contract_type}&`;
      }
      if (data.project) {
        filterParams += `filter[proyecto]=${data.project}&`;
      }
      if (data.rol) {
        filterParams += `filter[cargo]=${data.rol}&`;
      }
      if (data.identificador_interno) {
        filterParams += `filter[identificador_interno.contains]=${data.identificador_interno}&`;
      }
      if (filterParams === '') {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description:
            'Por favor seleccione al menos un elemento para realizar el filtro.',
          duration: 3,
        });
      } else {
        const response = await filter(`?${filterParams}`);
        setSearchTermsData(response.data.results);
      }
    } catch (err) {
      console.log(err);
    }
    setSearchLoading(false);
  };

  const duplicateTRD = async (id, target_project) => {
    setSearchLoadingDuplicate(true);
    try {
      const response = await duplicate(id, target_project);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: 'El TDR se duplicó correctamente.',
          duration: 2,
        });
      }
      setTimeout(() => window.location.reload(), MESSAGES.TIME_RELOAD);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: 'Ocurrió un error al intentar duplicar el TDR.',
        duration: 2,
      });
    }
    setSearchLoadingDuplicate(false);
  };

  useEffect(() => {
    Promise.all([getContractTypesOptions(), getAllProjectsOptions()]);
  }, []);

  const getContractTypesOptions = async () => {
    const response = await getAllContractTypes();
    setContractTypes(response.data.results);
  };

  const getAllProjectsOptions = async () => {
    const response = await getAllProjects();
    setProjects(response.data.results);
  };

  const getRolesByProject = async (id) => {
    const response = await getRoleByProject(id);
    setRolesByProject(response.data.results);
  };

  const deleteDataSource = async (id) => {
    try {
      const response = await remove(id);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: 2,
        });
      }
      setTimeout(() => window.location.reload(), MESSAGES.TIME_RELOAD);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    }
  };

  const showDuplicateForm = async (id) => {
    setShowDuplcateOption(true);
  };

  const getTDRList = (projectData) => {
    setTdrList(null);
    setTimeout(() => {
      setTdrList(
        <TDRList
          duplicateOnClick={showDuplicateForm}
          project={projectData}
          deleteDataSource={deleteDataSource}
        />,
      );
    }, 500);
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <SpinLoad loading={isLoading}>
        <div className="box-title">
          <Title>Creación de términos de referencia</Title>
          {project && <Title level={4}>Proyecto: {project.nombre}</Title>}
        </div>
        {!project && (
          <Paragraph>
            Seleccione el proyecto en el cual desea crear los términos de
            referencia
          </Paragraph>
        )}
        <Form layout={showDuplcateOption ? 'vertical' : 'horizontal'}>
          <Form.Item
            name="proyecto"
            label={
              !showDuplcateOption
                ? 'Proyecto'
                : 'Proyecto para creación de TDR seleccionado:'
            }
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Este campo es requerido',
              },
            ]}
          >
            <SelectProjects
              proyecto={project ? project.id : null}
              onChangeData={(value) => {
                setProject(value);
                getTDRList(value);
              }}
            />
          </Form.Item>
        </Form>
        {project != null && showDuplcateOption === false && (
          <>
            <Paragraph>
              A continuación, encontrará una tabla con los términos de
              referencia creados para este proyecto, con la posibilidad de
              editarlos. Si desea agregar un nuevo término de referencia, por
              favor, haga click en el botón "Crear Nuevo TDR"
            </Paragraph>

            {tdrList}
          </>
        )}
        {showDuplcateOption === true && (
          <>
            <p>
              Seleccione en cualquiera de las siguientes opciones para hacer la
              búsqueda.
            </p>
            <Form
              layout="vertical"
              onFinish={(data) => {
                onSearchTerms(data);
              }}
            >
              <Form.Item label="Tipo de contrato" name="contract_type">
                <Select allowClear>
                  {contractTypes.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Nombre del proyecto" name="project">
                <Select
                  onChange={(value) => {
                    getRolesByProject(value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {projects.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Nombre Cargo/Rol" name="rol">
                <Select allowClear>
                  {rolesByProject.map((item) => (
                    <Select.Option value={item.id}>{item.cargo}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Identificador interno"
                name="identificador_interno"
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <CustomButton loading={searchLoading} text="Buscar" />
              </Form.Item>
            </Form>
            <Divider orientation="left">
              <Title level={3}>Listado de TDR existentes</Title>
            </Divider>
            <Table
              loading={searchLoading}
              columns={searchColumns}
              dataSource={searchTermsData}
            />
            <PdfViewer
              pdf={pdfUrl}
              onCancel={() => setShowPdf(false)}
              visible={showPdf}
              isWord={true}
            />
          </>
        )}

        <div style={{ marginTop: '10px' }}>
          <Space>
            <CustomButton
              danger="true"
              onClick={() => {
                if (showDuplcateOption) {
                  setShowDuplcateOption(false);
                }
              }}
              text={
                !showDuplcateOption ? (
                  <Link to={`${INTERNAL_LINKS.HOME}`}>Volver</Link>
                ) : (
                  'Volver'
                )
              }
            />
          </Space>
        </div>
      </SpinLoad>
    </>
  );
};

export default TDRHome;
