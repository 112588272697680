import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement/`,
    data,
  );
};

const postDocument = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement/`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}/`,
    data,
  );
};

const putDocument = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const postTemplateDocument = async (id, data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}/template/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false
) => {
  let searchBy = '';

  if (search)
    searchBy = '&filter[search]=' + search

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      }
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

const filter = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = '',
) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement` +
      `${query}&page[number]=${page}&page[size]=${pageSize}` +
      `&sort=${sortBy}` +
      `&filter[search]=${search}`,
  );
};

const postInvitations = async (id) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}/launch/`,
  );
};

const getPublicInfo = async (id) => {
  return await httpClient.get(`/announcement/${id}/link/`);
};

const getErrorsFile = async (id) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement/${id}/download_errors/`,
    null,
    {
      responseType: 'blob',
    },
  );
};

export {
  post,
  postDocument,
  getAll,
  get,
  put,
  putDocument,
  postTemplateDocument,
  remove,
  filter,
  postInvitations,
  getPublicInfo,
  getErrorsFile,
  ajaxTable,
};
