import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/`, data);
};

const postDocument = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/announcement_selection_email/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection_email${query}`);
};

export {
  post,
  postDocument,
  getAll,
  get,
  put,
  remove,
  filter
};
