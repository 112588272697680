import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import MESSAGES from '../../enums/Messages';

export function IsLoggedIn() {
  return window.localStorage['access_token'] !== undefined;
}

export function setUserSession(session_data) {
  let user_data = session_data.attributes;
  window.localStorage['access_token'] = session_data.token;
  window.localStorage['userId'] = user_data.id;
  window.localStorage['tercero'] = session_data.resume;
  window.localStorage['username'] = user_data.username;
  window.localStorage['email'] = user_data.email;
  window.localStorage['first_name'] = user_data.first_name;
  window.localStorage['last_name'] = user_data.last_name;
  window.localStorage['last_login'] = user_data.last_login;
  window.localStorage['person_type'] = session_data.person_type;
  window.localStorage['groups'] = JSON.stringify(user_data.groups);
  window.localStorage['permissions'] = JSON.stringify(session_data.included);
  window.location.reload();
}

export function LogOut() {
  window.localStorage.clear();
  window.location = '/';
}

export function generate_uuid() {
  let dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export const IsRole = (rol) => {
  let groups = JSON.parse(localStorage['groups']);
  if (groups.length > 0) {
    if (groups[0] === rol) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const useQuery = () => new URLSearchParams(useLocation().search);

export const DateFormat = (date) => {
  if (date) {
    let format = new Date(date).toISOString().split('T')[0];
    return format;
  }
};

export const validatorNumbers = (minLength = 0, zeroPass = false) => {
  return () => ({
    validator(_, value) {
      if (new RegExp('^[0-9]*$').test(value)) {
        if (
          !value ||
          minLength === 0 ||
          value.toString().length >= minLength ||
          (zeroPass && value.toString() === '0')
        ) {
          return Promise.resolve();
        } else
          return Promise.reject(
            new Error(`Ingrese mínimo ${minLength} números`),
          );
      }
      return Promise.reject(new Error(`Ingrese únicamente números`));
    },
  });
};

export const catchException = (
  exception,
  message = MESSAGES.DESC_ERROR_GET_MSG,
  seconds = MESSAGES.TIME_SECONDS,
) => {
  console.log(exception);

  if (exception?.response?.data?.errors) {
    const errors = exception.response.data.errors;
    message = errors.shift().detail;
  }

  notification.error({
    message: MESSAGES.ERROR_MSG,
    description: message,
    duration: seconds,
  });
};
