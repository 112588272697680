import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/cities/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/cities/?page[size]=10000`,
  );
};

const getByState = async (stateId) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/cities/?page[size]=10000&filter[estado]=${stateId}&sort=nombre`,
  );
};

const getByCountry = async (countryId) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/cities/?page[size]=10000&filter[pais]=${countryId}&sort=nombre`,
  );
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/cities/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/cities/${id}/`,
    data,
  );
};

const patch = async (id) => {
  return await httpClient.patch(
    `${process.env.REACT_APP_API_HOST}/cities/${id}`,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/cities/${id}`,
  );
};

const filter = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = false,
  search = false,
) => {
  return await httpClient.get(
    `/cities/${query}?page[number]=${page}&page[size]=${pageSize}&${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

export {
  post,
  getAll,
  get,
  put,
  patch,
  remove,
  getByState,
  getByCountry,
  filter,
};
