import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_resume_contract/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_resume_contract/${id}`);
};

const getAllBySelf = async () => {
  return await httpClient.get(
    `/term_resume_contract/?filter[tercero]=${window.localStorage['tercero']}&filter[estado_validacion__isnull]=True`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(`/term_resume_contract/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/term_resume_contract/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_resume_contract/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`/term_resume_contract${query}`);
};

const filtersearch = async (query) => {
  return await httpClient.get(`/term_resume_contract_search/${query}`);
};


const history = async (query) => {
  return await httpClient.get(`/term_val_resume_contract${query}`);
};

const getResume = async (id) => {
  return await httpClient.get(`/term_val_resume_contract/${id}`);
};


const interventors = async (project_id) => {
  return await httpClient.get(
    `/term_resume_contract/interventors/?filter[proyecto]=${project_id}`,
  );
};

const reject = async (id) => {
  return await httpClient.get(`/term_resume_contract/${id}/reject/`);
};

export {
  post,
  get,
  getAllBySelf,
  put,
  patch,
  remove,
  filter,
  filtersearch,
  history,
  getResume,
  interventors,
  reject,
};
