import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import { getAll } from '../../api/module/jobs';

const { Option } = Select;

const EmployeeRolForm = ({ form, onFinish }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    getAllJobs();
  }, []);

  const getAllJobs = async () => {
    const response = await getAll();
    setJobs(response.data.results);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical">
      <Form.Item
        name="cargo"
        label="Digite el cargo del empleado dentro de Corpoeducación"
        rules={[{ required: true }]}
      >
        <Select allowClear showSearch optionFilterProp="children">
          {jobs.map((item) => (
            <Option key={`item-0-${item.id}`} value={item.id}>
              {item.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="depende_de"
        label="¿De quién depende?"
        rules={[{ required: true }]}
      >
        <Select allowClear showSearch optionFilterProp="children">
          {jobs.map((item) => (
            <Option key={`item-1-${item.id}`} value={item.id}>
              {item.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="le_reportan"
        label="¿Quienes le reportan?"
        rules={[{ required: true }]}
      >
        <Select allowClear showSearch optionFilterProp="children" mode="multiple">
          {jobs.map((item) => (
            <Option key={`item-2-${item.id}`} value={item.id}>
              {item.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default EmployeeRolForm;
