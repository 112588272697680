import React, { useState, useEffect } from 'react';
import { notification, Row, Col, Space } from 'antd';
import { getByPeople } from '../../api/module/restrictive_list';
import MESSAGES from '../../enums/Messages';
import CustomButton from '../CustomButton';
import HistoryList from '../../pages/AnnouncementSelection/ValidationRestrictive/HistoryList';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import SpinLoad from "../SpinLoad";

export default function RestrictiveListTable({ tercero, disabled = false }) {
  const [historyList, setHistoryList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const getHistoryLists = async () => {
    try {
      setIsLoading(true);
      const response = await getByPeople(tercero);
      setHistoryList(response.data.results);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getHistoryLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <SpinLoad loading={isLoading}>
        <HistoryList dataSource={historyList} />
      </SpinLoad>
      <Row gutter={12}>
        <Col span={12}>
          <CustomButton
            loading={isLoading}
            onClick={getHistoryLists}
            text="Actualizar historial"
            htmlType="button"
          />
        </Col>
        <Col span={12} align="end">
          <CustomButton
            type="default"
            className={'ant-btn-info'}
            text={
              <Link
                to={`${INTERNAL_LINKS.RESTRICTIVE_LIST}/${tercero}`}
                target="_blank"
              >
                Cargar nueva consulta a listas restrictivas
              </Link>
            }
            disabled={disabled}
          />
        </Col>
      </Row>
    </Space>
  );
}
