import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/term_specifications/`, data);
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_specifications/`);
};

const getAllByTerm = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_specifications/?filter[termino]=${id}`);
};

const getAllByTermPagos = async (id) => {
  return (await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_specifications/?filter[termino]=${id}&filter[tiene_pagos]=1`));
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_specifications/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/term_specifications/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/term_specifications/${id}`);
};

export {
  post,
  getAll,
  getAllByTerm,
  getAllByTermPagos,
  get,
  put,
  remove
}