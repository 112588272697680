import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert, notification, Col, Typography, Row } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import { get as getProject } from '../../api/module/projects';
import { getAll } from '../../api/module/contract_types';

import './style.scss';

const { Title, Paragraph } = Typography;

export default function ContractTypes({ match }) {
  const [project_id] = useState(
    parseInt(match.params.project_id),
  );
  const [project, setProject] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  const breadcrumbItems = [
    {
      text: 'Proyectos',
      to: `${INTERNAL_LINKS.PROJECTS}`,
    },
    {
      text: 'Términos de referencia por proyecto',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'TDR Selección tipo contrato',
    },
  ];

  const handleGetProject = () => {
    try {
      getProject(project_id).then((res) => setProject(res.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetContract = () => {
    try {
      getAll().then((res) => setDataSource(res.data.results));
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    handleGetProject();
    handleGetContract();
  }, []);

  return (
    <div>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>Creación de Términos de Referencia</Title>
        <Title level={3}>Proyecto: {project != null && project.nombre}</Title>
      </div>
      <div className="box-description">
        <Paragraph>
          A continuación encontrará el paso previo para acceder al formulario de
          términos de referencia, por favor seleccione el tipo de contato que
          aplica para los TDR que desea crear.
        </Paragraph>
      </div>
      <Title level={5}>Seleccione el tipo de contrato antes de empezar.</Title>
      <div className="site-layout-content">
        {dataSource.map((item, index) => (
          <Row key={index}>
            <Col xs={6} md={7} lg={9}>
              <div className="btn-position">
                {item.id < 6 && (
                  <CustomButton
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.TERM}/0/${project_id}/${item.id}`}
                      >
                        {item.nombre}
                      </Link>
                    }
                  />
                )}
              </div>
            </Col>
            <Col xs={18} sm={12} md={16} lg={15}>
              {item.descripcion != null && item.descripcion != '' ? (
                <Alert
                  className="box-text-background"
                  style={{ margin: '16px 0' }}
                  message={item.descripcion}
                />
              ) : null}
            </Col>
          </Row>
        ))}
      </div>
      <div>
        <CustomButton
          danger
          text={<Link to={`${INTERNAL_LINKS.TDR_HOME}`}>Volver</Link>}
        />
      </div>
    </div>
  );
}
