import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/template/`, data, {
    responseType: 'blob',
  });
};

const postWord = async (data) => {
  return await httpClient.post(`/template/`, data);
};

export { post, postWord };
