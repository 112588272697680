import httpClient from '../httpClient';

const change_password = async (id, data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/users/${id}/change_password/`, data);
};
const recover_password = async (id, data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/users/${id}/recover_password/`, data);
};

export {
  change_password,
  recover_password
};
