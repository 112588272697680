import httpClient from '../httpClient';

const postMultiple = async (data) => {
	return await httpClient.post(`/employees/multiple/`, data);
};

const post = async (data) => {
	return await httpClient.post(`/employees/`, data);
};

const getAll = async () => {
  return await httpClient.get(`/employees/?page[size]=1000`);
};

const getAllActive = async () => {
  return await httpClient.get(`/employees/?page[size]=1000&filter[estado_interventoria]=true`);
};

const get = async (id) => {
  return await httpClient.get(`/employees/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/employees/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/employees/${id}`);
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  postMultiple,
  getAllActive,
}
