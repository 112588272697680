import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';

import StatesForm from '../../../containers/StatesForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/states';
import {
  get as getCountry,
  getAll as getAllCountries,
} from '../../../api/module/countries';

import './style.scss';

const StatesForms = ({ history, match }) => {
  const [statesID] = useState(parseInt(match.params.id));
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Creación de Departamentos');
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      setLoading(true);

      if (!statesID) {
        response = await post(data);
      } else {
        response = await put(statesID, data);
      }

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.STATES);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Get Data States by ID
  const handleGetCountries = () => {
    try {
      getAllCountries().then((res) => setCountries(res.data.results));
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  // Get Data Coutries by ID
  const handleGet = async () => {
    try {
      if (statesID) {
        const response = await get(statesID);
        const response2 = await getCountry(response.data.pais);
        response.data.nombreCountry = response2.data.nombre;
        setData(response.data);
        setTitle('Actualización de Departamentos');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    handleGet();
    handleGetCountries();
  }, []);

  return (
    <Skeleton loading={data === null}>
      <StatesForm
        title={title}
        onFinish={save}
        initialValues={data}
        loading={loading}
        countries={countries}
      />
    </Skeleton>
  );
};

export default StatesForms;
