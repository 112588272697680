import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/rangeAge';

import './style.scss';

const SelectRangeAge = ({
  className = '',
  onChange = () => {},
  rango_edad = null,
  disabled = false,
}) => {
  const [data, setData] = useState(null);

  const getTRangeAge = async () => {
    const response = await getAll();
    setData(response.results);
  };

  useEffect(async () => {
    await getTRangeAge();
  }, []);

  return (
    <Select
      laceholder="Seleccione el rango de edad"
      className={className}
      onChange={onChange}
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      defaultValue={rango_edad}
    >
      {data != null &&
        data.map((item) => (
          <Select.Option key={`range-age-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
export default SelectRangeAge;
