import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Form, Input, Alert, Space, Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined} from "@ant-design/icons";

import { CustomButton } from "../../components";
import { recover_password } from "../../api/module/user_password";

import InternalLinks from "../../enums/InternalLinks";

import "./style.scss";
import PublicHeader from "../PublicHeader";

export default class UserRecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      alert: {
        show: false
      }
    }
  }

  async doRecoverPassword(data) {
    const { id, token } = this.props.match.params;

    try {
      this.setState({ loading: true });
      this.setState({ alert: {
          show: false
        }
      });

      data["token"] = token;
      const response = await recover_password(id, data);

      this.setState({loading: false});

      if (response.status === 200) {
        this.setState({
          alert: {
            show: true,
            message: "Bienvenido!",
            description: "Su contraseña ha sido cambiada, su anterior contraseña ya no funcionará para ingresar",
            type: "success"
          }
        });
      }
    } catch (exception) {
      this.setState({ loading: false });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = "No se pudo cambiar la contraseña.";
      }

      this.setState({
        alert: {
          show: true,
          message: "Error",
          description: description,
          type: "error"
        }
      });
    }
  }

  render() {
    const loading = this.state.loading;
    const alert = this.state.alert;

    return (
      <Row justify={"center"} align={"top"}>
        <Col xs={22} sm={17} md={12} lg={9} xl={7} className={"form-container"}>
          <PublicHeader/>
          <PageHeader className={"public-page-header"} title={"Cambiar contraseña"} />
          <Form name={"normal_password"} layout={"vertical"} requiredMark={"optional"} scrollToFirstError={true}
                onFinish={(data) => this.doRecoverPassword(data)}>
            <Form.Item label={"Nueva contraseña"} name={"new_password"} required tooltip={"Ingrese su nueva contraseña"}
                       rules={[
                         {
                           required: true,
                           message: "Ingrese su nueva contraseña",
                         },
                       ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                onPaste={(e) => e.preventDefault()}
                placeholder="Nueva contraseña"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                allowClear
              />
            </Form.Item>
            <Form.Item label={"Confirme su nueva contraseña"} name={"password_confirm"} required
                       tooltip={"Ingrese su nueva contraseña"}
                       dependencies={['new_password']}
                       rules={[
                         {
                           required: true,
                           message: "Ingrese su nueva contraseña de nuevo",
                         },
                         ({ getFieldValue }) => ({
                           validator(_, value) {
                             if (!value || getFieldValue("new_password") === value) {
                               return Promise.resolve();
                             }

                             return Promise.reject("La nueva contraseña no coincide!");
                           },
                         }),
                       ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                onPaste={(e) => e.preventDefault()}
                placeholder="Confirmar nueva contraseña"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                allowClear
              />
            </Form.Item>
            <Space direction={"vertical"} size={"small"} className={"w-100"}>
              <Form.Item className="mb-0">
                <Space direction={"vertical"} size={"small"} className={"w-100"}>
                  <CustomButton text={"Cambiar contraseña"} loading={loading} block />
                  <Link to={InternalLinks.HOME}>
                    <Button danger block>Volver</Button>
                  </Link>
                </Space>
              </Form.Item>
              { alert.show &&
                <Alert
                  message={alert.message}
                  description={alert.description}
                  type={alert.type}
                  showIcon
                />
              }
            </Space>
          </Form>
        </Col>
      </Row>
    );
  }
}
