export default {
  SUCCESS_MSG: 'Transacción exitosa',
  DESC_SUCCESS_MSG: 'Se ha almacenado correctamente.',
  DESC_SUCCESS_DELETE_MSG: 'Se ha eliminado correctamente!',
  DESC_SUCCESS_TEAM_NOTIFY: 'Se ha enviado la notificación al correo!',
  DESC_SUCCESS_SEND_INVITATIONS_MSG:
    'Se ha enviado la notificación de la convocatoria a los correos de los usuarios elegidos.',
  ERROR_MSG: 'Error',
  ERROR_MSG_DELETE: 'No es posible eliminar este registro',
  DESC_ERROR_POST_MSG: 'Ha ocurrido un error, no se ha podido guardar. ',
  DESC_ERROR_ADD_CONTRACTOR_UNIQUE_MSG:
    'El contratista ya esta registrado en el cargo.',
  DESC_ERROR_GET_MSG: 'Ha ocurrido un error, no se ha podido obtener los datos',
  DESC_ERROR_DELETE_MSG: 'Ha ocurrido un error, no se ha podido eliminar',
  DESC_ERROR_DELETE_MSG_AREAS: 'Se encuentra asociado a términos de referencia',
  DESC_ERROR_DELETE_MSG_CURRENCYTYPE: 'Ha ocurrido un error, no se ha podido eliminar',
  DESC_ERROR_DELETE_MSG_CURRENCYTYPE_F: 'La moneda esta relacionada en un proyecto',
  DELETE_CONFIRM_MSG: '¿Está seguro(a) que desea eliminar?',
  DELETE_CONFIRM_MSG_POSITION:
    '¿Está seguro(a) de eliminar el registro? Recuerde que si elimina el registro, se eliminarán las personas que haya asignado a este Cargo/Rol.',
  END_CONFIRM_MSG: '¿Está seguro(a) de terminar esta convocatoria?',
  DESC_ERROR_ADD_PAY:
    'No puede agregar el pago. No hay obligaciones y/o pagos agregados',
  DESC_ERROR_ADD_CONTRACTOR:
    'No se puede agregar el contratista. Verifica los datos seleccionados',
  ERROR_FILE_PDF: 'Error al cargar archivo',
  DESC_ERROR_FILE_PDF: 'Seleccione un documento en formato PDF',
  DESC_ERROR_SEACRH_MSG:
    'Ha ocurrido un error, no se ha podido completar la busqueda',
  DESC_ERROR_UPLOAD_FILE_MSG:
    'Ha ocurrido un error, no se ha podido subir el archivo, seleccione una archivo PDF',
  DESC_SERVER_ERROR_DELETE_MSG:
    'No se ha podido eliminar debido a que tiene una referencia con otro registro, eliminarlo podría afectar otros registros que estan asociados',
  DESC_ERROR_SEND_INVITATIONS_MSG:
    'No se ha podido enviar la notificación de la convocatoria debido a un error',
  DESC_ERROR_SEND_CONFIRMATION_MSG:
    'No se ha podido enviar la solicitud para aplicar a este cargo',
  DESC_ERROR_TEMPLATE_MSG:
    'El archivo cargado presenta errores, verifiquelo y carguelo de nuevo',
  DESC_SUCCESS_REGISTER_MSG:
    'Por favor, revise el correo electrónico registrado, incluyendo notificaciones, publicidad y correo no deseado (spam), donde se indicarán los pasos a seguir para que el registro sea exitoso.',
  TIME: 3000,
  TIME_SECONDS: 3,
  TIME_RELOAD: 3000,
  MSG_SEND_EMAIL: '¿Está seguro(a) de enviar el correo electrónico?',
  MSG_SAVE_RECORD: '¿Está seguro(a) de guardar este registro?',
  MSG_SAVE_STATE: '¿Está seguro(a) de guardar este estado?',
  MSG_UNSAVED_CHANGES: 'Tiene cambios sin guardar',
  MSG_UNSAVED_MSG: 'No se guardaran los cambios. ¿Desea continuar?',
};
