import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Layout } from 'antd';
import moment from 'moment';

import AnnouncementShowForm from '../../containers/AnnouncementShowForm';
import { useQuery } from '../../utils/helpers/helpers';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { get } from '../../api/module/announcement';
import { filter as announcement_by_rol } from '../../api/module/announcement_rol';

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItemsHistory = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Histórico Convocatorias',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT_HISTORY}`,
  },
  {
    text: 'Previsualización Convocatoria',
  },
];

const breadcrumbItemsFinish = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias Caducadas',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT_FINISH}`,
  },
  {
    text: 'Previsualización Convocatoria',
  },
];

const AnnouncementShow = ({ history, match }) => {
  let query = useQuery();
  const Annhistory = query.get('history') === 'true';
  const Annfinish = query.get('finish') === 'true';
  const AnnPreview = query.get('preview') === 'true';
  const show = query.get('show') === 'true';
  const [announcement_id] = useState(match.params.announcement_id);
  const [announcement_type_id] = useState(match.params.announcement_type_id);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [initialValuesCargoRol, setInitialValuesCargoRol] = useState(null);

  const breadcrumbItems = [
    {
      text: 'Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
    },
    {
      text: 'Crear Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${announcement_type_id}/${announcement_id}`,
    },
    {
      text: 'Previsualización Convocatoria',
    },
  ];

  useEffect(() => {
    const handleGet = async () => {
      try {
        setIsLoading(true);
        const response = await get(announcement_id);
        if (response.data.fecha_inicio) {
          response.data.fecha_inicio = moment(response.data.fecha_inicio);
        }
        if (response.data.fecha_fin) {
          response.data.fecha_fin = moment(response.data.fecha_fin);
        }
        if (response.data.tipo === true) {
          response.data.proyecto = null;
        }
        if (response.data.tipo === false) {
          response.data.proyecto_nombre = null;
        }
        const responseRoles = await announcement_by_rol(
          '?filter[convocatoria]=' + announcement_id,
        );
        setInitialValuesCargoRol(responseRoles.data.results);
        setInitialValues(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    if (announcement_id) handleGet();
  }, [announcement_id]);

  const back = () => {
    history.goBack();
  };

  return (
    <Content>
      {show ? null : Annhistory ? (
        <BreadcrumbContainer items={breadcrumbItemsHistory} />
      ) : Annfinish ? (
        <BreadcrumbContainer items={breadcrumbItemsFinish} />
      ) : (
        <BreadcrumbContainer items={breadcrumbItems} />
      )}
      {show ? null : (
        <Title className={'text-uppercase'} level={2}>
          Previsualización de convocatoria
        </Title>
      )}
      <Skeleton loading={isLoading}>
        {initialValues && initialValuesCargoRol && (
          <AnnouncementShowForm
            initialValues={initialValues}
            loading={isLoading}
            initialValuesCargoRol={initialValuesCargoRol}
            back={back}
            isPreview={AnnPreview}
          />
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementShow;
