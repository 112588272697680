import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';

import PolicyForm from '../../../containers/PolicyForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import { post, put, get } from '../../../api/module/politics';

export default function PoliticsForms({ history, match }) {
  const [policyID] = useState(parseInt(match.params.id));
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(
    'Creación de políticas, lineamientos y/o protocolos específicos',
  );
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      setLoading(true);

      if (!policyID) {
        response = await post(data);
      } else {
        response = await put(policyID, data);
      }

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.POLITICS);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      if (policyID) {
        get(policyID).then((res) => setData(res.data));
        setTitle(
          'Actualización de políticas, lineamientos y/o protocolos específicos',
        );
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    handleGetData();
  }, []);

  const policy = [
    { label: 'Política', value: 'PO' },
    { label: 'Lineamiento', value: 'LI' },
    { label: 'Protocolo', value: 'PR' },
  ];

  return (
    <Skeleton loading={data === null}>
      <PolicyForm
        title={title}
        onFinish={save}
        initialValues={data}
        loading={loading}
        policy={policy}
      />
    </Skeleton>
  );
}
