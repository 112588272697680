import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Radio,
  Typography,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Layout,
} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import INTERNAL_LINKS from '../../enums/InternalLinks';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { Option } = Select;

const EducationContractorsForm = ({
  ids,
  onFinish,
  loading,
  educationTypes,
  knowledgeAreas,
  educationLevels,
  countries,
  initialValues,
  semesters,
  back,
  showClick,
  isLoadingDoc,
}) => {
  const [form] = Form.useForm();

  const [inProcess, setInProcess] = useState(null);
  const [educationType, setEducationType] = useState(null);
  const [educationLevel, setEducationLevel] = useState(null);

  const breadcrumbItems = [
    {
      text: 'Proveedores y Consultores - Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSONS}`,
    },
    {
      text: 'Registro de Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSON}/${ids.person_id}`,
    },
    {
      text: 'Educación',
    },
  ];

  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  useEffect(() => {
    if (initialValues.tipo_educacion) {
      setEducationType(initialValues.tipo_educacion);
    } else {
      setEducationType(1);
    }
    if (initialValues.nivel_educativo) {
      setEducationLevel(initialValues.nivel_educativo);
    }
    if (initialValues.finalizado) {
      setInProcess(initialValues.finalizado);
    }
  }, []);

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Educación
      </Title>
      <Paragraph>
        Recuerde que solo debe agregar la información relacionada con el
        quehacer de Corpoeducación (información que considere relevante) de la
        más reciente a la más antigua, de lo contrario dicha información puede
        ser eliminada de la plataforma.
      </Paragraph>
      <Form
        name="nest-messages"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{ ...initialValues, tipo_educacion: 1 }}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="tipo_educacion"
              label="Tipo de educación"
              rules={[
                {
                  required: true,
                  message: 'Es requerido que seleccione un tipo de educación',
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  setEducationType(value);
                }}
                allowClear
                placeholder="Tipo de educación..."
              >
                {educationTypes != null && (
                  <>
                    {educationTypes.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nivel_educativo"
              label="Seleccione el nivel educativo"
              rules={[
                {
                  required: true,
                  message: 'Es requerido que seleccione el nivel educativo',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Nivel educativo..."
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => {
                  setEducationLevel(value);
                }}
              >
                {educationLevels != null && (
                  <>
                    {educationLevels.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {educationLevel !== 1 && (
          <>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="pais"
                  label="País"
                  rules={[
                    {
                      required: true,
                      message: 'Se requiere que selecccione un país',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="País..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries != null && (
                      <>
                        {countries.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="finalizado"
                  label="Estado actual"
                  rules={[
                    {
                      required: true,
                      message: 'El campo finalizado o en curso es requerido!',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(event) => {
                      setInProcess(event.target.value);
                    }}
                  >
                    <Radio value={true}>Finalizado</Radio>
                    <Radio value={false}>En curso</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="institucion"
                  label="Institución"
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que ingrese el nombre de la institución',
                    },
                  ]}
                >
                  <Input placeholder="Institución..." allowClear />
                </Form.Item>
              </Col>
            </Row>
            {educationLevel !== 4 && (
              <>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="area_conocimiento"
                      label="Área del conocimiento"
                      rules={[
                        {
                          required: true,
                          message:
                            'Es requerido que seleccione el área del conocimiento',
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Área del conocimiento ..."
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {knowledgeAreas != null && (
                          <>
                            {knowledgeAreas.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nombre}
                              </Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="titulo_nombre_programa"
                      label={
                        form.getFieldValue('tipo_educacion') === 1 &&
                        form.getFieldValue('finalizado') === true
                          ? 'Título obtenido'
                          : 'Nombre del programa'
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Es requerido que ingrese título obtenido',
                        },
                      ]}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  {educationType === 1 && inProcess === false && (
                    <Col span={24}>
                      <Form.Item
                        name="semestre"
                        label="Semestre actual"
                        rules={[
                          {
                            required: true,
                            message:
                              'Es requerido que seleccione el semestre actual',
                          },
                        ]}
                      >
                        <Select allowClear showSearch placeholder="Semestre...">
                          {semesters != null && (
                            <>
                              {semesters.map((semester) => (
                                <Option key={semester} value={semester}>
                                  {semester}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {inProcess === true && (
                    <Col span={4}>
                      <Form.Item
                        name="fecha_terminacion"
                        label={
                          educationType === 1 && inProcess === true
                            ? 'Fecha de grado'
                            : 'Fecha de Terminación'
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {educationType === 2 && inProcess === true && (
                  <Row gutter={12}>
                    <Col span={4}>
                      <Form.Item
                        name="horas"
                        label="Total horas"
                        rules={[
                          {
                            required: true,
                            message:
                              'Es requerido que ingrese el total de horas cursadas',
                          },
                        ]}
                      >
                        <Input placeholder="Horas..." allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={12}>
                  <Form.Item
                    noStyle
                    dependencies={[
                      'nivel_educativo',
                      'finalizado',
                      'tipo_educacion',
                    ]}
                  >
                    {({ getFieldValue }) =>
                      educationType === 1 &&
                      inProcess === true &&
                      getFieldValue('nivel_educativo') === 7 ? (
                        <>
                          <Col span={24}>
                            <Form.Item
                              name="tarjeta_profesional"
                              label="¿Cuenta con Tarjeta profesional?"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Radio.Group>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              noStyle
                              dependencies={['tarjeta_profesional']}
                            >
                              {({ getFieldValue }) =>
                                getFieldValue('tarjeta_profesional') ===
                                true ? (
                                  <Row gutter={10} align="middle">
                                    <Col span={20}>
                                      <CustomUpload
                                        upload_type={2}
                                        file_type={1}
                                        required={
                                          !initialValues.documento_tarjeta_profesional_nombre
                                        }
                                        name="tarjeta"
                                        label="Tarjeta profesional"
                                        tooltip="Tamaño del archivo máximo de 2MB en formato PDF"
                                      />
                                    </Col>
                                    <Col>
                                      {initialValues.documento_tarjeta_profesional_nombre && (
                                        <CustomButton
                                          style={{ marginTop: 5 }}
                                          type="default"
                                          htmlType="button"
                                          text="Ver"
                                          loading={isLoadingDoc}
                                          onClick={() => {
                                            showClick(
                                              initialValues.documento_tarjeta_profesional_nombre,
                                            );
                                          }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                ) : null
                              }
                            </Form.Item>
                          </Col>
                        </>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item noStyle dependencies={['pais']}>
                    {({ getFieldValue }) =>
                      getFieldValue('pais') !== 45 ? (
                        <Col span={12}>
                          <Form.Item
                            name="homologado_colombia"
                            label="¿Título homologado en Colombia?"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Es requerido que seleccione una opción',
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={true}>Si</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      ) : null
                    }
                  </Form.Item>
                </Row>
              </>
            )}
            <Row gutter={12} align="middle">
              <Col span={20}>
                <CustomUpload
                  upload_type={2}
                  file_type={1}
                  required={!initialValues.documento_certificado_nombre}
                  name="certificacion"
                  label="Cargar Certificación o título"
                  tooltip="Tamaño del archivo máximo de 2MB en formato PDF"
                />
              </Col>
              <Col span={4}>
                {initialValues.documento_certificado_nombre && (
                  <CustomButton
                    style={{ marginTop: 5 }}
                    type="default"
                    htmlType="button"
                    text="Ver"
                    loading={isLoadingDoc}
                    onClick={() => {
                      showClick(initialValues.documento_certificado_nombre);
                    }}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Space>
                <CustomButton loading={loading} text="Guardar" />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default EducationContractorsForm;
