import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getByProject } from '../../api/module/project_category';

export const SelectProjectCategories = ({
  className = '',
  onChange = () => {},
  onChangeData = null,
  proyecto,
  categoria,
  disabled = false,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let isMounted = true;
    getByProject(proyecto).then((res) => {
      if (isMounted) setData(res.data.results);
    });
    return () => (isMounted = false);
  }, []);

  const onSelfChange = (value, option) => {
    if (!onChangeData) onChange(value, option);
    else onChangeData(value ? data.find((item) => item.id === value) : null);
  };

  return (
    <>
      <Select
        placeholder="Seleccione la prueba o subcategoría"
        className={className}
        onChange={onSelfChange}
        defaultValue={categoria ? categoria : null}
        disabled={disabled}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data != null &&
          data.map((item) => (
            <Select.Option
              key={`project-category-key-${item.id}`}
              value={item.id}
            >
              {item.nombre}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default SelectProjectCategories;
