import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Typography,
  notification,
  Badge,
  Layout,
  Tag,
  Input,
  Modal,
  Row,
  Col,
} from 'antd';
import { Link } from 'react-router-dom';

import MESSAGES from '../../enums/Messages';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';

import {
  ajaxTable,
  getCounters,
  saveStatus as doSaveStatus,
  projectSelector,
  roleSelector,
} from '../../api/module/insurance_policy';

import { ClockCircleOutlined } from '@ant-design/icons';
import AjaxTable from '../../components/AjaxTable';
import CustomSelector from '../../components/CustomSelector';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Revisión Pólizas',
  },
];

const initCounters = () => ({
  review: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  incomplete: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  approved: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
});

const InsurancePolicyHome = ({ match }) => {
  const [counters, setCounters] = useState(initCounters);

  const [activeTab, setActiveTab] = useState(match.params.tab || 'review');

  const [statusLoading, setStatusLoading] = useState(undefined);

  const [project, setProject] = useState(
    parseInt(match.params.project_id) || undefined,
  );

  const [query, setQuery] = useState(
    match.params.project_id
      ? `filter[proyecto]=${match.params.project_id}`
      : '',
  );

  async function fetchCounters(newTab) {
    try {
      setCounters(initCounters);
      const counters = await getCounters(query);
      setCounters(counters.data.results);
      if (newTab !== activeTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  async function saveStatus(record) {
    if (!record.ev_calculado)
      Modal.confirm({
        title: 'Información SIGA',
        content: 'Falta(n) módulo(s) por revisar',
        okText: 'Cerrar',
        cancelButtonProps: { style: { display: 'none' } },
      });
    else {
      try {
        setStatusLoading(record.id);
        const res = await doSaveStatus(record.id);
        fetchCounters(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setStatusLoading(undefined);
      }
    }
  }

  useEffect(() => {
    fetchCounters(activeTab);
  }, [query]);

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'Nombre Contratista / Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      width: '5rem',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
      width: '3rem',
    },
    {
      title: '# Modificación',
      dataIndex: 'modificacion_num',
      width: '2rem',
      align: 'center',
    },
    {
      title: 'Estado adjunto',
      dataIndex: 'estado_adjunto',
      width: '3rem',
      align: 'center',
      render: (t) =>
        t === 'Enviado' ? (
          <p style={{ color: 'rgba(150, 125, 185, 1)' }}>{t}</p>
        ) : (
          <p style={{ color: 'rgba(240, 80, 95, 1)' }}>{t}</p>
        ),
    },
    {
      title: 'Validador precontractual',
      dataIndex: 'validador_nombre',
    },
  ];

  const extraColumnsToCheck = [
    {
      title: 'Validar Documentos',
      key: 'validate_button',
      width: '5rem',
      render: (_, record) => (
        <CustomButton
          block
          type="primary"
          htmlType={'button'}
          text={
            <Link to={`${INTERNAL_LINKS.INSURANCE}-validar/${record.id}`}>
              Validar
            </Link>
          }
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: '12rem',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Paragraph>{record.ev_calculado || 'Sin revisar'}</Paragraph>
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              text="Guardar"
              onClick={() => saveStatus(record)}
              disabled={statusLoading}
              loading={statusLoading === record.id}
            />
          </>
        );
      },
    },
  ];

  const extraColumnsAdjust = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <>
          <CustomButton
            block
            type={'default'}
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link to={`${INTERNAL_LINKS.INSURANCE}-email/${record.id}`}>
                Generar Correo
              </Link>
            }
          />
          {record.estado_correo && <b>Enviado</b>}
        </>
      ),
    },
    {
      title: 'Historial de correos',
      key: 'datetime',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.INSURANCE}-email-history/${record.id}`}
              target="_blank"
            >
              Historial
            </Link>
          }
        />
      ),
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link to={`${INTERNAL_LINKS.INSURANCE}-validar/${record.id}`}>
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO REVISIÓN DE PÓLIZAS
      </Title>
      <br />
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Proyecto </Title>
        </Col>

        <Col span={17} offset={0.5}>
          <CustomSelector
            onChange={(v) => {
              setProject(v);
              setQuery(v ? `filter[proyecto]=${v}` : '');
            }}
            endpointFetch={async () => {
              return await projectSelector();
            }}
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el Proyecto...',
            }}
            initialValue={project}
          />
        </Col>
      </Row>
      {project && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo </Title>
          </Col>

          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(v) =>
                setQuery(
                  v ? `filter[cargo]=${v}` : `filter[proyecto]=${project}`,
                )
              }
              endpointFetch={async () => {
                return await roleSelector(project);
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo...',
              }}
              initialValue={
                match.role != undefined ? parseInt(match.role) : undefined
              }
              key={project}
            />
          </Col>
        </Row>
      )}
      <br />
      <Tabs
        defaultActiveKey={'review'}
        activeKey={activeTab}
        onTabClick={(key, _) => {
          setActiveTab(key);
        }}
        destroyInactiveTabPane={true}
        type="card"
      >
        <TabPane
          tab={
            <Paragraph>
              Por revisar
              <Badge showZero count={counters.review} />
            </Paragraph>
          }
          key="review"
        >
          <AjaxTable
            columns={[...columns, ...extraColumnsToCheck]}
            endpoint={ajaxTable}
            query={`${query}&filter[estado_validacion]=75`}
            reloadButton
            onReload={() => fetchCounters('review')}
            searchInput
            exportCSV
          />
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Ajustar información
              <Badge showZero count={counters.incomplete} />
            </Paragraph>
          }
          key="incomplete"
        >
          <AjaxTable
            columns={[...columns, ...extraColumnsAdjust]}
            endpoint={ajaxTable}
            query={`${query}&filter[estado_validacion]=76`}
            reloadButton
            onReload={() => fetchCounters('incomplete')}
            searchInput
            exportCSV
          />
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Aprobados completo
              <Badge showZero count={counters.approved} />
            </Paragraph>
          }
          key="approved"
        >
          <AjaxTable
            columns={[...columns, ...extraColumnsApproved]}
            endpoint={ajaxTable}
            query={`${query}&filter[estado_validacion]=77`}
            reloadButton
            onReload={() => fetchCounters('approved')}
            searchInput
            exportCSV
          />
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default InsurancePolicyHome;
