import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Form, Input, Alert, Space, Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";

import { CustomButton } from "../../components";
import { email } from "../../api/module/user_email";

import InternalLinks from "../../enums/InternalLinks";

import "./style.scss";
import PublicHeader from "../PublicHeader";

export default class UserEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      alert: {
        show: false
      }
    }
  }

  async doEmail(data) {
    try {
      this.setState({ loading: true });
      this.setState({ alert: {
          show: false
        }
      });

      const response = await email(data);

      this.setState({ loading: false });

      if (response.status === 200) {
        this.setState({
          alert: {
            show: true,
            message: "Correo enviado!",
            description: "Se ha enviado un correo electrónico con instrucciones",
            type: "success"
          }
        });
      }
    } catch (exception) {
      this.setState({ loading: false });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = "No se pudo enviar el correo de recuperacion de econtraseña.";
      }

      this.setState({
        alert: {
          show: true,
          message: "Error",
          description: description,
          type: "error"
        }
      });
    }
  }

  render() {
    const loading = this.state.loading;
    const alert = this.state.alert;

    return (
      <Row justify="center" align="top">
        <Col xs={22} sm={17} md={12} lg={9} xl={7} className="form-container">
          <PublicHeader/>
          <PageHeader className={"public-page-header"} title={"Recuperar contraseña"} />
          <Form name={"normal_email"} layout={"vertical"} requiredMark={"optional"} scrollToFirstError={true}
                onFinish={(data) => this.doEmail(data)}>
            <Form.Item label={"Correo electrónico"} name={"email"} required tooltip={"Ingrese su coreo electrónico"}
                       rules={[
                         {
                           type: "email",
                           message: "Ingrese un correo electrónico válido!",
                         },
                         {
                           required: true,
                           message: "Ingrese su correo electrónico",
                        },
                      ]}
            >
              <Input prefix={<MailOutlined/>} type={"email"} placeholder={"Correo electrónico"} allowClear />
            </Form.Item>
            <Space direction={"vertical"} size={"small"} className={"w-100"}>
              <Form.Item className={"mb-0"}>
                <Space direction={"vertical"} size={"small"} className={"w-100"}>
                  <CustomButton text={"Enviar correo"} loading={loading} block />
                  <Link to={InternalLinks.HOME}>
                    <Button danger block>Volver</Button>
                  </Link>
                </Space>
              </Form.Item>
              { alert.show &&
                <Alert
                  message={alert.message}
                  description={alert.description}
                  type={alert.type}
                  showIcon
                />
              }
            </Space>
          </Form>
        </Col>
      </Row>
    );
  }
}
