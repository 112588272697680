import React, { useState, useEffect } from 'react';
import {Alert, Layout} from 'antd';
import { withRouter, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import { IsRole } from '../../utils/helpers/helpers';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import ClientsPage from '../../pages/Clients/Form';
import ClientsList from '../../pages/Clients/List';
import TermsPage from '../../pages/Terms/Form';
import AreaList from '../../pages/Areas/List/index';
import AreasForms from '../../pages/Areas/Form/index';
import CurrencyTypeList from '../../pages/CurrencyTypes/List/index';
import CurrencyTypesForms from '../../pages/CurrencyTypes/Form/index';
import CostsList from '../../pages/Costs/List/index';
import CostsForms from '../../pages/Costs/Form/index';
import IdentitiesTypesList from '../../pages/IdentitiesTypes/List/index';
import IdentitiesTypesForms from '../../pages/IdentitiesTypes/Form/index';
import DocumentTypesList from '../../pages/DocumentTypes/List/index';
import DocumentTypesForms from '../../pages/DocumentTypes/Form/index';
import CountriesList from '../../pages/Countries/List/index';
import CountriesForms from '../../pages/Countries/Form/index';
import StatesList from '../../pages/States/List/index';
import StatesForm from '../../pages/States/Form/index';
import CitiesList from '../../pages/Cities/List/index';
import CitiesForms from '../../pages/Cities/Form/index';
import ResponsibilitiesForm from '../../pages/Responsibility/index';
import ProductsForm from '../../pages/Products/index';
import ContractPolicies from '../../pages/ContractPolicies/index';
import NaturalPersonList from '../../pages/NaturalPerson/List/index';
import NaturalPersonForms from '../../pages/NaturalPerson/Form/index';
import LegalPersonList from '../../pages/LegalPerson/List/index';
import LegalPersonForms from '../../pages/LegalPerson/Form/index';

import ContractTypes from '../../pages/ContractTypes/index';

import SearchContractor from '../../pages/SearchContractor/index';
import LaborContractors from '../../pages/LaborContractors/Form/index';
import EducationContractors from '../../pages/EducationContractors/Form/index';
import TermPayments from '../../pages/TermPayment/index';
import TermDifferentiatedValues from '../../pages/TermDifferentiatedValues/index';
import ExperiencieIcfes from '../../pages/ExperiencieIcfes/Form/index';
import OtherCertifications from '../../pages/OtherCertifications/Form/index';
import EmployeesPage from '../../pages/Employees';

import TDRHome from '../../pages/Terms/Home/index';
import RolesManagement from '../../pages/RolesManagement/index';

import HelpFormat from '../../pages/HelpFormat/index';
import ReportsForms from '../../pages/Reports/index';
import SigaappForms from '../../pages/Sigaapp/index';

import './style.scss';

import ContentManager from '../../containers/ContentManager/index';

import RejectTDR from '../../pages/RejectTDR';
import Paragraph from "antd/es/typography/Paragraph";
import {WarningOutlined} from "@ant-design/icons";
import ResumeContractListPage from "../../pages/RecumeContract/list";
import ResumeContractFormPage from "../../pages/RecumeContract/form";

const { Content } = Layout;

const ContentApp = ({ loading, history }) => {
  const [manager] = useState(IsRole(1));
  const [coordinator] = useState(IsRole(2));
  const [legal] = useState(IsRole(3));
  const [administrative] = useState(IsRole(4));
  const [lead] = useState(IsRole(8));
  const [principal] = useState(IsRole(9));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }, [history]);

  const TDRRoutes = () => {
    return (
      <>
        <Route
          exact
          component={(props) => {
            return <TDRHome {...props}></TDRHome>;
          }}
          path={`${INTERNAL_LINKS.TDR_HOME}`}
        />
        <Route
          exact
          component={(props) => {
            return <ContractTypes {...props}></ContractTypes>;
          }}
          path={`${INTERNAL_LINKS.CONTRACT_TYPE}/:project_id?`}
        />
        <Route
          exact
          component={(props) => {
            return <TermsPage {...props}></TermsPage>;
          }}
          path={`${INTERNAL_LINKS.TERM}/:id?/:project_id?/:contract_id?`}
        />
        <Route
          exact
          component={(props) => {
            return <ResponsibilitiesForm {...props}></ResponsibilitiesForm>;
          }}
          path={`${INTERNAL_LINKS.RESPONSIBILITIES}/:term_id?/:project_id?/:contract_id?`}
        />
        <Route
          exact
          component={(props) => {
            return <ProductsForm {...props}></ProductsForm>;
          }}
          path={`${INTERNAL_LINKS.PRODUCTS}/:term_id?/:project_id?/:contract_id?`}
        />
        <Route
          exact
          component={(props) => {
            return <ContractPolicies {...props}></ContractPolicies>;
          }}
          path={`${INTERNAL_LINKS.CONTRACT_POLICIES}/:term_id?/:project_id?/:contract_id?`}
        />
        <Route
          exact
          component={(props) => {
            return <TermPayments {...props}></TermPayments>;
          }}
          path={`${INTERNAL_LINKS.PAYRESPONSIBILITYPRODUCT}/0/:term_id?/:project_id?/:contract_id?`}
        />
        <Route
          exact
          component={(props) => {
            return (
              <TermDifferentiatedValues {...props}></TermDifferentiatedValues>
            );
          }}
          path={`${INTERNAL_LINKS.TERM_DIFFERENTIATED_VALUES}/:term_id/`}
        />
        <Route
          exact
          component={(props) => {
            return <SearchContractor {...props}></SearchContractor>;
          }}
          path={`${INTERNAL_LINKS.SEARCHCONTRACTOR}/:term_id?/:project_id?/:contract_id?`}
        />
      </>
    );
  };

  return (
    <Layout className="private-layout-container">
      <Content className="private-layout-content">
        {false && (
          <Alert
            style={{marginBottom: '15px'}}
            message={
              <Paragraph
                style={{fontSize: '40px', color: 'red', marginBottom: 10}}>Información importante
              </Paragraph>}
            description={
              <Paragraph strong style={{fontSize: '17px'}}>
                Le informamos que la plataforma entrará en actualización entre
                <span style={{color: 'red'}}> 12:00 m y 01:00 p.m.</span> el día hoy 17 de febrero de 2022.
                <br/>
                <br/><span style={{color: 'red'}}>Durante este periodo de tiempo no haga uso de la plataforma.</span>
                <br/>
                <br/>Si usa la plataforma en este periodo de tiempo no se garantiza el almacenamiento de la información.
              </Paragraph>}
            type="error"
            showIcon
            icon={<WarningOutlined style={{fontSize: 44}} />}
          />
          )}
        <ContentManager />
        <Switch>
          <Route
            exact
            component={(props) => {
              return <NaturalPersonForms {...props} />;
            }}
            path={`${INTERNAL_LINKS.NATURAL_PERSON}/:person_id?`}
          />
          <Route
            exact
            component={(props) => {
              return <LegalPersonForms {...props}></LegalPersonForms>;
            }}
            path={`${INTERNAL_LINKS.LEGAL_PERSON}/:person_id?`}
          />
          <Route
            exact
            component={(props) => {
              return <LaborContractors {...props}></LaborContractors>;
            }}
            path={`${INTERNAL_LINKS.LABOR_CONTRACTORS}/:tercero_id/:id?`}
          />
          <Route
            exact
            component={(props) => {
              return <EducationContractors {...props}></EducationContractors>;
            }}
            path={`${INTERNAL_LINKS.EDUCATION_CONTRACTORS}/:tercero_id/:id?`}
          />
          <Route
            exact
            component={(props) => {
              return <OtherCertifications {...props}></OtherCertifications>;
            }}
            path={`${INTERNAL_LINKS.OTHER_CERTIFICATIONS}/:tercero_id/:id?`}
          />
          <Route
            exact
            component={(props) => {
              return <ExperiencieIcfes {...props}></ExperiencieIcfes>;
            }}
            path={`${INTERNAL_LINKS.EXPERIENCIE_ICFES}/:tercero_id/:id?`}
          />
          <Route
            exact
            component={(props) => {
              return (
                <NaturalPersonForms
                  {...props}
                  readOnly={true}
                ></NaturalPersonForms>
              );
            }}
            path={`${INTERNAL_LINKS.NATURAL_PERSON}/ver/:person_id`}
          />
          <Route
            exact
            component={(props) => {
              return (
                <LegalPersonForms {...props} readOnly={true}></LegalPersonForms>
              );
            }}
            path={`${INTERNAL_LINKS.LEGAL_PERSON}/ver/:person_id`}
          />
          {(superUser || legal) && (
            <>
              <Route
                exact
                component={(props) => {
                  return <ClientsList {...props}></ClientsList>;
                }}
                path={INTERNAL_LINKS.CLIENTS}
              />
              <Route
                exact
                component={(props) => {
                  return <ClientsPage {...props}></ClientsPage>;
                }}
                path={`${INTERNAL_LINKS.CLIENT}/:client_id?`}
              />
              {!legal && (
                <>
                  <Route
                    exact
                    component={(props) => {
                      return <AreaList {...props}></AreaList>;
                    }}
                    path={INTERNAL_LINKS.AREAS}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <AreasForms {...props}></AreasForms>;
                    }}
                    path={`${INTERNAL_LINKS.AREA}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CurrencyTypeList {...props}></CurrencyTypeList>;
                    }}
                    path={INTERNAL_LINKS.CURRENCY_TYPES}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CurrencyTypesForms {...props}></CurrencyTypesForms>;
                    }}
                    path={`${INTERNAL_LINKS.CURRENCY_TYPE}/:id?`}
                  />    
                  <Route
                    exact
                    component={(props) => {
                      return <ReportsForms {...props}></ReportsForms>;
                    }}
                    path={INTERNAL_LINKS.REPORTS_TDR}
                  />   
                  <Route
                    exact
                    component={(props) => {
                      return <SigaappForms {...props}></SigaappForms>;
                    }}
                    path={INTERNAL_LINKS.SIGAAPP}
                  />                  
                  <Route
                    exact
                    component={(props) => {
                      return (
                        <IdentitiesTypesList {...props}></IdentitiesTypesList>
                      );
                    }}
                    path={INTERNAL_LINKS.IDENTITIES_TYPES}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return (
                        <IdentitiesTypesForms {...props}></IdentitiesTypesForms>
                      );
                    }}
                    path={`${INTERNAL_LINKS.IDENTITY_TYPE}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <DocumentTypesList {...props}></DocumentTypesList>;
                    }}
                    path={INTERNAL_LINKS.DOCUMENT_TYPES}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return (
                        <DocumentTypesForms {...props}></DocumentTypesForms>
                      );
                    }}
                    path={`${INTERNAL_LINKS.DOCUMENT_TYPE}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CostsList {...props}></CostsList>;
                    }}
                    path={INTERNAL_LINKS.COSTS}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CostsForms {...props}></CostsForms>;
                    }}
                    path={`${INTERNAL_LINKS.COST}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CountriesList {...props}></CountriesList>;
                    }}
                    path={`${INTERNAL_LINKS.COUNTRIES}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CountriesForms {...props}></CountriesForms>;
                    }}
                    path={`${INTERNAL_LINKS.COUNTRY}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <StatesList {...props}></StatesList>;
                    }}
                    path={`${INTERNAL_LINKS.STATES}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <StatesForm {...props}></StatesForm>;
                    }}
                    path={`${INTERNAL_LINKS.STATE}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CitiesList {...props}></CitiesList>;
                    }}
                    path={`${INTERNAL_LINKS.CITIES}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <CitiesForms {...props}></CitiesForms>;
                    }}
                    path={`${INTERNAL_LINKS.CITY}/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <NaturalPersonList {...props}></NaturalPersonList>;
                    }}
                    path={`${INTERNAL_LINKS.NATURAL_PERSONS}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <LegalPersonList {...props}></LegalPersonList>;
                    }}
                    path={`${INTERNAL_LINKS.LEGAL_PERSONS}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <LaborContractors {...props}></LaborContractors>;
                    }}
                    path={`${INTERNAL_LINKS.LABOR_CONTRACTORS}/:tercero_id/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return (
                        <EducationContractors {...props}></EducationContractors>
                      );
                    }}
                    path={`${INTERNAL_LINKS.EDUCATION_CONTRACTORS}/:tercero_id/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return (
                        <OtherCertifications {...props}></OtherCertifications>
                      );
                    }}
                    path={`${INTERNAL_LINKS.OTHER_CERTIFICATIONS}/:tercero_id/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <ExperiencieIcfes {...props}></ExperiencieIcfes>;
                    }}
                    path={`${INTERNAL_LINKS.EXPERIENCIE_ICFES}/:tercero_id/:id?`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <RolesManagement {...props}></RolesManagement>;
                    }}
                    path={`${INTERNAL_LINKS.ROLES_MANAGEMENT}`}
                  />
                  <Route
                    exact
                    component={(props) => {
                      return <HelpFormat {...props}></HelpFormat>;
                    }}
                    path={`${INTERNAL_LINKS.HELP_FORMAT}`}
                  />
                </>
              )}
              <Route
                exact
                component={(props) => {
                  return <EmployeesPage {...props}></EmployeesPage>;
                }}
                path={`${INTERNAL_LINKS.EMPLOYEES}`}
              />
              <Route
                exact
                component={(props) => {
                  return <RejectTDR {...props}></RejectTDR>;
                }}
                path={`${INTERNAL_LINKS.REJECT_TDR}`}
              />

              {TDRRoutes()}
            </>
          )}

          {manager && (
            <>
              {TDRRoutes()}
              <Route
                exact
                component={(props) => {
                  return <RejectTDR {...props}></RejectTDR>;
                }}
                path={`${INTERNAL_LINKS.REJECT_TDR}`}
              />
            </>
          )}

          {legal && <>{TDRRoutes()}</>}

          {coordinator && <>{TDRRoutes()}</>}

          {lead && (
            <>
              <Route
                exact
                component={(props) => {
                  return <ClientsList {...props}></ClientsList>;
                }}
                path={INTERNAL_LINKS.CLIENTS}
              />
              <Route
                exact
                component={(props) => {
                  return <ClientsPage {...props}></ClientsPage>;
                }}
                path={`${INTERNAL_LINKS.CLIENT}/:client_id?`}
              />
              {TDRRoutes()}
            </>
          )}

          {principal && (
            <>
              {TDRRoutes()}
              <Route
                exact
                component={(props) => {
                  return <RejectTDR {...props}></RejectTDR>;
                }}
                path={`${INTERNAL_LINKS.REJECT_TDR}`}
              />
            </>
          )}

          {assistant && <>{TDRRoutes()}</>}
          {administrative && <></>}
        </Switch>
      </Content>
    </Layout>
  );
};

export default withRouter(ContentApp);
