import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`/education_level/`, data);
};

const getAll = async () => {
  return await httpClient.get(`/education_level/`);
};

const get = async (id) => {
  return await httpClient.get(`/education_level/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/education_level/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/education_level/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`/education_level/${query}`);
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  filter,
}
