import React, { useEffect, useRef, useState } from 'react';
import {
  Descriptions,
  notification,
  Form,
  Space,
  Row,
  Col,
  Input,
  Typography,
  Card,
  Skeleton,
  Select,
  InputNumber,
  Popconfirm,
  Alert,
  Table,
  Divider,
  Tag,
} from 'antd';

import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import CustomButton from '../../../components/CustomButton';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  info as getInfo,
  filter as filterAjaxTable,
  patchModule,
  post,
  patch,
  remove,
} from '../../../api/module/mass_ctm_payments';
import { IsRole, catchException } from '../../../utils/helpers/helpers';
import '../Form/style.scss';
import AjaxTable from '../../../components/AjaxTable';
import ContractorsModal from '../MassForm/ContractorsModal';

const { Title } = Typography;

const { Option } = Select;

const MassModificationPayments = () => {
  var { modification_id } = useParams();
  let history = useHistory();
  const [isLegal] = useState(IsRole(3));

  const [dataInfo, setDataInfo] = useState();
  const [responsibilities, setResponsibilities] = useState([]);
  const [products, setProducts] = useState([]);

  const [initialValues, setInitialValues] = useState({});
  const [paymentForm] = Form.useForm();
  const [moduleForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [atentionVisible, setAtentionVisible] = useState(false);

  const [toDelete, setToDelete] = useState();
  const [reload, setReload] = useState(false);

  const [valueData, setValueData] = useState({});

  const scrollRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const { data } = await getInfo(modification_id);
        setDataInfo(data);
        setResponsibilities(data.responsabilidades);
        setProducts(data.productos);
        const compareTotal = data.valor_total_origen
          ? data.valor_total_modificado
          : data.valor_total_vigente;
        setValueData({
          total: compareTotal,
          currentTotalText: formatCurrency(data.valor_total_vigente),
          availableText: formatCurrency(
            data.valor_total_vigente - data.valor_no_disponible,
          ),
          totalModifiedText:
            data.valor_total_origen &&
            formatCurrency(data.valor_total_modificado),
          used: data.valor_asignado,
          usedText: formatCurrency(data.valor_asignado),
          pending: compareTotal - data.valor_asignado,
          pendingText: formatCurrency(compareTotal - data.valor_asignado),
        });
      } catch (err) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [modification_id]);

  useEffect(() => {
    paymentForm.resetFields();
  }, [initialValues]);

  const addToSelectors = (record) => {
    setResponsibilities((r) => [
      ...record.responsabilidades.map((x) => ({
        value: x.id,
        label: x.descripcion,
        requiere_eliminacion: x.requiere_eliminacion,
        aprobado_pago: x.aprobado_pago,
      })),
      ...r,
    ]);
    setProducts((p) => [
      ...record.productos.map((x) => ({
        value: x.id,
        label: x.descripcion,
        requiere_eliminacion: x.requiere_eliminacion,
        aprobado_pago: x.aprobado_pago,
      })),
      ...p,
    ]);
  };

  const removeFromSelectors = (record) => {
    if (record.responsabilidades && record.responsabilidades.length > 0) {
      const toRemoveR = record.responsabilidades;
      setResponsibilities((r) => r.filter((x) => !toRemoveR.includes(x.value)));
    }
    if (record.productos && record.productos.length > 0) {
      const toRemoveP = record.productos;
      setProducts((p) => p.filter((x) => !toRemoveP.includes(x.value)));
    }
  };

  const handleEdit = (record) => {
    if (initialValues?.id !== record.id) {
      if (initialValues?.id) removeFromSelectors(initialValues);
      addToSelectors(record);
    }
    setInitialValues({
      ...record,
      responsabilidades: record.responsabilidades.map((x) => x.id),
      productos: record.productos.map((x) => x.id),
    });
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeValueData = (alter) => {
    setValueData((s) => {
      const newUsed = s.used + alter;
      const newPending = s.total - newUsed;
      return {
        ...s,
        used: newUsed,
        usedText: formatCurrency(newUsed),
        pending: newPending,
        pendingText: formatCurrency(newPending),
      };
    });
  };

  const deleteRecord = async (record) => {
    try {
      setToDelete(record.id);
      await remove(record.id);
      changeValueData(-1 * record.valor);
      addToSelectors(record);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
        duration: 2,
      });
      setReload({});
      if (initialValues?.id === record.id) {
        removeFromSelectors(initialValues);
        setInitialValues({});
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    } finally {
      setToDelete();
    }
  };

  const childPayed = (child) => child.aprobado_pago;

  const columns = [
    {
      title: 'Pago No.',
      dataIndex: 'numero',
      key: 'numero',
    },
    {
      title: 'Valor de pago',
      dataIndex: 'valor',
      key: 'valor',
      render: (text) => {
        return `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      },
    },
    {
      title: 'Estado de pago / Pago aprobado',
      dataIndex: 'aprobado_pago',
      render: (text) => (text ? 'Aprobado Pago' : 'Pendiente'),
    },
    {
      title: 'Acciones',
      key: 'Acciones',
      align: 'center',
      width: 200,
      render: (_, record) =>
        !record.aprobado_pago && (
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <CustomButton
              block
              htmlType={'button'}
              text="Editar"
              onClick={() => handleEdit(record)}
            />
            <Popconfirm
              title={MESSAGES.DELETE_CONFIRM_MSG}
              onConfirm={() => deleteRecord(record)}
            >
              <CustomButton
                block
                type={'default'}
                htmlType={'button'}
                danger
                text="Eliminar"
                loading={toDelete === record.id}
                disabled={
                  record.responsabilidades.some(childPayed) ||
                  record.productos.some(childPayed)
                }
              />
            </Popconfirm>
          </Space>
        ),
    },
  ];

  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${dataInfo?.proyecto_id}/${modification_id}`,
    },
    {
      text: 'Modificación forma de pago',
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Tipo', dataIndex: 'tipo', width: '10rem', align: 'center' },
      { title: 'No.', dataIndex: 'codigo', width: '5rem', align: 'center' },
      { title: 'Descripción', dataIndex: 'descripcion' },
      {
        title: 'Estado',
        dataIndex: 'aprobado_pago',
        width: '10rem',
        render: (text) => (text ? 'Aprobado' : ''),
      },
      {
        title: 'Modificaciones',
        dataIndex: 'origen',
        render: (_, record) =>
          record.origen === 1
            ? ''
            : record.origen === 2
            ? 'Modificada'
            : 'Nueva',
        align: 'center',
        width: '10rem',
      },
    ];

    const dataResponsabilities = record.responsabilidades.map((x) => ({
      ...x,
      tipo: 'Obligación',
    }));
    const dataProducts = record.productos.map((x) => ({
      ...x,
      tipo: 'Producto',
    }));

    return (
      <Table
        columns={columns}
        rowKey="id"
        dataSource={[...dataResponsabilities, ...dataProducts]}
        pagination={false}
        title={() => 'Obligaciones y/o productos asociados'}
      />
    );
  };

  const handleFinishModule = async (values) => {
    setLoadingSend(true);
    try {
      await patchModule(dataInfo.id, values);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: 'Módulo actualizado correctamente.',
        duration: 2,
      });
      setTimeout(() => {
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
        );
      }, MESSAGES.TIME);
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoadingSend(false);
    }
  };

  const handleFinishPayment = async (values) => {
    setLoading(true);
    try {
      if (initialValues?.id) {
        await patch(initialValues.id, values);
        changeValueData(values.valor - initialValues.valor);
      } else {
        await post({ ...values, modulo_modificacion: dataInfo.id });
        changeValueData(values.valor);
      }
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      removeFromSelectors(values);
      setReload({});
      setInitialValues({});
    } catch (error) {
      catchException(error, MESSAGES.DESC_ERROR_POST_MSG);
    } finally {
      setLoading(false);
    }
  };

  const atentionOk = () => {
    setAtentionVisible(false);
    moduleForm.submit();
  };

  const confirmOk = () => {
    setAtentionVisible(true);
  };

  const popsCancel = () => {
    setAtentionVisible(false);
  };

  function formatCurrency(number) {
    number = number ? number : 0;
    const formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter
      .format(number)
      .replace(/\./g, 'x')
      .replace(/,/g, '.')
      .replace(/x/g, ',');
  }

  const validatePago = (_, value) => {
    if (!value) {
      return Promise.resolve();
    } else if (isNaN(value)) {
      return Promise.reject(new Error('Debe ser un valor numérico'));
    }
    if (value < 0) {
      return Promise.reject(new Error('Debe ser positivo'));
    }

    return Promise.resolve();
  };

  const renderOption = (option) => {
    const optionStyle = {};

    if (option.requiere_eliminacion) {
      optionStyle['color'] = 'white';
      optionStyle['backgroundColor'] = '#f0505f';
    }

    return (
      <Option
        key={option.value}
        value={option.value}
        style={optionStyle}
        disabled={option.aprobado_pago}
      >
        {option.label}
      </Option>
    );
  };

  const tagRender = (props, type) => {
    const { value, label, closable, onClose, ...extra } = props;

    const tagStyle = {
      fontWeight: 'bold',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.65)',
      backgroundColor: '#f5f5f5',
      paddingInlineStart: '8px',
      paddingInlineEnd: '4px',
      paddingTop: '2.5px',
      paddingBottom: '2.5px',
      maxWidth: '100%',
      display: 'flex',
      marginTop: '2px',
      marginBottom: '2px',
      height: '24px',
      alignItems: 'center',
    };

    let x;
    if (type === 'r') x = responsibilities.find((i) => i.value === value);
    else x = products.find((i) => i.value === value);

    if (x && x.requiere_eliminacion) {
      tagStyle['color'] = 'white';
      tagStyle['backgroundColor'] = '#f0505f';
    }

    return (
      <Tag closable onClose={onClose} style={tagStyle} title={label}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </div>
      </Tag>
    );
  };

  const tagRenderR = (props) => {
    return tagRender(props, 'r');
  };

  const tagRenderP = (props) => {
    return tagRender(props, 'p');
  };

  const validateFields = (rule, value) => {
    return new Promise((resolve, reject) => {
      const { responsabilidades, productos } = paymentForm.getFieldsValue([
        'responsabilidades',
        'productos',
      ]);
      if (
        (!responsabilidades || responsabilidades.length < 1) &&
        (!productos || productos.length < 1)
      ) {
        reject('El pago debe tener al menos una responsabilidad o producto');
      } else {
        resolve();
      }
    });
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title className={'text-uppercase'} level={2}>
          MODIFICACIÓN CONTRACTUAL PRESUPUESTAL
        </Title>
      </div>
      {dataInfo ? (
        <>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Descriptions
              layout="horizontal"
              column={2}
              bordered
              labelStyle={{ maxWidth: '5rem' }}
              className="fade-in"
            >
              <Descriptions.Item label="Cargo / Rol" span={2}>
                <Row align="middle">
                  <Col flex="auto">{dataInfo.cargo_nombre}</Col>
                  <Col flex="0 0">
                    <ContractorsModal
                      initialValues={{ ...dataInfo, id: dataInfo.ctm_id }}
                    />
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item
                label="Identificador interno de la modificación masiva"
                span={2}
              >
                {dataInfo.identificador_masivo}
              </Descriptions.Item>
            </Descriptions>
          </Space>
          <div ref={scrollRef}></div>
          <Form
            layout="vertical"
            autoComplete="off"
            form={moduleForm}
            initialValues={dataInfo}
            onFinish={handleFinishModule}
          >
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={3}>5. Cambio forma de pago</Title>}
                style={{ padding: 0 }}
                headStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
              >
                {isLegal && (
                  <Form.Item
                    label="Por favor, digite en letras el número de la cláusula del contrato a modificar"
                    name="clausula"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese el nombre de una cláusula',
                      },
                      {
                        max: 100,
                        message:
                          'El campo debe tener un máximo de 100 caracteres',
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                )}
                <Form
                  form={paymentForm}
                  initialValues={initialValues}
                  onFinish={handleFinishPayment}
                >
                  <Form.Item
                    name="valor"
                    label="Valor del pago (incluye impuestos , tasas y contribuciones)"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese un valor',
                      },
                      {
                        validator: validatePago,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Valor total en COP..."
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="numero"
                    label="Número de pago"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese un valor',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Digite el número del pago"
                    />
                  </Form.Item>
                  <Row align="top" gutter={30}>
                    <Col span={12}>
                      <Form.Item
                        name="responsabilidades"
                        label="Seleccione las responsabilidades u obligaciones específicas del contratista asociadas al pago:"
                        rules={[
                          {
                            validator: validateFields,
                          },
                        ]}
                        dependencies={['productos']}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          tagRender={tagRenderR}
                        >
                          {responsibilities.map(renderOption)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="productos"
                        label="Seleccione los productos y/o entregables asociados al pago:"
                        rules={[
                          {
                            validator: validateFields,
                          },
                        ]}
                        dependencies={['responsabilidades']}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          tagRender={tagRenderP}
                        >
                          {products.map(renderOption)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div className="box-btn-center">
                      <CustomButton
                        htmlType="button"
                        loading={loading}
                        text={initialValues?.id ? 'Editar' : 'Agregar'}
                        onClick={() => paymentForm.submit()}
                      />
                    </div>
                  </Form.Item>
                </Form>
              </Card>
            </Space>

            <Space
              direction={'vertical'}
              style={{ width: '100%', marginTop: '30px' }}
              className="fade-in"
            >
              <Alert
                type={
                  valueData.pending > 0
                    ? 'success'
                    : valueData.pending < 0
                    ? 'error'
                    : 'info'
                }
                message="Información "
                description={
                  <ul>
                    <Divider></Divider>
                    <li id="1">
                      El valor total del contrato incluyendo posibles
                      modificaciones vigentes:{' '}
                      <span style={{ fontStyle: 'bold' }}>
                        {valueData.currentTotalText}
                      </span>
                    </li>
                    <li id="1">
                      Valor total disponible incluyendo posibles modificaciones
                      vigentes:{' '}
                      <span style={{ fontStyle: 'bold' }}>
                        {valueData.availableText}
                      </span>
                    </li>
                    {valueData.totalModifiedText && (
                      <li id="1">
                        Valor total contrato modificado (incluyendo impuestos,
                        tasas y contribuciones):{' '}
                        <span style={{ fontStyle: 'bold' }}>
                          {valueData.totalModifiedText}
                        </span>
                      </li>
                    )}
                    <li id="2">
                      Ha asignado:{' '}
                      <span style={{ fontStyle: 'bold' }}>
                        {valueData.usedText}
                      </span>
                    </li>
                    <li id="3">
                      Valor Pendiente por asignar:{' '}
                      <span style={{ fontStyle: 'bold' }}>
                        {valueData.pendingText}
                      </span>
                    </li>
                  </ul>
                }
                style={{ marginBottom: '20px' }}
              />
              <AjaxTable
                columns={columns}
                rowKey="id"
                endpoint={filterAjaxTable}
                reloadSource={reload}
                scroll={{ x: false }}
                expandedRowRender={{ expandedRowRender }}
                query={dataInfo.id}
              />

              <Form.Item
                label="Justificación"
                name="justificacion"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa la Justificación',
                  },
                ]}
              >
                <Input.TextArea allowClear />
              </Form.Item>

              <Space>
                <CustomButton
                  htmlType="button"
                  className={'back-button'}
                  type="default"
                  text={
                    <Link
                      to={`${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`}
                    >
                      Volver
                    </Link>
                  }
                />

                <Popconfirm
                  visible={atentionVisible}
                  placement="topLeft"
                  title={
                    <>
                      <p>
                        Esta Modificación puede implicar cambiar / ajustar
                        otro(s) Módulos de la pantalla anterior.
                      </p>
                      <p>
                        Por favor verifique con atención y seleccionelo(s) para
                        ajustarlo(s) en caso de ser requerido .
                      </p>
                    </>
                  }
                  okText="Entendido"
                  cancelText="Cancelar"
                  onConfirm={atentionOk}
                  onCancel={popsCancel}
                ></Popconfirm>
                <CustomButton
                  loading={loadingSend}
                  htmlType="button"
                  text={
                    <Popconfirm
                      placement="topLeft"
                      title="¿Está seguro(a) de guardar los ajustes realizados?"
                      okText="Sí"
                      cancelText="No"
                      onConfirm={confirmOk}
                    >
                      {' '}
                      Guardar Modificación
                    </Popconfirm>
                  }
                />
              </Space>
            </Space>
          </Form>
        </>
      ) : (
        <Skeleton active loading />
      )}
    </>
  );
};

export default MassModificationPayments;
