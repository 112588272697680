import React from 'react';

import Logo from "../../images/logo_corpoeducacion-01.png";

import './style.scss';

const GreenLogo = () => {
  return(
    <div className="green-logo-container">
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default GreenLogo;
