import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Select,
  notification,
  Input,
  Space,
  Descriptions,
  Alert,
  Layout,
  Skeleton,
  Card,
} from 'antd';

import CustomButton from '../../../components/CustomButton';
import { patch, get } from '../../../api/module/announcement_selection';
import AnnouncementValidationDocumentsForm from '../../../containers/AnnouncementPreselectionDocumentsForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { get as getResume } from '../../../api/module/contractor';
import { filter as filterResume } from '../../../api/module/resume_documents';
import { get as Announcement } from '../../../api/module/announcement';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { get as getDocUrl } from '../../../api/module/document_repository';

import PdfViewer from '../../../components/PdfViewer';

import './style.scss';

const { Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const tailLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AnnouncementSelectionValidationDocuments = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [url, setUrl] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(null);

  const optionPreselection = [
    { id: 24, nombre: 'Pendiente' },
    { id: 25, nombre: 'Cumple' },
    { id: 26, nombre: 'No cumple' },
  ];
  const [name, setName] = useState('');

  const breadcrumbItems = [
    {
      text: 'Selección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Validación documentos',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      const response = await patch(match.params.selection_id, data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getName();
    getAnnouncement();
    getDocuments();
    getSelection();
  }, []);

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(match.params.announcement_id);
      const responseCargo = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseCargo.data.cargo;
      response.data.estudios = responseCargo.data.estudios;
      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelection = async () => {
    try {
      const response = await get(match.params.selection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDocuments = async () => {
    const response = await filterResume(match.params.person_id);
    setDocuments(response.data.results);
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const getDocument = async (documentId) => {
    setUrl(null);
    const response = await getDocUrl(`${documentId}.pdf`);
    setUrl(response.data.url);
    setShowPDF(true);
    setLoadingDocument(null);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Validación de documentos</Title>}
        >
          <AnnouncementValidationDocumentsForm
            initialValues={dataSource}
            name={name}
            useDescriptions={true}
            descriptionsTitle="Información de convocatoria"
            descriptionsColumns={2}
          />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>{name}</Title>}
        >
          {documents &&
            documents.map((item) => (
              <Descriptions
                bordered={true}
                layout="vertical"
                column={1}
                style={{ marginBottom: 15 }}
              >
                <Descriptions.Item label="Tipo Documento">
                  {item.tipo_documento_descripcion}
                </Descriptions.Item>
                <Descriptions.Item label={'Documento'}>
                  <CustomButton
                    loading={loadingDocument === item.documento}
                    onClick={() => {
                      setLoadingDocument(item.documento);
                      getDocument(item.documento);
                    }}
                    text="Ver documento"
                  />
                </Descriptions.Item>
              </Descriptions>
            ))}
        </Card>
        {initialValues != null && (
          <Card size={'small'} bordered={false}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <Form.Item
                name="estado_documentos_seleccion"
                label="Estado selección documentos"
                rules={[{ required: true }]}
              >
                <Select disabled={initialValues.estado_seleccion === 21}>
                  {optionPreselection.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...tailLayout}
                name="observacion_documentos_seleccion"
                label="Observación general"
              >
                <TextArea
                  disabled={initialValues.estado_seleccion === 21}
                  rows={4}
                />
              </Form.Item>
              <Space>
                {initialValues.estado_seleccion !== 21 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Card>
        )}
      </Skeleton>
      <PdfViewer
        pdf={url}
        visible={showPDF}
        onCancel={() => {
          setShowPDF(false);
        }}
      />
    </Content>
  );
};

export default AnnouncementSelectionValidationDocuments;
