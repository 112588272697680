import React, { useState } from 'react';
import { Layout, Avatar, Menu, Typography, Drawer, Button } from 'antd';
import MenuApp from './Menu';
import Logo from '../../images/logo_corpoeducacion-01.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { MenuOutlined } from '@ant-design/icons';
import { LogOut } from '../../utils/helpers/helpers';

import './style.scss';

const { Sider } = Layout;
const { Title } = Typography;

const SiderApp = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {/* Responsive Menu*/}
      <div style={{ paddingTop: '1rem', position: 'fixed' }}>
        <Button
          className="menu"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
        <Drawer
          title={
            <>
              <div className="side-logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="side-title-container">
                <Title level={5}>
                  SIGA - Sistema de Gestión Administrativa y Contratación
                </Title>
              </div>
            </>
          }
          placement="left"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <MenuApp></MenuApp>
          <div className="side-user-info">
            <Avatar
              style={{ backgroundColor: '#00A499' }}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <span className="side-user-name">
              USUARIO: {window.localStorage['username']}
            </span>
          </div>
          <Menu className="sise-user-menu">
            <Menu.Item
              onClick={() => {
                LogOut();
              }}
              key="logout"
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
      {/*Normal Menu*/}
      <Sider
        width={250}
        breakpoint="lg"
        className="site-layout-background"
        collapsedWidth="0"
        trigger={null}
      >
        <div className="side-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="side-title-container">
          <Title level={5}>
            SIGA - Sistema de Gestión Administrativa y Contratación
          </Title>
        </div>
        <MenuApp/>
        <div className="side-user-info">
          <span className="side-user-name">
            USUARIO: {window.localStorage['username']}
          </span>
        </div>
        <Menu className="side-user-menu">
          <Menu.Item key="logout-key">
            <Button
              danger
              onClick={() => {
                LogOut();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 10}} /> Cerrar sesión
            </Button>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};
export default SiderApp;
