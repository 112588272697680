import React, { useState } from 'react';
import { Descriptions, Modal, Typography } from 'antd';
import AjaxTable from '../../../components/AjaxTable';
import { getContractors } from '../../../api/module/mass_contract_modifications';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { CustomButton } from '../../../components';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const ContractorsModal = ({ initialValues = {} }) => {
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: 'Contratista',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'tipo_identificacion',
    },
    {
      title: 'Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: 'Cantidad de modificaciones realizadas',
      dataIndex: 'mod_num',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => {
        return (
          <CustomButton
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${record.contrato}/${initialValues.proyecto_id}/${initialValues.cargo_id}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver
              </Link>
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Histórico contractuales
          </Title>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        width={'80%'}
        footer={null}
      >
        {initialValues && (
          <>
            <Descriptions
              layout="horizontal"
              column={1}
              bordered
              labelStyle={{ maxWidth: '5rem' }}
            >
              <Descriptions.Item label="Proyecto">
                {initialValues.project_name}
              </Descriptions.Item>
              <Descriptions.Item label="Cargo / Rol">
                {initialValues.cargo_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Identificador interno de la modificación masiva">
                {initialValues.identificador_masivo}
              </Descriptions.Item>
            </Descriptions>
            <AjaxTable
              columns={columns}
              endpoint={getContractors}
              query={initialValues.id}
              scroll={{ x: false }}
              rowKey="id"
              exportCSV
              searchInput
            />
          </>
        )}
      </Modal>
      <CustomButton
        htmlType="button"
        text="Ver contratistas de la modificación actual"
        onClick={() => setVisible(true)}
      />
    </>
  );
};

export default ContractorsModal;
