import React, { useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  Space,
  Alert,
  Modal,
} from 'antd';

import { CustomButton } from '../../../components';

import AjaxTable from '../../../components/AjaxTable';
import { contractSearch } from '../../../api/module/contract_modifications';

const { Title, Paragraph } = Typography;

const ContractSearch = ({ project_id, next, positions }) => {
  const [query, setQuery] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    setLoading(true);
    const q = Object.entries(data || {}).reduce((pv, cv) => {
      if (cv[1]) pv.push(`filter[${cv[0]}]=${cv[1]}`);
      return pv;
    }, []);
    q.push(`filter[proyecto]=${project_id}`);
    setQuery(q.join('&'));
    setLoading(false);
  };

  const columns = [
    {
      title: 'Nombre Contratista/Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'Cargo/Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: '# de modificaciones realizadas',
      dataIndex: 'mod_num',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      width: 125,
      render: (_, record) => (
        <Space>
          <CustomButton
            type="default"
            text="Crear modificación"
            onClick={() => {
              if (record.mod_draft) {
                Modal.error({
                  title: 'Error',
                  content:
                    'Este contrato tiene una modificación en curso, por lo tanto, no se puede crear una nueva modificación.',
                });
              } else if (record.status_pc) {
                if (record.status_pc === 1)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene una terminación en curso, por lo tanto, no se puede crear una nueva modificación.',
                  });
                else if (record.status_pc === 2)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene una terminación finalizada, por lo tanto, no se puede crear una nueva modificación.',
                  });
                else if (record.status_pc === -1)
                  Modal.error({
                    title: 'Error',
                    content:
                      'Este contrato tiene pagos incompletos, por lo tanto, no se puede crear una nueva modificación.',
                  });
              } else next(record);
            }}
          />
          <CustomButton type="info" text="Otras acciones" />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Alert
        showIcon
        type="info"
        message="Información importante"
        description="Los resultados de la búsqueda mostrará los contratistas/proveedores que han aceptado contratación en el proyecto seleccionado con contrato VIGENTE o ACTIVO."
      />
      <br />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={5}>Búsqueda por identificación</Title>
        <Row align="bottom" gutter={8}>
          <Col span={8}>
            <Form.Item label="Nombre" name="nombre">
              <Input allowClear placeholder="Búsqueda por nombre" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Número de documento" name="documento">
              <Input
                type="number"
                allowClear
                placeholder="Búsqueda por documento"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Correo electrónico" name="correo">
              <Input allowClear placeholder="Correo electrónico" />
            </Form.Item>
          </Col>
        </Row>
        <Title level={5}>Búsqueda por contrato</Title>
        <Paragraph>
          Seleccione el criterio por el cual desea realizar las modificaciones
          contractuales
        </Paragraph>
        <Row align="bottom" gutter={8}>
          <Col span={12}>
            <Form.Item label="Cargo" name="cargo">
              <Select
                placeholder="Seleccione un cargo/rol..."
                allowClear
                options={positions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Número de Contrato vigente" name="contrato_num">
              <Input allowClear placeholder="Búsqueda por contrato VIGENTE" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} justify="end">
          <Col>
            <CustomButton loading={loading} text="Buscar" />
          </Col>
          <Col>
            <CustomButton
              htmlType="button"
              type="default"
              onClick={() => {
                form.resetFields();
              }}
              danger={true}
              text="Borrar filtros"
            />
          </Col>
        </Row>
      </Form>
      <br />
      {query && (
        <AjaxTable
          columns={columns}
          tableTitle={'Listado de resultados'}
          endpoint={contractSearch}
          query={query}
          searchInput
          exportCSV
        />
      )}
    </div>
  );
};

export default ContractSearch;
