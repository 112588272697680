import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/job_type';

export const SelectJobType = ({ className = '', onChange = () => {} }) => {
  const [jobType, setJobType] = useState(null);

  const getJobType = () => {
    getAll().then((response) => setJobType(response.data.results));
  };

  useEffect(() => {
    getJobType();
  }, []);

  return (
    <Select
      allowClear
      showSearch
      className={className}
      placeholder="Núcleo del conocimiento"
      onChange={(value) => onChange(value)}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {jobType != null &&
        jobType.map((item) => (
          <Select.Option key={`job-type-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
