import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/experience_areas/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/experience_areas/?page[size]=1000&sort=nombre`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/experience_areas/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/experience_areas/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/experience_areas/${id}`,
  );
};

export { post, getAll, get, put, remove };
