import httpClient from '../httpClient';

const setAnnouncementCloseTime = async (text) => {
  if (text)
    return await httpClient.get(`/schedule_announcement_check/?cron=${text}`, {
      baseURL: process.env.REACT_APP_API_DOMAIN,
    });
  else
    return await httpClient.get(`/schedule_announcement_check/`, {
      baseURL: process.env.REACT_APP_API_DOMAIN,
    });
};

const inactivateUser = async (id, data) => {
  return await httpClient.patch(`/users_admin/${id}/inactivate/`, data);
};

const activateUser = async (id) => {
  return await httpClient.patch(`/users_admin/${id}/activate/`);
};

const changeEmail = async (id, data) => {
  return await httpClient.patch(`/users_admin/${id}/change_email/`, data);
};

const filterUsers = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  _exportCSV = false,
) => {
  return await httpClient.get(
    `/users_admin/?page[number]=${page}&page[size]=${pageSize}${
      search ? `&filter[search]=${search}` : ''
    }`,
    {
      params: query,
    },
  );
};

export {
  setAnnouncementCloseTime,
  inactivateUser,
  activateUser,
  changeEmail,
  filterUsers,
};
