import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/knowledge_areas';

export const SelectKnowledgeArea = ({
  className = '',
  onChange = () => {},
}) => {
  const [knowledgeAreas, setKnowledgeAreas] = useState(null);

  const getKnowledgeAreas = () => {
    getAll().then((response) => setKnowledgeAreas(response.data.results));
  };

  useEffect(() => {
    getKnowledgeAreas();
  }, []);

  return (
    <Select
      allowClear
      showSearch
      className={className}
      placeholder="Área del conocimiento"
      onChange={(value) => onChange(value)}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onClear={() => onChange(null)}
    >
      {knowledgeAreas != null &&
        knowledgeAreas.map((item) => (
          <Select.Option key={`knowledge-area-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
