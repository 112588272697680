import { createAction } from "redux-actions";

// Action Types
const SET_LOADING = "SET_LOADING";

export const constants = {
  SET_LOADING,
};

// ------------------------------------
// Actions
// ------------------------------------
export const setLoading = createAction(SET_LOADING, (loading) => ({ loading }));

export const actions = {
  setLoading,
};
