import React, { memo, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Popconfirm,
  Typography,
  Form,
  Input,
  Radio,
  notification,
  Card,
  Space,
  Alert,
  Skeleton,
  Descriptions,
  Row,
  Col,
  Checkbox,
  Modal,
} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import {
  post,
  patch,
  remove,
  filter as filterAjaxTable,
  swap,
  info,
  preview,
  patchModule,
} from '../../../api/module/ctm_responsibilities';

import '../Form/style.scss';
import AjaxTable from '../../../components/AjaxTable';
import { IsRole, catchException } from '../../../utils/helpers/helpers';

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const ResponsibilitiesList = ({
  handleEdit,
  deleteDataSource,
  ctmmID,
  reload,
  onSwap,
  swapID,
  lSwap,
  requireElimination,
  unrequireElimination,
}) => {
  const [deleteID, setDeleteID] = useState();

  const columns = [
    {
      title: 'Código vigente',
      dataIndex: 'codigo',
      key: 'codigo',
      width: '5rem',
      align: 'center',
    },
    {
      title: 'Obligación vigente',
      dataIndex: 'descripcion_vigente',
      key: 'descripcion',
      render: (_, record) =>
        record.origen !== 3 ? record.descripcion_vigente : record.descripcion,
    },
    {
      title: 'Asociado a pago vigente',
      dataIndex: 'tiene_pagos_vigente',
      width: '7rem',
      render: (_, record) =>
        record.origen !== 3
          ? record.tiene_pagos_vigente === true
            ? 'Si'
            : 'No'
          : record.tiene_pagos === true
          ? 'Si'
          : 'No',
      align: 'center',
    },
    {
      title: 'Solicitar eliminación',
      dataIndex: 'requiere_eliminacion',
      width: '7rem',
      render: (_, record) => (
        <>
          {record.origen !== 3 && (
            <Checkbox
              checked={record.requiere_eliminacion}
              onClick={() =>
                record.requiere_eliminacion
                  ? unrequireElimination(record)
                  : requireElimination(record)
              }
            />
          )}
        </>
      ),
      align: 'center',
    },
    {
      title: 'Acciones / Justificación',
      key: 'acciones',
      fixed: 'right',
      width: 200,
      render: (_, record) => {
        return (
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <CustomButton
              block
              htmlType={'button'}
              text={
                record.requiere_eliminacion ? 'Ver Justificación' : 'Ver/Editar'
              }
              onClick={() => handleEdit(record)}
            />
            {record.origen === 3 && (
              <>
                <CustomButton
                  block
                  type={record.id === swapID ? 'dashed' : 'default'}
                  htmlType={'button'}
                  text={
                    lSwap && (record.id === swapID || record.id === lSwap)
                      ? 'Cambiando'
                      : record.id === swapID
                      ? 'Deseleccionar'
                      : 'Intercambiar Nueva'
                  }
                  onClick={() => onSwap(record)}
                  loading={
                    lSwap && (record.id === swapID || record.id === lSwap)
                  }
                />
                <CustomButton
                  block
                  type={'default'}
                  htmlType={'button'}
                  loading={record.id === deleteID}
                  danger
                  text={
                    <Popconfirm
                      title={MESSAGES.DELETE_CONFIRM_MSG}
                      onConfirm={() => {
                        setDeleteID(record.id);
                        deleteDataSource(record.id);
                      }}
                    >
                      Eliminar
                    </Popconfirm>
                  }
                />
              </>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'origen',
      width: '8rem',
      fixed: 'right',
      render: (_, record) =>
        record.origen === 1 ? '' : record.origen === 2 ? 'Modificada' : 'Nueva',
      align: 'center',
    },
  ];

  return (
    <AjaxTable
      columns={columns}
      searchInput
      tableTitle={'Listado de responsabilidades'}
      endpoint={filterAjaxTable}
      reloadSource={reload}
      scroll={{ x: false }}
      query={ctmmID}
      exportCSV
    />
  );
};

const JustificationForm = memo(({ initialValues, id, onFinish }) => {
  const [loading, setLoading] = useState(false);

  const save = async (data) => {
    try {
      setLoading(true);
      await patch(id, { ...data, requiere_eliminacion: true });
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      setLoading(false);
      onFinish();
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 10,
      offset: 11,
    },
  };

  return (
    <Form
      onFinish={save}
      autoComplete="off"
      layout="vertical"
      initialValues={initialValues}
    >
      <br />
      <Paragraph>
        Responsabilidad u obligación específica No. {initialValues.codigo}
      </Paragraph>
      <div className="ant-form-item">
        <Row gutter={300}>
          <Col>
            <Text className="ant-form-item-label">
              Responsabilidad u obligación específica del contratista
              <b> vigente</b>
            </Text>
          </Col>
          <Col>
            <Text>
              Pago asociado <b> vigente</b>
            </Text>
            <Radio.Group
              style={{ marginLeft: '2rem' }}
              value={initialValues.tiene_pagos_vigente}
              disabled
            >
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <TextArea
          rows={4}
          bordered
          allowClear
          value={initialValues.descripcion_vigente}
          disabled
        />
      </div>
      <Form.Item
        {...formItemLayout}
        name="justificacion"
        label="Justificación de la solicitud de eliminación"
        rules={[
          {
            required: true,
            message: 'Este campo es obligatorio!',
          },
        ]}
      >
        <TextArea rows={4} bordered allowClear />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <CustomButton loading={loading} text="Editar" />
      </Form.Item>
    </Form>
  );
});

const ResponsibilityForm = memo(
  ({ ctmmID, initialValues, id, onFinish, showOld }) => {
    const [loading, setLoading] = useState(false);

    const save = async (data) => {
      try {
        if (!id) {
          const newData = {
            modulo_modificacion: ctmmID,
            ...data,
          };
          setLoading(true);
          await post(newData);
        } else {
          setLoading(true);
          await patch(id, data);
        }
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setLoading(false);
        onFinish();
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
        setLoading(false);
      }
    };

    const formItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
    };

    const buttonItemLayout = {
      wrapperCol: {
        span: 10,
        offset: 11,
      },
    };

    return (
      <Form
        onFinish={save}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
      >
        {initialValues && (
          <>
            <br />
            <Paragraph>
              Responsabilidad u obligación específica No. {initialValues.codigo}
            </Paragraph>
            {showOld && initialValues.origen !== 3 && (
              <>
                <div className="ant-form-item">
                  <Row gutter={300}>
                    <Col>
                      <Text className="ant-form-item-label">
                        Responsabilidad u obligación específica del contratista
                        <b> vigente</b>
                      </Text>
                    </Col>
                    <Col>
                      <Text>
                        Pago asociado <b> vigente</b>
                      </Text>
                      <Radio.Group
                        style={{ marginLeft: '2rem' }}
                        value={initialValues.tiene_pagos_vigente}
                        disabled
                      >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <TextArea
                    rows={4}
                    bordered
                    allowClear
                    value={initialValues.descripcion_vigente}
                    disabled
                  />
                </div>
              </>
            )}
          </>
        )}

        <Form.Item
          {...formItemLayout}
          name="descripcion"
          label={
            <>
              Responsabilidad u obligación específica del contratista&nbsp;
              {showOld && <b>modificada</b>}
            </>
          }
          rules={[
            {
              required: true,
              message: 'Este campo es obligatorio!',
            },
          ]}
        >
          <TextArea rows={4} bordered allowClear />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="tiene_pagos"
          label="¿La responsabilidad u obligación específica tiene asociado algún pago?"
          rules={[
            {
              required: true,
              message: 'Tiene que escoger una opción',
            },
          ]}
        >
          <Radio.Group style={{ marginLeft: '2rem' }}>
            <Radio value={true}>Si</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="justificacion"
          label="Justificación"
          rules={[
            {
              required: true,
              message: 'Este campo es obligatorio!',
            },
          ]}
        >
          <TextArea rows={4} bordered allowClear />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <CustomButton loading={loading} text={id ? 'Editar' : 'Agregar'} />
        </Form.Item>
      </Form>
    );
  },
);

const GeneralSaveButton = ({ onClick, loadingForm }) => (
  <Popconfirm
    placement="topLeft"
    title="¿Está seguro(a) de guardar los ajustes realizados?"
    okText="Sí"
    cancelText="No"
    onConfirm={onClick}
  >
    <CustomButton loading={loadingForm} text="Guardar Modificación" />
  </Popconfirm>
);

export default function ModificationResponsibilitiesForm({ match }) {
  const { modification_id } = useParams();
  const [isLegal] = useState(IsRole(3));
  const [dataInfo, setDataInfo] = useState();
  const [swapData, setSwapData] = useState(undefined);
  const [lSwap, setLSwap] = useState(undefined);
  const [loadingForm, setLoadingForm] = useState(false);

  const [reloadTable, setReloadTable] = useState();

  const [form] = Form.useForm();
  let history = useHistory();

  const [editData, setEditData] = useState(undefined);
  const [justificationData, setJustificationData] = useState(undefined);
  const [createNew, setCreateNew] = useState(false);

  const [openPreview, setOpenPreview] = useState(false);

  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${
        dataInfo?.proyecto_id ?? 0
      }/${modification_id}`,
    },
    {
      text: 'Responsabilidades u obligaciones específicas del contratista',
    },
  ];

  useEffect(() => {
    let mounted = true;
    async function fetchData() {
      try {
        const res = await info(modification_id);
        if (mounted) setDataInfo(res.data);
      } catch (error) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
    return () => {
      mounted = false;
    };
  }, [modification_id]);

  const handleEdit = (record) => {
    if (record.requiere_eliminacion) {
      setJustificationData(record);
    } else {
      setEditData(record);
    }
  };

  const doReload = () => {
    setReloadTable({});
  };

  const doSwap = async (a, b) => {
    try {
      setLSwap(b);
      await swap(a, b);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      setSwapData(undefined);
      setLSwap(undefined);
      doReload();
    } catch (error) {
      catchException(error);
    }
  };

  const onSwap = (row) => {
    if (swapData) {
      if (row.id === swapData.id) setSwapData(undefined);
      else doSwap(swapData.id, row.id);
    } else {
      setSwapData(row);
    }
  };

  const unrequireElimination = async (id) => {
    try {
      await patch(id, { requiere_eliminacion: false, justificacion: '' });
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
      });
      setReloadTable({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Delete Validation
  const deleteDataSource = async (id) => {
    try {
      await remove(id);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
      });
      if (id === swapData?.id) setSwapData(undefined);
      setReloadTable({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description:
          'Esta responsabilidad ya se encuentra vinculada a un pago. Por favor, primero, elimine la forma de pago y, luego proceda a eliminar la responsabilidad',
        duration: 2,
      });
    }
  };

  const onSave = () => {
    if (isLegal) form.submit();
    else
      history.push(
        `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
      );
  };

  const onFinish = async (values) => {
    try {
      setLoadingForm(true);
      await patchModule(dataInfo.id, values);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      setTimeout(() => {
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
        );
      }, MESSAGES.TIME);
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title className={'text-uppercase'} level={2}>
          MODIFICACIÓN CONTRACTUAL RESPONSABILIDADES
        </Title>
      </div>
      {dataInfo ? (
        <>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Descriptions
              layout="horizontal"
              column={2}
              bordered
              labelStyle={{ maxWidth: '5rem' }}
              className="fade-in"
            >
              <Descriptions.Item label="Contratista / Proveedor" span={2}>
                <Row align="middle">
                  <Col flex="auto">{dataInfo.contratista_nombre}</Col>
                  <Col flex="0 0">
                    <CustomButton
                      htmlType="button"
                      text={
                        <Link
                          to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${dataInfo.contrato_id}/${dataInfo.proyecto_id}/${dataInfo.cargo_id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ver históricos contractuales
                        </Link>
                      }
                    />
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label="Cargo / Rol" span={2}>
                {dataInfo?.cargo_nombre}
              </Descriptions.Item>
            </Descriptions>
          </Space>
          <br />
          <br />

          <Card
            size={'small'}
            bordered={false}
            title={
              <Title level={3}>
                2. Ajuste de responsabilidades u obligaciones específicas:
              </Title>
            }
          >
            {isLegal && (
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                initialValues={{
                  clausula: dataInfo.clausula,
                }}
              >
                <Form.Item
                  label="Digite en letras el número de la cláusula del contrato a modificar"
                  name="clausula"
                  rules={[
                    {
                      required: true,
                      message: 'Ingrese el nombre de la cláusula',
                    },
                    {
                      max: 100,
                      message:
                        'El campo debe tener un máximo de 100 caracteres',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    allowClear
                    placeholder=""
                    name="name_clause"
                  />
                </Form.Item>
              </Form>
            )}
          </Card>
          <Paragraph>
            Por favor, de las siguientes obligaciones modifique las que requiera
          </Paragraph>
          {swapData && (
            <>
              <Alert
                message="Intercambio de código"
                description={
                  <>
                    Se ha seleccionado para intercambio de código el item
                    <br />
                    <br />
                    Código {swapData.codigo}: {swapData.descripcion}
                  </>
                }
                type={'info'}
                showIcon
              />
            </>
          )}

          <ResponsibilitiesList
            ctmmID={dataInfo.id}
            handleEdit={handleEdit}
            deleteDataSource={deleteDataSource}
            reload={reloadTable}
            onSwap={onSwap}
            swapID={swapData?.id}
            lSwap={lSwap}
            requireElimination={(record) => setJustificationData(record)}
            unrequireElimination={(record) => unrequireElimination(record.id)}
          />

          <Paragraph>
            Para agregar una nueva responsabilidad u obligación de clic en el
            botón "Agregar"{' '}
            <CustomButton
              htmlType="button"
              text="Agregar"
              onClick={() => setCreateNew(true)}
            />
          </Paragraph>

          <Modal
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                Modificación Contractual
              </Title>
            }
            destroyOnClose={true}
            footer={null}
            visible={!!editData}
            onCancel={() => setEditData(undefined)}
            width={'80%'}
          >
            <b>Modificación de responsabilidad u obligación específica</b>
            <ResponsibilityForm
              ctmmID={dataInfo.id}
              initialValues={editData}
              onFinish={() => {
                doReload();
                setCreateNew(false);
              }}
              id={editData?.id}
              showOld={editData?.origen === 1 || editData?.origen === 2}
            />
          </Modal>

          <Modal
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                Modificación Contractual
              </Title>
            }
            destroyOnClose={true}
            footer={null}
            visible={!!justificationData}
            onCancel={() => setJustificationData(undefined)}
            width={'80%'}
          >
            <b>Solicitud de eliminación</b>
            <JustificationForm
              initialValues={justificationData}
              onFinish={() => {
                doReload();
                setJustificationData(undefined);
              }}
              id={justificationData?.id}
            />
          </Modal>

          <Modal
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                Modificación Contractual
              </Title>
            }
            destroyOnClose={true}
            footer={null}
            visible={createNew}
            onCancel={() => setCreateNew(false)}
            width={'80%'}
          >
            <b>Agregar una nueva responsabilidad u obligación específica</b>
            <ResponsibilityForm
              ctmmID={dataInfo.id}
              onFinish={() => {
                doReload();
                setCreateNew(false);
              }}
            />
          </Modal>

          <Modal
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                Modificación Contractual
              </Title>
            }
            destroyOnClose={true}
            footer={null}
            visible={openPreview}
            onCancel={() => setOpenPreview(false)}
            width={'80%'}
          >
            <b>Previsualización de responsabilidades u obligaciones</b>
            <AjaxTable
              columns={[
                {
                  title: 'Código',
                  dataIndex: 'codigo',
                },
                {
                  title: 'Obligación',
                  dataIndex: 'descripcion',
                  key: 'descripcion',
                },
                {
                  title: 'Asociado a pago',
                  dataIndex: 'tiene_pagos',
                  width: '12rem',
                  render: (_, record) =>
                    record.tiene_pagos === true ? 'Si' : 'No',
                  align: 'center',
                },
              ]}
              searchInput
              tableTitle={'Listado de responsabilidades'}
              endpoint={preview}
              scroll={{ x: false }}
              query={dataInfo.id}
              exportCSV
            />
          </Modal>

          <Space size="large">
            <CustomButton
              htmlType="button"
              className={'back-button'}
              type="default"
              text={
                <Link
                  to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`}
                >
                  Volver
                </Link>
              }
            />

            <CustomButton
              htmlType="button"
              type="default"
              text="Previsualizar"
              onClick={() => setOpenPreview(true)}
            />

            <Popconfirm
              placement="topLeft"
              title={
                <>
                  <p>
                    Esta Modificación puede implicar cambiar / ajustar otro(s)
                    Módulos de la pantalla anterior.
                  </p>
                  <p>
                    Por favor verifique con atención y seleccionelo(s) para
                    ajustarlo(s) en caso de ser requerido .
                  </p>
                </>
              }
              okText="Entendido"
              cancelText="Cancelar"
              onConfirm={onSave}
            >
              <GeneralSaveButton loadingForm={loadingForm} />
            </Popconfirm>
          </Space>
        </>
      ) : (
        <Skeleton active></Skeleton>
      )}
    </>
  );
}
