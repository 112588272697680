import React, { useState, useEffect } from 'react';
import { notification, Modal, Divider, Skeleton, Typography } from 'antd';
import moment from 'moment';

import { useQuery } from '../../../utils/helpers/helpers';
import NaturalPersonForm from '../../../containers/NaturalPersonForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/contractor';
import { filter as getAllExperiencieIcfes } from '../../../api/module/resume_experience_icfes';
import { get as getProject } from '../../../api/module/projects';
import {
  post as postDocuments,
  filter as getDocumentsByContrat,
} from '../../../api/module/resume_documents';

import './style.scss';

const { Paragraph } = Typography;

const NaturalPersonForms = ({ history, match, readOnly = false }) => {
  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [person_id, setPersonId] = useState(parseInt(match.params.person_id));
  const [initialValues, setInitialValues] = useState(null);
  const [project, setProject] = useState(null);
  const ids = {
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };
  const [icfes, setIcfes] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [experienciaGeneralTextArea, setExperienciaGeneralTextArea] =
    useState('');

  const [docsComplete, setDocsComplete] = useState(false);

  useEffect(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        handleGet(),
        getExperiencieIcfes(),
        getDocuments(),
      ]);
      if (project_id) {
        getProjectData();
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProjectData = async () => {
    const response = await getProject(project_id);
    setProject(response.data);
  };

  const getExperiencieIcfes = async () => {
    if (person_id) {
      const response = await getAllExperiencieIcfes(
        '?filter[tercero]=' + person_id,
      );
      if (response.data.results !== undefined) {
        //setIcfes(true)
      }
    }
  };

  const handleGet = async () => {
    try {
      let data = {};
      if (person_id) {
        const response = await get(person_id);
        data = response.data;
        if (data.fecha_expedicion_documento != null) {
          data.fecha_expedicion_documento = moment(
            data.fecha_expedicion_documento,
          );
        }

        if (data.experiencia_icfes) {
          setIcfes(true);
        }
        data.numeroDocumentoConfirm = data.numero_identificacion;
        data.celularConfirm = data.numero_celular;
        data.correoConfirm = data.notificacion_email;
        if (data.experiencia_laboral_general) {
          setExperienciaGeneralTextArea(data.experiencia_laboral_general);
        }

        if (data['notificacion_pais'] == null) {
          data['notificacion_pais'] = 45;
        }
        if (
          data['notificacion_estado'] == null &&
          data['notificacion_pais'] === 45
        ) {
          data['notificacion_estado'] = 3;
        }
        if (
          data['notificacion_ciudad'] == null &&
          data['notificacion_estado'] === 3
        ) {
          data['notificacion_ciudad'] = 149;
        }
        setInitialValues(data);
      } else {
        if (data['notificacion_pais'] == null) {
          data['notificacion_pais'] = 45;
        }
        if (
          data['notificacion_estado'] == null &&
          data['notificacion_pais'] === 45
        ) {
          data['notificacion_estado'] = 3;
        }
        if (
          data['notificacion_ciudad'] == null &&
          data['notificacion_estado'] === 3
        ) {
          data['notificacion_ciudad'] = 149;
        }

        setInitialValues(data);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const checkDocs = () => {
    Modal.success({
      title: 'Se ha registrado correctamente su información',
      content: (
        <>
          <Divider />
          <Paragraph>
            Recuerde que puede actualizarla cuando lo considere necesario.
          </Paragraph>
          <Paragraph>
            Gracias por su interés en trabajar con nosotros.
          </Paragraph>
          <Paragraph>
            Tendremos en cuenta la información registrada cuando Corpoeducación
            lo requiera.
          </Paragraph>
          <Paragraph>
            Por favor, revise constantemente la bandeja de entrada,
            notificaciones y correo no deseado (spam) de su correo electrónico.
          </Paragraph>
        </>
      ),
      onOk: () => {
        window.location = '/';
      },
    });
  };

  const save = async (data) => {
    try {
      let response;
      data.fecha_expedicion_documento = moment(
        data.fecha_expedicion_documento,
      ).format('YYYY-MM-DD');
      data.tipo_persona = 1;

      if (data.notificacion_pais !== 45) {
        data.notificacion_estado = null;
      }
      if (data.hasOwnProperty('codigo_postal')) {
        if (data['codigo_postal'] === '') {
          delete data['codigo_postal'];
        }
      }

      if (!person_id) {
        setLoading(true);
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(person_id, data);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${response.data.id}`);
          setPersonId(response.data.id);
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const getDocuments = async () => {
    try {
      if (person_id) {
        const response = await getDocumentsByContrat(person_id);
        setDocuments(response.data.results);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const saveCv = async (data, name) => {
    try {
      if (data.fileList.length > 0) {
        if (data.fileList[0].type === 'application/pdf') {
          data = data.fileList[0].originFileObj;
          data.tercero = person_id;

          const formData = new FormData();
          formData.append(name, data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          const response = await postDocuments(formData);
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            setTimeout(() => {
              setLoading(false);
              const res = response.data;
              setDocuments((documents) => [...documents, res]);
            }, MESSAGES.TIME);
          }
        } else {
          notification.error({
            message: MESSAGES.ERROR_FILE_PDF,
            description: MESSAGES.DESC_ERROR_FILE_PDF,
            duration: 3,
          });
        }
      } else {
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const saveGeneralExperience = async () => {
    try {
      let response;
      let data = initialValues;
      data.experiencia_laboral_general = experienciaGeneralTextArea;
      data.tipo_persona = 1;
      data.experiencia_icfes = icfes;
      if (data.fecha_expedicion_documento != null) {
        data.fecha_expedicion_documento = moment(
          data.fecha_expedicion_documento,
        ).format('YYYY-MM-DD');
      }
      setLoading(true);
      response = await put(person_id, data);

      if ([200, 201, 202, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          dduration: 2,
        });
        setTimeout(() => {
          history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${response.data.id}`);
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  return (
    <Skeleton loading={isLoading} active>
      {initialValues != null && (
        <NaturalPersonForm
          ids={ids}
          project={project}
          title={'Registro de proveedores y consultores - Persona natural'}
          onFinish={save}
          setDocsComplete={setDocsComplete}
          initialValues={initialValues}
          loading={loading}
          personID={parseInt(person_id)}
          icfes={icfes}
          setIcfes={setIcfes}
          saveGeneralExperiencie={saveGeneralExperience}
          saveCv={saveCv}
          documents={documents}
          setDocuments={setDocuments}
          experienciaGeneralTextArea={experienciaGeneralTextArea}
          setExperienciaGeneralTextArea={setExperienciaGeneralTextArea}
          readOnly={readOnly}
          checkDocs={checkDocs}
        />
      )}
    </Skeleton>
  );
};

export default NaturalPersonForms;
