import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import ProjectCategoryForm from '../../../containers/ProjectCategoryForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import { post, put, get } from '../../../api/module/project_category';

const ProjectCategoryForms = ({ history, match }) => {
  const [projectCategoryID, setprojectCategoryID] = useState(parseInt(0));
  const [title, setTitle] = useState('Creación Categorias por Proyecto');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      if (!projectCategoryID) {
        setLoading(true);
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(projectCategoryID, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.PROJECT_CATEGORIES);
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      console.log(match.params.project_id)
      if (projectCategoryID) {
        get(projectCategoryID).then((res) => setData(res.data));
        setTitle('Actualización Categoría por proyecto');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    console.log(match)
    handleGetData();
  }, []);

  return (
    <>
      {data != null && (
        <ProjectCategoryForm
          title={title}
          onFinish={save}
          initialValues={data}
          loading={loading}
        />
      )}
    </>
  );
};

export default ProjectCategoryForms;
