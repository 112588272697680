import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  Typography,
  notification,
  Input,
  Skeleton,
  Space,
  Button,
} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import MESSAGES from '../../../enums/Messages';
import { history } from '../../../api/module/term_resume_contract';
import Paragraph from 'antd/lib/typography/Paragraph';
import AjaxTable from '../../../components/AjaxTable';

import { get } from '../../../api/module/term_legal_validation_document';

import { get as getStatus } from '../../../api/module/status_process';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import 'moment/locale/es';

import '../style.scss';

const { Title } = Typography;

export default function DocumentValidationContractualHistory({ match }) {
  const [validationData, setValidationData] = useState(undefined);

  const breadcrumbItems = [
    {
      text: 'Validación de Documentos Jurídico',
      to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}`,
    },
    {
      text: 'Validación Jurídico',
      to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/${match.params.validation_id}`,
    },
    {
      text: 'Historial de contratos',
    },
  ];

  async function fetchData() {
    try {
      const validationId = match.params.validation_id;
      const res = await get(validationId);
      setValidationData(res.data);
      let resStatus = undefined;
      if (res.data.estado_validacion_general) {
        resStatus = await getStatus(res.data.estado_validacion_general);
        resStatus = resStatus.data.sigla;
      }
      setValidationData({
        ...res.data,
        estado_validacion_general_sigla: resStatus,
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.validation_id]);

  const clearAll = async () => {
    setValidationData(undefined);
    fetchData();
  };

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: ['termino', 'proyecto_nombre'],
      key: ['termino', 'proyecto_nombre'],
    },
    {
      title: 'No. Contrato',
      dataIndex: 'numero_contrato',
      key: 'numero_contrato',
    },
    {
      title: 'Cargo',
      dataIndex: ['termino', 'cargo_nombre'],
      key: ['termino', 'cargo_nombre'],
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) =>
        record.estado_validacion === 54 ? (
          <Paragraph>Contrato no aceptado</Paragraph>
        ) : record.estado_proceso === 4 ? (
          <Button type="primary">
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_RESUME}/${record.id}`}
            >
              Ver
            </Link>
          </Button>
        ) : (
          <>
            <Paragraph>El proceso de contratación esta en curso</Paragraph>
            <Paragraph strong>
              Recargue el listado mínimo pasados 5 minutos
            </Paragraph>
          </>
        ),
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Historial de contratos
      </Title>
      {validationData ? (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.tercero.nombre_completo}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tercero.tipo_identificacion_nombre} - ${validationData.tercero.numero_identificacion}`}
            </Descriptions.Item>
          </Descriptions>

          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              const thisQuery = `filter[tercero]=${validationData.tercero.id}&filter[estado_proceso__gt]=0`;
              return await history(`?${thisQuery}`, page, pageSize);
            }}
            columns={columns}
            reloadButton
          ></AjaxTable>

          <CustomButton
            type="default"
            htmlType="button"
            className={'back-button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/${match.params.validation_id}`}
              >
                Volver
              </Link>
            }
          />
        </Space>
      ) : (
        <Skeleton loading={true} />
      )}
    </>
  );
}
