import React from 'react';
import {
  Typography,
  Form,
  Input,
  Row,
  Col,
  Descriptions,
  Space,
} from 'antd';

const { Title, Paragraph } = Typography;

const AnnouncementValidationDocumentsForm = ({
  initialValues,
  name,
  useDescriptions = false,
  descriptionsTitle = '',
  descriptionsColumns = 1,
}) => {
  const onFinish = async () => {};

  return useDescriptions === true ? (
    <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
      <Descriptions
        layout="vertical"
        title={descriptionsTitle}
        column={descriptionsColumns}
        bordered
      >
        <Descriptions.Item label="Número de convocatoria">
          {initialValues.numero}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre de la convocatoria">
          {initialValues.nombre}
        </Descriptions.Item>
        <Descriptions.Item label="Cargo/Rol">
          {initialValues.cargo}
        </Descriptions.Item>
      </Descriptions>
    </Space>
  ) : (
    <Form
      name="control-hooks"
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name="numero" label="Número de convocatoria">
            <Input type="text" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nombre" label="Nombre de la convocatoria">
            <Input type="text" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name="cargo" label="Cargo / Rol ">
            <Input type="text" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Title level={5}>Requisitos de la convocatoria para el cargo</Title>
        </Col>
      </Row>
      <Form.Item name="estudios" label="Estudios">
        <Input.TextArea type="text" disabled rows={4} />
      </Form.Item>
      <Paragraph>{name}</Paragraph>
    </Form>
  );
};

export default AnnouncementValidationDocumentsForm;
