import React, { useState, useEffect } from 'react';
import { notification, Modal, Divider, Skeleton } from 'antd';

import { useQuery, IsRole } from '../../../utils/helpers/helpers';
import LegalPersonForm from '../../../containers/LegalPersonForm/index';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/contractor';
import { get as getProject } from '../../../api/module/projects';
import Paragraph from 'antd/lib/typography/Paragraph';

const LegalPersonForms = ({ match, readOnly = false }) => {
  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [person_id] = useState(parseInt(match.params.person_id));
  const [project, setProject] = useState(null);
  const ids = {
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };
  const [superUser] = useState(IsRole(6));

  const [docsComplete, setDocsComplete] = useState(false);

  useEffect(async () => {
    try {
      setIsLoading(true);
      await Promise.all([handleGet()]);
      if (project_id) {
        await getProjectData();
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProjectData = async () => {
    const response = await getProject(project_id);
    setProject(response.data);
  };

  const handleGet = async () => {
    try {
      if (person_id) {
        const response = await get(person_id);
        let data = response.data;
        if (data['notificacion_pais'] == null) {
          data['notificacion_pais'] = 45;
        }
        if (
          data['notificacion_estado'] == null &&
          data['notificacion_pais'] === 45
        ) {
          data['notificacion_estado'] = 3;
        }
        if (
          data['notificacion_ciudad'] == null &&
          data['notificacion_estado'] === 3
        ) {
          data['notificacion_ciudad'] = 149;
        }
        data.nitConfirm = data.numero_identificacion;
        data.celularConfirm = data.numero_celular;
        data.correoConfirm = data.notificacion_email;

        setInitialValues(data);
      } else {
        setInitialValues({ constituido_colombia: true });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const checkDocs = () => {
    Modal.success({
      title: 'Se ha registrado correctamente su información',
      content: (
        <>
          <Divider />
          <Paragraph>
            Recuerde que puede actualizarla cuando lo considere necesario.
          </Paragraph>
          <Paragraph>
            Gracias por su interés en trabajar con nosotros.
          </Paragraph>
          <Paragraph>
            Tendremos en cuenta la información registrada cuando Corpoeducación
            lo requiera.
          </Paragraph>
          <Paragraph>
            Por favor, revise constantemente la bandeja de entrada,
            notificaciones y correo no deseado (spam) de su correo electrónico.
          </Paragraph>
        </>
      ),
      onOk: () => {
        window.location = '/';
      },
    });
  };

  const save = async (data) => {
    try {
      let response;

      if (data.notificacion_pais !== 45) {
        data.notificacion_estado = null;
      }

      if (!data.tipo_identificacion) {
        data.tipo_identificacion = 15;
      }
      if (data.hasOwnProperty('codigo_postal')) {
        if (data['codigo_postal'] === '') {
          delete data['codigo_postal'];
        }
      }

      if (!person_id) {
        setLoading(true);

        data.tipo_persona = 2;
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(person_id, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        if (docsComplete) {
          checkDocs();
        } else {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
        }
        setTimeout(() => {
          if (superUser === true) {
            window.location = `${INTERNAL_LINKS.LEGAL_PERSON}/${response.data.id}`;
          } else {
            window.location = `/`;
          }
        }, 5000);
      }
    } catch (exception) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(exception);

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
      setLoading(false);
    }
  };

  return (
    <Skeleton loading={isLoading} active>
      {initialValues != null && (
        <LegalPersonForm
          person_id={parseInt(person_id)}
          ids={ids}
          project={project}
          title={'Registro de proveedores y consultores - Persona jurídica'}
          initialValues={initialValues}
          onFinish={save}
          setDocsComplete={setDocsComplete}
          loading={loading}
          readOnly={readOnly}
          checkDocs={checkDocs}
        />
      )}
    </Skeleton>
  );
};

export default LegalPersonForms;
