//Library imports
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Typography,
  notification,
  Space,
  Popconfirm,
  Layout,
  Tabs,
  Badge,
} from 'antd';
import moment from 'moment';

//Component imports
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

//API imports
import {
  getAll,
  remove,
  patch as editStatus,
  ajaxTable as filterAjaxTable,
} from '../../../api/module/projects';

//Css imports
import './style.scss';
import { PlusOutlined } from '@ant-design/icons';
import AjaxTable from '../../../components/AjaxTable';

//Constants
const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const breadcrumbItems = [
  {
    text: 'Proyectos',
  },
];

const ProjectsList = ({ perm }) => {
  const location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [sortBy] = useState('nombre');
  const [listType, setListType] = useState(
    location.state && location.state.closed_type != null
      ? location.state.closed_type === 1
        ? 'closed'
        : location.state.closed_type === 2
        ? 'administrativeClosed'
        : 'active'
      : 'active',
  );
  const [columnType, setColumnType] = useState(null);

  useEffect(async () => {
    await Promise.all([getDataSource()]);
  }, []);

  const getDataSource = async () => {
    try {
      const res = await getAll();
      setDataSource(res.data.results);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoadingButton(false);
    }
  };

  const handleStateChange = async (project_id, state) => {
    setLoadingButton(true);
    await editStatus(project_id, { estado_proyecto_sigla: state })
      .then(async (response) => {
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setReloadTable(reloadTable !== true);
          setLoadingButton(false);
          await getDataSource();
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      });
  };

  const handleRT = async (project_id) => {
    await handleStateChange(project_id, 'PY-RT');
  };

  const handleCT = async (project_id) => {
    await handleStateChange(project_id, 'PY-CT');
  };

  const handleCA = async (project_id) => {
    await handleStateChange(project_id, 'PY-CAJ');
  };

  const handleRA = async (project_id) => {
    await handleStateChange(project_id, 'PY-RAJ');
  };

  const handleListType = (newListType) => {
    setListType(newListType);
  };

  const columns = [
    {
      title: 'Nombre Proyecto',
      dataIndex: 'nombre',
    },
    {
      title: 'Cliente-Aliado',
      dataIndex: 'cliente_nombre',
      key: 'cliente_nombre',
    },
    {
      title: 'Organización ejecutora',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      align: 'center',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo_proyecto_nombre',
      key: 'tipo_proyecto_nombre',
    },
    {
      title: 'Fecha Creación SIGA',
      dataIndex: 'created_at',
      align: 'center',
      render: (_, record) => moment(record.created_at).format('YYYY-MM-DD'),
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'fecha_inicio',
      render: (_, record) => moment(record.fecha_inicio).format('YYYY-MM-DD'),
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'fecha_fin',
      render: (_, record) => moment(record.fecha_fin).format('YYYY-MM-DD'),
    },
  ];

  useEffect(() => {
    let dataActive = [];
    let dataClosed = [];
    let dataAdminClosed = [];

    dataSource.forEach((item, index) => {
      const latest_state = item.estado_proyecto_sigla;

      switch (latest_state) {
        case 'PY-A':
        case 'PY-RT':
          dataActive.push(item);
          break;
        case 'PY-CT':
        case 'PY-RAJ':
          dataClosed.push(item);
          break;
        case 'PY-CAJ':
          dataAdminClosed.push(item);
      }
    });

    setDataShow({
      active: dataActive,
      closed: dataClosed,
      administrativeClosed: dataAdminClosed,
    });
  }, [dataSource]);

  const handleColumnType = useMemo(() => {
    let col = columns;

    if (listType === 'active') {
      col.push({
        title: 'Acciones',
        dataIndex: 'acciones',
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <Space>
            <CustomButton
              type="default"
              htmlType={'button'}
              className={'ant-btn-info'}
              loading={loadingButton}
              text={
                <Link to={`${INTERNAL_LINKS.PROJECT}/${record.id}`}>
                  Editar
                </Link>
              }
            />
            <CustomButton
              danger
              type={'default'}
              loading={loadingButton}
              text={
                <Popconfirm
                  title="¿Está seguro(a) de que desea realizar el cierre técnico de este proyecto?"
                  onConfirm={() => handleCT(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  Cierre técnico
                </Popconfirm>
              }
            />
          </Space>
        ),
      });
    }
    if (listType === 'closed') {
      col.push({
        title: 'Acciones',
        dataIndex: 'acciones',
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <Space>
            <CustomButton
              htmlType={'button'}
              loading={loadingButton}
              text={
                <Popconfirm
                  title="¿Está seguro(a) de que desea realizar la reactivación técnica de este proyecto?"
                  onConfirm={() => handleRT(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  Reactivar técnico
                </Popconfirm>
              }
            />
            <CustomButton
              danger
              type={'default'}
              loading={loadingButton}
              htmlType={'button'}
              text={
                <Popconfirm
                  title="¿Está seguro(a) de que desea realizar el cierre administrativo y jurídico de este proyecto?"
                  onConfirm={() => handleCA(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  Cierre administrativo y jurídico
                </Popconfirm>
              }
            />
            <CustomButton
              type="default"
              loading={loadingButton}
              htmlType={'button'}
              text={
                <Link
                  to={`${INTERNAL_LINKS.PROJECT}/ver/${record.id}?closed_type=1`}
                >
                  Ver
                </Link>
              }
            />
          </Space>
        ),
      });
    }
    if (listType === 'administrativeClosed') {
      col.push({
        title: 'Acciones',
        dataIndex: 'acciones',
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <Space>
            <CustomButton
              htmlType={'button'}
              loading={loadingButton}
              text={
                <Popconfirm
                  title="¿Está seguro(a) de que desea realizar la reactivación administrativa y jurídica de este proyecto? "
                  onConfirm={() => handleRA(record.id)}
                  okText="Si"
                  cancelText="No"
                >
                  Reactivar administrativo y jurídico
                </Popconfirm>
              }
            />
            <CustomButton
              type="default"
              loading={loadingButton}
              text={
                <Link
                  to={`${INTERNAL_LINKS.PROJECT}/ver/${record.id}?closed_type=2`}
                >
                  Ver
                </Link>
              }
            />
          </Space>
        ),
      });
    }
    setColumnType(col);
  }, [listType, loadingButton, dataSource]);

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de administración de Proyectos.
      </Title>
      <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
        {perm.Crear && (
          <CustomButton
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            text={
              <Link to={INTERNAL_LINKS.PROJECT}>
                <PlusOutlined /> Crear nuevo proyecto
              </Link>
            }
          />
        )}
        <Tabs
          type="card"
          onChange={handleListType}
          destroyInactiveTabPane={true}
        >
          <TabPane
            tab={
              <Paragraph>
                Activo
                <Badge
                  showZero
                  count={dataShow.active ? dataShow.active.length : 0}
                />
              </Paragraph>
            }
            key="active"
          >
            <AjaxTable
              columns={columnType}
              pageSize={10}
              scroll={{ x: 'max-content' }}
              tableTitle={'Listado de proyectos activos'}
              endpoint={filterAjaxTable}
              sortBy={sortBy}
              reloadSource={reloadTable}
              query={`/?filter[estado_proyecto__sigla__in]=PY-A,PY-RT`}
              exportCSV
              searchInput
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Cerrado técnico
                <Badge
                  showZero
                  count={dataShow.closed ? dataShow.closed.length : 0}
                />
              </Paragraph>
            }
            key="closed"
          >
            <AjaxTable
              columns={columnType}
              pageSize={10}
              scroll={{ x: 'max-content' }}
              tableTitle={'Listado de proyectos cerrados técnico'}
              endpoint={filterAjaxTable}
              sortBy={sortBy}
              reloadSource={reloadTable}
              query={`/?filter[estado_proyecto__sigla__in]=PY-CT,PY-RAJ`}
              exportCSV
              searchInput
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Cerrado administrativo y jurídico
                <Badge
                  showZero
                  count={
                    dataShow.administrativeClosed
                      ? dataShow.administrativeClosed.length
                      : 0
                  }
                />
              </Paragraph>
            }
            key="administrativeClosed"
          >
            <AjaxTable
              columns={columnType}
              pageSize={10}
              scroll={{ x: 'max-content' }}
              tableTitle={
                'Listado de proyectos cerrados administrativo y jurídico'
              }
              endpoint={filterAjaxTable}
              sortBy={sortBy}
              reloadSource={reloadTable}
              query={`/?filter[estado_proyecto__sigla]=PY-CAJ`}
              exportCSV
              searchInput
            />
          </TabPane>
        </Tabs>
      </Space>
    </Content>
  );
};

export default ProjectsList;
