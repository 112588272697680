import React from 'react';
import { Button } from 'antd';

import './style.scss';

const CustomButton = ({
  text = '',
  type = 'primary',
  htmlType = 'submit',
  disabled = false,
  loading = false,
  block = false,
  className = '',
  onClick = () => {},
  danger = false,
  style = null,
  ...rest
}) => {
  return (
    <Button
      style={style}
      type={type}
      className={className}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      block={block}
      danger={danger}
      {...rest}
    >
      {text}
    </Button>
  );
};
export default CustomButton;
