import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/term_payments/`, data);
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments/`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments/${id}`);
};

const getAllByTerm = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_payments/?filter[termino]=${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/term_payments/${id}/`, data);
};

const patch = async (id) => {
  return await httpClient.patch(`${process.env.REACT_APP_API_HOST}/term_payments/${id}`);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/term_payments/${id}`);
};

const removePayment = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/remove_payment/`, data);
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false
) => {
  let searchBy = '';

  if (search)
    searchBy = '&filter[search]=' + search

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_payments/export` +
      `${query}&sort=${sortBy}` +
      `${searchBy}`,
      {
        responseType: 'blob',
      }
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_payments` +
      `${query}&page[number]=${page}&page[size]=${pageSize}` +
      `&sort=${sortBy}` +
      `${searchBy}`,
    );
};

export {
  post,
  getAll,
  get,
  getAllByTerm,
  removePayment,
  put,
  patch,
  remove,
  ajaxTable,
}
