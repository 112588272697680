import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Form, Input, Select, Row, Col, Space, Layout } from 'antd';
import { Link } from 'react-router-dom';

import './style.scss';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../Breadcrumb';

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Departamentos',
    to: `${INTERNAL_LINKS.STATES}`,
  },
  {
    text: 'Formulario de Departamentos',
  },
];

const validateMessages = {
  required: '${label} Es Requerido!',
  types: {
    nombre: '${label} No es un nombre Valido !',
    codigo: '${label} No es un sigla Valida!',
  },
};

const StatesForm = ({ title, onFinish, initialValues, loading, countries }) => {
  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Paragraph>
        A continuación, diligencie el formulario "{title}".
        <br />
        Recuerde que los campos obligatorios están marcados con asterisco (*).
      </Paragraph>
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="pais"
              label="País"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nombre"
              label="Nombre"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="codigo"
              label="Código"
              rules={[
                {
                  required: true,
                },
                {
                  max: 5,
                  message: 'Solo puede ingresar 5 caracteres!',
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Space>
              <CustomButton text="Guardar" loading={loading} />
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                text={<Link to={INTERNAL_LINKS.STATES}> Volver </Link>}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

StatesForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  countries: PropTypes.array,
};

StatesForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
  countries: [],
};
export default StatesForm;
