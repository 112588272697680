import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `/term_legal_validation_icfes_experience/`,
    data,
  );
};

const get = async (id) => {
  return await httpClient.get(`/term_legal_validation_icfes_experience/${id}`);
};

const getAllByValidation = async (validation_id) => {
  return await httpClient.get(
    `/term_legal_validation_icfes_experience/?filter[validacion_documentos_juridico]=${validation_id}&page[size]=1000`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `/term_legal_validation_icfes_experience/${id}/`,
    data,
  );
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `/term_legal_validation_icfes_experience/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `/term_legal_validation_icfes_experience/${id}`,
  );
};

export { post, get, getAllByValidation, put, patch, remove };
