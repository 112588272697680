import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Layout } from 'antd';

//Component imports
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { ajaxTable as filterAjaxTable } from '../../../api/module/announcement_rol';

import './style.scss';
import AjaxTable from '../../../components/AjaxTable';

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Módulo de preselección',
  },
];

const AnnouncementPreselection = ({ history }) => {
  const [sortColumn, setSortColumn] = useState('-created_at');

  const columns = [
    {
      title: 'Tipo convocatoria',
      dataIndex: 'tipo',
      key: 'tipo',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('tipo');
        if (sortOrder === 'descend') setSortColumn('-tipo');
      },
    },
    {
      title: 'Proyecto',
      align: 'center',
      dataIndex: 'proyecto_nombre',
      key: 'proyecto_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('proyecto_nombre');
        if (sortOrder === 'descend') setSortColumn('-proyecto_nombre');
      },
    },
    {
      title: 'Organización',
      align: 'center',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('organizacion_nombre');
        if (sortOrder === 'descend') setSortColumn('-organizacion_nombre');
      },
    },
    {
      title: 'No. convocatoria',
      align: 'center',
      dataIndex: 'numero',
      key: 'numero',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('numero');
        if (sortOrder === 'descend') setSortColumn('-numero');
      },
    },
    {
      title: 'Nombre convocatoria',
      align: 'center',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre');
        if (sortOrder === 'descend') setSortColumn('-nombre');
      },
    },
    {
      title: 'Cargo',
      align: 'center',
      dataIndex: ['cargo'],
      key: ['cargo'],
    },
    {
      title: 'Total vacantes',
      dataIndex: ['total_vacantes'],
      key: ['total_vacantes'],
      align: 'center',
    },
    {
      title: 'Total aplicantes',
      dataIndex: ['aplicantes'],
      key: ['aplicantes'],
      align: 'center',
    },
    {
      title: 'Preseleccionados',
      dataIndex: ['preseleccionados'],
      key: ['preseleccionados'],
      align: 'center',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => (
        <CustomButton
          block
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION}/${record.convocatoria_id}/${record.id}`}
            >
              Validar
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de preselección
      </Title>
      <AjaxTable
        columns={columns}
        pageSize={10}
        sortBy={sortColumn}
        reloadButton
        searchInput
        tableTitle={'Listado de cargos de convocatorias para preselección'}
        endpoint={filterAjaxTable}
        query={'/preselected/?'}
      />
    </Content>
  );
};

export default AnnouncementPreselection;
