import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Divider,
  Radio,
  Alert,
  Card,
  Space,
  Layout,
} from 'antd';

import { IsRole, validatorNumbers } from '../../utils/helpers/helpers';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import SelectGenders from '../../components/SelectGenders/index';
import SelectIdentifitiesType from '../../components/SelectIdentifitiesType/index';
import SelectRangeAge from '../../components/SelectRangeAge/index';
import CountryStateCity from '../../components/CountryStateCity';
import EducationContractorsList from '../../pages/EducationContractors/List/index';
import LaborContractorsList from '../../pages/LaborContractors/List/index';
import ExperiencieIcfesList from '../../pages/ExperiencieIcfes/List/index';
import OtherCertificationsList from '../../pages/OtherCertifications/List/index';
import AdditionalDocumentsNatural from '../../pages/AdditionalDocumentsNatural/index';

import './style.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Proveedores y Consultores - Persona Natural',
    to: `${INTERNAL_LINKS.NATURAL_PERSONS}`,
  },
  {
    text: 'Registro de Persona Natural',
  },
];

const dateFormat = 'YYYY/MM/DD';

const NaturalPersonForm = ({
  ids,
  project,
  text,
  title,
  onFinish,
  setDocsComplete,
  initialValues,
  loading,
  personID,
  icfes,
  setIcfes,
  saveGeneralExperiencie,
  experienciaGeneralTextArea,
  setExperienciaGeneralTextArea,
  readOnly,
  checkDocs,
}) => {
  const formRef = useRef(null);
  const [dvField, setDvField] = useState(false);
  const [spanDv, setSpanDv] = useState(12);
  const [form] = Form.useForm();
  const [coordinator] = useState(IsRole(2));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));
  const location = useLocation();
  const educationRef =
    location.state && Object.keys(location.state)[0] === 'educationRef'
      ? location.state.educationRef
      : null;
  const otherCertificationRef =
    location.state && Object.keys(location.state)[0] === 'otherCertificationRef'
      ? location.state.otherCertificationRef
      : null;
  const workExperienceRef =
    location.state && Object.keys(location.state)[0] === 'workExperienceRef'
      ? location.state.workExperienceRef
      : null;
  const icfesExperienceRef =
    location.state && Object.keys(location.state)[0] === 'icfesExperienceRef'
      ? location.state.icfesExperienceRef
      : null;
  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };

  const breadcrumbItemsTerms = [
    {
      text: 'Proyectos',
      to: `${INTERNAL_LINKS.PROJECTS}`,
    },
    {
      text: 'Términos de referencia por proyecto',
      to: `${INTERNAL_LINKS.TERMS}/${ids.project_id}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`,
    },
    {
      text: 'Formulario de búsqueda de contratistas',
      to: `${INTERNAL_LINKS.SEARCHCONTRACTOR}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`,
    },
    {
      text: 'Registro de Persona Natural',
    },
  ];

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function calcularDigitoVerificacion(e) {
    var myNit = e.target.value;
    var vpri, x, y, z;
    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ''); // Espacios
    myNit = myNit.replace(/,/g, ''); // Comas
    myNit = myNit.replace(/\./g, ''); // Puntos
    myNit = myNit.replace(/-/g, ''); // Guiones
    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return '';
    }
    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      x += y * vpri[z - i];
    }

    y = x % 11;
    let res = y > 1 ? 11 - y : y;
    if (res) {
      form.setFieldsValue({ digito_verificacion: res });
    } else {
      alert('Verifique el número de documento registrado');
      form.setFieldsValue({ digito_verificacion: '' });
      form.setFieldsValue({ numero_identificacion: '' });
    }
  }

  useEffect(() => {
    if (educationRef) {
      educationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (otherCertificationRef) {
      otherCertificationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (workExperienceRef) {
      workExperienceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (icfesExperienceRef) {
      icfesExperienceRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    onChangeIdentificationType(initialValues.tipo_identificacion);
  }, []);

  const onChangeIdentificationType = (value) => {
    setDvField(false);
    setSpanDv(12);

    if (value === 7 || value === 15) {
      setDvField(true);
      setSpanDv(7);
    }
  };

  const validatorIdentification = (length) => {
    if (dvField) return validatorNumbers(length);

    return true;
  };

  return (
    <Content>
      {(superUser || assistant || coordinator) &&
        ids.term_id != null &&
        ids.project_id != null &&
        ids.contract_id != null && (
          <>
            <BreadcrumbContainer items={breadcrumbItemsTerms} />
            <div className="box-title">
              <Title className={'text-uppercase'} level={2}>
                TÉRMINOS DE REFERENCIA
              </Title>
              <Title level={3}>PROYECTO: {project && project.nombre}</Title>
            </div>
            <div className="box-description">
              <Paragraph>
                Los siguientes términos de referencia complementan el texto del
                contrato firmado entre las partes y están subordinados a dichos
                contratos.
              </Paragraph>
            </div>
            <br></br>
            <Divider />
            <br></br>
            <Title className={'text-uppercase'} level={2}>
              E. DATOS DEL CONTRATISTA
            </Title>
            <div className="box-description">
              <Paragraph>
                Por favor diligencie los datos del contratista que va a crear:
              </Paragraph>
              <Title level={3}>PERSONA NATURAL</Title>
            </div>
          </>
        )}
      {superUser &&
        ids.term_id == null &&
        ids.project_id == null &&
        ids.contract_id == null && (
          <>
            <BreadcrumbContainer items={breadcrumbItems} />
            <Title className={'text-uppercase'} level={2}>
              {title}
            </Title>
            <Paragraph style={{ marginTop: 10 }}>{text}</Paragraph>
          </>
        )}
      {!superUser && (
        <>
          <BreadcrumbContainer items={breadcrumbItems} />
          <Title className={'text-uppercase'} level={2}>
            {title}
          </Title>
          <Paragraph style={{ marginTop: 10 }}>{text}</Paragraph>
        </>
      )}
      <Form
        name="nest-messages"
        form={form}
        layout="vertical"
        onFinish={readOnly ? () => {} : onFinish}
        initialValues={{ ...initialValues }}
        scrollToFirstError={true}
        onValuesChange={(data) => {
          if (data.hasOwnProperty('notificacion_pais')) {
            form.setFieldsValue({
              notificacion_estado: null,
              notificacion_ciudad: null,
            });
          }
          if (data.hasOwnProperty('notificacion_estado')) {
            form.setFieldsValue({
              notificacion_ciudad: null,
            });
          }
        }}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Identificación general</Title>}
        >
          <Row gutter={12} ref={formRef}>
            <Col span={12}>
              <Form.Item
                name="primer_nombre_razon_social"
                label="Nombre(s)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input allowClear placeholder="Nombre(s)" disabled={readOnly} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="primer_apellido"
                label="Apellidos"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input allowClear placeholder="Apellidos" disabled={readOnly} />
              </Form.Item>
            </Col>
          </Row>
          {!superUser && (
            <Row gutter={12}>
              <Col span={24}>
                <Alert
                  style={{ marginBottom: '15px' }}
                  message="Atención"
                  description={
                    'Si la información de su Tipo de documento o Número de documento se encuentra errada, por favor comuníquese con la entidad'
                  }
                  type={'info'}
                  showIcon
                />
              </Col>
            </Row>
          )}
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="tipo_identificacion"
                label="Tipo de documento"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectIdentifitiesType
                  onChange={onChangeIdentificationType}
                  tipo_identificacion={initialValues.tipo_identificacion}
                  disabled={readOnly || !superUser}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={spanDv}>
              <Form.Item
                name="numero_identificacion"
                label="Número de documento"
                rules={[
                  {
                    required: true,
                  },
                  validatorIdentification(6),
                ]}
              >
                <Input
                  onBlur={(e) => {
                    if (dvField) calcularDigitoVerificacion(e);
                  }}
                  allowClear
                  placeholder="Número de documento"
                  disabled={readOnly || !superUser}
                />
              </Form.Item>
            </Col>
            {dvField && (
              <Col span={3}>
                <Form.Item
                  name="digito_verificacion"
                  label="DV"
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !isNaN(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'Verifique el número de documento registrado!',
                        );
                      },
                    }),
                  ]}
                >
                  <Input allowClear placeholder="DV" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={spanDv}>
              <Form.Item
                name="numeroDocumentoConfirm"
                label="Confirme el número de documento"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue('numero_identificacion') === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'El número de documento no coincide!',
                      );
                    },
                  }),
                ]}
              >
                <Input
                  allowClear
                  onPaste={(e) => e.preventDefault()}
                  placeholder="Confirme el número de documento"
                  disabled={readOnly || !superUser}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={7}>
              <Form.Item
                name="fecha_expedicion_documento"
                label="Fecha de expedición del documento"
                rules={[
                  {
                    required: true,
                    type: 'object',
                  },
                ]}
              >
                <DatePicker
                  allowClear
                  format={dateFormat}
                  disabledDate={disabledDate}
                  placeholder="Fecha de expedición del documento"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="lugar_expedicion_documento"
                label="Lugar de expedición del documento"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Lugar de expedición del documento"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="sexo"
                label="Sexo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectGenders disabled={readOnly} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="rango_edad"
                label="Rango de edad"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectRangeAge
                  rango_edad={initialValues.rango_edad}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="numero_celular"
                label="Teléfono de notificación"
                rules={[
                  {
                    required: true,
                  },
                  validatorNumbers(7),
                ]}
              >
                <Input
                  allowClear
                  placeholder="Teléfono de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="celularConfirm"
                label="Confirme el Teléfono de notificación"
                maxLength={2}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('numero_celular') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'El Teléfono de notificación no coincide!',
                      );
                    },
                  }),
                  validatorNumbers(7),
                ]}
              >
                <Input
                  allowClear
                  onPaste={(e) => e.preventDefault()}
                  placeholder="Confirme el teléfono de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="otros_numeros_telefonicos"
                label="Otros números telefónicos"
              >
                <Input
                  allowClear
                  placeholder="Otros números telefónicos"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={18}>
              <Form.Item
                name="notificacion_direccion"
                label="Dirección de notificación"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Dirección de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="codigo_postal"
                label="Código postal"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value != null && value.length > 0) {
                        if (value.length >= 3 && value.length <= 8) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Código postal no válido!');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  allowClear
                  placeholder="Código postal"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <CountryStateCity
            countryKey="notificacion_pais"
            stateKey="notificacion_estado"
            cityKey="notificacion_ciudad"
            countryLabel="País de notificación"
            stateLabel="Departamento de notificación"
            cityLabel="Ciudad de notificación"
            country={initialValues.notificacion_pais}
            state={initialValues.notificacion_estado}
            city={initialValues.notificacion_ciudad}
            required={true}
            disabled={readOnly}
          />
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="notificacion_email"
                label="Correo electrónico de notificación"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Correo electrónico de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="correoConfirm"
                label="Confirme el correo electrónico de notificación"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue('notificacion_email') === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'El correo electrónico de notificación no coincide!',
                      );
                    },
                  }),
                ]}
              >
                <Input
                  allowClear
                  onPaste={(e) => e.preventDefault()}
                  placeholder="Confirme el correo electrónico de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          {!readOnly && (
            <Row gutter={12}>
              <Col span={24}>
                <Space>
                  <CustomButton
                    loading={loading}
                    text="Guardar identificación general"
                  />
                  {(superUser || assistant || coordinator) &&
                    ids.term_id != null &&
                    ids.project_id != null &&
                    ids.contract_id != null && (
                      <CustomButton
                        type={'default'}
                        className={'back-button'}
                        htmlType="button"
                        text={
                          <Link
                            to={`${INTERNAL_LINKS.SEARCHCONTRACTOR}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`}
                          >
                            Volver
                          </Link>
                        }
                      />
                    )}
                  {superUser && ids.term_id == null && (
                    <CustomButton
                      type={'default'}
                      className={'back-button'}
                      htmlType="button"
                      text={
                        <Link to={INTERNAL_LINKS.NATURAL_PERSONS}>Volver</Link>
                      }
                    />
                  )}
                </Space>
              </Col>
            </Row>
          )}
        </Card>
        {personID > 0 && (
          <>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>Educación</Title>}
            >
              <div ref={educationRef}>
                <Paragraph>
                  Recuerde que solo debe agregar la información relacionada con
                  el quehacer de Corpoeducación (información que considere
                  relevante) de la más reciente a la más antigua, de lo
                  contrario dicha información puede ser eliminada de la
                  plataforma.
                </Paragraph>
                {!readOnly && (
                  <CustomButton
                    type={'default'}
                    className={'ant-btn-info'}
                    htmlType="button"
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.EDUCATION_CONTRACTORS}/${personID}`}
                      >
                        <PlusOutlined /> Agregar educación
                      </Link>
                    }
                  />
                )}
                <EducationContractorsList
                  idTercero={personID}
                  readOnly={readOnly}
                />
              </div>
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>Otras certificaciones (opcional)</Title>}
            >
              <div ref={otherCertificationRef}>
                {readOnly ? (
                  <Paragraph>
                    Revise aquí únicamente las certificaciones que evidencien la
                    idoneidad en algún tema específico requerido por
                    Corpoeducación
                  </Paragraph>
                ) : (
                  <Paragraph>
                    Registre aquí únicamente las certificaciones que evidencien
                    su idoneidad en algún tema específico requerido por
                    Corpoeducación y que no lo haya cargado anteriormente
                  </Paragraph>
                )}
                <CustomButton
                  type={'default'}
                  className={'ant-btn-info'}
                  htmlType="button"
                  text={
                    <Link
                      to={`${INTERNAL_LINKS.OTHER_CERTIFICATIONS}/${personID}`}
                    >
                      <PlusOutlined /> Agregar otras certificaciones
                    </Link>
                  }
                />
                <OtherCertificationsList
                  idTercero={personID}
                  readOnly={readOnly}
                />
              </div>
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>Experiencia laboral general</Title>}
            >
              <Row gutter={12} ref={workExperienceRef}>
                <Col span={24}>
                  <Paragraph>
                    Por favor realice una descripción de su experiencia laboral,
                    incluyendo principalmente las palabras clave que resuman
                    mejor los cargos, especialidades y áreas en las que se ha
                    desempeñado
                  </Paragraph>
                  <Form.Item
                    name="descripcion"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={'Experiencia laboral general'}
                      maxLength={1000}
                      showCount
                      rows={5}
                      onChange={(e) =>
                        setExperienciaGeneralTextArea(e.target.value)
                      }
                      defaultValue={experienciaGeneralTextArea}
                      disabled={readOnly}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {readOnly ? (
                <Paragraph style={{ marginTop: 10 }}>
                  Recuerde que solo encuentra la información relacionada con el
                  quehacer de Corpoeducación
                </Paragraph>
              ) : (
                <Space direction={'vertical'}>
                  <CustomButton
                    loading={loading}
                    onClick={saveGeneralExperiencie}
                    htmlType="button"
                    text="Guardar experiencia laboral general"
                  />
                  <Paragraph>
                    Recuerde que solo debe agregar la información relacionada
                    con el quehacer de Corpoeducación, de lo contrario dicha
                    información puede ser eliminada de la plataforma
                  </Paragraph>
                </Space>
              )}
              <CustomButton
                type={'default'}
                className={'ant-btn-info'}
                htmlType="button"
                text={
                  <Link to={`${INTERNAL_LINKS.LABOR_CONTRACTORS}/${personID}`}>
                    <PlusOutlined /> Agregar experiencia laboral
                  </Link>
                }
              />
              <LaborContractorsList idTercero={personID} readOnly={readOnly} />
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>Experiencia ICFES</Title>}
            >
              <div ref={icfesExperienceRef}>
                <Space direction={'vertical'}>
                  ¿Ha tenido algún tipo de contratación con el ICFES?
                  <Radio.Group
                    onChange={(e) => setIcfes(e.target.value)}
                    defaultValue={icfes}
                    disabled={readOnly}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  {!readOnly && (
                    <CustomButton
                      loading={loading}
                      onClick={saveGeneralExperiencie}
                      text="Guardar"
                    />
                  )}
                </Space>
                {icfes === true && (
                  <Row gutter={12}>
                    <Col span={24}>
                      {readOnly ? (
                        <Paragraph style={{ marginTop: 10 }}>
                          A continuación, se lista su experiencia
                        </Paragraph>
                      ) : (
                        <Paragraph style={{ marginTop: 10 }}>
                          A continuación, agregue su experiencia
                        </Paragraph>
                      )}
                      <CustomButton
                        type={'default'}
                        className={'ant-btn-info'}
                        htmlType="button"
                        text={
                          <Link
                            to={`${INTERNAL_LINKS.EXPERIENCIE_ICFES}/${personID}`}
                          >
                            <PlusOutlined /> Agregar experiencia ICFES
                          </Link>
                        }
                      />
                      <ExperiencieIcfesList
                        idTercero={personID}
                        readOnly={readOnly}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Card>
            <AdditionalDocumentsNatural
              person_id={personID}
              readOnly={readOnly}
              setDocsComplete={setDocsComplete}
            />
            {!readOnly && (
              <Card size={'small'} bordered={false}>
                <Space>
                  <CustomButton
                    text="Guardar y terminar registro"
                    loading={loading}
                    onClick={async () => {
                      try {
                        await form.validateFields();
                        form.submit();
                        checkDocs();
                      } catch (e) {
                        formRef.current.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  />
                  <CustomButton
                    type={'default'}
                    className={'back-button'}
                    htmlType="button"
                    text={<Link to={INTERNAL_LINKS.HOME}> Volver </Link>}
                  />
                </Space>
              </Card>
            )}
          </>
        )}
      </Form>
    </Content>
  );
};

NaturalPersonForm.propTypes = {
  ids: PropTypes.object,
  project: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
  onFinish: PropTypes.func,
  setDocsComplete: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  personID: PropTypes.number,
  readOnly: PropTypes.bool,
  checkDocs: PropTypes.func,
};

NaturalPersonForm.defaultProps = {
  ids: {},
  project: {},
  text: '',
  title: '',
  onFinish: () => {},
  setDocsComplete: () => {},
  checkDocs: () => {},
  initialValues: {},
  loading: false,
  personID: 0,
  readOnly: false,
};
export default NaturalPersonForm;
