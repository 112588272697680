import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  notification,
  Table,
  Typography,
  Modal,
  List,
  Alert,
  Card,
  Popconfirm,
  Radio,
} from 'antd';

import NaturalPersonForm from '../../containers/SearchContratorForm/NaturalPersonForm/index';
import LegalPersonForm from '../../containers/SearchContratorForm/LegalPersonForm/index';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import MESSAGES from '../../enums/Messages';
import { CustomButton } from '../../components';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { post } from '../../api/module/contractor_search';
import { get as getTermByID } from '../../api/module/terms';
import { get as getContractorByTermino } from '../../api/module/contractratorTerminos';
import {
  post as addContract,
  remove as deleteContract,
  get as getID,
} from '../../api/module/add_contract_termino';
import Text from 'antd/es/typography/Text';
import SpinLoad from '../../components/SpinLoad';
import { getListByJobTerm } from '../../api/module/project_position_assignments';
import AjaxTable from '../../components/AjaxTable';
import { ajaxTable as filterAjaxTable } from '../../api/module/contractratorTerminos';

const { Title, Paragraph } = Typography;

const SearchContractor = ({ match }) => {
  const [termID] = useState(parseInt(match.params.term_id));
  const [projectID] = useState(parseInt(match.params.project_id));
  const [contractID] = useState(parseInt(match.params.contract_id));
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceWorkTeam, setDataSourceWorkTeam] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [selectByTerms, setSelectByTerms] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingWorkTeam, setLoadingWorkTeam] = useState(false);
  const [contractorSource, setContractorSource] = useState(null);
  const [personType, setPersonType] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [ids] = useState({
    termID: termID,
    projectID: projectID,
    contractID: contractID,
  });
  const [knowledgeArea, setKnowledgeArea] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [term, setTerm] = React.useState(null);
  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}`,
    },
    {
      text: 'Formulario de búsqueda de contratistas',
    },
  ];

  useEffect(async () => {
    try {
      await Promise.all([getContractData(), getContractTerm()]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getContractData = async () => {
    const response = await getTermByID(termID);
    setTerm(response.data);
    setPersonType(response.data.tipo_persona);

    await getDataSourceWorkTeam(termID);
  };

  const getDataSourceWorkTeam = async (term) => {
    try {
      setLoadingWorkTeam(true);
      const response = await getListByJobTerm(term);
      setDataSourceWorkTeam(response.data.results);
      setLoadingWorkTeam(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoadingWorkTeam(false);
    }
  };

  const selectedKnowledge = (value) => {
    setKnowledgeArea(value);
  };

  const onFinish = async (data) => {
    try {
      if (data.documento == '') {
        data.documento = undefined;
      }
      if (data.nombre == '') {
        data.nombre = undefined;
      }
      if (data.email == '') {
        data.email = undefined;
      }
      if (data.tipo_cargo == '') {
        data.tipo_cargo = undefined;
      }
      if (data.meses_experiencia == '') {
        data.meses_experiencia = undefined;
      }
      if (data.pregrado != null) {
        data.nivel_educativo = 7;
        delete data.pregrado;
      }
      if (data.especializacion != null) {
        data.nivel_educativo = 8;
        delete data.especializacion;
      }
      if (data.maestria != null) {
        data.nivel_educativo = 9;
        delete data.maestria;
      }
      if (data.doctorado != null) {
        data.nivel_educativo = 10;
        delete data.doctorado;
      }
      if (data.posdoctorado != null) {
        data.nivel_educativo = 11;
        delete data.posdoctorado;
      }
      data.area_conocimiento = knowledgeArea;
      data.tipo_persona = personType;
      data.termino = termID;
      setLoadingSearch(true);
      const response = await post(data);
      setLoadingSearch(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoadingSearch(false);
    }
  };

  const checkContract = (selectedRows) => {
    setShowModal(selectedRows.some((item) => item?.contrato));
  };

  const checkCargos = (selectedRows) => {
    setShowErrorModal(selectedRows.some((item) => item?.cargos_aplica));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelect(selectedRows);
      checkContract(selectedRows);
      checkCargos(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setDataSelect(selectedRows);
      checkContract(selectedRows);
      checkCargos(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setDataSelect(selectedRows);
      checkContract(selectedRows);
      checkCargos(selectedRows);
    },
  };

  const save = () => {
    if (dataSelect.length > 0) {
      dataSelect.map(async (item) => {
        try {
          setLoadingSearch(true);
          setLoadingAdd(true);
          const response = await addContract({
            termino: termID,
            tercero: item.tercero ?? item.id,
          });
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
          }
          await getContractTerm();
          await getContractData();
          setLoadingSearch(false);
          setLoadingAdd(false);
        } catch (err) {
          console.log(err);
          setLoadingSearch(false);
          setLoadingAdd(false);
        }
      });
      setDataSource([]);
    } else {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_ADD_CONTRACTOR,
        duration: 2,
      });
      setLoadingSearch(false);
      setLoadingAdd(false);
    }
  };

  const getContractTerm = async () => {
    const response = await getContractorByTermino(termID);
    setSelectByTerms(response.data.results);
    setLoadingList(!loadingList);
  };

  const deleteDataSource = useCallback(
    async (id) => {
      setLoadingDelete(true);
      try {
        let response = await getID(
          '?filter[tercero]=' + id + '&filter[termino]=' + termID,
        );
        response = await deleteContract(response.data.results[0].id);
        await getContractData();
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
        }
        await getContractTerm();
        setSortBy('id');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
      setLoadingDelete(false);
    },
    [dataSource],
  );

  const columns = [
    {
      title: 'Nombres y apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'tipo_identificacion_nombre',
    },
    {
      title: 'Nro. de documento',
      dataIndex: 'numero_identificacion',
      key: 'numero_identificacion',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion_documento',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'numero_celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'notificacion_direccion',
    },
    {
      title: 'País',
      dataIndex: 'notificacion_pais_nombre',
    },
    {
      title: 'Departamento',
      dataIndex: 'notificacion_estado_nombre',
    },
    {
      title: 'Ciudad',
      dataIndex: 'notificacion_ciudad_nombre',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      width: 125,
      render: (_, record) => (
        <Popconfirm
          title={MESSAGES.DELETE_CONFIRM_MSG}
          onConfirm={() => deleteDataSource(record.id)}
        >
          <CustomButton
            danger
            loading={loadingDelete}
            type="default"
            text="Eliminar"
          />
        </Popconfirm>
      ),
    },
  ];

  const columnsWorkTeam = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'num_documento',
    },
    {
      title: 'Fecha expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'País',
      dataIndex: 'pais',
      key: 'pais',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
  ];

  return (
    <div>
      <BreadcrumbContainer items={breadcrumbItems} />
      {personType == null && (
        <>
          <Title className={'text-uppercase'} level={2}>
            Formulario de búsqueda de contratistas
          </Title>
          <Paragraph>
            Antes de continuar por favor seleccione un tipo de persona en los
            términos de referencia y haga clic en el botón GUARDAR MÓDULO B.
          </Paragraph>
        </>
      )}
      {personType != null && (
        <>
          <Title className={'text-uppercase'} level={2}>
            TÉRMINOS DE REFERENCIA
          </Title>
          <Title level={3}>PROYECTO: {term && term.proyecto_nombre}</Title>
          <Paragraph>
            Los siguientes términos de referencia complementan el texto del
            contrato firmado entre las partes y están subordinados a dichos
            contratos.
          </Paragraph>
          {showAlert && (
            <Alert
              message="Atención"
              description={
                <Text>
                  La(s) siguiente(s) persona(s) ya tiene(n) un TDR asignado en
                  este proyecto para un cargo/rol diferente:
                  <List
                    style={{ marginTop: 20 }}
                    dataSource={selectByTerms}
                    renderItem={(item) =>
                      item.contrato && (
                        <List.Item>{item.nombre_completo}</List.Item>
                      )
                    }
                  />
                </Text>
              }
              type="warning"
              showIcon
            />
          )}
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>E. DATOS DEL CONTRATISTA</Title>}
          >
            <Paragraph>
              Seleccione desde dónde desea agregar contratistas a este TDR
            </Paragraph>
            <Radio.Group
              style={{ marginBottom: 15 }}
              onChange={(e) => {
                setContractorSource(e.target.value);
              }}
            >
              <Radio value={1}>Desde equipo de trabajo</Radio>
              <Radio style={{ marginRight: '13px' }} value={0}>
                Desde la búsqueda de contratistas{' '}
              </Radio>
            </Radio.Group>
            {contractorSource === 0 && (
              <>
                <Paragraph>
                  Por favor, busque los datos del contratista por el criterio de
                  búsqueda deseado. Si la búsqueda no arroja resultados proceda
                  a crearlo(a) para poder agregarlo(a)
                </Paragraph>
                {personType === 1 && (
                  <NaturalPersonForm
                    dataSource={dataSource}
                    ids={ids}
                    onFinish={onFinish}
                    loading={loadingSearch}
                    selectedKnowledge={selectedKnowledge}
                    rowSelection={rowSelection}
                  />
                )}
                {personType === 2 && (
                  <LegalPersonForm
                    dataSource={dataSource}
                    ids={ids}
                    onFinish={onFinish}
                    setKnowledgeArea={setKnowledgeArea}
                    loading={loadingSearch}
                    rowSelection={rowSelection}
                  />
                )}
              </>
            )}
            {contractorSource === 1 && (
              <SpinLoad loading={loadingWorkTeam}>
                <Table
                  rowSelection={{ ...rowSelection }}
                  columns={columnsWorkTeam}
                  rowKey="id"
                  scroll={{ x: 2200 }}
                  dataSource={dataSourceWorkTeam}
                  title={() => (
                    <Paragraph className={'margin-5'} strong>
                      Listado de contratistas para agregar
                    </Paragraph>
                  )}
                />
              </SpinLoad>
            )}
            {term &&
              contractorSource != null &&
              selectByTerms.length < term.cargos_requeridos && (
                <div className="text-center">
                  <CustomButton
                    loading={loadingAdd}
                    text="Agregar contratista"
                    onClick={() => {
                      if (showErrorModal) {
                        Modal.error({
                          title: 'Error',
                          content: (
                            <>
                              La(s) siguiente(s) persona(s) ya tiene(n) un TDR
                              asignado en este proyecto para este mismo
                              cargo/rol:
                              <List
                                dataSource={dataSelect}
                                renderItem={(item) =>
                                  item?.cargos_aplica ? (
                                    <List.Item>
                                      {item.nombre_completo} -{' '}
                                      {item.cargos_aplica}
                                    </List.Item>
                                  ) : null
                                }
                              />
                            </>
                          ),
                          okText: 'Volver',
                        });
                      } else if (showModal) {
                        Modal.confirm({
                          title: 'Atención',
                          content: (
                            <>
                              La(s) siguiente(s) persona(s) ya tiene(n) un TDR
                              asignado en este proyecto para otro(s) cargo/rol:
                              <List
                                dataSource={dataSelect}
                                renderItem={(item) =>
                                  item?.contrato ? (
                                    <List.Item>
                                      {item.nombre_completo} - {item.contrato}
                                    </List.Item>
                                  ) : null
                                }
                              />
                              ¿Desea continuar?
                            </>
                          ),
                          okText: 'Si',
                          cancelText: 'No',
                          onOk: () => {
                            save();
                          },
                        });
                      } else {
                        save();
                      }
                    }}
                  />
                </div>
              )}
            <AjaxTable
              columns={columns}
              tableTitle={'Listado de contratistas agregados'}
              endpoint={filterAjaxTable}
              query={`/${termID}/?`}
              reloadSource={loadingList}
              sortBy={sortBy}
            />
          </Card>
        </>
      )}
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        text={
          <Link
            to={`${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}/?module=E`}
          >
            Volver
          </Link>
        }
      />
    </div>
  );
};

export default SearchContractor;
