import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Divider,
  Alert,
  Card,
  Space,
  Layout,
} from 'antd';
import { Link } from 'react-router-dom';

import { IsRole, validatorNumbers } from '../../utils/helpers/helpers';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import SelectIdentifitiesType from '../../components/SelectIdentifitiesType/index';
import SelectOrganizationType from '../../components/SelectOrganizationType/index';
import CountryStateCity from '../../components/CountryStateCity';
import AdditionalDocumentsLegal from '../../pages/AdditionalDocumentsLegal/index';

const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Proveedores y Consultores - Persona Jurídica',
    to: `${INTERNAL_LINKS.LEGAL_PERSONS}`,
  },
  {
    text: 'Registro de Persona Jurídica',
  },
];

const LegalPersonForm = ({
  ids,
  person_id,
  project,
  title,
  onFinish,
  setDocsComplete,
  initialValues,
  loading,
  readOnly,
  checkDocs,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [coordinator] = useState(IsRole(2));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));
  const [isColombianFactory, setIsColombianFactory] = useState(null);
  const [dvField, setDvField] = useState(false);
  const [identificationTypeCEO, setIdentificationTypeCEO] = useState(false);

  const breadcrumbItemsTerms = [
    {
      text: 'Proyectos',
      to: `${INTERNAL_LINKS.PROJECTS}`,
    },
    {
      text: 'Términos de referencia por proyecto',
      to: `${INTERNAL_LINKS.TERMS}/${ids.project_id}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`,
    },
    {
      text: 'Formulario de búsqueda de contratistas',
      to: `${INTERNAL_LINKS.SEARCHCONTRACTOR}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`,
    },
    {
      text: 'Registro de Persona Jurídica',
    },
  ];

  function calcularDigitoVerificacion(e) {
    var myNit = e.target.value;
    var vpri, x, y, z;
    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ''); // Espacios
    myNit = myNit.replace(/,/g, ''); // Comas
    myNit = myNit.replace(/\./g, ''); // Puntos
    myNit = myNit.replace(/-/g, ''); // Guiones
    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      form.setFieldsValue({ digito_verificacion: '' });
      return;
    }
    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      x += y * vpri[z - i];
    }

    y = x % 11;
    let res = y > 1 ? 11 - y : y;
    if (res !== null && res !== undefined) {
      form.setFieldsValue({ digito_verificacion: res });
    } else {
      console.log('Error al calcular DV');
      form.setFieldsValue({
        digito_verificacion: '',
        numero_identificacion: '',
      });
    }
  }

  const onChangeIdentificationType = (value) => {
    setDvField(false);

    if (value === 7 || value === 15) {
      setDvField(true);
    }
  };

  const onChangeIdentificationTypeCEO = (value) => {
    setIdentificationTypeCEO(false);

    if (value === 7 || value === 15) {
      setIdentificationTypeCEO(true);
    }
  };

  const validatorIdentification = (length) => {
    if (dvField) return validatorNumbers(length);

    return true;
  };

  const validatorIdentificationCEO = (length) => {
    if (identificationTypeCEO) return validatorNumbers(length);

    return true;
  };

  useEffect(() => {
    setIsColombianFactory(initialValues.constituido_colombia);
    onChangeIdentificationType(initialValues.tipo_identificacion);
    onChangeIdentificationTypeCEO(
      initialValues.representante_tipo_identificacion,
    );
  }, []);

  return (
    <Content>
      {(superUser || assistant || coordinator) &&
        ids.term_id != null &&
        ids.project_id != null &&
        ids.contract_id != null && (
          <>
            <BreadcrumbContainer items={breadcrumbItemsTerms} />
            <div className="box-title">
              <Title level={2}>TÉRMINOS DE REFERENCIA</Title>
              <Title level={3}>PROYECTO: {project && project.nombre}</Title>
            </div>
            <div className="box-description">
              <Paragraph>
                Los siguientes términos de referencia complementan el texto del
                contrato firmado entre las partes y están subordinados a dichos
                contratos.
              </Paragraph>
            </div>
            <Divider />
            <Title level={2}>E. DATOS DEL CONTRATISTA</Title>
            <div className="box-description">
              <Paragraph>
                Por favor diligencie los datos del contratista que va a crear:
              </Paragraph>
              <Title level={3}>PERSONA JURÍDICA</Title>
            </div>
          </>
        )}
      {superUser &&
        ids.term_id == null &&
        ids.project_id == null &&
        ids.contract_id == null && (
          <>
            <BreadcrumbContainer items={breadcrumbItems} />
            <Title className={'text-uppercase'} level={2}>
              {title}
            </Title>
          </>
        )}
      {contractor && (
        <>
          <BreadcrumbContainer items={breadcrumbItems} />
          <Title className={'text-uppercase'} level={2}>
            {title}
          </Title>
        </>
      )}
      <Form
        name="nest-messages"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        onValuesChange={(data) => {
          if (data.hasOwnProperty('notificacion_pais')) {
            const toSet = {
              notificacion_estado: null,
              notificacion_ciudad: null,
            };
            form.setFieldsValue(toSet);
          }
          if (data.hasOwnProperty('notificacion_estado')) {
            const toSet = { notificacion_ciudad: null };
            form.setFieldsValue(toSet);
          }
          if (data.hasOwnProperty('constituido_colombia')) {
            let toSet;
            if (
              data.constituido_colombia === initialValues.constituido_colombia
            ) {
              toSet = {
                numero_identificacion: initialValues.numero_identificacion,
                digito_verificacion: initialValues.digito_verificacion,
                nitConfirm: initialValues.numero_identificacion,
              };
            } else {
              toSet = {
                numero_identificacion: null,
                digito_verificacion: null,
                nitConfirm: null,
              };
            }
            form.setFieldsValue(toSet);
          }
        }}
        initialValues={{ ...initialValues }}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Identificación general</Title>}
        >
          <Row gutter={12} ref={formRef}>
            <Col span={12}>
              <Form.Item
                name="primer_nombre_razon_social"
                label="Nombre Razón social"
                rules={[
                  {
                    required: true,
                    message: 'Razón social es requerido',
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Nombre Razón social..."
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sigla_comercial"
                label="Sigla o nombre comercial"
                tooltip="El nombre comercial se debe agregar solo si es diferente al nombre de la razón social"
              >
                <Input
                  allowClear
                  placeholder="Sigla o nombre comercial..."
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="constituido_colombia"
                label="¿Está constituido en Colombia?"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsColombianFactory(e.target.value);
                  }}
                  disabled={readOnly}
                >
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {!isColombianFactory && (
              <Col span={12}>
                <Form.Item
                  name="tipo_identificacion"
                  label="Tipo de documento"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SelectIdentifitiesType
                    tipo_identificacion={initialValues.tipo_identificacion}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          {!superUser && (
            <Row gutter={12}>
              <Col span={24}>
                <Alert
                  style={{ marginBottom: '15px' }}
                  message="Atención"
                  description={
                    'Si la información de su NIT o documento equivalente se encuentra errada, por favor comuníquese con la entidad'
                  }
                  type={'info'}
                  showIcon
                />
              </Col>
            </Row>
          )}
          <Row gutter={12}>
            <Form.Item noStyle dependencies={['constituido_colombia']}>
              {({ getFieldValue }) =>
                getFieldValue('constituido_colombia') === true ? (
                  <Col span={10}>
                    <Form.Item
                      label="NIT o documento equivalente"
                      name="numero_identificacion"
                      rules={[
                        {
                          required: true,
                        },
                        validatorNumbers(9),
                      ]}
                    >
                      <Input
                        onBlur={calcularDigitoVerificacion}
                        allowClear
                        placeholder="NIT o documento equivalente"
                        disabled={readOnly || !superUser}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={12}>
                    <Form.Item
                      label="Documento equivalente al NIT"
                      name="numero_identificacion"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        allowClear
                        placeholder="NIT o documento equivalente"
                        disabled={readOnly || !superUser}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
            <Form.Item
              noStyle
              dependencies={['constituido_colombia', 'numero_identificacion']}
            >
              {({ getFieldValue }) =>
                getFieldValue('constituido_colombia') === true ? (
                  <Col span={2}>
                    <Form.Item
                      name="digito_verificacion"
                      label="DV"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input allowClear placeholder="DV" disabled />
                    </Form.Item>
                  </Col>
                ) : null
              }
            </Form.Item>
            <Col span={12}>
              <Form.Item
                noStyle
                dependencies={['constituido_colombia', 'numero_identificacion']}
              >
                {({ getFieldValue }) =>
                  getFieldValue('constituido_colombia') === true ? (
                    <Form.Item
                      name="nitConfirm"
                      label="Confirme el NIT o documento"
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue('numero_identificacion') === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('El NIT no coincide!'),
                            );
                          },
                        }),
                        validatorNumbers(9),
                      ]}
                    >
                      <Input
                        onPaste={(e) => e.preventDefault()}
                        allowClear
                        placeholder="Confirma el Nit o documento equivalente"
                        disabled={readOnly || !superUser}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="nitConfirm"
                      label="Confirme el NIT o documento"
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue('numero_identificacion') === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('El NIT no coincide!'),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="text"
                        onPaste={(e) => e.preventDefault()}
                        allowClear
                        placeholder="Confirma el Nit o documento equivalente"
                        disabled={readOnly || !superUser}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="tipo_organizacion"
                label="Tipo de organización"
                rules={[
                  {
                    required: true,
                    message: 'Tipo de organización es requerido',
                  },
                ]}
              >
                <SelectOrganizationType
                  organization_type={initialValues.tipo_organizacion}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="representante_nombre"
                label="Nombre Representante legal"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Nombre Representante legal"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="representante_apellido"
                label="Apellido Representante legal"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Apellido Representante legal"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="representante_tipo_identificacion"
                label="Tipo de documento del Representante Legal"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectIdentifitiesType
                  onChange={(value) => {
                    onChangeIdentificationTypeCEO(value);
                  }}
                  tipo_identificacion={
                    initialValues.representante_tipo_identificacion
                  }
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="representante_numero_identificacion"
                label="Número de documento del Representante Legal"
                rules={[
                  {
                    required: true,
                  },
                  validatorIdentificationCEO(6),
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Número de documento del Representante Legal"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="numero_celular"
                label="Teléfono de notificación"
                rules={[
                  {
                    required: true,
                  },
                  validatorNumbers(7, true),
                ]}
              >
                <Input
                  placeholder="Teléfono de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="celularConfirm"
                label="Confirme el teléfono de notificación"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('numero_celular') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('El Teléfono de notificación no coincide!'),
                      );
                    },
                  }),
                  validatorNumbers(7, true),
                ]}
              >
                <Input
                  onPaste={(e) => e.preventDefault()}
                  placeholder="Teléfono de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="otros_numeros_telefonicos"
                label="Otros números telefónicos"
              >
                <Input
                  allowClear
                  placeholder="Otros números telefónicos"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={16}>
              <Form.Item
                name="notificacion_direccion"
                label="Dirección de notificación"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Dirección de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            {contractor && (
              <Col span={8}>
                <Form.Item
                  name="codigo_postal"
                  label="Código postal"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value != null && value.length > 0) {
                          if (value.length >= 3 && value.length <= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Código postal no válido!');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Código postal"
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <CountryStateCity
            countryKey="notificacion_pais"
            stateKey="notificacion_estado"
            cityKey="notificacion_ciudad"
            countryLabel="País de notificación"
            stateLabel="Departamento de notificación"
            cityLabel="Ciudad de notificación"
            country={initialValues.notificacion_pais}
            state={initialValues.notificacion_estado}
            city={initialValues.notificacion_ciudad}
            required={true}
            disabled={readOnly}
          />
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="notificacion_email"
                label="Correo electrónico de notificación"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Correo electrónico de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="correoConfirm"
                label="Confirme el Correo electrónico de notificación"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue('notificacion_email') === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'El correo electrónico de notificación no coincide!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  allowClear
                  onPaste={(e) => e.preventDefault()}
                  placeholder="Confirme el correo electrónico de notificación"
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
          {!readOnly && (
            <Form.Item>
              <Space>
                <CustomButton
                  loading={loading}
                  text="Guardar identificación general"
                />
                {(superUser || assistant || coordinator) &&
                  ids.term_id != null &&
                  ids.project_id != null &&
                  ids.contract_id != null && (
                    <CustomButton
                      type={'default'}
                      className={'back-button'}
                      htmlType="button"
                      text={
                        <Link
                          to={`${INTERNAL_LINKS.SEARCHCONTRACTOR}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`}
                        >
                          Volver
                        </Link>
                      }
                    />
                  )}
                {superUser &&
                  ids.term_id == null &&
                  ids.project_id == null &&
                  ids.contract_id == null && (
                    <CustomButton
                      type={'default'}
                      className={'back-button'}
                      htmlType="button"
                      text={
                        <Link to={INTERNAL_LINKS.LEGAL_PERSONS}> Volver </Link>
                      }
                    />
                  )}
              </Space>
            </Form.Item>
          )}
        </Card>
        {person_id > 0 && (
          <>
            <AdditionalDocumentsLegal
              person_id={person_id}
              readOnly={readOnly}
              setDocsComplete={setDocsComplete}
            />
            {!readOnly && (
              <Card size={'small'} bordered={false}>
                <Space>
                  <CustomButton
                    text="Guardar y terminar registro"
                    loading={loading}
                    onClick={async () => {
                      try {
                        await form.validateFields();
                        form.submit();
                        checkDocs();
                      } catch (e) {
                        formRef.current.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  />
                  {(superUser || assistant || coordinator) &&
                    ids.term_id != null &&
                    ids.project_id != null &&
                    ids.contract_id != null && (
                      <CustomButton
                        type={'default'}
                        className={'back-button'}
                        htmlType="button"
                        text={
                          <Link
                            to={`${INTERNAL_LINKS.SEARCHCONTRACTOR}/${ids.term_id}/${ids.project_id}/${ids.contract_id}`}
                          >
                            Volver
                          </Link>
                        }
                      />
                    )}
                  {superUser &&
                    ids.term_id == null &&
                    ids.project_id == null &&
                    ids.contract_id == null && (
                      <CustomButton
                        type={'default'}
                        className={'back-button'}
                        htmlType="button"
                        text={
                          <Link to={INTERNAL_LINKS.LEGAL_PERSONS}>Volver</Link>
                        }
                      />
                    )}
                </Space>
              </Card>
            )}
          </>
        )}
      </Form>
    </Content>
  );
};

LegalPersonForm.propTypes = {
  ids: PropTypes.object,
  person_id: PropTypes.number,
  project: PropTypes.object,
  title: PropTypes.string,
  onFinish: PropTypes.func,
  setDocsComplete: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

LegalPersonForm.defaultProps = {
  ids: {},
  person_id: 0,
  project: {},
  title: '',
  onFinish: () => {},
  setDocsComplete: () => {},
  initialValues: {},
  loading: false,
  isLoading: false,
  readOnly: false,
};
export default LegalPersonForm;
