import React, { useState, memo, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import {
  Typography,
  notification,
  Form,
  Modal,
  Popconfirm,
  Card,
  Row,
  Col,
  Space,
} from 'antd';

import AnnouncementCloseForm from '../../containers/AnnouncementCloseForm/';
import AnnouncementTemplateForm from '../../containers/AnnouncementTemplateForm';
import { AnnouncementCloseSearch } from '../AnnouncementCloseSearch';
import AnnouncementTable from '../../components/TablePosition';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import PdfViewer from '../../components/PdfViewer';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import {
  post,
  postDocument,
  put,
  putDocument,
  postTemplateDocument,
  postInvitations,
} from '../../api/module/announcement';
import { getAllResumeNotNull as getAllAddedContrator } from '../../api/module/announcement_invite';
import { get as getDocumentUrl } from '../../api/module/document_repository';

const { Paragraph } = Typography;

export const AnnouncementClose = memo(
  ({
    initialValues,
    isLoading,
    announcementType,
    announcement_id,
    regulIcfes,
    handleGet,
  }) => {
    let history = useHistory();
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [isLoadingDoc, setIsLoadingDoc] = useState(false);
    const [isSendInvitations, setIsSendInvitations] = useState(false);
    const [externalUsers, setExternalUsers] = useState(
      initialValues != null && initialValues.usuarios_externos ? true : false,
    );
    const [formData] = useState(new FormData());
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [addedContractor, setAddedContractor] = useState([]);
    const [form] = Form.useForm();
    const [fileWasUploaded, setFileWasUploaded] = useState(false);

    const showClick = async (documento_nombre) => {
      setIsLoadingDoc(true);
      const response = await getDocumentUrl(documento_nombre);
      setPdfUrl(response.data.url);
      setShowPdf(true);
      setIsLoadingDoc(false);
    };

    const save = async (data) => {
      try {
        setLoading(true);
        let response;
        if (data.proyecto != null && data.nombre != null) {
          if (data.anexo != null) {
            if (data.anexo.file.status != 'removed') {
              data.anexo = data.anexo.fileList[0].originFileObj;
            }
          } else {
            data.anexo = '';
          }
          data.usuarios_externos = externalUsers;
          data.clase = regulIcfes == 0 ? true : false;
          data.usuarios_externos = externalUsers;
          data.tipo = announcementType && announcementType == 1 ? true : false;
          data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
          data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');

          formData.append('anexo', data);
          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          if (initialValues != null) {
            if (data.anexo != null) {
              response = await putDocument(initialValues.id, formData);
            } else {
              response = await put(initialValues.id, data);
            }
          } else {
            if (data.anexo != null) {
              response = await postDocument(formData);
            } else {
              response = await post(data);
            }
          }
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            setTimeout(() => {
              setLoading(false);
              window.location = `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${
                response.data.tipo == true ? 1 : 0
              }/${response.data.id}`;
            }, MESSAGES.TIME);
          }
        } else {
          alert('Antes de continuar complete los campos necesarios');
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
        setLoading(false);
      }
    };

    const getAddedContractor = async () => {
      try {
        if (announcement_id) {
          setLoading(true);
          setLoadingAdd(true);
          const response = await getAllAddedContrator(announcement_id);
          setLoading(false);
          setLoadingAdd(false);
          setAddedContractor(response.data.results);
        }
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(async () => {
      await getAddedContractor();
    }, []);

    const sendInvitations = async () => {
      if (announcement_id) {
        try {
          setIsSendInvitations(true);
          const response = await postInvitations(announcement_id);
          if ([200, 201, 204].indexOf(response.status) > -1) {
            let secondsToGo = 5;
            const modal = Modal.success({
              title: MESSAGES.SUCCESS_MSG,
              content: (
                <>
                  <p>{MESSAGES.DESC_SUCCESS_SEND_INVITATIONS_MSG}</p>
                  <p>Esta solicitud tardará en terminarse</p>
                </>
              ),
              onOk: () => history.push(`${INTERNAL_LINKS.ANNOUNCEMENT}`),
            });
            setTimeout(() => {
              modal.destroy();
              history.push(`${INTERNAL_LINKS.ANNOUNCEMENT}`);
            }, secondsToGo * 1000);
            setIsSendInvitations(false);
          }
        } catch (err) {
          console.log(err);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_SEND_INVITATIONS_MSG,
            duration: 2,
          });
          setIsSendInvitations(false);
        }
      }
    };

    const saveTemplate = async (data) => {
      setErrors(false);
      try {
        setLoadingFile(true);

        if (data.plantilla != null && [data.plantilla.fileList].length >= 1) {
          if (data.plantilla.file.status != 'removed') {
            data.plantilla = data.plantilla.fileList[0].originFileObj;
          }
        } else {
          data.plantilla = '';
        }
        delete data.dirigido;
        delete data.dirigido_1;
        delete data.dirigido_2;
        delete data.users_unknown;

        data.tipo = announcementType && announcementType == 1 ? true : false;
        data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
        data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');

        formData.append('plantilla', data);
        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }

        const response = await postTemplateDocument(initialValues.id, formData);

        if ([200, 201, 204].indexOf(response.status) > -1) {
          if (response.data.success) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            setFileWasUploaded(true);
            setTimeout(() => {
              //handleGet(response.data.id);
              setLoadingFile(false);
            }, MESSAGES.TIME);
          }
        }
      } catch (exception) {
        let description = MESSAGES.DESC_ERROR_POST_MSG;

        if (exception.response) {
          if (exception.response.data.errors) {
            const errors = exception.response.data.errors;
            description = errors.shift().detail;
          }
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 8,
        });

        setLoadingFile(false);
        if (
          description !== 'El archivo ya se encuentra almacenado en el servidor'
        ) {
          setErrors(true);
        }
      }
    };

    const linkPush = () => {
      if (initialValues == null && announcement_id != null) {
        history.push(
          `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/0/?announcement_id=${announcement_id}&edit=false`,
        );
      } else {
        history.push(
          `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/0/?announcement_id=${announcement_id}&edit=true`,
        );
      }
    };

    return (
      <>
        <SpinLoad loading={isLoading}>
          <AnnouncementCloseForm
            announcement_id={announcement_id}
            loading={loading}
            initialValues={initialValues}
            save={save}
            showCLick={showClick}
            linkPush={linkPush}
            form={form}
            isLoadingDoc={isLoadingDoc}
          />
          <PdfViewer
            pdf={pdfUrl}
            onCancel={() => setShowPdf(false)}
            visible={showPdf}
          />
        </SpinLoad>
        <AnnouncementTable
          announcement_id={announcement_id}
          announcementType={announcementType}
          announcementStatus={initialValues !== undefined ? initialValues.estado_lanzamiento : false}
        />
        {announcement_id && (
          <>
            <AnnouncementTemplateForm
              announcementType={announcementType}
              saveTemplate={saveTemplate}
              setExternalUsers={setExternalUsers}
              externalUsers={externalUsers}
              initialValues={initialValues}
              isLoading={loadingFile}
              showCLick={showClick}
              hasErrors={errors}
              formAnnouncement={form}
              loading={loading}
              fileWasUploaded={fileWasUploaded}
            />
            <Card size={'small'} bordered={false}>
              <Paragraph strong>
                2. Seleccionar usuarios específicos registrados en SIGA. A
                continuación, realice la búsqueda de las personas que desee
                invitar.
              </Paragraph>
              <AnnouncementCloseSearch
                loadingContractor={loadingAdd}
                setLoadingContractor={setLoadingAdd}
                announcement_id={announcement_id}
                getAddedContractor={getAddedContractor}
                addedContractor={addedContractor}
              />
            </Card>
          </>
        )}
        <Row gutter={12}>
          <Col span={12}>
            <Space>
              {announcement_id && (
                <CustomButton
                  text="Guardar convocatoria"
                  loading={loading}
                  onClick={() => form.submit()}
                />
              )}
              {announcement_id ? (
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={
                    <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`}>
                      Volver
                    </Link>
                  }
                />
              ) : (
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={
                    <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT}`}>Volver</Link>
                  }
                />
              )}
            </Space>
          </Col>
          <Col span={12}>
            <Space>
              {announcement_id && (
                <>
                  <CustomButton
                    type="default"
                    className={'ant-btn-info'}
                    htmlType="button"
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${announcement_id}?preview=true`}
                      >
                        Visualizar convocatoria
                      </Link>
                    }
                  />
                  {initialValues.estado_lanzamiento === false &&
                    initialValues.total > 0 && (
                      <Popconfirm
                        placement="topLeft"
                        title="¿Está seguro(a) que los datos registrados en la convocatoria están correctos?"
                        okText="Sí"
                        cancelText="No"
                        onConfirm={sendInvitations}
                      >
                        <CustomButton
                          text="Invitar"
                          htmlType="button"
                          loading={isSendInvitations}
                        />
                      </Popconfirm>
                    )}
                </>
              )}
            </Space>
          </Col>
        </Row>
      </>
    );
  },
);
