import React, { useState, useEffect } from 'react';
import {
  notification,
  Typography,
  Skeleton,
  Divider,
  Descriptions,
  Table,
  Radio,
  Input,
  Form,
  Alert,
  Space,
  List,
  Popconfirm,
  Modal,
  Steps,
  Card,
} from 'antd';
import { CustomButton } from '../../components';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import MESSAGES from '../../enums/Messages';
import { get, reject } from '../../api/module/term_resume_contract';
import {
  multiple as saveDocs,
  sign as signDocuments,
} from '../../api/module/term_resume_validation';
import { multiple as saveAuths } from '../../api/module/term_resume_authorize';
import moment from 'moment';
import 'moment/locale/es';
import CountryStateCity from '../../components/CountryStateCity';
import { getFilteredByTDR } from '../../api/module/authorize';
import { filter } from '../../api/module/term_legal_validation_document_supplementary';
import { postWord as post } from '../../api/module/template';
import PdfViewer from '../../components/PdfViewer';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import {
  SaveOutlined,
  SendOutlined,
  SolutionOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import SpinLoad from '../../components/SpinLoad';

const { Step } = Steps;
const breadcrumbItems = [
  {
    text: 'Términos de Referencia por Aprobar',
  },
];

const initModalState = {
  pdf: undefined,
  visible: false,
  key: 'no-key',
};

const { Title, Text, Paragraph } = Typography;

export default function TDRApprovedShow({ match, history }) {
  const [tdrData, setTdrData] = useState(undefined);
  const [initialValues, setInitialValues] = useState(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepStatus, setCurrentStepStatus] = useState('process');
  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('Información importante');
  const [alertDescription, setAlertDescription] = useState(
    'Su proceso de contratación no ha finalizado. En el menú de Documentos contractuales se le informará el estado final de su contratación.',
  );
  const [stepIcon1, setStepIcon1] = useState(<SaveOutlined />);
  const [stepIcon2, setStepIcon2] = useState(<SolutionOutlined />);
  const [stepIcon3, setStepIcon3] = useState(<SyncOutlined />);
  const [stepIcon4, setStepIcon4] = useState(<SendOutlined />);

  const [modalState, setModalState] = useState(initModalState);
  const [contractState, setContractState] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveSteps, setSaveSteps] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData(id) {
      try {
        const res = await get(id);
        if (
          !res.data.termino.fechas.fecha_inicio_contrato ||
          !res.data.termino.fechas.fecha_terminacion_contrato
        ) {
          await reject(res.data.id);
          setTdrData(res.data);
          return;
        }

        const resList = await filter(
          `/?filter[termino]=${res.data.termino.id}`,
          1,
          1000,
        );
        const resAuth = await getFilteredByTDR(res.data.termino.organizacion);

        //INI CARL20220930
        setTdrData(res.data);
        if (res.data.termino.implica_interventoria) {
          setInitialValues({
            doc_list: resList.data.results,
            auth_list: resAuth.data.results,
          });
        } else {
          setInitialValues({
            doc_list: resList.data.results,
            auth_list: resAuth.data.results.filter((value) => {
              return value.id != 8;
            }),
          });
        }
        //FIN CARL20220930

        if (res.data.estado_proceso > 0) {
          setSaveSteps(true);
          // setCurrentStep(res.data.estado_proceso - 1);

          if (res.data.estado_proceso === 4) {
            setAlertType('success');
            setAlertMessage('Felicitaciones');
            setAlertDescription(
              'El proceso de contratación ha finalizado, revise su correo electrónico',
            );
          }
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData(match.params.id);
  }, [match.params.id]);

  const columns = [
    {
      title: 'Tipo de documento',
      render: (_, record) => {
        return record.plantilla_codigo != null
          ? record.plantilla_codigo + ' - ' + record.plantilla_nombre
          : record.plantilla_nombre;
      },
    },
    {
      title: 'Ver',
      key: 'view',
      align: 'center',
      width: 126,
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Visualizar"
            htmlType="button"
            loading={loadingPDF === record.id}
            disabled={loadingPDF}
            onClick={async () => {
              setLoadingPDF(record.id);
              try {
                const pdfData = await post({
                  template: record.tipo_plantilla,
                  person: tdrData.tercero.id,
                  term: tdrData.termino.id,
                  person_type: tdrData.tercero.tipo_persona,
                });
                const fileURL = pdfData.data.url;
                setModalState({
                  visible: true,
                  pdf: fileURL,
                  key: `pdf-${record.id}`,
                });
              } catch (err) {
                console.log(err);
                notification.error({
                  message: MESSAGES.ERROR_MSG,
                  description: MESSAGES.DESC_ERROR_GET_MSG,
                  duration: 2,
                });
              }
              setLoadingPDF(undefined);
            }}
          />
        );
      },
    },
    {
      title: 'Aceptación del documento',
      key: 'acceptance',
      align: 'center',
      width: 144,
      render: (_, _record, index) => {
        return (
          <Form.Item
            noStyle
            name={[index, 'estado_aceptacion']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: 'Explique el motivo de su NO aceptación',
      key: 'motive',
      render: (_, _record, index) => {
        return <MotiveField index={index} />;
      },
    },
  ];

  async function onSubmit(values) {
    const documentos = values.doc_list;
    const autorizaciones = values.auth_list;

    // if (contractState) setSaveSteps(true);

    setSaveLoading(true);
    // setStepIcon1(<LoadingOutlined />);
    try {
      await saveDocs({
        documentos: documentos,
        contrato: tdrData.id,
      });
      if (autorizaciones)
        await saveAuths({
          autorizaciones: autorizaciones,
          contrato: tdrData.id,
        });

      if (contractState) {
        // setStepIcon1(<SaveOutlined />);
        try {
          await signDocuments(tdrData.id);
          setSaveSteps(true);
          setTimeout(() => {
            history.push(INTERNAL_LINKS.RESUME_CONTRACTS);
          }, 10000);
        } catch (exception) {
          console.log(exception);
          let description_error;

          if (exception.response.data.errors) {
            const errors = exception.response.data.errors;
            description_error = errors.shift().detail;
          } else {
            description_error = 'No se pudieron firmar los documentos.';
          }

          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: description_error,
            duration: 2,
          });
        }

        // tdrData.estado_proceso = 1;
        // await continueStep();
      } else {
        Modal.info({
          title: 'Guardado',
          content: 'Su respuesta ha sido guardada.',
          onOk: () => history.push(INTERNAL_LINKS.HOME_ANNOUNCEMENT),
          onCancel: () => history.push(INTERNAL_LINKS.HOME_ANNOUNCEMENT),
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setSaveLoading(false);
    }
  }

  // const continueStep = async () => {
  //   switch (tdrData.estado_proceso) {
  //     case 1:
  //       try {
  //         setSaveLoading(true);
  //         setCurrentStep(1);
  //         setStepIcon2(<LoadingOutlined />);

  //         const response = await signDocuments(tdrData.id);

  //         if ([200, 201, 204].indexOf(response.status) > -1) {
  //           tdrData.estado_proceso = 2;
  //           setStepIcon2(<SolutionOutlined />);
  //           await continueStep();
  //         }
  //         setSaveLoading(false);
  //       } catch (exception) {
  //         setSaveLoading(false);
  //         setCurrentStepStatus('error');
  //         setStepIcon2(<CloseCircleOutlined />);
  //         console.log(exception);
  //         let description_error;

  //         if (exception.response.data.errors) {
  //           const errors = exception.response.data.errors;
  //           description_error = errors.shift().detail;
  //         } else {
  //           description_error = 'No se pudieron firmar los documentos.';
  //         }

  //         notification.error({
  //           message: MESSAGES.ERROR_MSG,
  //           description: description_error,
  //           duration: 2,
  //         });
  //       }
  //       break;
  //     case 2:
  //       try {
  //         setSaveLoading(true);
  //         setCurrentStep(2);
  //         setStepIcon3(<LoadingOutlined />);

  //         const response = await updateDocuments(tdrData.id);

  //         if ([200, 201, 204].indexOf(response.status) > -1) {
  //           tdrData.estado_proceso = 3;
  //           setStepIcon3(<SyncOutlined />);
  //           await continueStep();
  //         }
  //         setSaveLoading(false);
  //       } catch (exception) {
  //         setSaveLoading(false);
  //         setCurrentStepStatus('error');
  //         setStepIcon3(<CloseCircleOutlined />);
  //         console.log(exception);
  //         let description_error;

  //         if (exception.response.data.errors) {
  //           const errors = exception.response.data.errors;
  //           description_error = errors.shift().detail;
  //         } else {
  //           description_error = 'No se pudo actualizar la información.';
  //         }

  //         notification.error({
  //           message: MESSAGES.ERROR_MSG,
  //           description: description_error,
  //           duration: 2,
  //         });
  //       }
  //       break;
  //     case 3:
  //       try {
  //         setSaveLoading(true);
  //         setCurrentStep(3);
  //         setStepIcon4(<LoadingOutlined />);

  //         const response = await notifyDocuments(tdrData.id);

  //         if ([200, 201, 204].indexOf(response.status) > -1) {
  //           tdrData.estado_proceso = 4;
  //           setStepIcon4(<SendOutlined />);
  //           setAlertType('success');
  //           setCurrentStepStatus('finish');
  //           setAlertMessage('Felicitaciones');
  //           setAlertDescription(
  //             'El proceso de contratación ha finalizado, revise su correo electrónico',
  //           );
  //         }
  //         setSaveLoading(false);
  //       } catch (exception) {
  //         setSaveLoading(false);
  //         setCurrentStepStatus('error');
  //         setStepIcon4(<CloseCircleOutlined />);
  //         console.log(exception);
  //         let description_error;

  //         if (exception.response.data.errors) {
  //           const errors = exception.response.data.errors;
  //           description_error = errors.shift().detail;
  //         } else {
  //           description_error = 'No se pudo enviar las notificaciones.';
  //         }

  //         notification.error({
  //           message: MESSAGES.ERROR_MSG,
  //           description: description_error,
  //           duration: 2,
  //         });
  //       }
  //       break;
  //   }
  // };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Términos de Referencia por Aprobar
      </Title>
      {tdrData ? (
        <Descriptions column={2} bordered layout="vertical">
          <Descriptions.Item label="Proyecto, licitación concurso u otro">
            {tdrData.termino.proyecto_nombre}
          </Descriptions.Item>
          <Descriptions.Item label="Cargo/Rol">
            {tdrData.termino.subcategoria_nombre
              ? `${tdrData.termino.cargo_nombre} - ${tdrData.subcategoria_nombre}`
              : tdrData.termino.cargo_nombre}
          </Descriptions.Item>
          <Descriptions.Item label="Objeto del contrato" span={2}>
            {tdrData.termino.objeto_contrato}
          </Descriptions.Item>
          <Descriptions.Item
            label="Requerimientos de educación / formación"
            span={2}
          >
            {tdrData.termino.requerimiento_formacion}
          </Descriptions.Item>
          <Descriptions.Item label="Requerimientos de experiencia" span={2}>
            {tdrData.termino.requerimiento_experiencia}
          </Descriptions.Item>
          <Descriptions.Item label="Lugar de ejecución" span={2}>
            <CountryStateCity
              disabled
              country={tdrData.termino.pais}
              state={tdrData.termino.estado}
              city={tdrData.termino.ciudad}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label="Fecha Inicio Contratación"
            style={{ textAlign: 'center' }}
          >
            {tdrData.termino.fechas.fecha_inicio_contrato
              ? `${moment(tdrData.termino.fechas.fecha_inicio_contrato).format(
                  'DD MMMM YYYY',
                )}`
              : 'Fecha expirada'}
          </Descriptions.Item>
          <Descriptions.Item
            label="Fecha de Terminación Contratación"
            style={{ textAlign: 'center' }}
          >
            {tdrData.termino.fechas.fecha_terminacion_contrato
              ? `${moment(
                  tdrData.termino.fechas.fecha_terminacion_contrato,
                ).format('DD MMMM YYYY')}`
              : 'Fecha expirada'}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Skeleton active loading={true} />
      )}
      <br />
      {tdrData ? (
        !tdrData.termino.fechas.fecha_inicio_contrato ||
        !tdrData.termino.fechas.fecha_terminacion_contrato ? (
          <>
            <Divider orientation="center">
              <Title level={3}>
                El período de contratación ya terminó. El contrato se ha
                rechazado automáticamente.
              </Title>
            </Divider>
            <br />
            <div style={{ textAlign: 'center' }}>
              <CustomButton
                htmlType="button"
                type="default"
                danger="true"
                text={<Link to={INTERNAL_LINKS.HOME_ANNOUNCEMENT}>Volver</Link>}
              />
            </div>
          </>
        ) : (
          initialValues &&
          tdrData.estado_proceso < 1 && (
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>Documentos por revisar</Title>}
            >
              <Form
                preserve={true}
                form={form}
                onValuesChange={(newV, V) => {
                  if (newV.hasOwnProperty('doc_list')) {
                    const old = V.doc_list;
                    let makeTrue = false;
                    newV.doc_list.forEach((element, index) => {
                      if (element.showField === false) {
                        old[index] = { estado_aceptacion: true, motivo: null };
                        makeTrue = true;
                      } else {
                      }
                    });
                    if (makeTrue) form.setFieldsValue({ doc_list: old });
                  }
                }}
                onFinish={onSubmit}
                initialValues={initialValues}
              >
                <Form.List name="doc_list">
                  {() => (
                    <SpinLoad loading={!initialValues}>
                      <Table
                        rowKey="id"
                        scroll={{ x: 400 }}
                        dataSource={initialValues.doc_list}
                        columns={columns}
                        pagination={false}
                        title={() => (
                          <Paragraph className={'margin-5'} strong>
                            Listado de documentos
                          </Paragraph>
                        )}
                      />
                    </SpinLoad>
                  )}
                </Form.List>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    for (let i = 0; i < prevValues.doc_list.length; i++) {
                      if (
                        curValues.doc_list[i].estado_aceptacion !==
                        prevValues.doc_list[i].estado_aceptacion
                      )
                        return true;
                    }
                    return false;
                  }}
                >
                  {({ getFieldValue }) => {
                    const docs = getFieldValue('doc_list');
                    if (
                      docs &&
                      docs.every(
                        (e) =>
                          e.estado_aceptacion && e.estado_aceptacion === true,
                      )
                    ) {
                      return (
                        <>
                          <Form.List name="auth_list">
                            {() => (
                              <AuthorizationList
                                dataSource={initialValues.auth_list}
                              />
                            )}
                          </Form.List>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => {
                              for (
                                let i = 0;
                                i < prevValues.auth_list.length;
                                i++
                              ) {
                                if (
                                  curValues.auth_list[i].estado_aceptacion !==
                                  prevValues.auth_list[i].estado_aceptacion
                                )
                                  return true;
                              }
                              return false;
                            }}
                          >
                            {({ getFieldValue }) => {
                              const auths = getFieldValue('auth_list');
                              if (
                                auths &&
                                auths.every(
                                  (e) =>
                                    e.estado_aceptacion &&
                                    e.estado_aceptacion === true,
                                )
                              )
                                return (
                                  <div style={{ textAlign: 'center' }}>
                                    <CustomButton
                                      type="primary"
                                      text="GUARDAR Y ACEPTAR CONTRATACIÓN"
                                      htmlType="submit"
                                      loading={saveLoading}
                                    />
                                  </div>
                                );
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <Popconfirm
                                    onConfirm={() => {
                                      setContractState(false);
                                      form.submit();
                                    }}
                                    title={
                                      <Text>
                                        ¿Está seguro(a) de rechazar este proceso
                                        de contratación? Si tiene dudas por
                                        favor <ContactLink text="contáctenos" />
                                      </Text>
                                    }
                                    okText="Rechazar"
                                    okButtonProps={{ type: 'danger' }}
                                    cancelText="Cancelar"
                                  >
                                    <CustomButton
                                      type="danger"
                                      text="GUARDAR Y RECHAZAR CONTRATACIÓN"
                                      htmlType="button"
                                      loading={saveLoading}
                                    />
                                  </Popconfirm>
                                </div>
                              );
                            }}
                          </Form.Item>
                        </>
                      );
                    }
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Popconfirm
                          onConfirm={() => {
                            setContractState(false);
                            form.submit();
                          }}
                          title="¿Está seguro(a) de rechazar esta contratación?"
                          okText="Si"
                          cancelText="No"
                        >
                          <CustomButton
                            type="danger"
                            text="GUARDAR Y RECHAZAR CONTRATACIÓN"
                            htmlType="button"
                            loading={saveLoading}
                          />
                        </Popconfirm>
                      </div>
                    );
                  }}
                </Form.Item>
              </Form>
            </Card>
          )
        )
      ) : (
        <Skeleton active loading={true} />
      )}
      {saveSteps && (
        <>
          <Alert
            message={alertMessage}
            description={alertDescription}
            type={alertType}
            showIcon
          />
        </>
      )}
      {/* {saveSteps && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Alert
            message={alertMessage}
            description={alertDescription}
            type={alertType}
            showIcon
          />
          <Steps
            current={currentStep}
            status={currentStepStatus}
            style={{ marginTop: 30 }}
          >
            <Step
              disabled
              title="Guardar"
              description="Guardar estados"
              icon={stepIcon1}
            />
            <Step
              disabled
              title="Firma"
              description="Firmar documentos contractuales"
              icon={stepIcon2}
            />
            <Step
              disabled
              title="Actualización"
              description="Actualizar información"
              icon={stepIcon3}
            />
            <Step
              disabled
              title="Notificar"
              description="Enviar notificaciones"
              icon={stepIcon4}
            />
          </Steps>

          {tdrData.estado_proceso > 0 && tdrData.estado_proceso !== 4 && (
            <CustomButton
              style={{ marginTop: 10 }}
              type="primary"
              text="Continuar el proceso de contratación"
              htmlType="button"
              loading={saveLoading}
              onClick={continueStep}

            />
          )}
        </Space>
      )} */}
      <PdfViewer
        pdf={modalState.pdf}
        visible={modalState.visible}
        onCancel={() => setModalState(initModalState)}
        isWord={true}
      />
    </>
  );
}

function MotiveField({ index }) {
  return (
    <Form.Item
      noStyle
      dependencies={[
        ['doc_list', index, 'estado_aceptacion'],
        ['doc_list', index, 'showField'],
      ]}
    >
      {({ getFieldValue }) => {
        if (getFieldValue(['doc_list', index, 'estado_aceptacion']) === false) {
          return (
            <>
              {getFieldValue(['doc_list', index, 'showField']) ||
              getFieldValue(['doc_list', index, 'motivo']) ? (
                <Form.Item
                  name={[index, 'motivo']}
                  rules={[
                    {
                      required: true,
                      message: 'Indique el motivo',
                    },
                  ]}
                >
                  <Input.TextArea rows={2} placeholder="Motivo" />
                </Form.Item>
              ) : (
                <Form.Item
                  name={[index, 'showField']}
                  rules={[
                    {
                      required: true,
                      message: 'Revise la alerta',
                    },
                  ]}
                >
                  <AlertInputField />
                </Form.Item>
              )}
            </>
          );
        }
        return undefined;
      }}
    </Form.Item>
  );
}

function AlertInputField({ onChange }) {
  return (
    <Alert
      message="Alerta"
      description={
        <Text>
          Al no aceptar la información puede implicar no continuar con el
          proceso de contratación. Si tiene dudas por favor{' '}
          <ContactLink text="contáctenos" />
          <br />
          <Space>
            <Text>¿Está seguro(a)?</Text>
            <CustomButton
              text="Sí"
              type="danger"
              htmlType="button"
              onClick={() => onChange(true)}
            />
            <CustomButton
              text="No"
              type="primary"
              htmlType="button"
              onClick={() => onChange(false)}
            />
          </Space>
        </Text>
      }
      type="info"
      showIcon
    />
  );
}

function ContactLink({ text }) {
  return (
    <a
      href="https://www.corpoeducacion.org.co/contacto/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
}

function AuthorizationList({ dataSource }) {
  function renderItem(item, index) {
    return (
      <List.Item>
        <List.Item.Meta
          title={item.nombre}
          description={
            <Space
              direction="vertical"
              style={{ width: '100%', color: 'black' }}
            >
              <div
                key={`authorize-${index}`}
                dangerouslySetInnerHTML={{ __html: item.descripcion }}
              />
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  rules={[
                    {
                      required: true,
                      message: 'Confirme o rechace la autorización',
                    },
                  ]}
                  name={[index, 'estado_aceptacion']}
                >
                  <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Form.Item
                noStyle
                dependencies={[['auth_list', index, 'estado_aceptacion']]}
              >
                {({ getFieldValue }) => {
                  if (
                    getFieldValue(['auth_list', index, 'estado_aceptacion']) ===
                    false
                  )
                    return (
                      <Alert
                        message="Alerta"
                        description={
                          <Text>
                            LEA ESPECIALMENTE BIEN ESTA PREGUNTA ANTES DE
                            CONTESTARLA. PUEDE IMPLICAR NO CONTINUAR CON EL
                            PROCESO DE CONTRATACIÓN. Si tiene dudas por favor{' '}
                            <ContactLink text="contáctenos" />
                          </Text>
                        }
                        type="info"
                        showIcon
                      />
                    );
                  return undefined;
                }}
              </Form.Item>
            </Space>
          }
        />
      </List.Item>
    );
  }

  return (
    <>
      <Alert
        message="Información importante"
        description="Por favor lea atentamente la siguiente información"
        type="info"
        showIcon
      />
      <List renderItem={renderItem} dataSource={dataSource} />
    </>
  );
}
