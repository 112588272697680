import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Typography, notification, Layout } from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { ajaxTable, getAll, remove } from '../../../api/module/clients';
import { PlusOutlined } from '@ant-design/icons';
import AjaxTable from '../../../components/AjaxTable';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Módulo Administración de Clientes - Aliados',
  },
];

const ClientsList = () => {
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Razón social',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Sigla-Nombre comercial',
      dataIndex: 'sigla',
      align: 'center',
      key: 'sigla',
    },
    {
      title: 'NIT',
      align: 'center',
      render: (_, record) =>
        record.nit == null ? record.num_identificacion : record.nit,
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'created_at',
      align: 'center',
      render: (_, record) => moment(record.created_at).format('YYYY-MM-DD'),
    },
    {
      title: 'Fecha Actualización',
      dataIndex: 'updated_at',
      align: 'center',
      render: (_, record) => moment(record.updated_at).format('YYYY-MM-DD'),
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => (
        <CustomButton
          block
          type="default"
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link to={`${INTERNAL_LINKS.CLIENT}/${record.id}`}>Editar</Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de administración de Clientes - Aliados
      </Title>
      <Paragraph>
        A continuación, podrá realizar acciones como editar y/o eliminar
        registros contenidos en la siguiente tabla. Si desea agregar un nuevo
        cliente - aliado, por favor, haga click en el botón "Crear nuevo cliente
        - aliado".
      </Paragraph>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <Link to={INTERNAL_LINKS.CLIENT}>
            <PlusOutlined /> Crear nuevo Cliente - Aliado
          </Link>
        }
      />
      <SpinLoad loading={loading}>
        <AjaxTable
          rowKey="id"
          columns={columns}
          scroll={{ x: 1300 }}
          tableTitle={'Listado de Clientes - Aliados'}
          endpoint={ajaxTable}
          searchInput
        />
      </SpinLoad>
    </Content>
  );
};

export default ClientsList;
