import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/`,
  );
};

const getListByRole = async (position_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/?filter[cargo]=${position_id}`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/${id}/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/${id}`,
  );
};

const notify = async (id) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/project_positions/${id}/notify/`,
  );
};

const getListByJobTerm = async (term_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_position_assignments/job_list/?filter[termino]=${term_id}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = '',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  if (sortBy) sortBy = '&sort=' + sortBy;

  if (exportCSV)
    return await httpClient.get(
      `/project_position_assignments/export${query}${sortBy}${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/project_position_assignments${query}&page[number]=${page}&page[size]=${pageSize}${sortBy}${searchBy}`,
    );
};

const postFile = async (data) => {
  return await httpClient.post(
    `/project_position_assignments/upload_file/`,
    data,
  );
};

const deleteFile = async (id) => {
  return await httpClient.delete(
    `/project_position_assignments/${id}/delete_file/`,
  );
};

const assignFile = async (id) => {
  return await httpClient.patch(
    `/project_position_assignments/${id}/assign_file/`,
  );
};

const filesTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = '',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  if (sortBy) sortBy = '&sort=' + sortBy;

  return await httpClient.get(
    `/project_position_assignments/list_files/?filter[cargo]=${query}&page[number]=${page}&page[size]=${pageSize}${sortBy}${searchBy}`,
  );
};

const downloadFile = async (id) => {
  return await httpClient.get(
    `/project_position_assignments/${id}/download_errors/`,
    {
      responseType: 'blob',
    },
  );
};

export {
  post,
  getAll,
  getListByRole,
  getListByJobTerm,
  get,
  put,
  remove,
  notify,
  ajaxTable,
  postFile,
  deleteFile,
  assignFile,
  downloadFile,
  filesTable,
};
