import httpClient from '../httpClient';

const getUserInformation = async() => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/custom/user-details`);
}

const getAllUsers = async() => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/users/?page[size]=1000`);
}

const post = async(data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/users/`,data);
}

const getUsersByGroup = async (id) => {
  return await httpClient.post(`/groups/by_rol/ `, { rol: id });
};

export {
  getUserInformation,
  getAllUsers,
  post,
  getUsersByGroup,
};
