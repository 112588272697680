import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_director_validation_document/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_director_validation_document/${id}/`);
};

const filter = async (query, page, pageSize) => {
  return await httpClient.get(
    `/term_director_validation_document${query}&page[number]=${page}&page[size]=${pageSize}`,
  );
};

const getCounters = async (query) => {
  return await httpClient.get(
    `term_director_validation_document/counters${query}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `/term_director_validation_document/${id}/`,
    data,
  );
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `/term_director_validation_document/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(`/term_director_validation_document/${id}`);
};

const signSelected = async (data) => {
  return await httpClient.post(
    `/term_director_validation_document/selected/`,
    data,
  );
};

const signMassive = async (data) => {
  return await httpClient.post(
    `/term_director_validation_document/massive/`,
    data,
  );
};

const reject = async (query) => {
  return await httpClient.get(
    `/term_director_validation_document/rejected${query}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false
) => {
  let searchBy = '';

  if (search)
    searchBy = '&filter[search]=' + search

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_director_validation_document/export` +
      `${query}&sort=${sortBy}` +
      `${searchBy}`,
      {
        responseType: 'blob',
      }
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_director_validation_document` +
      `${query}&page[number]=${page}&page[size]=${pageSize}` +
      `&sort=${sortBy}` +
      `${searchBy}`,
    );
};

export {
  post,
  get,
  filter,
  getCounters,
  put,
  patch,
  remove,
  signSelected,
  signMassive,
  reject,
  ajaxTable,
};
