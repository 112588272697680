import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/cost_centers/`, data);
};

const postFile = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/cost_centers/upload/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/cost_centers/?page[size]=1000`);
};

const getFiltered = async (client_type) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/cost_centers/?filter[tipo]=${client_type}`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/cost_centers/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/cost_centers/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/cost_centers/${id}`);
};

const query = async (query) => {
  return await httpClient.get(`/cost_centers/${query}`);
};

export {
  post,
  postFile,
  getAll,
  getFiltered,
  get,
  put,
  remove,
  query,
}
