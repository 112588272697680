import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/template_process/`);
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/template_process/${id}`,
  );
};

const templateProcess = async (dependant = true, first_row = false, hiring_type = 0,
                               hiring_stage = 0, person_type = 0, contract = 0,
                               template_type = 0) => {
  let filter = '?'

  if (!dependant)
    filter += 'filter[tipo_contrato__isnull]=true'
  else
    filter += 'filter[tipo_contrato__isnull]=false'

  if (hiring_type !== 0)
    filter += '&filter[tipo_contratacion]=' + hiring_type

  if (hiring_stage !== 0)
    filter += '&filter[etapa_contratacion]=' + hiring_stage

  if (person_type === 1)
    filter += '&filter[persona_natural]=true'

  if (person_type === 2)
    filter += '&filter[persona_juridica]=true'

  if (contract !== 0)
    filter += '&filter[tipo_contrato]=' + contract

  if (template_type !== 0)
    filter += '&filter[tipo_plantilla]=' + template_type

  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/template_process/${filter}`);
};

export {
  getAll,
  get,
  templateProcess,
};
