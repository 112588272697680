import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Descriptions, notification, Typography } from 'antd';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AjaxTable from '../../../components/AjaxTable';
import { filtersearch } from '../../../api/module/term_resume_contract';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import Paragraph from 'antd/lib/typography/Paragraph';
import { get } from '../../../api/module/contractor';
import MESSAGES from '../../../enums/Messages';
import SpinLoad from '../../../components/SpinLoad';
import CustomButton from '../../../components/CustomButton'; //CARL20220928

const { Title } = Typography;

const ResumeContractSearchPersonDetail = ({ match, history }) => {
  const breadcrumbItems = [
    {
      text: 'Búsqueda de Documentos Contractuales',
      to: `${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}`,
    },
    {
      text: 'Contratos por persona',
    },
  ];

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: ['termino', 'proyecto_nombre'],
      key: ['termino', 'proyecto_nombre'],
    },
    {
      title: 'No. Contrato',
      dataIndex: 'numero_contrato',
      key: 'numero_contrato',
    },
    {
      title: 'Cargo',
      dataIndex: ['termino', 'cargo_nombre'],
      key: ['termino', 'cargo_nombre'],
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) =>
        record.estado_validacion === 54 ? (
          <Paragraph>Contrato no aceptado</Paragraph>
        ) : record.estado_proceso === 4 ? (
          <Button type="primary">
            <Link
              to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${record.id}/${record.termino.proyecto}/${record.termino.cargo}/${record.tercero.id}`}
            >
              Ver
            </Link>
          </Button>
        ) : (
          <>
            <Paragraph>El proceso de contratación esta en curso</Paragraph>
            <Paragraph strong>
              Recargue el listado mínimo pasados 5 minutos
            </Paragraph>
          </>
        ),
    },
  ];

  const [terceroData, setTerceroData] = useState();

  const getTerceroData = async () => {
    try {
      const res = await get(match.params.id);
      setTerceroData(res.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => getTerceroData(), []);

  //INI CARL20220928
  const back = () => {
    history.push(`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}/1`);
  };
  //FIN CARL20220928

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />

      <div className="box-title">
        <Title>DOCUMENTOS CONTRACTUALES</Title>
      </div>
      <p>
        A continuación se muestra la lista de los contratos en los que ha
        participado.
      </p>

      <SpinLoad loading={!terceroData}>
        <Descriptions bordered={true} column={2}>
          <Descriptions.Item label="Nombre completo / Razón social" span={2}>
            {terceroData && terceroData.nombre_completo}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo de documento">
            {terceroData && terceroData.tipo_identificacion_nombre}
          </Descriptions.Item>
          <Descriptions.Item label="Número de documento">
            {terceroData && terceroData.numero_identificacion}
          </Descriptions.Item>
        </Descriptions>
      </SpinLoad>

      <AjaxTable
        endpointFetch={async (page, pageSize) => {
          const thisQuery = `filter[tercero]=${match.params.id}&filter[estado_proceso__gt]=0`;
          return await filtersearch(`?${thisQuery}`, page, pageSize);
        }}
        columns={columns}
        reloadButton
      ></AjaxTable>
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </>
  );
};

export default ResumeContractSearchPersonDetail;
