import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Input,
  Row,
  Col,
  Radio,
  notification,
  Space,
  Descriptions,
  Skeleton,
  Layout,
} from 'antd';
import { useQuery } from '../../../utils/helpers/helpers';

import CustomButton from '../../../components/CustomButton';
import CustomUpload from '../../../components/CustomUpload';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { get } from '../../../api/module/announcement_selection';
import {
  post,
  postDocument,
} from '../../../api/module/announcement_selection_email';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const AnnouncementEmail = ({ history, match }) => {
  const [loading, setLoading] = useState();
  const [documento, setDocumento] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [formData] = useState(new FormData());
  let query = useQuery();

  const breadcrumbItems = [
    {
      text: 'Seleccionados',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Generar Email',
    },
  ];

  const email = query.get('email');
  const status =
    email === 'preselected'
      ? 23
      : email === 'adjust-information'
      ? 21
      : email === 'rejected'
      ? 22
      : 0;

  const onChange = (e) => {
    setDocumento(e.target.value);
  };

  const onFinish = async (data) => {
    try {
      let response;
      data.convocatoria = match.params.announcement_id;
      data.convocatoria_rol = parseInt(match.params.announcement_rol_id);
      data.tercero = parseInt(match.params.person_id);
      data.estado_tercero = status;
      if (documento) {
        data.documento_carta_compromiso =
          data.documento_carta_compromiso.fileList[0].originFileObj;
        formData.append('documento_carta_compromiso', data);
        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }
        response = await postDocument(formData);
      } else {
        response = await post(data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSelection();
  }, []);

  const getSelection = async () => {
    const response = await get(match.params.selection_id);
    setInitialValues(response.data);
    setLoadingForm(false);
  };

  const state = () => {
    if (email === 'preselected') {
      return 'Preseleccionado';
    }
    if (email === 'adjust-information') {
      return 'Ajustar información';
    }
    if (email === 'rejected') {
      return 'Rechazado';
    }
  };

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Skeleton loading={loadingForm} active>
        {initialValues != null && (
          <Space
            direction={'vertical'}
            style={{ width: '100%' }}
            size={'middle'}
          >
            <Title className={'text-uppercase'} level={2}>
              Módulo de Selección
            </Title>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Estado">{state()}</Descriptions.Item>
              <Descriptions.Item label="Nombres">
                {initialValues.tercero_data.nombre_completo}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Observaciones" column={1} bordered>
              {initialValues.tercero_data.tipo_persona === 1 && (
                <>
                  <Descriptions.Item label="Observación general Educación">
                    {initialValues.observacion_educacion_seleccion}
                  </Descriptions.Item>
                  <Descriptions.Item label="Observación general Experiencia">
                    {initialValues.observacion_laboral_seleccion}
                  </Descriptions.Item>
                </>
              )}
              {initialValues.tercero_data.tipo_persona === 2 && (
                <Descriptions.Item label="Observación general Documentos">
                  {initialValues.observacion_documentos_seleccion}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Form
              onFinish={onFinish}
              initialValues={initialValues}
              layout="vertical"
            >
              <Form.Item
                name="mensaje"
                label={<Text strong>Mensaje del correo</Text>}
              >
                <TextArea rows={4} />
              </Form.Item>
              {state() === 'Preseleccionado' && (
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="carta_compromiso"
                      label={<Text strong>¿Enviar carta de compromiso?</Text>}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChange}>
                        <Radio value={true}>Sí</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {documento && (
                      <CustomUpload
                        name="documento_carta_compromiso"
                        file_type={1}
                        upload_type={2}
                      />
                    )}
                  </Col>
                </Row>
              )}
              <Space>
                <CustomButton loading={loading} text="Enviar correo" />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Space>
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementEmail;
