import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  Form,
  notification,
  Input,
  Row,
  Col,
  DatePicker,
  Skeleton,
  Descriptions,
  Card,
} from 'antd';

import CustomButton from '../../../../components/CustomButton';
import MESSAGES from '../../../../enums/Messages';
import SpinLoad from '../../../../components/SpinLoad';
import CustomUpload from '../../../../components/CustomUpload';
import PdfViewer from '../../../../components/PdfViewer';
import HistoryList from '../HistoryList';

import { get as personId } from '../../../../api/module/contractor';
import { postDocument } from '../../../../api/module/restrictive_list';
import { getByPeople } from '../../../../api/module/restrictive_list';
import BreadcrumbContainer from '../../../../containers/Breadcrumb';

const { Title } = Typography;
const { TextArea } = Input;

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result);
}

const RestrictiveLists = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [dataSource, setDataSource] = useState(null);
  const [historyList, setHistoryList] = useState(null);
  const [formData] = useState(new FormData());
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [fileView, setFileView] = useState(null);

  const handleFile = (file) => {
    if (file) {
      getBase64(file.fileList[0].originFileObj, setFileView);
    }
  };

  const showCLick = (url) => {
    setPdfUrl(fileView);
    setShowPdf(!showPdf);
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      let response;
      data.fecha_consulta_lista = moment(data.fecha_consulta_lista).format(
        'YYYY-MM-DD',
      );
      data.tercero = match.params.person_id;
      data.archivo_lista = data.archivo_lista.fileList[0].originFileObj;
      data.descripcion_lista = data.descripcion_lista || '';

      formData.append('archivo_lista', data);
      for (let prop in data) {
        if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
      }
      response = await postDocument(formData);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(async () => {
    await Promise.all([getDataSource(), getHistoryLists()]);
  }, []);

  const getDataSource = async () => {
    try {
      const response = await personId(match.params.person_id);
      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getHistoryLists = async () => {
    try {
      setLoadingList(true);
      const response = await getByPeople(match.params.person_id);
      setHistoryList(response.data.results);
      setLoadingList(false);
    } catch (err) {
      console.log(err);
    }
  };

  const breadcrumbItems = [
    {
      text: 'Módulo de listas restrictivas',
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de listas restrictivas
      </Title>
      <Skeleton loading={loadingForm} active paragraph={{ rows: 1 }}>
        {dataSource != null && (
          <Descriptions title="Información de contratista" bordered>
            <Descriptions.Item label="Nombre completo/Razón social">
              {dataSource.nombre_completo}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {dataSource.tipo_identificacion_nombre}
              {' - '}
              {dataSource.numero_identificacion}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Skeleton>
      <SpinLoad loading={loadingList}>
        <HistoryList dataSource={historyList} />
      </SpinLoad>
      <Form layout="vertical" onFinish={onFinish}>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Agregar consulta</Title>}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Cargar el documento resultado de las consultas en formato PDF">
                <CustomUpload
                  name="archivo_lista"
                  file_type={1}
                  upload_type={5}
                  onChange={(e) => handleFile(e)}
                />
              </Form.Item>
            </Col>
            {fileView !== null && (
              <Col span={4}>
                <Form.Item label=" ">
                  <CustomButton
                    text="Ver"
                    style={{ marginTop: 5 }}
                    htmlType="button"
                    type="default"
                    onClick={() => {
                      showCLick();
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="fecha_consulta_lista"
                label="Fecha de la consulta"
              >
                <DatePicker placeholder="Fecha de la consulta" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name="descripcion_lista"
                label="Descripción del archivo cargado"
              >
                <TextArea placeholder="Descripción del archivo cargado" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <CustomButton loading={loading} text="Guardar" />
            </Col>
          </Row>
        </Card>
      </Form>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
};

export default RestrictiveLists;
