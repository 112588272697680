import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {Table, Popconfirm, Typography, notification, Space, Layout} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { getAll, remove } from '../../../api/module/organizations_types';

import './style.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Tipos de Organizaciones',
  },
];

const OrganizationsTypesList = ({ history }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // GetData List
  const getDataSource = () => {
    try {
      setLoading(true);
      getAll().then((res) => {
        setDataSource(res.data.results);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getDataSource();
  }, []);

  // Delete Object by Id of List
  const deleteDataSource = useCallback(
    async (id) => {
      try {
        setLoadingDelete(true);
        const response = await remove(id);
        setLoadingDelete(false);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DELETE_CONFIRM_MSG,
            duration: 2,
          });
          setDataSource(dataSource.filter((item) => item.id !== id));
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [dataSource],
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Sigla',
      dataIndex: 'sigla',
      key: 'sigla',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <CustomButton
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            text={
              <Link to={`${INTERNAL_LINKS.ORGANIZATION_TYPE}/${record.id}`}>
                Editar
              </Link>
            }
          />
          <CustomButton
            danger
            type={'default'}
            htmlType="button"
            loading={loadingDelete}
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG}
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Tipos de organizaciones
      </Title>
      <Paragraph>
        A continuación, podrá realizar acciones como editar y/o eliminar
        registros contenidos en la siguiente tabla. Si desea agregar un nuevo
        tipo de organización, por favor, haga click en el botón
        "Crear nuevo tipo de organización".
      </Paragraph>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <Link to={INTERNAL_LINKS.ORGANIZATION_TYPE}>
            <PlusOutlined /> Crear nuevo tipo de organización
          </Link>
        }
      />
      <SpinLoad loading={loading}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 400 }}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Tipos de organizaciones
            </Paragraph>
          )}
        />
      </SpinLoad>
    </Content>
  );
};

export default OrganizationsTypesList;
