import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { notification, Skeleton } from 'antd';

import { useQuery, IsRole } from '../../../utils/helpers/helpers';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import PdfViewer from '../../../components/PdfViewer';

import { post, get, put } from '../../../api/module/resume_experience';
import { getAll as getExperience } from '../../../api/module/experience_areas';
import { getAll as getJobType } from '../../../api/module/job_type';
import LaborContractorsForm from '../../../containers/LaborContractorForm/index';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Por favor seleccione el tiempo!',
    },
  ],
};
const SortName = (data) => {
  return data.sort(function (a, b) {
    var nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
    var nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
};

const DateFormat = (date) => {
  if (date) {
    return new Date(date).toISOString().split('T')[0];
  }
};

export default function LaborContractors({ history, match }) {
  const [terceroID] = useState(parseInt(match.params.tercero_id));

  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const ids = {
    person_id: terceroID,
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };

  const [coordinator] = useState(IsRole(2));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [checkValue, setCheckValue] = useState(0);
  const [experienceAreas, setExperienceAreas] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [dateAdmission, setDateAdmission] = useState(null);
  const [todayDate] = useState(DateFormat(new Date()));
  const [initialValues, setInitialValues] = useState(null);
  const workExperienceRef = useRef(null);
  const [document, setDocument] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showClick = async (documento_nombre) => {
    setIsLoadingDoc(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    setPdfUrl(response.data.url);
    setShowPdf(true);
    setIsLoadingDoc(false);
  };

  const onFinish = async (data) => {
    try {
      if (data.certificacion != undefined) {
        if (data.certificacion.fileList.length > 0) {
          if (data.certificacion.fileList[0].type == 'application/pdf') {
            data.certificacion = data.certificacion.fileList[0].originFileObj;
            save(data);
          } else {
            notification.error({
              message: MESSAGES.ERROR_FILE_PDF,
              description: MESSAGES.DESC_ERROR_FILE_PDF,
              duration: 3,
            });
            setLoading(false);
          }
        } else {
          save(data);
        }
      } else {
        save(data);
      }
    } catch (err) {}
  };

  const save = async (data) => {
    try {
      let response;
      const formData = new FormData();
      if (checkValue == 1) {
        if (data.fecha_ingreso != null) {
          data.fecha_ingreso = moment(data.fecha_ingreso).format('YYYY-MM-DD');
        }
        if (data.fecha_terminacion != null) {
          data.fecha_terminacion = moment(data.fecha_terminacion).format(
            'YYYY-MM-DD',
          );
        }
        setLoading(true);

        if (match.params.id) {
          data = {
            ...data,
            tercero: terceroID,
            finalizado: true,
          };
          formData.append('certificacion', data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }

          response = await put(match.params.id, formData);
        } else {
          data = {
            ...data,
            tercero: terceroID,
            finalizado: true,
          };
          formData.append('certificacion', data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }

          response = await post(formData);
        }
      } else if (checkValue == 2) {
        if (data.fecha_ingreso != null) {
          data.fecha_ingreso = moment(data.fecha_ingreso).format('YYYY-MM-DD');
        }
        setLoading(true);
        if (match.params.id) {
          data = {
            ...data,
            tercero: terceroID,
            finalizado: false,
          };
          formData.append('certificacion', data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }

          response = await put(match.params.id, formData);
        } else {
          data = {
            ...data,
            tercero: terceroID,
            finalizado: false,
          };
          formData.append('certificacion', data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }

          response = await post(formData);
        }
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${terceroID}`, {
            workExperienceRef: workExperienceRef,
          });
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += ` ${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });

      setLoading(false);
    }
  };

  const handleGetExperienceAreas = () => {
    try {
      getExperience().then((res) =>
        setExperienceAreas(SortName(res.data.results)),
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetJobType = () => {
    try {
      getJobType().then((res) => setJobType(SortName(res.data.results)));
    } catch (err) {
      console.log(err);
    }
  };

  const back = () => {
    history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${terceroID}`, {
      workExperienceRef: workExperienceRef,
    });
  };

  useEffect(async () => {
    load();
  }, []);

  const load = async () => {
    if (match.params.id) {
      handleGetLabor();
    }
    await Promise.all([handleGetExperienceAreas(), handleGetJobType()]);
  };

  const handleGetLabor = async () => {
    try {
      setIsLoading(true);
      get(match.params.id).then((res) => {
        if (res.data.finalizado) {
          setCheckValue(1);
        } else {
          setCheckValue(2);
        }
        if (res.data.fecha_ingreso) {
          res.data.fecha_ingreso = moment(res.data.fecha_ingreso);
        }
        if (res.data.fecha_terminacion) {
          res.data.fecha_terminacion = moment(res.data.fecha_terminacion);
        }
        if (res.data.documento_nombre) {
          setDocument(false);
        }
        setInitialValues(res.data);
        setIsLoading(false);
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Skeleton loading={isLoading}>
        {match.params.id ? (
          <>
            {initialValues != null && checkValue !== 0 && (
              <LaborContractorsForm
                ids={ids}
                onFinish={onFinish}
                setCheckValue={setCheckValue}
                checkValue={checkValue}
                config={config}
                todayDate={todayDate}
                setDateAdmission={setDateAdmission}
                dateAdmission={dateAdmission}
                experienceAreas={experienceAreas}
                jobType={jobType}
                loading={loading}
                DateFormat={DateFormat}
                back={back}
                initialValues={initialValues}
                showClick={showClick}
                document={document}
                isLoadingDoc={isLoadingDoc}
              />
            )}
          </>
        ) : (
          <>
            <LaborContractorsForm
              ids={ids}
              onFinish={onFinish}
              setCheckValue={setCheckValue}
              checkValue={checkValue}
              config={config}
              todayDate={todayDate}
              setDateAdmission={setDateAdmission}
              dateAdmission={dateAdmission}
              experienceAreas={experienceAreas}
              jobType={jobType}
              loading={loading}
              DateFormat={DateFormat}
              back={back}
              initialValues={initialValues}
              showClick={showClick}
              document={document}
              isLoadingDoc={isLoadingDoc}
            />
          </>
        )}
      </Skeleton>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
}
