import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Popconfirm,
  Typography,
  notification,
  Row,
  Form,
  Space,
  Col,
  Modal,
  Radio,
  Alert,
  Input,
} from 'antd';
import AjaxTable from '../../../components/AjaxTable';
import {
  filterUsers,
  activateUser,
  changeEmail,
  inactivateUser,
} from '../../../api/module/admin_actions';
import { CustomButton } from '../../../components';
import MESSAGES from '../../../enums/Messages';

const { Title } = Typography;

const ChangeEmailForm = ({ record, onFinish }) => {
  const [saving, setSaving] = useState(false);

  async function onSelfFinish(values) {
    try {
      setSaving(true);
      await changeEmail(record.id, values);
      onFinish(true);
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description:
          error?.response?.data?.errors[0]?.detail ||
          MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setSaving(false);
    }
  }

  return (
    <Form layout="vertical" onFinish={onSelfFinish}>
      <Form.Item
        name="new_email"
        label="Correo electrónico nuevo"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input allowClear placeholder="Correo electrónico nuevo" />
      </Form.Item>
      <Form.Item
        name="correoConfirm"
        label="Confirme el correo electrónico nuevo"
        rules={[
          {
            required: true,
            type: 'email',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_email') === value) {
                return Promise.resolve();
              }
              return Promise.reject('El correo electrónico nuevo no coincide!');
            },
          }),
        ]}
      >
        <Input
          allowClear
          onPaste={(e) => e.preventDefault()}
          placeholder="Confirme el correo electrónico nuevo"
        />
      </Form.Item>
      <Row gutter={12}>
        <Col span={12}>
          <Space>
            <CustomButton text="Guardar" loading={saving} />
            <CustomButton
              htmlType="button"
              onClick={() => onFinish()}
              type="default"
              danger
              text="Cerrar"
              disabled={saving}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const InactivateForm = ({ record, onFinish }) => {
  const [saving, setSaving] = useState(false);

  async function onSelfFinish(values) {
    try {
      setSaving(true);
      await inactivateUser(record.id, values);
      onFinish(true);
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setSaving(false);
    }
  }

  return (
    <Form layout="vertical" onFinish={onSelfFinish}>
      {record.rol !== 'Consultor' && (
        <>
          <Alert
            type="info"
            showIcon
            message={`Este usuario tiene asignado el rol ${record?.rol?.toUpperCase()}`}
          />
          <br />
        </>
      )}
      <Form.Item
        name="lista_rosada"
        label="¿Es lista rosada?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value={true}>Si</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="justificacion_inactivacion"
        label="Justifique la inactivación del usuario"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea allowClear />
      </Form.Item>
      <Row gutter={12}>
        <Col span={12}>
          <Space>
            <CustomButton text="Guardar" loading={saving} />
            <CustomButton
              htmlType="button"
              onClick={() => onFinish()}
              type="default"
              danger
              text="Cerrar"
              disabled={saving}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const UserManagement = () => {
  const [form] = Form.useForm();
  const [query, setQuery] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [refreshTable, setRefresh] = useState(false);

  const [emailRecord, setEmailRecord] = useState(undefined);
  const [inactivateRecord, setInactivateRecord] = useState(undefined);

  function onFinish(values) {
    setQuery(values);
  }

  function onClear() {
    setQuery();
    form.resetFields();
  }

  async function handleActivate(id) {
    try {
      setLoading(true);
      await activateUser(id);
      setRefresh({});
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  }

  function handleClose(refresh = false) {
    if (refresh) setRefresh({});
    setEmailRecord(undefined);
    setInactivateRecord(undefined);
    setLoading(false);
  }

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
    },
    {
      title: 'Nombre Completo',
      dataIndex: 'fullname',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
    },
    {
      title: 'Fecha registro',
      dataIndex: 'register_date',
    },
    {
      title: 'Activo',
      dataIndex: 'is_active',
      render: (t) => (t ? 'Si' : 'No'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      align: 'center',
      fixed: 'right',
      width: 125,
      render: (_, record) => (
        <Space>
          <CustomButton
            type="default"
            text="Editar Correo"
            onClick={() => {
              setEmailRecord(record);
              setLoading(true);
            }}
            disabled={loading}
          />
          {record.is_active ? (
            record.rol === 'Consultor' ? (
              <CustomButton
                type="danger"
                text="Inactivar usuario"
                disabled={loading}
                onClick={() => setInactivateRecord(record)}
              />
            ) : (
              <Popconfirm
                onConfirm={() => setInactivateRecord(record)}
                okText="Si"
                cancelText="No"
                title={`Este usuario tiene asignado el rol ${record?.rol?.toUpperCase()}, ¿está seguro de inactivarlo?`}
              >
                <CustomButton
                  type="danger"
                  text="Inactivar usuario"
                  disabled={loading}
                />
              </Popconfirm>
            )
          ) : (
            <Popconfirm
              onConfirm={() => {
                handleActivate(record.id);
              }}
              title={
                <>
                  ¿Está seguro de activar este usuario?
                  <br />
                  Lista rosada: {record.lista_rosada ? 'Si' : 'No'}
                  <br />
                  Justificación: {record.justificacion_inactivacion}
                </>
              }
              okText="Activar"
              cancelText="Cancelar"
            >
              <CustomButton
                type="info"
                text="Activar usuario"
                disabled={loading}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title level={5}>Búsqueda por educación</Title>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label="Nombre" name="filter[fullname]">
              <Input allowClear placeholder="Búsqueda por nombre" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Número de documento"
              name="filter[identificacion]"
            >
              <Input
                type="number"
                allowClear
                placeholder="Búsqueda por documento"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Correo electrónico" name="filter[email]">
              <Input allowClear placeholder="Correo electrónico" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Space>
              <CustomButton text="Buscar" />
              <CustomButton
                htmlType="button"
                onClick={onClear}
                type="default"
                danger
                text="Borrar filtros"
              />
            </Space>
          </Col>
        </Row>
      </Form>
      {query && (
        <AjaxTable
          columns={columns}
          endpoint={filterUsers}
          query={query}
          tableTitle="Listado de Usuarios"
          reloadSource={refreshTable}
        />
      )}
      <Modal
        visible={emailRecord?.id}
        title={<Title level={5}>{emailRecord?.fullname}</Title>}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <ChangeEmailForm record={emailRecord} onFinish={handleClose} />
      </Modal>
      <Modal
        visible={inactivateRecord?.id}
        title={<Title level={5}>{inactivateRecord?.fullname}</Title>}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <InactivateForm record={inactivateRecord} onFinish={handleClose} />
      </Modal>
    </>
  );
};

export default UserManagement;
