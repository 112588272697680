import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Popconfirm,
  Typography,
  notification,
  Layout,
  Space,
} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { getAll, remove } from '../../../api/module/organizations';
import { getAll as getAllOrganizationsPartners } from '../../../api/module/organization_partner';

import './style.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Organizaciones ejecutoras',
  },
];

const OrganizationsList = ({ location }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [organizationsPartners, setOrganizationsPartners] = useState([]);
  const columns = [
    {
      title: 'Tipo de Organización',
      dataIndex: 'tipo_organizacion_nombre',
      key: 'tipo_organizacion_nombre',
    },
    {
      title: 'Razón social',
      dataIndex: 'razon_social',
      key: 'razon_social',
    },
    {
      title: 'NIT',
      dataIndex: 'num_identificacion',
      key: 'num_identificacion',
    },
    {
      title: 'DV',
      dataIndex: 'digito_verificacion',
      key: 'digito_verificacion',
      align: 'center',
    },
    {
      title: 'Sigla o Nombre comercial',
      dataIndex: 'sigla',
      key: 'sigla',
      render: (_, record) => {
        return record.sigla !== 'undefined' ? record.sigla : '';
      },
    },
    {
      title: 'País',
      dataIndex: 'pais_nombre',
      key: 'pais_nombre',
    },
    {
      title: 'Departamento',
      dataIndex: 'estado_nombre',
      key: 'estado_nombre',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad_nombre',
      key: 'ciudad_nombre',
    },
    {
      title: 'Representante legal',
      dataIndex: 'representante_legal',
      key: 'representante_legal',
    },
    {
      title: 'Tipo de identificación representante legal',
      dataIndex: 'tipo_identidad_nombre',
      key: 'tipo_identidad_nombre',
    },
    {
      title: 'Número identificación representante legal',
      dataIndex: 'representante_numero_identificacion',
      key: 'representante_numero_identificacion',
      responsive: ['xs'],
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Organización activa',
      dataIndex: 'esta_activa',
      align: 'center',
      render: (_, record) =>
        dataSource.length >= 1
          ? record.esta_activa === true
            ? 'Si'
            : 'No'
          : null,
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 200,
      responsive: ['sm', 'mg', 'lg'],
      render: (_, record) => (
        <Space>
          <CustomButton
            type="default"
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link to={`${INTERNAL_LINKS.ORGANIZATION}/${record.id}`}>
                Editar
              </Link>
            }
          />
          <CustomButton
            danger
            type={'default'}
            htmlType={'button'}
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG}
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      responsive: ['xs'],
      render: (_, record) => (
        <Space>
          <CustomButton
            type="default"
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link to={`${INTERNAL_LINKS.ORGANIZATION}/${record.id}`}>
                Editar
              </Link>
            }
          />
          <CustomButton
            danger
            type={'default'}
            htmlType={'button'}
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG}
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    load();
  }, []);

  const getDataSource = async () => {
    setLoading(true);
    try {
      let response = await getAll();
      setDataSource(response.data.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const getOrganizationPartners = async () => {
    try {
      let response = await getAllOrganizationsPartners();
      setOrganizationsPartners(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const load = async () => {
    try {
      Promise.all([getDataSource(), getOrganizationPartners()]);
    } catch (err) {
      console.log(err);
    }
  };

  // Delete Object by Id of List
  const deleteDataSource = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const response = await remove(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
        }
        setDataSource(dataSource.filter((item) => item.id !== id));
        setLoading(false);
      } catch (err) {
        console.log(err);
        try {
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: err.response.data.error,
            duration: 2,
          });
        } catch {
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_DELETE_MSG,
            duration: 2,
          });
        }
      }
    },
    [dataSource],
  );

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Integrantes',
        dataIndex: 'integrante',
        key: 'integrante',
      },
    ];
    let data;
    if (organizationsPartners != null) {
      let filterData = organizationsPartners.filter(
        (item) => item.organizacion === record.id,
      );
      if (filterData != null) {
        data = filterData;
      }
    }
    const newDataSource = [];
    for (let i = 0; i < data.length; i++) {
      const findData = dataSource.find(
        (item) => item.id === data[i].integrante,
      );
      newDataSource.push({ id: i, integrante: findData.razon_social });
    }
    return (
      <Table
        columns={columns}
        rowKey="id"
        dataSource={newDataSource}
        pagination={false}
      />
    );
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Organizaciones ejecutoras
      </Title>
      <Paragraph>
        A continuación, podrá realizar acciones como editar y/o eliminar
        registros contenidos en la siguiente tabla. Si desea agregar unq nueva
        organización ejecutora, por favor, haga click en el botón "Crear nueva
        organización ejecutora".
      </Paragraph>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <Link to={INTERNAL_LINKS.ORGANIZATION}>
            <PlusOutlined /> Crear nueva organización ejecutora
          </Link>
        }
      />
      <SpinLoad loading={loading}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          expandable={{ expandedRowRender }}
          columns={columns}
          scroll={{ x: 2000 }}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Organizaciones ejecutoras
            </Paragraph>
          )}
        />
      </SpinLoad>
    </Content>
  );
};

export default OrganizationsList;
