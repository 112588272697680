import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Typography,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Select,
  InputNumber,
  Descriptions,
  Card,
  Space,
} from 'antd';

import CustomButton from '../../components/CustomButton';
import CountryStateCity from '../../components/CountryStateCity/index';

import { validatorNumbers } from '../../utils/helpers/helpers';

import { filter as getContractTypes } from '../../api/module/contract_types';

const { Title } = Typography;

const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const options = [
  {
    id: 1,
    name: 'Mensual',
  },
  {
    id: 2,
    name: 'Total contrato',
  },
  {
    id: 3,
    name: 'Entrega de productos',
  },
  {
    id: 4,
    name: 'Otro ¿Cuál?',
  },
];

const AnnouncementPositionForm = memo(
  ({
     loading,
     announcement,
     initialValues,
     specificTest,
     setSpecificTest,
     interview,
     setInterview,
     psychotechnicalTest,
     setPsychotechnicalTest,
     travel,
     setTravel,
     anotherCity,
     setAnotherCity,
     save,
     ids,
     back,
   }) => {
    const [form] = Form.useForm();
    const [another, setAnother] = useState(
      initialValues != null ? parseInt(initialValues.periodo) : null,
    );
    const [remuneration1, setRenumeration1] = useState(null);
    const [remuneration2, setRenumeration2] = useState(null);
    const [contractTypes, setContractTypes] = useState([]);

    useEffect(() => {
      load();
    }, []);

    const load = () => {
      getContractTypesOptions();
    };

    const getContractTypesOptions = async () => {
      const response = await getContractTypes(``);
      setContractTypes(response.data.results);
    };

    return (
      <>
        {!initialValues.hasOwnProperty('id') ? (
          <Title className={'text-uppercase'} level={2}>
            Crear cargo en convocatoria
          </Title>
        ) : (
          <Title className={'text-uppercase'} level={2}>
            Editar cargo en convocatoria
          </Title>
        )}
        <Descriptions
          title="Información de la convocatoria"
          bordered
          column={2}
        >
          <Descriptions.Item label="Número de convocatoria">
            {announcement.numero}
          </Descriptions.Item>
          <Descriptions.Item label="Nombre de la convocatoria">
            {announcement.nombre}
          </Descriptions.Item>
        </Descriptions>
        <Form
          form={form}
          layout="vertical"
          onFinish={save}
          initialValues={initialValues}
          scrollToFirstError
          autoComplete="off"
          onValuesChange={(data) => {
            if (data.hasOwnProperty('pais')) {
              form.setFieldsValue({
                estado: null,
                ciudad: null,
              });
            }
            if (data.hasOwnProperty('estado')) {
              form.setFieldsValue({
                ciudad: null,
              });
            }
          }}
          validateMessages={validateMessages}
        >
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>Agregar Cargo/Rol</Title>}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="cargo"
                  label="Cargo/Rol de la convocatoria"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Cargo/Rol de la convocatoria"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="remuneracion_fija"
                  label="Remuneración fija"
                  onChange={(e) => {
                    setRenumeration2(true);
                    if (e.target.value == '' || e.target.value == '$') {
                      setRenumeration2(false);
                    }
                  }}
                >
                  <InputNumber
                    disabled={
                      remuneration1 ||
                      initialValues.remuneracion_variable != null
                    }
                    min={0}
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Remuneración fija..."
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="remuneracion_variable"
                  label="Remuneración variable"
                  onChange={(e) => {
                    setRenumeration1(true);
                    if (e.target.value == '' || e.target.value == '$') {
                      setRenumeration1(false);
                    }
                  }}
                >
                  <Input.TextArea
                    rows={4}
                    disabled={
                      remuneration2 || initialValues.remuneracion_fija != null
                    }
                    style={{ width: '100%' }}
                    placeholder="Remuneración variable"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item name="periodo" label="Período">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    placeholder="Período"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => setAnother(value)}
                  >
                    {options != null &&
                      options.map((item) => (
                        <Select.Option
                          key={`period-key-${item.id}`}
                          value={item.id}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              {another == 4 && (
                <Col span={8}>
                  <Form.Item name="periodo_cual" label="¿Cuál?">
                    <Input allowClear />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={12}>
              <Col span={10}>
                <Form.Item name="tipo_contrato" label="Tipo Contrato">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contractTypes.map((item) => (
                      <Select.Option
                        key={`contract-type-key-${item.id}`}
                        value={item.id}
                      >
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Alert
                  style={{ marginBottom: 15 }}
                  type="info"
                  showIcon
                  message={'Requerimientos técnicos'}
                  description="Marque los requerimientos técnicos para la selección. Si no requiere ninguno, deje sin marcar las opciones"
                />
              </Col>
              <Col span={24}>
                <Form.Item name="dirigida"
                           label={'Requerimientos técnicos'}
                >
                  <Row justify="space-between">
                    <Checkbox
                      checked={specificTest}
                      onChange={() => setSpecificTest(!specificTest)}
                    >
                      Prueba específica
                    </Checkbox>
                    <Checkbox
                      checked={interview}
                      onChange={() => setInterview(!interview)}
                    >
                      Entrevista
                    </Checkbox>
                    <Checkbox
                      checked={psychotechnicalTest}
                      onChange={() =>
                        setPsychotechnicalTest(!psychotechnicalTest)
                      }
                    >
                      Prueba psicotécnica
                    </Checkbox>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>Lugar de realización labores</Title>}
          >
            <Row gutter={12}>
              <Col span={18}>
                <CountryStateCity
                  required={false}
                  countryKey="pais"
                  stateKey="departamento"
                  cityKey="ciudad"
                  countryLabel="País"
                  stateLabel="Departamento"
                  cityLabel="Ciudad"
                  country={initialValues.pais}
                  state={initialValues.departamento}
                  city={initialValues.ciudad}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item
                  name="duracion"
                  label="Duración del proyecto (meses)"
                  rules={[
                    {
                      required: true,
                    },
                    validatorNumbers(),
                  ]}
                >
                  <Input
                    placeholder="Duración del proyecto en meses"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>Requisitos de la convocatoria</Title>}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="estudios"
                  label="Estudios"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    maxLength={1000}
                    showCount
                    placeholder="Estudios"
                    allowClear
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="tipo_experiencia_laboral"
                  label="Tipo de experiencia laboral"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    maxLength={1000}
                    showCount
                    placeholder="Experiencia Laboral"
                    allowClear
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={7}>
                <Form.Item
                  name="tiempo_experiencia_laboral"
                  label="Tiempo de experiencia laboral (meses)"
                  rules={[
                    { required: false },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue('tiempo_experiencia_laboral') != null) {
                          if (new RegExp('^[0-9]*$').test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`Ingrese únicamente números`),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Tiempo de experiencia laboral en meses..."
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="travel"
                  label={"Viajar"}
                >
                  <Checkbox checked={travel} onChange={() => setTravel(!travel)}>
                    Disponibilidad para viajar
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="other_city"
                  label={"Vivir en otra ciudad"}
                >
                  <Checkbox
                    checked={anotherCity}
                    onChange={() => setAnotherCity(!anotherCity)}
                  >
                    Posibilidad de radicarse en otra ciudad durante el proyecto
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={4}>
                <Form.Item
                  name="total_vacantes"
                  label="Total vacantes"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Total vacantes" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item>
                  <Space>
                    <CustomButton
                      htmlType="submit"
                      loading={loading}
                      text="Guardar"
                    />
                    <CustomButton
                      type={"default"}
                      className={"back-button"}
                      htmlType="button"
                      onClick={back}
                      text="Volver"
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </>
    );
  },
);

AnnouncementPositionForm.propTypes = {
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};

AnnouncementPositionForm.defaultProps = {
  onFinish: () => {},
  initialValues: {},
  loading: false,
};
export default AnnouncementPositionForm;
