import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_document_contract_validation/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_document_contract_validation/${id}/`);
};

const filter = async (query, page, pageSize) => {
  return await httpClient.get(
    `/term_document_contract_validation${query}&page[number]=${page}&page[size]=${pageSize}`,
  );
};

const getCounters = async (term_id) => {
  return await httpClient.get(
    `term_document_contract_validation/counters/?filter[contrato__termino]=${term_id}`,
  );
};

const getValidationDetail = async (id) => {
  return await httpClient.get(
    `/term_document_contract_validation/${id}/validation_detail/`,
  );
};

const getAllValidationContractDetails = async () => {
  return await httpClient.get(
    `/term_document_contract_validation/validation_detail_list/`,
  );
};

const notify = async (id) => {
  return await httpClient.get(
    `/term_document_contract_validation/${id}/notify/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `/term_document_contract_validation/${id}/`,
    data,
  );
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `/term_document_contract_validation/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(`/term_document_contract_validation/${id}`);
};

export {
  post,
  getValidationDetail,
  getAllValidationContractDetails,
  notify,
  get,
  filter,
  getCounters,
  put,
  patch,
  remove,
};
