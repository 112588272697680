import React, { useEffect, useState } from 'react';
import {
  Alert,
  Typography,
  notification,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Card,
  Modal,
} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import CustomButton from '../../../components/CustomButton';
import MESSAGES from '../../../enums/Messages';
import {
  getGroupsAssign,
  getUsersAssign,
  sendObservations,
} from '../../../api/module/assign_postcontractual';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

const ErrorsContent = ({ errors }) => {
  if (!errors) return null;
  return (
    <ul>
      {/* {errors.a ? (
        <li>
          Ha seleccionado el modulo de Plazo mas no ha indicado la nueva fecha
          final.
        </li>
      ) : null} */}
    </ul>
  );
};

const AssignmentStates = ({ postcontractualID }) => {
  let history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [groups, setGroups] = useState(null);
  const [users, setUsers] = useState(null);
  const [observations, setObservations] = useState([]);
  const [statusProcess, setStatusProcess] = useState([]);
  const [errors, setErrors] = useState(undefined);
  const [formObservations] = Form.useForm();
  const [LoadSendForm, setLoadSendForm] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        if (postcontractualID) {
          const response = await getGroupsAssign(postcontractualID);
          setGroups(response.data.groups);
          setStatusProcess(response.data.statuses);
          setObservations(response.data.observations);
          setLoading(false);
        }
      } catch (err) {
        errorNotification();
      }
    }

    fetchData();
  }, [postcontractualID]);

  const errorNotification = () => {
    notification.error({
      message: MESSAGES.ERROR_MSG,
      description: MESSAGES.DESC_ERROR_GET_MSG,
      duration: 2,
    });
  };

  const getUsersData = async (id) => {
    setLoadingUsers(true);
    formObservations.setFieldsValue({
      usuario_destino: undefined,
    });
    try {
      const response = await getUsersAssign(id);
      setUsers(response.data.results);
    } catch (error) {
      errorNotification();
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleErrors = async (errors, data = undefined) => {
    setErrors({ errors, data });
    setErrorModal(true);
  };

  const sendObservation = async (data) => {
    setLoadSendForm(true);

    try {
      const response = await sendObservations(postcontractualID, data);
      if (response.data?.errors) {
        handleErrors(response.data.errors, data);
        setLoadSendForm(false);
        return;
      }
      setIsSent(true);
      formObservations.resetFields();
      const created = response.data.created ? response.data.created : {};
      setObservations((s) => [...s, created]);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push(`${INTERNAL_LINKS.POSTCONTRACTUAL}`);
      }, 4000);
    } catch (err) {
      setIsSent(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        placement: 'bottomRight',
      });
    }
    setLoadSendForm(false);
  };

  return (
    <>
      <Modal
        title="Validación de liquidación"
        visible={errorModal}
        onOk={() => {
          sendObservation({ ...errors?.data, ignore_errors: true });
          setErrorModal(false);
        }}
        onCancel={() => setErrorModal(false)}
      >
        <>
          La liquidación requiere ajustes:
          <ErrorsContent errors={errors?.errors} />
          ¿Desea enviarla de todos modos?
        </>
      </Modal>
      <Skeleton loading={isLoading} active>
        {errors?.errors.length > 0 && (
          <Alert
            message="Errores de validación:"
            type="warning"
            description={<ErrorsContent errors={errors} />}
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>ASIGNACIÓN DE ESTADOS</Title>}
        >
          <Form
            form={formObservations}
            layout="vertical"
            onFinish={(data) => {
              sendObservation(data);
            }}
          >
            <Form.Item
              label="Selección del rol que revisará"
              name="rol"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(value) => {
                  getUsersData(value);
                }}
              >
                {groups &&
                  groups.map((item) => (
                    <Select.Option
                      key={`document-groups-key-${item.id}`}
                      value={item.id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Selección de la persona que revisará"
              name="usuario_destino"
              rules={[{ required: true }]}
            >
              <Select loading={loadingUsers} disabled={loadingUsers}>
                {users &&
                  users.map((item) => (
                    <Select.Option
                      key={`document-users-key-${item.id}`}
                      value={item.id}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Estado para"
              name="estado_proceso"
              rules={[{ required: true }]}
            >
              <Select options={statusProcess} />
            </Form.Item>
            <Form.Item label="Observaciones" name="observacion">
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              {!isSent && (
                <CustomButton
                  loading={LoadSendForm}
                  htmlType="submit"
                  text="Enviar"
                />
              )}
            </Form.Item>
          </Form>
        </Card>

        {observations.length > 0 && (
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={4}>Histórico de estados asignados</Title>}
            loading={isLoading}
          >
            {observations.map((item, index) => (
              <Descriptions
                title={`Estado No. ${index + 1}`}
                layout="vertical"
                column={4}
                bordered
                key={index}
              >
                <Descriptions.Item label="Usuario que realiza el envío y observación">
                  {item.nombre_completo}
                </Descriptions.Item>
                <Descriptions.Item label="Estado Asignado">
                  {item.estado_proceso_nombre}
                </Descriptions.Item>
                <Descriptions.Item label="Enviado a">
                  {item.rol_nombre}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha y hora del envío">
                  {moment(item.created_at).format('DD MMMM YYYY HH:mm')}
                </Descriptions.Item>
                <Descriptions.Item label="Observación" span={4}>
                  {item.observacion}{' '}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </Card>
        )}
      </Skeleton>
    </>
  );
};

export default AssignmentStates;

export { ErrorsContent };
