import React, { useEffect, useState } from 'react';
import { Layout, notification, Skeleton } from 'antd';

import { useQuery } from '../../utils/helpers/helpers';
import AnnouncementPositionForm from '../../containers/AnnouncementPositionForm/index';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import { post, get, put } from '../../api/module/announcement_rol';
import { get as getAnnouncementById } from '../../api/module/announcement';

const { Content } = Layout;

const AnnouncementPosition = ({ match, history }) => {
  let query = useQuery();
  const announcement_id = query.get('announcement_id');
  const edit = query.get('edit') === 'true';
  const [position_id] = useState(match.params.id);
  const [announcement_type_id] = useState(
    parseInt(match.params.announcement_type_id),
  );
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [specificTest, setSpecificTest] = useState(false);
  const [interview, setInterview] = useState(false);
  const [psychotechnicalTest, setPsychotechnicalTest] = useState(false);
  const [travel, setTravel] = useState(false);
  const [anotherCity, setAnotherCity] = useState(false);
  const [ids] = useState({
    announcement_id: announcement_id,
    announcement_type_id: announcement_type_id,
  });
  const [announcement, setAnnouncement] = useState(null);

  const breadcrumbItems = [
    {
      text: 'Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
    },
    {
      text: 'Crear Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}`,
    },
    {
      text: 'Crear Cargo de Convocatoria',
    },
  ];

  const breadcrumbItemsEdit = [
    {
      text: 'Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
    },
    {
      text: 'Convocatoria en curso',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`,
    },
    {
      text: 'Editar Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${announcement_type_id}/${announcement_id}`,
    },
    {
      text: 'Cargo de Convocatoria',
    },
  ];

  const back = () => {
    history.goBack();
  };

  const getAnnouncement = async () => {
    try {
      setLoadingData(true);
      let response = await getAnnouncementById(announcement_id);
      setAnnouncement(response.data);
      setLoadingData(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    if (announcement_id) {
      await getAnnouncement();
    }
  }, []);

  const save = async (data) => {
    try {
      setLoading(true);
      data.convocatoria = announcement_id;
      data.prueba_especifica = specificTest;
      data.entrevista = interview;
      data.prueba_psicotecnica = psychotechnicalTest;
      data.disponibilidad_viajar = travel;
      data.radicarse_otra_ciudad = anotherCity;

      if (data.tiempo_experiencia_laboral === '') {
        data.tiempo_experiencia_laboral = null;
      }

      let response;
      if (position_id > 0) {
        response = await put(position_id, data);
      } else {
        response = await post(data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          setLoading(false);
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${announcement_type_id}/${announcement_id}`,
          );
        }, MESSAGES.TIME);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };
  const handleGetPosition = async () => {
    let data = {};
    setIsLoading(true);
    if (position_id > 0) {
      const response = await get(position_id);
      data = response.data;
    }
    setIsLoading(false);
    setInitialValues(data);
    setSpecificTest(data.prueba_especifica);
    setInterview(data.entrevista);
    setPsychotechnicalTest(data.prueba_psicotecnica);
    setTravel(data.disponibilidad_viajar);
    setAnotherCity(data.radicarse_otra_ciudad);
  };
  useEffect(async () => {
    await handleGetPosition();
  }, []);

  return (
    <Content>
      {edit ? (
        <BreadcrumbContainer items={breadcrumbItemsEdit} />
      ) : (
        <BreadcrumbContainer items={breadcrumbItems} />
      )}
      <Skeleton loading={loadingData}>
        {initialValues != null && (
          <AnnouncementPositionForm
            loading={loading}
            announcement={announcement}
            initialValues={initialValues}
            specificTest={specificTest}
            setSpecificTest={setSpecificTest}
            interview={interview}
            setInterview={setInterview}
            psychotechnicalTest={psychotechnicalTest}
            setPsychotechnicalTest={setPsychotechnicalTest}
            travel={travel}
            setTravel={setTravel}
            anotherCity={anotherCity}
            setAnotherCity={setAnotherCity}
            save={save}
            ids={ids}
            back={back}
          />
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementPosition;
