import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/roles_management/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient
    .get(`${process.env.REACT_APP_API_HOST}/roles_management/`)
    .then((res) => res.data);
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/roles_management/${id}/`,
    data,
  );
};

export { post, getAll, put };
