import React, { useState }   from 'react';
import { Link } from 'react-router-dom';
import { Layout, Space, Typography } from 'antd';

import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import {ajaxTable as filterAnnouncements} from '../../api/module/announcement';

import './style.scss';
import AjaxTable from "../../components/AjaxTable";

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias caducadas',
  },
];

const AnnouncementFinish = ({ history }) => {
  const [sortColumn, setSortColumn] = useState('-numero');
  const columns = [
    {
      title: 'Tipo convocatoria',
      dataIndex: 'tipo',
      key: 'tipo',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('tipo');
        if (sortOrder === 'descend') setSortColumn('-tipo');
      },
      render: (_, record) => {
        if (record.tipo) {
          return 'Abierta';
        } else {
          return 'Cerrada';
        }
      },
    },
    {
      title: 'Proyecto',
      dataIndex: 'nombre_proyecto',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre_proyecto');
        if (sortOrder === 'descend') setSortColumn('-nombre_proyecto');
      },
    },
    {
      title: 'Organización',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('organizacion_nombre');
        if (sortOrder === 'descend') setSortColumn('-organizacion_nombre');
      },
    },
    {
      title: 'No. convocatoria',
      dataIndex: 'numero',
      key: 'numero',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('numero');
        if (sortOrder === 'descend') setSortColumn('-numero');
      },
    },
    {
      title: 'Nombre convocatoria',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre');
        if (sortOrder === 'descend') setSortColumn('-nombre');
      },
    },
    {
      title: 'Fecha inicio',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_inicio');
        if (sortOrder === 'descend') setSortColumn('-fecha_inicio');
      },
    },
    {
      title: 'Fecha fin',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_fin');
        if (sortOrder === 'descend') setSortColumn('-fecha_fin');
      },
    },
    {
      title: 'Cantidad de Cargo / Rol',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('total');
        if (sortOrder === 'descend') setSortColumn('-total');
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: 125,
      align: 'center',
      render: (_, record) =>
        <Space direction={'vertical'}>
          <CustomButton
            block
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${record.id}?finish=true&preview=true`}
              >
                Ver
              </Link>
            }
          />
          <CustomButton
            block
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_DETAILS}/${record.id}`}
              >
                Detalle
              </Link>
            }
          />
        </Space>
    },
  ];

  const back = () => {
    history.goBack();
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Convocatorias caducadas
      </Title>
      <AjaxTable
        columns={columns}
        pageSize={10}
        scroll={{ x: 1400 }}
        tableTitle={'Listado de convocatorias caducadas'}
        endpoint={filterAnnouncements}
        sortBy={sortColumn}
        searchInput
        exportCSV
        query={'?filter[estado_convocatoria.sigla]=CV-F&filter[convocatoria_caducada]=true'}
      />
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </Content>
  );
};

export default AnnouncementFinish;
