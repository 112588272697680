import React, { useState } from 'react';
import { notification, Typography, Popconfirm, Space } from 'antd';

import CustomButton from '../../../../components/CustomButton';
import SpinLoad from '../../../../components/SpinLoad';
import MESSAGES from '../../../../enums/Messages';
import { post } from '../../../../api/module/announcement_selection_email';
import {
  ajaxTable as filterAjaxTable,
} from '../../../../api/module/announcement_selection';
import AjaxTable from "../../../../components/AjaxTable";

const { Paragraph } = Typography;

const Selected = ({
  dataSource,
  announcement_id,
  announcement_rol_id,
  loading,
  person_id,
}) => {
  const [isLoadingNoSuccess, setIsLoadingNoSuccess] = useState(false);
  const [isLoadingPending, setIsLoadingPending] = useState(false);
  const [isLoadingContrated, setIsLoadingContrated] = useState(false);
  const [reloadSource, setReloadSource] = useState(false);

  const sendEmailNoSuccess = async (person_id) => {
    try {
      setIsLoadingNoSuccess(true);
      let data = {
        convocatoria: announcement_id,
        convocatoria_rol: announcement_rol_id,
        tercero: person_id,
        estado_tercero: 19,
      };

      const response = await post(data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setReloadSource(!reloadSource);
        setIsLoadingNoSuccess(false);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setIsLoadingNoSuccess(false);
    }
  };

  const sendEmailPending = async (person_id) => {
    try {
      setIsLoadingPending(true);
      let data = {
        convocatoria: announcement_id,
        convocatoria_rol: announcement_rol_id,
        tercero: person_id,
        estado_tercero: 32,
      };
      const response = await post(data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setReloadSource(!reloadSource);
        setTimeout(() => {
          setIsLoadingPending(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setIsLoadingPending(false);
    }
  };

  const sendEmailContracted = async (person_id) => {
    try {
      setIsLoadingContrated(true);
      let data = {
        convocatoria: announcement_id,
        convocatoria_rol: announcement_rol_id,
        tercero: person_id,
        estado_tercero: 23,
      };
      const response = await post(data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setReloadSource(!reloadSource);
        setTimeout(() => {
          setIsLoadingContrated(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setIsLoadingContrated(false);
    }
  };

  const columns = [
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero_data', 'notificacion_email'],
      key: ['tercero_data', 'notificacion_email'],
      align: 'center',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero_data', 'nombre_completo'],
      key: ['tercero_data', 'nombre_completo'],
      align: 'center',
    },
    {
      title: 'Tipo Identificación',
      dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      key: ['tercero_data', 'tipo_identificacion_nombre'],
      align: 'center',
    },
    {
      title: 'Doc Identificación',
      dataIndex: ['tercero_data', 'numero_identificacion'],
      key: ['tercero_data', 'numero_identificacion'],
    },
    {
      title: 'Estado Final',
      dataIndex: ['tercero_data', 'estado'],
      align: 'center',
      render: (_, record) => (record.estado_seleccion === 23 ? 'Seleccionado' : null),
    },
    {
      title: 'Enviar Correo notificación',
      dataIndex: 'estado_preseleccion',
      key: 'estado_preseleccion',
      render: (_, record) => (
        <Space direction="vertical">
          <Space>
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              loading={isLoadingNoSuccess}
              text={
                <Popconfirm
                  title={MESSAGES.MSG_SEND_EMAIL}
                  onConfirm={(e) => {
                    sendEmailNoSuccess(record.tercero);
                  }}
                >
                  Enviar correo proceso no exitoso
                </Popconfirm>
              }
            />
            {record.estado_envio_seleccionado_no_exitoso && (
              <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
            )}
          </Space>
          <Space>
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              loading={isLoadingPending}
              text={
                <Popconfirm
                  title={MESSAGES.MSG_SEND_EMAIL}
                  onConfirm={(e) => {
                    sendEmailPending(record.tercero);
                  }}
                >
                  Enviar correo proceso pendiente
                </Popconfirm>
              }
            />
            {record.estado_envio_seleccionado_pendiente && (
              <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
            )}
          </Space>
          <Space>
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              loading={isLoadingContrated}
              text={
                <Popconfirm
                  title={MESSAGES.MSG_SEND_EMAIL}
                  onConfirm={(e) => {
                    sendEmailContracted(record.tercero);
                  }}
                >
                  Enviar correo continuación a contratación
                </Popconfirm>
              }
            />
            {record.estado_envio_seleccionado && (
              <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
            )}
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <SpinLoad loading={loading}>
      {dataSource != null && (
        <AjaxTable
          columns={columns}
          pageSize={10}
          tableTitle={'Listado de contratistas seleccionados'}
          endpoint={filterAjaxTable}
          searchInput
          exportCSV
          reloadButton
          reloadSource={reloadSource}
          query={`/?filter[convocatoria_rol]=${announcement_rol_id}&filter[estado_seleccion]=23`}
        />
      )}
    </SpinLoad>
  );
};

export default Selected;
