import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Input, Button, message, Form, Alert } from 'antd';

import './style.scss';

export const CustomUpload = ({
  file_type,
  upload_type,
  onChange,
  name,
  label,
  loading,
  required,
  tooltip,
  validate_size,
  buttonLabel = 'Cargar',
  disabled = false,
}) => {
  let uploadRef = null;
  const [fileList, setFileList] = useState([]);
  const [fileError, setError] = useState(false);
  const [textError, setTextError] = useState(false);

  const onInternalChange = (e) => {
    onChange(e);
  };

  const beforeUpload = (file) => {
    setFileList([]);
    setError(false);
    setTextError('');
    setFileList([file]);

    if (file_type === 1) {
      return beforeUploadPdf(file);
    }
    if (file_type === 2) {
      return beforeUploadImg(file);
    }
    if (file_type === 3) {
      return beforeUploadXlsx(file);
    }
    if (file_type === 4) {
      return beforeUploadWord(file);
    }
  };

  const beforeUploadPdf = (file) => {
    const isPDF = file.type === 'application/pdf';

    try {
      if (!isPDF) {
        setError(true);
        setTextError('Solo puede subir archivos PDF!');
        setFileList([]);
        return false;
      }
      if (!checkSize(file)) {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
    return isPDF ? false : Upload.LIST_IGNORE;
  };

  const beforeUploadImg = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    try {
      if (!isJpgOrPng) {
        setError(true);
        setTextError('solo puede subir archivos JPG/PNG!');
        setFileList([]);
        return false;
      }
      checkSize(file);
    } catch (err) {
      console.log(err);
    }

    return isJpgOrPng ? false : Upload.LIST_IGNORE;
  };

  const beforeUploadXlsx = (file) => {
    let isXlsx =
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    try {
      if (!isXlsx) {
        setError(true);
        setTextError('Solo puede subir archivos xlsx!');
        setFileList([]);
      }
      if (!checkSize(file)) {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
    return isXlsx ? false : Upload.LIST_IGNORE;
  };

  const beforeUploadWord = (file) => {
    let isWORD =
      file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    try {
      if (!isWORD) {
        setError(true);
        setTextError('Solo puede subir archivos Word!');
        setFileList([]);
      }
      if (!checkSize(file)) {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
    return isWORD ? false : Upload.LIST_IGNORE;
  };

  const checkSize = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 4.5;

    if (!isLt5M && validate_size) {
      setError(true);
      setTextError('El archivo no puede ser mayor a 2MB!');
      setFileList([]);
      return false;
    }
    return true;
  };

  return (
    <>
      {(upload_type === 1 || upload_type === 3) && (
        <Upload
          ref={(ref) => (uploadRef = ref)}
          multiple={false}
          maxCount={1}
          beforeUpload={beforeUpload}
          fileList={fileList}
          onChange={(e) => onInternalChange(e)}
          disabled={disabled}
        >
          <Input
            suffix={
              <Button loading={loading} disabled={disabled}>
                {buttonLabel}
              </Button>
            }
          />
        </Upload>
      )}
      {upload_type === 2 && (
        <Form.Item
          name={name}
          label={label}
          tooltip={tooltip}
          rules={[
            {
              required: required,
            },
          ]}
        >
          <Upload
            ref={(ref) => (uploadRef = ref)}
            style={{ width: '100%' }}
            multiple={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            fileList={fileList}
            onRemove={() => {
              setFileList([]);
            }}
            disabled={disabled}
          >
            <Input
              suffix={
                <Button loading={loading} disabled={disabled}>
                  {buttonLabel}
                </Button>
              }
            />
          </Upload>
        </Form.Item>
      )}
      {upload_type === 4 && (
        <Upload
          ref={(ref) => (uploadRef = ref)}
          multiple={false}
          maxCount={1}
          beforeUpload={beforeUpload}
          listType="picture-card"
          onChange={(e) => onInternalChange(e)}
          fileList={fileList}
        >
          {buttonLabel}
        </Upload>
      )}
      {upload_type === 5 && (
        <Form.Item
          name={name}
          label={label}
          tooltip={tooltip}
          rules={[
            {
              required: required,
            },
          ]}
        >
          <Upload
            ref={(ref) => (uploadRef = ref)}
            style={{ width: '100%' }}
            multiple={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={(e) => onInternalChange(e)}
            fileList={fileList}
          >
            <Input suffix={<Button>{buttonLabel}</Button>} />
          </Upload>
        </Form.Item>
      )}
      {fileError && (
        <>
          <br></br>
          <Alert showIcon message={textError} type="error" />
          <br></br>
        </>
      )}
    </>
  );
};

CustomUpload.propTypes = {
  file_type: PropTypes.number,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
};

CustomUpload.defaultProps = {
  file_type: 0,
  onChange: () => {},
  loading: false,
  required: false,
  tooltip: '',
};
export default CustomUpload;
