import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Modal, Skeleton, Layout, Tabs, Badge } from 'antd';

import AnnouncementPreselectionForm from '../../../containers/AnnouncementPreselectionForm/index';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AjustInformation from '../Validation/AdjustInformation/index';
import Preselected from '../Validation/Preseleted/index';
import ToCheck from '../Validation/ToCheck/index';
import Rejected from '../Validation/Rejected/index';

import { get as Announcement } from '../../../api/module/announcement';
import { counters as AnnouncementPreselectionCounters } from '../../../api/module/announcement_preselection';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { filter as getEmailsById } from '../../../api/module/announcement_preselection_email';

import './style.scss';
import ValidationEmailHistory from '../../../components/ValidationEmailHistory';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const AnnouncementValidation = ({ history, match }) => {
  const [announcement_id] = useState(match.params.announcement_id);
  const [announcement_rol_id] = useState(match.params.announcement_rol_id);
  const [dataSource, setDataSource] = useState(null);
  const [loadingForm, setLoadingForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [counters, setCounters] = useState(null);
  const [activeKey, setActiveKey] = useState('toCheck');
  const [check, setCheck] = useState(null);
  const [preselected, setPreselected] = useState(null);
  const [pending, setPending] = useState(null);
  const [rejected, setReject] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalHistoryData, setModalHistoryData] = useState(null);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);

  const breadcrumbItems = [
    {
      text: 'Preseleccionados',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}`,
    },
    {
      text: 'Validación',
    },
  ];

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(announcement_id);
      const responseRol = await AnnouncementRol(announcement_rol_id);
      response.data.cargo = responseRol.data.cargo;
      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getCounters = async () => {
    const response = await AnnouncementPreselectionCounters(
      announcement_rol_id,
    );
    setCounters(response.data.results);
  };

  useEffect(async () => {
    setLoadingForm(true);
    await loadData();
    setLoadingForm(false);
    setCheck([]);
  }, []);

  const loadData = async () => {
    await Promise.all([getAnnouncement(), getCounters()]);
  };

  const handleListType = async (newListType) => {
    setCheck(null);
    setPreselected(null);
    setPending(null);
    setReject(null);

    if (newListType === 'toCheck') setCheck([]);

    if (newListType === 'preselected') setPreselected([]);

    if (newListType === 'adjust_information') setPending([]);

    if (newListType === 'rejected') setReject([]);

    setActiveKey(newListType);
    await getCounters();
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Preselección - Validación
      </Title>
      <Skeleton loading={loadingForm} active>
        {dataSource != null && (
          <AnnouncementPreselectionForm
            initialValues={dataSource}
            useDescriptions={true}
            descriptionsTitle="Información de la convocatoria"
            descriptionsColumns={3}
          />
        )}
        <CustomButton
          style={{ marginTop: 10 }}
          htmlType={'htmlButton'}
          onClick={(event) => {
            setCheck([]);

            setTimeout(async () => {
              await handleListType('toCheck');
            }, 500);
          }}
          text={'Actualizar registros'}
        />
        <Tabs
          type="card"
          defaultActiveKey="toCheck"
          activeKey={activeKey}
          onChange={handleListType}
          style={{ marginTop: 15 }}
          destroyInactiveTabPane={true}
        >
          <TabPane
            tab={
              <Paragraph>
                Por revisar
                <Badge showZero count={counters ? counters.apply : 0} />
              </Paragraph>
            }
            key="toCheck"
          >
            <ToCheck
              dataSource={check}
              announcement_id={announcement_id}
              announcement_rol_id={announcement_rol_id}
              loading={isLoading}
              loadData={loadData}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Preseleccionados
                <Badge showZero count={counters ? counters.preselected : 0} />
              </Paragraph>
            }
            key="preselected"
          >
            <Preselected
              dataSource={preselected}
              announcement_id={announcement_id}
              announcement_rol_id={announcement_rol_id}
              loading={isLoading}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Ajustar información (Pendiente)
                <Badge showZero count={counters ? counters.pending : 0} />
              </Paragraph>
            }
            key="adjust_information"
          >
            <AjustInformation
              dataSource={pending}
              announcement_id={announcement_id}
              announcement_data={dataSource}
              announcement_rol_id={announcement_rol_id}
              loading={isLoading}
              historyLoading={historyLoading}
              showHistory={async (record) => {
                setHistoryLoading(true);
                let response = await getEmailsById(
                  `?filter[convocatoria_tercero]=${record.id}`,
                );
                setModalData(record);
                setModalHistoryData(response.data.results);
                setTimeout(() => {
                  setHistoryVisible(true);
                  setHistoryLoading(false);
                }, 500);
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Rechazados
                <Badge showZero count={counters ? counters.reject : 0} />
              </Paragraph>
            }
            key="rejected"
          >
            <Rejected
              dataSource={rejected}
              announcement_id={announcement_id}
              announcement_rol_id={announcement_rol_id}
              loading={isLoading}
            />
          </TabPane>
        </Tabs>
        <CustomButton
          type={'default'}
          className={'back-button'}
          htmlType="button"
          text={
            <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}`}>
              Volver
            </Link>
          }
        />
      </Skeleton>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Histórico de correos electrónicos enviados - Preselección
          </Title>
        }
        visible={historyVisible}
        onCancel={() => {
          setHistoryVisible(false);
        }}
        cancelText="Cerrar"
        width={1000}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ValidationEmailHistory
          type="preselection"
          validationData={modalData}
          dataSource={modalHistoryData}
        />
      </Modal>
    </Content>
  );
};

export default AnnouncementValidation;
