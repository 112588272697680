import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/term_products/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_products/`,
  );
};

const getAllByTerm = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_products/?filter[termino]=${id}`,
  );
};

const getAllByTermPagos = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_products/?filter[termino]=${id}&filter[tiene_pagos]=1&page[size]=100`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_products/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/term_products/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/term_products/${id}`,
  );
};

const renumerate = async (term_id) => {
  return await httpClient.post('/term_products/renumerate/', {
    termino: term_id,
  });
};
const swap = async (a, b) => {
  return await httpClient.post('/term_products/swap/', {
    a: a,
    b: b,
  });
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'codigo',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_products/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_products` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

export {
  post,
  getAll,
  getAllByTerm,
  getAllByTermPagos,
  get,
  put,
  remove,
  ajaxTable,
  renumerate,
  swap,
};
