import httpClient from '../httpClient';

const getDocuments = async (id) => {
  return await httpClient.get(`ct_modifications_director/${id}`);
};

const getCounters = async (query) => {
  return await httpClient.get(`ct_modifications_director/counters${query}`);
};

const sign = async (id) => {
  return await httpClient.patch(`/ct_modifications_director/${id}/sign/`);
};

const reject = async (id, data) => {
  return await httpClient.patch(
    `/ct_modifications_director/${id}/reject/`,
    data,
  );
};

const doReturn = async (id, data) => {
  return await httpClient.patch(
    `/ct_modifications_director/${id}/do_return/`,
    data,
  );
};

const makeFilterParams = (params = {}) => {
  const filterParams = Object.entries(params).reduce((pv, cv) => {
    pv[`filter[${cv[0]}]`] = cv[1];
    return pv;
  }, {});
  return { params: filterParams };
};

const signSelected = async (data, params) => {
  return await httpClient.patch(
    `/ct_modifications_director/selected/`,
    data,
    makeFilterParams(params),
  );
};

const signMassive = async (params) => {
  return await httpClient.patch(
    `/ct_modifications_director/massive/`,
    {},
    makeFilterParams(params),
  );
};

const getProjects = async (params) => {
  return await httpClient.get(
    '/ct_modifications_director_selectors/projects/',
    {
      params: params,
    },
  );
};

const getContractTypes = async (params) => {
  return await httpClient.get(
    '/ct_modifications_director_selectors/contract_types/',
    {
      params: params,
    },
  );
};

const getRoles = async (params) => {
  return await httpClient.get('/ct_modifications_director_selectors/roles/', {
    params: params,
  });
};

const getTerms = async (params) => {
  return await httpClient.get('/ct_modifications_director_selectors/terms/', {
    params: params,
  });
};

const getGroups = async (id) => {
  return await httpClient.get(
    `/ct_modifications_director_return_selectors/get_groups/?filter[modificacion]=${id}`,
  );
};

const getUsers = async (id, group) => {
  return await httpClient.get(
    `/ct_modifications_director_return_selectors/get_users/?filter[modificacion]=${id}&filter[grupo]=${group}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/ct_modifications_director/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/ct_modifications_director` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

export {
  getCounters,
  sign,
  signSelected,
  signMassive,
  reject,
  doReturn,
  ajaxTable,
  getDocuments,
  getProjects,
  getContractTypes,
  getRoles,
  getTerms,
  getUsers,
  getGroups,
};
