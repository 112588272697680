import { handleActions } from "redux-actions";
import { Map } from "immutable";

// Action Type Imports
import { constants as mainConstants } from "../actions/main-actions";

// Main Reducer
export const reducers = {
  [mainConstants.SET_LOADING]: (state, { payload }) => {
    return state.merge({ loading: payload.loading });
  },
};

export const initialState = () =>
  Map({
    loading: false,
  });

export default handleActions(reducers, initialState());
