import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_document_contract_training/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_document_contract_training/${id}`);
};

const getAllByValidation = async (validation_id) => {
  return await httpClient.get(
    `/term_document_contract_training/documents/?filter[document_validation]=${validation_id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(`/term_document_contract_training/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `/term_document_contract_training/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(`/term_document_contract_training/${id}`);
};

export { post, get, getAllByValidation, put, patch, remove };
