import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Radio,
  Typography,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Layout,
} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import INTERNAL_LINKS from '../../enums/InternalLinks';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { Option } = Select;

const LaborContractorsForm = ({
  ids,
  onFinish,
  setCheckValue,
  checkValue,
  config,
  todayDate,
  setDateAdmission,
  experienceAreas,
  jobType,
  loading,
  DateFormat,
  back,
  initialValues,
  showClick,
  document,
  isLoadingDoc,
}) => {
  const [initialDate, setInitialDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);

  const breadcrumbItems = [
    {
      text: 'Proveedores y Consultores - Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSONS}`,
    },
    {
      text: 'Registro de Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSON}/${ids.person_id}`,
    },
    {
      text: 'Registro laboral',
    },
  ];

  useEffect(() => {
    if (initialValues) {
      if (initialValues.fecha_ingreso) {
        setInitialDate(initialValues.fecha_ingreso);
      }
      if (initialValues.fecha_terminacion) {
        setFinishDate(initialValues.fecha_terminacion);
      }
    }
  }, []);

  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Registro laboral
      </Title>
      <Paragraph>
        Recuerde que solo debe agregar la información relacionada con el
        quehacer de Corpoeducación (información que considere relevante) de la
        más reciente a la más antigua, de lo contrario dicha información puede
        ser eliminada de la plataforma.
      </Paragraph>
      <Form
        name="nest-messages"
        layout="vertical"
        initialValues={{ ...initialValues }}
        onFinish={onFinish}
        scrollToFirstError
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="empresa"
              label="Empresa o entidad"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Empresa..." allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="estado"
              label="Estado actual"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => setCheckValue(e.target.value)}
                value={checkValue}
              >
                <Radio value={1}>Trabajo finalizado</Radio>
                <Radio value={2}>Trabajo actual</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {checkValue === 1 && (
          <>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="fecha_ingreso"
                  label="Fecha de ingreso"
                  {...config}
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que seleccione una fecha de ingreso',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || todayDate >= DateFormat(value)) {
                          if (
                            getFieldValue('fecha_terminacion') <
                            getFieldValue('fecha_ingreso')
                          ) {
                            return Promise.reject(
                              'La fecha de terminación no puede ser menor a la fecha de ingreso.',
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject(
                          'La fecha de ingreso no debe ser posterior a la fecha actual!',
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      setDateAdmission(DateFormat(e));
                      setInitialDate(e);
                    }}
                    disabledDate={disabledDate}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                {initialDate && (
                  <Form.Item noStyle>
                    Fecha de ingreso seleccionada:
                    <strong> {initialDate.format('DD MMMM YYYY')}</strong>
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Form.Item
                  name="fecha_terminacion"
                  style={{ marginBottom: 10 }}
                  label="Fecha de terminación"
                  placeholder="Fecha de terminación..."
                  {...config}
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que seleccione una fecha de terminación',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || todayDate >= DateFormat(value)) {
                          if (
                            getFieldValue('fecha_terminacion') <
                            getFieldValue('fecha_ingreso')
                          ) {
                            return Promise.reject(
                              'La fecha de terminación no puede ser menor a la fecha de ingreso.',
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject(
                            'La fecha de ingreso no debe ser posterior a la fecha actual!',
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={disabledDate}
                    format="YYYY-MM-DD"
                    onChange={(e) => {
                      setFinishDate(e);
                    }}
                  />
                </Form.Item>
                {finishDate && (
                  <Form.Item noStyle>
                    Fecha de terminación seleccionada:
                    <strong> {finishDate.format('DD MMMM YYYY')}</strong>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="area_experiencia"
                  label="Área de experiencia"
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que seleccione un área de experiencia',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Área de Experiencia..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {experienceAreas != null && (
                      <>
                        {experienceAreas.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="tipo_cargo"
                  label="Tipo de cargo"
                  rules={[
                    {
                      required: true,
                      message: 'Es requerido que seleccione un tipo de cargo',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Tipo de Trabajo..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {jobType != null && (
                      <>
                        {jobType.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="cargo"
                  label="Nombre de cargo"
                  rules={[
                    {
                      required: true,
                      message: 'Es requerido que ingrese nombre del cargo',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || value.length <= 100) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Ingrese maximo 100 caracteres!');
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Nombre del cargo..." allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} align={'middle'}>
              <Col span={20}>
                <CustomUpload
                  upload_type={2}
                  file_type={1}
                  required={document}
                  name="certificacion"
                  label="Cargar Certificación"
                  tooltip="Tamaño del archivo máximo de 2MB en formato PDF"
                />
              </Col>
              <Col span={4}>
                {initialValues != null && initialValues.documento_nombre && (
                  <CustomButton
                    type="default"
                    htmlType="button"
                    text="Ver"
                    loading={isLoadingDoc}
                    onClick={() => {
                      showClick(initialValues.documento_nombre);
                    }}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        {checkValue === 2 && (
          <>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="fecha_ingreso"
                  label="Fecha de ingreso"
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que seleccione una fecha de ingreso',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || todayDate >= DateFormat(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'La fecha de ingreso no debe ser posterior a la fecha actual!',
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                {initialDate && (
                  <Form.Item noStyle>
                    Fecha de ingreso seleccionada:
                    <br />
                    <strong>{initialDate.format('DD MMMM YYYY')}</strong>
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                <Form.Item
                  name="area_experiencia"
                  label="Área de experiencia"
                  rules={[
                    {
                      required: true,
                      message:
                        'Es requerido que seleccione un área de experiencia',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Área de Experiencia..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {experienceAreas != null && (
                      <>
                        {experienceAreas.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 20 }}>
              <Col span={8}>
                <Form.Item
                  name="tipo_cargo"
                  label="Tipo de cargo"
                  rules={[
                    {
                      required: true,
                      message: 'Es requerido que seleccione un tipo de cargo',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Tipo de cargo..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {jobType != null && (
                      <>
                        {jobType.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nombre}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="cargo"
                  label="Nombre de cargo"
                  rules={[
                    {
                      required: true,
                      message: 'Es requerido que ingrese nombre del cargo',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || value.length <= 100) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Ingrese maximo 100 caracteres!');
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Nombre del cargo..." allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} align={'middle'}>
              <Col span={20}>
                <CustomUpload
                  upload_type={2}
                  file_type={1}
                  required={document}
                  name="certificacion"
                  label="Cargar Certificación"
                  tooltip="Tamaño del archivo máximo de 2MB en formato PDF"
                />
              </Col>
              <Col span={4}>
                {initialValues != null && initialValues.documento_nombre && (
                  <CustomButton
                    type="default"
                    htmlType="button"
                    text="Ver"
                    loading={isLoadingDoc}
                    onClick={() => {
                      showClick(initialValues.documento_nombre);
                    }}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Space>
                <CustomButton loading={loading} text="Guardar" />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default LaborContractorsForm;
