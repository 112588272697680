//Library imports
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {Divider, Input, notification, Popconfirm, Table, Typography} from 'antd';

//Component imports
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

//API imports
import {getAll as getPolitics} from '../../api/module/politics';
import {
  getAllByTerm as getTermPolicies,
  post as postTermPolicies,
  remove as deleteTermPolicies,
} from '../../api/module/term_policies';
import {get as getProject} from '../../api/module/projects';

//Css imports
import './style.scss';

//Constants
const { Search } = Input;
const { Title, Paragraph } = Typography;

/* Component that filter, show the Policies and adds them*/
const ContractPoliciesSearchTable = memo(
  ({ dataSource, spinLoad, rowSelection }) => {
    const columns = [
      {
        title: 'Políticas, lineamientos y/o protocolos específicos',
        dataIndex: 'nombre',
        key: 'nombre',
      },
    ];

    return (
      <>
        <SpinLoad loading={spinLoad}>
          <Table
            bordered
            rowKey="id"
            size="small"
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </SpinLoad>
      </>
    );
  },
);

const ContractPoliciesTable = memo(
  ({ dataSource, spinLoad, deleteDataSource, politics }) => {
    let policiesName = (id) => {
      const findPolicy = politics.find((item) => item.id == id);
      if (findPolicy != null) {
        return findPolicy.nombre;
      }
    };

    const columns = [
      {
        title: 'Políticas, lineamientos y/o protocolos específicos',
        dataIndex: 'especificacion',
        render: (_, record) =>
          dataSource.length >= 1 ? policiesName(record.especificacion) : null,
      },
      {
        title: 'ACCIÓN',
        dataIndex: 'accion',
        align: 'center',
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <div className="box-btn-center">
              <CustomButton
                danger="true"
                text={
                  <Popconfirm
                    title={MESSAGES.DELETE_CONFIRM_MSG}
                    onConfirm={() => deleteDataSource(record.id)}
                  >
                    Eliminar
                  </Popconfirm>
                }
              />
            </div>
          ) : null,
      },
    ];

    return (
      <>
        <SpinLoad loading={spinLoad}>
          <Table
            bordered
            rowKey="id"
            scroll={{ x: 400 }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </SpinLoad>
      </>
    );
  },
);

/* Main Component */
export default function ContractPolicies({ match }) {
  const [termID] = useState(parseInt(match.params.term_id));
  const [projectID] = useState(
    parseInt(match.params.project_id),
  );
  const [contractID] = React.useState(
    parseInt(match.params.contract_id),
  );
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [politics, setPolitics] = useState([]);
  const [project, setProject] = useState([]);
  const [newDataPolicies, setNewDataPolicies] = useState(null);
  const [termPolicies, setTermPolicies] = useState([]);
  const [text, setText] = useState('');
  const [selected, setSelected] = useState([]);

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}`,
    },
    {
      text:
        'Políticas, lineamientos y/o protocolos específicos para el desarrollo del contrato',
    },
  ];

  const getPoliticsData = () => {
    try {
      setSpinLoad(true);
      getPolitics().then((res) => {
        setPolitics(res.data.results);
        setSpinLoad(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const getProjectData = async () => {
    const response = await getProject(projectID);
    setProject(response.data);
  };

  useEffect(() => {
    getPoliticsData();
    getProjectData();
  }, []);

  // selected button table
  const onSelectChange = (selected) => {
    setSelected(selected);
  };

  const rowSelection = {
    selected,
    onChange: onSelectChange,
  };

  const associatePolicies = async () => {
    try {
      let response;
      for (let i = 0; i < selected.length; i++) {
        const data = {
          termino: termID,
          especificacion: parseInt(selected[i]),
        };
        setLoading(true);
        response = await postTermPolicies(data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const dataSourceID = useCallback(
    (data) => {
      return data.filter((item) => item.termino == termID);
    },
    [termID],
  );

  const getTermPoliciesData = () => {
    try {
      setSpinLoad(true);
      getTermPolicies(termID).then((res) => {
        setTermPolicies(dataSourceID(res.data.results));
        setSpinLoad(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getTermPoliciesData();
  }, []);

  const deleteDataSource = useCallback(
    async (id) => {
      try {
        const response = await deleteTermPolicies(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
          setTermPolicies(termPolicies.filter((item) => item.id !== id));
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [termPolicies],
  );

  useEffect(() => {
    // aggregate data filtering
    let newPolitics = [...politics];
    let newTermPolicies = [...termPolicies];
    const newArray = [];
    for (let i = 0; i < newPolitics.length; i++) {
      let equal = false;
      for (let j = 0; (j < newTermPolicies.length) & !equal; j++) {
        if (newPolitics[i]['id'] == newTermPolicies[j]['especificacion']) {
          equal = true;
        }
      }
      if (!equal) newArray.push(newPolitics[i]);
    }
    setNewDataPolicies(newArray);
  }, [politics, termPolicies]);

  const filteredPolitics = useMemo(() => {
    if (newDataPolicies) {
      return newDataPolicies.filter((item) =>
        item.nombre.toString().toLowerCase().includes(text.toLowerCase()),
      );
    }
  }, [text, newDataPolicies]);

  return (
    <div className="terms-location-container">
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title level={2}>TÉRMINOS DE REFERENCIA</Title>
        <Title level={3}>PROYECTO: {project && project.nombre}</Title>
      </div>
      <div className="box-description">
        <Paragraph>Los siguientes términos de referencia complementan el texto del contrato firmado entre las partes y están subordinados a dichos contratos.</Paragraph>
      </div>
      <Divider />
      <Title level={3}>C. ESPECIFICACIONES DEL CONTRATO</Title>
      <Title level={4}>
        Políticas, lineamientos y/o protocolos específicos para el desarrollo
        del contrato
      </Title>
      <div className="box-description">
        <Paragraph>
          Seleccione las Políticas, lineamientos y/o protocolos específicos.
          Recuerde que son requerimientos asociados a condiciones
          contractuales del cliente / aliado.
        </Paragraph>
      </div>
      <div className="box__table-search">
        <div className="box__btn-search">
          <Search
            placeholder="Búsqueda en la tabla"
            onChange={(e) => setText(e.target.value)}
            value={text}
            style={{ width: 200 }}
          />
        </div>
        <ContractPoliciesSearchTable
          dataSource={filteredPolitics}
          spinLoad={spinLoad}
          rowSelection={rowSelection}
        />
      </div>
      <div className="box-btn-center box__btn-t-b">
        <CustomButton
          loading={loading}
          text="Asociar"
          onClick={associatePolicies}
        />
      </div>

      <ContractPoliciesTable
        dataSource={termPolicies}
        spinLoad={spinLoad}
        politics={politics}
        deleteDataSource={deleteDataSource}
      />

      <div className="box-btn-center">
        <CustomButton
          danger="true"
          text={
            <Link
              to={`${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}/?module=C`}
            >
              Cerrar
            </Link>
          }
        />
      </div>
    </div>
  );
}
