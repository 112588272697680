import React from 'react';
import { Modal } from 'antd';

const PdfViewer = ({ pdf, visible, onCancel, isWord = false }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      style={{ top: 20 }}
      width={'80%'}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <iframe
        src={
          isWord === true
            ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                pdf,
              )}`
            : pdf
        }
        style={{ width: '100%', minHeight: '80vh', marginTop: '1.5em' }}
      />
    </Modal>
  );
};
export default PdfViewer;
