import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/pay_responsibility_product/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient
    .get(`${process.env.REACT_APP_API_HOST}/pay_responsibility_product/`)
    .then((res) => res.data);
};

const getAllByTerm = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/pay_responsability_product/?filter[termino]=${id}&page[size]=1000`,
  );
};

const getAllByPay = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/pay_responsability_product/?filter[pago_id]=${id}`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/pay_responsibility_product/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/pay_responsibility_product/${id}/`,
    data,
  );
};

const removePayment = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/remove_payment/`,
    data,
  );
};

export { post, getAll, getAllByTerm, get, put, removePayment };
