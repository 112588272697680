import React, { useState, useEffect } from 'react';
import { Select, Skeleton } from 'antd';
import PropTypes from 'prop-types';

import { getRoleByProject } from '../../api/module/project_positions';

const SelectPositionRole = ({
  className = '',
  onChange = () => {},
  onChangeData = null,
  onLoad = null,
  project_id = undefined,
  cargo = undefined,
  disabled,
  style = {},
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(cargo);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (data) setValue(undefined);
      let res = await getRoleByProject(project_id);
      setData(res.data.results);
      if (onLoad) {
        onLoad(
          cargo
            ? res.data.results.find((item) => item.id === cargo)
            : undefined,
        );
      }
      setIsLoading(false);
    }
    fetchData();
  }, [project_id]);

  const onSelfChange = (value) => {
    if (!onChangeData) onChange(value);
    else onChangeData(value ? data.find((item) => item.id === value) : null);
    setValue(value);
  };

  return (
    <>
      <Skeleton loading={isLoading} active paragraph={false} style={style}>
        <Select
          placeholder="Elija cargo o rol que tendrá la persona a contratar..."
          className={className}
          onChange={onSelfChange}
          loading={isLoading}
          value={value}
          disabled={disabled}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={style}
          key={project_id}
        >
          {data &&
            data.map((item) => (
              <Select.Option
                key={`position-rol-key-${item.id}`}
                value={item.id}
              >
                {item.cargo}
              </Select.Option>
            ))}
        </Select>
      </Skeleton>
    </>
  );
};

SelectPositionRole.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  positionRole: PropTypes.number,
  project_id: PropTypes.number,
  required: PropTypes.bool,
  cargo: PropTypes.number,
  style: PropTypes.object,
};

SelectPositionRole.defaultProps = {
  className: '',
  disabled: false,
  onChange: () => {},
  positionRole: 0,
  project_id: 0,
  required: false,
  cargo: undefined,
  style: {},
};

export default SelectPositionRole;
