import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/resumes_by_term/`, data);
};

const getAll = async () => {
  return (await httpClient.get(`${process.env.REACT_APP_API_HOST}/resumes_by_term/`).then(res => res.data));
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/resumes_by_term/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/resumes_by_term/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/resumes_by_term/${id}`);
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false
) => {
  let searchBy = '';

  if (search)
    searchBy = '&filter[search]=' + search

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/resumes_by_term/export` +
      `${query}&sort=${sortBy}` +
      `${searchBy}`,
      {
        responseType: 'blob',
      }
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/resumes_by_term` +
      `${query}&page[number]=${page}&page[size]=${pageSize}` +
      `&sort=${sortBy}` +
      `${searchBy}`,
    );
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  ajaxTable
}
