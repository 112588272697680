import React, { useEffect, useState } from 'react';
import { Modal, Typography, Radio, Layout, Row, notification } from 'antd';
import SpinLoad from '../SpinLoad';

import {
  filterActive,
  filterAssigned,
  getType,
  post,
  remove,
} from '../../api/module/project_assignments';
import CustomButton from '../CustomButton';
import MESSAGES from '../../enums/Messages';
import AjaxTable from '../AjaxTable';

const { Title, Text } = Typography;

const { Content } = Layout;

const ProjectAssignmentModal = ({
  visible = false,
  onCancel = () => {},
  userData = null,
}) => {
  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState([]);
  const [permType, setPermType] = useState(undefined);
  const [permLoading, setPermLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeFromProject = async (project_id) => {
    setLoading(true);
    try {
      await remove(project_id, userData.id);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
        duration: 2,
      });
      reloadSource();
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const addToAll = async () => {
    setLoading(true);
    try {
      await post({ user: userData.id, perm_type: permType });
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const addToSelected = async () => {
    setLoading(true);
    try {
      await post({ user: userData.id, pj_list: selected, perm_type: permType });
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      reloadSource();
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const projectColumns = [
    {
      title: 'Proyecto',
      dataIndex: 'nombre',
      key: 'nombre',
    },
  ];

  const assignedProjectColumns = [
    ...projectColumns,
    {
      title: 'Acción',
      dataIndex: 'id',
      render: (value) => {
        return (
          <Row justify="space-around">
            <CustomButton
              text="Desvincular"
              type="default"
              danger
              onClick={() => {
                removeFromProject(value);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const getPermType = async () => {
    try {
      setPermLoading(true);
      const res = await getType(userData.id);
      setPermType(res.data.perm_type);
      setPermLoading(false);
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      console.log(err);
      setPermLoading(false);
    }
  };

  const reloadSource = () => {
    setSelected([]);
    setReload({});
  };

  useEffect(() => {
    if (userData) getPermType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onTypeChange = (e) => {
    setPermType(e.target.value);
  };

  const rowSelection = {
    fixed: true,
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selected.map((v) => v.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelected(selectedRows);
    },
  };

  if (!userData?.id) {
    return null;
  }

  return (
    <Modal
      title={
        <Title level={4} style={{ marginBottom: 0 }}>
          Asignación de proyectos
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      width={'80%'}
      footer={null}
    >
      <Content style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>
        <Text strong>
          Asigne el(los) proyecto(s) en los que la persona participará
        </Text>

        <SpinLoad loading={permLoading}>
          <Radio.Group
            onChange={onTypeChange}
            style={{ width: '100%', marginTop: '1rem' }}
            value={permType}
          >
            <div
              style={{
                width: '100%',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              <Radio value={'all'}>
                Asignarle todos los proyectos a este usuario
              </Radio>
              <Radio value={'selected'}>
                Asignarle uno o más proyectos a este usuario
              </Radio>
            </div>
          </Radio.Group>
        </SpinLoad>
        <br />
        {permType === 'selected' ? (
          <SpinLoad loading={loading}>
            <AjaxTable
              columns={projectColumns}
              rowSelection={rowSelection}
              endpoint={filterActive}
              reloadSource={reload}
              query={userData.id}
              rowKey="id"
              scroll={{ x: 'max-content' }}
              searchInput
            />
            <Row justify={'space-around'} style={{ marginBottom: '2rem' }}>
              <CustomButton
                text="Vincular"
                onClick={addToSelected}
                disabled={selected.length === 0}
              />
            </Row>
            <Text strong>Proyectos asignados al usuario</Text>
            <AjaxTable
              columns={assignedProjectColumns}
              rowKey="id"
              scroll={{ x: 'max-content' }}
              endpoint={filterAssigned}
              reloadSource={reload}
              query={userData.id}
            />
            <Row justify={'space-around'}>
              <CustomButton
                text="Cerrar"
                type="default"
                danger
                onClick={onCancel}
              />
            </Row>
          </SpinLoad>
        ) : (
          <Row
            gutter={12}
            justify={'space-around'}
            style={{ marginTop: '2rem' }}
          >
            <CustomButton
              text="Guardar selección"
              onClick={addToAll}
              loading={loading}
            />
            <CustomButton
              type="default"
              danger
              text="Cerrar"
              onClick={onCancel}
            />
          </Row>
        )}
      </Content>
    </Modal>
  );
};
export default ProjectAssignmentModal;
