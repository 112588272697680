import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { notification, Row, Form, Select, Col, Space } from 'antd';

import WorkTeamSearchForm from '../../containers/WorkTeamSearchForm';
import MESSAGES from '../../enums/Messages';
import { CustomButton, CustomSelector } from '../../components';
//Api Imports
import { post } from '../../api/module/contractor_search';
import {
  assignFile,
  deleteFile,
  downloadFile,
  filesTable,
  post as postAssignment,
  postFile,
} from '../../api/module/project_position_assignments';
import { filter as fetchAnnouncement } from '../../api/module/announcement';
import { filter as filterAnnouncementRoles } from '../../api/module/announcement_rol';
import { filter as fetchSelectedUsers } from '../../api/module/announcement_selection';
import AjaxTable from '../../components/AjaxTable';
import { PersonSearchTable } from '../../components/PersonSearchTable';
import { catchException } from '../../utils/helpers/helpers';
import CustomUpload from '../../components/CustomUpload';
import { CheckOutlined } from '@ant-design/icons';

const WorkTeamSearch = ({
  assignmentType,
  cargoID,
  pendingMassive,
  setPendingMassive,
  personType = 1,
  afterAddPosition = () => {},
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [knowledgeArea, setKnowledgeArea] = useState(undefined);
  const [announcementFinish, setAnnouncementFinish] = useState(null);
  const [emptyText, setEmptyText] = useState('No hay datos');
  const [loadingFile, setLoadingFile] = useState(undefined);
  const [reloadFiles, setReloadFiles] = useState(false);

  const [form] = Form.useForm();

  const getFinishedAnnouncements = useCallback(async () => {
    try {
      const response = await fetchAnnouncement(
        '?filter[estado_convocatoria__sigla]=CV-F',
        1,
        1000,
        'numero',
      );
      const mapped = response.data.results.map((item) => {
        return { value: item.id, label: `${item.numero} - ${item.nombre}` };
      });
      setAnnouncementFinish(mapped);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setAnnouncementFinish(null);
    }
  }, []);

  useEffect(() => {
    getFinishedAnnouncements();
  }, [getFinishedAnnouncements]);

  const onRoleLoadFinish = async (data) => {
    setLoading(true);
    const selectedUsers = await fetchSelectedUsers(
      `get_selected_by_rol/${data.cargo}/${23}`,
    );

    setDataSource(selectedUsers.data.results);
    setLoading(false);
  };

  const selectedKnowledge = (value) => {
    setKnowledgeArea(value);
  };

  const onFinish = async (data) => {
    try {
      if (data.documento == '') {
        data.documento = undefined;
      }
      if (data.nombre == '') {
        data.nombre = undefined;
      }
      if (data.email == '') {
        data.email = undefined;
      }
      if (data.tipo_cargo == '') {
        data.tipo_cargo = undefined;
      }
      if (data.meses_experiencia == '') {
        data.meses_experiencia = undefined;
      }
      if (data.pregrado != null) {
        data.nivel_educativo = 7;
        delete data.pregrado;
      }
      if (data.especializacion != null) {
        data.nivel_educativo = 8;
        delete data.especializacion;
      }
      if (data.maestria != null) {
        data.nivel_educativo = 9;
        delete data.maestria;
      }
      if (data.doctorado != null) {
        data.nivel_educativo = 10;
        delete data.doctorado;
      }
      if (data.posdoctorado != null) {
        data.nivel_educativo = 11;
        delete data.posdoctorado;
      }
      data.area_conocimiento = knowledgeArea;
      data.tipo_persona = personType;
      data.termino = 0;
      setLoading(true);
      const response = await post(data);
      setLoading(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
        setEmptyText(
          'No se han encontrado elementos para la búsqueda realizada',
        );
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const rowSelection = {
    dataSelect,
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelect(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setDataSelect(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setDataSelect(selectedRows);
    },
  };

  const addToPosition = async () => {
    let promises = [];
    dataSelect.forEach(async (item) => {
      const data = {
        tercero: item.tercero,
        cargo: cargoID,
      };
      promises.push(postAssignment(data));
    });
    try {
      setLoading(true);
      const response = await Promise.all(promises);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setDataSelect([]);
        setDataSource([]);
      }
      setLoading(false);
      afterAddPosition();
    } catch (err) {
      setLoading(false);
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
    }
  };

  const assignMassive = async (id) => {
    try {
      setLoadingFile(1);
      await assignFile(id);
      setLoadingFile();
      setPendingMassive();
      setReloadFiles({});
      afterAddPosition();
    } catch (error) {
      setLoadingFile();
      catchException(error, MESSAGES.DESC_ERROR_POST_MSG);
    }
  };

  const deleteMassive = async (id) => {
    try {
      setLoadingFile(2);
      await deleteFile(id);
      setLoadingFile();
      setPendingMassive();
      setReloadFiles({});
    } catch (error) {
      setLoadingFile();
      catchException(error, MESSAGES.DESC_ERROR_POST_MSG);
    }
  };

  const uploadMassive = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('archivo', data.fileList[0].originFileObj);
      formData.append('cargo', cargoID);
      const res = await postFile(formData);
      setPendingMassive(res.data.id);
      setLoading(false);
      setReloadFiles({});
    } catch (error) {
      setLoading(false);
      catchException(error, MESSAGES.DESC_ERROR_POST_MSG);
    }
  };

  const downloadMassive = async (id) => {
    try {
      setLoadingFile(3);
      let response = await downloadFile(id);
      const file = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
      setLoadingFile();
    } catch (error) {
      setLoadingFile();
      catchException(error, MESSAGES.DESC_ERROR_POST_MSG);
    }
  };

  const filesColumns = [
    {
      title: 'Nombre del archivo',
      dataIndex: 'nombre',
      align: 'center',
    },
    {
      title: 'Fecha y hora cargue',
      dataIndex: 'created_at',
      render: (d) => moment(d).format('YYYY-MM-DD hh:mm'),
      align: 'center',
    },
    {
      title: 'Cantidad total de registros',
      dataIndex: 'registros',
      width: '8rem',
      align: 'center',
    },
    {
      title: 'Registros aprobados',
      dataIndex: 'aprobados',
      width: '8rem',
      align: 'center',
    },
    {
      title: 'Registros con errores',
      dataIndex: 'con_error',
      width: '8rem',
      align: 'center',
    },
    {
      title: 'Archivo revisado',
      key: 'download',
      width: '8rem',
      align: 'center',
      render: (_, record) => (
        <CustomButton
          text="Revisar"
          danger
          type="default"
          htmlType="button"
          onClick={() => downloadMassive(record.id)}
          loading={3 === loadingFile}
          disabled={loadingFile}
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_, record) =>
        record.asignado ? (
          <CustomButton
            type="default"
            htmlType="button"
            block
            text={
              <>
                <CheckOutlined /> <strong> Datos ya cargados </strong>
              </>
            }
          />
        ) : (
          <Space>
            {record.aprobados > 0 && (
              <CustomButton
                text="Asignar registros aprobados"
                htmlType="button"
                onClick={() => assignMassive(record.id)}
                loading={1 === loadingFile}
                disabled={loadingFile}
              />
            )}
            <CustomButton
              text="Eliminar archivo"
              danger
              type="default"
              htmlType="button"
              onClick={() => deleteMassive(record.id)}
              loading={2 === loadingFile}
              disabled={loadingFile}
            />
          </Space>
        ),
    },
  ];

  if (assignmentType === 3) {
    return (
      <>
        <b>
          Por favor descargue{' '}
          <a
            href={`${process.env.REACT_APP_API_DOMAIN}/static/download/Plantilla_Contratistas_ET.xlsx`}
            target="_blank"
          >
            aqui
          </a>{' '}
          la plantilla para el cargue masivo de contratistas
        </b>
        <br />
        <Form onFinish={(e) => uploadMassive(e.archivo)} layout="vertical">
          <CustomUpload
            file_type={3}
            upload_type={2}
            name="archivo"
            label="Seleccione el archivo Excel diligenciado a partir de la plantilla sugerida"
            buttonLabel="Seleccionar archivo a cargar"
            loading={loading}
            disabled={loadingFile || pendingMassive}
            required
          />
          <CustomButton
            loading={loading}
            disabled={loadingFile || pendingMassive}
            text="Cargar y verificar archivo"
          />
        </Form>
        <br />
        {!loading && (
          <AjaxTable
            tableTitle="Listado de archivos cargados"
            endpoint={filesTable}
            query={cargoID}
            columns={filesColumns}
            reloadSource={reloadFiles}
            pageSize={3}
          />
        )}
      </>
    );
  }

  return (
    <>
      {assignmentType && assignmentType == 1 ? (
        <WorkTeamSearchForm
          onFinish={onFinish}
          selectedKnowledge={selectedKnowledge}
          allowCreation={true}
          personType={personType}
        />
      ) : assignmentType == 0 ? (
        <Form layout="vertical" form={form} onFinish={onRoleLoadFinish}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                name="convocatoria"
                label="Lista de convocatorias terminadas"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Convocatoria..."
                  optionFilterProp="label"
                  options={announcementFinish}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle dependencies={['convocatoria']}>
                {({ getFieldValue, setFieldsValue }) => {
                  const conv = getFieldValue('convocatoria');
                  setFieldsValue({ cargo: undefined });
                  return (
                    <Form.Item
                      name="cargo"
                      label="Cargos de la convocatoria seleccionada"
                      rules={[
                        {
                          required: true,
                          message: 'Este campo es requerido',
                        },
                      ]}
                      valuePropName="initialValue"
                    >
                      <CustomSelector
                        valueName="id"
                        labelName="cargo"
                        endpointFetch={async () => {
                          return await filterAnnouncementRoles(
                            `/with_selected/?filter[convocatoria]=${conv}`,
                          );
                        }}
                        selectorProps={{
                          placeholder: 'Seleccione el cargo...',
                        }}
                        selectorKey={`r_${conv}`}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <CustomButton text="Buscar" />
        </Form>
      ) : null}
      <div className="box-table">
        <PersonSearchTable
          dataSource={dataSource}
          loading={loading}
          rowSelection={rowSelection}
          personType={personType}
          emptyText={emptyText}
        />
        <Row justify="center">
          <CustomButton
            loading={loading}
            htmlType="submit"
            text="Agregar contratista"
            onClick={() => addToPosition()}
          />
        </Row>
      </div>
    </>
  );
};
export default WorkTeamSearch;
