import React from 'react';
import { Route } from 'react-router-dom';
import { ForbiddenPage } from '../../pages/ErrorPages/index';

const CustomRoute = (props) => {
  if (props.perm()) {
    return <Route {...props} />;
  }
  return (
    <Route
      {...props}
      component={(props) => {
        return <ForbiddenPage {...props} />;
      }}
    />
  );
};

export default CustomRoute;
