export default {
  LOGIN: '/login',
  REGISTER: '/user/register',
  RECOVER_EMAIL: '/user/email',
  RECOVER_PASSWORD: '/user/:id/recover_password/:token',
  CHANGE_PASSWORD: '/user/:id/change_password',
  VERIFY: '/user/:id/verify/:token',
  HOME: '/',
  PROJECT: '/project',
  PROJECTS: '/projects',
  CLIENTS: '/clients',
  CLIENT: '/client',
  TERMS: '/terms',
  TERM: '/term',
  AREA: '/area',
  AREAS: '/areas',
  CURRENCY_TYPE: '/currency-type',
  CURRENCY_TYPES: '/currency-types',
  COST: '/cost',
  COSTS: '/costs',
  COSTS_FORM: '/costs-form',
  COUNTRIES: '/countries',
  COUNTRY: '/country',
  STATES: '/states',
  STATE: '/state',
  CITIES: '/cities',
  CITY: '/city',
  IDENTITY_TYPE: '/identity-type',
  IDENTITIES_TYPES: '/identities-types',
  DOCUMENT_TYPE: '/document-type',
  DOCUMENT_TYPES: '/document-types',
  TERM_PAYMENT: '/term-payment',
  TERM_PAYMENTS: '/term-payments',
  TERM_DIFFERENTIATED_VALUES: '/term-differentiated-value',
  POLICY: '/policy',
  POLITICS: '/politics',
  RESPONSIBILITIES: '/responsabilities',
  PRODUCTS: '/products',
  NATURAL_PERSONS: '/natural-persons',
  NATURAL_PERSON: '/natural-person',
  LEGAL_PERSONS: '/legal-persons',
  LEGAL_PERSON: '/legal-person',
  ORGANIZATIONS: '/organizations',
  ORGANIZATION: '/organization',
  CONTRACT_TYPE: '/contract-type',
  TEMPLATE: '/template',
  TEMPLATES: '/templates',
  PAYRESPONSIBILITYPRODUCT: '/pay-responsibility-product',
  PROVEEDORESCONSULTORES: '/proveedores-consultores',
  CONTRACT_POLICIES: '/contract-policies',
  PROJECT_COST_CENTER: '/project-cost-center',
  ORGANIZATIONS_TYPES: '/organizations-types',
  ORGANIZATION_TYPE: '/organization-type',
  SEARCHCONTRACTOR: '/search-contractor',
  LABOR_CONTRACTORS: '/labor-contractors',
  EDUCATION_CONTRACTORS: '/education-contractors',
  TERM_NATURAL_PERSON: '/term-natural-person',
  TERM_LEGAL_PERSON: '/term-legal-person',
  OTHER_CERTIFICATIONS: '/other-certifications',
  EXPERIENCIE_ICFES: '/experiencie-icfes',
  PUBLIC_ANNOUNCEMENT: '/public-announcement',
  HOME_ANNOUNCEMENT: '/',
  ANNOUNCEMENT: '/announcements',
  ANNOUNCEMENT_NEW: '/new-announcement',
  ANNOUNCEMENT_POSITION: '/announcement-position',
  ANNOUNCEMENT_PROGRESS: '/announcement-progress',
  ANNOUNCEMENT_DETAILS: '/announcement-details',
  ANNOUNCEMENT_FINISH: '/announcement-finish',
  ANNOUNCEMENT_REJECTED: '/announcement-rejected',
  ANNOUNCEMENT_SHOW: '/announcement-show',
  ANNOUNCEMENT_HISTORY: '/announcement-history',
  ANNOUNCEMENT_PRESELECTION: '/announcement-preselection',
  ANNOUNCEMENT_SELECTION: '/announcement-selection',
  ANNOUNCEMENT_SELECTION_VALIDATION: '/announcement-selection-validation',
  ANNOUNCEMENT_SELECTION_VALIDATION_EDUCATION:
    '/announcement-selection-validation-education',
  ANNOUNCEMENT_SELECTION_VALIDATION_LABORAL:
    '/announcement-selection-validation-laboral',
  ANNOUNCEMENT_SELECTION_VALIDATION_RESTRICTIVE:
    '/announcement-selection-validation-restrictive',
  ANNOUNCEMENT_SELECTION_VALIDATION_TESTS:
    '/announcement-selection-validation-tests',
  ANNOUNCEMENT_SELECTION_VALIDATION_DOCUMENTOS:
    '/announcement-selection-validation-documents',
  ANNOUNCEMENT_SELECTION_EMAIL: '/announcement-selection-email',
  ANNOUNCEMENT_PRESELECTION_VALIDATION: '/announcement-preselection-validation',
  ANNOUNCEMENT_PRESELECTION_VALIDATION_EDUCATION:
    '/announcement-preselection-validation-education',
  ANNOUNCEMENT_PRESELECTION_VALIDATION_LABORAL:
    '/announcement-preselection-validation-laboral',
  ANNOUNCEMENT_PRESELECTION_VALIDATION_DOCUMENTOS:
    '/announcement-preselection-validation-documentos',
  ANNOUNCEMENT_PRESELECTION_EMAIL: '/announcement-email',
  ANNOUNCEMENT_ADJUST_INFORMATION: '/announcement-adjust-information',
  ADDITIONAL_DOCUMENTS_LEGAL: '/additional-documents-legal',
  PROJECT_TEAM: '/project-team',
  ROLES_MANAGEMENT: '/roles-management',
  WORK_TEAM: '/work-team',
  PDF_VIEWER: '/pdf-viewer',
  TDR_HOME: '/home-tdr',
  PROJECT_CATEGORIES: '/project-categories',
  PROJECT_CATEGORY: '/project-category',
  HELP_FORMAT: '/help-format',
  RESTRICTIVE_LIST: '/restrictive-list',
  RESTRICTIVE_LIST_SEARCH: '/restrictive-list-search',
  RESUME_SEARCH: '/resume-search',
  DOCUMENT_VALIDATION_PRECONTRACTUAL: '/document-validation-precontractual',
  DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL:
    '/document-validation-precontractual-email',
  DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL_HISTORY:
    '/document-validation-precontractual-email-history',
  DOCUMENT_VALIDATION_CONTRACTUAL: '/document-validation-contractual',
  DOCUMENT_VALIDATION_CONTRACTUAL_EMAIL:
    '/document-validation-contractual-email',
  DOCUMENT_VALIDATION_CONTRACTUAL_HISTORY:
    '/document-validation-contractual-history',
  DOCUMENT_VALIDATION_CONTRACTUAL_RESUME:
    '/document-validation-contractual-resume',
  DOCUMENT_VALIDATION_SHOW: '/document-validation-show',
  DOCUMENT_VALIDATION_DIRECTOR: '/document-validation-director',
  DOCUMENT_VALIDATION_PARTIAL: '/document-validation-partial',
  DOCUMENT_VALIDATION_PARTIAL_EMAIL: '/document-validation-partial-email',
  DOCUMENT_VALIDATION_PARTIAL_EMAIL_HISTORY:
    '/document-validation-partial-email-history',
  TERMS_OF_REFERENCE_SHOW: '/tdr-approved-show',
  EMPLOYEES: '/employees',
  REJECT_TDR: '/reject-tdr',
  RESUME_CONTRACTS: '/resume-contracts',
  RESUME_CONTRACT: '/resume-contract',
  RESUME_CONTRACT_SEARCH: '/resume-contract-search',
  CONTRACT_MODIFICATIONS: '/contract-modifications',
  CONTRACT_MASS_MODIFICATIONS: '/contract-mass-modifications',
  MODIFICATION_CHANGE_TERM: '/modification-change-term',
  MODIFICATION_RESUME_CONTRACT: '/modification-resume-contract',
  MODIFICATION_DOCUMENTS: '/modification-documents',
  MODIFICATION_CHANGE_RESPONSIBILITIES: '/modification-change-responsibilities',
  MODIFICATION_CHANGE_PRODUCTS: '/modification-change-products',
  MODIFICATION_BUDGET: '/modification-budget',
  MODIFICATION_PAYMENTS: '/modification-payments',
  MASS_MODIFICATION_CHANGE_TERM: '/mass-modification-change-term',
  MASS_MODIFICATION_BUDGET: '/mass-modification-budget',
  MASS_MODIFICATION_CHANGE_RESPONSIBILITIES:
    '/mass-modification-change-responsibilities',
  MASS_MODIFICATION_CHANGE_PRODUCTS: '/mass-modification-change-products',
  MASS_MODIFICATION_PAYMENTS: '/mass-modification-payments',
  MASS_MODIFICATION_DOCUMENTS: '/mass-modification-documents',
  MODIFICATION_DIRECTOR: '/modification-director',
  MODIFICATION_DIRECTOR_DOCUMENTS: '/modification-director-documents',
  MODIFICATION_SHOW: '/modification-show',
  INSURANCE: '/insurance',
  CONTRACTOR_INSURANCE: '/contractor-insurance',
  POSTCONTRACTUAL: '/postcontractual',
  MASS_POSTCONTRACTUAL: '/mass-postcontractual',
  POSTCONTRACTUAL_DIRECTOR: '/postcontractual-director',
  POSTCONTRACTUAL_CONTRACTOR: '/postcontractual-contractor',
  REPORTS_TDR: '/reports',
  SIGAAPP: '/sigaapp',
  ADMIN: '/admin',
};
