import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Skeleton, Modal, Layout, Badge, Tabs } from 'antd';

import AnnouncementSelectionForm from '../../../containers/AnnouncementSelectionForm/index';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import Preselected from '../Validation/Preseleted/index';
import Selected from '../Validation/Selected/index';
import AjustInformation from '../Validation/AdjustInformation/index';
import Rejected from '../Validation/Rejected/index';
import ValidationEmailHistory from '../../../components/ValidationEmailHistory';

import { get as Announcement } from '../../../api/module/announcement';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { filter as getEmailsById } from '../../../api/module/announcement_selection_email';

import './style.scss';
import { counters as AnnouncementSelectionCounters } from '../../../api/module/announcement_selection';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const AnnouncementValidation = ({ history, match }) => {
  const [announcement_id] = useState(match.params.announcement_id);
  const [preselection_id] = useState(match.params.announcement_rol_id);
  const [dataSource, setDataSource] = useState(null);
  const [loadingForm, setLoadingForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [counters, setCounters] = useState(null);
  const [activeKey, setActiveKey] = useState('preselected');
  const [selected, setSelected] = useState(null);
  const [preselected, setPreselected] = useState(null);
  const [pending, setPending] = useState(null);
  const [rejected, setReject] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalHistoryData, setModalHistoryData] = useState(null);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);

  const breadcrumbItems = [
    {
      text: 'Seleccionados',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
    },
  ];

  const getDataSource = async () => {
    try {
      setIsLoading(true);
      const response = await Announcement(announcement_id);
      const responseRol = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseRol.data.cargo;
      setDataSource(response.data);
      setIsLoading(false);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getCounters = async () => {
    const response = await AnnouncementSelectionCounters(preselection_id);
    setCounters(response.data.results);
  };

  const loadData = async () => {
    try {
      await Promise.all([getDataSource(), getCounters()]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    setLoadingForm(true);
    await loadData();
    setLoadingForm(false);

    setPreselected([]);
  }, []);

  const handleListType = async (newListType) => {
    setPreselected(null);
    setSelected(null);
    setPending(null);
    setReject(null);

    if (newListType === 'preselected') setPreselected([]);

    if (newListType === 'selected') setSelected([]);

    if (newListType === 'adjust_information') setPending([]);

    if (newListType === 'rejected') setReject([]);

    setActiveKey(newListType);
    await getCounters();
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección - Validación
      </Title>
      <Skeleton loading={loadingForm} active>
        {dataSource != null && (
          <AnnouncementSelectionForm
            initialValues={dataSource}
            useDescriptions={true}
            descriptionsTitle="Información de la convocatoria"
            descriptionsColumns={3}
          />
        )}
        <CustomButton
          style={{ marginTop: 10 }}
          htmlType={'htmlButton'}
          onClick={(event) => {
            setPreselected([]);

            setTimeout(async () => {
              await handleListType('preselected');
            }, 500);
          }}
          text={'Actualizar registros'}
        />
        {dataSource != null && (
          <Tabs
            type="card"
            defaultActiveKey="preselected"
            activeKey={activeKey}
            onChange={handleListType}
            style={{ marginTop: 15 }}
            destroyInactiveTabPane={true}
          >
            <TabPane
              tab={
                <Paragraph>
                  Preseleccionados
                  <Badge showZero count={counters ? counters.preselected : 0} />
                </Paragraph>
              }
              key="preselected"
            >
              <Preselected
                dataSource={preselected}
                announcement_id={announcement_id}
                announcement_rol_id={match.params.announcement_rol_id}
                loading={isLoading}
                loadData={loadData}
              />
            </TabPane>
            <TabPane
              tab={
                <Paragraph>
                  Seleccionados
                  <Badge showZero count={counters ? counters.selected : 0} />
                </Paragraph>
              }
              key="selected"
            >
              <Selected
                dataSource={selected}
                announcement_id={announcement_id}
                announcement_rol_id={match.params.announcement_rol_id}
                loading={isLoading}
                person_id={dataSource.tercero}
              />
            </TabPane>
            <TabPane
              tab={
                <Paragraph>
                  Ajustar información
                  <Badge showZero count={counters ? counters.pending : 0} />
                </Paragraph>
              }
              key="adjust_information"
            >
              <AjustInformation
                dataSource={pending}
                announcement_id={announcement_id}
                announcement_data={dataSource}
                announcement_rol_id={match.params.announcement_rol_id}
                loading={isLoading}
                person_id={dataSource.tercero}
                historyLoading={historyLoading}
                showHistory={async (record) => {
                  setHistoryLoading(true);
                  let response = await getEmailsById(
                    `?filter[convocatoria]=${record.convocatoria}&filter[convocatoria_rol]=${record.convocatoria_rol}&filter[tercero]=${record.tercero}`,
                  );
                  setModalData(record);
                  setModalHistoryData(response.data.results);
                  setHistoryVisible(true);
                  setHistoryLoading(false);
                }}
              />
            </TabPane>
            <TabPane
              tab={
                <Paragraph>
                  Rechazados
                  <Badge showZero count={counters ? counters.reject : 0} />
                </Paragraph>
              }
              key="rejected"
            >
              <Rejected
                dataSource={rejected}
                announcement_id={announcement_id}
                announcement_rol_id={match.params.announcement_rol_id}
                loading={isLoading}
                person_id={dataSource.tercero}
              />
            </TabPane>
          </Tabs>
        )}
        <CustomButton
          type={'default'}
          className={'back-button'}
          htmlType="button"
          text={
            <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`}>Volver</Link>
          }
        />
      </Skeleton>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Histórico de correos electrónicos enviados - Selección
          </Title>
        }
        visible={historyVisible}
        onCancel={() => {
          setHistoryVisible(false);
        }}
        cancelText="Cerrar"
        width={1000}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ValidationEmailHistory
          type="selection"
          validationData={modalData}
          dataSource={modalHistoryData}
        />
      </Modal>
    </Content>
  );
};

export default AnnouncementValidation;
