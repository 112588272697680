import React from 'react';
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Space,
  Layout,
} from 'antd';
import moment from 'moment';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';

const { Title } = Typography;
const { Content } = Layout;

const OtherCertificationsForm = ({
  ids,
  onFinish,
  initialValues,
  back,
  loading,
  certification,
  showClick,
  isLoadingDoc,
}) => {
  const breadcrumbItems = [
    {
      text: 'Proveedores y Consultores - Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSONS}`,
    },
    {
      text: 'Registro de Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSON}/${ids.person_id}`,
    },
    {
      text: 'Otras Certificaciones',
    },
  ];

  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Otras certificaciones (Opcional)
      </Title>
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ ...initialValues }}
        autoComplete="off"
        encType="multipart/form-data"
        validateMessages={validateMessages}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="institucion"
              label="Institución"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear placeholder="Institución" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nombre"
              label="Nombre certificación"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear placeholder="Nombre certificación" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="fecha_terminacion"
              label="Fecha de terminación"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} align={'middle'}>
          <Col span={20}>
            <CustomUpload
              upload_type={2}
              file_type={1}
              required={certification}
              name="certificacion"
              label="Cargar certificación o título"
              tooltip="Tamaño del archivo máximo de 2MB en formato PDF"
            />
          </Col>
          <Col span={4}>
            {initialValues != null && initialValues.documento_nombre && (
              <CustomButton
                style={{ marginTop: 5 }}
                type="default"
                htmlType="button"
                text="Ver"
                loading={isLoadingDoc}
                onClick={() => {
                  showClick(initialValues.documento_nombre);
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Space>
                <CustomButton loading={loading} text="Guardar" />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default OtherCertificationsForm;
