import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Tabs,
  Row,
  Col,
  Typography,
  Space,
  notification,
  Skeleton,
  Badge,
  Modal,
  Layout,
  Form,
} from 'antd';
import { Link } from 'react-router-dom';

import MESSAGES from '../../enums/Messages';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';

import { filter as filterStatus } from '../../api/module/status_process';
import {
  filter as filterSVDP,
  getCounters,
  patch as patchSVDP,
} from '../../api/module/term_document_contract_validation';

import './style.scss';
import { get as getTerm } from '../../api/module/terms';
import SelectProjects from '../../components/SelectProjects';
import SelectPositionRole from '../../components/SelectPositionRole';
import SelectTermByUniqueID from '../../components/SelectTermByUniqueID';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Seguimiento Validación Documentación Parcial',
  },
];

const DocumentValidationPartialHomeSelectors = ({ match, setTermID }) => {
  const [loaded, setLoaded] = useState(false);
  const [projectID, setProjectID] = useState(undefined);
  const [roleData, setRoleData] = useState(undefined);

  useEffect(() => {
    if (match.params.term_id) {
      async function loadTerm(term_id) {
        const res = await getTerm(term_id);
        setLoaded(res.data);
        setProjectID(res.data.proyecto);
      }
      loadTerm(match.params.term_id);
    } else {
      setLoaded({});
    }
  }, [match]);

  function changeRoleData(newData, clearOther = true) {
    if (clearOther) {
      setTermID(undefined);
      setLoaded({});
    }
    setRoleData(newData);
  }

  function changeProjectID(newID, clearOther = true) {
    if (clearOther) {
      setRoleData(undefined);
      setTermID(undefined);
      setLoaded({});
    }
    setProjectID(newID);
  }

  return (
    <Content>
      {loaded ? (
        <Space
          direction={'vertical'}
          style={{ width: '100%' }}
          key={match.params.term_id}
        >
          <Form>
            <Row gutter={12}>
              <Col span={6}>
                <Title level={4}>Proyecto a revisar</Title>
              </Col>
              <Col span={18}>
                <Form.Item>
                  <SelectProjects
                    onChange={changeProjectID}
                    proyecto={loaded.proyecto}
                  />
                </Form.Item>
              </Col>
            </Row>
            {projectID && (
              <Row gutter={12}>
                <Col span={6}>
                  <Title level={4}>Cargo/rol a revisar</Title>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    <SelectPositionRole
                      onChangeData={changeRoleData}
                      onLoad={(data) => changeRoleData(data, false)}
                      project_id={projectID}
                      cargo={loaded.cargo}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {roleData && (
              <Row gutter={12}>
                <Col span={6}>
                  <Title level={4}>Identificador del término a revisar</Title>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    <SelectTermByUniqueID
                      onChange={setTermID}
                      cargo_id={roleData.id}
                      onLoad={(data = {}) => setTermID(data.id)}
                      term={loaded.id}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
          {roleData && (
            <Descriptions layout="vertical" column={1} bordered>
              <Descriptions.Item
                label={'Requerimientos de educación / formación'}
              >
                {roleData.educacion_requerida}
              </Descriptions.Item>
              <Descriptions.Item label={'Requerimientos de experiencia'}>
                {roleData.experiencia_requerida}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Space>
      ) : (
        <Skeleton paragraph active loading={!loaded} />
      )}
    </Content>
  );
};

const DocumentValidationPartialHome = ({ match }) => {
  const [termID, setTermID] = useState();

  const [counters, setCounters] = useState({
    review: 0,
    'TDR-SVDP-G-AC': 0,
    'TDR-SVDP-G-AI': 0,
  });
  const [stateOpt, setStateOpt] = useState([]);

  const [acceptableSubs, setAcceptableSubs] = useState([]);

  const [activeTab, setActiveTab] = useState('review');

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await filterStatus('/?filter[sigla.icontains]=TDR-SVDP-G-');
        const resSub = await filterStatus(
          '/?filter[sigla.icontains]=TDR-SVDP-M-',
        );
        const states = res.data.results.map((x) => {
          return { label: x.nombre, value: x.id, sigla: x.sigla };
        });
        const subStates = resSub.data.results.reduce((prev, x) => {
          prev[x.sigla] = x.id;
          return prev;
        }, {});
        setStateOpt(states);

        setAcceptableSubs(subStates);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }

    fetchData();
  }, []);

  const onStatusSave = (id, newState) => {
    async function putStatus(id, newState) {
      try {
        await patchSVDP(id, { estado_validacion_general: newState.value });
        setActiveTab(newState.sigla);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    }
    putStatus(id, newState);
  };

  const extraColumnsToCheck = [
    {
      title: 'Validar Documentos',
      key: 'validate_button',
      render: (_, record) => (
        <CustomButton
          block
          type="primary"
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}-validar/${record.id}`}
            >
              Validar
            </Link>
          }
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        let thisStates;
        if (record.tercero.tipo_persona_nombre === 'Natural')
          thisStates = [
            record.estado_validacion_basicos,
            record.estado_validacion_formacion,
            record.estado_validacion_experiencia,
            record.estado_validacion_icfes,
          ];
        else thisStates = [record.estado_validacion_basicos];
        return (
          <StateSelector
            // defState={record.estado_validacion_general}
            thisStates={thisStates}
            subStates={acceptableSubs}
            onSave={(newStatus) => onStatusSave(record.id, newStatus)}
            options={stateOpt}
          />
        );
      },
    },
  ];

  const extraColumnsAdjust = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <>
          <CustomButton
            block
            type={'default'}
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL_EMAIL}/${record.id}`}
              >
                Generar Correo
              </Link>
            }
          />
          {record.estado_envio && 'Enviado'}
        </>
      ),
    },
    {
      title: 'Historial de correos',
      key: 'datetime',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL_EMAIL_HISTORY}/${record.id}`}
              target="_blank"
            >
              Historial
            </Link>
          }
        />
      ),
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}-validar/${record.id}`}
            >
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Seguimiento Validación Documentación Parcial
      </Title>
      <DocumentValidationPartialHomeSelectors
        match={match}
        setTermID={setTermID}
      />
      {termID && (
        <>
          <br></br>
          <CustomButton
            text="Actualizar registros"
            onClick={() => {
              window.location.reload();
            }}
          />
          <div className="box-table">
            <Tabs
              defaultActiveKey={'review'}
              activeKey={activeTab}
              onTabClick={(key, _) => {
                setActiveTab(key);
              }}
              destroyInactiveTabPane={true}
              type="card"
              key={termID}
            >
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados parciales
                    <Badge showZero count={counters.review} />
                  </Paragraph>
                }
                key="review"
              >
                <ValidationTable
                  setCounters={setCounters}
                  extraColumns={extraColumnsToCheck}
                  endpointFetch={async (page, pageSize) => {
                    return await filterSVDP(
                      `/?include=extra_counters&filter[contrato__termino]=${termID}&filter[estado_validacion_general__isnull]=True`,
                      page,
                      pageSize,
                    );
                  }}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Ajustar información
                    <Badge showZero count={counters['TDR-SVDP-G-AI']} />
                  </Paragraph>
                }
                key="TDR-SVDP-G-AI"
              >
                <ValidationTable
                  setCounters={setCounters}
                  extraColumns={extraColumnsAdjust}
                  endpointFetch={async (page, pageSize) => {
                    return await filterSVDP(
                      `/?include=extra_counters&filter[contrato__termino]=${termID}&filter[estado_validacion_general__sigla]=TDR-SVDP-G-AI`,
                      page,
                      pageSize,
                    );
                  }}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados completos
                    <Badge showZero count={counters['TDR-SVDP-G-AC']} />
                  </Paragraph>
                }
                key="TDR-SVDP-G-AC"
              >
                <ValidationTable
                  setCounters={setCounters}
                  extraColumns={extraColumnsApproved}
                  endpointFetch={async (page, pageSize) => {
                    return await filterSVDP(
                      `/?include=extra_counters&filter[contrato__termino]=${termID}&filter[estado_validacion_general__sigla]=TDR-SVDP-G-AC`,
                      page,
                      pageSize,
                    );
                  }}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Content>
  );
};

const ValidationTable = ({
  extraColumns = [],
  endpointFetch,
  defPageSize = 10,
  setCounters = () => {},
}) => {
  const columns = [
    {
      title: 'Tipo persona',
      dataIndex: ['tercero', 'tipo_persona_nombre'],
      fixed: true,
    },
    {
      title: 'Nombre completo/Razón social',
      dataIndex: ['tercero', 'nombre_completo'],
      fixed: true,
    },
    {
      title: 'Tipo doc',
      dataIndex: ['tercero', 'tipo_identificacion_nombre'],
    },
    {
      title: 'Nro. Documento',
      dataIndex: ['tercero', 'numero_identificacion'],
    },
    {
      title: 'Teléfono',
      dataIndex: ['tercero', 'numero_celular'],
    },
    {
      title: 'Correo Electrónico',
      dataIndex: ['tercero', 'notificacion_email'],
    },
  ];

  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState(undefined);

  const onPageChange = async (page, pageSize) => {
    try {
      const res = await endpointFetch(page, pageSize);
      setDataSource(res.data.results);
      setPagination(res.data.meta.pagination);
      setCounters(res.data.meta.extra_counters);
    } catch (error) {
      console.log(error);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    onPageChange(1, defPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinLoad loading={!pagination}>
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={[...columns, ...extraColumns]}
        pagination={false}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de Contratistas
          </Paragraph>
        )}
      />
      {pagination && (
        <Pagination
          defaultCurrent={1}
          current={pagination.page}
          onChange={onPageChange}
          total={pagination.count}
          pageSize={defPageSize}
          style={{ textAlign: 'end', marginTop: '10px' }}
        />
      )}
    </SpinLoad>
  );
};

const StateSelector = ({
  defState = undefined,
  onSave = () => {},
  options = [],
  thisStates = undefined,
  subStates = undefined,
}) => {
  const [state, setState] = useState(defState);

  function onSelfSave() {
    const selectedS = options.find((x) => x.value === state);
    const anyEmpty = thisStates.some((x) => !x);
    const fullyAcceptable = thisStates.every(
      (x) => subStates['TDR-SVDP-M-AC'] === x,
    );
    const onlyAJ = thisStates.some((x) => x === subStates['TDR-SVDP-M-AI']);
    if (!fullyAcceptable && selectedS.sigla === 'TDR-SVDP-G-AC') {
      Modal.warning({
        title: 'Estado inválido',
        content: 'El registro tiene estados diferentes a Aprobado completo.',
      });
      return;
    }
    if (fullyAcceptable && selectedS.sigla !== 'TDR-SVDP-G-AC') {
      Modal.warning({
        title: 'Estado inválido',
        content:
          'El registro tiene todos sus estados en Aprobado completo, solo se puede guardar `Aprobado completo`',
      });
      return;
    }
    if (onlyAJ && selectedS.sigla !== 'TDR-SVDP-G-AI') {
      Modal.warning({
        title: 'Estado inválido',
        content:
          'El registro tiene estados en Ajustar Información, solo se puede guardar `Devolver`',
      });
      return;
    }
    if (anyEmpty) {
      Modal.confirm({
        title: 'Estado inválido',
        content: 'Hay módulos sin estado. ¿Desea continuar?',
        okText: 'Si',
        cancelText: 'No',
        onOk: () => onSave(selectedS),
      });
      return;
    }
    onSave(selectedS);
  }

  return (
    <Space direction="horizontal">
      <Select
        onChange={setState}
        defaultValue={defState}
        placeholder="Seleccione el estado..."
        options={options}
      />
      <Popconfirm
        onConfirm={() => {
          onSelfSave();
        }}
        title={MESSAGES.MSG_SAVE_STATE}
      >
        <CustomButton type="default" text="Guardar" disabled={!state} />
      </Popconfirm>
    </Space>
  );
};

export default DocumentValidationPartialHome;
