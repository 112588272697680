import httpClient from '../httpClient';

const verify = async (id, data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/users/${id}/activate/`, data);
};

const resend = async (id) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/users/${id}/resend_activation/`);
};

export {
  verify,
  resend,
}
