import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Col,
  Layout,
  notification,
  Row,
  Select,
  Skeleton,
  Alert,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd';

import LoadTemplateForm from '../../containers/LoadTemplateForm';
import LoadTemplateProcessForm from '../../containers/LoadTemplateProcessForm';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import PdfViewer from '../../components/PdfViewer';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import InternalLinks from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import { get as getTemplateType } from '../../api/module/template_types';
import {
  changeStatus,
  generateWordTemplateURL as generateTemplateURL,
  postTemplateDocuments,
  templateFiltered,
} from '../../api/module/templates';

import { templateProcess } from '../../api/module/template_process';

import { get as getHiringType } from '../../api/module/hiring_types';
import {
  get as getHiringStage,
  getAll as getAllHiringStages,
} from '../../api/module/hiring_stages';
import { get as getPersonType } from '../../api/module/person_types';
import { get as getContractType } from '../../api/module/contract_types';
import { get as getDocument } from '../../api/module/document_repository';

import './style.scss';
import Paragraph from 'antd/es/typography/Paragraph';

const { Content } = Layout;
const { Title } = Typography;

const { TabPane } = Tabs;

const TemplateTable = memo(
  ({
    dataSource,
    loading,
    hiring_type_id,
    hiring_stage_id,
    person_type_id,
    contract_type_id,
    template_id,
    showCLick,
    onRefresh /*= () => {},*/,
  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalNewVisible, setIsModalNewVisible] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const generateTemplatePDF = async (version) => {
      try {
        setPreviewLoading(true);
        let response = await generateTemplateURL({
          template: template_id,
          version: version,
          person_type: person_type_id,
        });

        showCLick(response.data.url);
        setPreviewLoading(false);
      } catch (exception) {
        let description;
        setPreviewLoading(false);

        if (exception.response.data.errors) {
          const errors = exception.response.data.errors;
          description = errors.shift().detail;
        } else {
          description =
            'No se pudo generar el PDF. Revise los nombres de las variables utilizadas.';
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 2,
        });
      }
    };

    const getDocumentURL = async (document) => {
      try {
        setDownloadLoading(true);
        let response = await getDocument(document);
        window.open(response.data.url);
        setDownloadLoading(false);
      } catch (exception) {
        let description;
        setDownloadLoading(false);

        if (exception.response.data.errors) {
          const errors = exception.response.data.errors;
          description = errors.shift().detail;
        } else {
          description = 'No se pudieron obtener los registros.';
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 2,
        });
      }
    };

    const changeTemplateStatus = async (template) => {
      try {
        setStatusLoading(true);
        let response = await changeStatus(template.id);
        template.estado_nombre = response.data.estado_nombre;
        setStatusLoading(false);
      } catch (exception) {
        let description;
        setStatusLoading(false);

        if (exception.response.data.errors) {
          const errors = exception.response.data.errors;
          description = errors.shift().detail;
        } else {
          description = 'No se pudieron obtener los registros.';
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 2,
        });
      }
    };

    const setStageBack = () => {
      window.location.href = `${INTERNAL_LINKS.TEMPLATE}/${hiring_type_id}/0/${contract_type_id}/${person_type_id}/0`;
    };

    const columns = [
      {
        title: 'Fecha',
        dataIndex: 'created_at',
        render: (_, record) =>
          dataSource.length >= 1
            ? moment(record.created_at).format('YYYY-MM-DD')
            : null,
      },
      {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo',
      },
      {
        title: 'Versión',
        dataIndex: 'version',
        key: 'version',
        align: 'center',
        defaultSortOrder: 'descend',
      },
      {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
      },
      {
        title: 'Acciones',
        dataIndex: 'ver',
        width: 120,
        fixed: 'right',
        render: (value, record, index) => {
          return (
            <Space direction="vertical">
              <CustomButton
                block
                text="Ver PDF"
                loading={previewLoading}
                onClick={() => generateTemplatePDF(record.version)}
              />
              <CustomButton
                block
                loading={downloadLoading}
                type="default"
                text={'Descargar'}
                onClick={() => getDocumentURL(record.documento)}
              />
              {index === 0 && (
                <CustomButton
                  block
                  danger
                  type={'default'}
                  loading={statusLoading}
                  text={record.estado_nombre}
                  onClick={() => changeTemplateStatus(record)}
                />
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <Content>
        <Table
          rowKey="id"
          loading={loading}
          scroll={{ x: 300 }}
          dataSource={dataSource}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Plantillas
            </Paragraph>
          )}
        />
        <Space style={{ marginTop: 10 }}>
          <CustomButton
            onClick={() => setIsModalVisible(true)}
            text="Cargar plantilla"
          />
          {hiring_type_id === 0 &&
            hiring_stage_id === 0 &&
            contract_type_id === 0 && (
              <Link to={InternalLinks.TEMPLATES}>
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={'Volver a plantillas'}
                />
              </Link>
            )}
          {hiring_type_id !== 0 &&
            hiring_stage_id !== 0 &&
            contract_type_id !== 0 && (
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                onClick={() => setStageBack()}
                text={'Volver a etapa de contratación'}
              />
            )}
        </Space>
        <LoadTemplateForm
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          hiring_type_id={hiring_type_id}
          hiring_stage_id={hiring_stage_id}
          template_id={template_id}
          contract_type_id={contract_type_id}
          person_type_id={person_type_id}
          isUpdate={!(dataSource && dataSource.length === 0)}
          onRefresh={onRefresh}
        />
      </Content>
    );
  },
);

const TemplatePreList = ({
  SearchTemplate,
  setTemplateTypeId,
  loading,
  onRefresh,
  hiring_type_id,
  person_type_id,
  contract_id,
  template_id,
}) => {
  let history = useHistory();

  const [isModalNewVisible, setIsModalNewVisible] = useState(false);
  const [hiringStage, setHiringStage] = useState(null);
  const [hiring_stage_id, setHiringStageID] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [hiringStageDataSource, setHiringStageDataSource] = useState([]);

  useEffect(async () => {
    await getHiringTypes();
    await getHiringStageSource();
  }, []);

  const setTab = (key, event) => {
    console.log(key);
    setDataSource(
      hiringStageDataSource.filter(
        (item) => item.etapa_contratacion === parseInt(key),
      ),
    );
    setHiringStageID(key);
  };

  const getHiringTypes = async () => {
    try {
      setLoadingTemplates(true);
      let response = await getAllHiringStages();
      setLoadingTemplates(false);

      setHiringStage(response.data.results);
    } catch (exception) {
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const getHiringStageSource = async () => {
    try {
      const response = await templateProcess(
        true,
        false,
        hiring_type_id,
        0,
        person_type_id,
        contract_id,
        template_id,
      );

      setHiringStageDataSource(response.data.results);
      setDataSource(
        response.data.results.filter((item) => item.etapa_contratacion === 1),
      );

      setHiringStageID(1);
    } catch (exception) {
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const getHiringStageSourceR = async () => {
    try {
      const response = await templateProcess(
        true,
        false,
        hiring_type_id,
        0,
        person_type_id,
        contract_id,
        template_id,
      );

      setHiringStageDataSource(response.data.results);
      setDataSource(
        response.data.results.filter((item) => item.etapa_contratacion === 2),
      );

      setHiringStageID(2);
    } catch (exception) {
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const setTemplateUrl = (template_type_id) => {
    window.location.href = `${INTERNAL_LINKS.TEMPLATE}/${hiring_type_id}/${hiring_stage_id}/${contract_id}/${person_type_id}/${template_type_id}`;
  };

  return (
    <Skeleton loading={loadingTemplates} active>
      <Tabs defaultActiveKey="1" onTabClick={setTab} type="card">
        {hiringStage != null &&
          hiringStage.map((item) => (
            <TabPane tab={item.nombre} key={item.id}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Paragraph className={'mb-0'}>
                    Plantillas {item.nombre.toLowerCase()}
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={
                      'Seleccione un tipo de plantilla ' +
                      item.nombre.toLowerCase()
                    }
                    allowClear
                    showSearch
                    onChange={(value) => {
                      setTemplateUrl(value);
                    }}
                    optionFilterProp="children"
                  >
                    {dataSource &&
                      dataSource.map((item) => {
                        return (
                          <Select.Option
                            key={`template-key-${item.tipo_plantilla}`}
                            value={item.tipo_plantilla}
                          >
                            {person_type_id === 1 && item.tipo_plantilla_codigo
                              ? item.tipo_plantilla_codigo + ' - '
                              : ''}
                            {person_type_id === 2 &&
                            item.tipo_plantilla_codigo_pj
                              ? item.tipo_plantilla_codigo_pj + ' - '
                              : ''}
                            {item.tipo_plantilla_nombre}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
              </Row>
              {item.nombre.toLowerCase() == 'contractual' && (
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <Paragraph className={'mb-0'}></Paragraph>
                  </Col>
                  <Col span={24}>
                    <CustomButton
                      onClick={() => setIsModalNewVisible(true)}
                      text="Crear nueva plantilla"
                    />
                    <LoadTemplateProcessForm
                      isModalNewVisible={isModalNewVisible}
                      setIsModalNewVisible={setIsModalNewVisible}
                      hiring_type_id={hiring_type_id}
                      person_type_id={person_type_id}
                      contract_type_id={contract_id}
                      onRefresh={() => {
                        getHiringStageSourceR([]);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>
          ))}
      </Tabs>
    </Skeleton>
  );
};

export default function TemplateType({ match, history }) {
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [formData] = useState(new FormData());

  const [hiringType] = useState(parseInt(match.params.hiring_type_id));
  const [hiringStage] = useState(parseInt(match.params.hiring_stage_id));
  const [contractType] = useState(parseInt(match.params.contract_type_id));
  const [personType] = useState(parseInt(match.params.person_type_id));
  const [templateTypeId, setTemplateTypeId] = useState(
    parseInt(match.params.template_type_id),
  );

  const [hiringTypeName, setHiringTypeName] = useState(null);
  const [hiringStageName, setHiringStageName] = useState(null);
  const [contractTypeName, setContractTypeName] = useState(null);
  const [personTypeName, setPersonTypeName] = useState(null);
  const [templateTypeName, setTemplateTypeName] = useState(null);

  const [templateDocument, setTemplateDocument] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showCLick = (url) => {
    setPdfUrl(url);
    setShowPdf(!showPdf);
  };

  const getBreadcrumbItems = async () => {
    try {
      try {
        const response_person = await getPersonType(personType);

        if (templateTypeId !== 0) {
          const response_template = await getTemplateType(templateTypeId);

          if (personType === 1)
            setTemplateTypeName(
              `${
                response_template.data.codigo != null
                  ? response_template.data.codigo
                  : ''
              } - ${response_template.data.nombre}`,
            );
          if (personType === 2)
            setTemplateTypeName(
              `${
                response_template.data.codigo_pj != null
                  ? response_template.data.codigo_pj
                  : ''
              } - ${response_template.data.nombre}`,
            );
        }

        if (contractType !== 0) {
          const response_contract = await getContractType(contractType);
          setContractTypeName(response_contract.data.nombre);
        }

        if (hiringType !== 0) {
          const response_hiring_type = await getHiringType(hiringType);
          setHiringTypeName(response_hiring_type.data.nombre);
        }

        if (hiringStage !== 0) {
          const response_hiring_stage = await getHiringStage(hiringStage);
          setHiringStageName(response_hiring_stage.data.nombre);
        }

        setPersonTypeName(response_person.data.nombre);
      } catch (exception) {
        let description;

        if (exception.response.data.errors) {
          const errors = exception.response.data.errors;
          description = errors.shift().detail;
        } else {
          description = 'No se pudieron obtener los registros.';
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const breadcrumbItems = [
    {
      text: 'Plantillas',
      to: `${INTERNAL_LINKS.TEMPLATES}`,
    },
    {
      text: `${hiringTypeName != null ? hiringTypeName + ' - ' : ''}
        ${hiringStageName != null ? hiringStageName + ' - ' : ''}
        ${contractTypeName != null ? contractTypeName + ' - ' : ''}
        ${
          personTypeName != null
            ? 'Persona ' + personTypeName + ' - '
            : 'Cargando ...'
        }
        ${templateTypeName != null ? templateTypeName : ''}
        `,
    },
  ];

  useEffect(async () => {
    await getBreadcrumbItems();

    if (templateTypeId !== 0) await searchTemplate();
    else setLoading(false);
  }, []);

  const saveTemplates = async (data) => {
    try {
      setIsLoading(true);
      if (templateTypeId) {
        if (templateDocument.fileList.length > 0) {
          if (
            templateDocument.fileList[0].type ==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ) {
            data = templateDocument.fileList[0].originFileObj;
            data.tipo_contratacion = hiringType;
            data.etapa_contratacion = hiringStage;
            data.tipo_contrato = contractType;
            data.tipo_persona = personType;
            data.tipo_plantilla = templateTypeId;

            formData.append('documento', data);
            for (let prop in data) {
              if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
            }
            const response = await postTemplateDocuments(formData);
            if ([200, 201, 204].indexOf(response.status) > -1) {
              notification.success({
                message: MESSAGES.SUCCESS_MSG,
                description: MESSAGES.DESC_SUCCESS_MSG,
                duration: 2,
              });
              setTimeout(() => {
                setIsLoading(false);
                const res = response.data;
                setDataSource((dataSource) => [...dataSource, res]);
              }, MESSAGES.TIME);
            }
          } else {
            notification.error({
              message: MESSAGES.ERROR_FILE_PDF,
              description: MESSAGES.DESC_ERROR_FILE_PDF,
              duration: 3,
            });
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const searchTemplate = async () => {
    try {
      if (templateTypeId) {
        let template_type = 0;

        if (contractType !== 0) {
          template_type = 1;
        }

        setLoading(true);
        const response = await templateFiltered(
          template_type,
          false,
          false,
          hiringType,
          0,
          personType,
          contractType,
          templateTypeId,
        );
        setDataSource(response.data.results);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Plantillas
      </Title>
      <Title level={4}>
        {hiringTypeName != null ? hiringTypeName + ' - ' : ''}
        {hiringStageName != null ? hiringStageName + ' - ' : ''}
        {contractTypeName != null ? contractTypeName + ' - ' : ''}
        {personTypeName != null ? 'Persona ' + personTypeName : 'Cargando ...'}
        {templateTypeName != null ? ' - ' + templateTypeName : ''}
      </Title>
      <Row gutter={12}>
        <Col span={24}>
          <Alert
            style={{ marginBottom: 15 }}
            message="Importante"
            action={
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                text={
                  <a
                    href={`${process.env.REACT_APP_API_DOMAIN}/static/download/DiccionarioVariables.pdf`}
                    target="_blank"
                  >
                    Descargar diccionario
                  </a>
                }
              />
            }
            description={
              <Paragraph style={{ marginBottom: 0 }}>
                Para crear o cargar una nueva plantilla, descargue el
                diccionario de variables.
              </Paragraph>
            }
            type="info"
            showIcon
          />
        </Col>
      </Row>
      {templateTypeId === 0 && (
        <div style={{ marginTop: '2rem' }}>
          <Row gutter={12}>
            <Col span={24}>
              <TemplatePreList
                SearchTemplate={searchTemplate}
                setTemplateTypeId={setTemplateTypeId}
                hiring_type_id={hiringType}
                person_type_id={personType}
                template_id={templateTypeId}
                contract_id={contractType}
                loading={loading}
                onRefresh={() => {
                  setDataSource([]);
                }}
              />
            </Col>
            <Col span={24}>
              <CustomButton
                style={{ marginTop: 15 }}
                type={'default'}
                className={'back-button'}
                htmlType="button"
                text={<Link to={INTERNAL_LINKS.TEMPLATES}> Volver </Link>}
              />
            </Col>
          </Row>
        </div>
      )}
      <Skeleton loading={loading} active>
        {dataSource != null && (
          <TemplateTable
            dataSource={dataSource}
            setDataSource={setDataSource}
            saveTemplates={saveTemplates}
            loading={loading}
            hiring_type_id={hiringType}
            hiring_stage_id={hiringStage}
            person_type_id={personType}
            template_id={templateTypeId}
            contract_type_id={contractType}
            history={history}
            setShowPdf={setShowPdf}
            showCLick={showCLick}
            onRefresh={() => {
              searchTemplate([]);
            }}
            /*onRefresh={searchTemplate}*/
          />
        )}
      </Skeleton>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
        isWord={true}
      />
    </Content>
  );
}
