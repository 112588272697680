import React, { useState, useEffect } from 'react';
import { Select, Skeleton } from 'antd';

import { getAllActive, get } from '../../api/module/projects';

export const SelectProjects = ({
  className = '',
  onChange = () => {},
  onChangeData = null,
  proyecto = null,
  disabled = false,
  style = {},
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (!disabled) {
      getAllActive().then((res) => {
        if (isMounted) setData(res.data.results);
      });
    } else {
      get(proyecto).then((res) => {
        if (isMounted) setData([res.data]);
      });
    }
    return () => (isMounted = false);
  }, []);

  const onSelfChange = (value, option) => {
    if (!onChangeData) onChange(value, option);
    else onChangeData(value ? data.find((item) => item.id === value) : null);
  };

  return (
    <>
      <Skeleton loading={!data} active paragraph={false} style={style}>
        <Select
          placeholder="Seleccione el proyecto"
          className={className}
          onChange={onSelfChange}
          defaultValue={proyecto}
          disabled={disabled}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={style}
        >
          {data != null &&
            data.map((item) => (
              <Select.Option key={`project-key-${item.id}`} value={item.id}>
                {item.nombre}
              </Select.Option>
            ))}
        </Select>
      </Skeleton>
    </>
  );
};

export default SelectProjects;
