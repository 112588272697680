import React, { useEffect, useState } from 'react';
import { Typography, notification, Skeleton } from 'antd';

import ClientForm from '../../../containers/ClientForm';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, get, put } from '../../../api/module/clients';
import { getAll } from '../../../api/module/client_cost_centers';

const { Title } = Typography;

const breadcrumbItems = [
  {
    text: 'Módulo Administración de Clientes - Aliados',
    to: `${INTERNAL_LINKS.CLIENTS}`,
  },
  {
    text: 'Formulario de Clientes - Aliados',
  },
];

const ClientsPage = ({ history, match }) => {
  const [client_id] = useState(parseInt(match.params.client_id));
  const [title, setTitle] = useState('CLIENTES - ALIADOS');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [costCenter, setCostCenter] = useState(null);

  const onFinish = async (data) => {
    try {
      let response;
      setIsSaved(true);
      if (data.pais) {
        if (data.pais !== 45) {
          data['estado'] = null;
        }
      }
      if (!client_id) {
        response = await post(data);
      } else {
        response = await put(client_id, data);
      }

      setIsSaved(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.CLIENTS);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setIsSaved(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  const getCostCenter = async (client_index) => {
    try {
      const response = await getAll(client_index);
      setCostCenter(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataSource = async () => {
    try {
      if (client_id) {
        setIsLoading(true);
        const response = await get(client_id);
        let data = response.data;
        if (data.constituido_colombia === true) {
          data.nit_confirm = data.nit;
        } else {
          data.num_identificacion_confirm = data.num_identificacion;
        }
        data.confirm_numero_documento_representante_legal =
          data.numero_documento_representante_legal;
        data.telefonoConfirm = data.telefono;
        data.correoConfirm = data.correo;

        setInitialValues(data);
        setIsLoading(false);
        setTitle('EDITAR CLIENTES - ALIADOS');
      } else {
        setInitialValues({ constituido_colombia: true });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const load = async () => {
    await Promise.all([getDataSource(), getCostCenter('C')]);
  };

  useEffect(async () => {
    load();
  }, []);

  return (
    <Skeleton loading={isLoading}>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>{title}</Title>
      {initialValues && costCenter && (
        <ClientForm
          onFinish={onFinish}
          loading={isSaved}
          costCenter={costCenter}
          initialValues={initialValues}
        />
      )}
    </Skeleton>
  );
};

export default ClientsPage;
