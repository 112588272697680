import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Descriptions,
  notification,
  Form,
  Space,
  Row,
  Col,
  Input,
  Typography,
  DatePicker,
  Card,
  Skeleton,
  Popconfirm,
} from 'antd';

import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import CustomButton from '../../../components/CustomButton';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { useParams, Link, useHistory } from 'react-router-dom';
import { get, patch, getContractors } from '../../../api/module/mass_ctm_date';
import { IsRole } from '../../../utils/helpers/helpers';
import '../Form/style.scss';
import AjaxTable from '../../../components/AjaxTable';
import ContractorsModal from '../MassForm/ContractorsModal';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';
const dateFormatText = 'DD MMMM YYYY';

const GeneralSaveButton = ({ onClick, loadingForm }) => (
  <Popconfirm
    placement="topLeft"
    title="¿Está seguro(a) de guardar los ajustes realizados?"
    okText="Sí"
    cancelText="No"
    onConfirm={onClick}
  >
    <CustomButton loading={loadingForm} text="Guardar Modificación" />
  </Popconfirm>
);

const MassModificationChangeTerm = () => {
  var { modification_id } = useParams();
  const [formModifyDate] = Form.useForm();

  const [isLegal] = useState(IsRole(3));
  const [projectId, setPorjectId] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');

  let history = useHistory();

  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${projectId}/${modification_id}`,
    },

    {
      text: 'Modificacion modulo plazo',
    },
  ];

  const [loadingFormDate, setLoadingFormDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataInfo, setDataInfo] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const getModuleInfo = async (modification_id) => {
    try {
      const response = await get(modification_id);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      return null;
    }
  };

  useEffect(() => {
    if (modification_id && isMounted) {
      setLoading(true);
      try {
        getModuleInfo(modification_id).then((response) => {
          let resInfo = response.data.info ? response.data.info : {};
          let modification = response.data.modificacion
            ? response.data.modificacion
            : {};
          let fechaMod = modification.fecha_fin_contrato
            ? moment(modification.fecha_fin_contrato, 'YYYY-MM-DD')
            : null;

          formModifyDate.setFieldsValue({
            modified_date: fechaMod,
            justification: modification.justificacion,
            name_clause: modification.clausula,
          });
          if (modification.justificacion == null) {
            fechaMod = null;
          }
          setSelectedDate(fechaMod);
          setPorjectId(resInfo.proyecto_id);
          setDataInfo(resInfo);
          setHasLoaded(true);
          setLoading(false);
        });
      } catch (err) {
        setHasLoaded(false);
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, [modification_id, isMounted]);

  const disabledDate = (current) => {
    let finalDate = moment(dataInfo.fecha_fin_proyecto).format('YYYY-MM-DD');
    let startDate = moment(dataInfo.fecha_inicio_contrato).format('YYYY-MM-DD');

    if (moment(finalDate).isSame(startDate)) {
      finalDate = moment(dataInfo.fecha_fin_contrato).format('YYYY-MM-DD');
    }
    return (
      current && (current < moment(startDate) || current > moment(finalDate))
    );
  };

  const formatDate = (dateVal, strFormat) => {
    var dateFinal = dateVal ? dateVal : '';
    if (dateFinal !== '') {
      dateFinal = moment(dateFinal).format(strFormat);
    }
    return dateFinal;
  };

  const sendFormDates = async (inputs) => {
    setLoadingFormDate(true);

    const data = {
      ...inputs,
      modified_date: formatDate(inputs.modified_date, 'YYYY-MM-DD'),
    };

    try {
      await patch(modification_id, data);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: 'Módulo actualizado correctamente.',
        duration: 2,
      });
      setLoadingFormDate(false);
      setTimeout(() => {
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
        );
      }, MESSAGES.TIME);
    } catch (error) {
      setLoadingFormDate(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const columns = [
    {
      title: 'Contratista',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: 'Fecha inicio',
      dataIndex: 'fecha_inicio',
      render: (text) => formatDate(text, dateFormat),
    },
    {
      title: 'Fecha Finalización VIGENTE',
      dataIndex: 'fecha_fin',
      render: (text) => formatDate(text, dateFormat),
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title className={'text-uppercase'} level={2}>
          MODIFICACIÓN CONTRACTUAL PLAZO
        </Title>
      </div>
      <Row align="middle" gutter={8}>
        <Col flex="auto">
          {dataInfo && (
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Skeleton loading={loading} active>
                <Descriptions
                  layout="horizontal"
                  column={2}
                  bordered
                  labelStyle={{ maxWidth: '5rem' }}
                >
                  <Descriptions.Item label="Cargo / Rol" span={2}>
                    <Row align="middle">
                      <Col flex="auto">{dataInfo.cargo_nombre}</Col>
                      <Col flex="0 0">
                        <ContractorsModal initialValues={dataInfo} />
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Identificador interno de la modificación masiva"
                    span={2}
                  >
                    {dataInfo.identificador_masivo}
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            </Space>
          )}
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Form
              form={formModifyDate}
              name="form_modificacion_fecha"
              layout="vertical"
              autoComplete="off"
              onFinish={sendFormDates}
            >
              <Card
                size={'small'}
                bordered={false}
                title={
                  <Title level={3}>1.Plazo (Disminución o prórroga)</Title>
                }
              >
                {dataInfo && isLegal && (
                  <Form.Item
                    label="Digite en letras el número de la cláusula del contrato a modificar"
                    name="name_clause"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese el nombre de la cláusula',
                      },
                      {
                        max: 100,
                        message:
                          'El campo debe tener un máximo de 100 caracteres',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      allowClear
                      placeholder=""
                      name="name_clause"
                    />
                  </Form.Item>
                )}
              </Card>

              <br />
              {dataInfo && (
                <AjaxTable
                  columns={columns}
                  endpoint={getContractors}
                  query={dataInfo.id}
                  scroll={{ x: false }}
                  rowKey="id"
                />
              )}
              <br />

              <Space direction={'vertical'} style={{ width: '100%' }}>
                <Card
                  size={'middle'}
                  bordered={false}
                  title={<Title level={4}>Nueva fecha de terminación</Title>}
                  className="fade-in"
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Fecha terminación modificada"
                        name="modified_date"
                        rules={[
                          {
                            required: true,
                            type: 'object',
                          },
                        ]}
                      >
                        {dataInfo && hasLoaded && (
                          <DatePicker
                            allowClear
                            onChange={handleDateChange}
                            format={dateFormat}
                            disabledDate={disabledDate}
                            placeholder="Fecha modificada"
                            style={{
                              width: '100%',
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} align="middle">
                      <p
                        style={{
                          textAlign: 'left',
                          paddingTop: '35px',
                          paddingLeft: '10px',
                        }}
                      >
                        {formatDate(selectedDate, dateFormatText)}
                      </p>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Justificación"
                    name="justification"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa la Justificación',
                      },
                    ]}
                  >
                    <Input.TextArea allowClear />
                  </Form.Item>

                  {hasLoaded && (
                    <Space>
                      <CustomButton
                        htmlType="button"
                        className={'back-button'}
                        type="default"
                        text={
                          <Link
                            to={`${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`}
                          >
                            Volver
                          </Link>
                        }
                      />

                      <Popconfirm
                        placement="topLeft"
                        title={
                          <>
                            <p>
                              Esta Modificación puede implicar cambiar / ajustar
                              otro(s) Módulos de la pantalla anterior.
                            </p>
                            <p>
                              Por favor verifique con atención y seleccionelo(s)
                              para ajustarlo(s) en caso de ser requerido .
                            </p>
                          </>
                        }
                        okText="Entendido"
                        cancelText="Cancelar"
                        onConfirm={() => formModifyDate.submit()}
                      >
                        <GeneralSaveButton loadingForm={loadingFormDate} />
                      </Popconfirm>
                    </Space>
                  )}
                </Card>
              </Space>
            </Form>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default MassModificationChangeTerm;
