import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Typography } from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';

import AjaxTable from '../../../components/AjaxTable';

import { filter } from '../../../api/module/term_resume_contract';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Title } = Typography;

const ResumeContractListPage = () => {
  const breadcrumbItems = [
    {
      text: 'Documentos contractuales',
    },
  ];

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: ['termino', 'proyecto_nombre'],
      key: ['termino', 'proyecto_nombre'],
    },
    {
      title: 'No. Contrato',
      dataIndex: 'numero_contrato',
      key: 'numero_contrato',
    },
    {
      title: 'Cargo',
      dataIndex: ['termino', 'cargo_nombre'],
      key: ['termino', 'cargo_nombre'],
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) =>
        record.estado_validacion === 54 ? (
          <Paragraph>Contrato no aceptado</Paragraph>
        ) : record.estado_proceso === 4 ? (
          <Button type="primary">
            <Link to={`${INTERNAL_LINKS.RESUME_CONTRACT}/${record.id}`}>
              Ver
            </Link>
          </Button>
        ) : (
          <>
            <Paragraph>El proceso de contratación esta en curso</Paragraph>
            <Paragraph strong>
              Recargue el listado mínimo pasados 5 minutos
            </Paragraph>
          </>
        ),
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />

      <div className="box-title">
        <Title>DOCUMENTOS CONTRACTUALES</Title>
      </div>
      <p>
        A continuación se muestra la lista de los contratos en los que usted ha
        participado.
      </p>
      <p>
        Para actualizar el estado de los registros de la tabla de clic en el
        botón "Recargar listado". En caso de no generarse sus documentos
        contractuales en un lapso de 12 horas por favor{' '}
        <a
          href="https://www.corpoeducacion.org.co/contacto/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contáctenos
        </a>
      </p>

      <AjaxTable
        endpointFetch={async (page, pageSize) => {
          const thisQuery = `filter[tercero]=${window.localStorage['tercero']}&filter[estado_proceso__gt]=0`;
          return await filter(`?${thisQuery}`, page, pageSize);
        }}
        columns={columns}
        reloadButton
      ></AjaxTable>
    </>
  );
};

export default ResumeContractListPage;
