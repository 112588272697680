import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Form, Input } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const breadcrumbItems = [
  {
    text: 'Tipos de Documentos',
    to: `${INTERNAL_LINKS.DOCUMENT_TYPES}`,
  },
  {
    text: 'Formulario de Tipos de Documentos',
  },
];

const tailLayout = {
  wrapperCol: { offset: 0, span: 12 },
};

const validateMessages = {
  required: '${label} Es Requerido!',
  types: {
    nombre: '${label} No es un nombre válido!',
    descripcion: '${label} No es una descripción válida!',
  },
};

const DocumentTypeForm = ({ title, onFinish, initialValues, loading }) => {
  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>{title}</Title>
      </div>
      <div className="box-description">
        <Paragraph>
          A continuación, diligencie el formulario de "Creación de Tipos de
          Documentos". Recuerde que los campos obligatorios están marcados con
          asterisco (*).
        </Paragraph>
      </div>
      <div className="form-container">
        <Form
          name="nest-messages"
          layout="vertical"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.length <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Solo puede ingresar 100 caracteres!');
                },
              }),
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.length <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Solo puede ingresar 100 caracteres!');
                },
              }),
            ]}
          >
            <TextArea rows={4} allowClear />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <div className="box-btn">
              <CustomButton loading={loading} text="Guardar" />

              <CustomButton
                className="box__space-left"
                danger="true"
                text={<Link to={INTERNAL_LINKS.DOCUMENT_TYPES}>Volver</Link>}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

DocumentTypeForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};

DocumentTypeForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
};
export default DocumentTypeForm;
