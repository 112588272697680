import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`${process.env.REACT_APP_API_HOST}/resume_search/`, data);
};

const getAll = async () => {
  return (await httpClient.get(`${process.env.REACT_APP_API_HOST}/resume_search/`).then(res => res.data));
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/resume_search/${id}`);
};


const getPerson = async (nombre,documento,correo) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/search_person/?filter[nombre]=${nombre}&filter[documento]=${documento}&filter[correo]=${correo}`,
  );
};



const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/resume_search/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/resume_search/${id}`);
};

export {
  post,
  getAll,
  getPerson,
  get,
  put,
  remove
}