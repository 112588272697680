import React, { useState, useEffect } from 'react';
import { Select, Skeleton } from 'antd';
import PropTypes from 'prop-types';

/**
 * Custom selector for endpoint usage, based in ANT-D components Skeleton and Select.
 * endpointFetch should be an async function that returns a list in JSON API format,
 * with results readable through data['results'].
 * Triggers render based on the key value.
 */

const CustomSelector = ({
  endpointFetch,
  onChange,
  onChangeData,
  onLoad,
  initialValue,
  disabled,
  selectorKey,
  loaderProps,
  selectorProps,
  valueName,
  labelName,
}) => {
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (data) setValue(undefined);
      try {
        let res = await endpointFetch();
        let options = [];
        if (onLoad) {
          let initialData = undefined;
          options = res.data.results.map((item) => {
            if (item[valueName] === initialValue) initialData = item;
            return { value: item[valueName], label: item[labelName] };
          });
          onLoad(initialData);
        } else {
          options = res.data.results.map((item) => ({
            value: item[valueName],
            label:
              item[labelName] !== undefined && item[labelName] !== null
                ? item[labelName]
                : item[valueName],
          }));
        }
        setOptions(options);
        setData(res.data.results);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectorKey]);

  const onSelfChange = (value) => {
    if (!onChangeData) onChange(value);
    else
      onChangeData(
        value ? data.find((item) => item[valueName] === value) : null,
      );
    setValue(value);
  };

  return (
    <>
      <Skeleton
        {...CustomSelector.defaultProps.loaderProps}
        {...loaderProps}
        loading={isLoading}
      >
        <Select
          {...CustomSelector.defaultProps.selectorProps}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          {...selectorProps}
          onChange={onSelfChange}
          loading={isLoading}
          value={value}
          disabled={disabled}
          key={selectorKey}
          options={options}
        />
      </Skeleton>
    </>
  );
};

CustomSelector.propTypes = {
  endpointFetch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeData: PropTypes.func,
  onLoad: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectorKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loaderProps: PropTypes.object,
  selectorProps: PropTypes.object,
  valueName: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
};

CustomSelector.defaultProps = {
  disabled: false,
  onChange: () => {},
  onChangeData: undefined,
  onLoad: undefined,
  loaderProps: {
    active: true,
    paragraph: false,
  },
  selectorProps: {
    allowClear: true,
    showSearch: true,
  },
  initialValue: undefined,
  selectorKey: 'cs',
  valueName: 'value',
  labelName: 'label',
};

export default CustomSelector;
