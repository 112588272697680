import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
  Row,
  Skeleton,
  Col,
  Card,
  Layout,
  List,
} from 'antd';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';

import { useQuery } from '../../../utils/helpers/helpers';
import ProjectForm from '../../../containers/ProjectForm/index';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { getAll as getAllOrganizations } from '../../../api/module/organizations';
import { getAll as getAllClients } from '../../../api/module/clients';

import {
  getByProject as getAllProjectCategory,
  remove,
} from '../../../api/module/project_category';
import {
  post as postProject,
  put as putProject,
  get as getProject,
} from '../../../api/module/projects';
import {
  post as postAllProjectCategory,
  put as putAllProjectCategory,
} from '../../../api/module/project_category';

import './style.scss';

const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

const ProjectsForms = ({ history, match, readOnly = false }) => {
  let query = useQuery();
  const closed_type = query.get('closed_type');
  const [projectID, setProjectID] = useState(parseInt(match.params.project_id));
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Creación de Proyecto');
  const [initialValues, setInitialValues] = useState(null);
  const [code, setCode] = useState(0);
  const [clients, setClients] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [projectCategoryID, setProjectCategoryID] = useState(0);
  const [loadingProjectCategory, setLoadingProjectCategory] = useState(false);
  const [formProjectCategory] = Form.useForm();
  const [form] = Form.useForm();
  const [tipoProyecto, setTipoProyecto] = useState(0);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: 'nombre_proyecto',
      key: 'nombre_proyecto',
    },
    {
      title: 'Categoría',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      render: (_, record) =>
        dataSource.length >= 1 && !readOnly ? (
          <Space>
            <CustomButton
              type="default"
              text="Editar"
              htmlType={'button'}
              className={'ant-btn-info'}
              onClick={() => {
                editProjectCategory(record);
              }}
            />
            <CustomButton
              danger
              type={'default'}
              htmlType={'button'}
              loading={loadingDelete}
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSourceProjectCategory(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </Space>
        ) : null,
    },
  ];
  const save = async (data) => {
    setLoading(true);
    try {
      let response;
      if (!projectID) {
        response = await postProject(data);
      } else {
        response = await putProject(projectID, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          if (projectID) {
            history.push(INTERNAL_LINKS.PROJECTS);
          } else {
            history.push(`${INTERNAL_LINKS.PROJECT}/${response.data.id}`);
            setProjectID(response.data.id);
          }
          setLoading(false);
        }, MESSAGES.TIME);
      } else {
        setCode(code);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  // Get Data by ID
  const handleGetData = async () => {
    try {
      setLoading(true);
      if (projectID) {
        let response = await getProject(projectID);
        if (response.data['fecha_inicio'] != null) {
          response.data['fecha_inicio'] = moment(response.data.fecha_inicio);
        }
        if (response.data['fecha_fin'] != null) {
          response.data['fecha_fin'] = moment(response.data.fecha_fin);
        }
        setLoading(false);
        setInitialValues(response.data);
        setTitle('Actualización de Proyecto');
        setTipoProyecto(response.data.tipo_proyecto);
      } else {
        setLoading(false);
        setInitialValues({ tipo_moneda: 1 });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const handleGetClients = async () => {
    try {
      const response = await getAllClients();
      setClients(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetOrganizations = async () => {
    try {
      const response = await getAllOrganizations();
      setOrganizations(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataSourceProjectCategory = async () => {
    try {
      if (projectID) {
        const res = await getAllProjectCategory(projectID);
        setDataSource(res.data.results);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const saveProjectCategory = async (data) => {
    try {
      let response;

      setLoadingProjectCategory(true);
      if (projectCategoryID === 0) {
        data['proyecto'] = projectID;
        response = await postAllProjectCategory(data);
      } else {
        response = await putAllProjectCategory(projectCategoryID, data);
      }
      setLoadingProjectCategory(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        formProjectCategory.resetFields();
        setProjectCategoryID(0);
        await getDataSourceProjectCategory();
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoadingProjectCategory(false);
    }
  };

  const editProjectCategory = (data) => {
    formProjectCategory.setFieldsValue(data);
    setProjectCategoryID(data.id);
  };

  // Delete Object by Id of List
  const deleteDataSourceProjectCategory = useCallback(
    async (id) => {
      try {
        setLoadingDelete(true);
        const response = await remove(id);
        setLoadingDelete(false);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
          setDataSource(dataSource.filter((item) => item.id !== id));
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [dataSource],
  );

  useEffect(async () => {
    await Promise.all([
      handleGetData(),
      handleGetClients(),
      handleGetOrganizations(),
      getDataSourceProjectCategory(),
    ]);
  }, []);

  return (
    <Skeleton loading={loading}>
      {initialValues != null && (
        <ProjectForm
          title={title}
          onFinish={save}
          initialValues={initialValues}
          loading={loading}
          clients={clients}
          organizations={organizations}
          project_id={projectID}
          form={form}
          readOnly={readOnly}
        />
      )}
      {projectID > 0 && tipoProyecto === 0 && (
        <Content>
          {!readOnly && (
            <Form
              form={formProjectCategory}
              name="nest-messages"
              layout="vertical"
              autoComplete="off"
              onFinish={(data) => {
                saveProjectCategory(data);
              }}
            >
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={3}>Subcategorías del proyecto</Title>}
              >
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="nombre"
                      label="Agregar pruebas o subcategorías del proyecto"
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || value.length <= 100) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              'Solo puede ingresar 100 caracteres!',
                            );
                          },
                        }),
                      ]}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item>
                      <Space>
                        <CustomButton
                          loading={loadingProjectCategory}
                          text="Agregar"
                        />
                        {projectCategoryID !== 0 && (
                          <CustomButton
                            danger
                            type={'default'}
                            htmlType={'button'}
                            onClick={() => {
                              setProjectCategoryID(0);
                              formProjectCategory.resetFields();
                            }}
                            text="Cancelar edición"
                          />
                        )}
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          )}
          <SpinLoad loading={loadingProjectCategory}>
            <Table
              rowKey="id"
              scroll={{ x: 400 }}
              dataSource={dataSource}
              columns={columns}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Subcategorías del proyecto
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Content>
      )}
      {projectID > 0 && (
        <Content>
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>Estado del proyecto</Title>}
          >
            {initialValues && initialValues.estados_proyecto && (
              <List
                dataSource={initialValues.estados_proyecto}
                renderItem={(item) => (
                  <List.Item>
                    <Text>
                      <Text code>{moment(item.created_at).format('YYYY-MM-DD h:mm a')}</Text>{' '}
                      {item.estado_descripcion}{' | '}
                      {item.usuario_nombre}
                    </Text>
                  </List.Item>
                )}
              />
            )}
          </Card>
          <Row gutter={12} style={{ marginTop: 10 }}>
            <Col span={24}>
              <Space>
                <CustomButton
                  text="Guardar"
                  loading={loading}
                  onClick={() => form.submit()}
                />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={
                    <Link
                      to={{
                        pathname: INTERNAL_LINKS.PROJECTS,
                        state: { closed_type: closed_type },
                      }}
                    >
                      Volver
                    </Link>
                  }
                />
              </Space>
            </Col>
          </Row>
        </Content>
      )}
    </Skeleton>
  );
};

export default ProjectsForms;
