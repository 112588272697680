import React, { useEffect, useState } from 'react';
// Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// Page Projects
import PublicLayout from "./containers/PublicLayout";
import PrivateLayout from "./containers/PrivateLayout";

import { IsLoggedIn } from './utils/helpers/helpers';

function App() {
  const [IsLogged, setIsLogged] = useState(false);

  useEffect(() => {
    setIsLogged(IsLoggedIn());
  }, []);

  return (<>
    {
      IsLogged === true ?
        <PrivateLayout />
        :
        <PublicLayout />
    }
  </>);
}

export default App;
