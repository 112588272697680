import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  Select,
  Row,
  Typography,
  Popconfirm,
  notification,
  Modal,
  Input,
  Form,
  Skeleton,
  List,
  Space,
  Col,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import DocumentListViewer from '../../../components/DocumentListViewer';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import {
  get,
  getDocsByType,
  saveDocsByType,
  getDocUrl,
} from '../../../api/module/insurance_policy';

const { Title, Text } = Typography;
const { TextArea } = Input;

const breadcrumbItems = [
  {
    text: 'Revisión Pólizas',
    to: `${INTERNAL_LINKS.INSURANCE}`,
  },
  {
    text: 'Validación Pólizas',
  },
];

const tabNameMap = {
  poliza: 'Póliza',
  recibo: 'Recibo de pago',
};

export default function InsurancePolicyForm({ match }) {
  const [validationData, setValidationData] = useState(undefined);

  async function fetchData() {
    try {
      const res = await get(match.params.validation_id);
      setValidationData(res.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.validation_id]);

  const clearAll = async (newData) => {
    setValidationData((s) => ({
      ...s,
      ...newData.g,
      history: [...s.history, newData.new_h],
    }));
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO REVISIÓN DE PÓLIZAS
      </Title>
      {validationData ? (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Proyecto a revisar">
              {validationData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/rol a revisar">
              {validationData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.contratista_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tipo_identificacion} - ${validationData.identificacion}`}
            </Descriptions.Item>
            <Descriptions.Item label="No. Contrato">
              {validationData.contrato_num}
            </Descriptions.Item>
            <Descriptions.Item label="No. de la modificación">
              {validationData.modificacion_num}
            </Descriptions.Item>
          </Descriptions>
          <CustomButton
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${validationData.contrato}/${validationData.proyecto_id}/${validationData.cargo_id}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver históricos contractuales
              </Link>
            }
          />
          <ValidationModulesContractual
            validationData={validationData}
            stateOpt={validationData.module_states}
            selectOpt={validationData.doc_states}
            clearAll={clearAll}
          />
        </Space>
      ) : (
        <Skeleton loading={true} />
      )}
    </>
  );
}

function ValidationModulesContractual({
  validationData,
  stateOpt,
  selectOpt,
  clearAll = () => {},
}) {
  const [tab, setTab] = useState(undefined);

  const [tabData, setTabData] = useState([]);
  const [changed, setChanged] = useState(false);

  const [isSaving, setSaving] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);

  const changeTab = async (newTab) => {
    setTabLoading(true);

    try {
      const res = await getDocsByType(
        validationData.id,
        newTab === 'poliza' ? 0 : 1,
      );
      setTabData(res.data.results);
      setTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }

    setTabLoading(false);
  };

  const checkChanged = (newTab) => {
    if (changed) {
      Modal.confirm({
        title: MESSAGES.MSG_UNSAVED_CHANGES,
        icon: <ExclamationCircleOutlined />,
        content: MESSAGES.MSG_UNSAVED_MSG,
        okText: 'Si',
        cancelText: 'Volver',
        onOk: () => {
          changeTab(newTab);
          setChanged(false);
        },
      });
    } else {
      changeTab(newTab);
    }
  };

  const onFinish = async (values) => {
    setSaving(true);
    const data = values;
    data.tipo = tab === 'poliza' ? 0 : 1;
    try {
      setTabLoading(true);
      const res = await saveDocsByType(validationData.id, data);
      setTab(undefined);
      setChanged(false);
      clearAll(res.data);
      setTabLoading(false);
      setSaving(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setTabLoading(false);
      setSaving(false);
    }
  };

  return stateOpt ? (
    <>
      <Descriptions
        layout="vertical"
        title="¿Qué documentos desea validar?"
        column={5}
        bordered
        labelStyle={{ display: 'block', textAlign: 'center' }}
        contentStyle={{ display: 'block', textAlign: 'center' }}
        style={{ marginTop: '15px' }}
      >
        <Descriptions.Item
          label={
            <CustomButton
              text="Póliza"
              type={tab === 'poliza' ? 'primary' : 'default'}
              onClick={() => checkChanged('poliza')}
            />
          }
        >
          <Space direction={'vertical'}>
            <Text>Estado de revisión:</Text>
            <Text strong>
              {validationData.estado_poliza
                ? stateOpt.find((e) => e.value === validationData.estado_poliza)
                    .label
                : 'Por revisar'}
            </Text>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <CustomButton
              text="Recibo de pago"
              type={tab === 'recibo' ? 'primary' : 'default'}
              onClick={() => checkChanged('recibo')}
            />
          }
        >
          <Space direction={'vertical'}>
            <Text>Estado de revisión:</Text>
            <Text strong>
              {validationData.estado_recibo
                ? stateOpt.find((e) => e.value === validationData.estado_recibo)
                    .label
                : 'Por revisar'}
            </Text>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Skeleton loading={tabLoading} active>
        {tab && (
          <DocViewerForm
            title={tabNameMap[tab]}
            selectOpt={selectOpt}
            initialValues={{ ...validationData, doc_list: tabData }}
            type={tab}
            stateOpt={stateOpt}
            onFinish={onFinish}
            setChanged={setChanged}
            history={validationData.history}
            disabled={validationData.estado_validacion !== 75}
            isSaving={isSaving}
            onlyFullValid={stateOpt.find((x) => x.sigla === 'CT-PL-M-A')}
            validState={selectOpt.find((x) => x.sigla === 'CT-PL-D-C')}
            isTabLoading={tabLoading}
          />
        )}
        {!tab && (
          <CustomButton
            style={{ marginTop: 10 }}
            type="default"
            htmlType="button"
            className={'back-button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.INSURANCE}/${
                  validationData.estado_validacion === 75
                    ? 'review'
                    : validationData.estado_validacion === 76
                    ? 'incomplete'
                    : 'approved'
                }/${validationData.proyecto_id}`}
              >
                Volver
              </Link>
            }
          />
        )}
      </Skeleton>
    </>
  ) : (
    <Skeleton loading={true} />
  );
}

function DocViewerForm({
  title,
  selectOpt,
  initialValues,
  history = [],
  type = '',
  stateOpt,
  onFinish = () => {},
  setChanged = () => {},
  onlyFullValid,
  validState,
  disabled = true,
  isSaving = false,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, type]);
  return (
    <Form
      name={`docValidationViewer-${type}`}
      onFinish={onFinish}
      onFinishFailed={() =>
        Modal.error({
          title: 'Existen errores en el formulario',
          content:
            'Recuerde que los campos de validación de cada documento y el estado del modulo son obligatorios',
        })
      }
      initialValues={initialValues}
      form={form}
      onValuesChange={() => {
        setChanged(true);
      }}
      key={type}
    >
      <Form.List name="doc_list">
        {() => {
          return (
            <DocumentListViewer
              listTitle={title}
              docList={initialValues.doc_list}
              pdfDataIndex={'id'}
              urlLoadFunc={async (record) => {
                const res = await getDocUrl(
                  record.id,
                  type === 'poliza' ? 0 : 1,
                );
                return res.data.url;
              }}
              extraColumnsLeft={[
                {
                  title: 'Fecha cargue',
                  dataIndex: 'created_at',
                  width: '10em',
                  render: (t) => (
                    <>
                      {moment(t).format('DD-MM-YYYY')}
                      <br />
                      {moment(t).format('hh:mm a')}
                    </>
                  ),
                },
              ]}
              extraColumnsRight={[
                {
                  title: 'Documento validado',
                  width: '8em',
                  key: 'selector',
                  render: (_, record, index) => {
                    return (
                      <Form.Item
                        name={[index, 'estado_documento']}
                        rules={[{ required: true }]}
                        isListField
                        noStyle
                      >
                        <Select
                          placeholder="Seleccione..."
                          options={selectOpt}
                          disabled={disabled}
                        />
                      </Form.Item>
                    );
                  },
                },
              ]}
            />
          );
        }}
      </Form.List>
      <Descriptions layout="vertical" column={1} bordered>
        <Descriptions.Item
          label={`Observaciones del módulo ${tabNameMap[type]}`}
        >
          <Form.Item name={`observaciones_${type}`} noStyle>
            <TextArea
              placeholder="Observaciones"
              disabled={disabled}
              rows={4}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          label={`Historial de observaciones del módulo ${tabNameMap[type]}`}
        >
          <List
            rowKey="id"
            dataSource={history}
            renderItem={(item) => {
              let showState;
              if (item[`estado_${type}`]) {
                showState = stateOpt.find(
                  (e) => e.value === item[`estado_${type}`],
                );
                showState = showState.label;
              } else {
                return;
              }
              return (
                <List.Item>
                  <List.Item.Meta
                    title={`${moment(item.created_at).format(
                      'DD MMMM YYYY, hh:mm:ss',
                    )} - ${showState}`}
                    description={
                      item[`observaciones_${type}`]
                        ? item[`observaciones_${type}`]
                        : 'Sin observaciones'
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={12} style={{ marginTop: 15 }}>
        <Col span={24}>
          <Form.Item
            name={`estado_${type}`}
            label={
              <Text strong>
                'Estado de revision del módulo para este Cargo/Rol'
              </Text>
            }
            dependencies={['doc_list']}
            shouldUpdate={true}
            rules={[
              { required: true, message: 'Seleccione un estado!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value === onlyFullValid.value) {
                    let hasValid = false;
                    const dList = getFieldValue('doc_list');
                    for (const i of dList) {
                      if (i.estado_documento === validState.value) {
                        hasValid = true;
                        break;
                      }
                    }

                    if (hasValid) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        `${onlyFullValid.label} requiere que al menos un documento sea valido!`,
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="Seleccione el estado..."
              options={stateOpt}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Space>
        {(!disabled || isSaving) && (
          <Popconfirm
            onConfirm={() => {
              form.submit();
            }}
            disabled={disabled}
            title={MESSAGES.MSG_SAVE_STATE}
          >
            <CustomButton
              type="primary"
              text="Guardar"
              htmlType="button"
              disabled={disabled}
              loading={isSaving}
            />
          </Popconfirm>
        )}
        <CustomButton
          type="default"
          htmlType="button"
          className={'back-button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.INSURANCE}/${
                initialValues.estado_validacion === 75
                  ? 'review'
                  : initialValues.estado_validacion === 76
                  ? 'incomplete'
                  : 'approved'
              }/${initialValues.proyecto_id}`}
            >
              Volver
            </Link>
          }
        />
      </Space>
    </Form>
  );
}
