import React, { useState, useEffect } from 'react';
import { Layout, notification } from 'antd';

import AdditionalDocumentsLegalForm from '../../containers/AdditionalDocumentsLegalForm/index';
import PdfViewer from '../../components/PdfViewer';
import MESSAGES from '../../enums/Messages';

import { getDocumentByInitial } from '../../api/module/help_upload';
import { get as getDocUrl } from '../../api/module/document_repository';

const { Content } = Layout;

export default function AdditionalDocumentsLegal({
  person_id,
  readOnly = false,
  setDocsComplete = () => {},
}) {
  const requiredDocs = [
    'documento_16',
    'documento_17',
    'documento_18',
    'documento_19',
    'documento_20',
    'documento_21',
    'documento_22',
  ];

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfHelp, setPDFHelp] = useState('');

  const getHelpPDF = async () => {
    try {
      const response = await getDocumentByInitial('PDF-RP');
      setPDFHelp(response.data.results.shift());
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    if (person_id) {
      await getHelpPDF();
    }
  }, []);

  const showClick = async (filename, id) => {
    try {
      setLoadingPDF(id);
      setPdfUrl(null);
      let response = await getDocUrl(filename);
      setPdfUrl(response.data.url);
      setShowPdf(!showPdf);
      setLoadingPDF(false);
    } catch (err) {
      console.log(err);
      setLoadingPDF(false);
    }
  };

  return (
    <Content>
      <AdditionalDocumentsLegalForm
        personId={person_id}
        showClick={showClick}
        readOnly={readOnly}
        pdfHelp={pdfHelp}
        loadingPDF={loadingPDF}
      />
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </Content>
  );
}
