import httpClient from '../httpClient';

const confirm = async (id) => {
  return await httpClient.post(`/term_legal_rejected/${id}/confirm/`);
};

const notify = async (id, data) => {
  return await httpClient.post(`/term_legal_rejected/${id}/notify/`, data);
};

export { confirm, notify };
