import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Typography, Space, Card } from 'antd';

import CustomButton from '../../components/CustomButton/';
import { SelectKnowledgeArea } from '../../components/SelectKnowledgeArea/';
import { SelectExperienceArea } from '../../components/SelectExperienceArea';
import { SelectJobType } from '../../components/SelectJobType';
import { PersonSearchTable } from '../../components/PersonSearchTable';

const { Title } = Typography;

const AnnouncementCloseSearchForm = memo(
  ({
    dataSource,
    setDataSource,
    loading,
    onFinish,
    rowSelection,
    selectedKnowledge,
  }) => {
    const [form] = Form.useForm();

    return (
      <>
        <Form
          form={form}
          name="location_form"
          layout="vertical"
          className="mt-8"
          onFinish={onFinish}
        >
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={5}>Búsqueda por identificación</Title>}
          >
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label="Nombre" name="nombre">
                  <Input allowClear placeholder="Búsqueda por nombre" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Número de documento" name="documento">
                  <Input
                    type="number"
                    allowClear
                    placeholder="Búsqueda por documento"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Correo electrónico" name="correo">
                  <Input allowClear placeholder="Correo electrónico" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={5}>Búsqueda por educación</Title>}
          >
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label="Pregrado" name="pregrado">
                  <SelectKnowledgeArea
                    onChange={(value) => selectedKnowledge(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Especialización" name="especializacion">
                  <SelectKnowledgeArea
                    onChange={(value) => selectedKnowledge(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Maestría" name="maestria">
                  <SelectKnowledgeArea
                    onChange={(value) => selectedKnowledge(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label="Doctorado" name="doctorado">
                  <SelectKnowledgeArea
                    onChange={(value) => selectedKnowledge(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Posdoctorado" name="posdoctorado">
                  <SelectKnowledgeArea
                    onChange={(value) => selectedKnowledge(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Titulo obtenido" name="titulo_obtenido">
                  <Input allowClear placeholder="Titulo obtenido" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={5}>Búsqueda por experiencia</Title>}
          >
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  label="Área de experiencia"
                  name="area_experiencia"
                >
                  <SelectExperienceArea />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Meses de experiencia"
                  name="meses_experiencia"
                >
                  <Input
                    type="number"
                    allowClear
                    placeholder="Meses de experiencia"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Tipo de cargo" name="tipo_cargo">
                  <SelectJobType />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label="Cargo" name="cargo">
                  <Input allowClear placeholder="Búsqueda por cargo" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Row gutter={12}>
            <Col span={12}>
              <Space>
                <CustomButton
                  loading={loading}
                  text="Buscar"
                />
                <CustomButton
                  htmlType="button"
                  onClick={() => {
                    form.resetFields();
                    setDataSource([]);
                  }}
                  type="default"
                  danger
                  text="Borrar filtros"
                />
              </Space>
            </Col>
          </Row>
        </Form>
        <PersonSearchTable
          dataSource={dataSource}
          loading={loading}
          rowSelection={rowSelection}
        />
      </>
    );
  },
);

AnnouncementCloseSearchForm.propTypes = {
  dataSource: PropTypes.array,
  ids: PropTypes.object,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  selectedKnowledge: PropTypes.func,
};

AnnouncementCloseSearchForm.defaultProps = {
  dataSource: [],
  ids: {},
  loading: false,
  rowSelection: {},
  selectedKnowledge: () => {},
};

export default AnnouncementCloseSearchForm;
