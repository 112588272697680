import React from 'react';

import './style.scss';
import InternalLinks from '../../enums/InternalLinks';
import Col from 'antd/lib/col';
import { Link } from 'react-router-dom';
import GreenLogo from '../../components/GreenLogo';

import './style.scss';

export default class PublicHeader extends React.Component {
  render() {
    return (
      <div className="public-header-container">
        <Col>
          <Link to={InternalLinks.HOME}>
            <GreenLogo />
            <h3>SIGA - Sistema de Gestión Administrativa y Contratación</h3>
          </Link>
        </Col>
      </div>
    );
  }
}
