import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Radio,
  Space,
  InputNumber,
  Layout,
} from 'antd';
import { Link } from 'react-router-dom';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import SelectOrganizationsActive from '../../components/SelectOrganizationsActive';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { getAll as getCurrencyTypes } from '../../api/module/currency_types';
import { query as getAllCostCenter } from '../../api/module/costs';

import './style.scss';

const { Title, Paragraph } = Typography;
const breadcrumbItems = [
  {
    text: 'Proyectos',
    to: `${INTERNAL_LINKS.PROJECTS}`,
  },
  {
    text: 'Formulario de Proyecto',
  },
];
const { Option } = Select;
const { Content } = Layout;

const validateMessages = {
  required: '${label} Es Requerido!',
};

const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Por favor seleccione el tiempo!',
    },
  ],
};

const FormProject = ({
  title,
  onFinish,
  initialValues,
  loading,
  clients,
  project_id,
  form,
  readOnly,
}) => {
  const [currencyTypesOptions, setCurrencyTypesOptions] = useState({
    list: [],
    COP: null,
  });
  const [initialDate, setInitialDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [costCenters, setCostCenters] = useState([]);

  const getCurrencyTypesOptions = async () => {
    const response = await getCurrencyTypes();
    setCurrencyTypesOptions({
      list: response.data.results,
      COP: response.data.results.find((item) => item.sigla === 'COP'),
    });
  };

  useEffect(() => {
    getCurrencyTypesOptions();
    if (initialValues.fecha_inicio) {
      setInitialDate(initialValues.fecha_inicio);
    }
    if (initialValues.fecha_fin) {
      setFinishDate(initialValues.fecha_fin);
    }
    if (initialValues.centro_costo_cliente) {
      clientChangeEvent(initialValues.centro_costo_cliente);
    }
  }, []);

  const clientChangeEvent = async (value) => {
    try {
      const response = await getAllCostCenter(
        `?filter[cliente_centro_costo]=${value}`,
      );
      setCostCenters(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      {!readOnly && (
        <Paragraph>
          A continuación, diligencie el formulario "{title}".
          <br />
          Recuerde que los campos obligatorios están marcados con asterisco (*).
        </Paragraph>
      )}
      <Form
        form={form}
        name="nest-messages"
        layout="vertical"
        onFinish={(data) => onFinish(data)}
        validateMessages={validateMessages}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="tipo_proyecto"
              label="Tipo de proyecto"
              tooltip={{
                placement: 'top',
                title: `Elija el tipo de proyecto`,
              }}
            >
              <Radio.Group
                defaultValue={initialValues.tipo_contrato}
                disabled={readOnly || project_id > 0}
              >
                <Radio value={1}>Regular</Radio>
                <Radio value={0}>Tipo ICFES</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="cliente"
              label="Cliente - Aliado"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Cliente..."
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={readOnly}
                onChange={(value) => {
                  let client_centro_costo = 0;
                  for (let item of clients) {
                    if (item.id === value) {
                      client_centro_costo = item.centro_costo_cliente;
                    }
                  }
                  clientChangeEvent(client_centro_costo);
                }}
              >
                {clients != null && (
                  <>
                    {clients.map((item) => (
                      <Option key={`key-client-${item.id}`} value={item.id}>
                        {item.nombre}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="organizacion"
              label="Organización ejecutora"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <SelectOrganizationsActive
                organization={initialValues.organizacion}
                disabled={readOnly}
                organization_name={initialValues.organizacion_nombre}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nombre"
              label="Nombre del proyecto"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Solo puede ingresar 100 caracteres!',
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder="Nombre del Proyecto..."
                allowClear
                disabled={readOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="centro_costo"
              label="Centro de costos del proyecto"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Centro de costos del proyecto..."
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={readOnly}
              >
                {costCenters.map((item) => (
                  <Option key={`key-client-${item.id}`} value={item.id}>
                    {item.codigo + ' - ' + item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label="Código del proyecto Syscorpo"
              name="codigo_syscorpo"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label="Sigla del proyecto"
              name="nombre_syscorpo"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length <= 11) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Solo puede ingresar 11 caracteres!');
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="fecha_inicio" label="Fecha de inicio" {...config}>
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                disabled={readOnly}
                onChange={(value) => {
                  setInitialDate(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fecha_fin"
              label="Fecha de finalización"
              {...config}
            >
              <DatePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                disabled={readOnly}
                onChange={(value) => {
                  setFinishDate(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            {initialDate && (
              <Form.Item>
                Fecha de inicio seleccionada:{' '}
                <strong>{initialDate.format('DD MMMM YYYY')}</strong>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {finishDate && (
              <Form.Item>
                Fecha de finalización seleccionada:{' '}
                <strong>{finishDate.format('DD MMMM YYYY')}</strong>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="tipo_moneda"
              label="Seleccione la moneda origen del proyecto"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Moneda..."
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={readOnly}
              >
                {currencyTypesOptions &&
                  currencyTypesOptions.list.map((item) => (
                    <Option key={`currency-key-${item.id}`} value={item.id}>
                      {item.sigla}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            {currencyTypesOptions.COP && (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.tipo_moneda !== currentValues.tipo_moneda
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('tipo_moneda') !==
                  currencyTypesOptions.COP.id ? (
                    <Form.Item
                      name="trp"
                      label="Tasa representativa del mercado para el proyecto (TRM)"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="TRP..."
                        style={{ width: '100%' }}
                        allowClear
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        disabled={readOnly}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            {!project_id && (
              <Space>
                <CustomButton text="Guardar" loading={loading} />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={<Link to={INTERNAL_LINKS.PROJECTS}> Volver </Link>}
                />
              </Space>
            )}
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

FormProject.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  client: PropTypes.array,
  organization: PropTypes.array,
  readOnly: PropTypes.bool,
};

FormProject.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
  client: [],
  organization: [],
  readOnly: false,
};

export default FormProject;
