import React, { useState, useEffect } from 'react';
import {
  notification,
  Typography,
  Skeleton,
  Row,
  Divider,
  Descriptions,
  List,
} from 'antd';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import {
  getValidationDetail as getSVDP,
  notify,
} from '../../api/module/term_document_contract_validation';
import { CustomButton } from '../../components';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import MESSAGES from '../../enums/Messages';
import moment from 'moment';
import 'moment/locale/es';

const breadcrumbItems = [
  {
    text: 'Documentos para ajustar información',
  },
];

const { Title, Paragraph } = Typography;

export default function DocumentContractValidationShow({ match, history }) {
  const [validationData, setValidationData] = useState(undefined);
  const [loadingNotification, setLoadingNotification] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const validationId = match.params.validation_id;
        const res = await getSVDP(validationId);
        setValidationData(res.data);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.validation_id]);

  const notifyUser = async () => {
    setLoadingNotification(true);
    try {
      const response = await notify(validationData.id);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          setLoadingNotification(false);
          history.push(INTERNAL_LINKS.HOME_ANNOUNCEMENT);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoadingNotification(false);
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>Documentos para ajustar información</Title>
      </div>
      {validationData ? (
        <>
          <Descriptions column={2} bordered layout="vertical">
            <Descriptions.Item label="Proyecto, licitación concurso u otro">
              {validationData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/Rol">
              {validationData.subcategoria_nombre
                ? `${validationData.cargo_nombre} - ${validationData.subcategoria_nombre}`
                : validationData.cargo_nombre}
            </Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
          <Descriptions column={1} bordered layout="vertical">
            <Descriptions.Item
              key="validation-precontractual-show"
              label="Notificación"
            >
              <List
                dataSource={validationData.email_list}
                grid={{ gutter: [0, 24], column: 1 }}
                renderItem={(item, index) => (
                  <>
                    <Title level={5}>
                      Email No. {index + 1} - Enviado el:{' '}
                      {moment(item.created_at).format('DD MMMM YYYY h:mm a')}
                    </Title>
                    <div
                      key={`validation-precontractual-show-${index}`}
                      dangerouslySetInnerHTML={{ __html: item.mensaje }}
                    ></div>
                  </>
                )}
                pagination={false}
              />
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">
            <Title level={4}>Paso No. 1</Title>
          </Divider>
          <br></br>
          <div style={{ textAlign: 'center' }}>
            <Descriptions column={1} layout="vertical" bordered>
              <Descriptions.Item
                labelStyle={{ fontWeight: 'bold' }}
                label={
                  <>
                    <Paragraph>
                      Para actualizar/ajustar siga las siguientes indicaciones:
                    </Paragraph>
                    <ul className="steps-list">
                      <li>
                        Hacer clic en el botón "Actualizar información
                        requerida".
                      </li>
                      <li>
                        Se abrirá en una nueva pestaña de su navegador su
                        perfil.
                      </li>
                      <li>
                        En esa nueva pestaña debe actualizar la información que
                        se le solicita.
                      </li>
                      <li>
                        Luego de actualizar y guardar, debe cerrar esa pestaña.
                      </li>
                      <li>Continúe al paso 2</li>
                    </ul>
                  </>
                }
              >
                <CustomButton
                  type="primary"
                  text={
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to={
                        validationData.tercero.tipo_persona_nombre === 'Natural'
                          ? `${INTERNAL_LINKS.NATURAL_PERSON}/${validationData.tercero.id}`
                          : `${INTERNAL_LINKS.LEGAL_PERSON}/${validationData.tercero.id}`
                      }
                    >
                      Actualizar información requerida
                    </Link>
                  }
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Divider orientation="left">
            <Title level={4}>Paso No. 2</Title>
          </Divider>
          <Descriptions column={2} bordered>
            <Descriptions.Item
              labelStyle={{ fontWeight: 'bold' }}
              label="Una vez actualizada la información indicada en el paso No. 1, notifique a la organización haciendo clic en el botón 'Notificar ajuste', de lo contrario no será tenido en cuenta"
            >
              <CustomButton
                loading={loadingNotification}
                type="primary"
                onClick={() => {
                  notifyUser();
                }}
                text="Notificar ajuste"
              />
            </Descriptions.Item>
          </Descriptions>
          <Row justify="space-around" style={{ marginTop: '15px' }}>
            <CustomButton
              danger="true"
              type="default"
              htmlType="button"
              text={
                <Link to={`${INTERNAL_LINKS.HOME_ANNOUNCEMENT}`}>Volver</Link>
              }
            />
          </Row>
        </>
      ) : (
        <Skeleton loading={true} />
      )}
    </>
  );
}
