import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Skeleton, Table, notification } from 'antd';

import { CustomButton } from '../../../components';
import MESSAGES from '../../../enums/Messages';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import PdfViewer from '../../../components/PdfViewer';
import { get } from '../../../api/module/term_director_validation_document';
import { filter } from '../../../api/module/term_legal_validation_document_supplementary';
import { postWord as post } from '../../../api/module/template';

const { Title } = Typography;

const breadcrumbItems = [
  {
    text: 'Firma de TDR de Contratistas y Proveedores',
    to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}`,
  },
  {
    text: 'Validación y Firma de TDR',
  },
];

const initModalState = {
  pdf: undefined,
  visible: false,
  key: 'no-key',
};

export default function DocumentValidationDirectorForm({ match }) {
  const [modalState, setModalState] = useState(initModalState);
  const [dataSource, setDataSource] = useState(undefined);
  const [contractData, setContractData] = useState(undefined);
  const [loadingPDF, setLoadingPDF] = useState(undefined);

  useEffect(() => {
    async function fetchData(id) {
      try {
        const res = await get(id);
        const resList = await filter(
          `/?filter[termino]=${res.data.termino.id}`,
          1,
          1000,
        );
        setContractData(res.data);
        setDataSource(resList.data.results);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData(match.params.validation_id);
  }, [match.params.validation_id]);

  const columns = [
    {
      title: 'Tipo de documento',
      render: (_, record) => {
        return record.plantilla_codigo != null ? record.plantilla_codigo + ' - ' + record.plantilla_nombre : record.plantilla_nombre;
      },
    },
    {
      title: 'Ver',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Visualizar"
            htmlType="button"
            loading={loadingPDF === record.id}
            disabled={loadingPDF}
            onClick={async () => {
              setLoadingPDF(record.id);
              try {
                const response = await post({
                  template: record.tipo_plantilla,
                  person: contractData.tercero.id,
                  term: contractData.termino.id,
                  person_type: contractData.tercero.tipo_persona
                });
                setModalState({
                  visible: true,
                  pdf: response.data.url,
                  key: `pdf-${record.id}`,
                });
              } catch (err) {
                console.log(err);
                notification.error({
                  message: MESSAGES.ERROR_MSG,
                  description: MESSAGES.DESC_ERROR_GET_MSG,
                  duration: 2,
                });
              }
              setLoadingPDF(undefined);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>Validación y Firma de TDR</Title>
      </div>
      {dataSource ? (
        <div className="box-description">
          <Row>
            <Col span={22} offset={1}>
              <Table
                dataSource={dataSource}
                columns={columns}
                bordered
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination={false}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <Skeleton active loading={true} />
      )}
      <Row justify="space-around" style={{ marginTop: '15px' }}>
        <CustomButton
          danger="true"
          htmlType="button"
          text={
            <Link to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}`}>
              Volver
            </Link>
          }
        />
      </Row>

      <PdfViewer
        pdf={modalState.pdf}
        visible={modalState.visible}
        onCancel={() => setModalState(initModalState)}
        isWord={true}
      />
    </>
  );
}
