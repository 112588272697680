import React, { useState } from 'react';
import { Menu, Typography, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeopleArrows,
  faProjectDiagram,
  faHome,
  faBriefcase,
  faMoneyCheck,
  faUniversity,
  faIdBadge,
  faIdCard,
  faAddressCard,
  faFilePdf,
  faFileExcel,
  faGlobeAmericas,
  faFlagUsa,
  faCity,
  faSitemap,
  faAward,
  faScroll,
  faCheckDouble,
  faUsers,
  faListAlt,
  faUsersCog,
  faVoteYea,
  faExclamationTriangle,
  faCheckSquare,
  faBook,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';

import { IsRole } from '../../../utils/helpers/helpers.js';
import INTERNAL_LINKS from '../../../enums/InternalLinks';

import './style.scss';

const { Title } = Typography;
const { SubMenu } = Menu;

const CustomMenuItem = ({ perm, ...props }) => {
  if (perm()) {
    return (
      // <Menu.Item key={INTERNAL_LINKS.ANNOUNCEMENT}>
      //   <Link to={INTERNAL_LINKS.ANNOUNCEMENT}>
      //     <FontAwesomeIcon icon={faScroll} style={{ marginRight: '5px' }} />
      //     Convocatorias
      //   </Link>
      // </Menu.Item>
      <Menu.Item {...props} />
    );
  }
  return null;
};

const MenuApp = () => {
  let location = useLocation();

  const [manager] = useState(IsRole(1));
  const [coordinator] = useState(IsRole(2));
  const [legal] = useState(IsRole(3));

  const [administrative] = useState(IsRole(4));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));
  const [lead] = useState(IsRole(8));
  const [principal] = useState(IsRole(9));
  const [analyst] = useState(IsRole(11));
  const [validator] = useState(IsRole(12));
  const [projectValidator] = useState(IsRole(13));
  const [accountingAssistant] = useState(IsRole(14));
  const [professionalSGSST] = useState(IsRole(15));
  const [person_id] = useState(window.localStorage['tercero']);
  const [person_type_id] = useState(
    parseInt(window.localStorage['person_type']),
  );
  const [selectedKey] = useState([location.pathname]);
  const defaultOpenKeys = [
    'item-submenu-proveedores',
    'item-submenu-proveedores',
    'item-submenu-procesos',
    'item-submenu-configuracion',
    'item-submenu-parametros',
    'item-submenu-reportes',
  ];

  const [perms] = useState(() => {
    const p = JSON.parse(window.localStorage.getItem('permissions'))[0]
      .attributes;

    return p.lista_permisos.reduce((map, value) => {
      map[value.descripcion] = p.tipo_permisos.reduce((perm_map, type) => {
        perm_map[type.tipo] = value.tipos.includes(type.id);
        return perm_map;
      }, {});
      return map;
    }, {});
  });

  return (
    <Menu
      trigger={null}
      mode="inline"
      className="main-menu"
      defaultSelectedKeys={selectedKey}
      defaultOpenKeys={defaultOpenKeys}
    >
      <Menu.Item key={INTERNAL_LINKS.HOME}>
        <Link to={INTERNAL_LINKS.HOME}>
          <FontAwesomeIcon icon={faHome} /> Inicio
        </Link>
      </Menu.Item>
      {!superUser && (
        <Menu.ItemGroup
          key="ItemMenuGroup0"
          title={<Title level={4}>Personal</Title>}
        >
          {!superUser && person_type_id === 1 && (
            <>
              <Menu.Item key={INTERNAL_LINKS.NATURAL_PERSON}>
                <Link to={`${INTERNAL_LINKS.NATURAL_PERSON}/${person_id}`}>
                  <FontAwesomeIcon icon={faIdCard} /> Perfil
                </Link>
              </Menu.Item>
            </>
          )}
          {(!superUser && person_type_id) === 2 && (
            <>
              <Menu.Item key={INTERNAL_LINKS.LEGAL_PERSON}>
                <Link to={`${INTERNAL_LINKS.LEGAL_PERSON}/${person_id}`}>
                  <FontAwesomeIcon icon={faIdCard} /> Perfil
                </Link>
              </Menu.Item>
            </>
          )}
          <SubMenu
            key="item-submenu-parametros"
            title={<Title level={5}>Contratos</Title>}
          >
            <Menu.Item key={INTERNAL_LINKS.RESUME_CONTRACTS}>
              <Link to={INTERNAL_LINKS.RESUME_CONTRACTS}>
                <FontAwesomeIcon icon={faBook} /> Documentos contractuales
              </Link>
            </Menu.Item>
            <Menu.Item key={INTERNAL_LINKS.CONTRACTOR_INSURANCE}>
              <Link to={INTERNAL_LINKS.CONTRACTOR_INSURANCE}>
                <FontAwesomeIcon icon={faBook} /> Pólizas
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu.ItemGroup>
      )}
      {!contractor ? (
        <Menu.ItemGroup
          key="ItemMenuGroup1"
          title={<Title level={4}>Gestión Administrativa</Title>}
        >
          {(manager ||
            coordinator ||
            administrative ||
            superUser ||
            legal ||
            lead ||
            projectValidator ||
            accountingAssistant ||
            assistant ||
            principal ||
            professionalSGSST) && (
            <SubMenu
              key="item-submenu-proveedores"
              title={<Title level={5}>Proveedores/Consultores</Title>}
            >
              {(manager ||
                coordinator ||
                administrative ||
                superUser ||
                legal ||
                lead ||
                projectValidator ||
                accountingAssistant ||
                assistant ||
                principal ||
                professionalSGSST) && (
                <>
                  <Menu.Item key={INTERNAL_LINKS.RESUME_SEARCH}>
                    <Link to={INTERNAL_LINKS.RESUME_SEARCH}>
                      <FontAwesomeIcon icon={faUsers} /> Hojas de vida
                    </Link>
                  </Menu.Item>
                </>
              )}
              {superUser && (
                <>
                  <SubMenu
                    key="sub-menu-0"
                    className="submenu-item"
                    icon={
                      <FontAwesomeIcon
                        icon={faIdBadge}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    title="Edición"
                  >
                    <Menu.Item key={INTERNAL_LINKS.NATURAL_PERSONS}>
                      <Link to={INTERNAL_LINKS.NATURAL_PERSONS}>
                        <FontAwesomeIcon
                          icon={faIdCard}
                          style={{ marginRight: '5px' }}
                        />
                        Persona natural
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={INTERNAL_LINKS.LEGAL_PERSONS}>
                      <Link to={INTERNAL_LINKS.LEGAL_PERSONS}>
                        <FontAwesomeIcon
                          icon={faAddressCard}
                          style={{ marginRight: '5px' }}
                        />
                        Persona jurídica
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </>
              )}
              {(legal || superUser) && (
                <Menu.Item key={INTERNAL_LINKS.RESTRICTIVE_LIST_SEARCH}>
                  <Link to={INTERNAL_LINKS.RESTRICTIVE_LIST_SEARCH}>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Listas
                    restrictivas
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
          <SubMenu
            key="item-submenu-procesos"
            title={<Title level={5}>Procesos</Title>}
          >
            {(manager ||
              coordinator ||
              administrative ||
              superUser ||
              assistant ||
              legal ||
              principal ||
              analyst ||
              validator ||
              lead) && (
              <>
                {!legal && !validator && (
                  <Menu.Item key={INTERNAL_LINKS.ANNOUNCEMENT}>
                    <Link to={INTERNAL_LINKS.ANNOUNCEMENT}>
                      <FontAwesomeIcon
                        icon={faScroll}
                        style={{ marginRight: '5px' }}
                      />
                      Convocatorias
                    </Link>
                  </Menu.Item>
                )}
                {(manager ||
                  coordinator ||
                  administrative ||
                  superUser ||
                  assistant ||
                  principal ||
                  analyst ||
                  validator ||
                  lead) && (
                  <Menu.Item key={INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}>
                    <Link to={INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}>
                      <FontAwesomeIcon icon={faCheckDouble} /> Preselección
                    </Link>
                  </Menu.Item>
                )}
                {(manager ||
                  coordinator ||
                  administrative ||
                  superUser ||
                  assistant ||
                  principal ||
                  analyst ||
                  legal ||
                  validator ||
                  lead) && (
                  <Menu.Item key={INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}>
                    <Link to={INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}>
                      <FontAwesomeIcon icon={faVoteYea} /> Selección
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
            {(superUser ||
              legal ||
              lead ||
              manager ||
              principal ||
              administrative ||
              assistant ||
              coordinator) && (
              <>
                <Menu.Item key={INTERNAL_LINKS.PROJECT_TEAM}>
                  <Link to={INTERNAL_LINKS.PROJECT_TEAM}>
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ marginRight: '5px' }}
                    />
                    Equipo de trabajo
                  </Link>
                </Menu.Item>
              </>
            )}
            {(principal ||
              manager ||
              coordinator ||
              administrative ||
              legal ||
              lead) && (
              <>
                <Menu.Item key={INTERNAL_LINKS.TDR_HOME}>
                  <Tooltip title="Términos de referencia" placement="topRight">
                    <Link to={INTERNAL_LINKS.TDR_HOME}>
                      <FontAwesomeIcon icon={faListAlt} /> TDR
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </>
            )}
            {(superUser || assistant) && (
              <>
                <Menu.Item
                  key={INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}
                >
                  <Tooltip
                    title="Validación de documentos Precontractuales"
                    placement="topRight"
                  >
                    <Link
                      to={INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}
                    >
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        style={{ marginRight: '5px' }}
                      />
                      Validación de documentos Precontractuales
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </>
            )}
            {(superUser || legal) && (
              <>
                <Menu.Item key={INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}>
                  <Tooltip
                    title="Validación de documentos Jurídico"
                    placement="topRight"
                  >
                    <Link to={INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}>
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        style={{ marginRight: '5px' }}
                      />
                      Validación de documentos Jurídico
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </>
            )}

            {(superUser || assistant) && (
              <Menu.Item key={INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}>
                <Tooltip
                  title="Seguimiento Validación Documentación Parcial"
                  placement="topRight"
                >
                  <Link to={INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}>
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      style={{ marginRight: '5px' }}
                    />
                    Seguimiento Validación Documentación Parcial
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            {(superUser ||
              assistant ||
              legal ||
              projectValidator ||
              accountingAssistant ||
              coordinator ||
              lead ||
              manager ||
              principal ||
              professionalSGSST) && (
              <Menu.Item key={INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}>
                <Link to={INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}>
                  <FontAwesomeIcon icon={faBook} /> Búsqueda de Documentos
                  contractuales
                </Link>
              </Menu.Item>
            )}

            {principal && (
              <Menu.Item key={INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}>
                <Tooltip
                  title="Firma de Términos de referencia"
                  placement="topRight"
                >
                  <Link to={INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}>
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      style={{ marginRight: '5px' }}
                    />
                    Firma de TDR
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}

            {(principal || manager || legal || superUser) && (
              <>
                <Menu.Item key={INTERNAL_LINKS.REJECT_TDR}>
                  <Link to={INTERNAL_LINKS.REJECT_TDR}>
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      style={{ marginRight: '5px' }}
                    />
                    Rechazo de TDR
                  </Link>
                </Menu.Item>
              </>
            )}
            {(principal || lead || manager || legal || coordinator) && (
              <>
                <Menu.Item key={INTERNAL_LINKS.CONTRACT_MODIFICATIONS}>
                  <Link to={INTERNAL_LINKS.CONTRACT_MODIFICATIONS}>
                    <FontAwesomeIcon
                      icon={faListAlt}
                      style={{ marginRight: '5px' }}
                    />
                    Modificaciones Contractuales
                  </Link>
                </Menu.Item>
              </>
            )}
            {principal && (
              <Menu.Item key={INTERNAL_LINKS.MODIFICATION_DIRECTOR}>
                <Tooltip
                  title="Firma Modificaciones Contractuales"
                  placement="topRight"
                >
                  <Link to={INTERNAL_LINKS.MODIFICATION_DIRECTOR}>
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      style={{ marginRight: '5px' }}
                    />
                    Firma Modificaciones Contractuales
                  </Link>
                </Tooltip>
              </Menu.Item>
            )}
            <CustomMenuItem
              key={INTERNAL_LINKS.INSURANCE}
              perm={() => perms['Revisión Pólizas']?.Leer}
            >
              <Link to={INTERNAL_LINKS.INSURANCE}>
                <FontAwesomeIcon icon={faListAlt} /> Revisión Pólizas
              </Link>
            </CustomMenuItem>
            <CustomMenuItem
              key={INTERNAL_LINKS.POSTCONTRACTUAL}
              perm={() => perms['Poscontractual']?.Leer}
            >
              <Link to={INTERNAL_LINKS.POSTCONTRACTUAL}>
                <FontAwesomeIcon icon={faListAlt} /> Poscontractual
              </Link>
            </CustomMenuItem>
            <CustomMenuItem
              key={INTERNAL_LINKS.POSTCONTRACTUAL_DIRECTOR}
              perm={() => perms['Firma Poscontractual']?.Leer}
            >
              <Link to={INTERNAL_LINKS.POSTCONTRACTUAL_DIRECTOR}>
                <FontAwesomeIcon icon={faListAlt} /> Firma Poscontractual
              </Link>
            </CustomMenuItem>
          </SubMenu>

          {(coordinator ||
            assistant ||
            manager ||
            legal ||
            superUser ||
            lead) && (
            <>
              <SubMenu
                key="item-submenu-configuracion"
                title={<Title level={5}>Configuración</Title>}
              >
                {(superUser || legal || lead) && (
                  <>
                    <Menu.Item key={INTERNAL_LINKS.ORGANIZATIONS}>
                      <Link to={INTERNAL_LINKS.ORGANIZATIONS}>
                        <FontAwesomeIcon icon={faSitemap} /> Organizaciones
                        ejecutoras
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={INTERNAL_LINKS.CLIENTS}>
                      <Link to={INTERNAL_LINKS.CLIENTS}>
                        <FontAwesomeIcon icon={faPeopleArrows} />{' '}
                        Clientes-Aliados
                      </Link>
                    </Menu.Item>
                  </>
                )}
                {superUser && (
                  <>
                    <Menu.Item key={INTERNAL_LINKS.COSTS}>
                      <Link to={INTERNAL_LINKS.COSTS}>
                        <FontAwesomeIcon icon={faUniversity} /> Centro de Costos
                      </Link>
                    </Menu.Item>
                  </>
                )}
                {(coordinator ||
                  assistant ||
                  manager ||
                  legal ||
                  superUser ||
                  lead) && (
                  <>
                    <Menu.Item key={INTERNAL_LINKS.PROJECTS}>
                      <Link to={INTERNAL_LINKS.PROJECTS}>
                        <FontAwesomeIcon icon={faProjectDiagram} /> Proyectos
                      </Link>
                    </Menu.Item>
                  </>
                )}
                <Menu.Item key={INTERNAL_LINKS.EMPLOYEES}>
                  <Link to={INTERNAL_LINKS.EMPLOYEES}>
                    <FontAwesomeIcon icon={faSitemap} /> Empleados
                  </Link>
                </Menu.Item>
              </SubMenu>
            </>
          )}
          {(legal || superUser) && (
            <>
              <SubMenu
                key="item-submenu-parametros"
                title={<Title level={5}>Parámetros</Title>}
              >
                {superUser && (
                  <Menu.Item key={INTERNAL_LINKS.ROLES_MANAGEMENT}>
                    <Link to={INTERNAL_LINKS.ROLES_MANAGEMENT}>
                      <FontAwesomeIcon
                        icon={faUsersCog}
                        style={{ marginRight: '5px' }}
                      />
                      Administración de roles
                    </Link>
                  </Menu.Item>
                )}
                {(legal || superUser) && (
                  <>
                    <Menu.Item key={INTERNAL_LINKS.TEMPLATES}>
                      <Link to={INTERNAL_LINKS.TEMPLATES}>
                        <FontAwesomeIcon icon={faFileExcel} /> Plantillas
                      </Link>
                    </Menu.Item>
                  </>
                )}
                {(superUser || legal) && (
                  <>
                    {(legal || superUser) && (
                      <Menu.Item key={INTERNAL_LINKS.POLITICS}>
                        <Link to={INTERNAL_LINKS.POLITICS}>
                          <FontAwesomeIcon icon={faAward} /> Políticas
                        </Link>
                      </Menu.Item>
                    )}
                    {superUser && (
                      <>
                        <Menu.Item key={INTERNAL_LINKS.HELP_FORMAT}>
                          <Link to={INTERNAL_LINKS.HELP_FORMAT}>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ marginRight: '5px' }}
                            />
                            Ayudas Formato PDF
                          </Link>
                        </Menu.Item>
                        <Menu.Item key={INTERNAL_LINKS.AREAS}>
                          <Link to={INTERNAL_LINKS.AREAS}>
                            <FontAwesomeIcon icon={faBriefcase} /> Áreas
                          </Link>
                        </Menu.Item>
                        <Menu.Item key={INTERNAL_LINKS.CURRENCY_TYPES}>
                          <Link to={INTERNAL_LINKS.CURRENCY_TYPES}>
                            <FontAwesomeIcon icon={faMoneyCheck} /> Tipos de
                            monedas
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {(superUser || legal) && (
                      <Menu.Item key={INTERNAL_LINKS.ORGANIZATIONS_TYPES}>
                        <Link to={INTERNAL_LINKS.ORGANIZATIONS_TYPES}>
                          <FontAwesomeIcon icon={faSitemap} /> Tipos de
                          Organizaciones
                        </Link>
                      </Menu.Item>
                    )}
                    {superUser && (
                      <>
                        <Menu.Item key={INTERNAL_LINKS.COUNTRIES}>
                          <Link to={INTERNAL_LINKS.COUNTRIES}>
                            <FontAwesomeIcon icon={faGlobeAmericas} /> Países
                          </Link>
                        </Menu.Item>
                        <Menu.Item key={INTERNAL_LINKS.STATES}>
                          <Link to={INTERNAL_LINKS.STATES}>
                            <FontAwesomeIcon icon={faFlagUsa} /> Departamentos
                          </Link>
                        </Menu.Item>
                        <Menu.Item key={INTERNAL_LINKS.CITIES}>
                          <Link to={INTERNAL_LINKS.CITIES}>
                            <FontAwesomeIcon icon={faCity} /> Ciudades
                          </Link>
                        </Menu.Item>
                        <Menu.Item key={INTERNAL_LINKS.IDENTITIES_TYPES}>
                          <Link to={INTERNAL_LINKS.IDENTITIES_TYPES}>
                            <FontAwesomeIcon icon={faIdCard} /> Tipo de
                            Identificación
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                  </>
                )}
              </SubMenu>
            </>
          )}
          {superUser && (
            <SubMenu
              key="item-submenu-reportes"
              title={<Title level={5}>Reportes</Title>}
            >
              <Menu.Item key={INTERNAL_LINKS.REPORTS_TDR}>
                <Link to={INTERNAL_LINKS.REPORTS_TDR}>
                  <FontAwesomeIcon icon={faIdCard} />
                  Reportes
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {superUser && (
            <SubMenu
              key="item-submenu-sicronizacion"
              title={<Title level={5}>Sincronización</Title>}
            >
              <Menu.Item key={INTERNAL_LINKS.SIGAAPP}>
                <Link to={INTERNAL_LINKS.SIGAAPP}>
                  <FontAwesomeIcon icon={faSitemap} />
                  Descarga programa
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {superUser && (
            <SubMenu
              key="item-submenu-announcement-close-control"
              title={<Title level={5}>Control Convocatorias</Title>}
            >
              <Menu.Item key={`${INTERNAL_LINKS.ADMIN}-announcement`}>
                <Link to={`${INTERNAL_LINKS.ADMIN}-announcement`}>
                  <FontAwesomeIcon icon={faScroll} />
                  Cierre automatico
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu.ItemGroup>
      ) : null}
    </Menu>
  );
};

export default MenuApp;
