import httpClient from '../httpClient';

const getHistory = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/contract_history/${id}`,
  );
};

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/modify_contract_date/`,
    data,
  );
};

const getModificationDate = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/modification_date/${id}`,
  );
};

const patch = async (id, data) => {
  return await httpClient.patch(
    `${process.env.REACT_APP_API_HOST}/modification_date/${id}/modify_contract_date/`,
    data,
  );
};

export { getHistory, post, getModificationDate, patch };
