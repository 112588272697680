import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Row,
  Col,
  Radio,
  Select,
  Space,
} from 'antd';
import SelectIdentifitiesType from '../../components/SelectIdentifitiesType/index';
import CountryStateCity from '../../components/CountryStateCity';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { validatorNumbers } from '../../utils/helpers/helpers';

const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const ClientForm = ({ onFinish, initialValues, loading, costCenter }) => {
  const [form] = Form.useForm();
  const [dvField, setDvField] = useState(false);

  function calcularDigitoVerificacion(e) {
    var myNit = e.target.value;
    var vpri, x, y, z;
    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ''); // Espacios
    myNit = myNit.replace(/,/g, ''); // Comas
    myNit = myNit.replace(/\./g, ''); // Puntos
    myNit = myNit.replace(/-/g, ''); // Guiones
    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return;
    }
    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      x += y * vpri[z - i];
    }

    y = x % 11;
    let res = y > 1 ? 11 - y : y;
    if (res != null && res != undefined) {
      form.setFieldsValue({ digito_verificacion: res });
    } else {
      form.setFieldsValue({ digito_verificacion: '', nit: '' });
    }
  }

  const onChangeIdentificationType = (value) => {
    setDvField(false);

    if (value === 7 || value === 15) {
      setDvField(true);
    }
  };

  const validatorIdentification = (length) => {
    if (dvField) return validatorNumbers(length);

    return true;
  };

  useEffect(() => {
    onChangeIdentificationType(initialValues.tipo_identificacion);
  }, []);

  return (
    <Form
      name="nest-messages"
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ ...initialValues }}
      scrollToFirstError
      onValuesChange={(data) => {
        if (data.hasOwnProperty('constituido_colombia')) {
          let toSet;
          if (
            data.constituido_colombia === initialValues.constituido_colombia
          ) {
            toSet = {
              nit: initialValues.nit,
              digito_verificacion: initialValues.digito_verificacion,
              nit_confirm: initialValues.nit_confirm,
            };
          } else {
            toSet = {
              nit: null,
              digito_verificacion: null,
              nit_confirm: null,
            };
          }
          form.setFieldsValue(toSet);
        }
        if (data.hasOwnProperty('pais')) {
          form.setFieldsValue({
            estado: null,
            ciudad: null,
          });
        }
        if (data.hasOwnProperty('estado')) {
          form.setFieldsValue({
            ciudad: null,
          });
        }
      }}
      autoComplete="off"
      validateMessages={validateMessages}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="nombre"
            label="Nombre Razón social"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input allowClear placeholder="Nombre Razón social" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="sigla"
            label="Sigla o Nombre comercial"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input allowClear placeholder="Sigla o Nombre comercial" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="constituido_colombia"
            label="¿Está constituido en Colombia?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item noStyle dependencies={['constituido_colombia']}>
        {({ getFieldValue }) =>
          getFieldValue('constituido_colombia') === true ? (
            <Row gutter={12}>
              <Col span={10}>
                <Form.Item
                  name="nit"
                  label="NIT o documento equivalente"
                  rules={[
                    {
                      required: true,
                    },
                    validatorNumbers(9),
                  ]}
                >
                  <Input
                    allowClear
                    onBlur={calcularDigitoVerificacion}
                    placeholder="Número de documento"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  name="digito_verificacion"
                  label="DV"
                  rules={[
                    {
                      required: true,
                      message:
                        'Verifique el número de documento registrado',
                    },
                  ]}
                >
                  <Input allowClear placeholder="DV" disabled />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="nit_confirm"
                  label="Confirmar NIT o documento equivalente"
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('nit') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'El NIT o documento equivalente no coincide',
                          ),
                        );
                      },
                    }),
                    validatorNumbers(9),
                  ]}
                  dependencies={['nit']}
                >
                  <Input
                    allowClear
                    onPaste={(e) => e.preventDefault()}
                    onBlur={calcularDigitoVerificacion}
                    placeholder="Confirmar Número de documento"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  name="digito_verificacion"
                  label="DV"
                  rules={[
                    {
                      required: true,
                      message:
                        'Verifique el número de documento registrado',
                    },
                  ]}
                >
                  <Input allowClear placeholder="DV" disabled />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="num_identificacion"
                  label="Documento identificación de Razón Social"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Documento identificación de Razón Social"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="num_identificacion_confirm"
                  label="Confirmar documento identificación de Razón Social"
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('num_identificacion') === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'El Documento identificación de Razón Social no coincide',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    allowClear
                    onPaste={(e) => e.preventDefault()}
                    placeholder="Documento identificación de Razón Social"
                  />
                </Form.Item>
              </Col>
            </Row>
          )
        }
      </Form.Item>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="tipo_identidad_representante_legal"
            label="Tipo de documento del Representante Legal"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectIdentifitiesType
              onChange={onChangeIdentificationType}
              tipo_identificacion={
                initialValues.tipo_identidad_representante_legal
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="numero_documento_representante_legal"
            label="Número de documento del Representante Legal"
            rules={[
              {
                required: true,
              },
              validatorIdentification(6),
            ]}
          >
            <Input
              allowClear
              placeholder="Número de documento del Representante Legal"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="confirm_numero_documento_representante_legal"
            label="Confirmar número de documento del Representante Legal"
            rules={[
              {
                required: true,
              },
              validatorIdentification(6),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    getFieldValue('numero_documento_representante_legal') ===
                      value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'El Número de documento del Representante Legal no coincide',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input
              allowClear
              onPaste={(e) => e.preventDefault()}
              placeholder="Confirmar Número de documento del Representante Legal"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="representante_legal_nombre"
            label="Nombre(s) Representante legal"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input allowClear placeholder="Nombre(s) Representante legal" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="representante_legal_apellido"
            label="Apellidos(s) Representante legal"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              allowClear
              placeholder="Apellidos(s) Representante legal"
            />
          </Form.Item>
        </Col>
      </Row>
      <CountryStateCity
        countryKey="pais"
        stateKey="estado"
        cityKey="ciudad"
        countryLabel="País"
        stateLabel="Departamento"
        cityLabel="Ciudad"
        country={initialValues.pais}
        state={initialValues.estado}
        city={initialValues.ciudad}
        required={true}
      />
      <Form.Item
        name="direccion"
        label="Dirección de notificación"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input allowClear placeholder="Dirección de notificación" />
      </Form.Item>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="telefono"
            label="Teléfono de notificación"
            rules={[
              {
                required: true,
              },
              validatorNumbers(7, true),
            ]}
          >
            <Input allowClear placeholder="Teléfono de notificación" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="telefonoConfirm"
            label="Confirme el teléfono de notificación"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('telefono') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('El Teléfono de notificación no coincide'),
                  );
                },
              }),
            ]}
          >
            <Input
              allowClear
              onPaste={(e) => e.preventDefault()}
              placeholder="Teléfono de notificación"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="otros_numeros_telefonicos"
            label="Otros números telefónicos"
          >
            <Input allowClear placeholder="Otros números telefónicos..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="correo"
            label="Correo electrónico de notificación"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input
              allowClear
              placeholder="Correo electrónico de notificación"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="correoConfirm"
            label="Confirme el correo electrónico de notificación"
            rules={[
              {
                required: true,
                type: 'email',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('correo') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'El correo electrónico de notificación no coincide',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input
              allowClear
              onPaste={(e) => e.preventDefault()}
              placeholder="Confirme el correo electrónico de notificación"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            name="centro_costo_cliente"
            label="Centro de costo del cliente - aliado"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Seleccione centro de costo"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {costCenter != null &&
                costCenter.map((item) => (
                  <Select.Option
                    key={`cost-center-key-${item.id}`}
                    value={item.id}
                  >
                    {item.nombre}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <Space>
            <CustomButton text="Guardar" loading={loading} />
            <CustomButton
              type={'default'}
              className={'back-button'}
              htmlType="button"
              text={<Link to={INTERNAL_LINKS.CLIENTS}> Volver </Link>}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

ClientForm.propTypes = {
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  costCenter: PropTypes.array,
};

ClientForm.defaultProps = {
  onFinish: () => {},
  initialValues: {},
  loading: false,
  costCenter: [],
};

export default ClientForm;
