import React from 'react';
import { Descriptions } from 'antd';

import './style.scss';

const AnnouncementPreselectionForm = ({
  initialValues,
  useDescriptions = false,
  descriptionsTitle = '',
  descriptionsColumns = 1,
}) => {
  return (
    <Descriptions
      layout="vertical"
      title={descriptionsTitle}
      column={descriptionsColumns}
      bordered
    >
      <Descriptions.Item label="Número de convocatoria">
        {initialValues.numero}
      </Descriptions.Item>
      <Descriptions.Item label="Nombre de la convocatoria">
        {initialValues.nombre}
      </Descriptions.Item>
      <Descriptions.Item label="Organización">
        {initialValues.organizacion_nombre}
      </Descriptions.Item>
      <Descriptions.Item label="Proyecto">
        {initialValues.proyecto_nombre !== null
          ? initialValues.proyecto_nombre
          : initialValues.nombre_proyecto !== null
          ? initialValues.nombre_proyecto
          : null}
      </Descriptions.Item>
      <Descriptions.Item label="Tipo de convocatoria">
        {initialValues.tipo === true
          ? 'Abierta'
          : initialValues.tipo === false
          ? 'Cerrada'
          : 'No asignado'}
      </Descriptions.Item>
      <Descriptions.Item label="Cargo/Rol">
        {initialValues.cargo}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AnnouncementPreselectionForm;
