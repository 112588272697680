import httpClient from '../httpClient';

const post = async (data) => {
	return await httpClient.post(`/term_differentiated_values/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_differentiated_values/${id}`);
};

const getAllByTerm = async (id) => {
  return await httpClient.get(`/term_differentiated_values/?filter[termino]=${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/term_differentiated_values/${id}/`, data);
};

const patch = async (id) => {
  return await httpClient.patch(`/term_differentiated_values/${id}`);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_differentiated_values/${id}`);
};

export {
  post,
  get,
  getAllByTerm,
  put,
  patch,
  remove,
};
