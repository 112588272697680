import React, { memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Popconfirm,
  Typography,
  Form,
  Input,
  Radio,
  notification,
  Card,
  Space,
  Alert,
  Modal,
  InputNumber,
} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import {
  post,
  put,
  remove,
  renumerate,
  ajaxTable as filterAjaxTable,
  swap,
  move,
} from '../../api/module/term_responsibilities';
import { get as getProject } from '../../api/module/projects';

import './style.scss';
import AjaxTable from '../../components/AjaxTable';
import { catchException } from '../../utils/helpers/helpers';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;
let textareaRef;

const ResponsibilitiesList = ({
  handleEdit,
  deleteDataSource,
  termID,
  reload,
  onSwap,
  onMove,
  moveID,
  swapID,
  lSwap,
}) => {
  const [deleteID, setDeleteID] = useState();

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      width: '5rem',
      align: 'center',
    },
    {
      title: 'Responsabilidad u obligación',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      width: 150,
      render: (_, record) => {
        return (
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <CustomButton
              block
              htmlType={'button'}
              text="Editar"
              onClick={() => handleEdit(record)}
            />
            <CustomButton
              block
              type={record.id === swapID ? 'dashed' : 'default'}
              htmlType={'button'}
              text={
                lSwap && (record.id === swapID || record.id === lSwap)
                  ? 'Cambiando'
                  : record.id === swapID
                  ? 'Deseleccionar'
                  : 'Intercambiar'
              }
              onClick={() => onSwap(record)}
              loading={lSwap && (record.id === swapID || record.id === lSwap)}
            />
            <CustomButton
              block
              type={record.id === moveID ? 'dashed' : 'default'}
              htmlType={'button'}
              text={moveID && record.id === moveID ? 'Cambiando' : 'Mover a...'}
              onClick={() => onMove(record)}
              loading={moveID && record.id === moveID}
            />
            <CustomButton
              block
              type={'default'}
              htmlType={'button'}
              loading={record.id === deleteID}
              danger
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => {
                    setDeleteID(record.id);
                    deleteDataSource(record.id);
                  }}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </Space>
        );
      },
    },
  ];

  return (
    <AjaxTable
      columns={columns}
      searchInput
      tableTitle={'Listado de responsabilidades'}
      endpoint={filterAjaxTable}
      reloadButton
      reloadSource={reload}
      scroll={{ x: false }}
      query={'/?filter[termino]=' + termID}
      showSizeChanger
      pageSize={50}
    />
  );
};

const ResponsibilityForm = memo(({ termID, initialValues, id, doReload }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        descripcion: initialValues.descripcion,
      });
    }
  }, [initialValues]);

  const save = async (data) => {
    try {
      let response;
      if (!id) {
        const newData = {
          termino: termID,
          ...data,
        };
        setLoading(true);
        response = await post(newData);
      } else {
        const newData = {
          termino: termID,
          ...data,
        };
        setLoading(true);
        response = await put(id, newData);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        form.resetFields();
        doReload();
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 20 },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 10,
      offset: 11,
    },
  };

  return (
    <Form
      name="control-ref"
      form={form}
      onFinish={(data) => save(data)}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        {...formItemLayout}
        name="descripcion"
        label="Responsabilidad u obligación específica del contratista. Recuerde que debe agregarlas una a una."
        rules={[
          {
            required: true,
            message: 'Este campo es obligatorio!',
          },
        ]}
      >
        <TextArea
          ref={(textarea) => {
            textareaRef = textarea;
          }}
          rows={4}
          bordered
          allowClear
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <CustomButton loading={loading} text={id ? 'Editar' : 'Agregar'} />
      </Form.Item>
    </Form>
  );
});

export default function ResponsibilitiesForm({ match }) {
  const [termID] = useState(parseInt(match.params.term_id));
  const [projectID] = React.useState(parseInt(match.params.project_id));
  const [contractID] = React.useState(parseInt(match.params.contract_id));
  const [data, setData] = useState(undefined);
  const [id, setId] = useState(0);
  const [project, setProject] = useState([]);

  const [lRenumerate, setLRenumerate] = useState(false);
  const [swapData, setSwapData] = useState(undefined);
  const [lSwap, setLSwap] = useState(undefined);
  const [moveData, setMoveData] = useState(undefined);

  const [reloadTable, setReloadTable] = useState();

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}`,
    },
    {
      text: 'Responsabilidades u obligaciones específicas del contratista',
    },
  ];

  const getProjectData = async () => {
    const response = await getProject(projectID);
    setProject(response.data);
  };

  useEffect(() => {
    getProjectData();
  }, []);

  const handleEdit = (item) => {
    setData(item);
    setId(item.id);
    setTimeout(() => {
      textareaRef.focus();
    }, 500);
  };

  const doReload = () => {
    setData(undefined);
    setId(undefined);
    setReloadTable({});
  };

  const doRenumerate = async () => {
    try {
      setLRenumerate(true);
      await renumerate(termID);
      doReload();
      setLRenumerate(false);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
    } catch (error) {
      catchException(error);
    }
  };

  const doSwap = async (a, b) => {
    try {
      setLSwap(b);
      await swap(a, b);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        duration: 2,
      });
      setSwapData(undefined);
      setLSwap(undefined);
      doReload();
    } catch (error) {
      catchException(error);
    }
  };

  const onSwap = (row) => {
    if (swapData) {
      if (row.id === swapData.id) setSwapData(undefined);
      else doSwap(swapData.id, row.id);
    } else {
      setSwapData(row);
    }
  };

  const onMove = (row) => {
    setMoveData(row);
  };

  const doMove = async (values) => {
    try {
      setMoveData((s) => ({ ...s, loading: true }));
      await move(moveData.id, values);
      setMoveData();
      setReloadTable({});
    } catch (error) {
      setMoveData((s) => ({ ...s, loading: false }));
      catchException(error);
    }
  };

  // Delete Validation
  const deleteDataSource = async (id) => {
    try {
      const response = await remove(id);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
        });
      }
      if (id === swapData?.id) setSwapData(undefined);
      setReloadTable({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description:
          'Esta responsabilidad ya se encuentra vinculada a un pago. Por favor, primero, elimine la forma de pago y, luego proceda a eliminar la responsabilidad',
        duration: 2,
      });
    }
  };

  return (
    <div className="terms-location-container">
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        TÉRMINOS DE REFERENCIA
      </Title>
      <Title level={3}>PROYECTO: {project && project.nombre}</Title>
      <Paragraph>
        Los siguientes términos de referencia complementan el texto del contrato
        firmado entre las partes y están subordinados a dichos contratos.
      </Paragraph>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>C. ESPECIFICACIONES DEL CONTRATO</Title>}
      >
        <Title level={4}>
          Responsabilidades u obligaciones específicas del contratista
        </Title>
        {!data && !id && (
          <>
            <ResponsibilityForm termID={termID} id={id} doReload={doReload} />
            <CustomButton
              htmlType="button"
              text="Renumerar"
              onClick={doRenumerate}
              loading={lRenumerate}
            />
          </>
        )}
        {data && id && (
          <>
            <ResponsibilityForm
              termID={termID}
              initialValues={data}
              doReload={doReload}
              id={id}
            />
            <CustomButton
              htmlType="button"
              text="Renumerar"
              onClick={doRenumerate}
              loading={lRenumerate}
            />
          </>
        )}
        {swapData && (
          <>
            <br />
            <br />
            <Alert
              message="Intercambio de código"
              description={
                <>
                  Se ha seleccionado para intercambio de código el item
                  <br />
                  <br />
                  Código {swapData.codigo}: {swapData.descripcion}
                </>
              }
              type={'info'}
              showIcon
            />
          </>
        )}
        <ResponsibilitiesList
          termID={termID}
          handleEdit={handleEdit}
          deleteDataSource={deleteDataSource}
          reload={reloadTable}
          onSwap={onSwap}
          onMove={onMove}
          moveID={moveData?.id}
          swapID={swapData?.id}
          lSwap={lSwap}
        />
        <CustomButton
          type={'default'}
          className={'back-button'}
          htmlType="button"
          text={
            <Link
              to={`${INTERNAL_LINKS.TERM}/${termID}/${projectID}/${contractID}/?module=C`}
            >
              Volver
            </Link>
          }
        />
      </Card>
      <Modal
        visible={moveData?.id}
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Mover a...
          </Title>
        }
        footer={null}
        closable
        onCancel={() => setMoveData()}
        destroyOnClose
      >
        <Form onFinish={doMove}>
          <Form.Item
            name="new_code"
            label="Nuevo código"
            rules={[
              { required: true, message: 'El nuevo código es requerido' },
              { type: 'number', min: 1, message: 'Ingrese un valor positivo' },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Space align="end">
            <CustomButton
              htmlType="button"
              className={'back-button'}
              type="default"
              text="Volver"
              onClick={() => setMoveData()}
            />
            <CustomButton
              loading={moveData?.loading}
              htmlType="submit"
              text="Mover"
            />
          </Space>
        </Form>
      </Modal>
    </div>
  );
}
