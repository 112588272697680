import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/states/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/states/?page[size]=1000`,
  );
};

const getByCountry = async (countryId) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/states/?page[size]=1000&filter[pais]=${countryId}&sort=nombre`,
  );
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/states/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/states/${id}/`,
    data,
  );
};

const patch = async (id) => {
  return await httpClient.patch(
    `${process.env.REACT_APP_API_HOST}/states/${id}`,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/states/${id}`,
  );
};

const filter = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = false,
  search = false,
) => {
  return await httpClient.get(
    `/states/${query}?page[number]=${page}&page[size]=${pageSize}&${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

export { post, getAll, get, put, patch, remove, getByCountry, filter };
