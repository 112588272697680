import React, { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Row, Col, InputNumber } from 'antd';

import CustomButton from '../../components/CustomButton';

import { getAllByTerm as getAllResponsibilitiesProducts } from '../../api/module/term_pay_responsibility_product';
import { getAllByTermPagos as getAllResponsibilities } from '../../api/module/term_responsibilities';
import { getAllByTermPagos as getAllProducts } from '../../api/module/term_products';

import './style.scss';

const { Option } = Select;

const validateMessages = {
  required: '${label} Es Requerido!',
};

const TermPaymentForm = memo(({
  onFinish,
  formRef,
  loading,
  inputLoading,
  ids,
  pago_id,
  usedResponsibilities,
  usedProducts,
}) => {
  const [responsibilitiesProducts, setResponsibilitiesProducts] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [products, setProducts] = useState([]);
  const [newResponsibilities, setNewResponsibilities] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(0);
  const [selectedResponsibilities, setSelectedResponsibilities] = useState(0);
  const [checkPR, setCheckPR] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    handleResponsibilitiesProducts();
    handleGetResponsibilities();
    handleGetProducts();
  }, []);

  const handleResponsibilitiesProducts = async () => {
    try {
      const response = await getAllResponsibilitiesProducts(ids.term_id);
      setResponsibilitiesProducts(response.data.results);
    }
    catch (err) {
      console.log(err);
    }
  }
  // Get responsibilities by term
  const handleGetResponsibilities = async () => {
    try {
      const response = await getAllResponsibilities(ids.term_id);
      setResponsibilities(response.data.results);
      setSelectedResponsibilities(response.data.results.length);

    }
    catch (err) {
      console.log(err);
    }
  }

  // Get products by term
  const handleGetProducts = async () => {
    try {
      const response = await getAllProducts(ids.term_id);
      setProducts(response.data.results);
      setSelectedProducts(response.data.results.length);
    }
    catch (err) {
      console.log(err);
    }
  }
  const handleFilteringResponsibilities = useMemo(() => {
    const allTermResponsibilitiesProducts = [...responsibilitiesProducts]
    const allTermResponsibilitiesKeys = [];
    const termResponsibilities = [...responsibilities]

    let termUsedResponsibilities;
    let termUnusedResponsibilities;

    allTermResponsibilitiesProducts.forEach(item => {
      if (item.tipo === 'Obligación')
        allTermResponsibilitiesKeys.push(item.tipo_codigo);
    });
    termUsedResponsibilities = termResponsibilities.filter(item => allTermResponsibilitiesKeys.indexOf(item.codigo) !== -1);
    termUnusedResponsibilities = termResponsibilities.filter(item => allTermResponsibilitiesKeys.indexOf(item.codigo) === -1);

    if (!pago_id)
      setNewResponsibilities(termUnusedResponsibilities);
    else {
      if (usedResponsibilities !== null) {
        let termSelectedResponsibilities = termUsedResponsibilities.filter(item => usedResponsibilities.indexOf(item.id) !== -1);
        setNewResponsibilities(termSelectedResponsibilities.concat(termUnusedResponsibilities));

        if (usedResponsibilities.length > 0)
          setCheckPR(false)
      }
    }
  }, [responsibilities, pago_id, responsibilitiesProducts, usedResponsibilities])

  const handleFilteringProducts = useMemo(() => {
    const allTermResponsibilitiesProducts = [...responsibilitiesProducts]
    const allTermProductsKeys = [];
    const termProducts = [...products]

    let termUsedProducts;
    let termUnusedProducts;

    allTermResponsibilitiesProducts.forEach(item => {
      if (item.tipo === 'Producto')
        allTermProductsKeys.push(item.tipo_codigo);
    });
    termUsedProducts = termProducts.filter(item => allTermProductsKeys.indexOf(item.codigo) !== -1);
    termUnusedProducts = termProducts.filter(item => allTermProductsKeys.indexOf(item.codigo) === -1);

    if (!pago_id)
      setNewProducts(termUnusedProducts)
    else {
      if (usedProducts !== null) {
        let termSelectedProducts = termUsedProducts.filter(item => usedProducts.indexOf(item.id) !== -1);
        setNewProducts(termSelectedProducts.concat(termUnusedProducts));

        if (usedProducts.length  > 0)
          setCheckPR(false)
      }
    }
  }, [products, pago_id, responsibilitiesProducts, usedProducts])

  return (
    <Form
      ref={formRef}
      form={form}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        {...{ labelCol: { span: 0 }, wrapperCol: { span: 24 } }}
        name='valor'
        hasFeedback={inputLoading}
        label='Valor del pago (Incluyendo impuestos, tasas y contribuciones)'
        rules={[
          {
            required: true,
            message: 'Este campo es requerido',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder='Valor total en COP...'
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item
        {...{ labelCol: { span: 0 }, wrapperCol: { span: 24 } }}
        name='numero'
        hasFeedback={inputLoading}
        label='Número del pago'
        rules={[
          {
            required: true,
            message: 'Este campo es requerido',
          },
        ]}
      >
        <Input type='number' placeholder="Dígite Número del pago..." rows={1} style={{ width: '100%' }} allowClear />
      </Form.Item>
      <Row gutter={12}>
        <Col span={13}>
          <Form.Item
            name='responsabilidad'
            label={"Seleccione las responsabilidades u obligaciones específicas asociadas al pago"}
            hasFeedback={inputLoading}
            rules={[
              {
                required: checkPR,
                message: 'Seleccione un producto y/o responsabilidad',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleccione las responsabilidades..."
              onChange={(item) => {
                setSelectedResponsibilities(item.length);
                setCheckPR(true)

                if (item.length + selectedProducts > 0)
                  setCheckPR(false)
              }}
            >
              {newResponsibilities != null &&
                newResponsibilities.map((item) =>
                  <Option key={`responsibility-${item.id}`} value={parseInt(item.id)}>{item.descripcion}</Option>
                )
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name='producto'
            label={"Seleccione los productos y/o entregables asociados al pago"}
            hasFeedback={inputLoading}
            rules={[
              {
                required: checkPR,
                message: 'Seleccione un producto y/o responsabilidad',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleccione los productos ..."
              onChange={(item) => {
                setSelectedProducts(item.length);
                setCheckPR(true)

                if (selectedResponsibilities + item.length > 0)
                  setCheckPR(false)
              }}
            >
              {
                newProducts &&
                newProducts.map((item) =>
                  <Option key={`product-${item.id}`} value={parseInt(item.id)}>{item.descripcion}</Option>
                )
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="box-btn-center">
          <CustomButton loading={loading} text="Agregar" />
        </div>
      </Form.Item>
    </Form>
  );
});

TermPaymentForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  inputLoading: PropTypes.bool,
  ids: PropTypes.object,
};

TermPaymentForm.defaultProps = {
  title: '',
  onFinish: () => { },
  loading: false,
  inputLoading: false,
  ids: {},
};

export default TermPaymentForm;
