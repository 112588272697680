import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll, get } from '../../api/module/organizations_types';

import './style.scss';

const SelectOrganizationType = ({
  className = '',
  onChange = () => {},
  organization_type,
  disabled = false,
}) => {
  const [data, setData] = useState(null);

  const getTypeOrganizations = () => {
    getAll().then((res) => setData(res.data.results));
  };

  useEffect(() => {
    if (disabled) {
      if (organization_type)
        get(organization_type).then((res) => setData([res.data]));
    } else getTypeOrganizations();
  }, []);

  return (
    <Select
      placeholder="Seleccione el tipo de organización"
      className={className}
      onChange={onChange}
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      defaultValue={organization_type}
      disabled={disabled}
    >
      {data != null &&
        data.map((item) => (
          <Select.Option key={`organization-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
export default SelectOrganizationType;
