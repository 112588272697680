import React, { useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Form,
  Select,
  Space,
  Alert,
  Modal,
  Input,
} from 'antd';

import { CustomButton, CustomSelector } from '../../../components';

import AjaxTable from '../../../components/AjaxTable';
import {
  getIdentifierSelector,
  getTermsSelector,
  massContractSearch,
} from '../../../api/module/mass_contract_modifications';

const { Paragraph } = Typography;
const { TextArea } = Input;

const MassContractSearch = ({ project_id, next, positions }) => {
  const [query, setQuery] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [form] = Form.useForm();

  const [nextForm] = Form.useForm();

  const [selected, setSelected] = useState([]);

  const [errorMassID, setErrorMassID] = useState(false);

  const onFinish = async (data) => {
    setLoading(true);
    const q = Object.entries(data || {}).reduce((pv, cv) => {
      if ((cv[0] === 'identificador_masivo') & (cv[1] === 1))
        pv.push(`filter[identificador_masivo__isnull]=true`);
      else if (cv[1]) pv.push(`filter[${cv[0]}]=${cv[1]}`);
      return pv;
    }, []);
    q.push(`filter[proyecto]=${project_id}`);
    setSelected([]);
    setQuery(q.join('&'));
    setLoading(false);
  };

  const rowSelection = {
    columnTitle: 'Seleccione los contratistas que modificará masivamente',
    fixed: true,
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selected.map((v) => v.id),
    onChange: (_selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        const c = selectedRows[selectedRows.length - 1];
        setSelected(
          selectedRows.filter(
            (v) =>
              (!!c.identificador_masivo
                ? v.identificador_masivo === c.identificador_masivo
                : !v.mod_num) && v.identificador === c.identificador,
          ),
        );
      } else {
        setSelected(selectedRows);
      }
    },
    renderCell: (_checked, record, _index, originNode) => {
      if (record.mod_draft || record.status_pc) return null;
      if (
        (selected.length > 0 &&
          record.identificador_masivo !== selected[0].identificador_masivo) ||
        (record.mod_num > 0 && !record.identificador_masivo)
      )
        return null;
      return originNode;
    },
  };

  const columns = [
    {
      title: 'Cargo/Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'Identificador TDR',
      dataIndex: 'identificador',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    {
      title: 'Nombre Contratista/Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: '# de modificaciones realizadas',
      dataIndex: 'mod_num',
      width: '1rem',
      align: 'center',
    },
    {
      title: 'Identificador interno última Modificación',
      dataIndex: 'identificador_masivo',
      width: '10rem',
      align: 'center',
      render: (text, record) => {
        if (record.mod_draft)
          return (
            <>
              {text && (
                <>
                  {text}
                  <br />
                </>
              )}
              {record.md_text === 'i_'
                ? 'No habilitado - Modificación individual en curso'
                : `No habilitado - Modificación masiva '${record.md_text.substring(
                    2,
                  )}' en curso`}
            </>
          );
        if (record.status_pc)
          return (
            <>
              {text && (
                <>
                  {text}
                  <br />
                </>
              )}
              {record.status_pc === 1
                ? 'Terminación en curso'
                : record.status_pc === 2
                ? 'Terminación finalizada'
                : 'No habilitado'}
            </>
          );
        if (
          (selected.length > 0 &&
            record.identificador_masivo !== selected[0].identificador_masivo) ||
          (record.mod_num > 0 && !record.identificador_masivo)
        )
          return (
            <>
              {text && (
                <>
                  {text}
                  <br />
                </>
              )}
              No habilitado - Distinto grupo de modificación masiva
            </>
          );
        return text;
      },
    },
  ];

  const onNext = async (data) => {
    setLoadingNext(true);
    try {
      await next({ ...data, contracts: selected });
    } catch (error) {
      setLoadingNext(false);
      if (error.isMassIDErr) setErrorMassID(true);
    }
  };

  return (
    <div>
      <Alert
        showIcon
        type="info"
        message="Información importante"
        description="Los resultados de la búsqueda mostrará los contratistas/proveedores que han aceptado contratación en el proyecto seleccionado con contrato VIGENTE o ACTIVO."
      />
      <br />
      <Paragraph>
        Seleccione el criterio por el cual desea realizar la modificación
        contractual masiva
      </Paragraph>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        preserve={false}
        onValuesChange={(cv) => {
          if ('cargo' in cv) {
            form.setFieldsValue({
              termino: undefined,
              identificador_masivo: undefined,
            });
          }
          if ('termino' in cv) {
            form.setFieldsValue({
              identificador_masivo: undefined,
            });
          }
        }}
      >
        <Form.Item
          label="Cargo"
          name="cargo"
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Select
            placeholder="Seleccione un cargo/rol..."
            allowClear
            options={positions}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['cargo']}>
          {({ getFieldValue }) => {
            const cargo = getFieldValue('cargo');
            if (cargo) {
              return (
                <Form.Item
                  label="Identificador interno del TDR"
                  name="termino"
                  rules={[
                    { required: true, message: 'Este campo es requerido' },
                  ]}
                >
                  <CustomSelector
                    selectorProps={{
                      style: { width: '100%' },
                      placeholder:
                        'Seleccione el identificador único del termino de referencia... ',
                    }}
                    endpointFetch={async () => {
                      return await getTermsSelector(cargo);
                    }}
                    selectorKey={cargo}
                  />
                </Form.Item>
              );
            } else return null;
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['cargo', 'termino']}>
          {({ getFieldValue }) => {
            const termino = getFieldValue('termino');
            if (termino) {
              return (
                <Form.Item
                  label="Identificador interno de la Modificación masiva"
                  name="identificador_masivo"
                  tooltip={{
                    title:
                      'Seleccione el identificador interno de la modificación contractual a realizar según el listado de resultados en caso de requerirlo',
                  }}
                >
                  <CustomSelector
                    selectorProps={{
                      style: { width: '100%' },
                      placeholder:
                        'Seleccione el identificador interno de la modificación masiva... ',
                    }}
                    endpointFetch={async () => {
                      return await getIdentifierSelector(termino);
                    }}
                    selectorKey={termino}
                  />
                </Form.Item>
              );
            } else return null;
          }}
        </Form.Item>
        <Row gutter={8} justify="end">
          <Col>
            <CustomButton loading={loading} text="Buscar" />
          </Col>
          <Col>
            <CustomButton
              htmlType="button"
              type="default"
              onClick={() => {
                form.resetFields();
              }}
              danger={true}
              text="Borrar filtros"
            />
          </Col>
        </Row>
      </Form>
      <br />
      {query && (
        <>
          <AjaxTable
            columns={columns}
            tableTitle={'Listado de resultados'}
            endpoint={massContractSearch}
            rowSelection={rowSelection}
            query={query}
            searchInput
            exportCSV
          />

          <br />
          <Form form={nextForm} onFinish={onNext}>
            <Form.Item
              name="identificador_masivo"
              label="Identificador interno de la modificación masiva"
              rules={[{ required: true }]}
            >
              <TextArea
                placeholder="Digite aquí"
                maxLength={50}
                showCount
                autoSize={{ minRows: 1, maxRows: 1 }}
              />
            </Form.Item>
          </Form>

          {errorMassID && (
            <>
              <Alert
                showIcon
                message="El identificador masivo ya existe."
                type="error"
              />
              <br />
            </>
          )}

          <Space style={{ justifyContent: 'space-evenly', width: '100%' }}>
            <CustomButton
              type="primary"
              text="Iniciar modificación"
              onClick={() => {
                if (selected.length < 2) {
                  Modal.error({
                    title: 'Error',
                    okText: 'Ok',
                    content:
                      'Recuerde que debe seleccionar dos o más personas para continuar, de lo contrario, seleccione la opción de "Nueva modificación contractual - Individual"',
                  });
                } else nextForm.submit();
              }}
              loading={loadingNext}
            />
            <CustomButton type="info" text="Otras acciones" />
          </Space>
        </>
      )}
    </div>
  );
};

export default MassContractSearch;
