import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CustomButton } from '../../../components';

import BreadcrumbContainer from '../../../containers/Breadcrumb';

import PdfViewer from '../../../components/PdfViewer';
import AjaxTable from '../../../components/AjaxTable';

import { getResume } from '../../../api/module/term_resume_contract';
import { getAllBySelfAndTermVal } from '../../../api/module/term_resume_validation';
import { get as getDocumentUrl } from '../../../api/module/document_repository';

import INTERNAL_LINKS from '../../../enums/InternalLinks';

import "./style.scss";

const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const DocumentValidationContractualResume = ({history, match }) => {
  const breadcrumbItems = [
    {
      text: 'Validación de Documentos Jurídico',
      to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}`,
    },
    {
      text: 'Validación Jurídico',
    },
  ];
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);

  const columns = [
    {
      title: 'Tipo Documento',
      dataIndex: 'plantilla_nombre',
      key: 'plantilla_nombre',
      render: (_, record) => <>{record.plantilla_codigo != null ? record.plantilla_codigo + ' - ' + record.plantilla_nombre: record.plantilla_nombre}</>
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <Button
            type="default"
            onClick={async () => {
              const response = await getDocumentUrl(
                record.documento_firmado_nombre,
              );
              setPdfUrl(response.data.url);
              setShowPdf(true);
            }}
          >
            Visualizar
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getContractInformation();
  }, []);

  const getContractInformation = async () => {
    try {
      setLoading(true);
      const response = await getResume(match.params.id);
      setContract(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />

      <div className="box-title">
        <Title>DOCUMENTOS CONTRACTUALES</Title>
      </div>

      {loading === true ? (
        <div className="spin-container">
          <Spin indicator={antIcon}></Spin>
        </div>
      ) : (
        <>
          {contract != null && (
            <Descriptions bordered={true} column={2}>
              <Descriptions.Item label="Proyecto" span={2}>
                {contract.termino.proyecto_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Nro. Contrato">
                {contract.numero_contrato}
              </Descriptions.Item>
              <Descriptions.Item label="Cargo">
                {contract.termino.cargo_nombre}
              </Descriptions.Item>
            </Descriptions>
          )}
          <br></br>
          <br></br>
          <AjaxTable
            endpointFetch={async (page, pageSize) => {
              const thisQuery = `page[size]=1000&filter[tercero]=${contract.tercero.id}&filter[termino]=${contract.termino.id}`;
              return await getAllBySelfAndTermVal(`?${thisQuery}`);
            }}
            columns={columns}
          ></AjaxTable>

          <PdfViewer
            pdf={pdfUrl}
            onCancel={() => {
              setPdfUrl(null);
              setShowPdf(false);
            }}
            visible={showPdf}
          />

          <CustomButton
            type={'default'}
            className={'back-button'}
            htmlType="button"
            onClick={() => back()}
            text="Volver"
          />


        </>
      )}
    </>
  );
};

export default DocumentValidationContractualResume;
