import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';

import CustomButton from '../../../../components/CustomButton';
import SpinLoad from '../../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../../enums/InternalLinks';
import Paragraph from 'antd/lib/typography/Paragraph';
import AjaxTable from '../../../../components/AjaxTable';
import { ajaxTable as filterAjaxTable } from '../../../../api/module/announcement_preselection';

const AjustarInformacion = ({
  dataSource,
  announcement_id,
  announcement_data,
  announcement_rol_id,
  loading,
  showHistory = () => {},
  historyLoading = false,
}) => {
  const columns = [
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero_data', 'notificacion_email'],
      key: ['tercero_data', 'notificacion_email'],
      align: 'center',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero_data', 'nombre_completo'],
      key: ['tercero_data', 'nombre_completo'],
      align: 'center',
    },
    {
      title: 'Tipo Identificación',
      dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      key: ['tercero_data', 'tipo_identificacion_nombre'],
      align: 'center',
    },
    {
      title: 'Doc Identificación',
      dataIndex: ['tercero_data', 'numero_identificacion'],
      key: ['tercero_data', 'numero_identificacion'],
    },
    {
      title: 'Educación',
      dataIndex: 'estudio',
      key: 'estudio',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 1) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_EDUCATION}/${announcement_id}/${record.id}/${announcement_rol_id}/${record.tercero}`}
                >
                  Ver
                </Link>
              }
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado educación',
      dataIndex: 'estado_educacion_nombre',
      key: 'estado_educacion',
      align: 'center',
    },
    {
      title: 'Experiencia',
      dataIndex: 'experiencia',
      key: 'experiencia',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 1) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_LABORAL}/${announcement_id}/${record.id}/${announcement_rol_id}/${record.tercero}`}
                >
                  Ver
                </Link>
              }
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado experiencia',
      dataIndex: 'estado_experiencia_nombre',
      key: 'estado_experiencia',
      align: 'center',
    },
    {
      title: 'Documentos',
      dataIndex: 'documentos',
      key: 'documentos',
      render: (_, record) => {
        if (record.tercero_data.tipo_persona === 2) {
          return (
            <CustomButton
              block
              htmlType={'button'}
              text={
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_DOCUMENTOS}/${announcement_id}/${record.id}/${announcement_rol_id}/${record.tercero}`}
                >
                  Ver
                </Link>
              }
            />
          );
        } else {
          return 'No aplica';
        }
      },
    },
    {
      title: 'Estado documentos',
      dataIndex: 'estado_documentos_nombre',
      key: 'estado_documentos',
      align: 'center',
    },
    {
      title: 'Enviar Correo notificación',
      dataIndex: 'estado_preseleccion',
      key: 'estado_preseleccion',
      render: (_, record) => (
        <Space>
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_EMAIL}/${announcement_id}/${record.id}/${announcement_rol_id}/${record.tercero}?email=adjust-information`}
              >
                Generar correo{' '}
              </Link>
            }
          />
          {record.estado_envio_ajustar && (
            <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
          )}
        </Space>
      ),
    },
    {
      title: 'Histórico',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
        <CustomButton
          block
          loading={historyLoading}
          type="default"
          onClick={() => {
            showHistory({ ...announcement_data, ...record });
          }}
          text={'Ver'}
        />
      ),
    },
  ];

  return (
    <SpinLoad loading={loading}>
      {dataSource != null && (
        <AjaxTable
          columns={columns}
          pageSize={10}
          tableTitle={'Listado de contratistas para ajustar información'}
          endpoint={filterAjaxTable}
          searchInput
          exportCSV
          reloadButton
          query={`/?filter[convocatoria_rol]=${announcement_rol_id}&filter[estado_tercero]=12`}
        />
      )}
    </SpinLoad>
  );
};

export default AjustarInformacion;
