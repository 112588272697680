import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/reports/`);
};

const getExcel = async (url = '') => {
  return await httpClient.get(url, {
    responseType: 'blob',
  });
};

export { getAll, getExcel };
