import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import { post, put, get } from '../../../api/module/areas';

import AreaForm from '../../../containers/AreaForm/index';

const AreasForms = ({ history, match }) => {
  const [areaID] = useState(parseInt(match.params.id));
  const [title, setTitle] = useState('Creación de Áreas');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(async () => {
    handleGetData();
  }, []);

  const save = async (data) => {
    try {
      let response;
      setLoading(true);

      if (!areaID) {
        response = await post(data);
      } else {
        response = await put(areaID, data);
      }

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.AREAS);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Get Data Area by ID
  const handleGetData = async () => {
    try {
      if (areaID) {
        const response = await get(areaID);
        setData(response.data);
        setTitle('Actualización de Áreas');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  return (
    <Skeleton loading={data === null}>
      <AreaForm
        title={title}
        onFinish={save}
        initialValues={data}
        loading={loading}
      />
    </Skeleton>
  );
};

export default AreasForms;
