import React from 'react';
import { Alert } from 'antd';
import HomeIndicators from '../../components/HomeIndicators';

const HomePage = () => {
  return (
    <>
      <Alert
        style={{ marginBottom: 15 }}
        message="Información importante"
        description="Asegúrese de contar con una buena conexión a internet para el óptimo funcionamiento de la plataforma."
        type="info"
        showIcon
      />
      <HomeIndicators />
    </>
  );
};

export default HomePage;
