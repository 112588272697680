import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Space,
  Typography,
  Row,
  Col,
  Modal,
  Card,
  Alert,
  Divider,
  Table,
  Form,
  DatePicker,
  notification,
  Radio,
  Input,
} from 'antd';
import MESSAGES from '../../../enums/Messages';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link, useHistory } from 'react-router-dom';
import AssignmentStates, { ErrorsContent } from './AssignmentStates';
import './style.scss';
import AjaxTable from '../../../components/AjaxTable';
import { view_home as view_home_b } from '../../../api/module/ctm_responsibilities';
import { view_home as view_home_c } from '../../../api/module/ctm_products';
import { view_home as view_home_e } from '../../../api/module/request_mod_payments';
import { IsRole } from '../../../utils/helpers/helpers';
import SpinLoad from '../../../components/SpinLoad';
import {
  getDate,
  setEight as patchEigth,
} from '../../../api/module/contract_modifications';
import { sendSign } from '../../../api/module/request_mod_assign';
import { PolizaInput } from '../../../containers/TermForm';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';
const dateFormatText = 'DD MMMM YYYY';

const ModuleCard = ({ moduleSelect }) => {
  return (
    <>
      {moduleSelect.s && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Card
            bodyStyle={{ padding: 0 }}
            headStyle={{ padding: 0 }}
            style={{ textAlign: 'left' }}
            bordered={false}
            title={
              <Title level={4}>
                {moduleSelect.id + '. ' + moduleSelect.nombre}
              </Title>
            }
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16}>
                <Row>
                  {moduleSelect.rows >= 1 && (
                    <>
                      <Col span={18} className="col-vcenter-text">
                        <p className="text-p-vcenter">
                          {moduleSelect.textRow1}
                        </p>
                      </Col>
                      <Col span={6} align="right">
                        {moduleSelect.onView ? (
                          <CustomButton
                            type={'default'}
                            htmlType="button"
                            className={'ant-btn-success'}
                            style={{ width: '90px' }}
                            text={moduleSelect.textButton1}
                            onClick={moduleSelect.onView}
                          />
                        ) : (
                          <CustomButton
                            type={'default'}
                            htmlType="button"
                            className={'ant-btn-success'}
                            style={{ width: '90px' }}
                            text={
                              <Link to={moduleSelect.linkRow1}>
                                {moduleSelect.textButton1}
                              </Link>
                            }
                          />
                        )}
                      </Col>
                    </>
                  )}
                  {moduleSelect.rows >= 2 && (
                    <>
                      <Col span={18} className="col-vcenter-text">
                        <p className="text-p-vcenter">
                          {moduleSelect.textRow2}
                        </p>
                      </Col>
                      <Col span={6} align="right">
                        <CustomButton
                          type={'default'}
                          htmlType="button"
                          className={'ant-btn-success'}
                          style={{ width: '90px' }}
                          text={
                            <Link to={moduleSelect.linkRow2}>
                              {moduleSelect.textButton2}
                            </Link>
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col xs={24} md={8}>
                {moduleSelect.e
                  ? moduleSelect.messModify && (
                      <p className="modification-p-active">
                        {moduleSelect.messModify}
                      </p>
                    )
                  : moduleSelect.messGenerated && (
                      <p className="modification-p-active">
                        {moduleSelect.messGenerated}
                      </p>
                    )}
              </Col>
            </Row>
          </Card>
        </Space>
      )}
    </>
  );
};

const ModalB = ({ visible, onCancel, mm_id }) => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      width: '5rem',
      align: 'center',
    },
    {
      title: 'Obligación',
      dataIndex: 'descripcion_vigente',
      key: 'descripcion',
    },
    {
      title: 'Asociado a pago',
      dataIndex: 'tiene_pagos_vigente',
      width: '12rem',
      render: (_, record) =>
        record.tiene_pagos_vigente === true ? 'Si' : 'No',
      align: 'center',
    },
  ];
  if (!mm_id) return null;
  return (
    <Modal
      title={
        <Title level={4} style={{ marginBottom: 0 }}>
          Responsabilidades y/o obligaciones vigentes
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      width={'80%'}
      footer={null}
    >
      <AjaxTable
        columns={columns}
        tableTitle={'Listado de responsabilidades y/o obligaciones vigentes'}
        endpoint={view_home_b}
        query={mm_id}
        scroll={{ x: false }}
        rowKey="id"
      />
    </Modal>
  );
};

const ModalC = ({ visible, onCancel, mm_id }) => {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      width: '5rem',
      align: 'center',
    },
    {
      title: 'Producto',
      dataIndex: 'descripcion_vigente',
      key: 'descripcion',
    },
    {
      title: 'Asociado a pago',
      dataIndex: 'tiene_pagos_vigente',
      width: '12rem',
      render: (_, record) =>
        record.tiene_pagos_vigente === true ? 'Si' : 'No',
      align: 'center',
    },
  ];
  if (!mm_id) return null;
  return (
    <Modal
      title={
        <Title level={4} style={{ marginBottom: 0 }}>
          Productos y/o entregables vigentes
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      width={'80%'}
      footer={null}
    >
      <AjaxTable
        columns={columns}
        tableTitle={'Listado de productos y/o entregables vigentes'}
        endpoint={view_home_c}
        query={mm_id}
        scroll={{ x: false }}
        rowKey="id"
      />
    </Modal>
  );
};

const ModalE = ({ visible, onCancel, mm_id }) => {
  const columns = [
    {
      title: 'Pago No.',
      dataIndex: 'numero',
      key: 'numero',
      // width: '10rem',
      align: 'center',
    },
    {
      title: 'Valor de pago',
      dataIndex: 'valor',
      key: 'valor',
      render: (text) => {
        return `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      },
    },
  ];
  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Tipo', dataIndex: 'tipo' },
      { title: 'No.', dataIndex: 'codigo' },
      { title: 'Descripción', dataIndex: 'descripcion' },
    ];

    const dataResponsabilities = record.responsabilidades.map((x) => ({
      ...x,
      tipo: 'Obligación',
    }));
    const dataProducts = record.productos.map((x) => ({
      ...x,
      tipo: 'Producto',
    }));

    return (
      <Table
        columns={columns}
        rowKey="id"
        dataSource={[...dataResponsabilities, ...dataProducts]}
        pagination={false}
        scroll={{ x: false }}
        title={() => 'Obligaciones y/o productos asociados'}
      />
    );
  };
  if (!mm_id) return null;
  return (
    <Modal
      title={
        <Title level={4} style={{ marginBottom: 0 }}>
          Pagos vigentes
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      width={'80%'}
      footer={null}
    >
      <AjaxTable
        columns={columns}
        rowKey="id"
        endpoint={view_home_e}
        expandedRowRender={{ expandedRowRender }}
        query={mm_id}
        scroll={{ x: false }}
        tableTitle={'Listado de pagos vigentes'}
      />
    </Modal>
  );
};

const ModulesDraft = ({ modules, modification_id, initialValues }) => {
  const [modulesModified] = useState(
    modules.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {}),
  );

  const [visible, setVisible] = useState(false);

  const [legal] = useState(IsRole(3));

  const [form] = Form.useForm();

  const [hasChanges, setHasChanges] = useState(false);

  const [dateData, setDateData] = useState(undefined);

  const [loadingSave, setLoadingSave] = useState(false);

  const [toSign, setToSign] = useState(false);

  let history = useHistory();

  const closeModal = () => {
    setVisible(false);
  };

  const dataModuleExtra = {
    1: {
      rows: 1,
      textRow1: 'Modificar la fecha de terminación',
      textRow2: '',
      linkRow1: `${INTERNAL_LINKS.MODIFICATION_CHANGE_TERM}/${modification_id}`,
      linkRow2: '#',
      textButton1: 'Modificar',
      messModify: 'Actualizado.',
    },
    2: {
      rows: 2,
      textRow1: 'Ver responsabilidades u obligaciones específicas VIGENTES',
      textRow2:
        'Modificar responsablidades u obligaciones específicas VIGENTES',
      linkRow2: `${INTERNAL_LINKS.MODIFICATION_CHANGE_RESPONSIBILITIES}/${modification_id}`,
      textButton1: 'Ver',
      onView: () => {
        setVisible(2);
      },
      textButton2: 'Modificar',
      messGenerated:
        'Generado automáticamente por la plataforma. Ud puede modificarlo en caso de requerirlo.',
      messModify: 'Actualizado.',
    },
    3: {
      rows: 2,
      textRow1: 'Ver productos y/o entregables VIGENTES',
      textRow2: 'Modificar productos y/o entregables VIGENTES',
      linkRow2: `${INTERNAL_LINKS.MODIFICATION_CHANGE_PRODUCTS}/${modification_id}`,
      textButton1: 'Ver',
      onView: () => {
        setVisible(3);
      },
      textButton2: 'Modificar',
      messGenerated:
        'Generado automáticamente por la plataforma. Ud puede modificarlo en caso de requerirlo.',
      messModify: 'Actualizado.',
    },
    4: {
      rows: 1,
      textRow1: 'Modificar el valor',
      linkRow1: `${INTERNAL_LINKS.MODIFICATION_BUDGET}/${modification_id}`,
      linkRow2: '#',
      textButton1: 'Modificar',
      textButton2: '',
      messModify: 'Actualizado.',
    },
    5: {
      rows: 2,
      textRow1: 'Ver forma de pago VIGENTE',
      textRow2: 'Modificar forma de pago VIGENTE',
      linkRow2: `${INTERNAL_LINKS.MODIFICATION_PAYMENTS}/${modification_id}`,
      textButton1: 'Ver',
      onView: () => {
        setVisible(5);
      },
      textButton2: 'Modificar',
      messModify: 'Actualizado.',
    },
    7: {
      rows: 1,
      textRow1: 'Modificar documentos adicionales',
      textRow2: '#',
      linkRow1: `${INTERNAL_LINKS.MODIFICATION_DOCUMENTS}/${modification_id}`,
      linkRow2: '#',
      textButton1: 'Modificar',
      textButton2: '',
      messModify: 'Actualizado.',
    },
  };

  const showConfirmation = (doRedirect) => {
    setHasChanges(false);
    Modal.success({
      title: MESSAGES.SUCCESS_MSG,
      content: (
        <>
          <Divider />
          <p>Se han registrado los cambios correctamente.</p>
          <p>
            Esta información siempre estará disponible en la sección de Módulo
            Modificaciones Contractuales.
          </p>

          <p>Gracias</p>
        </>
      ),
      onOk: () => {
        if (doRedirect)
          history.push(`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`);
      },
    });
  };

  const onSave = () => {
    form.submit();
  };

  const onSaveSend = () => {
    setToSign(true);
    form.submit();
  };

  const onFinishForm = async (values) => {
    if (toSign) onFinishSend(values);
    else
      try {
        setLoadingSave(true);
        const data = { ...values };
        if (values.fecha_suscripcion)
          data.fecha_suscripcion = formatDate(
            values.fecha_suscripcion,
            'YYYY-MM-DD',
          );
        await patchEigth(modification_id, data);
        showConfirmation();
      } catch (error) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      } finally {
        setLoadingSave(false);
      }
  };

  const onFinishSend = async (values) => {
    try {
      setLoadingSave(true);
      const response = await sendSign(modification_id, {
        ...values,
        fecha_suscripcion: formatDate(values.fecha_suscripcion, 'YYYY-MM-DD'),
      });
      if (response.data?.errors) {
        Modal.error({
          title: 'Validación de modificación',
          content: (
            <>
              La modificación requiere ajustes:
              <ErrorsContent errors={response.data?.errors} />
            </>
          ),
        });
        setToSign(false);
      } else showConfirmation(true);
    } catch (error) {
      setToSign(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const formatDate = (dateVal, strFormat) => {
    var dateFinal = dateVal ? dateVal : '';
    if (dateFinal !== '') {
      dateFinal = moment(dateFinal).format(strFormat);
    }

    return dateFinal;
  };

  const disabledDate = (current) => {
    return (
      current &&
      (current.isAfter(dateData.max_date, 'day') ||
        current.isBefore(dateData.min_date, 'day'))
    );
  };

  useEffect(() => {
    async function fetchDate() {
      try {
        const { data } = await getDate(modification_id);
        const d = data?.date ? moment(data.date) : undefined;
        setDateData({
          date: d,
          max_date: moment(data.max_date),
          min_date: moment(data.min_date),
        });
      } catch (error) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    if (legal) {
      fetchDate();
    }
  }, [legal]);

  return (
    <>
      <Row align="middle" className="fade-in">
        <Col flex="auto" span={24} align="left">
          <Divider></Divider>
          <Alert
            showIcon
            type="info"
            message="Información importante"
            description="Tenga presente que 'Vigente' hace referencia a las últimas modificaciones realizadas y legalizadas."
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[1], ...dataModuleExtra[1] }}
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[2], ...dataModuleExtra[2] }}
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[3], ...dataModuleExtra[3] }}
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[4], ...dataModuleExtra[4] }}
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[5], ...dataModuleExtra[5] }}
          />
          <ModuleCard
            moduleSelect={{ ...modulesModified[7], ...dataModuleExtra[7] }}
          />
          <Divider />
          <Card
            bodyStyle={{ padding: 0 }}
            headStyle={{ padding: 0 }}
            style={{ textAlign: 'left' }}
            bordered={false}
            title={
              <Title level={4}>
                8. Pólizas y otros requerimientos adicionales
              </Title>
            }
          >
            {legal && !dateData && <SpinLoad loading={true} />}
            {((legal && dateData) || !legal) && (
              <Form
                layout="horizontal"
                initialValues={
                  legal
                    ? {
                        fecha_suscripcion: dateData.date,
                        requiere_poliza: initialValues.requiere_poliza,
                        justificacion_poliza:
                          initialValues.justificacion_poliza,
                      }
                    : {
                        requiere_poliza: initialValues.requiere_poliza,
                        justificacion_poliza:
                          initialValues.justificacion_poliza,
                      }
                }
                form={form}
                onFinish={onFinishForm}
                onValuesChange={() => setHasChanges(true)}
              >
                {initialValues.r_p && (
                  <Alert
                    showIcon
                    message="Información importante"
                    type="info"
                    description="Se requiere póliza para esta modificación contractual"
                  />
                )}
                <Form.Item
                  label="¿Se requiere póliza para esta modificación contractual?"
                  name="requiere_poliza"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <PolizaInput rp={initialValues.r_p} />
                </Form.Item>
                <Form.Item noStyle dependencies={['requiere_poliza']}>
                  {({ getFieldValue }) => (
                    <>
                      {initialValues.r_p &&
                        getFieldValue('requiere_poliza') === false && (
                          <Form.Item
                            label="Se requiere póliza para esta modificación contractual. Justifique por qué no se solicitará."
                            name="justificacion_poliza"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            tooltip={{
                              placement: 'top',
                              title: `Justifique su decisión`,
                            }}
                          >
                            <Input
                              placeholder="Justifique su decisión ..."
                              allowClear
                            />
                          </Form.Item>
                        )}
                    </>
                  )}
                </Form.Item>
                {legal && (
                  <Row align="top" justify="center">
                    <Col lg={16} md={24}>
                      <Form.Item
                        name="fecha_suscripcion"
                        label="Fecha de suscripción en la cual aplica la presente modificación"
                        rules={[
                          {
                            required: true,
                            type: 'object',
                          },
                        ]}
                      >
                        <DatePicker
                          format={dateFormat}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => (
                          <p>
                            {formatDate(
                              getFieldValue('fecha_suscripcion'),
                              dateFormatText,
                            )}
                          </p>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row align="middle">
        <Col flex="auto" span={legal ? 12 : 24} align="center">
          <Space>
            <CustomButton
              onClick={onSave}
              disabled={legal && (!dateData || toSign)}
              loading={loadingSave && !toSign}
              htmlType="button"
              text="GUARDAR MODIFICACIONES"
            />
          </Space>
        </Col>
        {legal && (
          <Col flex="auto" span={12} align="center">
            <Space>
              <CustomButton
                onClick={onSaveSend}
                disabled={legal && (!dateData || toSign)}
                loading={loadingSave && toSign}
                type="primary"
                htmlType="button"
                text="GUARDAR Y ENVIAR PARA CONTRATACIÓN"
              />
            </Space>
          </Col>
        )}
      </Row>
      <Divider />
      {!hasChanges && (
        <Row align="middle">
          <Col flex="auto" span={24} align="left">
            <AssignmentStates modificationId={modification_id} />
          </Col>
        </Row>
      )}
      <ModalB
        visible={visible === 2}
        onCancel={closeModal}
        mm_id={modulesModified[2]?.s}
      />
      <ModalC
        visible={visible === 3}
        onCancel={closeModal}
        mm_id={modulesModified[3]?.s}
      />
      <ModalE
        visible={visible === 5}
        onCancel={closeModal}
        mm_id={modification_id}
      />
    </>
  );
};

export default ModulesDraft;
