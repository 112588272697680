import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Typography, notification, Space } from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { ajaxTable as filterAll, remove } from '../../../api/module/contractor';
import { PlusOutlined } from '@ant-design/icons';
import AjaxTable from "../../../components/AjaxTable";

const breadcrumbItems = [
  {
    text: 'Proveedores y Consultores - Persona Jurídica',
  },
];

const { Title, Paragraph } = Typography;

const LegalPersonList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteDataSource = useCallback(
    async (id) => {
      try {
        setLoadingDelete(true);
        const response = await remove(id);
        setLoadingDelete(false);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_ERROR_DELETE_MSG,
            duration: 2,
          });
          await getDataSource();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [dataSource],
  );

  useEffect(() => {
    getDataSource();
  }, []);

  const getDataSource = async () => {
    try {
      setLoading(true);
      const response = await filterAll(
        '?include=legal_related_names&filter[tipo_persona]=2',
      );
      setDataSource(response.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const columns = [
    {
      title: 'Nombres Razón social',
      dataIndex: 'primer_nombre_razon_social',
      key: 'primer_nombre_razon_social',
    },
    {
      title: 'Sigla - Nombre Comercial',
      dataIndex: 'sigla_comercial',
      key: 'sigla_comercial',
    },
    {
      title: 'NIT - Documento equivalente',
      dataIndex: 'numero_identificacion',
      key: 'numero_identificacion',
    },
    {
      title: 'Tipo organización',
      dataIndex: 'tipo_organizacion_nombre',
      key: 'tipo_organizacion',
    },
    {
      title: 'Tipo documento Rep Legal',
      dataIndex: 'representante_tipo_identificacion_nombre',
      key: 'representante_tipo_identificacion',
    },
    {
      title: 'País notificación',
      dataIndex: 'notificacion_pais_nombre',
      key: 'notificacion_pais',
    },
    {
      title: 'Ciudad notificación',
      dataIndex: 'notificacion_ciudad_nombre',
      key: 'notificacion_ciudad',
    },
    {
      title: 'Depto notificación',
      dataIndex: 'notificacion_estado_nombre',
      key: 'notificacion_estado',
    },
    {
      title: 'Dirección notificación',
      dataIndex: 'notificacion_direccion',
      key: 'notificacion_direccion',
    },
    {
      title: 'Teléfono notificación',
      dataIndex: 'numero_celular',
      key: 'numero_celular',
    },
    {
      title: 'Otros # telefónicos',
      dataIndex: 'otros_numeros_telefonicos',
      key: 'otros_numeros_telefonicos',
    },
    {
      title: 'Correo electrónico notificación',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 200,
      responsive: ['sm', 'mg', 'lg'],
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Space>
            <CustomButton
              type={'default'}
              className={'ant-btn-info'}
              htmlType="button"
              text={
                <Link to={`${INTERNAL_LINKS.LEGAL_PERSON}/${record.id}`}>
                  Editar
                </Link>
              }
            />
            <CustomButton
              danger
              type={'default'}
              htmlType="button"
              loading={loadingDelete}
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSource(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </Space>
        ) : null,
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      responsive: ['xs'],
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            text={
              <Link to={`${INTERNAL_LINKS.LEGAL_PERSON}/${record.id}`}>
                Editar
              </Link>
            }
          />
          <CustomButton
            block
            danger
            type={'default'}
            htmlType="button"
            loading={loadingDelete}
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG}
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Proveedores y Consultores - Persona jurídica
      </Title>
      <Paragraph>
        A continuación podrá realizar acciones como la creación, edición y
        eliminación de persona jurídica.
      </Paragraph>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <Link to={INTERNAL_LINKS.LEGAL_PERSON}>
            <PlusOutlined /> Crear nuevo proveedor/consultor
          </Link>
        }
      />
      <SpinLoad loading={loading}>
        <AjaxTable
          columns={columns}
          searchInput
          tableTitle={'Listado de proveedores y consultores - persona jurídica'}
          endpoint={filterAll}
          query={'/?include=natural_related_names&filter[tipo_persona]=2'}
        />
      </SpinLoad>
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        text={<Link to="/">Volver</Link>}
      />
    </>
  );
};

export default LegalPersonList;
