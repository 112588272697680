import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Form, Input, Alert, Space, Button, PageHeader } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

import { CustomButton, CustomSelector } from '../../components';
import { login } from '../../api/module/auth';
import { setUserSession, validatorNumbers } from '../../utils/helpers/helpers';

import InternalLinks from '../../enums/InternalLinks';
import { getAll as getIDTypes } from '../../api/module/identities_types';

import './style.scss';
import PublicHeader from '../PublicHeader';

export default class UserLoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      alert: {
        show: false,
      },
    };
  }

  async doLogin(formData) {
    const data = {
      username: `${formData.tipo_identificacion}${formData.numero_identificacion}`,
      password: formData.password,
    };
    try {
      this.setState({ loading: true });
      this.setState({
        alert: {
          show: false,
        },
      });

      const response = await login(data);

      this.setState({ loading: false });

      if (response.status === 200) {
        setUserSession(response.data.data);
        this.setState({
          alert: {
            show: true,
            message: 'Bienvenido!',
            description: 'Espere un momento por favor...',
            type: 'success',
          },
        });
      }
    } catch (exception) {
      this.setState({ loading: false });
      this.setState({
        alert: {
          show: true,
          message: 'Error',
          description:
            'No se puede iniciar sesión con las credenciales proporcionadas.',
          type: 'error',
        },
      });
    }
  }

  render() {
    const loading = this.state.loading;
    const alert = this.state.alert;

    return (
      <Row justify={'center'} align={'top'}>
        <Col xs={22} sm={17} md={12} lg={9} xl={7} className={'form-container'}>
          <PublicHeader />
          <Alert
            message={<strong>¡Importante!</strong>}
            description="Si se encuentra interesado en participar de los diferentes procesos de Corpoeducación y sus aliados, por favor regístrese. Si ya se encuentra registrado, inicie sesión."
            type="info"
          />
          <PageHeader
            className={'public-page-header'}
            title={'Iniciar sesión'}
          />
          <Form
            name={'normal_login'}
            layout={'vertical'}
            requiredMark={'optional'}
            scrollToFirstError={true}
            onFinish={(data) => this.doLogin(data)}
          >
            <Form.Item
              label="Tipo de identificación"
              name="tipo_identificacion"
              required
              rules={[
                {
                  required: true,
                  message: 'Elija el tipo de identificación',
                },
              ]}
            >
              <CustomSelector
                endpointFetch={getIDTypes}
                valueName="sigla"
                labelName="nombre"
              />
            </Form.Item>
            <Form.Item noStyle>
              <Form.Item
                label="Número de documento de identificación"
                name="numero_identificacion"
                rules={[
                  {
                    required: true,
                    message: 'Ingrese número de documento de identificación',
                  },
                  ({ getFieldValue }) =>
                    ['CC', 'NIT'].includes(getFieldValue('tipo_identificacion'))
                      ? validatorNumbers(6)()
                      : true,
                ]}
                dependencies={['tipo_identificacion']}
              >
                <Input
                  prefix={<UserOutlined />}
                  allowClear
                  placeholder="Ingrese número de documento de identificación"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Contraseña"
              name="password"
              required
              tooltip={'Ingrese su contraseña'}
              rules={[
                {
                  required: true,
                  message: 'Ingrese su contraseña',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Contraseña"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                allowClear
              />
            </Form.Item>
            <Form.Item className={'mb-0 text-right'}>
              <Link to={InternalLinks.RECOVER_EMAIL}>Recuperar contraseña</Link>
            </Form.Item>
            <Space direction={'vertical'} size={'small'} className={'w-100'}>
              <Form.Item className="mb-0">
                <Space
                  direction={'vertical'}
                  size={'small'}
                  className={'w-100'}
                >
                  <CustomButton text={'Ingresar'} loading={loading} block />
                  <Link to={InternalLinks.REGISTER}>
                    <Button block>Registrarse</Button>
                  </Link>
                </Space>
              </Form.Item>
              {alert.show && (
                <Alert
                  message={alert.message}
                  description={alert.description}
                  type={alert.type}
                  showIcon
                />
              )}
            </Space>
          </Form>
        </Col>
      </Row>
    );
  }
}
