import httpClient from '../httpClient';

const getProjects = async (params) => {
  return await httpClient.get('/term_legal_validation_selectors/projects/', {
    params: params,
  });
};

const getRoles = async (params) => {
  return await httpClient.get('/term_legal_validation_selectors/roles/', {
    params: params,
  });
};

const getTerms = async (params) => {
  return await httpClient.get('/term_legal_validation_selectors/terms/', {
    params: params,
  });
};

const post = async (data) => {
  return await httpClient.post(`/term_legal_validation_document/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_legal_validation_document/${id}/`);
};

const filter = async (query, page, pageSize) => {
  return await httpClient.get(
    `/term_legal_validation_document${query}&page[number]=${page}&page[size]=${pageSize}`,
  );
};

const getCounters = async (query) => {
  return await httpClient.get(
    `term_legal_validation_document/counters${query}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(`/term_legal_validation_document/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/term_legal_validation_document/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_legal_validation_document/${id}`);
};

const saveRestrictive = async (id, data) => {
  return await httpClient.patch(
    `/term_legal_validation_document/${id}/restrictive/`,
    data,
  );
};

const reject = async (query) => {
  return await httpClient.get(
    `/term_legal_validation_document/rejected${query}`,
  );
};

export {
  post,
  get,
  filter,
  getCounters,
  put,
  patch,
  remove,
  saveRestrictive,
  reject,
  getProjects,
  getRoles,
  getTerms,
};
