import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/term_document_validation_email/`,
    data,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_document_validation_email/${id}`,
  );
};

const getAllByValidation = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_document_validation_email/?filter[validacion_documentos]=${id}`,
  );
};

export { post, get, getAllByValidation };
