import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Space,
  Row,
  Col,
  Table,
  Typography,
} from 'antd';

import CustomButton from '../../../components/CustomButton/';
import { SelectKnowledgeArea } from '../../../components/SelectKnowledgeArea/';
import { SelectExperienceArea } from '../../../components/SelectExperienceArea';
import { SelectJobType } from '../../../components/SelectJobType';
import SpinLoad from '../../../components/SpinLoad';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import Paragraph from "antd/lib/typography/Paragraph";

const { Title } = Typography;

const NaturalPersonForm = memo(({
  dataSource,
  ids,
  loading,
  onFinish,
  rowSelection,
  selectedKnowledge,
}) => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Nombres y apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo de Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'numero_identificacion',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion_documento'
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'numero_celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'notificacion_direccion',
    },
    {
      title: 'País',
      dataIndex: 'pais',
      key: 'pais',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
  ];

  return (
    <>
      <Form
        form={form}
        name="location_form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row align='bottom'>
          <Col span={24}>
            <Title level={5}>Búsqueda por identificación</Title>
            <Row align='bottom'>
              <Col span={7}>
                <Form.Item
                  label="Nombre" name='nombre'
                >
                  <Input allowClear placeholder="Búsqueda por nombre" />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label="Número de documento" name='documento'
                >
                  <Input type="number" allowClear placeholder="Búsqueda por documento" />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <Form.Item
                  label="Correo electrónico" name='correo'
                >
                  <Input allowClear placeholder="Correo electrónico" />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Búsqueda por educación</Title>
            <Row align='bottom'>
              <Col span={7}>
                <Form.Item
                  label="Pregrado" name='pregrado'
                >
                  <SelectKnowledgeArea onChange={(value) => selectedKnowledge(value)} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label="Especialización" name='especializacion'
                >
                  <SelectKnowledgeArea onChange={(value) => selectedKnowledge(value)} />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <Form.Item
                  label="Maestría"
                  name='maestria'
                >
                  <SelectKnowledgeArea onChange={(value) => selectedKnowledge(value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row align='bottom'>
              <Col span={7}>
                <Form.Item
                  label="Doctorado"
                  name='doctorado'
                >
                  <SelectKnowledgeArea onChange={(value) => selectedKnowledge(value)} />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Búsqueda por experiencia</Title>
            <Row align='bottom'>
              <Col span={7}>
                <Form.Item
                  label="Área de experiencia" name='area_experiencia'
                >
                  <SelectExperienceArea />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label="Meses de experiencia" name='meses_experiencia'
                >
                  <Input type="number" allowClear placeholder="Meses de experiencia" />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <Form.Item
                  label="Tipo de cargo" name='tipo_cargo'
                >
                  <SelectJobType />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" >
          <Form.Item>
            <div className="box-btn">
              <Space>
                <CustomButton htmlType="submit" loading={loading} text="Buscar" />

                <CustomButton
                  type="default"
                  text={<a href={`${INTERNAL_LINKS.NATURAL_PERSON}`} target="_blank">Crear contratista</a>}
                />
              </Space>
            </div>
          </Form.Item>
          <CustomButton htmlType="button" onClick={() => {
            form.resetFields();
          }} danger={true} text="Borrar filtros" />
        </Row>
      </Form>
      <SpinLoad loading={loading}>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          dataSource={dataSource}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de contratistas para agregar
            </Paragraph>
          )}
        />
      </SpinLoad>
    </>
  )
});


NaturalPersonForm.propTypes = {
  dataSource: PropTypes.array,
  ids: PropTypes.object,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  selectedKnowledge: PropTypes.func,
};

NaturalPersonForm.defaultProps = {
  dataSource: [],
  ids: {},
  loading: false,
  rowSelection: {},
  selectedKnowledge: () => { },
};

export default NaturalPersonForm;
