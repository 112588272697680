import httpClient from '../httpClient';

const getGroupsAssign = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/assignments_modification/${id}/`,
  );
};

const sendObservations = async (id, data) => {
  return await httpClient.patch(
    `${process.env.REACT_APP_API_HOST}/assignments_modification/${id}/add_observations/`,
    data,
  );
};

const sendSign = async (id, data) => {
  return await httpClient.patch(
    `/assignments_modification/${id}/send_director/`,
    data,
  );
};

const massSendSign = async (id, data) => {
  return await httpClient.patch(
    `/assignments_modification/${id}/mass_send_director/`,
    data,
  );
};

export { getGroupsAssign, sendObservations, sendSign, massSendSign };
