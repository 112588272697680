import httpClient from '../httpClient';

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/insurance_policy/export` +
        `/?${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/insurance_policy` +
        `/?${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

const getCounters = async (query) => {
  return await httpClient.get(`insurance_policy/counters/?${query}`);
};

const saveStatus = async (id) => {
  return await httpClient.patch(`insurance_policy/${id}/save_status/`, {});
};

const get = async (id) => {
  return await httpClient.get(`insurance_policy/${id}`);
};

const getDocsByType = async (id, type) => {
  return await httpClient.get(
    `insurance_policy/${id}/documents/?filter[tipo]=${type}`,
  );
};

const saveDocsByType = async (id, data) => {
  return await httpClient.put(`insurance_policy/${id}/save_validation/`, data);
};

const getDocUrl = async (id, type) => {
  return await httpClient.get(
    `insurance_policy/${id}/get_url/?filter[tipo]=${type}`,
  );
};

const mailInfo = async (id) => {
  return await httpClient.get(`insurance_policy/${id}/mail_info`);
};

const sendMail = async (id, data) => {
  return await httpClient.put(`insurance_policy/${id}/send_mail/`, data);
};

const mailHistory = async (id) => {
  return await httpClient.get(`insurance_policy/${id}/mail_history`);
};

const projectSelector = async () => {
  return await httpClient.get('ip_project_selector/');
};

const roleSelector = async (project_id) => {
  return await httpClient.get(
    `ip_project_positions_selector/?filter[proyecto]=${project_id}`,
  );
};

export {
  ajaxTable,
  getCounters,
  saveStatus,
  get,
  getDocsByType,
  saveDocsByType,
  getDocUrl,
  mailInfo,
  sendMail,
  mailHistory,
  projectSelector,
  roleSelector,
};
