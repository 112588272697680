import React, { useState, useEffect } from 'react';
import { Select, Skeleton } from 'antd';
import PropTypes from 'prop-types';

import { filter, get } from '../../api/module/organizations';

const SelectOrganizationsActive = ({
  className,
  onChange,
  organization,
  organization_name,
  disabled,
  active,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getOrganizations = () => {
    filter('/?filter[esta_activa]=' + active).then((res) => {
      setData(res.data.results);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (disabled && organization) {
      if (organization_name) {
        setData([{ id: organization, razon_social: organization_name }]);
        setIsLoading(false);
      } else
        get(organization).then((res) => {
          setData([res.data]);
          setIsLoading(false);
        });
    } else getOrganizations();
  }, []);

  return (
    <Skeleton loading={isLoading} active paragraph={false}>
      <Select
        placeholder="Seleccione la organización"
        className={className}
        onChange={onChange}
        defaultValue={organization}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
      >
        {data != null &&
          data.map((item) => (
            <Select.Option key={`organization-key-${item.id}`} value={item.id}>
              {item.sigla !== null && item.sigla !== "undefined" ? item.razon_social + ' - ' + item.sigla : item.razon_social}
            </Select.Option>
          ))}
      </Select>
    </Skeleton>
  );
};

SelectOrganizationsActive.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  organizacion: PropTypes.number,
  organization_name: PropTypes.string,
  active: PropTypes.bool,
};

SelectOrganizationsActive.defaultProps = {
  className: '',
  disabled: false,
  onChange: () => {},
  organizacion: null,
  organization_name: null,
  active: true,
};

export default SelectOrganizationsActive;
