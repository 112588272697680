import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/templates/`,
    data,
  );
};
const postTemplateType = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/templates_types/`,
    data,
  );
};


const postTemplateDocuments = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/templates/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/templates/`);
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/templates/${id}`,
  );
};

const getFilterByTemplate = async (template_id, contract_id, person_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/templates/?filter[tipo_plantilla]=${template_id}&filter[tipo_contrato]=${contract_id}&filter[tipo_persona]=${person_id}&sort=-created_at`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/templates/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/templates/${id}`,
  );
};

const generateTemplateURL = async (data) => {
  return await httpClient.post(`/template/`, data, {
    responseType: 'blob'
  });
};

const generateWordTemplateURL = async (data) => {
  return await httpClient.post(`/template/`, data);
};

const changeStatus = async (id) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/templates/${id}/change_status/`);
};

const templateFiltered = async (type = 1, status= true, first_row = false,
                                hiring_type = 0, hiring_stage = 0, person_type = 0,
                                contract = 0, template_type = 0) => {
  let filter = '?'

  if (type === 0)
    filter += 'filter[tipo_contrato__isnull]=true'
  else
    filter += 'filter[tipo_contrato__isnull]=false'

  if (hiring_type !== 0)
    filter += '&filter[tipo_contratacion]=' + hiring_type

  if (hiring_stage !== 0)
    filter += '&filter[etapa_contratacion]=' + hiring_stage

  if (person_type !== 0)
    filter += '&filter[tipo_persona]=' + person_type

  if (contract !== 0)
    filter += '&filter[tipo_contrato]=' + contract

  if (template_type !== 0)
    filter += '&filter[tipo_plantilla]=' + template_type

  if (status)
    filter += '&filter[estado]=' + status

  if (first_row)
    filter += '&page[limit]=1'

  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/templates/${filter}&sort=-version`);
};

export {
  post,
  postTemplateType,
  postTemplateDocuments,
  getAll,
  get,
  getFilterByTemplate,
  put,
  remove,
  generateTemplateURL,
  templateFiltered,
  changeStatus,
  generateWordTemplateURL,
};
