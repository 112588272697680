import React, { useEffect, useReducer, useState } from 'react';

import {
  Row,
  Col,
  Typography,
  Skeleton,
  notification,
  Tabs,
  Badge,
  Space,
  Divider,
  Popconfirm,
  Modal,
  Descriptions,
} from 'antd';
import { EditOutlined, SelectOutlined } from '@ant-design/icons';

import CustomSelector from '../../components/CustomSelector';
import { getSelectorWithTDR } from '../../api/module/projects';
import { filterTDR as filterRoleTDR } from '../../api/module/project_positions';
import { filter as filterTerms } from '../../api/module/terms';

import { CustomButton } from '../../components';
import MESSAGES from '../../enums/Messages';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import TextAreaModal from '../../components/TextAreaModal';
import {
  getCounters,
  patch,
  signMassive,
  signSelected,
} from '../../api/module/term_director_validation_document';
import { filter as filterStatus } from '../../api/module/status_process';
import AjaxTable from '../../components/AjaxTable';
import { ajaxTable as filterAjaxTable } from '../../api/module/term_director_validation_document';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const breadcrumbItems = [
  {
    text: 'Firma de TDR de Contratistas y Proveedores',
  },
];

const columns = [
  {
    title: 'Proyecto',
    dataIndex: ['termino', 'proyecto_nombre'],
  },
  {
    title: 'Identificador',
    dataIndex: ['termino', 'identificador_interno'],
  },
  {
    title: 'Tipo persona',
    dataIndex: ['tercero', 'tipo_persona_nombre'],
  },
  {
    title: 'Nombre completo/Razón social',
    dataIndex: ['tercero', 'nombre_completo'],
  },
  {
    title: 'Tipo doc',
    dataIndex: ['tercero', 'tipo_identificacion_nombre'],
  },
  {
    title: 'Nro. Documento',
    dataIndex: ['tercero', 'numero_identificacion'],
  },
  {
    title: 'Fecha inicio',
    dataIndex: ['termino', 'fecha_inicio'],
  },
  {
    title: 'Fecha fin',
    dataIndex: ['termino', 'fecha_fin'],
  },
];

const initModalState = {
  onFinish: async () => {},
  visible: false,
  key: 'no-key',
};

const initSelectors = {
  proyecto: undefined,
  cargo: undefined,
  identificador: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case 'project':
      return { ...initSelectors, proyecto: action.payload };
    case 'role':
      return {
        ...state,
        cargo: action.payload,
        identificador: initSelectors.identificador,
      };
    case 'term':
      return { ...state, identificador: action.payload };
    default:
      return initSelectors;
  }
}

function DocumentValidationDirectorSelectors({ match, setQuery }) {
  const [state, dispatch] = useReducer(reducer, initSelectors);

  useEffect(() => {
    if (!match.params.type || Object.values(state).some((value) => !!value)) {
      let q = '';
      if (q === '' && state.identificador) {
        q = `filter[termino]=${state.identificador}`;
      }
      if (q === '' && state.cargo) {
        q = `filter[termino__cargo]=${state.cargo}`;
      }
      if (q === '' && state.proyecto) {
        q = `filter[termino__proyecto]=${state.proyecto}`;
      }
      setQuery({ query: q, param: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="box-description" key="vdd-selectors">
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Administración:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            valueName="id"
            labelName="nombre"
            endpointFetch={async () => {
              return { data: { results: [] } };
            }}
          />
        </Col>
      </Row>
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Proyecto a revisar:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            onChange={(value) => dispatch({ type: 'project', payload: value })}
            initialValue={
              match.params.type && match.params.type === 'project'
                ? parseInt(match.params.id)
                : undefined
            }
            onLoad={(value) => {
              if (match.params.type === 'project')
                dispatch({
                  type: 'project',
                  payload: value ? value.value : undefined,
                });
            }}
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            endpointFetch={async () => {
              return await getSelectorWithTDR(
                '?filter[estado_proyecto__sigla.in]=PY-A,PY-RT',
              );
            }}
            key={match.url}
          />
        </Col>
      </Row>
      {state.proyecto && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo/rol a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'role', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'role'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'role')
                  dispatch({
                    type: 'role',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo / Rol a revisar... ',
              }}
              valueName="id"
              labelName="cargo"
              endpointFetch={async () => {
                return await filterRoleTDR(
                  `/?page[size]=1000&filter[proyecto]=${state.proyecto}`,
                );
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
      {state.cargo && (
        <Row className="box-selectors" align="middle">
          <Col span={6}>
            <Title level={4} style={{ paddingRight: '15px' }}>
              Identificador del término a revisar:
            </Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'term', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'term'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'term')
                  dispatch({
                    type: 'term',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder:
                  'Seleccione el identificador único del termino de referencia... ',
              }}
              valueName="id"
              labelName="identificador_interno"
              endpointFetch={async () => {
                return await filterTerms(
                  `/?page[size]=1000&filter[estado_proceso__sigla]=T-V&filter[cargo]=${state.cargo}`,
                );
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default function DocumentValidationDirectorHome({ match }) {
  const [query, setQuery] = useState({ q: '', param: {} });
  const [counters, setCounters] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const [activeTab, setActiveTab] = useState('review');
  const [massiveLoad, setMassiveLoad] = useState(false);

  const [modalState, setModalState] = useState(initModalState);

  const [validationStates, setValidationStates] = useState({});

  const [loadingIDs, setLoadingIDs] = useState([]);

  const [reloadReview, setReloadReview] = useState(false);

  useEffect(() => {
    async function fetchStates() {
      try {
        const res = await filterStatus('/?filter[sigla.icontains]=TDR-VDD-G-');
        const states = {
          approved: res.data.results.find((x) => x.sigla === 'TDR-VDD-G-A'),
          rejected: res.data.results.find((x) => x.sigla === 'TDR-VDD-G-R'),
        };
        setValidationStates(states);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchStates();
  }, []);

  async function onMassiveSign() {
    async function onOk() {
      setMassiveLoad(true);
      try {
        const res = await signMassive({
          ...query.param,
        });
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setMassiveLoad(false);
    }

    if (counters.review > 0) {
      Modal.confirm({
        title: `Se firmarán ${counters.review} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  function onSelectedSign() {
    async function onOk() {
      const data = {
        validaciones: selected.map((x) => x.id),
        proyecto: query.param.proyecto,
      };
      setLoadingIDs(data.validaciones);
      try {
        const res = await signSelected(data);
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setLoadingIDs([]);
    }

    if (selected.length > 0) {
      Modal.confirm({
        title: `Se firmarán ${selected.length} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  async function changeQuery(
    q = { query: '', param: undefined },
    newTab = undefined,
  ) {
    try {
      const res = await getCounters(`/?${q.query}`);
      if (q !== query) setQuery(q);
      setCounters(res.data.results);
      if (newTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setCounters(undefined);
    }
  }

  async function onSave(record) {
    try {
      setLoadingIDs([record.id]);
      await patch(record.id, {
        estado_validacion: validationStates.approved.id,
      });
      setLoadingIDs([]);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('approved');
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onRejectedStatusSave(id, text) {
    try {
      await patch(id, {
        observaciones: text,
        estado_validacion: validationStates.rejected.id,
      });
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('review');
      setReloadReview({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  const rowSelectionToCheck = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelected(selectedRows);
    },
  };

  const extraColumnsToCheck = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space split={<Divider type="vertical" />}>
            <CustomButton
              type="primary"
              text={
                <Link
                  to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}-validar/${record.id}`}
                >
                  Ver Documentos
                </Link>
              }
            />
            <Popconfirm
              onConfirm={() => {
                onSave(record);
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton
                type="default"
                text="Firmar"
                loading={loadingIDs.includes(record.id)}
                disabled={loadingIDs.length > 0}
              />
            </Popconfirm>
            <Popconfirm
              onConfirm={() => {
                setModalState({
                  visible: true,
                  onFinish: async (text) => {
                    await onRejectedStatusSave(record.id, text);
                    setModalState(initModalState);
                  },
                  key: record.id,
                });
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton type="danger" text="Rechazar" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <CustomButton
            type="primary"
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}-validar/${record.id}`}
              >
                Ver Documentos
              </Link>
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Firma de TDR de Contratistas y Proveedores
      </Title>
      <DocumentValidationDirectorSelectors
        match={match}
        setQuery={changeQuery}
      />

      {counters && validationStates ? (
        <>
          <Descriptions
            bordered
            labelStyle={{ display: 'block', textAlign: 'center' }}
            contentStyle={{ display: 'block', textAlign: 'center' }}
            style={{ marginTop: '15px' }}
            layout="vertical"
            column={2}
          >
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<EditOutlined />}
                  text={
                    <>
                      Firmar masivos{' '}
                      <Badge
                        count={counters.review}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onMassiveSign}
                  loading={massiveLoad}
                  disabled={
                    counters.review < 1 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto ||
                      query.param.cargo ||
                      query.param.identificador
                    )
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar masivos</Text> por favor
                seleccione los filtros de Proyecto, Cargo/Rol o Identificador
                del término a revisar. El número indica los registros que serán
                firmados.
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<SelectOutlined />}
                  text={
                    <>
                      Firmar seleccionados{' '}
                      <Badge
                        count={selected.length}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onSelectedSign}
                  loading={massiveLoad || loadingIDs.length > 0}
                  disabled={selected.length < 1 || activeTab !== 'review'}
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar seleccionados</Text>, por
                favor seleccione los registros deseados de la tabla a
                continuación. El número indica los registros que serán firmados.
              </Text>
            </Descriptions.Item>
          </Descriptions>
          {massiveLoad ? (
            <Skeleton active loading={true} />
          ) : (
            <Tabs
              style={{ marginTop: 15 }}
              defaultActiveKey={'review'}
              activeKey={activeTab}
              onTabClick={(key, _) => {
                setActiveTab(key);
              }}
              destroyInactiveTabPane={true}
              type="card"
              key={`vdd-home-tabs-${query.query}`}
            >
              <TabPane
                tab={
                  <Paragraph>
                    Por revisar
                    <Badge showZero count={counters.review} />
                  </Paragraph>
                }
                key="review"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsToCheck]}
                  tableTitle={'Listado de contratistas para revisar'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_validacion__isnull]=True`}
                  rowSelection={rowSelectionToCheck}
                  reloadSource={reloadReview}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados
                    <Badge showZero count={counters.approved} />
                  </Paragraph>
                }
                key="approved"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsApproved]}
                  tableTitle={'Listado de contratistas aprobados'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_validacion__sigla]=TDR-VDD-G-A`}
                />
              </TabPane>
            </Tabs>
          )}
        </>
      ) : (
        <Skeleton active loading={true} />
      )}
      <TextAreaModal
        modalProps={{
          visible: modalState.visible,
          centered: true,
          title: 'Motivo Rechazo',
        }}
        textAreaProps={{
          rows: 4,
        }}
        onOk={modalState.onFinish}
        onCancel={() => {
          setModalState(initModalState);
        }}
        key={modalState.key}
        rules={[
          {
            required: true,
            message: 'Ingrese un motivo!',
          },
        ]}
      />
    </>
  );
}
