import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/projects/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/projects/?page[size]=1000`,
  );
};

const getAllActive = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/projects/?page[size]=1000&filter[estado_proyecto__sigla.in]=PY-A,PY-RT&sort=nombre`,
  );
};

const getSelector = async (query = '') => {
  return await httpClient.get(`/project_selector/${query}`);
};

const getSelectorWithTDR = async (query = '') => {
  return await httpClient.get(`/project_selector/with_validated_tdr/${query}`);
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/projects/${id}/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/projects/${id}/`,
    data,
  );
};

const patch = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/projects/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/projects/${id}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/projects/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/projects` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

export {
  post,
  getAll,
  getAllActive,
  get,
  put,
  patch,
  remove,
  ajaxTable,
  getSelector,
  getSelectorWithTDR,
};
