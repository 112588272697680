import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Layout, Typography } from 'antd';

//Component imports
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import {ajaxTable as filterAjaxTable} from '../../api/module/announcement_rol';
import AjaxTable from "../../components/AjaxTable";

const { Title } = Typography;
const { Content } = Layout;

const AnnouncementPreselection = () => {
  const [sortColumn, setSortColumn] = useState('-created_at');
  const breadcrumbItems = [
    {
      text: 'Módulo de selección',
    },
  ];

  const columns = [
    {
      title: 'Tipo convocatoria',
      dataIndex: 'tipo',
      key: 'tipo',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('tipo');
        if (sortOrder === 'descend') setSortColumn('-tipo');
      },
    },
    {
      title: 'Proyecto',
      align: 'center',
      dataIndex: 'proyecto_nombre',
      key: 'proyecto_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('proyecto_nombre');
        if (sortOrder === 'descend') setSortColumn('-proyecto_nombre');
      },
    },
    {
      title: 'Organización',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('organizacion_nombre');
        if (sortOrder === 'descend') setSortColumn('-organizacion_nombre');
      },
    },
    {
      title: 'No. convocatoria',
      dataIndex: 'numero',
      key: 'numero',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('numero');
        if (sortOrder === 'descend') setSortColumn('-numero');
      },
    },
    {
      title: 'Nombre convocatoria',
      dataIndex: 'nombre',
      key: 'nombre',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre');
        if (sortOrder === 'descend') setSortColumn('-nombre');
      },
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('cargo');
        if (sortOrder === 'descend') setSortColumn('-cargo');
      },
    },
    {
      title: 'Total vacantes',
      dataIndex: 'total_vacantes',
      key: 'total_vacantes',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('total_vacantes');
        if (sortOrder === 'descend') setSortColumn('-total_vacantes');
      },
    },
    {
      title: 'Total aplicantes',
      dataIndex: 'aplicantes',
      key: 'aplicantes',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('aplicantes');
        if (sortOrder === 'descend') setSortColumn('-tiaplicantespo');
      },
    },
    {
      title: 'Preseleccionados',
      dataIndex: 'preseleccionados',
      key: 'preseleccionados',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('preseleccionados');
        if (sortOrder === 'descend') setSortColumn('-preseleccionados');
      },
    },
    {
      title: 'Seleccionados',
      dataIndex: 'seleccionados',
      key: 'seleccionados',
      align: 'center',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('seleccionados');
        if (sortOrder === 'descend') setSortColumn('-seleccionados');
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <CustomButton
          block
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${record.convocatoria_id}/${record.id}`}
            >
              Validar
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de selección
      </Title>
      <AjaxTable
        columns={columns}
        pageSize={10}
        sortBy={sortColumn}
        reloadButton
        searchInput
        tableTitle={'Listado de cargos de convocatorias para selección'}
        endpoint={filterAjaxTable}
        query={'/selected/?'}
      />
    </Content>
  );
};

export default AnnouncementPreselection;
