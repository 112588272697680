import React from 'react';
import { Typography, Form, Input, Row, Col, Divider, Descriptions } from 'antd';


const { Title, Paragraph } = Typography;

const AnnouncementValidationExperiencieForm = ({
  onFinish,
  initialValues,
  name,
  useDescriptions = false,
  descriptionsTitle = "",
  descriptionsColumns = 1
}) => {

  return (
    <>
      {
        useDescriptions === true ?
          <>
            <Descriptions layout="vertical" title={descriptionsTitle} column={descriptionsColumns} bordered>
              <Descriptions.Item label="Número de convocatoria">{initialValues.numero}</Descriptions.Item>
              <Descriptions.Item label="Nombre de la convocatoria">{initialValues.nombre}</Descriptions.Item>
              <Descriptions.Item label="Cargo/Rol">{initialValues.cargo}</Descriptions.Item>
            </Descriptions>
            <br></br>
            <Descriptions layout="vertical" title="Información del contratista" column={2} bordered>
              <Descriptions.Item label="Nombre">{name.nombre_completo}</Descriptions.Item>
              <Descriptions.Item label="Número de documento">{name.numero_identificacion}</Descriptions.Item>
            </Descriptions>
            <br></br>
            <br></br>
          </>
          :
          <Form
            layout="vertical"
            name="control-hooks"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item name="numero" label="Número de convocatoria">
              <Input type="text" disabled />
            </Form.Item>
            <Form.Item name="cargo" label="Cargo / Rol">
              <Input type="text" disabled />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item name="nombre" label="Nombre">
                  <Input type="text" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="numero_documento" label="Número de documento">
                  <Input type="text" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
      }
      <Divider></Divider>
    </>
  )
}


export default AnnouncementValidationExperiencieForm;
