import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/restrictive_list/`, data);
};

const postDocument = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/restrictive_list/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/restrictive_list/`);
};

const getByPeople = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/restrictive_list/?filter[tercero]=${id}&sort=-created_at`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/restrictive_list/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/restrictive_list/${id}/`, data);
};

const putDocument = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/restrictive_list/${id}/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/restrictive_list/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/restrictive_list${query}`);
};

export {
  post,
  postDocument,
  getAll,
  get,
  put,
  putDocument,
  remove,
  filter,
  getByPeople
};
