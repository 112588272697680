import React from 'react';
import { Space } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const ForbiddenPage = () => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{
        width: '100%',
        marginTop: '70px',
        textAlign: 'center',
      }}
    >
      <Title level={1}>403</Title>
      <Title level={1}>Acceso no permitido</Title>
    </Space>
  );
};

export const NotFoundPage = () => {
  return (
    <Title level={1}>
      404 <br />
      Página no encontrada
    </Title>
  );
};

export default NotFoundPage;
