import React, { useEffect, useState } from 'react';
import { Form, Typography, Row, Col, notification, Layout } from 'antd';

import CustomUpload from '../../components/CustomUpload';
import CustomButton from '../../components/CustomButton';
import MESSAGES from '../../enums/Messages';

import { getDocuments, putDocuments } from '../../api/module/help_upload';
import SpinLoad from '../../components/SpinLoad';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import Paragraph from 'antd/lib/typography/Paragraph';
import PdfViewer from '../../components/PdfViewer';
import { get as getDocumentUrl } from '../../api/module/document_repository';

const { Title } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Ayudas en formato PDF',
  },
];

const HelpFormat = ({ visible, onCancel }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(async () => {
    setLoading(true);
    await getDataSource();
    setLoading(false);
  }, []);

  const getDataSource = async () => {
    try {
      const response = await getDocuments();
      setDocuments(response.data.results);
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const showClick = async (documento_nombre) => {
    setLoadingDocument(true);

    const response = await getDocumentUrl(documento_nombre);

    setPdfUrl(response.data.url);
    setShowPdf(true);
    setLoadingDocument(false);
  };

  const saveDocument = async (item, data, documentName) => {
    try {
      if (data.fileList.length > 0) {
        const formData = new FormData();
        setLoading(true);
        let response;
        if (data.fileList[0].type === 'application/pdf') {
          data = data.fileList[0].originFileObj;

          formData.append(documentName, data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          response = await putDocuments(item.id, formData);
        }
        if ([200, 201, 204].indexOf(response.status) > -1) {
          documents[item.id - 1] = response.data;
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            setLoading(false);
            const res = response.data;
          }, MESSAGES.TIME);
        }
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Ayudas en formato PDF
      </Title>
      <Paragraph>
        Cargue aquí los PDF de Ayuda con la información orientadora
      </Paragraph>
      <SpinLoad loading={loading}>
        <Form layout="vertical" name="basic">
          {documents.map((item) => (
            <Row align={'middle'} gutter={12} key={item.id}>
              <Col span={22}>
                <Form.Item label={item.descripcion}>
                  <CustomUpload
                    file_type={1}
                    upload_type={1}
                    validate_size={false}
                    onChange={(value) => saveDocument(item, value, item.campo)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                {item.documento != null ? (
                  <CustomButton
                    style={{ marginTop: 5 }}
                    text="Ver"
                    htmlType="button"
                    type="default"
                    loading={loadingDocument}
                    onClick={() => {
                      showClick(item.documento);
                    }}
                  />
                ) : null}
              </Col>
            </Row>
          ))}
          <PdfViewer
            pdf={pdfUrl}
            onCancel={() => setShowPdf(false)}
            visible={showPdf}
          />
        </Form>
      </SpinLoad>
    </Content>
  );
};
export default HelpFormat;
