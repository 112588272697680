import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/sub_cost_centers/`, data);
};

const getAll = async () => {
  return await httpClient.get(`/sub_cost_centers/?page[size]=1000`);
};

const getByCostCenter = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/sub_cost_centers/?filter[centro_costo]=${id}`);
};

const get = async (id) => {
  return await httpClient.get(`/sub_cost_centers/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/sub_cost_centers/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`${process.env.REACT_APP_API_HOST}/sub_cost_centers/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/sub_cost_centers/${id}`);
};

export {
  post,
  getAll,
  get,
  put,
  patch,
  remove,
  getByCostCenter,
}
