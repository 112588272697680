import httpClient from '../httpClient';

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `insurance_policy_contractor/export/?${query}${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `insurance_policy_contractor/?${query}&page[number]=${page}&page[size]=${pageSize}${searchBy}`,
    );
};

const get = async (id) => {
  return await httpClient.get(`insurance_policy_contractor/${id}/`);
};

const send = async (id, data) => {
  return await httpClient.put(`insurance_policy_contractor/${id}/send/`, data);
};

const approvedTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = 'id',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  return await httpClient.get(
    `insurance_policy_contractor/approved_list/?filter[contrato]=${query}&page[number]=${page}&page[size]=${pageSize}${searchBy}`,
  );
};

const approvedDocs = async (id) => {
  return await httpClient.get(
    `insurance_policy_contractor/${id}/approved_docs/`,
  );
};

const getDocUrl = async (id, type) => {
  return await httpClient.get(
    `insurance_policy_contractor/${id}/get_url/?filter[tipo]=${type}`,
  );
};

export { ajaxTable, get, send, getDocUrl, approvedTable, approvedDocs };
