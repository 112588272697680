import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Checkbox, Typography, notification, Table } from 'antd';
import SpinLoad from '../../components/SpinLoad';
import { getAll as getFormat } from '../../api/module/roles_management';
import MESSAGES from '../../enums/Messages';

const { Title, Paragraph } = Typography;

const UserRoleViewer = ({
  visible = false,
  onCancel = () => {},
  group = null,
  userData = null,
}) => {
  const columns_init = [
    {
      title: 'Macro-Proceso',
      dataIndex: 'descripcion',
      key: 'proceso',
    },
    {
      title: 'Opciones Formulario',
      dataIndex: 'tipos',
      key: 'opciones',
      render: (value) => {
        return renderOptions(value);
      },
    },
  ];

  const renderCheckbox = (value) => {
    return value !== undefined ? (
      <Checkbox defaultChecked={value.checked} disabled={true} />
    ) : null;
  };

  const renderOptions = (value) => {
    const text = Object.keys(value).reduce((s, tipo) => {
      s = s + value[tipo].char;
      return s;
    }, '');
    return value !== undefined ? <>{text}</> : null;
  };

  const format_data = useCallback(
    (data, format) => {
      let columns = columns_init;
      const tipos_dict = {};

      format.tipo_permisos.forEach((element) => {
        columns.push({
          title: element.tipo,
          align: 'center',
          dataIndex: ['tipos', element.id],
          key: element.tipo,
          render: (value) => renderCheckbox(value),
        });
        tipos_dict[element.id] = element.tipo.charAt(0);
      });

      let formatedData = [];

      if (data) {
        for (let i = 0; i < format.lista_permisos.length; i++) {
          const element = format.lista_permisos[i];
          const data_element = data.lista_permisos[i];

          const tipos = element.tipos.reduce(function (map, obj) {
            map[obj] = {
              char: tipos_dict[obj],
              checked: data_element.tipos.includes(obj),
            };
            return map;
          }, {});

          formatedData.push({
            id: element.id,
            descripcion: element.descripcion,
            tipos: tipos,
          });
        }
      } else {
        format.lista_permisos.forEach((element) => {
          const tipos = element.tipos.reduce(function (map, obj) {
            map[obj] = {
              char: tipos_dict[obj],
              checked: false,
            };
            return map;
          }, {});

          formatedData.push({
            id: element.id,
            descripcion: element.descripcion,
            tipos: tipos,
          });
        });
      }

      return {
        columns: columns,
        tipos_dict: tipos_dict,
        lista_permisos: formatedData,
        loading: false,
        format: format,
      };
    },
    [group],
  );

  const userColumns = [
    {
      title: 'Nombres',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Email',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
    {
      title: 'Rol asignado',
      dataIndex: 'grupo_nombre',
    },
    {
      title: 'Estado colaborador',
      dataIndex: 'estado',
      key: 'estado',
      render: (value) => {
        return value === true ? 'Activo' : 'Inactivo';
      },
    },
  ];

  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    if (group)
      getFormat()
        .then((response) => {
          const formated = format_data(group, response);
          setState(formated);
        })
        .catch(() =>
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_POST_MSG,
            duration: 2,
          }),
        );
  }, []);

  return (
    <Modal
      title={
        <Title level={4} style={{ marginBottom: 0 }}>
          {group.nombre}
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      style={{ top: 20 }}
      width={'80%'}
      footer={null}
    >
      <SpinLoad loading={state.loading}>
        {group && (
          <>
            <Table
              rowKey="id"
              scroll={{ x: 'max-content' }}
              dataSource={state.lista_permisos}
              columns={state.columns}
              pagination={false}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Permisos que tiene el rol seleccionado
                </Paragraph>
              )}
            />
          </>
        )}
        {userData && (
          <Table
            rowKey="id"
            scroll={{ x: 'max-content' }}
            dataSource={[userData]}
            columns={userColumns}
            pagination={false}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Datos del colaborador
              </Paragraph>
            )}
          />
        )}
      </SpinLoad>
    </Modal>
  );
};
export default UserRoleViewer;
