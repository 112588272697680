import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/term_comments/`, data);
};

const getByTerm = async (term_id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/term_comments/?filter[termino]=${term_id}&sort=created_at`);
};

export {
  post,
  getByTerm
}
