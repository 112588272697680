import React, { useReducer, useEffect, useCallback } from 'react';
import {
  Table,
  Typography,
  Row,
  Space,
  Checkbox,
  notification,
  Input,
  Form,
  Col,
  Card,
} from 'antd';
import CustomButton from '../../components/CustomButton';
import {
  getAll as getFormat,
  put as saveChanges,
  post as saveNew,
} from '../../api/module/roles_management';
import MESSAGES from '../../enums/Messages';
import { CustomTooltip } from '../../components/CustomTooltip';

const { Title, Paragraph } = Typography;

const RolesPermissionsForm = ({
  loading = false,
  dataSource = null,
  creator = false,
  onClear = () => {},
  onUpdate = () => {},
  onClose = () => {},
}) => {
  const columns_init = [
    {
      title: 'Macro-Proceso',
      dataIndex: 'descripcion',
      key: 'proceso',
      render: (text, record) => {
        return (
          <>
            {text}
            {record.info && <CustomTooltip title={record.info} />}
          </>
        );
      },
    },
    {
      title: 'Opciones Formulario',
      dataIndex: 'tipos',
      key: 'opciones',
      render: (value) => {
        return renderOptions(value);
      },
    },
  ];

  const renderCheckbox = (value, index, fieldName) => {
    return value !== undefined ? (
      <Checkbox
        defaultChecked={value.checked}
        onChange={(e) => onChangeCheck(e, index, fieldName)}
      />
    ) : null;
  };

  const renderOptions = (value) => {
    const text = Object.keys(value).reduce((s, tipo) => {
      s = s + value[tipo].char;
      return s;
    }, '');
    return value !== undefined ? <>{text}</> : null;
  };

  const format_data = useCallback(
    (data, format) => {
      let columns = columns_init;
      const tipos_dict = {};

      format.tipo_permisos.forEach((element) => {
        columns.push({
          title: element.tipo,
          align: 'center',
          dataIndex: ['tipos', element.id],
          key: element.tipo,
          render: (value, row) => renderCheckbox(value, row.i, element.id),
        });
        tipos_dict[element.id] = element.tipo.charAt(0);
      });

      let formatedData = [];

      if (data) {
        for (let i = 0; i < format.lista_permisos.length; i++) {
          const element = format.lista_permisos[i];
          const data_element = data.lista_permisos[i];

          const tipos = element.tipos.reduce(function (map, obj) {
            map[obj] = {
              char: tipos_dict[obj],
              checked: data_element.tipos.includes(obj),
            };
            return map;
          }, {});

          formatedData.push({
            id: element.id,
            descripcion: element.descripcion,
            info: element.info,
            menu: element.menu,
            tipos: tipos,
            i: i,
          });
        }
      } else {
        format.lista_permisos.forEach((element, i) => {
          const tipos = element.tipos.reduce(function (map, obj) {
            map[obj] = {
              char: tipos_dict[obj],
              checked: false,
            };
            return map;
          }, {});

          formatedData.push({
            id: element.id,
            descripcion: element.descripcion,
            info: element.info,
            menu: element.menu,
            tipos: tipos,
            i: i,
          });
        });
      }

      const nested_list = formatedData.reduce((pv, cv) => {
        (pv[cv.menu] = pv[cv.menu] || []).push(cv);
        return pv;
      }, {});

      const nested_keys = Object.keys(nested_list).map((k) => {
        return { id: k };
      });

      return {
        columns: columns,
        tipos_dict: tipos_dict,
        lista_permisos: formatedData,
        lista_arbol: nested_list,
        lista_menus: nested_keys,
        loading: false,
        format: format,
      };
    },
    [dataSource],
  );

  const state_reducer = (state, action) => {
    if (action.init) {
      return action.init;
    }
    if (action.type === 'load') {
      return {
        ...state,
        loading: action.payload,
      };
    }
    state.lista_permisos[action.index].tipos[action.fieldName].checked =
      action.payload;
    return state;
  };
  const [state, dispatch] = useReducer(state_reducer, { loading: true });
  const [form] = Form.useForm();

  useEffect(() => {
    getFormat()
      .then((response) => {
        const formated = format_data(dataSource, response);
        dispatch({ init: formated });
      })
      .catch(() =>
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        }),
      );
  }, []);

  const onChangeCheck = (e, index, fieldName) => {
    dispatch({
      index: index,
      fieldName: fieldName,
      payload: e.target.checked,
    });
  };

  const toSerializable = (list) => {
    return list.map((element) => {
      return {
        id: element.id,
        tipos: Object.keys(element.tipos).map((tipo) => {
          return { id: tipo, checked: element.tipos[tipo].checked };
        }),
      };
    });
  };

  const save = () => {
    dispatch({ type: 'load', payload: true });
    saveGroupPermissionList().finally(() => {
      dispatch({ type: 'load', payload: false });
    });
  };

  const validateName = () => {
    form
      .validateFields()
      .then((values) => save())
      .catch((err) => {});
  };

  const saveGroupPermissionList = async () => {
    try {
      const data_list = toSerializable(state.lista_permisos);
      let data;
      if (creator) {
        data = {
          nombre_grupo: form.getFieldValue('groupName'),
          lista_permisos: data_list,
        };
      } else {
        data = {
          nombre_grupo: dataSource.nombre,
          lista_permisos: data_list,
        };
      }
      let response;
      if (creator) {
        response = await saveNew(data);
      } else {
        response = await saveChanges(dataSource.id, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
      }
      if (creator) onClear();
      else onUpdate();
    } catch (err) {
      console.log(err);
      try {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: err.response.data.errors[0].detail,
          duration: 2,
        });
      } catch {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    }
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={state.lista_arbol[record.id]}
        columns={state.columns}
        pagination={false}
      />
    );
  };

  return (
    <Card
      loading={state.loading || loading}
      size={'small'}
      bordered={false}
      title={
        (creator && dataSource && <Title level={5}>Duplicar rol</Title>) ||
        (creator && !dataSource && <Title level={5}>Crear nuevo rol</Title>) ||
        (!creator && dataSource && <Title level={5}>Editar rol</Title>)
      }
    >
      {creator && (
        <Form form={form} layout={'vertical'}>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Favor ingresar un nombre para el rol',
                  },
                ]}
                label="Digite el nombre del nuevo rol"
                name="groupName"
              >
                <Input placeholder="Nombre del nuevo rol" allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      <Paragraph>
        A continuación, seleccione los permisos que tendrá la persona
        seleccionada. Para ello, tenga en cuenta las siguientes definiciones:
      </Paragraph>
      {state.format && (
        <Space direction="horizontal" size={[40, 10]} wrap>
          <Paragraph>Opciones formulario:</Paragraph>
          {state.format.tipo_permisos.map((item) => (
            <Paragraph key={item.id}>
              {item.tipo.charAt(0)} = {item.tipo}
            </Paragraph>
          ))}
        </Space>
      )}
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={state.lista_menus}
        columns={[{ title: 'Menú', dataIndex: 'id' }]}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          defaultExpandAllRows: true,
        }}
        pagination={false}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de Tipos de procesos por Menú
          </Paragraph>
        )}
      />
      {creator ? (
        <CustomButton
          style={{ marginRight: 10 }}
          text={'Crear rol'}
          onClick={validateName}
        />
      ) : (
        <CustomButton
          style={{ marginRight: 10 }}
          text={'Guardar cambios'}
          onClick={save}
        />
      )}
      <CustomButton
        type="default"
        htmlType={'button'}
        danger
        text="Cerrar"
        onClick={onClose}
      />
    </Card>
  );
};

export default RolesPermissionsForm;
