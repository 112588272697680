import React, { useState, useEffect } from 'react';
import { Alert, Layout, notification, Row, Space } from 'antd';

import AnnouncementCloseSearchForm from '../../containers/AnnouncementCloseSearchForm';
import MESSAGES from '../../enums/Messages';
import { CustomButton } from '../../components';

import { post } from '../../api/module/contractor_search';
import { post as addContract } from '../../api/module/announcement_invite';
import { ListContractors } from './ListContractors';
import { PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

export const AnnouncementCloseSearch = ({
  announcement_id,
  getAddedContractor,
  addedContractor,
  loadingContractor,
  setLoadingContractor,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [newDataContractor, setNewDataContractor] = useState(null);

  const onFinish = async (data) => {
    try {
      if (data.documento === '') {
        data.documento = undefined;
      }
      if (data.nombre === '') {
        data.nombre = undefined;
      }
      if (data.email === '') {
        data.email = undefined;
      }
      if (data.tipo_cargo === '') {
        data.tipo_cargo = undefined;
      }
      if (data.meses_experiencia === '') {
        data.meses_experiencia = undefined;
      }
      if (data.pregrado != null) {
        data.nivel_educativo = 7;
        data.area_conocimiento = data.pregrado;
        delete data.pregrado;
      }
      if (data.especializacion != null) {
        data.nivel_educativo = 8;
        data.area_conocimiento = data.especializacion;
        delete data.especializacion;
      }
      if (data.maestria != null) {
        data.nivel_educativo = 9;
        data.area_conocimiento = data.maestria;
        delete data.maestria;
      }
      if (data.doctorado != null) {
        data.nivel_educativo = 10;
        data.area_conocimiento = data.doctorado;
        delete data.doctorado;
      }
      if (data.posdoctorado != null) {
        data.nivel_educativo = 11;
        data.area_conocimiento = data.posdoctorado;
        delete data.posdoctorado;
      }

      data.tipo_persona = 0;
      data.termino = 0;
      setLoading(true);
      const response = await post(data);
      setLoading(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelect(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setDataSelect(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setDataSelect(selectedRows);
    },
  };

  const save = async () => {
    if (dataSelect.length > 0) {
      setShowAlert(false);
      dataSelect.map(async (item) => {
        try {
          setLoading(true);
          const response = await addContract({
            tercero: item.tercero,
            convocatoria: announcement_id,
          });
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
          }
          setLoading(false);
          setLoadingContractor(true);
          await getAddedContractor();
          setLoadingContractor(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      });

      setDataSource([]);
    } else {
      setShowAlert(true);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_ADD_CONTRACTOR,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // search data filtering
    let newDataSource = [...dataSource];
    let newAddedContractor = [...addedContractor];
    const newArray = [];

    for (let i = 0; i < newDataSource.length; i++) {
      let equal = false;
      for (let j = 0; (j < newAddedContractor.length) & !equal; j++) {
        if (newAddedContractor[j]['tercero']) {
          if (
            newDataSource[i]['tercero'] === newAddedContractor[j]['tercero'].id
          ) {
            equal = true;
          }
        }
      }
      if (!equal) newArray.push(newDataSource[i]);
    }
    setNewDataContractor(newArray);
  }, [addedContractor, dataSource]);

  return (
    <Content>
      <AnnouncementCloseSearchForm
        dataSource={newDataContractor}
        setDataSource={setDataSource}
        loading={loading}
        onFinish={onFinish}
        rowSelection={rowSelection}
      />
      {announcement_id && dataSource.length > 0 && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Row justify={'center'} gutter={12}>
            <CustomButton
              type={'default'}
              className={'ant-btn-info'}
              loading={loading}
              onClick={() => save()}
              text={
                <>
                  <PlusOutlined /> Agregar contratista
                </>
              }
            />
          </Row>
          {showAlert && (
            <Alert
              message={'Seleccione un contratista'}
              description={
                'Por favor seleccione al menos un contratista para agregar.'
              }
              type="error"
              showIcon
            />
          )}
        </Space>
      )}
      <ListContractors
        loadingChange={loadingContractor}
        setLoadingChange={setLoadingContractor}
        announcement_id={announcement_id}
        addedContractor={addedContractor}
        getAddedContractor={getAddedContractor}
      />
    </Content>
  );
};
