import React, { useState, useEffect, useRef } from 'react';
import {
  Popconfirm,
  Typography,
  notification,
  Radio,
  Row,
  Modal,
  Descriptions,
  Skeleton,
} from 'antd';
import Col from 'antd/lib/col';

import CustomButton from '../../components/CustomButton';
import WorkTeamSearch from '../WorkTeamSearch';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

//API Imports

import {
  getListByRole,
  remove,
  notify,
  ajaxTable as filterWorkTeam,
} from '../../api/module/project_position_assignments';
import { get as getCargo } from '../../api/module/project_positions';
import AjaxTable from '../../components/AjaxTable';

const { Title, Text } = Typography;
const breadcrumbItems = [
  {
    text: 'Definición de cargo y Equipo de trabajo',
    to: `${INTERNAL_LINKS.PROJECT_TEAM}`,
  },
  {
    text: 'Equipo de trabajo',
  },
];

export default function WorkTeam({ match, history }) {
  const [assignmentType, setAssignmentType] = useState(null);
  const [assignmentPerson, setAssignmentPerson] = useState(1);
  const [cargoID] = useState(parseInt(match.params.position_id));
  const [cargoData, setCargoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [reloadSource, setReloadSource] = useState(false);

  const columns = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero', 'nombre_completo'],
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      width: '12rem',
      align: 'center',
      dataIndex: ['tercero', 'tipo_identificacion_sigla'],
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      align: 'center',
      dataIndex: ['tercero', 'numero_identificacion'],
      key: 'num_documento',
    },
    {
      title: 'Fecha expedición',
      width: '12rem',
      align: 'center',
      dataIndex: ['tercero', 'fecha_expedicion_documento'],
      key: 'fecha_expedicion',
    },
    {
      title: 'Correo Electrónico',
      align: 'center',
      dataIndex: ['tercero', 'notificacion_email'],
      key: 'email',
    },
    {
      title: 'Estado',
      dataIndex: ['estado_contrato', [1]],
      fixed: 'right',
      align: 'center',
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      width: 125,
      render: (_, record) => (
        <>
          {cargoData.has_ppf ? (
            'Cargue masivo'
          ) : record.estado_contrato[0] > 3 ||
            record.estado_contrato[0] === 0 ? (
            <Popconfirm
              title={MESSAGES.DELETE_CONFIRM_MSG}
              onConfirm={() => deleteDataSource(record.id)}
            >
              <CustomButton
                danger
                type="default"
                text="Eliminar"
                loading={deleteLoading}
              />
            </Popconfirm>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const ref = useRef();

  const getRole = () => {
    try {
      setLoading(true);
      getCargo(cargoID).then((res) => {
        setCargoData(res.data);
        if (res.data.has_ppf) {
          setAssignmentType(3);
        }
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const sendNotifications = async () => {
    try {
      const response = await notify(cargoID);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_TEAM_NOTIFY,
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getRole();
    getDataSource();
  }, []);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, [cargoData]);

  if (!cargoData && !loading) {
    return <Title level={1}>No se encontro el Cargo/Rol</Title>;
  }

  const getDataSource = async () => {
    try {
      const response = await getListByRole(cargoID);
      let resumeType = 0;
      for (let item of response.data.results) {
        if (resumeType === 0) {
          resumeType = item.tercero.tipo_persona;
        } else if (resumeType !== item.tercero.tipo_persona) {
          Modal.confirm({
            content:
              'El equipo de trabajo tiene personas naturales y jurídicas. Recuerde elaborar los TDR respectivos en caso de que no se hayan creado',
            okText: 'Entiendo',
            cancelButtonProps: { style: { display: 'none' } },
          });
          break;
        }
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  const deleteDataSource = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await remove(id);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: 2,
        });
      }
      await getDataSource();
      setReloadSource(!reloadSource);
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
      let description = '';
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        <span ref={ref} />
        Equipo de trabajo
      </Title>
      <Skeleton loading={loading} active>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Proyecto">
            <Text strong>{cargoData ? cargoData.proyecto_nombre : ''}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Cargo/Rol seleccionado">
            <Text strong>{cargoData ? cargoData.cargo : ''}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Skeleton>
      {cargoData && (
        <>
          <div className="box-title">
            <Text>
              Seleccione la forma en que desea agregar las personas para el
              Cargo/Rol
            </Text>
            <Row justify="start" style={{ marginTop: '15px' }}>
              <Radio.Group
                onChange={(e) => setAssignmentType(e.target.value)}
                value={assignmentType}
              >
                <Radio value={1}>Asignar personas desde SIGA</Radio>
                <Radio value={0}>Asignar personas desde Convocatoria</Radio>
                <Radio value={3}>Cargue masivo</Radio>
              </Radio.Group>
            </Row>
          </div>
          {assignmentType === 1 && (
            <div className="box-title">
              <Text>Seleccione el tipo de persona que desea agregar</Text>
              <Row justify="start" style={{ marginTop: '15px' }}>
                <Radio.Group
                  defaultValue={1}
                  onChange={(e) => setAssignmentPerson(e.target.value)}
                >
                  <Radio value={1}>Natural</Radio>
                  <Radio value={2}>Jurídica</Radio>
                </Radio.Group>
              </Row>
            </div>
          )}
          <WorkTeamSearch
            assignmentType={assignmentType}
            cargoID={cargoID}
            pendingMassive={cargoData.has_ppf}
            setPendingMassive={(v) =>
              setCargoData((s) => ({ ...s, has_ppf: v }))
            }
            personType={assignmentPerson}
            afterAddPosition={() => {
              getDataSource();
              setReloadSource(!reloadSource);
            }}
          />
          <AjaxTable
            columns={columns}
            pageSize={10}
            exportCSV
            searchInput
            scroll={{ x: 1200 }}
            tableTitle={'Listado de contratistas agregados'}
            endpoint={filterWorkTeam}
            reloadSource={reloadSource}
            query={`/?filter[cargo]=${cargoID}`}
          />
          <Row gutter={12}>
            <Col span={12}>
              {cargoData && (
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={() =>
                    history.push(
                      `${INTERNAL_LINKS.PROJECT_TEAM}/${cargoData.proyecto}`,
                    )
                  }
                  text="Volver"
                />
              )}
            </Col>
            <Col span={12}>
              <CustomButton
                htmlType="button"
                text="Notificar por correo electrónico"
                onClick={sendNotifications}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
