import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  notification,
  Space,
  Tabs,
  Typography,
  Popconfirm,
  Row,
  Col,
} from 'antd';

import AjaxTable from '../../../components/AjaxTable';
import CustomButton from '../../../components/CustomButton';
import { SelectProjects } from '../../../components/SelectProjects';

import BreadcrumbContainer from '../../../containers/Breadcrumb';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import {
  filter as filterMass,
  remove as removeMass,
  getCounters as getCountersMass,
} from '../../../api/module/mass_contract_modifications';

import {
  filter,
  remove,
  getCounters,
} from '../../../api/module/contract_modifications';
import { IsRole } from '../../../utils/helpers/helpers';

import './style.scss';
import { ClockCircleOutlined } from '@ant-design/icons';
import PdfViewer from '../../../components/PdfViewer';
import { generateWordTemplateURL } from '../../../api/module/templates';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const initCounters = () => ({
  approval: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  created: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  drafts: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  return: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  returned: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
  validated: (
    <ClockCircleOutlined style={{ marginLeft: '13px', marginRight: '3px' }} />
  ),
});

const ContractModificationList = ({ project_id }) => {
  const [userId] = useState(parseInt(window.localStorage['userId']));
  const [counters, setCounters] = useState(initCounters);

  const [manager] = useState(IsRole(1));
  const [coordinator] = useState(IsRole(2));
  const [lead] = useState(IsRole(8));
  const [principal] = useState(IsRole(9));
  const [legal] = useState(IsRole(3));

  const [activeTab, setActiveTab] = useState(
    manager || coordinator || lead ? 0 : legal ? 3 : 1,
  );

  const [reloadSource, setReloadSource] = useState(project_id);
  const [deleteID, setDeleteID] = useState(undefined);

  const [pdfUrl, setPDFUrl] = useState(undefined);
  const [loadingPDF, setLoadingPDF] = useState(undefined);

  const [massTab, setMassTab] = useState(0);

  const getURLPDF = async (record) => {
    try {
      setLoadingPDF(record.id);
      let response = await generateWordTemplateURL({
        person: record.tercero,
        term: record.termino,
        person_type: record.tipo_persona,
        contract: record.contrato,
        modification: record.id,
        template: 5,
      });
      setPDFUrl(response.data.url);
      setLoadingPDF(null);
    } catch (err) {
      setLoadingPDF(null);
      console.log(err);
    }
  };

  const getCountersData = async () => {
    let res;
    setCounters(initCounters);
    if (massTab) res = await getCountersMass(project_id);
    else res = await getCounters(project_id);
    setCounters(res.data.results);
  };

  useEffect(async () => {
    try {
      await getCountersData();
      setReloadSource(project_id);
    } catch (err) {
      console.log(err);
    }
  }, [project_id, massTab]);

  const deleteDataSource = async (id) => {
    try {
      setDeleteID(id);
      if (massTab) await removeMass(id);
      else await remove(id);
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
        duration: 2,
      });
      await getCountersData();
      setReloadSource({});
      setDeleteID(undefined);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description:
          'Elimine primero los módulos asociados a esta modificación contractual.',
        duration: 4,
      });
      setDeleteID(undefined);
    }
  };

  const columns = [
    {
      title: 'Nombre Contratista/Proveedor',
      dataIndex: 'contratista_nombre',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'identificacion',
    },
    {
      title: 'Cargo / Rol',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
    },
    ...([1, 4].includes(activeTab)
      ? [
          {
            title: 'Enviado a',
            dataIndex: 'enviado_a',
          },
        ]
      : []),
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: activeTab === 5 ? 300 : 200,
      render: (_, record) => (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <CustomButton
            block
            text="Generar PDF"
            onClick={() => {
              getURLPDF(record);
            }}
            loading={loadingPDF === record.id}
            style={{ width: '100%' }}
          />
          {activeTab !== 5 && activeTab !== 4 && activeTab !== 1 && (
            <CustomButton
              block
              type="default"
              style={{ width: '100%' }}
              text={
                <Link
                  to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${project_id}/${record.id}`}
                >
                  Editar
                </Link>
              }
            />
          )}
          {activeTab === 0 && (manager || coordinator || lead) && (
            <Popconfirm
              title={MESSAGES.DELETE_CONFIRM_MSG}
              onConfirm={() => {
                deleteDataSource(record.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <CustomButton
                style={{ width: '100%' }}
                danger
                block
                type="default"
                text="Eliminar"
                loading={record.id === deleteID}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const columnsMass = [
    {
      title: 'Identificador interno de modificación',
      dataIndex: 'identificador_masivo',
    },
    {
      title: 'Cargo / Rol',
      dataIndex: 'cargo_nombre',
    },
    ...([1, 4].includes(activeTab)
      ? [
          {
            title: 'Enviado a',
            dataIndex: 'enviado_a',
          },
        ]
      : []),
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: activeTab === 5 ? 300 : 200,
      render: (_, record) => (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <CustomButton
            block
            text="Generar PDF"
            onClick={() => {
              getURLPDF(record);
            }}
            loading={loadingPDF === record.id}
            style={{ width: '100%' }}
          />
          {activeTab !== 5 && activeTab !== 4 && activeTab !== 1 && (
            <CustomButton
              block
              type="default"
              style={{ width: '100%' }}
              text={
                <Link
                  to={`${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${project_id}/${record.id}`}
                >
                  Editar
                </Link>
              }
            />
          )}
          {activeTab === 0 && (manager || coordinator || lead) && (
            <Popconfirm
              title={MESSAGES.DELETE_CONFIRM_MSG}
              onConfirm={() => {
                deleteDataSource(record.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <CustomButton
                style={{ width: '100%' }}
                danger
                block
                type="default"
                text="Eliminar"
                loading={record.id === deleteID}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {(manager || coordinator || lead) && (
        <>
          <Paragraph>
            A continuación, encontrará una tabla con los registros de
            modificaciones contractuales creados para este proyecto. Si desea
            agregar una nueva modificación contractual, por favor, haga click en
            el botón "Nueva modificación contractual"
          </Paragraph>
          <Space>
            <CustomButton
              text={
                <Link
                  to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${project_id}`}
                >
                  Nueva modificación contractual - Individual
                </Link>
              }
            />
            <CustomButton
              text={
                <Link
                  to={`${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/${project_id}`}
                >
                  Nueva modificación contractual - Masiva
                </Link>
              }
            />
          </Space>

          <br />
        </>
      )}
      <Tabs
        type="card"
        destroyInactiveTabPane={true}
        style={{ marginTop: 15 }}
        onChange={(value) => setMassTab(parseInt(value))}
        defaultActiveKey={massTab}
      >
        <TabPane tab="Individual" key={0}>
          <Tabs
            type="card"
            defaultActiveKey={activeTab}
            destroyInactiveTabPane={true}
            onChange={(value) => {
              setActiveTab(parseInt(value));
            }}
            style={{ marginTop: 15 }}
          >
            {(manager || coordinator || lead) && (
              <TabPane
                tab={
                  <Paragraph>
                    Borrador
                    <Badge showZero count={counters.drafts} />
                  </Paragraph>
                }
                key={0}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    return await filter(
                      `?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-B&filter[usuario]=${userId}`,
                      page,
                      pageSize,
                    );
                  }}
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || coordinator || lead || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    En revisión
                    <Badge showZero count={counters.created} />
                  </Paragraph>
                }
                key={1}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    return await filter(
                      `?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-R&filter[usuario_origen]=${userId}`,
                      page,
                      pageSize,
                    );
                  }}
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || coordinator || lead || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Devueltos a mí
                    <Badge showZero count={counters.return} />
                  </Paragraph>
                }
                key={2}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-D&filter[usuario_destino]=${userId}`;
                    return await filter(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || lead || legal || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Para aprobación
                    <Badge showZero count={counters.approval} />
                  </Paragraph>
                }
                key={3}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-R&filter[usuario_destino]=${userId}`;
                    return await filter(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || lead || legal || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Devueltos por mí
                    <Badge showZero count={counters.returned} />
                  </Paragraph>
                }
                key={4}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-D&filter[usuario_origen]=${userId}`;
                    return await filter(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {legal && (
              <TabPane
                tab={
                  <Paragraph>
                    Validados
                    <Badge showZero count={counters.validated} />
                  </Paragraph>
                }
                key={5}
              >
                <AjaxTable
                  endpoint={filter}
                  query={`?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-V&filter[usuario_destino]=${userId}`}
                  searchInput
                  columns={columns}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
          </Tabs>
        </TabPane>
        <TabPane tab="Masivo" key={1}>
          <Tabs
            type="card"
            defaultActiveKey={activeTab}
            destroyInactiveTabPane={true}
            onChange={(value) => {
              setActiveTab(parseInt(value));
            }}
            style={{ marginTop: 15 }}
          >
            {(manager || coordinator || lead) && (
              <TabPane
                tab={
                  <Paragraph>
                    Borrador
                    <Badge showZero count={counters.drafts} />
                  </Paragraph>
                }
                key={0}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    return await filterMass(
                      `?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-B&filter[usuario]=${userId}`,
                      page,
                      pageSize,
                    );
                  }}
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || coordinator || lead || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    En revisión
                    <Badge showZero count={counters.created} />
                  </Paragraph>
                }
                key={1}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    return await filterMass(
                      `?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-R&filter[usuario_origen]=${userId}`,
                      page,
                      pageSize,
                    );
                  }}
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || coordinator || lead || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Devueltos a mí
                    <Badge showZero count={counters.return} />
                  </Paragraph>
                }
                key={2}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-D&filter[usuario_destino]=${userId}`;
                    return await filterMass(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || lead || legal || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Para aprobación
                    <Badge showZero count={counters.approval} />
                  </Paragraph>
                }
                key={3}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-R&filter[usuario_destino]=${userId}`;
                    return await filterMass(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {(manager || lead || legal || principal) && (
              <TabPane
                tab={
                  <Paragraph>
                    Devueltos por mí
                    <Badge showZero count={counters.returned} />
                  </Paragraph>
                }
                key={4}
              >
                <AjaxTable
                  endpointFetch={async (page, pageSize) => {
                    const thisQuery = `filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-D&filter[usuario_origen]=${userId}`;
                    return await filterMass(`?${thisQuery}`, page, pageSize);
                  }}
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
            {legal && (
              <TabPane
                tab={
                  <Paragraph>
                    Validados
                    <Badge showZero count={counters.validated} />
                  </Paragraph>
                }
                key={5}
              >
                <AjaxTable
                  endpoint={filterMass}
                  query={`?filter[proyecto]=${project_id}&filter[estado_proceso__sigla]=CT-MC-V&filter[usuario_destino]=${userId}`}
                  searchInput
                  columns={columnsMass}
                  reloadSource={reloadSource}
                />
              </TabPane>
            )}
          </Tabs>
        </TabPane>
      </Tabs>

      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setPDFUrl(undefined)}
        visible={pdfUrl}
        isWord={true}
      />
    </>
  );
};

const ContractModificationHome = () => {
  const [project, setProject] = useState(null);
  const breadcrumbItems = [
    {
      text: 'Modificaciones Contractuales',
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title>MÓDULO MODIFICACIONES CONTRACTUALES</Title>
      </div>
      <Paragraph>
        Seleccione el proyecto en el cual desea realizar las modificaciones
        contractuales
      </Paragraph>
      <Row align="middle" gutter={8}>
        <Col>Proyecto</Col>
        <Col flex="auto">
          <SelectProjects style={{ width: '100%' }} onChange={setProject} />
        </Col>
      </Row>
      <br />
      {project != null && (
        <>
          <ContractModificationList project_id={project} />
        </>
      )}
    </>
  );
};

export default ContractModificationHome;
