import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const CustomTooltip = ({ title }) => {

  return (
    <Tooltip title={title}>
      <QuestionCircleOutlined style={{ color: '#8c8c8c', marginLeft: 5 }} />
    </Tooltip>
  )
}
