import React from 'react';
import {
  Typography,
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Radio,
  Space,
  Skeleton,
  Divider,
  PageHeader,
} from 'antd';

import { CustomButton } from '../../components';
import { post as postUser } from '../../api/module/user';
import { post as postAuthorization } from '../../api/module/user_authorize';
import { getFilteredByRegister as getAuthorization } from '../../api/module/authorize';
import MESSAGES from '../../enums/Messages';

import './style.scss';
import { Link } from 'react-router-dom';
import InternalLinks from '../../enums/InternalLinks';
import PublicHeader from '../PublicHeader';
import UserRegisterForm from '../UserRegisterForm';

const { Paragraph } = Typography;

export default class UserAuthorizeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alert: {
        show: false,
      },
      skeleton: true,
      reason: false,
      register: false,
      authorizations: [],
      authorization: [],
    };
  }

  async componentDidMount() {
    await this.getAuthorizations();
  }

  async getAuthorizations() {
    try {
      const response = await getAuthorization().then((res) => res.data.results);
      const authorize = [];

      this.setState({ skeleton: false });
      this.setState({ authorizations: response });

      for (let i = 0; i < response.length; i++) {
        authorize[i] = null;
      }

      this.setState({ authorization: authorize });
    } catch (err) {
      this.setState({
        alert: {
          show: true,
          message: 'Error',
          description: err,
          type: 'error',
        },
      });
    }
  }

  onFinish = async (data) => {
    try {
      delete data.confirmar_digito_verificacion;
      delete data.notificacion_email_confirm;
      delete data.numeroConfirm;
      delete data.nitConfirm;
      delete data.recaptcha;
      delete data.password_confirm;

      data.primer_apellido =
        data.tipo_persona === 2 ? '' : data.primer_apellido;
      data.username = data.notificacion_email;

      this.setState({ loading: true });

      let response = await postUser(data);

      this.setState({ loading: false });

      if ([200, 201, 204].indexOf(response.status) > -1) {
        // setIcon(true);
        this.setState({
          alert: {
            show: true,
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_REGISTER_MSG,
            type: 'success',
            icon: true
          },
        });

        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch (exception) {
      this.setState({ loading: false });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudo crear el usuario, intente nuevamente.';
      }

      this.setState({
        alert: {
          show: true,
          message: 'Error',
          description: description,
          type: 'error',
        },
      });

      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  setAuthorization(auth, value) {
    const authorize = this.state.authorization;
    let reason = 0,
      register = 0,
      none = 0;

    this.setState({ reason: true });

    authorize[auth - 1] = value;
    authorize.map((value) => {
      if (value === null) none++;
      if (value === 0) reason++;
      if (value === 1) register++;
    });

    if (register === authorize.length) {
      this.setState({ register: true });
      this.setState({ reason: false });
    } else {
      if (reason <= authorize.length) {
        this.setState({ register: false });
        this.setState({ reason: true });
      }
      if (none > 0) {
        this.setState({ register: false });
        this.setState({ reason: false });
      }
    }

    this.setState({ authorization: authorize });
  }

  async authorizationSave(data) {
    try {
      const authorize = this.state.authorization;

      this.setState({ loading: true });

      authorize.map(async (value, index) => {
        if (value !== null) {
          data['autorizacion'] = index + 1;
          data['estado'] = !value;

          const response = await postAuthorization(data);

          this.setState({ loading: false });

          if ([200, 201, 204].indexOf(response.status) > -1) {
            this.setState({
              alert: {
                show: true,
                message: MESSAGES.SUCCESS_MSG,
                description: 'Se ha enviado su motivo',
                type: 'success',
              },
            });
          }
        }
      });
    } catch (exception) {
      this.setState({ loading: false });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se ha podido enviar su motivo';
      }

      this.setState({
        alert: {
          show: true,
          message: 'Error',
          description: description,
          type: 'error',
        },
      });
    }
  }

  render() {
    const loading = this.state.loading;
    const alert = this.state.alert;
    const skeleton = this.state.skeleton;
    const authorizations = this.state.authorizations;
    const authorization = this.state.authorization;
    const reason = this.state.reason;
    const register = this.state.register;

    return (
      <Row justify={'center'} align={'top'} className={'pb-8'}>
        <Col
          xs={22}
          sm={19}
          md={16}
          lg={14}
          xl={12}
          className={'form-container'}
        >
          <PublicHeader />
          <PageHeader
            className={'public-page-header'}
            title={'Autorizaciones'}
          />
          <Space direction={'vertical'} size={'small'} className={'w-100'}>
            <Skeleton loading={skeleton} active>
              <Form
                name={'normal_authorize'}
                layout={'vertical'}
                requiredMark={'optional'}
                scrollToFirstError={true}
                onFinish={(data) => this.authorizationSave(data)}
              >
                <Paragraph className={'mb-0'}>
                  Por favor, lea atentamente la siguiente información:
                </Paragraph>
                <Space
                  direction={'vertical'}
                  size={'small'}
                  className={'w-100'}
                >
                  <div>
                    {authorizations.map((auth) => (
                      <div key={auth.id}>
                        <Divider />
                        <div
                          className={'auth-descriptions'}
                          dangerouslySetInnerHTML={{ __html: auth.descripcion }}
                        />

                        <Radio.Group
                          onChange={(e) =>
                            this.setAuthorization(auth.id, e.target.value)
                          }
                          value={authorization[auth.id - 1]}
                          name={'auth-' + auth.id}
                        >
                          <Radio value={1}>Sí</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </div>
                    ))}
                  </div>
                  {!reason && !register && (
                    <Link to={InternalLinks.HOME}>
                      <Button danger>Volver</Button>
                    </Link>
                  )}
                  {reason && (
                    <>
                      <Form.Item
                        label={'Motivo de no autorización'}
                        name={'motivo'}
                        required
                        tooltip={'Ingrese el motivo de no autorización'}
                        rules={[
                          {
                            required: true,
                            message: 'Escriba el motivo',
                          },
                        ]}
                      >
                        <Input
                          allowClear
                          placeholder={
                            'Escriba el motivo por el cual lo rechaza'
                          }
                        />
                      </Form.Item>
                      <Form.Item className={'mb-0'}>
                        <Space size={'small'}>
                          <CustomButton
                            text={'Enviar'}
                            loading={loading}
                            block
                          />
                          <Link to={InternalLinks.HOME}>
                            <Button danger={true} className={'w-100'}>
                              Volver
                            </Button>
                          </Link>
                        </Space>
                      </Form.Item>
                      {alert != null && alert.show === true && (
                        <Alert
                          message={alert.message}
                          description={alert.description}
                          type={alert.type}
                          showIcon
                        />
                      )}
                    </>
                  )}
                </Space>
              </Form>
            </Skeleton>
            {register && (
              <UserRegisterForm
                save={this.onFinish}
                loading={this.state.loading}
                alert={this.state.alert}
              />
            )}
          </Space>
        </Col>
      </Row>
    );
  }
}
