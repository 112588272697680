import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  notification,
  Input,
  Space,
  Select,
  Alert,
  Skeleton,
  Layout,
  Card,
} from 'antd';

import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AnnouncementSelectionValidationRestrictiveForm from '../../../containers/AnnouncementSelectionRestrictiveForm/';

import { get as announcement } from '../../../api/module/announcement';
import { get as announcementRol } from '../../../api/module/announcement_rol';
import {
  get as getResume,
  get as personId,
} from '../../../api/module/contractor';
import { put, get } from '../../../api/module/announcement_selection';
import RestrictiveListTable from '../../../components/RestrictiveListTable';

const { Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const optionSelection = [
  { id: 24, nombre: 'Pendiente' },
  { id: 25, nombre: 'Cumple' },
  { id: 26, nombre: 'No cumple' },
];

const AnnouncementSelectionValidationRestrictive = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [name, setName] = useState('');

  const breadcrumbItems = [
    {
      text: 'Selección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Validación listas restrictivas',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);

      initialValues.observacion_general_lista_restrictiva =
        data.observacion_general_lista_restrictiva;
      initialValues.estado_lista_restrictiva = data.estado_lista_restrictiva;

      const response = await put(match.params.selection_id, initialValues);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(async () => {
    await Promise.all([getName(), getDataSource(), getSelection()]);
  }, []);

  const getSelection = async () => {
    try {
      const response = await get(match.params.selection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const getDataSource = async () => {
    try {
      const response = await announcement(match.params.announcement_id);
      const responseRol = await announcementRol(
        match.params.announcement_rol_id,
      );
      const responsePerson = await personId(match.params.person_id);

      response.data.cargo = responseRol.data.cargo;
      response.data.estudios = responseRol.data.estudios;
      response.data.tipo_experiencia_laboral =
        responseRol.data.tipo_experiencia_laboral;
      response.data.tiempo_experiencia_laboral =
        responseRol.data.tiempo_experiencia_laboral;
      response.data.nombre_completo = responsePerson.data.nombre_completo;
      response.data.tipo_identificacion_nombre =
        responsePerson.data.tipo_identificacion_nombre;
      response.data.numero_identificacion =
        responsePerson.data.numero_identificacion;

      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const tailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Validación de listas restrictivas</Title>}
        >
          <AnnouncementSelectionValidationRestrictiveForm
            initialValues={dataSource}
            name={name}
          />
          <RestrictiveListTable tercero={match.params.person_id} />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        {initialValues && (
          <Card size={'small'} bordered={false}>
            <Form
              onFinish={onFinish}
              initialValues={initialValues}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="estado_lista_restrictiva"
                label="Selección"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select disabled={initialValues.estado_seleccion === 21}>
                  {optionSelection.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...tailLayout}
                name="observacion_general_lista_restrictiva"
                label="Observaciones generales listas restrictivas"
              >
                <TextArea
                  disabled={initialValues.estado_seleccion === 21}
                  rows={4} />
              </Form.Item>
              <Space>
                {initialValues.estado_seleccion !== 21 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Card>
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementSelectionValidationRestrictive;
