import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Card, Layout, notification, Skeleton, Table, Tabs, Typography} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { getAll as getAllHiringTypes } from '../../api/module/hiring_types';
import { getAll as getAllContractTypes } from '../../api/module/contract_types';

import './styles.scss';
import MESSAGES from "../../enums/Messages";
import { templateProcess } from "../../api/module/template_process";
import SpinLoad from "../../components/SpinLoad";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Plantillas',
  },
];
const { TabPane } = Tabs;


const Templates = () => {
  const [hiringType, setHiringType] = useState(null);
  const [independentDataSource, setIndependentDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [contractTypeDataSource, setContractTypeDataSource] = useState([]);
  const [loadingIndependent, setLoadingIndependent] = useState(false);
  const [loadingDependent, setLoadingDependent] = useState(true);

  const getIndependentTemplatesDataSource = async () => {
    try {
      setLoadingIndependent(true);
      let response = await templateProcess(false);

      setIndependentDataSource(response.data.results);
      setLoadingIndependent(false);
    } catch (exception) {
      setLoadingIndependent(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const getContractTypes = async () => {
    try {
      setLoadingDependent(true);
      let response = await getAllContractTypes();

      setContractTypeDataSource(response.data.results);
      setDataSource(response.data.results.filter(
        (item) =>
          item.tipo_contratacion === 1
      ));
      setLoadingDependent(false);
    } catch (exception) {
      setLoadingDependent(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const setTab = (key, event) => {
    setDataSource(contractTypeDataSource.filter(
      (item) =>
        item.tipo_contratacion === parseInt(key)
    ));
  };

  const getHiringTypes = async () => {
    try {
      let response = await getAllHiringTypes();

      setHiringType(response.data.results);
    } catch (exception) {
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    await getIndependentTemplatesDataSource();
    await getHiringTypes();
    await getContractTypes();
  }, []);

  const columnsIndependentTemplate = [
    {
      title: 'Plantilla',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, record) => (
        record.tipo_plantilla_codigo + ' - ' + record.tipo_plantilla_nombre.toUpperCase()
      ),
    },
    {
      title: 'Persona Natural',
      dataIndex: 'tipo_persona',
      align: 'center',
      width: 200,
      render: (_, record) => {
        if (record.tipo_plantilla_codigo) {
          return (
            <CustomButton type="default" htmlType={"button"} text={
                <Link
                  to={`${INTERNAL_LINKS.TEMPLATE}/0/0/0/1/${record.tipo_plantilla}`}>
                  Ver plantillas
                </Link>
              }
            />
          )
        }
      },
    },
    {
      title: 'Persona Jurídica',
      dataIndex: 'persona_juridica',
      align: 'center',
      width: 200,
      render: (_, record) => {
        if (record.tipo_plantilla_codigo_pj) {
          return (
            <CustomButton type="default" htmlType={"button"} text={
              <Link
                to={`${INTERNAL_LINKS.TEMPLATE}/0/0/0/2/${record.tipo_plantilla}`}>
                Ver plantillas
              </Link>
            }/>
          )
        }
      },
    }
  ];

  const columnsProcess = [
    {
      title: 'Tipo de contrato',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Persona Natural',
      dataIndex: 'tipo_persona',
      render: (_, record) => (
        <CustomButton type="default" htmlType={"button"} text={
          <Link
            to={`${INTERNAL_LINKS.TEMPLATE}/${record.tipo_contratacion}/0/${record.id}/1/0`}>
            Ver plantillas
          </Link>
        } />
      ),
    },
    {
      title: 'Persona Jurídica',
      dataIndex: 'persona_juridica',
      render: (_, record) => (
        <CustomButton type="default" htmlType={"button"} text={
          <Link
            to={`${INTERNAL_LINKS.TEMPLATE}/${record.tipo_contratacion}/0/${record.id}/2/0`}>
            Ver plantillas
          </Link>
        } />
      ),
    }
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>Módulo de Plantillas</Title>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Plantillas que no dependen de un proceso de contratación</Title>}
      >
        <SpinLoad loading={loadingIndependent}>
          <Table
            rowKey="id"
            dataSource={independentDataSource}
            columns={columnsIndependentTemplate}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de Plantillas
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Plantillas que pertenecen de un proceso de contratación</Title>}
      >
        <Paragraph>
          A continuación, encontrará las opciones que le permitirán visualizar las plantillas disponibles.<br/>
          Aquí podrá seleccionar el tipo de plantilla que aparecen en la tabla.
        </Paragraph>
        <Skeleton loading={loadingDependent} active>
          <Tabs defaultActiveKey="1" onChange={setTab} type="card">
            {hiringType && hiringType.map(item =>
              <TabPane tab={item.nombre} key={item.id}>
                <Table
                  rowKey="id"
                  dataSource={dataSource}
                  columns={columnsProcess}
                  loading={loadingDependent}
                  title={() => (
                    <Paragraph className={'margin-5'} strong>
                      Listado de Plantillas {item.nombre}
                    </Paragraph>
                  )}
                />
              </TabPane>)
            }
          </Tabs>
        </Skeleton>
      </Card>
    </Content>
  );
}

export default Templates;
