import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`ct_modifications_contractor/`);
};

const get = async (id) => {
  return await httpClient.get(`ct_modifications_contractor/${id}/`);
};

const sign = async (id, data) => {
  return await httpClient.patch(
    `/ct_modifications_contractor/${id}/sign/`,
    data,
  );
};

export { getAll, get, sign };
