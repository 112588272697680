import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import CostForm from '../../../containers/CostForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import { post, put, get } from '../../../api/module/costs';

const CostsForms = ({ history, match }) => {
  const [costID, setCostID] = useState(parseInt(match.params.id));
  const [title, setTitle] = useState('Creación Centro de Costos');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      if (!costID) {
        setLoading(true);
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(costID, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.COSTS);
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      if (costID) {
        get(costID).then((res) => setData(res.data));
        setTitle('Actualización Centro de Costos');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <>
      {data != null && (
        <CostForm
          title={title}
          onFinish={save}
          initialValues={data}
          loading={loading}
        />
      )}
    </>
  );
};

export default CostsForms;
