import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Select,
  notification,
  Input,
  Space,
  Descriptions,
  Alert,
  Layout,
  Skeleton,
  Card,
} from 'antd';

import CustomButton from '../../../components/CustomButton';
import { put, get } from '../../../api/module/announcement_selection';
import AnnouncementValidationEducationForm from '../../../containers/AnnouncementPreselectionEducationForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { get as getResume } from '../../../api/module/contractor';
import { get as Announcement } from '../../../api/module/announcement';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { filter } from '../../../api/module/resume_training';

import './style.scss';
import { get as getDocumentUrl } from '../../../api/module/document_repository';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const tailLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AnnouncementSelectionValidationEducation = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [education, setEducation] = useState(null);

  const optionPreselection = [
    { id: 24, nombre: 'Pendiente' },
    { id: 25, nombre: 'Cumple' },
    { id: 26, nombre: 'No cumple' },
  ];
  const [name, setName] = useState('');

  const breadcrumbItems = [
    {
      text: 'Selección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Validación educación',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      initialValues.observacion_educacion_seleccion =
        data.observacion_educacion_seleccion;
      initialValues.estado_educacion_seleccion =
        data.estado_educacion_seleccion;
      delete initialValues.tercero_data;
      const response = await put(match.params.selection_id, initialValues);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getName();
    getAnnouncement();
    getEducation();
    getSelection();
  }, []);

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(match.params.announcement_id);
      const responseCargo = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseCargo.data.cargo;
      response.data.estudios = responseCargo.data.estudios;
      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelection = async () => {
    try {
      const response = await get(match.params.selection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getEducation = async () => {
    const response = await filter('?filter[tercero]=' + match.params.person_id);
    setEducation(response.data.results);
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const showDocument = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(documento_nombre);

    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Validación de educación</Title>}
        >
          <AnnouncementValidationEducationForm
            initialValues={dataSource}
            name={name}
            useDescriptions={true}
            descriptionsTitle="Información de convocatoria"
            descriptionsColumns={2}
          />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>{name}</Title>}
        >
          {education != null &&
            education.map((item, index) => (
              <Card
                size={'small'}
                bordered={false}
                title={
                  <Title level={5} className={'title-default'}>
                    Educación No. {index + 1}
                  </Title>
                }
              >
                <Descriptions layout="vertical" column={3} bordered>
                  <Descriptions.Item
                    label={
                      item.tipo_educacion === 1 && item.finalizado
                        ? 'Título obtenido '
                        : 'Nombre del programa'
                    }
                  >
                    {item.titulo_nombre_programa}
                  </Descriptions.Item>
                  <Descriptions.Item label="Estado">
                    {item.finalizado === false ? 'En curso' : 'Finalizado'}
                  </Descriptions.Item>
                  {item.tipo_educacion === 2 &&
                  item.finalizado === false ? null : (
                    <Descriptions.Item
                      label={
                        item.tipo_educacion === 1 && item.finalizado
                          ? 'Fecha de grado '
                          : item.tipo_educacion === 1 && !item.finalizado
                          ? 'Semestre actual'
                          : 'Fecha de terminación'
                      }
                    >
                      {item.tipo_educacion === 1 && !item.finalizado
                        ? item.semestre
                        : item.fecha_terminacion}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Certificado">
                    {item.documento_certificado_nombre ? (
                      <CustomButton
                        loading={loadingDocument}
                        onClick={() => {
                          showDocument(item.documento_certificado_nombre);
                        }}
                        text={'Ver documento'}
                      />
                    ) : (
                      <Text>No se encontró documento</Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tarjeta profesional">
                    {item.documento_tarjeta_profesional_nombre ? (
                      <CustomButton
                        loading={loadingDocument}
                        onClick={() => {
                          showDocument(
                            item.documento_tarjeta_profesional_nombre,
                          );
                        }}
                        text={'Ver documento'}
                      />
                    ) : (
                      <Text>No se encontró documento</Text>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ))}
        </Card>
        {initialValues != null && (
          <Card size={'small'} bordered={false}>
            <Form
              onFinish={onFinish}
              initialValues={initialValues}
              layout="vertical"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="estado_educacion_seleccion"
                label="Estado selección educación"
              >
                <Select disabled={initialValues.estado_seleccion === 21}>
                  {optionPreselection.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...tailLayout}
                name="observacion_educacion_seleccion"
                label="Observación general Educación"
              >
                <TextArea
                  disabled={initialValues.estado_seleccion === 21}
                  rows={4}
                />
              </Form.Item>
              <Space>
                {initialValues.estado_seleccion !== 21 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Card>
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementSelectionValidationEducation;
