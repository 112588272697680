import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Select,
  notification,
  Input,
  Space,
  Descriptions,
  Alert,
  Layout,
  Skeleton,
  Card,
} from 'antd';

import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AnnouncementValidationExperiencieForm from '../../../containers/AnnouncementPreselectionExperiencieForm/index';
import { get as getResume } from '../../../api/module/contractor';
import { get as Announcement } from '../../../api/module/announcement';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { filter } from '../../../api/module/resume_experience';
import { put, get } from '../../../api/module/announcement_selection';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const AnnouncementValidationExperiencie = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [experience, setExperience] = useState(null);
  const [name, setName] = useState('');

  const optionPreselection = [
    { id: 24, nombre: 'Pendiente' },
    { id: 25, nombre: 'Cumple' },
    { id: 26, nombre: 'No cumple' },
  ];

  const breadcrumbItems = [
    {
      text: 'Selección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Validación laboral',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      initialValues.observacion_laboral_seleccion =
        data.observacion_laboral_seleccion;
      initialValues.estado_laboral_seleccion = data.estado_laboral_seleccion;
      const response = await put(match.params.selection_id, initialValues);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(async () => {
    await Promise.all([
      getAnnouncement(),
      getSelection(),
      getExperience(),
      getName(),
    ]);
  }, []);

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(match.params.announcement_id);
      const responseCargo = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseCargo.data.cargo;
      response.data.estudios = responseCargo.data.estudios;
      response.data.tipo_experiencia_laboral =
        responseCargo.data.tipo_experiencia_laboral;
      response.data.tiempo_experiencia_laboral =
        responseCargo.data.tiempo_experiencia_laboral;
      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelection = async () => {
    try {
      const response = await get(match.params.selection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getExperience = async () => {
    const response = await filter('?filter[tercero]=' + match.params.person_id);
    setExperience(response.data.results);
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const showDocument = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );

    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Validacion laboral</Title>}
        >
          <AnnouncementValidationExperiencieForm
            initialValues={dataSource}
            name={name}
            useDescriptions={true}
            descriptionsTitle="Información de convocatoria"
            descriptionsColumns={2}
          />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>{name}</Title>}
        >
          {experience != null && experience.length > 0 ? (
            experience.map((item, index) => (
              <Card
                size={'small'}
                bordered={false}
                title={
                  <Title level={5} className={'title-default'}>
                    Experiencia No. {index + 1}
                  </Title>
                }
              >
                <Descriptions layout="vertical" column={3} bordered>
                  <Descriptions.Item label="Cargo">
                    {item.cargo}
                  </Descriptions.Item>
                  <Descriptions.Item label="Area de experiencia">
                    {item.area_experiencia_nombre}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tipo de cargo">
                    {item.tipo_cargo_nombre}
                  </Descriptions.Item>
                  <Descriptions.Item label="Estado">
                    {item.finalizado === false ? 'Actual' : 'Finalizado'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Fecha de Ingreso">
                    {item.fecha_ingreso}
                  </Descriptions.Item>
                  <Descriptions.Item label="Fecha de terminación">
                    {item.fecha_terminacion}
                  </Descriptions.Item>
                  <Descriptions.Item label="Meses de trabajo">
                    {item.meses.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Certificado">
                    {item.documento_nombre ? (
                      <CustomButton
                        loading={loadingDocument}
                        onClick={() => {
                          showDocument(item.documento_nombre);
                        }}
                        text={'Ver documento'}
                      />
                    ) : (
                      <Text>No se encontró documento</Text>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ))
          ) : (
            <Paragraph type={'danger'}>Sin información</Paragraph>
          )}
        </Card>
        {initialValues != null && (
          <Card size={'small'} bordered={false}>
            <Form
              onFinish={onFinish}
              initialValues={initialValues}
              layout="vertical"
            >
              <Form.Item
                name="estado_laboral_seleccion"
                label="Estado selección experiencia"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select disabled={initialValues.estado_seleccion === 21}>
                  {optionPreselection.map((item) => (
                    <Select.Option value={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="observacion_laboral_seleccion"
                label="Observación general Laboral"
              >
                <TextArea
                  disabled={initialValues.estado_seleccion === 21}
                  rows={4}
                />
              </Form.Item>
              <Space>
                {initialValues.estado_seleccion !== 21 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          </Card>
        )}
      </Skeleton>
    </Content>
  );
};

export default AnnouncementValidationExperiencie;
