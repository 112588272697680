import React, { useEffect, useReducer, useState } from 'react';
import {
  Descriptions,
  Pagination,
  Popconfirm,
  Table,
  Tabs,
  Row,
  Col,
  Typography,
  Space,
  notification,
  Badge,
  Layout,
  Input,
  Modal,
} from 'antd';
import { Link } from 'react-router-dom';

import MESSAGES from '../../enums/Messages';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';

import { filter as filterStatus } from '../../api/module/status_process';
import {
  filter as filterVDC,
  getCounters,
  getProjects,
  getRoles,
  getTerms,
  patch as patchVDC,
} from '../../api/module/term_legal_validation_document';

import './style.scss';
import TextAreaModal from '../../components/TextAreaModal';
import { CustomSelector } from '../../components';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Validación de Documentos Jurídico',
  },
];

const initSelectors = {
  proyecto: undefined,
  cargo: undefined,
  identificador: undefined,
  firstLoad: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'project':
      return { ...initSelectors, proyecto: action.payload, firstLoad: false };
    case 'project_init':
      if (state.firstLoad)
        return {
          ...initSelectors,
          proyecto: action.payload?.value,
          firstLoad: false,
        };
      return state;
    case 'role':
      return {
        ...state,
        cargo: action.payload?.value,
        experiencia_requerida: action.payload?.experiencia_requerida,
        educacion_requerida: action.payload?.educacion_requerida,
        identificador: initSelectors.identificador,
        firstLoad: false,
      };
    case 'role_init':
      if (state.firstLoad)
        return {
          proyecto: action.payload?.proyecto,
          cargo: action.payload?.value,
          experiencia_requerida: action.payload?.experiencia_requerida,
          educacion_requerida: action.payload?.educacion_requerida,
          identificador: initSelectors.identificador,
          firstLoad: false,
        };
      return state;
    case 'term':
      return { ...state, identificador: action.payload, firstLoad: false };
    case 'term_init':
      if (state.firstLoad)
        return {
          proyecto: action.payload.proyecto,
          cargo: action.payload.cargo,
          experiencia_requerida: action.payload?.experiencia_requerida,
          educacion_requerida: action.payload?.educacion_requerida,
          identificador: action.payload.value,
          firstLoad: false,
        };
      return state;
    default:
      return initSelectors;
  }
}

function DocumentValidationContractualHomeSelectors({ match, setQuery }) {
  const [state, dispatch] = useReducer(reducer, initSelectors);

  useEffect(() => {
    if (!match.params.type || Object.values(state).some((value) => !!value)) {
      let q = '';
      if (q === '' && state.identificador) {
        q = `filter[termino]=${state.identificador}`;
      }
      if (q === '' && state.cargo) {
        q = `filter[cargo]=${state.cargo}`;
      }
      if (q === '' && state.proyecto) {
        q = `filter[proyecto]=${state.proyecto}`;
      }
      setQuery({ query: q, param: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="box-description" key="vdc-selectors">
      {(!match.params.type ||
        (match.params.type === 'project' && state.firstLoad) ||
        state.proyecto) && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Proyecto a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) =>
                dispatch({ type: 'project', payload: value })
              }
              initialValue={
                match.params.type && match.params.type === 'project'
                  ? parseInt(match.params.id)
                  : state.proyecto
              }
              onLoad={(values) => {
                if (match.params.type === 'project' && values?.value)
                  dispatch({
                    type: 'project_init',
                    payload: values,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el proyecto a revisar...',
              }}
              endpointFetch={async () => {
                return await getProjects();
              }}
              key={match.url}
            />
          </Col>
        </Row>
      )}
      {(state.proyecto ||
        (match.params.type === 'role' && state.firstLoad)) && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo/rol a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChangeData={(values) =>
                dispatch({ type: 'role', payload: values })
              }
              initialValue={
                match.params.type && match.params.type === 'role'
                  ? parseInt(match.params.id)
                  : state.cargo
              }
              onLoad={(values) => {
                if (match.params.type === 'role' && values?.value)
                  dispatch({
                    type: 'role_init',
                    payload: values,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo / Rol a revisar... ',
              }}
              endpointFetch={async () => {
                return await getRoles({
                  'filter[proyecto]': state.proyecto,
                });
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
      {(state.cargo || (match.params.type === 'term' && state.firstLoad)) && (
        <Row className="box-selectors" align="middle">
          <Col span={6}>
            <Title level={4} style={{ paddingRight: '15px' }}>
              Identificador del término a revisar:
            </Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'term', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'term'
                  ? parseInt(match.params.id)
                  : state.identificador
              }
              onLoad={(values) => {
                if (match.params.type === 'term' && values?.value)
                  dispatch({
                    type: 'term_init',
                    payload: values,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder:
                  'Seleccione el identificador único del termino de referencia... ',
              }}
              endpointFetch={async () => {
                return await getTerms({
                  'filter[cargo]': state.cargo,
                });
              }}
              key={`rs-${state.cargo}`}
            />
          </Col>
        </Row>
      )}
      {/* {state.educacion_requerida && (
        <Descriptions layout="vertical" column={1} bordered>
          <Descriptions.Item label={'Requerimientos de educación / formación'}>
            {state.educacion_requerida}
          </Descriptions.Item>
          <Descriptions.Item label={'Requerimientos de experiencia'}>
            {state.experiencia_requerida}
          </Descriptions.Item>
        </Descriptions>
      )} */}
    </div>
  );
}

const DocumentValidationContractualHome = ({ match }) => {
  const [query, setQuery] = useState({ q: '', param: {} });
  const [counters, setCounters] = useState(undefined);
  const [stateOpt, setStateOpt] = useState([]);

  const [acceptableSubs, setAcceptableSubs] = useState([]);

  const [activeTab, setActiveTab] = useState('review');

  const initModalState = {
    onFinish: async () => {},
    visible: false,
    key: 'no-key',
  };

  const [modalState, setModalState] = useState(initModalState);

  async function changeQuery(
    q = { query: '', param: undefined },
    newTab = undefined,
  ) {
    try {
      const res = await getCounters(`/?${q.query}`);
      if (q !== query) setQuery(q);
      setCounters(res.data.results);
      if (newTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setCounters(undefined);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await filterStatus('/?filter[sigla.icontains]=TDR-VDC-G-');
        const resSub = await filterStatus(
          '/?filter[sigla.icontains]=TDR-VDC-M-',
        );
        const states = res.data.results.map((x) => {
          return { label: x.nombre, value: x.id, sigla: x.sigla };
        });
        const subStates = resSub.data.results.reduce((prev, x) => {
          prev[x.sigla] = x.id;
          return prev;
        }, {});

        setStateOpt(states);

        setAcceptableSubs(subStates);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }

    fetchData();
  }, []);

  const onReturnedStatusSave = async (id, newState, newObservation) => {
    try {
      const res = await patchVDC(id, {
        estado_validacion_general: newState.value,
        observaciones_general: newObservation,
      });
      return res;
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: err?.response?.data?.errors
          ? err.response.data.errors[0]?.detail
          : MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      throw err;
    }
  };

  const onStatusSave = (id, newState) => {
    async function putStatus(id, newState) {
      try {
        await patchVDC(id, { estado_validacion_general: newState.value });
        changeQuery(query, newState.sigla);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    }

    if (newState.sigla === 'TDR-VDC-G-D') {
      setModalState({
        visible: true,
        onFinish: async (text) => {
          await onReturnedStatusSave(id, newState, text);
          setModalState(initModalState);
          changeQuery(query, newState.sigla);
        },
        key: id,
      });
    } else {
      putStatus(id, newState);
    }
  };

  const extraColumnsToCheck = [
    {
      title: 'Validar Documentos',
      key: 'validate_button',
      render: (_, record) => (
        <CustomButton
          block
          type="primary"
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/${record.id}`}
            >
              Validar
            </Link>
          }
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        let thisStates;
        if (record.tercero.tipo_persona_nombre === 'Natural')
          thisStates = [
            record.estado_validacion_basicos,
            record.estado_validacion_formacion,
            record.estado_validacion_experiencia,
            record.estado_validacion_icfes,
            record.estado_validacion_restrictivas,
          ];
        else
          thisStates = [
            record.estado_validacion_basicos,
            record.estado_validacion_restrictivas,
          ];
        return (
          <StateSelector
            thisStates={thisStates}
            subStates={acceptableSubs}
            onSave={(newStatus) => onStatusSave(record.id, newStatus)}
            options={stateOpt}
          />
        );
      },
    },
  ];

  const extraColumnsRejected = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            record.estado_envio ? (
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_EMAIL}/${record.id}`}
              >
                Enviado
              </Link>
            ) : (
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_EMAIL}/${record.id}`}
              >
                Generar Correo
              </Link>
            )
          }
        />
      ),
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/${record.id}`}
            >
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  const extraColumnsReturned = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/${record.id}`}
            >
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Validación de Documentos Jurídico
      </Title>
      <DocumentValidationContractualHomeSelectors
        match={match}
        setQuery={changeQuery}
      />
      {query.query && counters && (
        <Tabs
          style={{ marginTop: 15 }}
          defaultActiveKey={'review'}
          activeKey={activeTab}
          onTabClick={(key, _) => {
            setActiveTab(key);
          }}
          destroyInactiveTabPane={true}
          type="card"
          key={query.query}
        >
          <TabPane
            tab={
              <Paragraph>
                Por revisar
                <Badge showZero count={counters.review} />
              </Paragraph>
            }
            key="review"
          >
            <ValidationTable
              extraColumns={extraColumnsToCheck}
              endpointFetch={async (page, pageSize) => {
                return await filterVDC(
                  `/?${query.query}&filter[estado_validacion_general__isnull]=True`,
                  page,
                  pageSize,
                );
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Aprobados completo
                <Badge showZero count={counters.approved} />
              </Paragraph>
            }
            key="TDR-VDC-G-AC"
          >
            <ValidationTable
              extraColumns={extraColumnsApproved}
              endpointFetch={async (page, pageSize) => {
                return await filterVDC(
                  `/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDC-G-AC`,
                  page,
                  pageSize,
                );
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Aprobados parcial
                <Badge showZero count={counters.partial} />
              </Paragraph>
            }
            key="TDR-VDC-G-AP"
          >
            <ValidationTable
              extraColumns={extraColumnsApproved}
              endpointFetch={async (page, pageSize) => {
                return await filterVDC(
                  `/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDC-G-AP`,
                  page,
                  pageSize,
                );
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Rechazados
                <Badge showZero count={counters.rejected} />
              </Paragraph>
            }
            key="TDR-VDC-G-R"
          >
            <ValidationTable
              extraColumns={extraColumnsRejected}
              endpointFetch={async (page, pageSize) => {
                return await filterVDC(
                  `/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDC-G-R`,
                  page,
                  pageSize,
                );
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <Paragraph>
                Devueltos
                <Badge showZero count={counters.returned} />
              </Paragraph>
            }
            key="TDR-VDC-G-D"
          >
            <ValidationTable
              extraColumns={extraColumnsReturned}
              endpointFetch={async (page, pageSize) => {
                return await filterVDC(
                  `/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDC-G-D`,
                  page,
                  pageSize,
                );
              }}
            />
          </TabPane>
        </Tabs>
      )}
      <TextAreaModal
        modalProps={{
          visible: modalState.visible,
          centered: true,
          title: 'Observación de la devolución',
        }}
        textAreaProps={{
          rows: 4,
        }}
        onOk={modalState.onFinish}
        onCancel={() => {
          setModalState(initModalState);
        }}
        key={modalState.key}
      />
    </Content>
  );
};

const ValidationTable = ({
  extraColumns = [],
  endpointFetch,
  defPageSize = 10,
}) => {
  const columns = [
    {
      title: 'Tipo persona',
      dataIndex: ['tercero', 'tipo_persona_nombre'],
      fixed: true,
    },
    {
      title: 'Nombre completo/Razón social',
      dataIndex: ['tercero', 'nombre_completo'],
      fixed: true,
    },
    {
      title: 'Tipo doc',
      dataIndex: ['tercero', 'tipo_identificacion_nombre'],
    },
    {
      title: 'Nro. Documento',
      dataIndex: ['tercero', 'numero_identificacion'],
    },
    {
      title: 'Teléfono',
      dataIndex: ['tercero', 'numero_celular'],
    },
    {
      title: 'Correo Electrónico',
      dataIndex: ['tercero', 'notificacion_email'],
    },
    {
      title: 'Estado Validación precontractual',
      dataIndex: 'estado_validacion_precontractual',
    },
  ];

  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState(undefined);

  const onPageChange = async (page, pageSize) => {
    try {
      const res = await endpointFetch(page, pageSize);
      setDataSource(res.data.results);
      setPagination(res.data.meta.pagination);
    } catch (error) {
      console.log(error);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    onPageChange(1, defPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinLoad loading={!pagination}>
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={[...columns, ...extraColumns]}
        pagination={false}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de Contratistas
          </Paragraph>
        )}
      />
      {pagination && (
        <Pagination
          defaultCurrent={1}
          current={pagination.page}
          onChange={onPageChange}
          total={pagination.count}
          pageSize={defPageSize}
          style={{ textAlign: 'end', marginTop: '10px' }}
        />
      )}
    </SpinLoad>
  );
};

const StateSelector = ({
  onSave = () => {},
  options = [],
  thisStates = undefined,
  subStates = undefined,
}) => {
  const [state] = useState(() => {
    if (thisStates.every((x) => subStates['TDR-VDC-M-AC'] === x))
      return options.find((x) => x.sigla === 'TDR-VDC-G-AC');

    if (thisStates.some((x) => x === subStates['TDR-VDC-M-R']))
      return options.find((x) => x.sigla === 'TDR-VDC-G-R');

    if (thisStates.some((x) => x === subStates['TDR-VDC-M-AI']))
      return options.find((x) => x.sigla === 'TDR-VDC-G-D');

    if (!thisStates[thisStates.length - 1]) return undefined;

    if (thisStates.some((x) => !x || x === subStates['TDR-VDC-M-AP']))
      return options.find((x) => x.sigla === 'TDR-VDC-G-AP');
  });

  function onSelfSave() {
    if (thisStates.some((x) => !x)) {
      Modal.confirm({
        title: 'Estado inválido',
        content: 'Hay módulos sin estado. ¿Desea continuar?',
        okText: 'Si',
        cancelText: 'No',
        onOk: () => onSave(state),
      });
      return;
    }
    onSave(state);
  }

  return (
    <Space direction="horizontal">
      <Input disabled value={state?.label || 'Por revisar'} />
      <Popconfirm
        onConfirm={() => {
          onSelfSave(state);
        }}
        title={MESSAGES.MSG_SAVE_STATE}
      >
        <CustomButton type="default" text="Guardar" disabled={!state} />
      </Popconfirm>
    </Space>
  );
};

export default DocumentValidationContractualHome;
