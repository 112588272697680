import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Space,
  Typography,
  Row,
  Col,
  Modal,
  Divider,
  Form,
  DatePicker,
  notification,
  Input,
  Descriptions,
  InputNumber,
} from 'antd';
import MESSAGES from '../../../enums/Messages';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import AssignmentStates, { ErrorsContent } from './AssignmentStates';
import { IsRole } from '../../../utils/helpers/helpers';
import SpinLoad from '../../../components/SpinLoad';
import { sendSign } from '../../../api/module/assign_postcontractual';
import { get, put } from '../../../api/module/postcontractual';
import BreadcrumbContainer from '../../../containers/Breadcrumb';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';
const dateFormatText = 'DD MMMM YYYY';

const breadcrumbItems = [
  {
    text: 'Modulo Poscontractual',
    to: `${INTERNAL_LINKS.POSTCONTRACTUAL}`,
  },
  {
    text: 'Formulario Poscontractual',
  },
];

const formatDate = (dateVal, strFormat) => {
  var dateFinal = dateVal ? dateVal : '';
  if (dateFinal !== '') {
    dateFinal = moment(dateFinal).format(strFormat);
  }

  return dateFinal;
};

const OrdinaryFields = ({ initialValues }) => {
  const disabledDate = (c) => {
    return (
      c &&
      (c.isAfter(initialValues.fecha_fin.clone().add(120, 'days'), 'day') ||
        c.isBefore(initialValues.fecha_fin, 'day'))
    );
  };
  return (
    <>
      <Form.Item
        name="clausula_pago"
        label="Por favor, digite en letras el número de la cláusula del contrato de Forma de pago"
        rules={[{ required: true }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ lg: 16, md: 24 }}
      >
        <Input />
      </Form.Item>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="valor_total"
            label="Valor total del contrato liquidado ejecutado"
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          {initialValues.currency}
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item name="fecha_inicio" label="Fecha de inicio del contrato">
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_inicio'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_fin"
            label="Fecha de finalización del contrato"
          >
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_fin'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_suscripcion"
            label="Fecha de suscripción del acta"
            rules={[
              {
                required: true,
                type: 'object',
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disabledDate}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>
                {formatDate(getFieldValue('fecha_suscripcion'), dateFormatText)}
              </p>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const AnticipatedFields = ({ initialValues }) => {
  const disabledDate = (c) => {
    return (
      c &&
      (c.isAfter(initialValues.fecha_fin, 'day') ||
        c.isBefore(initialValues.fecha_inicio, 'day'))
    );
  };
  return (
    <>
      <Divider orientation="left">
        <b>BALANCE FINANCIERO DEL CONTRATO</b>
      </Divider>
      <Form.Item
        name="clausula_valor"
        label="Por favor, digite en letras el número de la cláusula del contrato de Valor"
        rules={[{ required: true }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ lg: 16, md: 24 }}
      >
        <Input />
      </Form.Item>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="valor_total"
            label="Valor total del contrato (incluyendo modificaciones)"
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          {initialValues.currency}
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="valor_pagado"
            label="Valor ejecutado y pagado"
            rules={[
              { required: true },
              { type: 'number', min: 0, message: 'Ingrese un valor positivo' },
              {
                type: 'number',
                max: initialValues.valor_total,
                message:
                  'El valor digitado debe ser menor al Valor total del contrato.',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          {initialValues.currency}
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="valor_no_pagado"
            label="Valor ejecutado y por pagar"
            rules={[
              { required: true },
              { type: 'number', min: 0, message: 'Ingrese un valor positivo' },
              {
                type: 'number',
                max: initialValues.valor_total,
                message:
                  'El valor digitado debe ser menor al Valor total del contrato.',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          {initialValues.currency}
        </Col>
      </Row>
      <Form.Item noStyle dependencies={['valor_no_pagado', 'valor_pagado']}>
        {({ getFieldValue, setFieldsValue }) => {
          setFieldsValue({
            valor_no_ejecutado:
              initialValues.valor_total -
              (getFieldValue('valor_no_pagado') || 0) -
              (getFieldValue('valor_pagado') || 0),
          });
          return (
            <Row align="top" gutter={25}>
              <Col lg={16} md={24}>
                <Form.Item
                  name="valor_no_ejecutado"
                  label="Valor no ejecutado"
                  rules={[
                    { required: true },
                    {
                      type: 'number',
                      min: 0,
                      message: 'El valor debe ser positivo',
                    },
                    {
                      type: 'number',
                      max: initialValues.valor_total,
                      message:
                        'El valor digitado debe ser menor al Valor total del contrato.',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={24}>
                {initialValues.currency}
              </Col>
            </Row>
          );
        }}
      </Form.Item>
      <Divider orientation="left">
        <b>DURACIÓN</b>
      </Divider>
      <Form.Item
        name="clausula_duracion"
        label="Por favor, digite en letras el número de la cláusula del contrato de Duración"
        rules={[{ required: true }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ lg: 16, md: 24 }}
      >
        <Input />
      </Form.Item>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item name="fecha_inicio" label="Fecha de inicio del contrato">
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_inicio'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_fin"
            label="Fecha de finalización del contrato"
          >
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_fin'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_suscripcion"
            label="Fecha de terminación anticipada"
            rules={[
              {
                required: true,
                type: 'object',
              },
            ]}
            tooltip={{
              placement: 'top',
              title:
                'Seleccione la fecha hasta la cual trabajó el contratista o proveedor',
            }}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disabledDate}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>
                {formatDate(getFieldValue('fecha_suscripcion'), dateFormatText)}
              </p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Justifique la terminación anticipada del contratista / proveedor"
        name="justificacion_proceso"
        rules={[
          {
            required: true,
            message: 'Ingresa la Justificación',
          },
        ]}
        labelCol={{ span: 24 }}
      >
        <Input.TextArea allowClear />
      </Form.Item>
    </>
  );
};

const UnilateralFields = ({ initialValues }) => {
  const disabledDate = (c) => {
    return (
      c &&
      (c.isAfter(initialValues.fecha_fin.clone().add(120, 'days'), 'day') ||
        c.isBefore(initialValues.fecha_inicio, 'day'))
    );
  };
  return (
    <>
      <Form.Item
        name="clausula_pago"
        label="Por favor, digite en letras el número de la cláusula del contrato de Forma de pago"
        rules={[{ required: true }]}
        labelCol={{ span: 24 }}
        wrapperCol={{ lg: 16, md: 24 }}
      >
        <Input />
      </Form.Item>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="valor_total"
            label="Valor total del contrato liquidado ejecutado"
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          {initialValues.currency}
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item name="fecha_inicio" label="Fecha de inicio del contrato">
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_inicio'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_fin"
            label="Fecha de finalización del contrato"
          >
            <DatePicker
              format={dateFormat}
              disabled
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>{formatDate(getFieldValue('fecha_fin'), dateFormatText)}</p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row align="top" gutter={25}>
        <Col lg={16} md={24}>
          <Form.Item
            name="fecha_suscripcion"
            label="Fecha de suscripción del acta"
            rules={[
              {
                required: true,
                type: 'object',
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disabledDate}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <p>
                {formatDate(getFieldValue('fecha_suscripcion'), dateFormatText)}
              </p>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Justifique la terminación unilateral del contratista / proveedor"
        name="justificacion_proceso"
        rules={[
          {
            required: true,
            message: 'Ingresa la Justificación',
          },
        ]}
        labelCol={{ span: 24 }}
      >
        <Input.TextArea allowClear />
      </Form.Item>
    </>
  );
};

const PostContractualForm = ({ match, history }) => {
  const [legal] = useState(IsRole(3));
  const [form] = Form.useForm();

  const [hasChanges, setHasChanges] = useState(false);

  const [initialValues, setInitialValues] = useState();

  const [loading, setLoading] = useState(false);

  const [toSign, setToSign] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setInitialValues();
      try {
        const { data } = await get(match.params.id);
        setInitialValues({
          ...data,
          fecha_suscripcion: data?.fecha_suscripcion
            ? moment(data.fecha_suscripcion)
            : undefined,
          fecha_inicio: moment(data.fecha_inicio),
          fecha_fin: moment(data.fecha_fin),
        });
      } catch (error) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.id]);

  const showConfirmation = (doRedirect) => {
    setHasChanges(false);
    Modal.success({
      title: MESSAGES.SUCCESS_MSG,
      content: (
        <>
          <Divider />
          <p>Se han registrado los cambios correctamente.</p>
          <p>
            Esta información siempre estará disponible en la sección de Módulo
            Poscontractual.
          </p>

          <p>Gracias</p>
        </>
      ),
      onOk: () => {
        if (doRedirect)
          history.push(
            `${INTERNAL_LINKS.POSTCONTRACTUAL}/0/${initialValues.proyecto_id}`,
          );
      },
    });
  };

  const onSave = () => {
    form.submit();
  };

  const onSaveSend = () => {
    setToSign(true);
    form.submit();
  };

  const onFinishForm = async (values) => {
    if (toSign) onFinishSend(values);
    else
      try {
        setLoading(true);
        await put(initialValues.id, {
          ...values,
          fecha_suscripcion: `${formatDate(
            values.fecha_suscripcion,
            'YYYY-MM-DD',
          )} 00:00`,
        });
        showConfirmation();
      } catch (error) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      } finally {
        setLoading(false);
      }
  };

  const onFinishSend = async (values) => {
    try {
      setLoading(true);
      const response = await sendSign(initialValues.id, {
        ...values,
        fecha_suscripcion: `${formatDate(
          values.fecha_suscripcion,
          'YYYY-MM-DD',
        )} 00:00`,
      });
      if (response.data?.errors) {
        Modal.error({
          title: 'Validación de liquidación',
          content: (
            <>
              La liquidación requiere ajustes:
              <ErrorsContent errors={response.data?.errors} />
            </>
          ),
        });
        setToSign(false);
      } else showConfirmation(true);
    } catch (error) {
      setToSign(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO POSCONTRACTUAL
      </Title>
      {initialValues ? (
        <>
          <Title level={3}>Proyecto: {initialValues.project_name}</Title>
          <Descriptions
            layout="horizontal"
            column={2}
            bordered
            labelStyle={{ maxWidth: '5rem' }}
          >
            <Descriptions.Item label="Contratista / Proveedor" span={2}>
              <Row align="middle">
                <Col flex="auto">{initialValues.contratista_nombre}</Col>
                <Col flex="0 0">
                  <CustomButton
                    htmlType="button"
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${initialValues.contrato}/${initialValues.proyecto_id}/${initialValues.cargo_id}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver históricos contractuales
                      </Link>
                    }
                  />
                </Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/Rol" span={2}>
              {initialValues.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="No. de contrato">
              {initialValues.contrato_num}
            </Descriptions.Item>
            <Descriptions.Item label="Cantidad de modificaciones realizadas">
              {initialValues.mod_num ?? 0}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Title level={4}>
            Terminación y Liquidación{' '}
            {initialValues.tipo_terminacion === 1
              ? 'Ordinaria'
              : initialValues.tipo_terminacion === 2
              ? 'Anticipada'
              : 'Unilateral'}
          </Title>
          <Form
            layout="horizontal"
            initialValues={initialValues}
            form={form}
            onFinish={onFinishForm}
            onValuesChange={() => setHasChanges(true)}
          >
            {initialValues.tipo_terminacion === 1 ? (
              <OrdinaryFields initialValues={initialValues} />
            ) : initialValues.tipo_terminacion === 2 ? (
              <AnticipatedFields initialValues={initialValues} />
            ) : (
              <UnilateralFields initialValues={initialValues} />
            )}
          </Form>
          <Row align="middle">
            <Col flex="auto" span={legal ? 12 : 24} align="center">
              <Space>
                <CustomButton
                  onClick={onSave}
                  disabled={loading}
                  loading={loading && !toSign}
                  htmlType="button"
                  text="GUARDAR TERMINACIÓN"
                />
              </Space>
            </Col>
            {legal && (
              <Col flex="auto" span={12} align="center">
                <Space>
                  <CustomButton
                    onClick={onSaveSend}
                    disabled={loading}
                    loading={loading && toSign}
                    type="primary"
                    htmlType="button"
                    text="GUARDAR Y ENVIAR PARA FIRMA"
                  />
                </Space>
              </Col>
            )}
          </Row>
          <Divider />
          {!hasChanges && (
            <Row align="middle">
              <Col flex="auto" span={24} align="left">
                <AssignmentStates postcontractualID={initialValues.id} />
              </Col>
            </Row>
          )}
          <CustomButton
            htmlType="button"
            className={'back-button'}
            type="default"
            text={
              <Link
                to={`${INTERNAL_LINKS.POSTCONTRACTUAL}/0/${initialValues.proyecto_id}`}
              >
                Volver
              </Link>
            }
          />
        </>
      ) : (
        <SpinLoad loading={true} />
      )}
    </>
  );
};

export default PostContractualForm;
