import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/clients/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/clients/?page[size]=1000`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/clients/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/clients/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/clients/${id}`,
  );
};

const ajaxTable = async (
  _query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  return await httpClient.get(
    `clients/?page[number]=${page}&page[size]=${pageSize}&sort=${sortBy}${searchBy}`,
  );
};

export { post, getAll, get, put, remove, ajaxTable };
