import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  notification,
  Divider,
  Form,
  Input,
  InputNumber,
  Table,
  Space,
  Popconfirm,
} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import { get as getTerm } from '../../api/module/terms';
import { get as getProject } from '../../api/module/projects';
import {
  post,
  get,
  put,
  getAllByTerm,
  remove,
} from '../../api/module/term_differentiated_values';

const { Title, Paragraph } = Typography;

const TermDifferentiatedValues = ({ match }) => {
  const [form] = Form.useForm();
  const [project, setProject] = useState([]);
  const [term, setTerm] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [isUpdate, setIsUpdate] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'codigo',
      key: 'codigo',
    },
    {
      title: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
    },
    {
      title: 'Valor asociado',
      dataIndex: 'valor',
      key: 'valor',
      render: (text, record) =>
        `$ ${record.valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => {
        return (
          <Space>
            <CustomButton
              text="Editar"
              type="default"
              onClick={() => {
                setIsUpdate(record.id);
                getItem(record.id);
              }}
            />
            <Popconfirm
              placement="topLeft"
              title={'¿Está seguro(a) que desea eliminar este elemento?'}
              onConfirm={() => {
                removeItem(record.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <CustomButton danger="true" text="Eliminar" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${term && term.id}/${term && term.proyecto}/${
        term && term.tipo_contrato
      }`,
    },
    {
      text: 'Valores diferenciados',
    },
  ];

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setPageLoading(true);
    await Promise.all([getTermData(), getDataSource()]);
    setPageLoading(false);
  };

  const getProjectData = async (id) => {
    const response = await getProject(id);
    setProject(response.data);
  };

  const getTermData = async () => {
    const response = await getTerm(match.params.term_id);
    setTerm(response.data);
    getProjectData(response.data.proyecto);
  };

  const getDataSource = async () => {
    try {
      const response = await getAllByTerm(match.params.term_id);
      setDataSource(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const getItem = async (id) => {
    setPageLoading(true);
    try {
      const response = await get(id);
      form.setFieldsValue(response.data);
    } catch (err) {
      console.log(err);
    }
    setPageLoading(false);
  };

  const save = async (data) => {
    setPageLoading(true);
    let response;
    try {
      if (isUpdate === 0) {
        data.codigo = dataSource.length + 1;
        data['termino'] = match.params.term_id;
        response = await post(data);
      } else {
        response = await put(isUpdate, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        form.resetFields();
        setTimeout(() => {
          getDataSource();
        }, MESSAGES.TIME_RELOAD);
        setIsUpdate(0);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
    setPageLoading(false);
  };

  const removeItem = async (id) => {
    setPageLoading(true);
    try {
      const response = await remove(id);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });

        setTimeout(() => {
          getDataSource();
        }, MESSAGES.TIME_RELOAD);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
    setPageLoading(false);
  };

  return (
    <>
      {term && <BreadcrumbContainer items={breadcrumbItems} />}
      <div className="box-title">
        <Title level={2}>TÉRMINOS DE REFERENCIA</Title>
        <Title level={3}>PROYECTO: {project && project.nombre}</Title>
      </div>
      <div className="box-description">
        <Paragraph>
          Los siguientes términos de referencia complementan el texto del
          contrato firmado entre las partes y están subordinados a dichos
          contratos.
        </Paragraph>
      </div>
      <Divider />
      <Title level={4}>C. ESPECIFICACIONES DEL CONTRATO</Title>

      <Form
        layout="vertical"
        form={form}
        onFinish={(data) => {
          save(data);
        }}
      >
        <Form.Item
          label="Digite el concepto"
          name="concepto"
          rules={[{ required: true }]}
        >
          <Input.TextArea></Input.TextArea>
        </Form.Item>
        <Form.Item
          label="Valor asociado"
          name="valor"
          rules={[{ required: true }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          ></InputNumber>
        </Form.Item>
        <Form.Item>
          <Space>
            <CustomButton loading={pageLoading} text="Agregar / Guardar" />
            {isUpdate !== 0 && (
              <CustomButton
                type="default"
                text="Cancelar edición"
                onClick={() => {
                  setIsUpdate(0);
                  form.resetFields();
                }}
              />
            )}
          </Space>
        </Form.Item>
      </Form>

      <Table
        loading={pageLoading}
        columns={columns}
        dataSource={dataSource}
      ></Table>
      <br></br>
      <CustomButton
        danger="true"
        text={
          <Link
            to={`${INTERNAL_LINKS.TERM}/${term && term.id}/${
              term && term.proyecto
            }/${term && term.tipo_contrato}/?module=D`}
          >
            Cerrar
          </Link>
        }
      />
    </>
  );
};

export default TermDifferentiatedValues;
