import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Table,
  notification,
  Card,
  Space,
  Layout,
} from 'antd';

//Component imports
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton/';

import { post } from '../../api/module/contractor_search';

import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import SpinLoad from '../../components/SpinLoad';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const RestrictiveListSearch = ({}) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const breadcrumbItems = [
    {
      text: 'Módulo de listas restrictivas',
    },
  ];
  const columns = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_identificacion',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'numero_identificacion',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
    {
      title: 'Acciones',
      dataIndex: 'tercero',
      key: 'tercero',
      width: 125,
      render: (text, record) => (
        <CustomButton
          block
          type="default"
          className={'ant-btn-info'}
          htmlType="button"
          text={
            <a
              href={`${INTERNAL_LINKS.RESTRICTIVE_LIST}/${text}`}
              target="_blank"
            >
              Historial
            </a>
          }
        />
      ),
    },
  ];

  const onFinish = async (data) => {
    try {
      if (data.documento === '') {
        data.documento = undefined;
      }
      if (data.nombre === '') {
        data.nombre = undefined;
      }
      if (data.email === '') {
        data.email = undefined;
      }

      data.tipo_persona = 0;
      data.termino = 0;
      setLoading(true);
      const response = await post(data);
      setLoading(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de listas restrictivas
      </Title>
      <Form
        form={form}
        name="location_form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={5}>Formulario de búsqueda de contratistas</Title>
          }
        >
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item label="Nombre" name="nombre">
                <Input allowClear placeholder="Búsqueda por nombre" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Número de documento" name="documento">
                <Input
                  type="number"
                  allowClear
                  placeholder="Búsqueda por documento"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Correo electrónico" name="correo">
                <Input allowClear placeholder="Correo electrónico" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Space>
                <CustomButton loading={loading} text="Buscar" />
                <CustomButton
                  danger
                  htmlType="button"
                  type="default"
                  text="Borrar filtros"
                  onClick={() => {
                    form.resetFields();
                    setDataSource([]);
                  }}
                />
              </Space>
            </Col>
          </Row>
          <SpinLoad loading={loading}>
            <Table
              dataSource={dataSource}
              columns={columns}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Contratistas encontrados
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      </Form>
    </Content>
  );
};

RestrictiveListSearch.propTypes = {
  dataSource: PropTypes.array,
  ids: PropTypes.object,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  selectedKnowledge: PropTypes.func,
};

RestrictiveListSearch.defaultProps = {
  dataSource: [],
  ids: {},
  loading: false,
  rowSelection: {},
  selectedKnowledge: () => {},
};

export default RestrictiveListSearch;
