import React, { useState, useEffect } from 'react';
import {
  Typography,
  Form,
  Select,
  notification,
  Input,
  Row,
  Col,
  Space,
  Alert,
  Card,
  Skeleton,
  Layout,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import AnnouncementSelectionValidationTestForm from '../../../containers/AnnouncementSelectionTestsForm/index';

import { get as Announcement } from '../../../api/module/announcement';
import { get as AnnouncementRol } from '../../../api/module/announcement_rol';
import { get as getResume } from '../../../api/module/contractor';
import { put, get } from '../../../api/module/announcement_selection';

const { Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const AnnouncementSelectionValidationTests = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [resumeData, setResumeData] = useState('');
  const [name, setName] = useState('');

  const optionPreselection = [
    { id: 24, nombre: 'Pendiente' },
    { id: 25, nombre: 'Cumple' },
    { id: 26, nombre: 'No cumple' },
  ];

  const optionTest = [
    { id: 1, nombre: 'Aprueba' },
    { id: 2, nombre: 'No aprueba' },
  ];

  const breadcrumbItems = [
    {
      text: 'Selección',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`,
    },
    {
      text: 'Validación',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    },
    {
      text: 'Validación estado pruebas',
    },
  ];

  const back = () => {
    history.push(
      `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
    );
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      initialValues.resultado_prueba_especifica =
        data.resultado_prueba_especifica;
      initialValues.observacion_prueba_especifica =
        data.observacion_prueba_especifica;
      initialValues.resultado_entrevista = data.resultado_entrevista;
      initialValues.observacion_entrevista = data.observacion_entrevista;
      initialValues.resultado_prueba_sicotecnica =
        data.resultado_prueba_sicotecnica;
      initialValues.observacion_prueba_sicotecnica =
        data.observacion_prueba_sicotecnica;
      initialValues.resultado_final = data.resultado_final;

      const response = await put(match.params.selection_id, initialValues);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(
            `${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/${match.params.announcement_id}/${match.params.announcement_rol_id}`,
          );
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const getResumeData = async () => {
    const response = await getResume(match.params.person_id);
    setResumeData(response.data);
  };

  useEffect(async () => {
    await Promise.all([
      getName(),
      getAnnouncement(),
      getSelection(),
      getResumeData(),
    ]);
  }, []);

  const getAnnouncement = async () => {
    try {
      const response = await Announcement(match.params.announcement_id);
      const responseRol = await AnnouncementRol(
        match.params.announcement_rol_id,
      );
      response.data.cargo = responseRol.data.cargo;
      response.data.estudios = responseRol.data.estudios;
      response.data.tipo_experiencia_laboral =
        responseRol.data.tipo_experiencia_laboral;
      response.data.tiempo_experiencia_laboral =
        responseRol.data.tiempo_experiencia_laboral;
      response.data.prueba_especifica = responseRol.data.prueba_especifica;
      response.data.prueba_psicotecnica = responseRol.data.prueba_psicotecnica;
      response.data.entrevista = responseRol.data.entrevista;

      setDataSource(response.data);
      setLoadingForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelection = async () => {
    try {
      const response = await get(match.params.selection_id);
      setInitialValues(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getName = async () => {
    const response = await getResume(match.params.person_id);
    setName(
      `${response.data.numero_identificacion} - ${response.data.nombre_completo}`,
    );
  };

  const tailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Selección
      </Title>
      <Skeleton loading={loadingForm} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Validación de estado de otras pruebas</Title>}
        >
          <AnnouncementSelectionValidationTestForm
            initialValues={dataSource}
            name={resumeData}
            useDescriptions={true}
            descriptionsTitle="Información de convocatoria"
            descriptionsColumns={2}
          />
        </Card>
        {initialValues != null && initialValues.cargo_aplicado !== null && (
          <Alert
            style={{ marginBottom: 10 }}
            message="Atención"
            description={`El usuario también se encuentra aplicando al cargo ${initialValues.cargo_aplicado} de esta convocatoria.
            ¿Desea continuar con el proceso para este cargo?. En caso que no continúe, por favor rechazarlo.`}
            type="error"
            showIcon
          />
        )}
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>{name}</Title>}
        >
          {initialValues != null && dataSource != null && (
            <Form
              onFinish={onFinish}
              initialValues={
                dataSource.prueba_especifica !== 1 &&
                dataSource.prueba_psicotecnica !== 1 &&
                initialValues.resultado_final == null
                  ? { ...initialValues, resultado_final: 25 }
                  : initialValues
              }
            >
              {dataSource && dataSource.prueba_especifica ? (
                <Content>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item>
                        <Checkbox checked={dataSource.prueba_especifica}>
                          Prueba específica
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Resultado Prueba específica"
                        name="resultado_prueba_especifica"
                      >
                        <Select placeholder="Aprueba - No Aprueba">
                          {optionTest.map((item) => (
                            <Select.Option value={item.id}>
                              {item.nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    {...tailLayout}
                    name="observacion_prueba_especifica"
                    label="Observación"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Content>
              ) : null}
              {dataSource && dataSource.entrevista ? (
                <Content>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item>
                        <Checkbox checked={dataSource.entrevista}>
                          Entrevista
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Resultado Entrevista"
                        name="resultado_entrevista"
                      >
                        <Select placeholder="Aprueba - No Aprueba">
                          {optionTest.map((item) => (
                            <Select.Option value={item.id}>
                              {item.nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    {...tailLayout}
                    name="observacion_entrevista"
                    label="Observación"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Content>
              ) : null}
              {dataSource && dataSource.prueba_psicotecnica ? (
                <Content>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item>
                        <Checkbox checked={dataSource.prueba_psicotecnica}>
                          Prueba psicotécnica
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Resultado Prueba psicotécnica"
                        name="resultado_prueba_sicotecnica"
                      >
                        <Select placeholder="Aprueba - No Aprueba">
                          {optionTest.map((item) => (
                            <Select.Option value={item.id}>
                              {item.nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    {...tailLayout}
                    name="observacion_prueba_sicotecnica"
                    label="Observación"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Content>
              ) : null}
              {dataSource.prueba_especifica === null &&
                dataSource.entrevista === null &&
                dataSource.prueba_psicotecnica === null &&
                'No hay requerimientos técnicos para registrar'}
              <Content style={{ marginTop: 20 }}>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="resultado_final"
                      label="Resultado final"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select disabled={initialValues.estado_seleccion === 21}>
                        {optionPreselection.map((item) => (
                          <Select.Option
                            key={`status-key-${item.id}`}
                            value={item.id}
                          >
                            {item.nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Content>
              <Space>
                {initialValues.estado_seleccion !== 21 && (
                  <CustomButton loading={loading} text="Guardar" />
                )}
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form>
          )}
        </Card>
      </Skeleton>
    </Content>
  );
};

export default AnnouncementSelectionValidationTests;
