import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/`,
  );
};

const getAllByAnnouncement = async (announcement_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/?filter[convocatoria]=${announcement_id}`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}/`,
  );
};

const getAdjustInfo = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}/get_adjust_info/`,
  );
};

const getCounters = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}/counters/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = '',
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement_rol/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement_rol` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

const filter = async (query) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/announcement_rol${query}`,
  );
};

const notify = async (id, transactionType) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/announcement_rol/${id}/notify/`,
    { tipo: transactionType },
  );
};

export {
  post,
  getAll,
  getAllByAnnouncement,
  get,
  getAdjustInfo,
  getCounters,
  put,
  remove,
  filter,
  notify,
  ajaxTable,
};
