import httpClient from '../httpClient';

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  _sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `postcontractual_contractor/export/?${query}${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `postcontractual_contractor/?${query}&page[number]=${page}&page[size]=${pageSize}${searchBy}`,
    );
};

const get = async (id) => {
  return await httpClient.get(`postcontractual_contractor/${id}/`);
};

const sign = async (id, data) => {
  return await httpClient.patch(
    `/postcontractual_contractor/${id}/sign/`,
    data,
  );
};

const approvedTable = async (
  query = '',
  _page = 1,
  _pageSize = 10,
  _sortBy = 'id',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  return await httpClient.get(
    `postcontractual_contractor/${query}/approved_list/`,
  );
};

export { ajaxTable, get, sign, approvedTable };
