import React, { useState, useEffect, memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Form,
  Select,
  Input,
  Button,
  Radio,
  Divider,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Space,
  Modal,
  Typography,
  Card,
  Alert,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import CountryStateCity from '../../components/CountryStateCity';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { getAll as getAreas } from '../../api/module/areas';
import { getAll as getCurrencyTypes } from '../../api/module/currency_types';
import { getAll as getPersonTypes } from '../../api/module/person_types';
import { getAll as getInspectors } from '../../api/module/inspectors';
import { getByCostCenter } from '../../api/module/sub_cost_centers';
import { getAllByTerm as getDifferentiatedValuesByTerm } from '../../api/module/term_differentiated_values';
import { getAllByTerm as getPoliciesByTerm } from '../../api/module/term_policies';
import { getAllByTerm as getPaymentsByTerm } from '../../api/module/term_payment';
import { getRoleByProject } from '../../api/module/project_positions';
import { filter as getAllDocumentTypes } from '../../api/module/template_term';
import { getChildrenCounter, getResumeCounter } from '../../api/module/terms';
import { getAllActive as getAllEmployees } from '../../api/module/employees';
import { interventors as getInterventorsByProject } from '../../api/module/term_resume_contract';

import './style.scss';
import { CustomButton } from '../../components';

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const PolizaInput = ({ value, onChange, disabled, rp }) => {
  return (
    <Radio.Group
      disabled={disabled}
      value={value}
      onChange={(e) => {
        if (e.target.value === false && rp) {
          Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content:
              'Se requiere póliza para este TDR. ¿Esta seguro de no solicitar la póliza?',
            okText: 'Si',
            cancelText: 'No',
            onOk: () => onChange(e),
          });
        } else {
          onChange(e);
        }
      }}
    >
      <Radio value={true}>Si</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
  );
};

const TermsForm = memo(
  ({
    term,
    project,
    project_id,
    contract_id,
    loading,
    onSave,
    history,
    onReview,
    show,
    disabled,
    isLegal,
    currentModule = 'A',
    setCurrentModule,
  }) => {
    const [areasOptions, setAreasOptions] = useState([]);
    const [costOptions, setCostsOptions] = useState([]);
    const [currencyTypesOptions, setCurrencyTypesOptions] = useState([]);
    const [personTypesOptions, setPersonTypesOptions] = useState([]);
    const [inspectorOptions, setInspectorOption] = useState([]);
    const [termDifferentiatedValues, setTermDifferentiatedValues] = useState(
      [],
    );
    const [termPolicies, setTermPolicies] = useState([]);
    const [termPayments, setTermPayments] = useState([]);
    const [showEducation, setShowEducation] = useState(true);
    const [showPolicies, setShowPolicies] = useState(true);
    const [showAddPeople, setShowAddPeople] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [pendingSave, setPendingSave] = useState(false);
    const [form] = Form.useForm();
    const [review, setReview] = useState(false);
    const [positionsRole, setPositionsRole] = useState([]);
    const [showComment, setShowComment] = useState(null);
    const [initialDate, setInitialDate] = useState(null);
    const [finishDate, setFinishDate] = useState(null);
    const [initialMaxDate, setInitialMaxDate] = useState(null);
    const [finishMaxDate, setFinishMaxDate] = useState(null);
    const [valueContactFixed, setValueContactFixed] = useState(null);
    const [paymentForm, setPaymentForm] = useState(null);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [termResumeCounter, setTermResumeCounter] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [externalInterventors, setExternalInterventors] = useState([]);

    const [childrenCounters, setChildrenCounters] = useState({});

    const moduleA = useRef();
    const moduleB = useRef();
    const moduleC = useRef();
    const moduleD = useRef();
    const moduleE = useRef();
    const moduleF = useRef();
    const moduleG = useRef();

    const setModule = () => {
      if (currentModule === 'A') moduleA.current.scrollIntoView();
      if (currentModule === 'B') moduleB.current.scrollIntoView();
      if (currentModule === 'C') moduleC.current.scrollIntoView();
      if (currentModule === 'D') moduleD.current.scrollIntoView();
      if (currentModule === 'E') moduleE.current.scrollIntoView();
      if (currentModule === 'F') moduleF.current.scrollIntoView();
      if (currentModule === 'G') moduleG.current.scrollIntoView();
    };

    useEffect(() => {
      loadFormData();

      if (term !== null) setModule();

      if (term.cargo && term.cargo != NaN) {
        if (
          term.requerimiento_experiencia == null &&
          term.requerimiento_formacion == null
        ) {
          onChangePosition(term.cargo);
        }
      }
      if (term.tipo_persona != null) {
        if (term.tipo_persona === 1) {
          setShowEducation(false);
        }
        setShowAddPeople(false);
      }
      if (term.tipo_persona != null) {
        setShowAddPeople(false);
      }
      if (term.tiene_politicas != null) {
        if (term.tiene_politicas === 1) {
          setShowPolicies(false);
        }
      }
      if (term.fecha_inicio != null) {
        setInitialDate(term.fecha_inicio);
      }
      if (term.fecha_fin != null) {
        setFinishDate(term.fecha_fin);
      }
      if (term.fecha_maxima_inicio != null) {
        setInitialMaxDate(term.fecha_maxima_inicio);
      }
      if (term.fecha_maxima_fin != null) {
        setFinishMaxDate(term.fecha_maxima_fin);
      }
      if (term.fechas_fijas != null) {
        setShowComment(term.fechas_fijas === 1 ? true : false);
      }
      if (term.valor_contrato_fijo != null) {
        setValueContactFixed(term.valor_contrato_fijo === 1 ? true : false);
      }
      if (term.forma_pago != null) {
        setPaymentForm(term.forma_pago === 1 ? true : false);
      }
      calcTotalValue();
    }, []);

    const loadFormData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getAreasOptions(),
          getCostsOptions(),
          getCurrencyTypesOptions(),
          getPersonTypesOptions(),
          getInspectorOptions(),
          getCountTermPolicies(),
          getCountTermPayments(),
          getPositionsRole(),
          getDocumentTypesOptions(),
          getCountDifferentiatedValues(),
          getCountTermResume(),
          getEmployees(),
          getExternalInterventors(),
          getChildrenCounters(),
        ]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    const getChildrenCounters = async () => {
      const response = await getChildrenCounter(term.id);
      setChildrenCounters(response.data.results);
    };

    const getAreasOptions = async () => {
      const response = await getAreas();
      setAreasOptions(response.data.results);
    };

    const getCostsOptions = async () => {
      const response = await getByCostCenter(project.centro_costo);
      setCostsOptions(response.data.results);
    };

    const getCurrencyTypesOptions = async () => {
      const response = await getCurrencyTypes();
      setCurrencyTypesOptions(response.data.results);
    };

    const getPersonTypesOptions = async () => {
      const response = await getPersonTypes();
      setPersonTypesOptions(response.data.results);
    };

    const getExternalInterventors = async () => {
      const response = await getInterventorsByProject(term.proyecto);
      setExternalInterventors(response.data.results);
    };

    const getInspectorOptions = async () => {
      const response = await getInspectors();
      setInspectorOption(response.data.results);
    };

    const getDocumentTypesOptions = async () => {
      if (term.tipo_persona) {
        const response = await getAllDocumentTypes(
          `filter[tipo_contrato]=${term.tipo_contrato}&filter[tipo_persona]=${term.tipo_persona}`,
        );
        setDocumentTypes(response.data.results);
      } else setDocumentTypes([]);
    };

    const getPositionsRole = async () => {
      const response = await getRoleByProject(project_id);
      setPositionsRole(response.data.results);
    };

    const dataByTerm = (data) => {
      if (term.id > 0) {
        const findDataByTerm = data.filter((item) => item.termino == term.id);
        return findDataByTerm;
      }
    };
    const getCountDifferentiatedValues = async () => {
      if (term.id > 0) {
        const response = await getDifferentiatedValuesByTerm(term.id);
        setTermDifferentiatedValues(response.data.results);
      }
    };
    const getCountTermPolicies = async () => {
      if (term.id > 0) {
        const response = await getPoliciesByTerm(term.id);
        setTermPolicies(dataByTerm(response.data.results));
      }
    };
    const getCountTermPayments = async () => {
      if (term.id > 0) {
        const response = await getPaymentsByTerm(term.id);
        setTermPayments(response.data.results);
      }
    };
    const getCountTermResume = async () => {
      if (term.id > 0) {
        const response = await getResumeCounter(term.id);
        setTermResumeCounter(response.data.total);
      }
    };

    const getEmployees = async () => {
      const response = await getAllEmployees();
      setEmployees(response.data.results);
    };

    const onChangePosition = (positionId) => {
      const positionRoleSelected = positionsRole.find(
        (item) => item.id === positionId,
      );

      if (positionRoleSelected) {
        if (positionRoleSelected.educacion_requerida) {
          form.setFieldsValue({
            requerimiento_formacion: positionRoleSelected.educacion_requerida,
          });
        }
        if (positionRoleSelected.experiencia_requerida) {
          form.setFieldsValue({
            requerimiento_experiencia:
              positionRoleSelected.experiencia_requerida,
          });
        }
      }
    };

    const ModalConfirm = (e) => {
      let price = e.target.value;
      if (price !== null && price !== undefined) {
        price = price.replace('$', '');
        price = price.replace(/,/g, '');
        price = price.trim();
        price = parseInt(price);
        if (price > 0) {
          Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content:
              '¿El valor ingresado es correcto incluyendo impuestos, tasas y contribuciones?',
            okText: 'Si',
            cancelText: 'No',
          });
        }
      }
    };

    const saveAndRedirect = (url) => {
      if (!pendingSave) {
        history.push(url);
      } else {
        alert(
          'Antes de continuar por favor guarde los datos del módulo actual.',
        );
      }
    };

    const runDatesValidations = () => {
      form.validateFields([
        'fecha_inicio',
        'fecha_maxima_inicio',
        'fecha_fin',
        'fecha_maxima_fin',
      ]);
    };

    const calcTotalValue = () => {
      const tipo_moneda = form.getFieldValue('moneda');
      const total_value = form.getFieldValue('valor_total');
      let trp_value = total_value;
      if (tipo_moneda > 1) {
        trp_value = trp_value * project.trp;
      }
      if (term.requiere_poliza) {
        form.setFieldsValue({
          valor_total_trp: trp_value,
        });
      } else {
        form.setFieldsValue({
          valor_total_trp: trp_value,
          requiere_poliza:
            term.requiere_poliza === false
              ? false
              : trp_value >= term.valor_smmlv * 10,
        });
      }
    };

    const disabledInitialDates = (value) => {
      return (
        value < moment(project.fecha_inicio).subtract(1, 'days').endOf('day')
      );
    };

    const disabledFinishDates = (value) => {
      return value > moment(project.fecha_fin).endOf('day');
    };

    return (
      <Form
        layout="vertical"
        onFinish={async (data) => {
          setPendingSave(false);
          if (review === false) {
            await onSave(data);
          } else {
            if (data['documentos_adicionales'].length === 0) {
              Modal.confirm({
                title: '¿Los documentos complementarios están completos?',
                okText: 'Sí',
                cancelText: 'No',
                onOk: async () => {
                  setReview(false);
                  await onSave(data, true);
                },
              });
            } else {
              setReview(false);
              await onSave(data, true);
            }
          }
        }}
        initialValues={{
          ...term,
        }}
        onValuesChange={(data) => {
          if (data.hasOwnProperty('pais')) {
            form.setFieldsValue({
              estado: null,
              ciudad: null,
            });
          }
          if (data.hasOwnProperty('estado')) {
            form.setFieldsValue({
              ciudad: null,
            });
          }
          setPendingSave(true);
        }}
        form={form}
        scrollToFirstError={true}
      >
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>A. UBICACIÓN INSTITUCIONAL</Title>}
        >
          <Row gutter={12} ref={moduleA}>
            <Col span={24}>
              <Item
                label="Identificador Interno"
                name="identificador_interno"
                rules={[
                  {
                    required: true,
                    message: 'El campo Identificador interno es obligatorio.',
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Item
                label="Área de Corpoeducación"
                name="area"
                tooltip={{
                  placement: 'top',
                  title: `Seleccione el área o dependencia de Corpoeducación de la cual hace parte el requerimiento de contratación o donación.`,
                }}
              >
                <Select
                  disabled={disabled}
                  placeholder="Seleccione un área de corpoeducación..."
                  allowClear
                >
                  {areasOptions.map((item) => {
                    return (
                      <Option key={`area-key-${item.id}`} value={item.id}>
                        {item.nombre}
                      </Option>
                    );
                  })}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Item
                label="Centro de costos"
                name="centro_costo"
                tooltip={{
                  placement: 'top',
                  title: `Seleccione el proyecto a cuyos recursos estará cargado el contrato o la donación`,
                }}
              >
                <Select
                  disabled={disabled}
                  placeholder="Seleccione un centro de costo..."
                  allowClear
                >
                  {costOptions.map((item) => {
                    return (
                      <Option key={`cost-key-${item.id}`} value={item.id}>
                        {item.codigo} - {item.nombre}
                      </Option>
                    );
                  })}
                </Select>
              </Item>
            </Col>
          </Row>
          {!show && (
            <Row gutter={12}>
              <Col span={24}>
                <CustomButton
                  htmlType="button"
                  onClick={(event) => {
                    setCurrentModule('A');
                    form.submit();
                  }}
                  loading={loading}
                  text={'GUARDAR MÓDULO A'}
                />
              </Col>
            </Row>
          )}
        </Card>
        {term.id != undefined && term.id != 0 && (
          <>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>B. PERFIL REQUERIDO</Title>}
            >
              <Row gutter={12} ref={moduleB}>
                <Col span={24}>
                  <Form.Item
                    label="Tipo de persona"
                    name="tipo_persona"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('tipo_persona') == null) {
                            setShowAddPeople(true);
                          } else {
                            setShowAddPeople(false);
                          }

                          if (getFieldValue('tipo_persona') === 1) {
                            setShowEducation(false);
                          } else {
                            setShowEducation(true);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      placeholder="Seleccione tipo de persona..."
                      allowClear
                    >
                      {personTypesOptions.map((item) => {
                        return (
                          <Option
                            key={`personType-key-${item.id}`}
                            value={item.id}
                          >
                            {item.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Cargo /Rol"
                    name="cargo"
                    tooltip={{
                      placement: 'top',
                      title: `Seleccione el cargo o rol que tendrá la persona a contratar.`,
                    }}
                  >
                    <Select
                      disabled={disabled}
                      placeholder="Seleccione un cargo/rol..."
                      onChange={onChangePosition}
                      allowClear
                    >
                      {positionsRole &&
                        positionsRole.map((item) => {
                          return (
                            <Option
                              key={`position-role-key-${item.id}`}
                              value={item.id}
                            >
                              {item.cargo}
                            </Option>
                          );
                        })}
                    </Select>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Requerimientos de educación / formación"
                    name="requerimiento_formacion"
                    tooltip={{
                      placement: 'top',
                      title: `Digite aquí los requerimientos de formación o educación`,
                    }}
                    hidden={showEducation}
                  >
                    <Input.TextArea
                      rows={4}
                      disabled={disabled}
                      placeholder="Digite aquí los requerimientos de formación o educación..."
                      allowClear
                    />
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Requerimientos de experiencia"
                    name="requerimiento_experiencia"
                    tooltip={{
                      placement: 'top',
                      title: `Digite aquí los requerimientos de experiencia`,
                    }}
                  >
                    <Input.TextArea
                      rows={4}
                      disabled={disabled}
                      placeholder="Digite aquí los requerimientos de experiencia..."
                      allowClear
                    />
                  </Item>
                </Col>
              </Row>
              {!show && (
                <Row gutter={12}>
                  <Col span={24}>
                    <CustomButton
                      htmlType="button"
                      onClick={(event) => {
                        setCurrentModule('B');
                        form.submit();
                      }}
                      loading={loading}
                      text={'GUARDAR MÓDULO B'}
                    />
                  </Col>
                </Row>
              )}
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>C. ESPECIFICACIONES DEL CONTRATO</Title>}
            >
              <Row gutter={12} ref={moduleC}>
                <Col span={24}>
                  <Item
                    label="Objeto del contrato"
                    name="objeto_contrato"
                    tooltip={{
                      placement: 'top',
                      title: `Digite claramente cuál es el objeto a contratar o el objeto de la donación. Recuerde que este campo es la columna vertebral de los contratos.`,
                    }}
                  >
                    <TextArea
                      disabled={disabled}
                      placeholder="Digite claramente cuál es el objeto a contratar o el objeto de la donación..."
                      allowClear
                    />
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Lugar de ejecución"
                    name="lugar"
                    tooltip={{
                      placement: 'top',
                      title: `Seleccione el País, Departamento (aplica para Colombia) y ciudad en que se desarrollarán las actividades  del contrato o en el que se hace la donación`,
                    }}
                  >
                    <CountryStateCity
                      disabled={disabled}
                      countryKey="pais"
                      stateKey="estado"
                      cityKey="ciudad"
                      countryLabel="País"
                      stateLabel="Departamento"
                      cityLabel="Ciudad"
                      country={term.pais}
                      state={term.estado}
                      city={term.ciudad}
                    />
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="¿Este cargo/rol implica labores de interventoría?"
                    name="implica_interventoria"
                    tooltip={{
                      placement: 'top',
                      title: `Aplica cuando este cargo/rol tiene personas a cargo y es responsable de hacer el seguimiento y aprobación de sus obligaciones contractuales`,
                    }}
                  >
                    <Radio.Group
                      defaultValue={term.tiene_politicas}
                      disabled={disabled}
                    >
                      <Radio value={true}>Si</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Responsabilidades u obligaciones específicas del contratista"
                    tooltip={{
                      placement: 'top',
                      title: `Digite todas las responsabilidades u obligaciones específicas que tendrá el contratista. Para productos y/o entregables realícelo por la opción Productos y/o entregables`,
                    }}
                  >
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      align="middle"
                    >
                      <Col>
                        <Button
                          onClick={() => {
                            saveAndRedirect(
                              `${INTERNAL_LINKS.RESPONSIBILITIES}/${term.id}/${project_id}/${contract_id}`,
                            );
                          }}
                          disabled={term.id === 0}
                        >
                          Agregar
                        </Button>
                      </Col>
                      <Col>
                        {childrenCounters &&
                        childrenCounters.responsabilities ? (
                          childrenCounters.responsabilities === 1 ? (
                            <Text>
                              {childrenCounters.responsabilities} Registro
                            </Text>
                          ) : (
                            <Text>
                              {childrenCounters.responsabilities} Registros
                            </Text>
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Productos y/o entregables"
                    tooltip={{
                      placement: 'top',
                      title: `Digite todos los productos y/o entregables que tendrá el contratista.`,
                    }}
                  >
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      align="middle"
                    >
                      <Col>
                        <Button
                          onClick={() => {
                            saveAndRedirect(
                              `${INTERNAL_LINKS.PRODUCTS}/${term.id}/${project_id}/${contract_id}`,
                            );
                          }}
                          disabled={term.id === 0}
                        >
                          Agregar
                        </Button>
                      </Col>
                      <Col>
                        {childrenCounters && childrenCounters.products ? (
                          childrenCounters.products === 1 ? (
                            <Text>{childrenCounters.products} Registro</Text>
                          ) : (
                            <Text>{childrenCounters.products} Registros</Text>
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Políticas, lineamientos y/o protocolos específicos para el desarrollo del contrato."
                    name="tiene_politicas"
                    tooltip={{
                      placement: 'top',
                      title: `¿Hay políticas, lineamientos y/o protocolos específicos para el  desarrollo del contrato? Si la respuesta es Sí Agréguelos`,
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('tiene_politicas') === 1) {
                            setShowPolicies(false);
                          } else {
                            setShowPolicies(true);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Radio.Group
                      defaultValue={term.tiene_politicas}
                      disabled={disabled}
                    >
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item hidden={showPolicies}>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      align="middle"
                    >
                      <Col>
                        <Button
                          onClick={() => {
                            saveAndRedirect(
                              `${INTERNAL_LINKS.CONTRACT_POLICIES}/${term.id}/${project_id}/${contract_id}`,
                            );
                          }}
                          disabled={term.id === 0}
                        >
                          Agregar
                        </Button>
                      </Col>
                      <Col>
                        {termPolicies && termPolicies.length === 1 ? (
                          <Text>{termPolicies.length} Registro</Text>
                        ) : (
                          <Text>{termPolicies.length} Registros</Text>
                        )}
                      </Col>
                    </Row>
                  </Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item
                    label="Persona responsable de elaborar los términos de referencia:"
                    name="responsable_nombre"
                  >
                    <Input disabled />
                  </Item>
                </Col>
              </Row>
              {!show && (
                <Row gutter={12}>
                  <Col span={24}>
                    <CustomButton
                      htmlType="button"
                      onClick={(event) => {
                        setCurrentModule('C');
                        form.submit();
                      }}
                      loading={loading}
                      text={'GUARDAR MÓDULO C'}
                    />
                  </Col>
                </Row>
              )}
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={
                <Title level={3}>D. ASPECTOS JURÍDICOS Y ECONÓMICOS</Title>
              }
            >
              <Row gutter={12} ref={moduleD}>
                <Col span={12}>
                  <Form.Item
                    label="Fecha de inicio"
                    name="fecha_inicio"
                    tooltip={{
                      placement: 'top',
                      title: `Seleccione la fecha de inicio del contrato`,
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value != null) {
                            if (
                              value.isAfter(getFieldValue('fecha_fin'), 'day')
                            ) {
                              return Promise.reject(
                                new Error(
                                  'La fecha de inicio no puede ser mayor a la fecha de fin',
                                ),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabled={disabled}
                      disabledDate={disabledInitialDates}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setInitialDate(value);
                        runDatesValidations();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Fecha de terminación"
                    name="fecha_fin"
                    tooltip={{
                      placement: 'top',
                      title: `Seleccione la fecha de terminación del contrato`,
                    }}
                    rules={[
                      {
                        required: false,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value != null) {
                            if (
                              getFieldValue('fecha_inicio').isAfter(
                                value,
                                'day',
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  'La fecha de inicio no puede ser mayor a la fecha de fin',
                                ),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabled={disabled}
                      disabledDate={disabledFinishDates}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setFinishDate(value);
                        runDatesValidations();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  {initialDate && (
                    <Form.Item>
                      Fecha de inicio seleccionada:{' '}
                      <strong>{initialDate.format('DD MMMM YYYY')}</strong>
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  {finishDate && (
                    <Form.Item>
                      Fecha de terminación seleccionada:{' '}
                      <strong>{finishDate.format('DD MMMM YYYY')}</strong>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <Form.Item
                    label="¿La fecha de inicio y de terminación digitadas son fijas?"
                    name="fechas_fijas"
                    tooltip={{
                      placement: 'top',
                      title: `¿La fecha de inicio y de terminación digitadas son fijas?`,
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('fechas_fijas') === 1) {
                            setShowComment(true);
                          } else {
                            setShowComment(false);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Radio.Group disabled={disabled}>
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {showComment === true && (
                  <Col span={14}>
                    <Item
                      label="Si selecciona fecha fija recuerde el riesgo jurídico en que se puede incurrir en caso de que la aprobación sea posterior. Justifique su decisión."
                      name="justificacion_fecha_fija"
                      tooltip={{
                        placement: 'top',
                        title: `Justifique su decisión`,
                      }}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Justifique su decisión ..."
                        allowClear
                      />
                    </Item>
                  </Col>
                )}
              </Row>
              {showComment === false && (
                <>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item
                        label="Fecha de inicio máxima"
                        name="fecha_maxima_inicio"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value != null) {
                                if (
                                  !getFieldValue('fecha_maxima_inicio').isAfter(
                                    getFieldValue('fecha_maxima_fin'),
                                    'day',
                                  )
                                ) {
                                  if (getFieldValue('fecha_inicio')) {
                                    if (
                                      getFieldValue(
                                        'fecha_maxima_inicio',
                                      ).isBefore(
                                        getFieldValue('fecha_inicio'),
                                        'day',
                                      )
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          'La fecha de inicio máxima no puede ser menor a la fecha de inicio',
                                        ),
                                      );
                                    }
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    'La fecha de inicio máxima no puede ser mayor a la fecha de fin',
                                  ),
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          disabled={disabled}
                          disabledDate={disabledInitialDates}
                          style={{ width: '100%' }}
                          onChange={(value) => {
                            setInitialMaxDate(value);
                            runDatesValidations();
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Fecha de terminación máxima"
                        name="fecha_maxima_fin"
                        rules={[
                          {
                            required: false,
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                getFieldValue('fecha_maxima_inicio') != null &&
                                getFieldValue('fecha_maxima_fin') != null
                              ) {
                                if (
                                  !getFieldValue('fecha_maxima_inicio').isAfter(
                                    getFieldValue('fecha_maxima_fin'),
                                    'day',
                                  )
                                ) {
                                  if (getFieldValue('fecha_fin')) {
                                    if (
                                      getFieldValue(
                                        'fecha_maxima_fin',
                                      ).isBefore(
                                        getFieldValue('fecha_fin'),
                                        'day',
                                      )
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          'La fecha de terminación máxima no puede ser menor a la fecha de terminación',
                                        ),
                                      );
                                    }
                                  }

                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    new Error(
                                      'La fecha de terminación maxima no puede ser menor a la fecha de inicio máxima',
                                    ),
                                  );
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          disabled={disabled}
                          disabledDate={disabledFinishDates}
                          style={{ width: '100%' }}
                          onChange={(value) => {
                            setFinishMaxDate(value);
                            runDatesValidations();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      {initialMaxDate && (
                        <Form.Item>
                          Fecha de inicio máxima seleccionada:{' '}
                          <strong>
                            {initialMaxDate.format('DD MMMM YYYY')}
                          </strong>
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={12}>
                      {finishMaxDate && (
                        <Form.Item>
                          Fecha de terminación máxima seleccionada:{' '}
                          <strong>
                            {finishMaxDate.format('DD MMMM YYYY')}
                          </strong>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="¿El valor del contrato es fijo?"
                    name="valor_contrato_fijo"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('valor_contrato_fijo') === 1) {
                            setValueContactFixed(true);
                          } else {
                            setValueContactFixed(false);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Radio.Group disabled={disabled}>
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {valueContactFixed && (
                <Row gutter={12}>
                  <Col span={6}>
                    <Item
                      label="Moneda"
                      name="moneda"
                      tooltip={{
                        placement: 'top',
                        title: `Seleccione la moneda`,
                      }}
                    >
                      <Select
                        disabled={disabled}
                        style={{ width: '100%' }}
                        placeholder="Seleccione la moneda..."
                        allowClear
                        onChange={calcTotalValue}
                      >
                        {currencyTypesOptions.map((item) => {
                          if (
                            item.id === 1 ||
                            item.id === project.tipo_moneda
                          ) {
                            return (
                              <Option
                                key={`currency-key-${item.id}`}
                                value={item.id}
                              >
                                {item.sigla}
                              </Option>
                            );
                          }
                        })}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label="Valor total (Incluyendo impuestos, tasas y contribuciones)"
                      name="valor_total"
                      tooltip={{
                        placement: 'top',
                        title: `Digite el valor total del contrato incluyendo impuestos, tasas y contribuciones`,
                      }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                'No se aceptan números negativos',
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        disabled={disabled}
                        onBlur={ModalConfirm}
                        placeholder="Valor total..."
                        style={{ width: '100%' }}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={calcTotalValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Valor total equivalente en COP es:"
                      name="valor_total_trp"
                      tooltip={{
                        placement: 'top',
                        title: `Este campo solo permite valores en la moneda a pagar. No permite porcentajes, si es su caso por favor realice la conversión.`,
                      }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || value >= 0) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                'No se aceptan números negativos',
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        disabled={true}
                        style={{ width: '100%' }}
                        placeholder="Valor total en COP..."
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item noStyle dependencies={['valor_total']}>
                    {({ getFieldValue }) => (
                      <>
                        {getFieldValue('valor_total_trp') >=
                          10 * term.valor_smmlv && (
                          <Col span={24}>
                            <Alert
                              type="info"
                              showIcon
                              message="Información importante"
                              description="Se requiere póliza para este TDR"
                            />
                            <br />
                          </Col>
                        )}
                      </>
                    )}
                  </Form.Item>
                  <Col span={10}>
                    <Form.Item noStyle dependencies={['valor_total']}>
                      {({ getFieldValue }) => (
                        <Form.Item
                          label="¿Se requiere póliza para este TDR?"
                          name="requiere_poliza"
                        >
                          <PolizaInput
                            disabled={disabled}
                            rp={
                              getFieldValue('valor_total_trp') >=
                              10 * term.valor_smmlv
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    dependencies={['requiere_poliza', 'valor_total']}
                  >
                    {({ getFieldValue }) => (
                      <>
                        {getFieldValue('requiere_poliza') === false &&
                          getFieldValue('valor_total_trp') >=
                            10 * term.valor_smmlv && (
                            <Col span={14}>
                              <Form.Item
                                label="Se requiere póliza para este TDR. Justifique por qué no se solicitará."
                                name="justificacion_poliza"
                                tooltip={{
                                  placement: 'top',
                                  title: `Justifique su decisión`,
                                }}
                              >
                                <Input
                                  disabled={disabled}
                                  placeholder="Justifique su decisión ..."
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          )}
                      </>
                    )}
                  </Form.Item>
                </Row>
              )}
              {valueContactFixed === false && (
                <Row gutter={12}>
                  <Col span={24}>
                    <Item label="Agregue los valores diferenciados">
                      <Space>
                        <Button
                          onClick={() => {
                            saveAndRedirect(
                              `${INTERNAL_LINKS.TERM_DIFFERENTIATED_VALUES}/${term.id}`,
                            );
                          }}
                          disabled={term.id === 0}
                        >
                          Agregar valores diferenciados
                        </Button>

                        {termDifferentiatedValues &&
                        termDifferentiatedValues.length === 1 ? (
                          <Text>
                            {termDifferentiatedValues.length} Registro
                          </Text>
                        ) : (
                          <Text>
                            {termDifferentiatedValues.length} Registros
                          </Text>
                        )}
                      </Space>
                    </Item>
                  </Col>
                </Row>
              )}
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Forma de pago"
                    tooltip={{
                      placement: 'top',
                      title: `Agregue la forma de pago`,
                    }}
                    name="forma_pago"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('forma_pago') === 1) {
                            setPaymentForm(true);
                          } else {
                            setPaymentForm(false);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Radio.Group disabled={disabled}>
                      <Radio
                        value={1}
                        disabled={valueContactFixed === true ? false : true}
                      >
                        Pago fijo
                      </Radio>
                      <Radio value={0}>Pago variable</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {paymentForm === true || paymentForm === 1 ? (
                <Row gutter={12} style={{ marginBottom: 15 }}>
                  <Col>
                    <Button
                      onClick={() => {
                        saveAndRedirect(
                          `${INTERNAL_LINKS.PAYRESPONSIBILITYPRODUCT}/0/${term.id}/${project_id}/${contract_id}`,
                        );
                      }}
                      disabled={term.id === 0}
                    >
                      Agregar Forma de Pago
                    </Button>
                  </Col>
                  <Col>
                    {termPayments && termPayments.length === 1 ? (
                      <Text>{termPayments.length} Registro</Text>
                    ) : (
                      <Text>{termPayments.length} Registros</Text>
                    )}
                  </Col>
                </Row>
              ) : (
                <>
                  {(paymentForm === false || paymentForm === 0) && (
                    <Form.Item label="Texto de pago" name="forma_pago_texto">
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  )}
                </>
              )}
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Seleccione el tipo de interventoría para este cargo/rol"
                    name="tipo_interventoria"
                  >
                    <Radio.Group disabled={disabled}>
                      <Radio value={true}>
                        Interventoría interna (Empleados de la entidad)
                      </Radio>
                      <Radio value={false}>
                        Interventoría externa (Contratista)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Item noStyle dependencies={['tipo_interventoria']}>
                    {({ getFieldValue, setFieldsValue }) => {
                      if (getFieldValue('tipo_interventoria') === false) {
                        setFieldsValue({ interventor_interno: null });
                        return (
                          <Item
                            label="Interventor"
                            name="interventor_externo"
                            tooltip={{
                              placement: 'top',
                              title: `Seleccione el nombre de la persona que tendrá a su cargo la interventoría del contrato.`,
                            }}
                          >
                            <Select
                              placeholder="Seleccione el nombre de la persona..."
                              options={externalInterventors}
                            />
                          </Item>
                        );
                      } else if (getFieldValue('tipo_interventoria') === true) {
                        setFieldsValue({ interventor_externo: null });
                        return (
                          <Item label="Interventor" name="interventor_interno">
                            <Select placeholder="Seleccione el nombre de la persona...">
                              {employees.map((item) => {
                                if (item.estado_interventoria === true) {
                                  return (
                                    <Option
                                      key={`employees-key-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.nombre_completo} -{' '}
                                      {item.cargo_nombre ||
                                        'Sin cargo asignado'}
                                    </Option>
                                  );
                                }
                              })}
                            </Select>
                          </Item>
                        );
                      }
                      return null;
                    }}
                  </Item>
                </Col>
              </Row>
              {false && (
                <Row gutter={12}>
                  <Col span={12}>
                    <Item
                      label="Interventor"
                      name="interventor"
                      tooltip={{
                        placement: 'top',
                        title: `Seleccione el nombre de la persona que tendrá a su cargo la interventoría del contrato.`,
                      }}
                    >
                      <Select
                        disabled={disabled}
                        placeholder="Seleccione nombre de la persona..."
                        allowClear
                      >
                        {inspectorOptions.map((item) => {
                          return (
                            <Option
                              key={`audit-key-${item.id}`}
                              value={item.id}
                            >
                              {item.nombre}
                            </Option>
                          );
                        })}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Cargo" name="interventor_cargo">
                      <Input
                        disabled={disabled}
                        placeholder="Digite interventor cargo..."
                        allowClear
                      />
                    </Item>
                  </Col>
                </Row>
              )}
              {!show && (
                <Row gutter={12}>
                  <Col span={24}>
                    <CustomButton
                      htmlType="button"
                      onClick={(event) => {
                        setCurrentModule('D');
                        form.submit();
                      }}
                      loading={loading}
                      text={'GUARDAR MÓDULO D'}
                    />
                  </Col>
                </Row>
              )}
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={
                <Title level={3}>E. PERSONA A CONTRATAR / DONATARIO</Title>
              }
            >
              <Row gutter={12} ref={moduleE}>
                <Col span={24}>
                  <Item hidden={showAddPeople}>
                    <Space>
                      <Button
                        onClick={() => {
                          saveAndRedirect(
                            `${INTERNAL_LINKS.SEARCHCONTRACTOR}/${term.id}/${project_id}/${contract_id}`,
                          );
                        }}
                        disabled={term.id === 0}
                      >
                        Agregar
                      </Button>
                      {termResumeCounter && termResumeCounter === 1 ? (
                        <Text>{termResumeCounter} Registro</Text>
                      ) : (
                        <Text>{termResumeCounter} Registros</Text>
                      )}
                    </Space>
                  </Item>
                </Col>
              </Row>
            </Card>
            <Card
              size={'small'}
              bordered={false}
              title={<Title level={3}>F. DOCUMENTOS REQUERIDOS</Title>}
            >
              <Row gutter={12} ref={moduleF}>
                <Col span={24}>
                  {term.anexo_nombre != null ? (
                    <p>
                      Documentos contractuales requeridos de acuerdo con el{' '}
                      <strong>{term.anexo_nombre}</strong> del Procedimiento de
                      contratación de Corpoeducación
                    </p>
                  ) : (
                    <p>El número de anexo no ha sido asignado.</p>
                  )}
                </Col>
              </Row>
            </Card>
            {isLegal && (
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={3}>G. DOCUMENTOS COMPLEMENTARIOS</Title>}
              >
                <Row gutter={12} ref={moduleG}>
                  <Col span={24}>
                    <p>
                      Seleccione los documentos complementarios que se deben
                      firmar con el contrato.
                    </p>
                    <p>
                      Recuerde que son requerimientos asociados a condiciones
                      contractuales del cliente / aliado.
                    </p>
                    <Item name="documentos_adicionales">
                      <Select mode="multiple" allowClear>
                        {documentTypes.map((item) => (
                          <Select.Option
                            key={`document-types-key-${item.id}`}
                            value={item.tipo_plantilla}
                          >
                            {item.nombre_plantilla}
                          </Select.Option>
                        ))}
                      </Select>
                    </Item>
                  </Col>
                </Row>
              </Card>
            )}
            <Divider />
            <Item>
              <Space>
                {!show && (
                  <>
                    <Button loading={loading} htmlType="submit" type="primary">
                      GUARDAR Y NO ENVIAR
                    </Button>
                    {isLegal && (
                      <Button
                        onClick={() => {
                          setReview(true);
                        }}
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        GUARDAR Y ENVIAR PARA CONTRATACIÓN
                      </Button>
                    )}
                  </>
                )}
                <Button danger>
                  <Link to={`${INTERNAL_LINKS.TDR_HOME}`}>CERRAR</Link>
                </Button>
              </Space>
            </Item>
          </>
        )}
      </Form>
    );
  },
);

export default TermsForm;
export { PolizaInput };
