import httpClient from '../httpClient';

const info = async (id) => {
  return await httpClient.get(`/mass_modification_payments/${id}/info/`);
};

const post = async (data) => {
  return await httpClient.post('/mass_modification_payments/', data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/mass_modification_payments/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/mass_modification_payments/${id}/`);
};

const view_home = async (query, page = 1, pageSize = 10, search = false) => {
  return await httpClient.get(
    `/mass_modification_payments/view_home/?page[number]=${page}&page[size]=${pageSize}&filter[modificacion]=${query}${
      search ? `&filter[search]=${search}` : ''
    }`,
  );
};

const filter = async (
  query,
  page = 1,
  pageSize = 10,
  _sortBy = '-created_at',
  search = false,
  exportCSV = false,
) => {
  if (exportCSV)
    return await httpClient.get(
      `/mass_modification_payments/export/?filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `/mass_modification_payments/?page[number]=${page}&page[size]=${pageSize}&filter[modulo_modificacion]=${query}${
        search ? `&filter[search]=${search}` : ''
      }`,
    );
};

const patchModule = async (id, data) => {
  return await httpClient.patch(
    `/mass_modification_payments/${id}/update_module/`,
    data,
  );
};

export { info, post, patch, remove, filter, patchModule, view_home };
