import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/authorize/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/authorize/`);
};

const getFilteredByRegister = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/authorize/?filter[tipo]=REGISTRO`,
  );
};

const getFilteredByTDR = async (organization_id = undefined) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/authorize/?filter[tipo]=TDR${
      organization_id ? `&filter[organizacion]=${organization_id}` : ''
    }`,
  );
};

export { post, getAll, getFilteredByRegister, getFilteredByTDR };
