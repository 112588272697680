import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Typography, notification, Space } from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { filter, remove } from '../../../api/module/states';

import './style.scss';
import { PlusOutlined } from '@ant-design/icons';
import AjaxTable from '../../../components/AjaxTable';

const { Title, Paragraph } = Typography;
const breadcrumbItems = [
  {
    text: 'Departamentos',
  },
];

export default function StatesList({}) {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [reloadSource, setReloadSource] = useState(false);

  // Delete Object by Id of List
  const deleteDataSource = async (id) => {
    try {
      setLoadingDelete(id);
      await remove(id);
      setLoadingDelete(false);
      setReloadSource({});
      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
        duration: 2,
      });
    } catch (err) {
      console.log(err);
      setLoadingDelete(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <CustomButton
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            text={
              <Link to={`${INTERNAL_LINKS.STATE}/${record.id}`}>Editar</Link>
            }
          />
          <CustomButton
            danger
            type={'default'}
            htmlType="button"
            loading={loadingDelete === record.id}
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG}
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Departamentos
      </Title>
      <Paragraph>
        A continuación, podrá realizar acciones como editar y/o eliminar
        registros contenidos en la siguiente tabla. Si desea agregar un nuevo
        departamento, por favor, haga click en el botón "Crear nuevo
        departamento".
      </Paragraph>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <Link to={INTERNAL_LINKS.STATE}>
            <PlusOutlined /> Crear nuevo departamento
          </Link>
        }
      />
      <AjaxTable
        tableTitle={
          <Paragraph className={'margin-5'} strong>
            Listado de Departamentos
          </Paragraph>
        }
        columns={columns}
        endpoint={filter}
        scroll={{ x: 400 }}
        rowKey="id"
        searchInput
        reloadSource={reloadSource}
      />
    </>
  );
}
