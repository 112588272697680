import React, { useEffect, useState } from 'react';

import { Col, Card, Row, Typography, Modal, Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import './style.scss';
import { catchException } from '../../utils/helpers/helpers';
import { getIndicatorData, indicatorsVDP } from '../../api/module/home';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import AjaxTable from '../AjaxTable';

const { Title } = Typography;

const indicatorsBase = [
  {
    title: 'Validación Documentos Precontractuales',
    dataIndex: 'vdp',
    colorText: 'rgb(255, 216, 0)',
    colorNumber: 'rgb(255, 216, 0)',
    colorCard: 'rgba(255, 216, 0, 0.2)',
    link: (record) =>
      `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}/project=${record.id}`,
    endpointFetch: indicatorsVDP,
  },
];

const HomeIndicators = () => {
  const [data, setData] = useState(undefined);

  const [mT, setMT] = useState();

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const res = await getIndicatorData();
        if (isMounted) setData(res.data);
      } catch (error) {
        catchException(error);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  function handleClick(item) {
    console.log(item.dataIndex);
    setMT(item.dataIndex);
  }

  if (!data) {
    return <Skeleton loading={true} active={true} />;
  }

  return (
    <div id="homeIndicators">
      <Row justify="center" style={{ width: '100%' }} gutter={[8, 16]}>
        {indicatorsBase.map(
          (item, key) =>
            data[item.dataIndex] && (
              <Col style={{ display: 'flex' }} span={8} key={key}>
                <Card
                  bordered={false}
                  className="flexible-card"
                  style={{ background: item.colorCard }}
                  onClick={() => handleClick(item)}
                  hoverable
                >
                  <Card.Meta
                    title={
                      <Title
                        strong
                        level={4}
                        style={{
                          color: item.colorText,
                        }}
                      >
                        <div
                          style={{
                            color: item.colorText,
                          }}
                        >
                          {item.title}
                        </div>
                      </Title>
                    }
                    description={
                      <Title strong level={2}>
                        <div
                          style={{
                            color: item.colorNumber,
                            textDecoration: 'underline',
                          }}
                        >
                          {data[item.dataIndex] || '0'}
                        </div>
                      </Title>
                    }
                  />
                </Card>
                <Modal
                  visible={mT === item.dataIndex}
                  footer={null}
                  title={item.title}
                  onCancel={() => setMT()}
                  destroyOnClose
                  bodyStyle={{
                    paddingTop: '0px',
                  }}
                >
                  <AjaxTable
                    tableTitle="Proyectos con pendientes"
                    endpointFetch={async (page, pageSize) => {
                      return await item.endpointFetch(page, pageSize);
                    }}
                    columns={[
                      {
                        title: 'Proyecto',
                        dataIndex: 'nombre',
                      },
                      { title: '#', dataIndex: 'n', align: 'center' },
                      {
                        title: 'Acciones',
                        key: 'acciones',
                        width: '3rem',
                        align: 'center',
                        render: (record) => (
                          <Link to={item.link(record)}>Ir</Link>
                        ),
                      },
                    ]}
                  />
                </Modal>
              </Col>
            ),
        )}
      </Row>
    </div>
  );
};

export default HomeIndicators;
