import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  notification,
  Typography,
  Input,
  Form,
  Popconfirm,
  Alert,
  Layout,
  Space,
} from 'antd';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import { get as getDocumentUrl } from '../../../api/module/document_repository';
import { mailInfo, sendMail } from '../../../api/module/insurance_policy';

const breadcrumbItems = [
  {
    text: 'Revisión Pólizas',
    to: `${INTERNAL_LINKS.INSURANCE}`,
  },
  {
    text: 'Generar Correo',
  },
];

const tabNameMap = {
  poliza: 'Póliza',
  recibo: 'Recibo de pago',
};

const { Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

export default function InsurancePolicyEmail({ match, history }) {
  const [validationData, setValidationData] = useState(undefined);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      try {
        const validationId = match.params.validation_id;
        const res = await mailInfo(validationId);
        setValidationData({ ...res.data, mensaje: undefined });
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.validation_id]);

  const onFinish = async (data) => {
    try {
      setLoadingSend(true);
      await sendMail(validationData.id, { mensaje: data.mensaje });
      history.push(INTERNAL_LINKS.INSURANCE);
    } catch (err) {
      setLoadingSend(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 4,
      });
    }
  };

  const showHelp = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(documento_nombre);
    window.open(response.data.url);
    setLoadingDocument(false);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO REVISIÓN DE PÓLIZAS
      </Title>
      {validationData && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Proyecto a revisar">
              {validationData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/rol a revisar">
              {validationData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.contratista_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tipo_identificacion} - ${validationData.identificacion}`}
            </Descriptions.Item>
            <Descriptions.Item label="No. Contrato">
              {validationData.contrato_num}
            </Descriptions.Item>
            <Descriptions.Item label="No. de la modificación">
              {validationData.modificacion_num}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Estado</b>} span={2}>
              {validationData.estado_validacion_nombre}
            </Descriptions.Item>
          </Descriptions>
          <Form
            form={form}
            initialValues={validationData}
            onFinish={onFinish}
            key={`CT_PL_EMAIL_${match.params.validation_id}`}
          >
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Descriptions layout="vertical" column={1} bordered>
                <Descriptions.Item
                  label={`Observaciones del módulo ${tabNameMap.poliza}`}
                >
                  <Form.Item name={`observaciones_poliza`} noStyle>
                    <TextArea placeholder="Observaciones" rows={4} disabled />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item
                  label={`Observaciones del módulo ${tabNameMap.recibo}`}
                >
                  <Form.Item name={`observaciones_recibo`} noStyle>
                    <TextArea placeholder="Observaciones" rows={4} disabled />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Alert
                message="Nota"
                action={
                  <CustomButton
                    type={'default'}
                    className={'back-button'}
                    htmlType="button"
                    loading={loadingDocument}
                    onClick={() => {
                      showHelp(validationData.helper);
                    }}
                    text={'Descargar ayuda'}
                  />
                }
                description={
                  'Descargue la ayuda con la información orientadora sobre los posibles textos de correos.'
                }
                type="info"
                showIcon
              />
              <Descriptions layout="vertical" column={1} bordered>
                <Descriptions.Item label={<b>Mensaje del correo</b>}>
                  <Form.Item name="mensaje" rules={[{ required: true }]}>
                    <TextArea placeholder="Mensaje" rows={6} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Space>
            <Space style={{ marginTop: 10 }}>
              <Popconfirm
                onConfirm={() => {
                  form.submit();
                }}
                title={MESSAGES.MSG_SEND_EMAIL}
              >
                <CustomButton
                  type="primary"
                  text="Guardar"
                  loading={loadingSend}
                />
              </Popconfirm>
              <CustomButton
                type="default"
                htmlType="button"
                className={'back-button'}
                text={
                  <Link
                    to={`${INTERNAL_LINKS.INSURANCE}/incomplete/${validationData.proyecto_id}`}
                  >
                    Volver
                  </Link>
                }
              />
            </Space>
          </Form>
        </Space>
      )}
    </Content>
  );
}
