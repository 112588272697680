import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/rol_icfes'

import './style.scss';

const SelectTestIcfes = ({
  className = '',
  onChange = () => { },
  rol_icfes = 0
}) => {
  const [data, setData] = useState(null);

  const getTestIcfes = async () => {
    const response = await getAll();
    setData(response.results);
  }

  useEffect(() => {
    getTestIcfes();
  }, [])

  return (
    <>
      {
        data != null &&
        <>
          {
            (rol_icfes != 0 && rol_icfes != null) ?
              <Select
                labelInValue
                defaultValue={{ value: rol_icfes }}
                className={className}
                onChange={onChange}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  data != null &&
                  data.map(item => <Select.Option key={`rol-icfes-key-${item.id}-${item.nombre}`} value={item.id}>{item.nombre}</Select.Option>)
                }
              </Select>
              :
              <Select
                className={className}
                onChange={onChange}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  data != null &&
                  data.map(item => <Select.Option key={`rol-icfes-key-${item.id}-${item.nombre}`} value={item.id}>{item.nombre}</Select.Option>)
                }
              </Select>
          }
        </>
      }

    </>
  );
};
export default SelectTestIcfes;
