import React, { useState } from 'react';

import { Modal, Input, Form, List } from 'antd';
import CustomButton from '../CustomButton';

const defModalProps = {
  centered: true,
  cancelText: 'Cancelar',
  okText: 'Confirmar',
};

export default function TextAreaModal({
  modalProps = defModalProps,
  textAreaProps = {},
  defValue = undefined,
  rules = [],
  onOk = async (text) => {
    return;
  },
  onCancel = () => {},
}) {
  const mProps = { ...defModalProps, ...modalProps };
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const selfConfirm = async (data) => {
    setLoading(true);
    try {
      await onOk(data.ta_text);
    } catch (err) {
      console.log('Error while trying to submit on <TextAreaModal />: ', err);
      setLoading(false);
      throw err;
    }
  };
  return (
    <Modal
      title="Ingrese el texto..."
      {...mProps}
      footer={
        <>
          <CustomButton
            text={mProps.cancelText}
            type="default"
            htmlType="button"
            onClick={onCancel}
          />

          <CustomButton
            text={mProps.okText}
            type="primary"
            htmlType="button"
            loading={loading}
            onClick={() => form.submit()}
          />
        </>
      }
      closable={false}
      bodyStyle={{ paddingTop: '4px', paddingBottom: '4px' }}
    >
      <Form
        initialValues={{ ta_text: defValue }}
        preserve={false}
        onFinish={selfConfirm}
        onFinishFailed={(err) => {
          Modal.warning({
            title: 'Advertencia',
            content: (
              <List
                dataSource={err.errorFields[0].errors}
                pagination={false}
                renderItem={(item) => <>{item}</>}
              />
            ),
          });
        }}
        form={form}
      >
        <Form.Item name="ta_text" rules={rules} noStyle>
          <Input.TextArea {...textAreaProps} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
