import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';

import {
  Row,
  Col,
  Typography,
  Skeleton,
  notification,
  Tabs,
  Badge,
  Space,
  Divider,
  Popconfirm,
  Modal,
  Descriptions,
  Input,
  Form,
} from 'antd';
import { EditOutlined, SelectOutlined } from '@ant-design/icons';

import CustomSelector from '../../components/CustomSelector';

import { CustomButton } from '../../components';
import MESSAGES from '../../enums/Messages';
import { Link } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import TextAreaModal from '../../components/TextAreaModal';
import AjaxTable from '../../components/AjaxTable';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  getContractTypes,
  getCounters,
  getProjects,
  getRoles,
  getTerms,
  reject,
  doReturn,
  sign,
  signMassive,
  signSelected,
  ajaxTable as filterAjaxTable,
  getGroups,
  getUsers,
} from '../../api/module/ctm_director';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const breadcrumbItems = [
  {
    text: 'Firma de modificaciones contractuales de Contratistas y Proveedores',
  },
];

const columns = [
  {
    title: 'Proyecto',
    dataIndex: 'project_name',
  },
  {
    title: 'Tipo persona',
    dataIndex: 'tipo_persona',
  },
  {
    title: 'Nombre completo/Razón social',
    dataIndex: 'contratista_nombre',
  },
  {
    title: 'Tipo doc',
    dataIndex: 'tipo_identificacion',
  },
  {
    title: 'Nro. Documento',
    dataIndex: 'identificacion',
  },
  {
    title: 'Cargo',
    dataIndex: 'cargo_nombre',
  },
  {
    title: 'Nro. Contrato',
    dataIndex: 'contrato_num',
  },
  {
    title: '# Modificación',
    dataIndex: 'mod_num',
  },
  {
    title: 'Nueva Fecha fin',
    dataIndex: 'fecha_fin_contrato',
    render: (text) => (text ? moment(text).format('YYYY-MM-DD') : null),
  },
  {
    title: 'Identificador masivo',
    dataIndex: 'identificador_masivo',
  },
];

const initModalState = {
  onFinish: async () => {},
  visible: false,
  record: undefined,
};

const initSelectors = {
  proyecto: undefined,
  tipo_contrato: undefined,
  cargo: undefined,
  termino: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case 'project':
      return { ...initSelectors, proyecto: action.payload };
    case 'ct':
      return {
        ...initSelectors,
        proyecto: state.proyecto,
        tipo_contrato: action.payload,
      };
    case 'role':
      return {
        ...state,
        cargo: action.payload,
        termino: initSelectors.termino,
      };
    case 'term':
      return { ...state, termino: action.payload };

    default:
      return initSelectors;
  }
}

function ContractModificationsDirectorSelectors({ match, setQuery }) {
  const [state, dispatch] = useReducer(reducer, initSelectors);

  useEffect(() => {
    if (!match.params.type || state[match.params.type]) {
      let q = '';
      if (q === '' && state.termino) {
        q = `filter[termino]=${state.termino}`;
      }
      if (q === '' && state.cargo) {
        q = `filter[cargo]=${state.cargo}&filter[tipo_contrato]=${state.tipo_contrato}`;
      }
      if (q === '' && state.tipo_contrato) {
        q = `filter[tipo_contrato]=${state.tipo_contrato}&filter[proyecto]=${state.proyecto}`;
      }
      if (q === '' && state.proyecto) {
        q = `filter[proyecto]=${state.proyecto}`;
      }
      setQuery({ query: q, param: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="box-description" key="vdd-selectors">
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Administración:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            endpointFetch={async () => {
              return { data: { results: [] } };
            }}
          />
        </Col>
      </Row>
      <Row className="box-selectors">
        <Col span={6}>
          <Title level={4}>Proyecto a revisar:</Title>
        </Col>
        <Col span={17} offset={0.5}>
          <CustomSelector
            onChange={(value) => dispatch({ type: 'project', payload: value })}
            initialValue={
              match.params.type && match.params.type === 'project'
                ? parseInt(match.params.id)
                : undefined
            }
            onLoad={(value) => {
              if (match.params.type === 'project')
                dispatch({
                  type: 'project',
                  payload: value ? value.id : undefined,
                });
            }}
            selectorProps={{
              style: { width: '100%' },
              placeholder: 'Seleccione el proyecto a revisar...',
            }}
            endpointFetch={async () => {
              return await getProjects();
            }}
            key={match.url}
          />
        </Col>
      </Row>
      {state.proyecto && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Tipo de contrato:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'ct', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'ct'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'ct')
                  dispatch({
                    type: 'ct',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el tipo de contrato a revisar... ',
              }}
              endpointFetch={async () => {
                return await getContractTypes({
                  'filter[proyecto]': state.proyecto,
                });
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
      {state.tipo_contrato && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo/rol a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'role', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'role'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'role')
                  dispatch({
                    type: 'role',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo / Rol a revisar... ',
              }}
              endpointFetch={async () => {
                return await getRoles({
                  'filter[proyecto]': state.proyecto,
                  'filter[tipo_contrato]': state.tipo_contrato,
                });
              }}
              key={`rs-${state.tipo_contrato}`}
            />
          </Col>
        </Row>
      )}
      {state.cargo && (
        <Row className="box-selectors" align="middle">
          <Col span={6}>
            <Title level={4} style={{ paddingRight: '15px' }}>
              Identificador del término a revisar:
            </Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) => dispatch({ type: 'term', payload: value })}
              initialValue={
                match.params.type && match.params.type === 'term'
                  ? parseInt(match.params.id)
                  : undefined
              }
              onLoad={(value) => {
                if (match.params.type === 'term')
                  dispatch({
                    type: 'term',
                    payload: value ? value.id : undefined,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder:
                  'Seleccione el identificador único del termino de referencia... ',
              }}
              endpointFetch={async () => {
                return await getTerms({
                  'filter[cargo]': state.cargo,
                  'filter[tipo_contrato]': state.tipo_contrato,
                });
              }}
              key={`rs-${state.cargo}`}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

function ReturnModal({ id, onFinish, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  if (!id) return null;

  async function onSelfFinish(values) {
    setLoading(id);
    await onFinish(values);
    setLoading();
  }

  return (
    <Modal
      centered
      okText="Enviar"
      cancelText="volver"
      title={<Title level={4}>Devolver</Title>}
      closable={false}
      visible={true}
      footer={
        <>
          <CustomButton
            text="Cancelar"
            type="default"
            htmlType="button"
            onClick={onCancel}
          />
          <CustomButton
            text="Devolver"
            type="primary"
            className="background-bigaro"
            htmlType="button"
            loading={loading === id}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSelfFinish}>
        <Form.Item
          label="Selección del rol que revisará"
          name="rol"
          rules={[{ required: true }]}
        >
          <CustomSelector
            endpointFetch={async () => {
              return await getGroups(id);
            }}
            key={`rs_${id}`}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['rol']}>
          {({ getFieldValue, setFieldsValue }) => {
            const group = getFieldValue('rol');
            setFieldsValue({
              usuario_destino: undefined,
            });
            return (
              <Form.Item
                label="Selección de la persona que revisará"
                name="usuario_destino"
                rules={[{ required: true }]}
              >
                <CustomSelector
                  endpointFetch={async () => {
                    if (!group) return { data: { results: [] } };
                    return await getUsers(id, group);
                  }}
                  disabled={!group}
                  key={`rs_${id}_${group}`}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Justifique la devolución de esta modificación contractual"
          name="observacion"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function RejectModal({ record, visible, onFinish, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function onSelfFinish(values) {
    setLoading(record.id);
    onFinish(values);
  }

  if (!record) return null;

  return (
    <Modal
      centered
      title={<Title level={4}>Rechazar</Title>}
      closable={false}
      visible={visible}
      footer={
        <>
          <CustomButton
            type="default"
            htmlType="button"
            text="Cancelar"
            onClick={onCancel}
          />
          <CustomButton
            type="danger"
            text="Rechazar"
            htmlType="button"
            loading={loading === record.id}
            onClick={() => {
              form.submit();
            }}
          />
        </>
      }
    >
      {record.identificador_masivo && (
        <p>
          Este registro pertenece a la modificación contractual masiva:
          {record.identificador_masivo}. Por lo anterior, le recomendamos usar
          el Rechazo masivo.
        </p>
      )}
      <p>Por favor justifique su rechazo</p>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSelfFinish}
        initialValues={{ observacion: record.observaciones_director }}
      >
        <Form.Item
          label="Justifique el rechazo de esta liquidación"
          name="observacion"
          rules={[{ required: true, message: 'Ingrese una justificación' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function ContractModificationsDirector({ match }) {
  const [query, setQuery] = useState({ q: '', param: {} });
  const [counters, setCounters] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const [activeTab, setActiveTab] = useState('review');
  const [massiveLoad, setMassiveLoad] = useState(false);

  const [modalState, setModalState] = useState(initModalState);

  const [loadingIDs, setLoadingIDs] = useState([]);

  const [reloadReview, setReloadReview] = useState(false);

  const [returnID, setReturnID] = useState(undefined);

  async function onMassiveSign() {
    async function onOk() {
      setMassiveLoad(true);
      try {
        const res = await signMassive({
          ...query.param,
        });
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setMassiveLoad(false);
    }

    if (counters.pending > 0) {
      Modal.confirm({
        title: `Se firmarán ${counters.pending} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  function onSelectedSign() {
    async function onOk() {
      const data = {
        validaciones: selected.map((x) => x.id),
        proyecto: query.param.proyecto,
      };
      setLoadingIDs(data.validaciones);
      try {
        const res = await signSelected(data, { ...query.param });
        if (res.data.result === false)
          throw new Error('La firma masiva no resulto correctamente');
        changeQuery(query, 'approved');
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
      setLoadingIDs([]);
    }

    if (selected.length > 0) {
      Modal.confirm({
        title: `Se firmarán ${selected.length} registros.`,
        content: '¿Está seguro(a)?',
        onOk,
        cancelText: 'No',
        okText: 'Sí',
      });
    }
  }

  async function changeQuery(
    q = { query: '', param: undefined },
    newTab = undefined,
  ) {
    try {
      const res = await getCounters(`/?${q.query}`);
      if (q !== query) setQuery(q);
      setCounters(res.data.results);
      if (newTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setCounters(undefined);
    }
  }

  async function onSave(record) {
    try {
      setLoadingIDs([record.id]);
      await sign(record.id);
      setLoadingIDs([]);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('approved');
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onRejectedStatusSave(id, values) {
    try {
      await reject(id, values);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('review');
      setReloadReview({});
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  async function onReturn(values) {
    try {
      setLoadingIDs([returnID]);
      await doReturn(returnID, values);
      const res = await getCounters(`/?${query.query}`);
      setCounters(res.data.results);
      setActiveTab('review');
      setReloadReview({});
      setLoadingIDs([]);
      setReturnID();
    } catch (err) {
      setLoadingIDs([]);
      setReturnID();
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }

  const rowSelectionToCheck = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelected(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelected(selectedRows);
    },
  };

  const extraColumnsToCheck = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space split={<Divider type="vertical" />}>
            <CustomButton
              type="primary"
              text={
                <Link
                  to={`${INTERNAL_LINKS.MODIFICATION_DIRECTOR_DOCUMENTS}/${record.id}`}
                >
                  Ver Documentos
                </Link>
              }
            />
            <Popconfirm
              onConfirm={() => {
                onSave(record);
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton
                type="default"
                text="Firmar"
                loading={loadingIDs.includes(record.id)}
                disabled={loadingIDs.length > 0}
              />
            </Popconfirm>
            <Popconfirm
              onConfirm={() => {
                setModalState({
                  visible: true,
                  onFinish: async (values) => {
                    await onRejectedStatusSave(record.id, values);
                    setModalState(initModalState);
                  },
                  record: record,
                });
              }}
              title={MESSAGES.MSG_SAVE_STATE}
            >
              <CustomButton type="danger" text="Rechazar" />
            </Popconfirm>
            {!record.identificador_masivo && (
              <Popconfirm
                onConfirm={() => {
                  setReturnID(record.id);
                }}
                title={MESSAGES.MSG_SAVE_STATE}
              >
                <CustomButton
                  type="primary"
                  text="Devolver"
                  loading={returnID === record.id}
                  disabled={loadingIDs.length > 0}
                  className="background-bigaro"
                />
              </Popconfirm>
            )}
            <CustomButton
              type="default"
              htmlType="button"
              text={
                <Link
                  to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${record.contrato_id}/${record.proyecto_id}/${record.cargo_id}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver históricos contractuales
                </Link>
              }
            />
          </Space>
        );
      },
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <CustomButton
            type="primary"
            text={
              <Link
                to={`${INTERNAL_LINKS.MODIFICATION_DIRECTOR_DOCUMENTS}/${record.id}`}
              >
                Ver Documentos
              </Link>
            }
          />
        );
      },
    },
  ];

  const extraColumnsRejected = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Motivo"
            onClick={() => {
              Modal.info({
                title: 'Motivo Rechazo',
                centered: true,
                content: (
                  <Input.TextArea
                    rows={4}
                    value={record.observaciones_director}
                    disabled
                  />
                ),
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Firma de modificaciones contractuales de Contratistas y Proveedores
      </Title>
      <ContractModificationsDirectorSelectors
        match={match}
        setQuery={changeQuery}
      />

      {counters ? (
        <>
          <Descriptions
            bordered
            labelStyle={{ display: 'block', textAlign: 'center' }}
            contentStyle={{ display: 'block', textAlign: 'center' }}
            style={{ marginTop: '15px' }}
            layout="vertical"
            column={2}
          >
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<EditOutlined />}
                  text={
                    <>
                      Firmar masivos{' '}
                      <Badge
                        count={counters.pending}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onMassiveSign}
                  loading={massiveLoad}
                  disabled={
                    counters.pending < 1 ||
                    activeTab !== 'review' ||
                    !(
                      query.param.proyecto ||
                      query.param.cargo ||
                      query.param.tipo_contrato ||
                      query.param.termino
                    )
                  }
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar masivos</Text> por favor
                seleccione los filtros de Proyecto, Cargo/Rol o Tipo de contrato
                e Identificador del término a revisar. El número indica los
                registros que serán firmados.
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  type="default"
                  size="large"
                  icon={<SelectOutlined />}
                  text={
                    <>
                      Firmar seleccionados{' '}
                      <Badge
                        count={selected.length}
                        className="site-badge-count-4"
                        showZero={true}
                      />
                    </>
                  }
                  htmlType="button"
                  onClick={onSelectedSign}
                  loading={massiveLoad || loadingIDs.length > 0}
                  disabled={selected.length < 1 || activeTab !== 'review'}
                />
              }
            >
              <Text>
                Para habilitar <Text strong>Firmar seleccionados</Text>, por
                favor seleccione los registros deseados de la tabla a
                continuación. El número indica los registros que serán firmados.
              </Text>
            </Descriptions.Item>
          </Descriptions>
          {massiveLoad ? (
            <Skeleton active loading={true} />
          ) : (
            <Tabs
              style={{ marginTop: 15 }}
              defaultActiveKey={'review'}
              activeKey={activeTab}
              onTabClick={(key, _) => {
                setActiveTab(key);
              }}
              destroyInactiveTabPane={true}
              type="card"
              key={`vdd-home-tabs-${query.query}`}
            >
              <TabPane
                tab={
                  <Paragraph>
                    Por revisar
                    <Badge showZero count={counters.pending} />
                  </Paragraph>
                }
                key="review"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsToCheck]}
                  tableTitle={'Listado de contratistas para revisar'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__isnull]=True`}
                  rowSelection={rowSelectionToCheck}
                  reloadSource={reloadReview}
                  searchInput
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados
                    <Badge showZero count={counters.approved} />
                  </Paragraph>
                }
                key="approved"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsApproved]}
                  tableTitle={'Listado de contratistas aprobados'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__sigla]=CT-MC-D-A`}
                  searchInput
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Rechazados
                    <Badge showZero count={counters.rejected} />
                  </Paragraph>
                }
                key="rejected"
              >
                <AjaxTable
                  columns={[...columns, ...extraColumnsRejected]}
                  tableTitle={'Listado de contratistas rechazados'}
                  endpoint={filterAjaxTable}
                  query={`/?${query.query}&filter[estado_director__sigla]=CT-MC-D-R`}
                  searchInput
                />
              </TabPane>
            </Tabs>
          )}
        </>
      ) : (
        <Skeleton active loading={true} />
      )}
      <RejectModal
        visible={modalState.visible}
        onFinish={modalState.onFinish}
        onCancel={() => {
          setModalState(initModalState);
        }}
        record={modalState.record}
        key={modalState?.record?.id || 'no-key'}
      />
      <ReturnModal
        id={returnID}
        onFinish={onReturn}
        onCancel={() => setReturnID()}
      />
    </>
  );
}
