import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll, get } from '../../api/module/identities_types';

import './style.scss';

const SelectIdentifitiesType = ({
  className = '',
  onChange = () => {},
  tipo_identificacion = null,
  disabled = false,
}) => {
  const [data, setData] = useState(null);

  const getIdentities = () => {
    getAll().then((res) => setData(res.data.results));
  };

  const getIdentityName = () => {
    get(tipo_identificacion).then((res) => {
      setData([res.data]);
    });
  };

  useEffect(() => {
    if (disabled) {
      if (tipo_identificacion) getIdentityName();
    } else getIdentities();
  }, []);

  return (
    <Select
      placeholder="Seleccione el tipo de identificación"
      className={className}
      onChange={onChange}
      defaultValue={tipo_identificacion}
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {data != null &&
        data.map((item) => (
          <Select.Option key={`identity-type-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
export default SelectIdentifitiesType;
