import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Typography, Descriptions, Row, Col } from 'antd';

import { CustomButton } from '../../../components';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import ContractSearch from './ContractSearch';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import {
  get as getCTM,
  getNewInfo,
} from '../../../api/module/contract_modifications';
import { catchException } from '../../../utils/helpers/helpers';
import ModulesHome from './ModulesHome';

const { Title } = Typography;

const ContractModificationsForm = ({ match }) => {
  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
    },
  ];
  const [project_id] = useState(parseInt(match.params.proyecto));
  const [ct_mod_id] = useState(match.params.ctm);

  const [initialValues, setInitialValues] = useState(undefined);
  const [startValues, setStartValues] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        if (project_id && !ct_mod_id) {
          const { data } = await getNewInfo(project_id);
          setStartValues(data);
        } else {
          const { data } = await getCTM(ct_mod_id);
          setInitialValues(data);
        }
      } catch (error) {
        catchException(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        MÓDULO MODIFICACIONES CONTRACTUALES
      </Title>
      {startValues || initialValues ? (
        <>
          {!initialValues && (
            <>
              <Title level={3}>PROYECTO: {startValues?.project_name}</Title>
              <ContractSearch
                project_id={project_id}
                positions={startValues.positions}
                next={(record) =>
                  setInitialValues({
                    ...record,
                    id: undefined,
                    contrato: record.id,
                    project_name: startValues?.project_name,
                    modules: startValues?.modules,
                  })
                }
              />
            </>
          )}
          {initialValues && (
            <>
              <Descriptions
                layout="horizontal"
                column={2}
                bordered
                labelStyle={{ maxWidth: '5rem' }}
              >
                <Descriptions.Item label="Contratista / Proveedor" span={2}>
                  <Row align="middle">
                    <Col flex="auto">{initialValues.contratista_nombre}</Col>
                    <Col flex="0 0">
                      <CustomButton
                        htmlType="button"
                        text={
                          <Link
                            to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${initialValues.contrato}/${initialValues.proyecto_id}/${initialValues.cargo_id}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver históricos contractuales
                          </Link>
                        }
                      />
                    </Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label="Proyecto" span={2}>
                  {initialValues.project_name}
                </Descriptions.Item>
                <Descriptions.Item label="Cargo/Rol" span={2}>
                  {initialValues.cargo_nombre}
                </Descriptions.Item>
                <Descriptions.Item label="No. de contrato">
                  {initialValues.contrato_num}
                </Descriptions.Item>
                <Descriptions.Item label="Cantidad de modificaciones realizadas">
                  {initialValues.mod_num ?? 0}
                </Descriptions.Item>
              </Descriptions>
              <ModulesHome
                project_id={project_id}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
              />
            </>
          )}
        </>
      ) : (
        <Spin spinning={true} style={{ width: '100%' }}></Spin>
      )}
      <br />
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        text={
          <Link to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`}>Volver</Link>
        }
      />
    </div>
  );
};

export default ContractModificationsForm;
