import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Typography, notification, Space, Layout } from 'antd';

import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import BreadcrumbContainer from '../../containers/Breadcrumb';

import {
  ajaxTable as filterAnnouncements,
  put,
} from '../../api/module/announcement';
import { filter as filterStatus } from '../../api/module/status_process';
import { getAll as getAllProjects } from '../../api/module/projects';

import './style.scss';
import AjaxTable from '../../components/AjaxTable';

const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias en curso',
  },
];

const AnnouncementProgressList = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [reloadSource, setReloadSource] = useState(false);

  const statusChange = useCallback(async (id, announcement_type) => {
    try {
      setLoading(true);
      const response = await filterStatus('/?filter[sigla]=CV-F');
      await finish(id, response.data.results[0].id, announcement_type);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    }
  }, []);

  const finish = async (id, process_id, announcement_type) => {
    try {
      let data = {
        estado_convocatoria: process_id,
        tipo: announcement_type,
      };

      const response = await put(id, data);

      if ([200, 201, 202, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });

        setReloadSource(true);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_DELETE_MSG,
        duration: 2,
      });
    }
  };

  const [sortColumn, setSortColumn] = useState('-numero');
  const columns = [
    {
      title: 'Tipo convocatoria',
      dataIndex: 'tipo',
      key: 'tipo',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('tipo');
        if (sortOrder === 'descend') setSortColumn('-tipo');
      },
      render: (text, record) => {
        if (record.tipo) {
          return 'Abierta';
        } else {
          return 'Cerrada';
        }
      },
    },
    {
      title: 'Proyecto',
      dataIndex: 'nombre_proyecto',
    },
    {
      title: 'Organización',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('organizacion_nombre');
        if (sortOrder === 'descend') setSortColumn('-organizacion_nombre');
      },
    },
    {
      title: 'No. convocatoria',
      dataIndex: 'numero',
      key: 'numero',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('numero');
        if (sortOrder === 'descend') setSortColumn('-numero');
      },
    },
    {
      title: 'Nombre convocatoria',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre');
        if (sortOrder === 'descend') setSortColumn('-nombre');
      },
    },
    {
      title: 'Fecha inicio',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_inicio');
        if (sortOrder === 'descend') setSortColumn('-fecha_inicio');
      },
    },
    {
      title: 'Fecha fin',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_fin');
        if (sortOrder === 'descend') setSortColumn('-fecha_fin');
      },
    },
    {
      title: 'Cantidad de Cargo / Rol',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('total');
        if (sortOrder === 'descend') setSortColumn('-total');
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            loading={loading}
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${
                  record.tipo === true ? 1 : 0
                }/${record.id}`}
              >
                Editar
              </Link>
            }
          />
          <CustomButton
            block
            type={'default'}
            className={'ant-btn-info'}
            htmlType="button"
            loading={loading}
            text={
              <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT_DETAILS}/${record.id}`}>
                Detalle
              </Link>
            }
          />
          <CustomButton
            block
            danger
            type={'default'}
            htmlType="button"
            loading={loading}
            text={
              <Popconfirm
                title={MESSAGES.END_CONFIRM_MSG}
                okText="Si"
                cancelText="No"
                onConfirm={() => statusChange(record.id, record.tipo)}
              >
                Terminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  const back = () => {
    history.push(INTERNAL_LINKS.ANNOUNCEMENT);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Convocatorias en curso
      </Title>
      <AjaxTable
        columns={columns}
        pageSize={10}
        scroll={{ x: 1400 }}
        tableTitle={'Listado de convocatorias en curso'}
        endpoint={filterAnnouncements}
        sortBy={sortColumn}
        reloadSource={reloadSource}
        searchInput
        exportCSV
        query={'?filter[estado_convocatoria__sigla]=CV-C'}
      />
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </Content>
  );
};

export default AnnouncementProgressList;
