import React, { useState } from 'react';
import { Button, Input, Upload, Alert } from 'antd';
import {
  PlusOutlined,
  RollbackOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

const BetterUpload = ({
  value,
  onChange = (file) => {},
  single = true,
  suffix,
  pdf = false,
  uploadText = 'Cargar',
  changeText = 'Cambiar',
  asInput = true,
  ...props
}) => {
  const [errorDetail, setErrorDetail] = useState();

  const validateFile = (file, _fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setErrorDetail(
        `${file.name} es superior a 2MB. El tamaño máximo por archivo es de 2MB.`,
      );
      return Upload.LIST_IGNORE;
    }
    if (!pdf && !file.type.includes('image/')) {
      setErrorDetail(`${file.name} debe ser una imagen.`);
      return Upload.LIST_IGNORE;
    }
    if (pdf && file.type !== 'application/pdf') {
      setErrorDetail(`${file.name} debe ser un pdf`);
      return Upload.LIST_IGNORE;
    }
    setErrorDetail();
    return false;
  };

  const onSelfChange = (e) => {
    onChange(
      single
        ? e.fileList[0]?.originFileObj
        : e.fileList.map((v) => v.originFileObj),
    );
  };

  return (
    <Upload
      beforeUpload={validateFile}
      listType={asInput ? 'text' : 'picture-card'}
      maxCount={1}
      defaultFileList={
        value
          ? single
            ? [
                {
                  url: value,
                },
              ]
            : [...value].map((v) => ({ url: v }))
          : []
      }
      onChange={onSelfChange}
      accept={pdf ? 'application/pdf' : 'image/*'}
      showUploadList={!asInput}
      {...props}
    >
      {asInput ? (
        <Input
          value={value?.name}
          prefix={value?.name && <PaperClipOutlined />}
          suffix={
            <Button htmlType="button">{`${value ? changeText : uploadText} ${
              suffix ? suffix : ''
            }`}</Button>
          }
        />
      ) : (
        <div style={{ zIndex: 1 }}>
          {value ? <RollbackOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8, fontWeight: 500 }}>
            {`${value ? changeText : uploadText} ${suffix ? suffix : ''}`}
          </div>
        </div>
      )}
      {errorDetail && (
        <>
          <br></br>
          <Alert showIcon message={errorDetail} type="error" />
          <br></br>
        </>
      )}
    </Upload>
  );
};

export default BetterUpload;
