import React, { useEffect, useState } from 'react';
import { Table, Typography, Skeleton } from 'antd';

import AnnouncementDetailsForm from '../../containers/AnnouncementDetailsForm/index';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';

import { get } from '../../api/module/announcement';
import { filter } from '../../api/module/announcement_reject';

import './style.scss';

const { Title, Paragraph } = Typography;

const AnnouncementRejected = ({ history, match }) => {
  const [announcement_id] = useState(match.params.announcement_id);
  const [dataSource, setDataSource] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const breadcrumbItems = [
    {
      text: 'Convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
    },
    {
      text: 'Convocatorias en curso',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`,
    },
    {
      text: 'Detalle convocatoria',
      to: `${INTERNAL_LINKS.ANNOUNCEMENT_DETAILS}/${announcement_id}`,
    },
    {
      text: 'Ver listado de rechazos',
    },
  ];

  const getAnnouncementRejected = async () => {
    setLoadingDetail(true);
    const response = await filter(`?filter[convocatoria]=${announcement_id}`);
    setDataSource(response.data.results);
    setLoadingDetail(false);
  };

  const getAnnouncement = async () => {
    setLoading(true);
    const response = await get(announcement_id);
    response.data.tipo = response.data.tipo === true ? 1 : 0;
    setInitialValues(response.data);
    setLoading(false);
  };
  useEffect(() => {
    try {
      Promise.all([getAnnouncement(), getAnnouncementRejected()]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const columns = [
    {
      title: 'Nombre(s)',
      dataIndex: ['tercero_data', 'primer_nombre_razon_social'],
      key: ['tercero_data', 'primer_nombre_razon_social'],
    },
    {
      title: 'Apellidos',
      dataIndex: ['tercero_data', 'primer_apellido'],
      key: ['tercero_data', 'primer_apellido'],
    },
    {
      title: 'Motivo Rechazo',
      dataIndex: 'motivo',
      key: 'motivo',
    },
  ];

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Rechazos de convocatoria
      </Title>
      {initialValues != null && (
        <Skeleton loading={loadingDetail}>
          <AnnouncementDetailsForm
            initialValues={initialValues}
            announcementId={announcement_id}
          />
        </Skeleton>
      )}
      <SpinLoad loading={loading}>
        <Table
          rowKey="id"
          scroll={{ x: 400 }}
          dataSource={dataSource}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de rechazos
            </Paragraph>
          )}
        />
      </SpinLoad>
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </>
  );
};

export default AnnouncementRejected;
