import React, { useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { Input, Table, Popconfirm, Typography, notification } from 'antd';

import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import { get as getProject } from '../../api/module/projects';
import { getAll as getCostsCenter } from '../../api/module/costs';
import {
  post as postProjectCostCenter,
  getAll as getAllProjectCostCenter,
  remove as deleteProjectCostCenter,
} from '../../api/module/project_cost_center';

const { Title } = Typography;

/* Component that show Cost Center and adds them*/
const ProjectCostCenterSelectTable = memo(
  ({ dataSource, loading, rowSelection }) => {
    const columns = [
      {
        title: 'Centro de Costos',
        dataIndex: 'nombre',
        key: 'nombre',
      },
    ];

    return (
      <>
        <SpinLoad loading={loading}>
          <Table
            bordered
            size="small"
            rowKey="id"
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </SpinLoad>
      </>
    );
  },
);

const ProjectCostCenterTable = ({
  dataSource,
  loading,
  deleteDataSource,
  costCenter,
}) => {
  let CostCenterName = (id) => {
    const findCostCenter = costCenter.find((item) => item.id == id);
    if (findCostCenter != null) {
      const costCenterName = findCostCenter.nombre;
      return costCenterName;
    }
  };

  const columns = [
    {
      title: 'Centro de Costo',
      dataIndex: 'centro_costo',
      render: (_, record) =>
        dataSource.length >= 1 ? CostCenterName(record.centro_costo) : null,
    },
    {
      title: 'ACCIÓN',
      dataIndex: 'accion',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <div className="box-btn-center">
            <CustomButton
              danger="true"
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSource(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          </div>
        ) : null,
    },
  ];

  return (
    <>
      <SpinLoad loading={loading}>
        <Table
          bordered
          rowKey="id"
          scroll={{ x: 400 }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </SpinLoad>
    </>
  );
};

/* Main Component */
export default memo(function ProjectCostCenter({ match, history }) {
  const [userID, setUserID] = useState(parseInt(window.localStorage['userId']));
  const [projectID, setProjectID] = useState(parseInt(match.params.project_id));
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [project, setProject] = useState(null);
  const [costCenter, setCostCenter] = useState([]);
  const [newCostCenter, setNewCostCenter] = useState(null);
  const [projectCostCenter, setProjectCostCenter] = useState([]);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [cost, setCost] = useState(null);

  /* function shows data by ID */
  const dataSourceID = useCallback(
    (data) => {
      const findDataUserbyID = data.filter((item) => item.usuario == userID);
      const findDatabyID = findDataUserbyID.filter(
        (item) => item.proyecto == projectID,
      );
      return findDatabyID;
    },
    [userID, projectID],
  );

  const getProjectCostCenters = () => {
    try {
      setSpinLoad(true);
      getAllProjectCostCenter().then((res) => {
        setProjectCostCenter(dataSourceID(res.data.results));
        setSpinLoad(false);
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProjectCostCenters();
  }, []);

  const handleGetCostsCenter = () => {
    try {
      setSpinLoad(true);
      getCostsCenter().then((res) => {
        setCostCenter(res.data.results);
        setSpinLoad(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectData = () => {
    try {
      setSpinLoad(true);
      getProject(projectID).then((res) => {
        setProject(res.data);
        setSpinLoad(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectData();
    handleGetCostsCenter();
    if (projectCostCenter != null) {
      setOptions(3 - parseInt(projectCostCenter.length));
    }
  }, [projectCostCenter]);

  // selected button table
  const onSelectChange = (selected) => {
    if (selected.length - 1 < options) {
      setSelected(selected);
      setDisabled(false);
    } else if (options == 0) {
      notification.info({
        message: 'Info',
        description: `No puede seleccionar más opciones`,
        duration: 4,
      });
    } else {
      setDisabled(true);
      notification.info({
        message: 'Info',
        description: `Solo se puede seleccionar ${options} opciones`,
        duration: 4,
      });
    }
  };

  const rowSelection = {
    selected,
    onChange: onSelectChange,
  };

  const addCostCenterToProject = async () => {
    try {
      if (selected.length - 1 < options) {
        let response;
        for (let i = 0; i < selected.length; i++) {
          const data = {
            usuario: userID,
            proyecto: projectID,
            centro_costo: parseInt(selected[i]),
          };
          setLoading(true);
          response = await postProjectCostCenter(data);
        }
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            window.location.reload();
            setLoading(false);
          }, MESSAGES.TIME_RELOAD);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const deleteDataSource = useCallback(
    async (id) => {
      try {
        const response = await deleteProjectCostCenter(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
          setProjectCostCenter(
            projectCostCenter.filter((item) => item.id !== id),
          );
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [projectCostCenter],
  );

  useEffect(() => {
    // aggregate data filtering
    let newCostCenter = [...costCenter];
    let newProjectCostCenter = [...projectCostCenter];
    const newArray = [];
    for (let i = 0; i < newCostCenter.length; i++) {
      let equal = false;
      for (let j = 0; (j < newProjectCostCenter.length) & !equal; j++) {
        if (newCostCenter[i]['id'] == newProjectCostCenter[j]['centro_costo']) {
          equal = true;
        }
      }
      if (!equal) newArray.push(newCostCenter[i]);
    }
    setNewCostCenter(newArray);
  }, [projectCostCenter, costCenter]);

  return (
    <div>
      <div className="box-breadcrumb"></div>
      <div className="box-title">
        <Title>Módulo de asignación de Centros de Costos por Proyecto</Title>
        <Title level={3}>Proyecto: {project != null && project.nombre}</Title>
      </div>
      <div className="box-description">
        A continuación, podrá seleccionar máximo 3 centros de costo para el
        proyecto seleccionado
      </div>
      <div className="box-table">
        <ProjectCostCenterSelectTable
          dataSource={newCostCenter}
          loading={spinLoad}
          rowSelection={rowSelection}
        />

        <div className="box-btn-center box__btn-t-b">
          <CustomButton
            loading={loading}
            text="Agregar"
            onClick={addCostCenterToProject}
            disabled={projectCostCenter.length >= 3 || disabled}
          />
        </div>

        <ProjectCostCenterTable
          dataSource={projectCostCenter}
          loading={spinLoad}
          deleteDataSource={deleteDataSource}
          costCenter={costCenter}
        />

        <div className="box-btn-center">
          <CustomButton
            danger="true"
            text={<Link to={INTERNAL_LINKS.PROJECTS}>Volver</Link>}
          />
        </div>
      </div>
    </div>
  );
});
