import React, { useState, useEffect } from 'react';
import { notification, Skeleton } from 'antd';

import OrganizationTypeForm from '../../../containers/OrganizationTypeForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/organizations_types';

const OrganizationTypeForms = ({ history, match }) => {
  const [organizationTypeID] = useState(
    parseInt(match.params.id),
  );
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Creación de Tipos de Organizaciones');
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      setLoading(true);

      if (!organizationTypeID) {
        response = await post(data);
      } else {
        response = await put(organizationTypeID, data);
      }

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.ORGANIZATIONS_TYPES);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      if (organizationTypeID) {
        get(organizationTypeID).then((res) => setData(res.data));
        setTitle('Actualización de Tipos de Organizaciones');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    handleGetData();
  }, []);

  return (
    <Skeleton loading={data === null}>
      <OrganizationTypeForm
        title={title}
        onFinish={save}
        initialValues={data}
        loading={loading}
      />
    </Skeleton>
  );
};

export default OrganizationTypeForms;
