import React, { useEffect, useState } from 'react';
import {
  Modal,
  DatePicker,
  Typography,
  Form,
  Input,
  Row,
  Col,
  notification,
} from 'antd';

import MESSAGES from '../../enums/Messages';

import { postTemplateType } from '../../api/module/templates';

const { Title } = Typography;
const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const LoadTemplateProcessForm = ({
  isModalNewVisible,
  setIsModalNewVisible,
  hiring_type_id,
  person_type_id,
  contract_type_id,
  onRefresh,
}) => {
  const [form] = Form.useForm();
  const [templateCode, setTemplateCode] = useState(null);
  const [templateName, setTemplateName] = useState(null);

  const onFinish = async (data) => {
    try {
      let formData = new FormData();
      data.codigo_pj = data.codigo + 'PJ';

      for (let prop in data) {
        if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
      }

      formData.append('tipo_contratacion', hiring_type_id);
      formData.append('etapa_contratacion', 2);
      formData.append('tipo_contrato', contract_type_id);
      /*formDataP.append('tipo_plantilla', response.data.id);*/
      if (person_type_id === 1) {
        formData.append('persona_natural', 1);
        formData.append('persona_juridica', 0);
      } else {
        formData.append('persona_natural', 0);
        formData.append('persona_juridica', 1);
      }

      formData.append('tipo_persona', person_type_id);

      const response = await postTemplateType(formData);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        await onRefresh();
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {}, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  return (
    <>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Crear plantilla
          </Title>
        }
        visible={isModalNewVisible}
        onCancel={() => setIsModalNewVisible(false)}
        okText="Crear"
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            form.setFieldsValue({ documento: '' });
            onFinish(values);
            setIsModalNewVisible(false);
          });
        }}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          validateMessages={validateMessages}
        >

            <>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    label="Código"
                    name="codigo"
                    initialValue={templateCode}
                    rules={[{ required: true, message: 'es requerido' }]}
                  >
                    <Input placeholder="Código..." allowClear  />
                  </Form.Item>
                </Col>


                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    name="nombre"
                    initialValue={templateName}
                    rules={[{ required: true, message: 'es requerido' }]}
                  >
                    <Input placeholder="Nombre..." allowClear />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Sigla"
                    name="sigla"
                    rules={[{ required: true, message: 'es requerido' }]}
                  >
                    <Input placeholder="Sigla..." allowClear  />
                  </Form.Item>
                </Col>


              </Row>
            </>


        </Form>
      </Modal>
    </>
  );
};

export default LoadTemplateProcessForm;
