import React, { useState } from 'react';
import { Button, Form, Input, Layout } from 'antd';

import { setAnnouncementCloseTime } from '../../../api/module/admin_actions';
import { CustomButton } from '../../../components';

const { Content } = Layout;

export default function AnnouncementCloseAdmin({}) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await setAnnouncementCloseTime(values.cron_time);
      setLoading(false);
      setResponse(res.data);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setResponse();
    }
  };

  return (
    <Content>
      <Form onFinish={onFinish}>
        <Form.Item name="cron_time" label="Horario de cierre en formato CRON">
          <Input />
        </Form.Item>
        <CustomButton text="Guardar" loading={loading} />
      </Form>
      <br />
      {response && JSON.stringify(response)}
    </Content>
  );
}
