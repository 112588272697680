import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Form,
  Input,
  Popconfirm,
  Space,
  Row,
  Col,
  notification,
  Skeleton,
  Card,
  Alert,
} from 'antd';

import { SelectProjects } from '../../components/SelectProjects';
import ResumeSearchViewer from '../../components/ResumeSearchViewer';
import CustomButton from '../../components/CustomButton';
import MESSAGES from '../../enums/Messages';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import INTERNAL_LINKS from '../../enums/InternalLinks';

//API Imports
import {
  getRoleByProject,
  post,
  put,
  remove,
} from '../../api/module/project_positions';

import { get as retrieveProject } from '../../api/module/projects';
import SelectProjectCategories from '../../components/SelectProjectCategories';
import AjaxTable from '../../components/AjaxTable';
import { ajaxTable as filterAjaxTable } from '../../api/module/project_positions';

const { Title } = Typography;
const { TextArea } = Input;

const breadcrumbItems = [
  {
    text: 'Definición de Cargo y Equipo de Trabajo',
  },
];

const ProjectTeamForm = ({
  project,
  cargo_data,
  setCargoData,
  setReload,
  listRef,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => form.resetFields(), [cargo_data, project]);

  const save = async (data) => {
    try {
      if (project.tipo_proyecto !== 0) {
        delete data.prueba_subcategoria;
      }
      data.proyecto = project.id;
      let response;
      if (!cargo_data) {
        setLoading(true);
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(cargo_data.id, data);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          dduration: 2,
        });
        setCargoData(undefined);
        listRef.current.scrollIntoView({ behavior: 'smooth' });
        form.resetFields();
        setReload(true);
        // setTimeout(() => {
        setLoading(false);
        // }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      // setLoading(false);
    }
  };
  return (
    <Card
      size={'small'}
      bordered={false}
      title={<Title level={3}>Cargo/Rol que desea agregar</Title>}
    >
      <Form
        form={form}
        name="nest-messages"
        layout="horizontal"
        autoComplete="off"
        onFinish={save}
        labelCol={{
          span: 7,
          style: { minWidth: 'max-content' },
        }}
        wrapperCol={{
          offset: 0,
        }}
        labelAlign="left"
        initialValues={{
          proyecto: project ? project.id : undefined,
          cargo: cargo_data ? cargo_data.cargo : undefined,
          descripcion: cargo_data ? cargo_data.descripcion : undefined,
          educacion_requerida: cargo_data
            ? cargo_data.educacion_requerida
            : undefined,
          experiencia_requerida: cargo_data
            ? cargo_data.experiencia_requerida
            : undefined,
          prueba_subcategoria:
            cargo_data && project ? cargo_data.prueba_subcategoria : undefined,
          requeridos: cargo_data ? cargo_data.requeridos : undefined,
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Alert
              style={{ marginBottom: 20 }}
              message={''}
              description={
                'Recuerde que este Cargo/Rol será el utilizado en los Términos de referencia.'
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              {...{ wrapperCol: { span: 10 } }}
              name="cargo"
              label="Cargo/Rol del proyecto"
              rules={[
                {
                  required: true,
                  message: 'Este campo es requerido',
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="descripcion"
              label="Descripción del Cargo/Rol"
              rules={[
                {
                  required: false,
                  message: 'Este campo es opcional',
                },
              ]}
            >
              <TextArea allowClear rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Alert
              style={{ marginBottom: 20 }}
              message={''}
              description={
                'Diligencie los requerimientos de educación y experiencia. Recuerde que son el insumo para la revisión documental y la realización de los términos de referencia'
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="educacion_requerida"
              label="Requerimientos de educación/formación"
              rules={[
                {
                  required: true,
                  message: 'Este campo es requerido',
                },
              ]}
            >
              <TextArea allowClear rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="experiencia_requerida"
              label="Requerimientos de experiencia"
              rules={[
                {
                  required: true,
                  message: 'Este campo es requerido',
                },
              ]}
            >
              <TextArea allowClear rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.proyecto !== currentValues.proyecto
              }
            >
              {() =>
                project && project.tipo_proyecto === 0 ? (
                  <Form.Item
                    {...{ wrapperCol: { span: 21 } }}
                    name="prueba_subcategoria"
                    label="Seleccione la prueba o subcategoría"
                    rules={[
                      {
                        required: true,
                        message: 'Este campo es requerido',
                      },
                    ]}
                  >
                    <SelectProjectCategories
                      proyecto={project.id}
                      categoria={
                        cargo_data ? cargo_data.prueba_subcategoria : null
                      }
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              {...{ wrapperCol: { span: 2 } }}
              name="requeridos"
              label="Número de personas/entidades a contratar"
              rules={[
                {
                  required: true,
                  message: 'Este campo es requerido',
                },
                () => ({
                  validator(_, value) {
                    if (value !== '' && value != null && value != undefined) {
                      if (parseInt(value) > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error(
                            'Número de personas/entidades a contratar debe ser mayor a 0',
                          ),
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <CustomButton
              className="box__space-left"
              htmlType="submit"
              loading={loading}
              text={cargo_data ? 'Guardar Cambios' : 'Crear Cargo'}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const ProjectTeamList = ({
  project,
  setCargoData,
  reload,
  setReload,
  projectRef,
}) => {
  const columns_left = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
      key: 'proyecto_nombre',
    },
    {
      title: 'Cargo/Rol del proyecto',
      dataIndex: 'cargo',
      key: 'cargo_proyecto',
    },
  ];

  const columns_right = [
    {
      title: 'Número de personas/entidades a contratar',
      dataIndex: 'requeridos',
      key: 'num_personas_contratar',
    },
    {
      title: 'Número de personas asignadas',
      dataIndex: 'asignados',
      key: 'num_personas_asignadas',
    },
    {
      title: 'Número de personas contratadas',
      dataIndex: 'contratados',
      key: 'num_personas_contratados',
    },
    {
      title: 'Número de personas rechazadas',
      dataIndex: 'rechazados',
      key: 'num_personas_rechazados',
    },
    {
      title: 'Descripción de Cargo/Rol',
      dataIndex: 'descripcion',
      key: 'descripcion_cargo',
    },
    {
      title: 'Acciones',
      key: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 210,
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            type="button"
            htmlType={'button'}
            className={'ant-btn-info'}
            text="Editar Cargo Rol"
            block
            onClick={() => {
              setCargoData(record);
              projectRef.current.scrollIntoView();
            }}
          />
          <CustomButton
            text={
              <Link to={`${INTERNAL_LINKS.WORK_TEAM}/${record.id}`}>
                Asignar/Editar personas
              </Link>
            }
          />
          <CustomButton
            danger
            type="button"
            htmlType={'button'}
            block
            text={
              <Popconfirm
                title={MESSAGES.DELETE_CONFIRM_MSG_POSITION}
                okText="Si"
                cancelText="No"
                onConfirm={() => deleteDataSource(record.id)}
              >
                Eliminar
              </Popconfirm>
            }
          />
        </Space>
      ),
    },
  ];

  const column_prueba = [
    {
      title: 'Prueba',
      dataIndex: 'prueba_nombre',
      key: 'prueba_nombre',
    },
  ];
  const [columns, setColumns] = useState(
    project.tipo_proyecto === 0
      ? [...columns_left, ...column_prueba, ...columns_right]
      : [...columns_left, ...columns_right],
  );

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataSource = () => {
    try {
      setLoading(true);
      getRoleByProject(project.id).then((res) => {
        setDataSource(res.data.results);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  // Delete Object by Id
  const deleteDataSource = useCallback(
    async (id) => {
      try {
        const response = await remove(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
        }
        setReload(!reload);
        setDataSource(dataSource.filter((item) => item.id !== id));
      } catch (err) {
        // let description = MESSAGES.DESC_ERROR_DELETE_MSG;
        let description = '';
        console.log(err);

        if (err.response.data.errors) {
          for (const item of err.response.data.errors) {
            description += `${item.detail} - `;
          }
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 4,
        });
      }
    },
    [dataSource],
  );

  const formatColumns = () => {
    setColumns(
      project.tipo_proyecto === 0
        ? [...columns_left, ...column_prueba, ...columns_right]
        : [...columns_left, ...columns_right],
    );
  };

  useEffect(() => {
    if (reload) setReload(false);
    if (project && loading === false) {
      formatColumns();
      getDataSource();
    }
  }, [project, reload]);

  return (
    <AjaxTable
      columns={columns}
      searchInput
      reloadSource={reload}
      tableTitle={'Listado de Cargo/Rol del proyecto'}
      endpoint={filterAjaxTable}
      query={'/?filter[proyecto]=' + project.id}
    />
  );
};

export default function ProjectTeam({ match, history }) {
  const [cargo_data, setCargoData] = useState(null);
  const [project, setProject] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const projectRef = useRef();
  const titleRef = useRef();
  const listRef = useRef();

  useEffect(() => {
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [project]);

  useEffect(() => {
    let isMounted = true;
    if (match.params.project_id) {
      retrieveProject(match.params.project_id)
        .then((res) => {
          if (isMounted) {
            setProject(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: 'No se pudo cargar el proyecto',
            duration: 2,
          });
          if (isMounted) {
            setLoading(false);
            setProject(null);
          }
        });
    } else {
      setLoading(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        <span ref={titleRef} />
        Definición de cargos y equipo de trabajo del proyecto
      </Title>
      <Skeleton loading={loading} active>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Selección de proyecto</Title>}
          extra={
            <CustomButton
              htmlType="button"
              text="Buscar Hoja de vida"
              onClick={() => setVisible(true)}
            />
          }
        >
          <Form.Item
            {...{
              labelCol: { ...{ span: 4 } },
              wrapperCol: { span: 20 },
            }}
            name="proyecto"
            label="Proyecto"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Este campo es requerido',
              },
            ]}
          >
            <Row
              ref={projectRef}
              style={{ width: '100%', justifyContent: 'space-between' }}
              wrap={true}
            >
              <Col xs={20} sm={20} md={20} lg={10}>
                {match.params.project_id && !project ? null : (
                  <SelectProjects
                    proyecto={project ? project.id : null}
                    onChangeData={(value) => setProject(value)}
                    disabled={!!cargo_data}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
        </Card>
        {project && loading === false && (
          <ProjectTeamForm
            project={project}
            cargo_data={cargo_data}
            setCargoData={setCargoData}
            setReload={setReload}
            listRef={listRef}
          />
        )}
        <Row gutter={12}>
          <Col span={24} ref={listRef}>
            {project && loading === false && (
              <ProjectTeamList
                loading={loading}
                project={project}
                setCargoData={setCargoData}
                reload={reload}
                setReload={setReload}
                projectRef={projectRef}
              />
            )}
          </Col>
        </Row>
        <CustomButton
          type={'default'}
          className={'back-button'}
          htmlType="button"
          onClick={() => history.push('/')}
          text="Volver"
        />
      </Skeleton>
      <ResumeSearchViewer
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}
