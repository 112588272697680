import React, {   useState, useEffect,  } from 'react';
import moment from 'moment';
import { Layout, Table, Typography,notification, } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';

import './style.scss';
import { CustomTooltip } from '../../components/CustomTooltip';
import Paragraph from 'antd/lib/typography/Paragraph';
import  {getAll,getExcel } from '../../api/module/reports';
import MESSAGES from '../../enums/Messages';


const { Title } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Descarga del programa de sincronización',
  },
];

export default function Sigaapp({ history, match }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // GetData List
  const getDataSource = () => {
    try {
      setLoading(true);
      getAll().then((res) => {
        setDataSource(res.data.results);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    getDataSource();
  }, []);


  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Descarga del programa de sincronización
      </Title>
      <CustomButton
        type={'default'}
        className={'ant-btn-info'}
        htmlType="button"
        text={
          <a
            href={`${process.env.REACT_APP_API_DOMAIN}/static/download/AppSiga.zip`}
            target="_blank"
          >
            Click para descargar
          </a>
        }
      />
    </Content>
  );
}
