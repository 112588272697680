import React, { useState, memo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Alert,
  notification,
  Space,
  Row,
  Form,
  Modal,
  Popconfirm,
  Col,
  Card,
  Typography,
} from 'antd';

import AnnouncementOpenForm from '../../containers/AnnouncementOpenForm/index';
import AnnouncementTemplateForm from '../../containers/AnnouncementTemplateForm';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import PdfViewer from '../../components/PdfViewer';
import AnnouncementTable from '../../components/TablePosition';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import {
  post,
  postDocument,
  put,
  putDocument,
  postTemplateDocument,
  postInvitations,
} from '../../api/module/announcement';
import { get as getDocumentUrl } from '../../api/module/document_repository';

const { Title } = Typography;

export const AnnouncementOpen = memo(
  ({
    initialValues,
    isLoading,
    announcementType,
    announcement_id,
    regulIcfes,
    handleGet,
  }) => {
    let history = useHistory();
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [isLoadingDoc, setIsLoadingDoc] = useState(false);
    const [isSendInvitations, setIsSendInvitations] = useState(false);
    const [externalUsers, setExternalUsers] = useState(
      initialValues != null ? initialValues.usuarios_externos : false,
    );
    const [registeredUsers, setRegisteredUsers] = useState(
      initialValues != null ? initialValues.usuarios_siga : false,
    );
    const [unknownUsers, setUnknownUsers] = useState(
      initialValues != null ? initialValues.usuarios_desconocidos : false,
    );
    const [formData] = useState(new FormData());
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [form] = Form.useForm();
    const [publicLink, setPublicLink] = useState(null);
    const [fileWasUploaded, setFileWasUploaded] = useState(false);

    const showClick = async (documento_nombre) => {
      setIsLoadingDoc(true);
      const response = await getDocumentUrl(documento_nombre);
      setPdfUrl(response.data.url);
      setShowPdf(true);
      setIsLoadingDoc(false);
    };

    const generatePublicLink = () => {
      setPublicLink(
        `${process.env.REACT_APP_HOST}${INTERNAL_LINKS.PUBLIC_ANNOUNCEMENT}/${announcement_id}`,
      );
    };

    const save = async (data, reHandle = true) => {
      try {
        setLoading(true);
        let response;
        if (data.proyecto_nombre != null && data.nombre != null) {
          if (data.anexo != null) {
            if (data.anexo.file.status != 'removed') {
              data.anexo = data.anexo.fileList[0].originFileObj;
            }
          } else {
            data.anexo = '';
          }
          data.usuarios_siga = registeredUsers;
          data.usuarios_externos = externalUsers;
          data.usuarios_desconocidos = unknownUsers;
          data.clase = regulIcfes == 0 ? true : false;
          data.tipo = announcementType && announcementType == 1 ? true : false;
          data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
          data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');

          formData.append('anexo', data);
          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          if (initialValues != null) {
            if (data.anexo != null) {
              response = await putDocument(initialValues.id, formData);
            } else {
              response = await put(initialValues.id, data);
            }
          } else {
            if (data.anexo != null) {
              response = await postDocument(formData);
            } else {
              response = await post(data);
            }
          }
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            if (reHandle)
              setTimeout(() => {
                setLoading(false);
                window.location = `${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/${
                  response.data.tipo == true ? 1 : 0
                }/${response.data.id}`;
                handleGet(response.data.id);
              }, MESSAGES.TIME);
            else return response.data;
          }
        } else {
          alert('Antes de continuar complete los campos necesarios');
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
        setLoading(false);
        return null;
      }
    };

    const saveTemplate = async (data) => {
      setErrors(false);
      try {
        setLoadingFile(true);

        if (data.plantilla != null && [data.plantilla.fileList].length >= 1) {
          if (data.plantilla.file.status != 'removed') {
            data.plantilla = data.plantilla.fileList[0].originFileObj;
          }
        } else {
          data.plantilla = '';
        }
        delete data.dirigido;
        delete data.dirigido_1;
        delete data.dirigido_2;
        delete data.users_unknown;

        data.tipo = announcementType && announcementType == 1 ? true : false;
        data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
        data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');

        formData.append('plantilla', data);
        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }

        const response = await postTemplateDocument(initialValues.id, formData);

        if ([200, 201, 204].indexOf(response.status) > -1) {
          if (response.data.success) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            setFileWasUploaded(true);
            setTimeout(() => {
              // handleGet(response.data.id);
              setLoadingFile(false);
            }, MESSAGES.TIME);
          }
        }
      } catch (exception) {
        let description = MESSAGES.DESC_ERROR_POST_MSG;

        if (exception.response) {
          if (exception.response.data.errors) {
            const errors = exception.response.data.errors;
            description = errors.shift().detail;
          }
        }

        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: description,
          duration: 8,
        });

        setLoadingFile(false);
        if (
          description !== 'El archivo ya se encuentra almacenado en el servidor'
        ) {
          setErrors(true);
        }
      }
    };

    const linkPush = () => {
      if (initialValues == null && announcement_id != null) {
        history.push(
          `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/0/?announcement_id=${announcement_id}&edit=false`,
        );
      } else {
        history.push(
          `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/0/?announcement_id=${announcement_id}&edit=true`,
        );
      }
    };

    const sendInvitations = async () => {
      setIsSendInvitations(true);
      const announcement_saved = await save(form.getFieldsValue(), false);

      if (announcement_saved) {
        try {
          const response = await postInvitations(announcement_saved.id);
          if ([200, 201, 204].indexOf(response.status) > -1) {
            let secondsToGo = 5;
            const modal = Modal.success({
              title: MESSAGES.SUCCESS_MSG,
              content: (
                <>
                  <p>{MESSAGES.DESC_SUCCESS_SEND_INVITATIONS_MSG}</p>
                  <p>Esta solicitud tardará en terminarse</p>
                </>
              ),
              onOk: () => history.push(`${INTERNAL_LINKS.ANNOUNCEMENT}`),
            });
            setTimeout(() => {
              modal.destroy();
              history.push(`${INTERNAL_LINKS.ANNOUNCEMENT}`);
            }, secondsToGo * 1000);
            setIsSendInvitations(false);
          }
        } catch (err) {
          console.log(err);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_SEND_INVITATIONS_MSG,
            duration: 2,
          });
          setIsSendInvitations(false);
        }
      } else setIsSendInvitations(false);
    };

    return (
      <SpinLoad loading={isLoading}>
        <AnnouncementOpenForm
          loading={loading}
          save={save}
          initialValues={initialValues}
          showCLick={showClick}
          linkPush={linkPush}
          announcement_id={announcement_id}
          form={form}
        />
        <PdfViewer
          pdf={pdfUrl}
          onCancel={() => setShowPdf(false)}
          visible={showPdf}
        />
        <AnnouncementTable
          announcement_id={announcement_id}
          announcementType={announcementType}
          announcementStatus={initialValues !== undefined ? initialValues.estado_lanzamiento : false}
        />
        <>
          {announcement_id && (
            <>
              <AnnouncementTemplateForm
                announcementType={announcementType}
                isLoading={loadingFile}
                saveTemplate={saveTemplate}
                initialValues={initialValues}
                setExternalUsers={setExternalUsers}
                externalUsers={externalUsers}
                setRegisteredUsers={setRegisteredUsers}
                registeredUsers={registeredUsers}
                setUnknownUsers={setUnknownUsers}
                unknownUsers={unknownUsers}
                showCLick={showClick}
                hasErrors={errors}
                fileWasUploaded={fileWasUploaded}
                formAnnouncement={form}
              />
              <Row gutter={12}>
                <Col span={24}>
                  <Card
                    size={'small'}
                    bordered={false}
                    title={
                      <Title level={3}>Información de la convocatoria</Title>
                    }
                  >
                    <Alert
                      style={{ marginTop: '10px' }}
                      type="info"
                      showIcon
                      action={
                        <CustomButton
                          type="default"
                          className={'back-button'}
                          text="Generar Link convocatoria"
                          onClick={() => {
                            generatePublicLink();
                          }}
                        />
                      }
                      description="Si desea compartir esta convocatoria con usuarios NO regsitrados en SIGA, ni referenciados, a través de redes y otras plataformas, genere el link, cópielo y péguelo donde lo requiera."
                      message={'Información'}
                    />
                    <Col span={24} style={{ marginTop: 15, marginBottom: 15 }}>
                      <a href={publicLink ? publicLink : '#'} target="_blank">
                        {publicLink && publicLink}
                      </a>
                    </Col>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={12}>
            <Col span={12}>
              <Space>
                {announcement_id && (
                  <CustomButton
                    text="Guardar convocatoria"
                    loading={loading}
                    onClick={() => form.submit()}
                  />
                )}
                {announcement_id ? (
                  <CustomButton
                    type={'default'}
                    className={'back-button'}
                    htmlType="button"
                    text={
                      <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`}>
                        Volver
                      </Link>
                    }
                  />
                ) : (
                  <CustomButton
                    type={'default'}
                    className={'back-button'}
                    htmlType="button"
                    text={
                      <Link to={`${INTERNAL_LINKS.ANNOUNCEMENT}`}>Volver</Link>
                    }
                  />
                )}
              </Space>
            </Col>
            <Col span={12}>
              {announcement_id && (
                <Space>
                  <CustomButton
                    type="default"
                    className={'ant-btn-info'}
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${announcement_id}?preview=true`}
                      >
                        Visualizar convocatoria
                      </Link>
                    }
                  />
                  {unknownUsers === false &&
                    initialValues.estado_lanzamiento === false &&
                    initialValues.total > 0 && (
                      <Popconfirm
                        placement="topLeft"
                        title="¿Está seguro(a) que los datos registrados en la convocatoria están correctos?"
                        okText="Sí"
                        cancelText="No"
                        onConfirm={sendInvitations}
                      >
                        <CustomButton
                          type="default"
                          text="Lanzar Convocatoria"
                          loading={isSendInvitations}
                        />
                      </Popconfirm>
                    )}
                </Space>
              )}
            </Col>
          </Row>
        </>
      </SpinLoad>
    );
  },
);
