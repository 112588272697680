import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/experience_areas';

export const SelectExperienceArea = ({
  className = '',
  onChange = () => {},
}) => {
  const [experienceAreas, setExperienceAreas] = useState(null);

  const getExperienceAreas = () => {
    getAll().then((response) => setExperienceAreas(response.data.results));
  };

  useEffect(() => {
    getExperienceAreas();
  }, []);

  return (
    <Select
      allowClear
      showSearch
      className={className}
      placeholder="Área de Experiencia"
      onChange={(value) => onChange(value)}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {experienceAreas != null &&
        experienceAreas.map((item) => (
          <Select.Option key={`knowledge-area-key-${item.id}`} value={item.id}>
            {item.nombre}
          </Select.Option>
        ))}
    </Select>
  );
};
