import React, { useEffect, useState } from 'react';
import {
  Alert,
  Typography,
  notification,
  Row,
  Col,
  Form,
  Select,
  Input,
  Descriptions, Skeleton, Card,
} from 'antd';
import moment from 'moment';
import 'moment/locale/es';

import CustomButton from '../../../components/CustomButton';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import TermsForm from '../../../containers/TermForm';
import { useQuery } from '../../../utils/helpers/helpers';

import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import {
  post,
  put,
  get,
  sendToContract,
  getTermAssign,
} from '../../../api/module/terms';
import { get as getProject } from '../../../api/module/projects';
import { getByTerm } from '../../../api/module/contract_type_by_term';
import {
  post as postTermComment,
  getByTerm as getTermComment,
} from '../../../api/module/term_comments';
import { getUsersByGroup } from '../../../api/module/user';
import { filter as filterStatusProcess } from '../../../api/module/status_process';

import { IsRole } from '../../../utils/helpers/helpers';

import './style.scss';

const { Title, Paragraph, Text } = Typography;

const TermsPage = ({ match, history }) => {
  let query = useQuery();
  const show = query.get('show') === 'true';
  const [isLoading, setLoading] = React.useState(false);
  const [termId] = React.useState(parseInt(match.params.id));
  const [disabled] = React.useState(query.get('show') === 'true');
  const [projectId] = React.useState(parseInt(match.params.project_id));
  const [contractId] = React.useState(parseInt(match.params.contract_id));
  const [contractType, setContractType] = React.useState(null);
  const [term, setTerm] = React.useState(null);
  const [groups, setGroups] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [statusOptions, setStatusOptions] = React.useState(null);
  const [observations, setObservations] = React.useState(null);
  const [project, setProject] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  const [formObservations] = Form.useForm();
  const [loadingObservations, setLoadingObservation] = useState(false);
  const [loadingTerm, setLoadingTerm] = useState(false);

  const [isLegal] = useState(IsRole(3));

  const [currentModule, setCurrentModule] = useState(query.get('module'));

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'Formulario de Términos de referencia',
    },
  ];

  useEffect(() => {
    if (termId > 0) {
      getContractType();
    }

    if (project == null) {
      getProjectData();
    }

    if (term == null) {
      if (termId > 0) {
        getTermData();
      } else {
        setTerm({
          id: 0,
          responsable: parseInt(window.localStorage['userId']),
        });
      }
    }
    Promise.all([getGroupsData(), getStatusProcessData(), getObservation()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getContractType = async () => {
    const response = await getByTerm(termId);
    const data = response.data.results;
    data.map((item) => setContractType(item));
  };

  const getProjectData = async () => {
    const response = await getProject(projectId);
    setProject(response.data);
  };

  const getGroupsData = async () => {
    const response = await getTermAssign(termId);
    let all_groups = response.data.results;
    setGroups(all_groups);
  };

  const getUsersData = async (id) => {
    const response = await getUsersByGroup(id);
    setUsers(response.data.results);
  };

  const getStatusProcessData = async () => {
    const response = await filterStatusProcess(`?filter[id.in]=16,33`);
    setStatusOptions(response.data.results);
  };

  const sendObservation = async (data) => {
    setLoadingObservation(true);
    try {
      data['termino'] = termId;
      await postTermComment(data);
      formObservations.resetFields();

      notification.success({
        message: MESSAGES.SUCCESS_MSG,
        description: MESSAGES.DESC_SUCCESS_MSG,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push(INTERNAL_LINKS.TDR_HOME);
      }, 1000);
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        placement: 'bottomRight',
      });
    }
    setLoadingObservation(false);
  };

  const getObservation = async () => {
    if (termId > 0) {
      const response = await getTermComment(termId);
      setObservations(response.data.results);
    }
  };

  const getTermData = async () => {
    setLoadingTerm(true);
    let response = await get(termId);
    let data = response.data;

    let term_comment = await getTermComment(termId).then(
      (res) => res.data.results,
    );

    setLoadingTerm(false);

    if (term_comment != null && term_comment.length > 0) {
      data['observacion'] = term_comment[term_comment.length - 1].observacion;
    }
    if (data['fecha_inicio'] != null) {
      data['fecha_inicio'] = moment(data['fecha_inicio']);
    }
    if (data['fecha_fin'] != null) {
      data['fecha_fin'] = moment(data['fecha_fin']);
    }
    if (data['fecha_maxima_inicio'] != null) {
      data['fecha_maxima_inicio'] = moment(data['fecha_maxima_inicio']);
    }
    if (data['fecha_maxima_fin'] != null) {
      data['fecha_maxima_fin'] = moment(data['fecha_maxima_fin']);
    }
    if (data['pais'] == null) {
      data['pais'] = 45;
    }
    if (data['estado'] == null && data['pais'] === 45) {
      data['estado'] = 3;
    }
    if (data['ciudad'] == null && data['estado'] === 3) {
      data['ciudad'] = 149;
    }
    if (data['responsable'] == null) {
      data['responsable'] = parseInt(window.localStorage['userId']);
    }
    if (data['moneda'] == null) {
      data['moneda'] = 1;
    }
    if (data.tiene_politicas != null) {
      data['tiene_politicas'] = data.tiene_politicas === true ? 1 : 0;
    }
    if (data.valor_contrato_fijo != null) {
      data['valor_contrato_fijo'] = data.valor_contrato_fijo === true ? 1 : 0;
    }
    if (data.forma_pago != null) {
      data['forma_pago'] = data.forma_pago === true ? 1 : 0;
    }
    if (data.fechas_fijas != null) {
      data['fechas_fijas'] = data.fechas_fijas === true ? 1 : 0;
    }

    if (data.documentos_adicionales != null) {
      data['documentos_adicionales'] = data.documentos_adicionales.map(
        (item) => item.tipo_plantilla,
      );
    }

    if (data.cargo != null && data.cargo !== undefined) {
      data.cargo = parseInt(data.cargo);
    }
    setTerm(response.data);
  };

  const save = async (data, review = false) => {
    try {
      setErrors([]);
      setLoading(true);
      let response;
      if (termId === 0) {
        if (data['fecha_inicio'] != null) {
          data['fecha_inicio'] = moment(data.fecha_inicio).format('YYYY-MM-DD');
        }
        if (data['fecha_fin'] != null) {
          data['fecha_fin'] = moment(data.fecha_fin).format('YYYY-MM-DD');
        }
        if (data['fecha_maxima_inicio'] != null) {
          data['fecha_maxima_inicio'] = moment(data.fecha_maxima_inicio).format(
            'YYYY-MM-DD',
          );
        }
        if (data['fecha_maxima_fin'] != null) {
          data['fecha_maxima_fin'] = moment(data.fecha_maxima_fin).format(
            'YYYY-MM-DD',
          );
        }
        if (data['valor_total'] == null) {
          data['valor_total'] = 0;
        }
        data['proyecto'] = projectId;
        data['tipo_contrato'] = contractId;
        data['estado_proceso'] = 1;

        response = await post(data);
      } else {
        if (data['fecha_inicio'] != null && data['fecha_inicio'] != undefined) {
          data['fecha_inicio'] = moment(data.fecha_inicio).format('YYYY-MM-DD');
        }
        if (data['fecha_fin'] != null && data['fecha_fin'] != undefined) {
          data['fecha_fin'] = moment(data.fecha_fin).format('YYYY-MM-DD');
        }

        if (
          data['fecha_maxima_inicio'] != null &&
          data['fecha_maxima_inicio'] != undefined
        ) {
          data['fecha_maxima_inicio'] = moment(data.fecha_maxima_inicio).format(
            'YYYY-MM-DD',
          );
        }
        if (
          data['fecha_maxima_fin'] != null &&
          data['fecha_maxima_fin'] != undefined
        ) {
          data['fecha_maxima_fin'] = moment(data.fecha_maxima_fin).format(
            'YYYY-MM-DD',
          );
        }

        if (data['tipo_contrato'] != null) {
          data['plantilla'] = contractType.id;
        }

        response = await put(termId, data);
        setTerm(response.data);
      }
      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        if (review === false) {
          setTimeout(() => {
            window.location = `${INTERNAL_LINKS.TERM}/${response.data.id}/${projectId}/${contractId}?module=${currentModule}`;
          }, 500);

          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            placement: 'bottomRight',
          });
        } else {
          onReview();
        }
      } else {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          placement: 'bottomRight',
        });
        setErrors(response.data.errors);
      }
    } catch (err) {
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        placement: 'bottomRight',
      });
      if (err.data) {
        setErrors(err.data.errors);
      }
    }
  };

  const onReview = async () => {
    setErrors([]);
    setLoading(true);
    try {
      let response = await sendToContract(termId);

      setLoading(false);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        if (!response.data.valid) {
          setErrors(response.data.errors);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_POST_MSG,
            placement: 'bottomRight',
          });
        } else {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            placement: 'bottomRight',
          });
          setTimeout(() => {
            history.push(`${INTERNAL_LINKS.TDR_HOME}`);
          }, 1000);
        }
      } else {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className="terms-page-content">
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        {contractType && (
          <div>
            <Row justify="space-between">
              <Col>
                <Title>Términos de referencia (TDR)</Title>
              </Col>
              <Col>
                <Row justify="end">
                  <Text>{contractType.codigo}</Text>
                </Row>
                <Row>
                  <Col>
                    <Text>Versión {contractType.version}</Text>
                  </Col>
                  <Col style={{ marginLeft: '5px' }}>
                    <Text style={{ textTransform: 'capitalize' }}>
                      -{' '}
                      {moment(contractType.fecha)
                        .locale('es')
                        .format('MMMM YYYY')}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Title level={3}>Proyecto: {project && project.nombre}</Title>
      </div>
      <div className="box-description">
        <Paragraph>
          Los siguientes términos de referencia complementan el texto del
          contrato firmado entre las partes y están subordinados a dicho
          contrato.
        </Paragraph>
      </div>
      <Skeleton loading={loadingTerm} active>
        {term != null && project != null && (
          <TermsForm
            project={project}
            key={term}
            term={term}
            project_id={projectId}
            contract_id={contractId}
            onSave={save}
            history={history}
            onReview={onReview}
            show={show}
            disabled={disabled}
            isLegal={isLegal}
            currentModule={currentModule}
            setCurrentModule={setCurrentModule}
            loading={isLoading}
          />
        )}
      {errors.length > 0 && (
        <Alert
          message="Errores de validación:"
          type="error"
          description={
            <ul>
              {errors.map((item) => (
                <li>{item.error}</li>
              ))}
            </ul>
          }
        />
      )}
      {term && termId > 0 && (
        <>
          <Alert
            showIcon
            type="info"
            message="Información importante"
            description="Antes de continuar por favor guarde el TDR"
          />
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>ASIGNACIÓN DE ESTADOS</Title>}
          >
            <Form
              form={formObservations}
              layout="vertical"
              onFinish={(data) => {
                sendObservation(data);
              }}
            >
              <Form.Item
                label="Selección el rol que revisará"
                name="rol"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(value) => {
                    getUsersData(value);
                  }}
                >
                  {groups &&
                    groups.map((item) => {
                      return (
                        <Select.Option value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Selección la persona que revisará"
                name="usuario_destino"
                rules={[{ required: true }]}
              >
                <Select>
                  {users &&
                    users.map((item) => (
                      <Select.Option value={item.usuario_tercero}>
                        {item.nombre_completo}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Estado para"
                name="estado_termino"
                rules={[{ required: true }]}
              >
                <Select>
                  {statusOptions &&
                    statusOptions.map((item) => {
                      if (observations) {
                        if (observations.length > 0) {
                          return (
                            <Select.Option value={item.id}>
                              {item.descripcion}
                            </Select.Option>
                          );
                        } else {
                          if (item.id === 33)
                            return (
                              <Select.Option value={item.id}>
                                {item.descripcion}
                              </Select.Option>
                            );
                        }
                      }
                    })}
                </Select>
              </Form.Item>
              <Form.Item label="Observaciones" name="observacion">
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  loading={loadingObservations}
                  htmlType="submit"
                  text="Enviar"
                />
              </Form.Item>
            </Form>
            {observations && (
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={4}>Histórico de estados asignados</Title>}
              >
                {observations.map((item, index) => (
                  <Descriptions
                    title={`Estado No. ${index + 1}`}
                    layout="vertical"
                    column={4}
                    bordered
                  >
                    <Descriptions.Item label="Usuario que realiza el envío y observación">
                      {item.nombre_completo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estado Asignado">
                      {item.estado_termino_nombre}
                    </Descriptions.Item>
                    <Descriptions.Item label="Enviado a">
                      {item.rol_nombre}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha y hora del envío">
                      {moment(item.created_at).format('DD MMMM YYYY HH:mm')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Observación" span={4}>
                      {item.observacion}{' '}
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </Card>
            )}
          </Card>
        </>
      )}
      </Skeleton>
    </div>
  );
};

export default TermsPage;
