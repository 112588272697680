import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography, notification, Card } from 'antd';

import PayResponsibilityProductList from '../PayResponsibilityProduct/List/index';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';
import PaymentTypeForm from '../../containers/TermPaymentForm/index';

import { post, put } from '../../api/module/term_payment';
import {
  post as createResponsibility,
  getResponsibilitiesByPay,
} from '../../api/module/term_payments_responsibility';
import {
  post as createProduct,
  getProductsByPay,
} from '../../api/module/term_payments_product';
import { get as getTerm } from '../../api/module/terms';
import { get as getProject } from '../../api/module/projects';
import { catchException } from '../../utils/helpers/helpers';

const { Title, Paragraph } = Typography;

export default function TermPayments({ match }) {
  const formRef = useRef(null);
  const [project_id] = useState(parseInt(match.params.project_id));
  const [term_id] = useState(parseInt(match.params.term_id));
  const [contract_id] = useState(parseInt(match.params.contract_id));
  const [loading, setLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [data, setData] = useState(null);
  const [usedResponsibilities, setUsedResponsibilities] = useState(null);
  const [usedProducts, setUsedProducts] = useState(null);
  const [id, setId] = useState(0);
  const [project, setProject] = useState([]);
  const [term, setTerm] = useState(null);
  const [ids] = useState({
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  });

  const breadcrumbItems = [
    {
      text: 'Términos de referencia',
      to: `${INTERNAL_LINKS.TDR_HOME}`,
    },
    {
      text: 'Formulario de Términos de referencia',
      to: `${INTERNAL_LINKS.TERM}/${term_id}/${project_id}/${contract_id}`,
    },
    {
      text: 'Pagos',
    },
  ];

  useEffect(() => {
    getProjectData();
    getTermData();
    //console.log(dataSource)
  }, []);

  const save = async (data) => {
    try {
      let response;

      if (!id) {
        const termino = term_id;
        data['termino'] = termino;
        setLoading(true);
        response = await post(data, termino);
        data['pago'] = response.data.id;
      } else {
        setLoading(true);
        response = await put(id, data);
        data['pago'] = id;
        //response = await removePayment(data);
      }

      //productos
      let prod = data.producto;
      if (prod != null) {
        for (let i = 0; i < prod.length; i++) {
          data['producto'] = prod[i];
          response = await createProduct(data);
        }
      }
      //responsabilidades
      if (data.responsabilidad != null) {
        data.responsabilidad.map(async (item) => {
          data['responsabilidad'] = item;
          response = await createResponsibility(data);
        });
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        formRef.current.resetFields();
        setData({});
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, MESSAGES.TIME_RELOAD);
      }
    } catch (err) {
      catchException(err, MESSAGES.DESC_ERROR_POST_MSG);
      setLoading(false);
    }
  };

  const getProjectData = async () => {
    const response = await getProject(ids.project_id);
    setProject(response.data);
  };

  const getTermData = async () => {
    const response = await getTerm(ids.term_id);
    setTerm(response.data);
  };

  useEffect(async () => {
    if (id > 0) {
      setInputLoading(true);
      const responsibilities = await getResponsibilitiesByPay(id).then(
        (res) => res.data.results,
      );
      let option_responsibility = [];
      if (responsibilities != null) {
        responsibilities.map((opt) => {
          option_responsibility.push(opt.responsabilidad);
        });
      }
      const products = await getProductsByPay(id).then(
        (res) => res.data.results,
      );
      let option_product = [];
      if (products != null) {
        products.map((opt) => {
          option_product.push(opt.producto);
        });
      }
      setInputLoading(false);
      if (data != null) {
        setUsedResponsibilities(option_responsibility);
        setUsedProducts(option_product);
        formRef.current.setFieldsValue({
          valor: data.valor,
          numero: data.numero,
          responsabilidad: option_responsibility,
          producto: option_product,
        });
      }
    } else {
      setData({});
    }
  }, [id]);

  const handleEdit = useCallback(
    (item) => {
      if (item != null) {
        setData(item);
        setId(item.id);
        setTimeout(() => {
          document.getElementById('nest-messages_valor').focus();
        }, 1000);
      }
    },
    [data, id],
  );

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        TÉRMINOS DE REFERENCIA
      </Title>
      <Title level={3}>PROYECTO: {project && project.nombre}</Title>
      <Paragraph>
        Los siguientes términos de referencia complementan el texto del contrato
        firmado entre las partes y están subordinados a dichos contratos.
      </Paragraph>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>D. ASPECTOS JURÍDICOS Y ECONÓMICOS</Title>}
      >
        <Title level={4}>Formas de pago</Title>
        {data != null && (
          <PaymentTypeForm
            onFinish={save}
            formRef={formRef}
            loading={loading}
            inputLoading={inputLoading}
            ids={ids}
            pago_id={id}
            usedResponsibilities={usedResponsibilities}
            usedProducts={usedProducts}
          />
        )}
        {term != null && (
          <PayResponsibilityProductList
            ids={ids}
            handleEdit={handleEdit}
            totalValueTerm={term.valor_total || 0}
          />
        )}
        <CustomButton
          type={'default'}
          className={'back-button'}
          htmlType="button"
          text={
            <Link
              to={`${INTERNAL_LINKS.TERM}/${term_id}/${project_id}/${contract_id}/?module=D`}
            >
              Volver
            </Link>
          }
        />
      </Card>
    </>
  );
}
