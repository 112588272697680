import React, { useState } from 'react';
import {
  notification,
  Table,
  Modal,
  Space,
  Empty,
  Typography,
  Layout,
} from 'antd';

import SpinLoad from '../../components/SpinLoad';
import CustomButton from '../../components/CustomButton/';
import WorkTeamSearchForm from '../../containers/WorkTeamSearchForm';

import { Link } from 'react-router-dom';

import MESSAGES from '../../enums/Messages';
import INTERNAL_LINKS from '../../enums/InternalLinks';

//Api Imports
import { post } from '../../api/module/contractor_search';
import { generateWordTemplateURL as generateTemplateURL } from '../../api/module/templates';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const ResumeSearchViewer = ({
  visible = false,
  onCancel = () => {},
  isModal = true,
}) => {
  const [loadingPDF, setLoadingPDF] = useState(null);
  const columns = [
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'num_documento',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      fixed: 'right',
      width: 125,
      render: (_, record) => {
        return (
          <Space direction={'vertical'} style={{width: '100%'}}>
            <CustomButton
              block
              text={
                <Link
                  to={
                    record.tipo_persona === 'Natural'
                      ? `${INTERNAL_LINKS.NATURAL_PERSON}/ver/${record.tercero}`
                      : `${INTERNAL_LINKS.LEGAL_PERSON}/ver/${record.tercero}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver
                </Link>
              }
            />
            {record.tipo_persona === 'Natural' && (
              <CustomButton
                type={'default'}
                className={'ant-btn-info'}
                htmlType="button"
                text="Generar PDF"
                block
                onClick={async () => {
                  await getPDFUrl(record.tercero);
                }}
                loading={loadingPDF === record.tercero}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [knowledgeArea, setKnowledgeArea] = useState(undefined);
  const [emptyText, setEmptyText] = useState('No hay datos');

  const selectedKnowledge = (value) => {
    setKnowledgeArea(value);
  };

  const onFinish = async (data) => {
    try {
      if (data.documento == '') {
        data.documento = undefined;
      }
      if (data.nombre == '') {
        data.nombre = undefined;
      }
      if (data.email == '') {
        data.email = undefined;
      }
      if (data.tipo_cargo == '') {
        data.tipo_cargo = undefined;
      }
      if (data.meses_experiencia == '') {
        data.meses_experiencia = undefined;
      }
      if (data.pregrado != null) {
        data.nivel_educativo = 7;
        delete data.pregrado;
      }
      if (data.especializacion != null) {
        data.nivel_educativo = 8;
        delete data.especializacion;
      }
      if (data.maestria != null) {
        data.nivel_educativo = 9;
        delete data.maestria;
      }
      if (data.doctorado != null) {
        data.nivel_educativo = 10;
        delete data.doctorado;
      }
      if (data.posdoctorado != null) {
        data.nivel_educativo = 11;
        delete data.posdoctorado;
      }
      data.area_conocimiento = knowledgeArea;
      data.tipo_persona = 0;
      data.termino = 0;
      setLoading(true);
      const response = await post(data);
      setLoading(false);
      if (response.data.length > 0) {
        setDataSource(response.data);
      } else {
        setDataSource([]);
        setEmptyText(
          'No se han encontrado elementos para la búsqueda realizada.',
        );
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const getPDFUrl = async (id) => {
    try {
      setLoadingPDF(id);
      let response = await generateTemplateURL({
        person: id,
        template: 13,
        person_type: 1,
      });
      const fileURL = response.data.url;
      window.open(
        'https://view.officeapps.live.com/op/view.aspx?src=' +
          encodeURIComponent(fileURL),
        '_blank',
      );
      setLoadingPDF(null);
    } catch (err) {
      setLoadingPDF(null);
      console.log(err);
    }
  };

  return (
    <Content>
      {isModal === true ? (
        <Modal
          title={
            <Title level={4} style={{ marginBottom: 0 }}>
              Busqueda de contratistas
            </Title>
          }
          visible={visible}
          onCancel={onCancel}
          style={{ top: 20 }}
          width={'80%'}
          footer={null}
        >
          <WorkTeamSearchForm
            onFinish={onFinish}
            selectedKnowledge={selectedKnowledge}
            allowCreation={false}
            loading={loading}
          />
          <SpinLoad loading={loading}>
            <Table
              rowKey="id"
              scroll={{ x: 'max-content' }}
              dataSource={dataSource}
              columns={columns}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Hojas de vida
                </Paragraph>
              )}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={emptyText}
                  />
                ),
              }}
            />
          </SpinLoad>
        </Modal>
      ) : (
        <>
          <WorkTeamSearchForm
            onFinish={onFinish}
            selectedKnowledge={selectedKnowledge}
            allowCreation={false}
            loading={loading}
          />
          <SpinLoad loading={loading}>
            <Table
              rowKey="id"
              scroll={{ x: 'max-content' }}
              dataSource={dataSource}
              columns={columns}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Hojas de vida
                </Paragraph>
              )}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={emptyText}
                  />
                ),
              }}
            />
          </SpinLoad>
        </>
      )}
    </Content>
  );
};
export default ResumeSearchViewer;
