import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import CustomRoute from '../../containers/CustomRoute/index';

import HomePage from '../../pages/Home';
import HomeAnnouncement from '../../pages/HomeAnnouncement/index';

// Proyectos
import ProjectCategoryList from '../../pages/ProjectCategory/List/index';
import ProjectCategoryForm from '../../pages/ProjectCategory/Form/index';
import ProjectsList from '../../pages/Projects/List/index';
import ProjectsForms from '../../pages/Projects/Form/index';
import ProjectCostCenter from '../../pages/ProjectCostCenter/index';

// Definición de Cargo y Equipo de Trabajo
import ProjectTeam from '../../pages/ProjectTeam';
import WorkTeam from '../../pages/WorkTeam';

//Politicas
import PoliticsList from '../../pages/Politics/List/index';
import PoliticsForm from '../../pages/Politics/Form/index';

//Plantillas
import TemplateType from '../../pages/TemplateType/index';
import Templates from '../../pages/Templates/index';

//Preseleccionados
import AnnouncementPreselection from '../../pages/AnnouncementPreselection/List/index';
import AnnouncementValidation from '../../pages/AnnouncementPreselection/Validation/index';
import AnnouncementValidationEducation from '../../pages/AnnouncementPreselection/ValidationEducation/index';
import AnnouncementValidationExperiencie from '../../pages/AnnouncementPreselection/ValidationExperiencie/index';
import AnnouncementValidationDocuments from '../../pages/AnnouncementPreselection/ValidationDocuments/index';
import AnnouncementEmail from '../../pages/AnnouncementPreselection/Email/index';

//Seleccionados
import AnnouncementSelection from '../../pages/AnnouncementSelection/index';
import AnnouncementSelectionValidation from '../../pages/AnnouncementSelection/Validation/index';
import AnnouncementSelectionValidationEducation from '../../pages/AnnouncementSelection/ValidationEducation/index';
import AnnouncementSelectionValidationExperience from '../../pages/AnnouncementSelection/ValidationExperience/index';
import AnnouncementSelectionValidationRestrictive from '../../pages/AnnouncementSelection/ValidationRestrictive/index';
import AnnouncementSelectionValidationTests from '../../pages/AnnouncementSelection/ValidationTests/index';
import AnnouncementSelectionValidationDocuments from '../../pages/AnnouncementSelection/ValidationDocuments/index';
import AnnouncementSelectionEmail from '../../pages/AnnouncementSelection/Email/index';
import RestrictiveLists from '../../pages/AnnouncementSelection/ValidationRestrictive/RestrictiveLists';
import RestrictiveListSearch from '../../pages/RestrictiveListSearch';

//Convocatorias
import Announcement from '../../pages/Announcements/index';
import AnnouncementNew from '../../pages/AnnouncementNew/index';
import AnnouncementPosition from '../../pages/AnnouncementPosition/index';
import AnnouncementProgressList from '../../pages/AnnouncementProgress/index';
import AnnouncementDetails from '../../pages/AnnouncementDetails/index';
import AnnouncementFinish from '../../pages/AnnouncementFinish/index';
import AnnouncementRejected from '../../pages/AnnouncementRejected/index';
import AnnouncementShow from '../../pages/AnnouncementShow/index';
import AnnouncementHistoryList from '../../pages/AnnouncementHistory';
import AnnouncementAdjustInformation from '../../pages/AnnouncementAdjustInformation';

//Organizaciones
import OrganizationsList from '../../pages/Organizations/List/index';
import OrganizationsForms from '../../pages/Organizations/Form/index';

//Tipos de organizaciones
import OrganizationsTypeList from '../../pages/OrganizationsTypes/List/index';
import OrganizationTypeForms from '../../pages/OrganizationsTypes/Form/index';

//Validacion Precontractual
import DocumentValidationPrecontractualHome from '../../pages/DocumentValidationPrecontractual/index';
import DocumentValidationPrecontractualForm from '../../pages/DocumentValidationPrecontractual/Form';
import DocumentValidationPrecontractualEmail from '../../pages/DocumentValidationPrecontractual/Email';
import DocumentValidationPrecontractualEmailHistory from '../../pages/DocumentValidationPrecontractual/EmailHistory';

//Validacion Contractual
import DocumentValidationContractualHome from '../../pages/DocumentValidationContractual';
import DocumentValidationContractualEmail from '../../pages/DocumentValidationContractual/Email';
import DocumentValidationContractualHistory from '../../pages/DocumentValidationContractual/History';
import DocumentValidationContractualResume from '../../pages/DocumentValidationContractual/Resume';

import DocumentValidationContractualForm from '../../pages/DocumentValidationContractual/Form';

//Validacion Director
import DocumentValidationDirectorHome from '../../pages/DocumentValidationDirector';
import DocumentValidationDirectorForm from '../../pages/DocumentValidationDirector/Form';

//Contratistas
import ResumeSearchPage from '../../pages/ResumeSearch';
import DocumentValidationShow from '../../pages/DocumentValidationShow';
import TDRApprovedShow from '../../pages/TDRApprovedShow';

//Validacion Parcial
import DocumentValidationPartialHome from '../../pages/DocumentValidationPartial';
import DocumentValidationPartialForm from '../../pages/DocumentValidationPartial/Form';
import DocumentValidationPartialEmail from '../../pages/DocumentValidationPartial/Email';
import DocumentValidationPartialEmailHistory from '../../pages/DocumentValidationPartial/EmailHistory';
import DocumentContractValidationShow from '../../pages/DocumentContractValidationShow';

//Documentos contractuales

import ResumeContractListPage from '../../pages/RecumeContract/list';
import ResumeContractFormPage from '../../pages/RecumeContract/form';
import ResumeContractSearchPage from '../../pages/ResumeContractSearch';
import ResumeContractSearchDetail from '../../pages/ResumeContractSearch/detail';
import ResumeContractSearchPersonDetail from '../../pages/ResumeContractSearch/personDetail';
import ContractModificationHome from '../../pages/ContractModifications/Home';
import ContractModificationsForm from '../../pages/ContractModifications/Form';
import ModificationChangeTerm from '../../pages/ContractModifications/TermDate';
import ModificationDocuments from '../../pages/ContractModifications/Documents';
import ModificationResponsibilitiesForm from '../../pages/ContractModifications/Responsibilities';
import ModificationBudget from '../../pages/ContractModifications/Budget';
import ModificationProductsForm from '../../pages/ContractModifications/Products';
import ModificationPayments from '../../pages/ContractModifications/Payments';
import ContractModificationsDirector from '../../pages/ContractModificationsDirector';
import ContractModificationDirectorDocumentForm from '../../pages/ContractModificationsDirector/Form';
import CTMApprovedShow from '../../pages/ContractModificationsContractor';
import ContractMassModificationsForm from '../../pages/ContractModifications/MassForm';
import MassModificationChangeTerm from '../../pages/ContractModifications/TermDate/mass';
import MassModificationBudget from '../../pages/ContractModifications/Budget/mass';
import MassModificationResponsibilitiesForm from '../../pages/ContractModifications/Responsibilities/mass';
import MassModificationProductsForm from '../../pages/ContractModifications/Products/mass';
import MassModificationPayments from '../../pages/ContractModifications/Payments/mass';
import MassModificationDocuments from '../../pages/ContractModifications/Documents/mass';
import InsurancePolicyHome from '../../pages/Insurance';
import InsurancePolicyForm from '../../pages/Insurance/Form';
import InsurancePolicyEmail from '../../pages/Insurance/Email';
import InsurancePolicyEmailHistory from '../../pages/Insurance/EmailHistory';
import InsurancePolicyContractorHome from '../../pages/InsuranceContractor';
import InsuranceContractorForm from '../../pages/InsuranceContractor/Form';
import AnnouncementCloseAdmin from '../../pages/Admin/Announcement';
import PostContractualHome from '../../pages/PostContractual';
import NewPostContractualForm from '../../pages/PostContractual/NewPostContractual/Form';
import PostContractualForm from '../../pages/PostContractual/Form';
import NewMassPostContractualForm from '../../pages/MassPostContractual/NewMassPostContractual/Form';
import MassPostContractualForm from '../../pages/MassPostContractual/Form';
import PostContractualDirector from '../../pages/PostContractualDirector';
import PCApprovedShow from '../../pages/PostContractualContractor';

const ContentManager = () => {
  const [perms, setPerms] = useState(null);
  const [group, setGroup] = useState({ nombre: null, id: null });

  useEffect(() => {
    const p = JSON.parse(window.localStorage.getItem('permissions'))[0]
      .attributes;

    setPerms(
      p.lista_permisos.reduce((map, value) => {
        map[value.descripcion] = p.tipo_permisos.reduce((perm_map, type) => {
          perm_map[type.tipo] = value.tipos.includes(type.id);
          return perm_map;
        }, {});
        return map;
      }, {}),
    );
    setGroup({ nombre: p.nombre, id: p.id });
  }, []);

  if (!perms || !group) {
    return <></>;
  }

  return (
    <>
      <Route
        exact
        component={(props) => {
          if (group.nombre !== 'Super') return <HomeAnnouncement {...props} />;
          else return <HomePage {...props} />;
        }}
        path={INTERNAL_LINKS.HOME}
      />
      <Route
        exact
        component={(props) => {
          if (group.nombre !== 'Super')
            return (
              <AnnouncementAdjustInformation
                {...props}
              ></AnnouncementAdjustInformation>
            );
          else return <HomePage {...props} />;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_ADJUST_INFORMATION}/:announcement_rol_id/:id/:stage/:person_id`}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectsList {...props} perm={perms.Proyecto}></ProjectsList>;
        }}
        path={INTERNAL_LINKS.PROJECTS}
        perm={() => perms.Proyecto}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectsForms {...props}></ProjectsForms>;
        }}
        path={`${INTERNAL_LINKS.PROJECT}`}
        perm={() => perms.Proyecto.Crear}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectsForms {...props}></ProjectsForms>;
        }}
        path={`${INTERNAL_LINKS.PROJECT}/:project_id`}
        perm={() => perms.Proyecto.Editar}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectsForms {...props} readOnly={true}></ProjectsForms>;
        }}
        path={`${INTERNAL_LINKS.PROJECT}/ver/:project_id`}
        perm={() => perms.Proyecto}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectCategoryList {...props}></ProjectCategoryList>;
        }}
        path={INTERNAL_LINKS.PROJECT_CATEGORIES}
        perm={() => perms.Proyecto}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectCategoryForm {...props}></ProjectCategoryForm>;
        }}
        path={`${INTERNAL_LINKS.PROJECT_CATEGORY}/:project_id`}
        perm={() => perms.Proyecto.Editar}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ProjectCostCenter {...props}></ProjectCostCenter>;
        }}
        path={`${INTERNAL_LINKS.PROJECT_COST_CENTER}/:project_id`}
        perm={() => perms.Proyecto.Editar}
      />
      <CustomRoute
        component={(props) => {
          return <PoliticsList {...props}></PoliticsList>;
        }}
        path={INTERNAL_LINKS.POLITICS}
        perm={() => perms['Políticas']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <PoliticsForm {...props}></PoliticsForm>;
        }}
        path={`${INTERNAL_LINKS.POLICY}/:id?`}
        perm={() => perms['Políticas']}
      />
      <CustomRoute
        component={(props) => {
          return <TemplateType {...props}></TemplateType>;
        }}
        path={`${INTERNAL_LINKS.TEMPLATE}/:hiring_type_id/:hiring_stage_id/:contract_type_id/:person_type_id/:template_type_id`}
        perm={() => perms.Plantillas}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <Templates {...props}></Templates>;
        }}
        path={INTERNAL_LINKS.TEMPLATES}
        perm={() => perms.Plantillas}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ProjectTeam {...props}></ProjectTeam>;
        }}
        path={`${INTERNAL_LINKS.PROJECT_TEAM}/:project_id?`}
        perm={() => perms['Definición de Cargo y Equipo de Trabajo']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <WorkTeam {...props}></WorkTeam>;
        }}
        path={`${INTERNAL_LINKS.WORK_TEAM}/:position_id`}
        perm={() => perms['Definición de Cargo y Equipo de Trabajo']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementPreselection {...props}></AnnouncementPreselection>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION}`}
        perm={() => perms['Preselección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementSelection {...props}></AnnouncementSelection>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION}`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidation
              {...props}
            ></AnnouncementSelectionValidation>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION}/:announcement_id/:announcement_rol_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidationEducation
              {...props}
            ></AnnouncementSelectionValidationEducation>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_EDUCATION}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidationExperience
              {...props}
            ></AnnouncementSelectionValidationExperience>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_LABORAL}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidationRestrictive
              {...props}
            ></AnnouncementSelectionValidationRestrictive>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_RESTRICTIVE}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidationDocuments
              {...props}
            ></AnnouncementSelectionValidationDocuments>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_DOCUMENTOS}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <RestrictiveLists {...props}></RestrictiveLists>;
        }}
        path={`${INTERNAL_LINKS.RESTRICTIVE_LIST}/:person_id`}
        perm={() => perms['Listas restrictivas']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <RestrictiveListSearch {...props}></RestrictiveListSearch>;
        }}
        path={INTERNAL_LINKS.RESTRICTIVE_LIST_SEARCH}
        perm={() => perms['Listas restrictivas']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ResumeSearchPage {...props}></ResumeSearchPage>;
        }}
        path={INTERNAL_LINKS.RESUME_SEARCH}
        perm={() => perms['Hojas de vida']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionValidationTests
              {...props}
            ></AnnouncementSelectionValidationTests>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_VALIDATION_TESTS}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementSelectionEmail {...props}></AnnouncementSelectionEmail>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SELECTION_EMAIL}/:announcement_id/:selection_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Selección']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementValidation {...props}></AnnouncementValidation>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION}/:announcement_id/:announcement_rol_id`}
        perm={() => perms['Preselección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementValidationEducation
              {...props}
            ></AnnouncementValidationEducation>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_EDUCATION}/:announcement_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Preselección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementValidationExperiencie
              {...props}
            ></AnnouncementValidationExperiencie>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_LABORAL}/:announcement_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Preselección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementValidationDocuments
              {...props}
            ></AnnouncementValidationDocuments>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_VALIDATION_DOCUMENTOS}/:announcement_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Preselección']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementEmail {...props}></AnnouncementEmail>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PRESELECTION_EMAIL}/:announcement_id/:preselection_id/:announcement_rol_id/:person_id`}
        perm={() => perms['Preselección']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <Announcement {...props}></Announcement>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT}`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementNew {...props}></AnnouncementNew>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_NEW}/:announcement_type_id?/:announcement_id?`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementPosition {...props}></AnnouncementPosition>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/:announcement_type_id?/:id?`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <AnnouncementProgressList {...props}></AnnouncementProgressList>
          );
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementDetails {...props}></AnnouncementDetails>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_DETAILS}/:announcement_id?`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementFinish {...props}></AnnouncementFinish>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_FINISH}`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementRejected {...props}></AnnouncementRejected>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_REJECTED}/:announcement_id?`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementShow {...props}></AnnouncementShow>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/:announcement_id?`}
        perm={() => perms.Convocatorias}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementHistoryList {...props}></AnnouncementHistoryList>;
        }}
        path={`${INTERNAL_LINKS.ANNOUNCEMENT_HISTORY}`}
        perm={() => perms.Convocatorias}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <OrganizationsList {...props}></OrganizationsList>;
        }}
        path={`${INTERNAL_LINKS.ORGANIZATIONS}`}
        perm={() => perms['Organizaciones ejecutoras']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <OrganizationsForms {...props}></OrganizationsForms>;
        }}
        path={`${INTERNAL_LINKS.ORGANIZATION}/:id?`}
        perm={() => perms['Organizaciones ejecutoras']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <OrganizationsTypeList {...props}></OrganizationsTypeList>;
        }}
        path={INTERNAL_LINKS.ORGANIZATIONS_TYPES}
        perm={() => perms['Tipos de Organizaciones']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <OrganizationTypeForms {...props}></OrganizationTypeForms>;
        }}
        path={`${INTERNAL_LINKS.ORGANIZATION_TYPE}/:id?`}
        perm={() => perms['Tipos de Organizaciones']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <DocumentValidationShow {...props}></DocumentValidationShow>;
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_SHOW}/:validation_id`}
        perm={() => true} //
      />

      <CustomRoute
        exact
        component={(props) => {
          return <TDRApprovedShow {...props}></TDRApprovedShow>;
        }}
        path={`${INTERNAL_LINKS.TERMS_OF_REFERENCE_SHOW}/:id`}
        perm={() => true} //
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPrecontractualHome
              {...props}
            ></DocumentValidationPrecontractualHome>
          );
        }}
        path={[
          INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL,
          `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}/:type(project|role)=:id(\\d+)`,
        ]}
        perm={() => perms['Validación de Documentos Precontractual']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <DocumentValidationPrecontractualForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}-validar/:validation_id`}
        perm={() => perms['Validación de Documentos Precontractual']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPrecontractualEmail
              {...props}
            ></DocumentValidationPrecontractualEmail>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL}/:validation_id`}
        perm={() => perms['Validación de Documentos Precontractual']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPrecontractualEmailHistory
              {...props}
            ></DocumentValidationPrecontractualEmailHistory>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL_HISTORY}/:validation_id`}
        perm={() => perms['Validación de Documentos Precontractual']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationContractualHome
              {...props}
            ></DocumentValidationContractualHome>
          );
        }}
        path={[
          INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL,
          `${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}/:type(project|role|term)=:id(\\d+)`,
        ]}
        perm={() => perms['Validación de Documentos Jurídico']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationContractualForm
              {...props}
            ></DocumentValidationContractualForm>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL}-validar/:validation_id`}
        perm={() => perms['Validación de Documentos Jurídico']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationContractualEmail
              {...props}
            ></DocumentValidationContractualEmail>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_EMAIL}/:validation_id`}
        perm={() => perms['Validación de Documentos Jurídico']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationContractualHistory
              {...props}
            ></DocumentValidationContractualHistory>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_HISTORY}/:validation_id`}
        perm={() => perms['Validación de Documentos Jurídico']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <DocumentValidationContractualResume {...props} />;
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_CONTRACTUAL_RESUME}/:id`}
        perm={() => perms['Validación de Documentos Jurídico']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationDirectorHome
              {...props}
            ></DocumentValidationDirectorHome>
          );
        }}
        path={[
          `${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}`,
          `${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}/:type(project|role|term)=:id(\\d+)`,
        ]}
        perm={() => perms['Firma de TDR']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationDirectorForm
              {...props}
            ></DocumentValidationDirectorForm>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_DIRECTOR}-validar/:validation_id`}
        perm={() => perms['Firma de TDR']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPartialHome
              {...props}
            ></DocumentValidationPartialHome>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}/:term_id?`}
        perm={() => perms['Seguimiento validación documentación parcial']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPartialForm
              {...props}
            ></DocumentValidationPartialForm>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}-validar/:validation_id`}
        perm={() => perms['Seguimiento validación documentación parcial']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPartialEmail
              {...props}
            ></DocumentValidationPartialEmail>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL_EMAIL}/:validation_id`}
        perm={() => perms['Seguimiento validación documentación parcial']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentValidationPartialEmailHistory
              {...props}
            ></DocumentValidationPartialEmailHistory>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL_EMAIL_HISTORY}/:validation_id`}
        perm={() => perms['Seguimiento validación documentación parcial']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <DocumentContractValidationShow
              {...props}
            ></DocumentContractValidationShow>
          );
        }}
        path={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}-show/:validation_id`}
        perm={() => true} //
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ResumeContractListPage {...props} />;
        }}
        path={`${INTERNAL_LINKS.RESUME_CONTRACTS}`}
        perm={() => group.id !== 6}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ResumeContractFormPage {...props} />;
        }}
        path={`${INTERNAL_LINKS.RESUME_CONTRACT}/:id`}
        perm={() => group.id !== 6}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ResumeContractSearchPage {...props} />;
        }}
        path={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}/:type?/:param1?/:param2?`}
        perm={() => perms['Búsqueda de documentos contractuales']?.Leer}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ResumeContractSearchDetail {...props} />;
        }}
        path={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/:id/:proyecto?/:cargo?/:tercero?`}
        perm={() => perms['Búsqueda de documentos contractuales']?.Leer}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ResumeContractSearchPersonDetail {...props} />;
        }}
        path={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-person/:id`}
        perm={() => perms['Búsqueda de documentos contractuales']?.Leer}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ContractModificationHome {...props} />;
        }}
        path={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ContractModificationsForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/:proyecto/:ctm?`}
        perm={() => perms['Modificaciones Contractuales']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ModificationChangeTerm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_CHANGE_TERM}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ModificationResponsibilitiesForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_CHANGE_RESPONSIBILITIES}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ModificationProductsForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_CHANGE_PRODUCTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ModificationDocuments {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_DOCUMENTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ModificationBudget {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_BUDGET}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <ModificationPayments {...props} />;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_PAYMENTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <ContractMassModificationsForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.CONTRACT_MASS_MODIFICATIONS}/:proyecto/:ctm?`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationChangeTerm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_CHANGE_TERM}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationBudget {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_BUDGET}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationResponsibilitiesForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_CHANGE_RESPONSIBILITIES}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationProductsForm {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_CHANGE_PRODUCTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationPayments {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_PAYMENTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassModificationDocuments {...props} />;
        }}
        path={`${INTERNAL_LINKS.MASS_MODIFICATION_DOCUMENTS}/:modification_id`}
        perm={() => perms['Modificaciones Contractuales']}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <ContractModificationsDirector
              {...props}
            ></ContractModificationsDirector>
          );
        }}
        path={[
          `${INTERNAL_LINKS.MODIFICATION_DIRECTOR}`,
          `${INTERNAL_LINKS.MODIFICATION_DIRECTOR}/:type(project|role|ct|term)=:id(\\d+)`,
        ]}
        perm={() => perms['Firma Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <ContractModificationDirectorDocumentForm
              {...props}
            ></ContractModificationDirectorDocumentForm>
          );
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_DIRECTOR_DOCUMENTS}/:validation_id`}
        perm={() => perms['Firma Modificaciones Contractuales']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <CTMApprovedShow {...props}></CTMApprovedShow>;
        }}
        path={`${INTERNAL_LINKS.MODIFICATION_SHOW}/:id`}
        perm={() => true} //
      />
      <CustomRoute
        exact
        component={(props) => {
          return <InsurancePolicyHome {...props}></InsurancePolicyHome>;
        }}
        path={[
          `${INTERNAL_LINKS.INSURANCE}`,
          `${INTERNAL_LINKS.INSURANCE}/:tab`,
          `${INTERNAL_LINKS.INSURANCE}/:tab/:project_id`,
        ]}
        perm={() => perms['Revisión Pólizas']?.Leer}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <InsurancePolicyForm {...props}></InsurancePolicyForm>;
        }}
        path={`${INTERNAL_LINKS.INSURANCE}-validar/:validation_id`}
        perm={() => perms['Revisión Pólizas']?.Leer}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <InsurancePolicyEmail {...props}></InsurancePolicyEmail>;
        }}
        path={`${INTERNAL_LINKS.INSURANCE}-email/:validation_id`}
        perm={() => perms['Revisión Pólizas']?.Leer}
      />
      <CustomRoute
        exact
        component={(props) => {
          return (
            <InsurancePolicyEmailHistory
              {...props}
            ></InsurancePolicyEmailHistory>
          );
        }}
        path={`${INTERNAL_LINKS.INSURANCE}-email-history/:validation_id`}
        perm={() => perms['Revisión Pólizas']?.Leer}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <InsurancePolicyContractorHome
              {...props}
            ></InsurancePolicyContractorHome>
          );
        }}
        path={INTERNAL_LINKS.CONTRACTOR_INSURANCE}
        perm={() => true}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <InsuranceContractorForm {...props}></InsuranceContractorForm>;
        }}
        path={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/:insurance_id`}
        perm={() => true}
      />

      <CustomRoute
        exact
        component={(props) => {
          return <PostContractualHome {...props}></PostContractualHome>;
        }}
        path={[
          `${INTERNAL_LINKS.POSTCONTRACTUAL}`,
          `${INTERNAL_LINKS.POSTCONTRACTUAL}/:tab/:project_id`,
        ]}
        perm={() => perms['Poscontractual']?.Leer}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <NewPostContractualForm {...props}></NewPostContractualForm>;
        }}
        path={`${INTERNAL_LINKS.POSTCONTRACTUAL}-new/:project_id`}
        perm={() => perms['Poscontractual']?.Crear}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <PostContractualForm {...props}></PostContractualForm>;
        }}
        path={`${INTERNAL_LINKS.POSTCONTRACTUAL}-form/:id`}
        perm={() => perms['Poscontractual']?.Editar}
      />

      <CustomRoute
        exact
        component={(props) => {
          return (
            <NewMassPostContractualForm {...props}></NewMassPostContractualForm>
          );
        }}
        path={`${INTERNAL_LINKS.MASS_POSTCONTRACTUAL}-new/:project_id`}
        perm={() => perms['Poscontractual']?.Crear}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <MassPostContractualForm {...props}></MassPostContractualForm>;
        }}
        path={`${INTERNAL_LINKS.MASS_POSTCONTRACTUAL}-form/:id`}
        perm={() => perms['Poscontractual']?.Editar}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <PostContractualDirector {...props}></PostContractualDirector>;
        }}
        path={[
          `${INTERNAL_LINKS.POSTCONTRACTUAL_DIRECTOR}`,
          `${INTERNAL_LINKS.POSTCONTRACTUAL_DIRECTOR}/:type(project|role|ct|mass_pc)=:id(\\d+)`,
        ]}
        perm={() => perms['Firma Poscontractual']}
      />
      <CustomRoute
        exact
        component={(props) => {
          return <PCApprovedShow {...props}></PCApprovedShow>;
        }}
        path={`${INTERNAL_LINKS.POSTCONTRACTUAL_CONTRACTOR}/:id`}
        perm={() => true} //
      />

      <CustomRoute
        exact
        component={(props) => {
          return <AnnouncementCloseAdmin {...props} />;
        }}
        path={`${INTERNAL_LINKS.ADMIN}-announcement`}
        perm={() => group.nombre === 'Super'}
      />

      {/* <Route>
          <NotFoundPage />
        </Route> */}
    </>
  );
};

export default ContentManager;
