import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Form, Input, Row, Col, Space, Layout } from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import './style.scss';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Tipos de Identificación',
    to: `${INTERNAL_LINKS.IDENTITIES_TYPES}`,
  },
  {
    text: 'Formulario de Tipos de Identificación',
  },
];

const validateMessages = {
  required: '${label} Es Requerido!',
  types: {
    nombre: '${label} No es un nombre válido!',
    sigla: '${label} No es una sigla válida!',
  },
};

const IdentityTypeForm = ({ title, onFinish, initialValues, loading }) => {
  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Paragraph>
        A continuación, diligencie el formulario "{title}".
        <br />
        Recuerde que los campos obligatorios están marcados con asterisco (*).
      </Paragraph>
      <Form
        name="identities-type"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={initialValues}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="nombre"
              label="Nombre"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Solo puede ingresar 100 caracteres!',
                    );
                  },
                }),
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="sigla"
              label="Sigla"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length <= 5) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Solo puede ingresar 5 caracteres!');
                  },
                }),
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Space>
              <CustomButton text="Guardar" loading={loading} />
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                text={
                  <Link to={INTERNAL_LINKS.IDENTITIES_TYPES}> Volver </Link>
                }
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

IdentityTypeForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};

IdentityTypeForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
};
export default IdentityTypeForm;
