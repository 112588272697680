import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Alert, Table, Card } from 'antd';
import moment from 'moment';

import CustomButton from '../../components/CustomButton';
import { CustomTooltip } from '../../components/CustomTooltip';
import CustomUpload from '../../components/CustomUpload';
import SpinLoad from '../../components/SpinLoad';
import { get as getDocumentUrl } from '../../api/module/document_repository';

const { Title, Paragraph } = Typography;

const AdditionalDocumentsNaturalForm = ({
  saveDocument,
  documents,
  showClick,
  readOnly = false,
  pdfHelp,
  loadingPDF,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingHV, setLoadingHV] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);

  const dataSource = [
    {
      key: '1',
      tipoDocumento: 'Hoja de vida firmada',
      tooltip:
        'Hoja de vida firmada SIN soportes, donde se indique mínimo: Nombre completo, número de identificación, teléfono, dirección de residencia y relación de formación y experiencia.',
      text: 'documento_01',
    },
    {
      key: '2',
      tipoDocumento: 'Copia del documento de identidad',
      tooltip: 'Debe ser visible ambas caras del documento',
      text: 'documento_02',
    },
  ];

  const dataSourceCC = [
    {
      key: '1',
      tipoDocumento: 'Carta de compromiso',
      tooltip:
        'Cargue la carta de compromiso firmada en caso de ser requerida por la organización.',
      text: 'documento_25',
    },
  ];

  const dataSource2 = [
    {
      key: '2',
      tipoDocumento:
        'Libreta militar o documento que certifique la definición de su situación militar',
      tooltip:
        'Libreta Militar o documento que certifique la definición de su situación militar (cuando aplique). Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_03',
    },
    {
      key: '3',
      tipoDocumento: 'Certificación bancaria',
      tooltip:
        'Certificación bancaria donde se indique el No. de cuenta en la que se consignarán los pagos. Cuando se trate de cuentas en el exterior, debe especificar el código SWIFT/ABA o IBAN, y datos del banco intermediario (si se requiere). Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_04',
    },
    {
      key: '4',
      tipoDocumento: 'RUT vigente',
      tooltip:
        'RUT actualizado y con actividad económica correspondiente al objeto del contrato. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_05',
    },
    {
      key: '5',
      tipoDocumento: 'RIT vigente',
      tooltip:
        'Cuando las actividades se realicen en Bogotá D.C. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_06',
    },
    {
      key: '6',
      tipoDocumento: 'Certificación de afiliación a EPS',
      tooltip:
        'Certificación de afiliación a EPS (como cotizante). Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_07',
    },
    {
      key: '7',
      tipoDocumento: 'Certificación de afiliación a fondo de pensiones',
      tooltip:
        'Certificado de afiliación a Fondo de pensiones. En caso de no estar obligado(a) a cotizar pensión, por favor, cargue el respectivo documento que lo pruebe. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_08',
    },
    {
      key: '8',
      tipoDocumento: 'Certificación de afiliación a ARL',
      tooltip:
        'Certificación de afiliación a ARL (si la tiene) o Formato de solicitud de afiliación a ARL. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_09',
    },
    {
      key: '9',
      tipoDocumento: 'Examen de ingreso de seguridad y salud en el trabajo',
      tooltip:
        'Si lo tiene. Para contratos iguales o inferiores a 90 días, debe presentar examen preocupacional no mayor a 3 años. En contratos iguales o superiores a 91 días, debe presentar examen preocupacional realizado en el año en curso, específico para las labores de Corpoeducación o de otra entidad donde desarrolle labores similares. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_10',
    },
    {
      key: '10',
      tipoDocumento:
        'Certificado de antecedentes disciplinarios de la Procuraduría General de la Nación',
      tooltip:
        'La fecha de expedición del documento  no debe ser mayor a 30 días',
      text: 'documento_11',
    },
    {
      key: '11',
      tipoDocumento:
        'Certificado de antecedentes fiscales de la Contraloría General de la República',
      tooltip:
        'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_12',
    },
    {
      key: '12',
      tipoDocumento: 'Antecedentes judiciales de la Policía',
      tooltip:
        'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_13',
    },
    {
      key: '13',
      tipoDocumento:
        'Evidencia de la consulta de inhabilidades por delitos sexuales cometidos contra menores de 18 años',
      tooltip:
        'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_14',
    },
    {
      key: '14',
      tipoDocumento:
        'Evidencia de la consulta al Sistema de Registro Nacional de Medidas Correctivas -RNMC',
      tooltip:
        'La fecha de expedición del documento  no debe ser mayor a 30 días. Para mayor información haga click en el botón Ayuda de la parte superior.',
      text: 'documento_15',
    },
  ];

  const [documentsHVDataSource, setDocumentsHVDataSourse] =
    useState(dataSource);
  const [documentsCCDataSource, setDocumentsCCDataSource] =
    useState(dataSourceCC);
  const [documentsDataSource, setDocumentsDataSourse] = useState(dataSource2);

  const save = async (e, data) => {
    setLoading(true);
    await saveDocument(e, data);
    setDocumentsDataSourse([]);
    setDocumentsDataSourse(dataSource2);
    setLoading(false);
  };

  const saveHV = async (e, data) => {
    setLoadingHV(true);
    await saveDocument(e, data);
    setDocumentsHVDataSourse([]);
    setDocumentsHVDataSourse(dataSource);
    setLoadingHV(false);
  };

  const showHelp = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(documento_nombre);
    window.open(response.data.url);

    setLoadingDocument(false);
  };

  const columns = [
    {
      title: 'Tipo documento',
      dataIndex: 'tipoDocumento',
      key: 'tipoDocumento',
      render: (text, record) => (
        <>
          {record.tooltip !== '' ? (
            <>
              <Paragraph>
                {record.tipoDocumento} <CustomTooltip title={record.tooltip} />{' '}
              </Paragraph>
            </>
          ) : (
            <Paragraph>{record.tipoDocumento} </Paragraph>
          )}
        </>
      ),
    },
    {
      title: 'Cargar documento',
      dataIndex: 'cargarDocumento',
      key: 'cargarDocumento',
      width: 300,
      render: (text, record) => {
        if (!readOnly)
          return (
            <CustomUpload
              file_type={1}
              upload_type={1}
              onChange={(e) => saveDocument(e, record.text)}
            />
          );
        return null;
      },
    },
    {
      title: 'Consultar documento',
      dataIndex: 'consultarDocumento',
      key: 'consultarDocumento',
      width: 120,
      render: (text, record) => {
        if (documents != null) {
          let document = null;
          for (let item of documents) {
            if (item.documento_tipo === record.text) {
              if (document == null) {
                document = item;
              } else {
                if (moment(item.created_at) > moment(document.created_at)) {
                  document = item;
                }
              }
            }
          }
          if (document != null) {
            return (
              <CustomButton
                block
                type="default"
                className={'ant-btn-info'}
                htmlType="button"
                loading={loadingPDF === document.documento}
                onClick={() => {
                  showClick(`${document.documento}.pdf`, document.documento);
                }}
                text="Ver"
              />
            );
          }
        }
      },
    },
  ];

  const columns2 = [
    {
      title: 'Tipo documento',
      dataIndex: 'tipoDocumento',
      key: 'tipoDocumento',
      render: (text, record) => (
        <>
          {record.tooltip !== '' ? (
            <Paragraph>
              {record.tipoDocumento} <CustomTooltip title={record.tooltip} />
            </Paragraph>
          ) : (
            <Paragraph>{record.tipoDocumento} </Paragraph>
          )}
        </>
      ),
    },
    {
      title: 'Cargar documento',
      dataIndex: 'cargarDocumento',
      key: 'cargarDocumento',
      width: 300,
      render: (text, record) =>
        !readOnly && (
          <CustomUpload
            file_type={1}
            upload_type={1}
            onChange={(e) => saveDocument(e, record.text)}
          />
        ),
    },
    {
      title: 'Consultar documento',
      dataIndex: 'consultarDocumento',
      key: 'consultarDocumento',
      width: 120,
      render: (text, record) => {
        if (documents != null) {
          let document = null;
          for (let item of documents) {
            if (item.documento_tipo === record.text) {
              if (document == null) {
                document = item;
              } else {
                if (moment(item.created_at) > moment(document.created_at)) {
                  document = item;
                }
              }
            }
          }
          if (document != null) {
            return (
              <CustomButton
                block
                className={'ant-btn-info'}
                type="default"
                htmlType="button"
                loading={loadingPDF === document.documento}
                onClick={() => {
                  showClick(`${document.documento}.pdf`, document.documento);
                }}
                text="Ver"
              />
            );
          }
        }
      },
    },
  ];

  return (
    <>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Documentos adicionales obligatorios</Title>}
      >
        <Paragraph>
          Por favor, cargue la hoja de vida y la copia de su documento de
          identidad en formato PDF para terminar su registro. El tamaño máximo
          por archivo es de 2MB. <strong>Su cargue es obligatorio</strong>
        </Paragraph>
        <Table
          loading={loadingHV}
          pagination={false}
          columns={columns}
          dataSource={documentsHVDataSource}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de documentos obligatorios
            </Paragraph>
          )}
        />
        <Paragraph>
          En caso de ser enviada la <strong>Carta de compromiso</strong> vía
          correo electrónico por parte de la organización, cárguela firmada en
          este espacio.
        </Paragraph>
        <Table
          loading={loadingHV}
          pagination={false}
          columns={columns}
          dataSource={documentsCCDataSource}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de otros documentos obligatorios
            </Paragraph>
          )}
        />
      </Card>
      <Card
        size={'small'}
        bordered={false}
        title={
          <Title level={3}>Documentos adicionales para contratación</Title>
        }
      >
        {pdfHelp && (
          <Alert
            message="Nota"
            action={
              <CustomButton
                type={'default'}
                className={'back-button'}
                htmlType="button"
                loading={loadingDocument}
                onClick={() => {
                  showHelp(pdfHelp.documento);
                }}
                text={'Descargar ayuda'}
              />
            }
            description={
              'Descargue la ayuda con la información orientadora sobre los requerimientos de los documentos solicitados a continuación'
            }
            type="info"
            showIcon
          />
        )}
        <Paragraph style={{ marginTop: 10 }}>
          Los siguientes documentos solo son obligatorios para contratación.
          Recuerde cargarlos en formato PDF y con un tamaño del archivo máximo
          de 2MB
        </Paragraph>
        <SpinLoad loading={loading}>
          <Table
            pagination={false}
            columns={columns2}
            dataSource={documentsDataSource}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de documentos para contratación
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
    </>
  );
};

AdditionalDocumentsNaturalForm.propTypes = {
  saveCv: PropTypes.func,
  deleteDataSource: PropTypes.func,
  documents: PropTypes.array,
  showCLick: PropTypes.func,
};

AdditionalDocumentsNaturalForm.defaultProps = {
  saveCv: () => {},
  deleteDataSource: () => {},
  documents: [],
  showCLick: () => {},
  pdfHelp: null,
};

export default AdditionalDocumentsNaturalForm;
