import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Alert, Table, Skeleton, Card, Modal } from 'antd';

import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { filter as filterAnnouncement } from '../../api/module/announcement';
import { filter } from '../../api/module/announcement_invite';
import { filter as filterAnnouncementRol } from '../../api/module/announcement_rol';
import { getAllValidationDetails } from '../../api/module/term_document_validation';
import { getAllBySelf as getAllPendingTDRApproved } from '../../api/module/term_resume_contract';
import { getAll as getAllPendingCTMApproved } from '../../api/module/ctm_contractor';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import SpinLoad from '../../components/SpinLoad';
import { getAllValidationContractDetails } from '../../api/module/term_document_contract_validation';
import { ajaxTable as getAllPendingInsurance } from '../../api/module/insurance_policy_contractor';
import { ajaxTable as getAllPendingPostContractual } from '../../api/module/postcontractual_contractor.js';
import HomeIndicators from '../../components/HomeIndicators/index.js';

const { Title, Paragraph } = Typography;

const breadcrumbItems = [];

export default function HomeAnnouncement() {
  const [person_id] = useState(window.localStorage['tercero']);
  const [announcements, setAnnouncements] = useState([]);
  const [announcementsPublic, setAnnouncementsPublic] = useState([]);
  const [enrolled, setEnrolled] = useState([]);
  const [validationFixList, setValidationFixList] = useState([]);
  const [validationFixListContract, setValidationFixListContract] = useState(
    [],
  );
  const [pendingTDRApproved, setPendingTDRApproved] = useState([]);
  const [pendingCTMApproved, setPendingCTMApproved] = useState([]);
  const [pendingInsurance, setPendingInsurance] = useState([]);
  const [pendingPostContractual, setPendingPostContractual] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTDR, setIsLoadingTDR] = useState(false);
  const [isLoadingCTM, setIsLoadingCTM] = useState(false);
  const [isLoadingInsurance, setIsLoadingInsurance] = useState(false);
  const [isLoadingPostContractual, setIsLoadingPostContractual] =
    useState(false);
  const [isLoadingPublic, setIsLoadingPublic] = useState(false);
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isLoadingFix, setIsLoadingFix] = useState(false);

  const columnsTDRApproved = [
    {
      title: 'Nombre del Proyecto - Cargo',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {record.termino.proyecto_nombre} - {record.termino.cargo_nombre}{' '}
            {record.subcategoria_nombre && `- ${record.subcategoria_nombre}`}
          </>
        );
      },
    },
    {
      title: 'Requiere Póliza',
      dataIndex: ['termino', 'requiere_poliza'],
      render: (t) => (t ? 'Sí' : 'No'),
    },
    {
      title: 'Acciones',
      key: 'accionesTDRApproved',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return record.estado_proceso === 0 ? (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.TERMS_OF_REFERENCE_SHOW}/${record.id}`}
              >
                Ver
              </Link>
            }
          />
        ) : (
          <CustomButton
            block
            danger
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.TERMS_OF_REFERENCE_SHOW}/${record.id}`}
              >
                Continuar proceso
              </Link>
            }
          />
        );
      },
    },
  ];

  const columnsCTMApproved = [
    {
      title: 'Nombre del Proyecto - Cargo',
      dataIndex: 'nombre',
    },
    {
      title: 'Requiere Póliza',
      dataIndex: 'requiere_poliza',
      render: (t) => (t ? 'Sí' : 'No'),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return record.estado_firma === 0 ? (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link to={`${INTERNAL_LINKS.MODIFICATION_SHOW}/${record.id}`}>
                Ver
              </Link>
            }
          />
        ) : (
          <CustomButton
            block
            danger
            type="default"
            htmlType={'button'}
            text={
              <Link to={`${INTERNAL_LINKS.MODIFICATION_SHOW}/${record.id}`}>
                Continuar proceso
              </Link>
            }
          />
        );
      },
    },
  ];

  const columnsInsurance = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo_nombre',
    },
    {
      title: 'No. Contrato',
      dataIndex: 'contrato_num',
      // width: '3rem',
    },
    {
      title: '# Modificación',
      dataIndex: 'modificacion_num',
      // width: '2rem',
      align: 'center',
    },
    {
      title: 'Acciones',
      dataIndex: 'fix',
      align: 'center',
      fixed: 'right',
      // width: 110,
      render: (t, record) => {
        return t ? (
          <CustomButton
            block
            danger
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/${record.id}`}
              >
                Ajustar
              </Link>
            }
          />
        ) : (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.CONTRACTOR_INSURANCE}-form/${record.id}`}
              >
                Cargar
              </Link>
            }
          />
        );
      },
    },
  ];

  const columnsPCApproved = [
    {
      title: 'Nombre del Proyecto - Cargo',
      dataIndex: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return record.estado_firma === 0 ? (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.POSTCONTRACTUAL_CONTRACTOR}/${record.id}`}
              >
                Ver
              </Link>
            }
          />
        ) : (
          <CustomButton
            block
            danger
            type="default"
            htmlType={'button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.POSTCONTRACTUAL_CONTRACTOR}/${record.id}`}
              >
                Continuar proceso
              </Link>
            }
          />
        );
      },
    },
  ];

  const columnsAnnouncementPublic = [
    {
      title: 'Nombre de la convocatoria',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {record.numero == null ? '0000' : record.numero} - {record.nombre}
          </>
        );
      },
    },
    {
      title: 'Estado de aplicación',
      dataIndex: 'estado_aplicacion',
      key: 'estado_aplicacion',
      render: (_, record) => {
        if (record.reject_exists === true) {
          return 'Convocatoria rechazada';
        } else if (record.application_exists === true) {
          return 'Aplicante';
        } else {
          return 'Sin aplicación';
        }
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return (
          <CustomButton
            block
            type="default"
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link
                to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${record.id}/?show=true`}
              >
                Ver
              </Link>
            }
          />
        );
      },
    },
  ];

  const columns = [
    {
      title: 'Nombre de la convocatoria',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        if (record.convocatoria) {
          return (
            <>
              {record.convocatoria.numero == null
                ? '0000'
                : record.convocatoria.numero}{' '}
              - {record.convocatoria.nombre}
            </>
          );
        }
      },
    },
    {
      title: 'Estado de aplicación',
      dataIndex: 'estado_aplicacion',
      key: 'estado_aplicacion',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        if (record.convocatoria) {
          return (
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              className={'ant-btn-info'}
              text={
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${record.convocatoria.id}/?show=true`}
                >
                  Ver
                </Link>
              }
            />
          );
        }
      },
    },
  ];

  const columnsAnnouncementRol = [
    {
      title: 'Código de la convocatoria',
      dataIndex: 'numero',
      key: 'numero',
      render: (_, record) => `${record.numero} - ${record.nombre}`,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
    },
    {
      title: 'Fecha inicio convocatoria',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
    },
    {
      title: 'Fecha finalización convocatoria',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
    },
    {
      title: 'Estado de aplicación',
      dataIndex: 'estado_aplicacion',
      key: 'estado_aplicacion',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        let stage;
        if (record.source === 'Preselección') {
          stage = 1;
        } else {
          stage = 2;
        }
        return (
          <CustomButton
            block
            htmlType={'button'}
            type={
              record.estado_aplicacion === 'Ajustar información'
                ? 'danger'
                : 'default'
            }
            text={
              record.estado_aplicacion === 'Ajustar información' ? (
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_ADJUST_INFORMATION}/${record.cargo_id}/${record.id}/${stage}/${person_id}`}
                >
                  Ajustar
                </Link>
              ) : (
                <Link
                  to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${record.convocatoria_id}/?show=true`}
                >
                  Ver
                </Link>
              )
            }
          />
        );
      },
    },
  ];

  const columnsValidationInformationFixContract = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
    },
    {
      title: 'Cargo/Rol del proyecto',
      dataIndex: 'cargo_nombre',
      render: (_, record) => {
        if (record.subcategoria_nombre) {
          return `${record.cargo_nombre} - ${record.subcategoria_nombre}`;
        }
        return record.cargo_nombre;
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado_validacion_general__nombre',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return (
          <CustomButton
            block
            htmlType={'button'}
            type="danger"
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}-show/${record.id}`}
              >
                Ajustar
              </Link>
            }
          />
        );
      },
    },
  ];

  const columnsValidationInformationFix = [
    {
      title: 'Proyecto',
      dataIndex: 'proyecto_nombre',
    },
    {
      title: 'Cargo/Rol del proyecto',
      dataIndex: 'cargo_nombre',
      render: (_, record) => {
        if (record.subcategoria_nombre) {
          return `${record.cargo_nombre} - ${record.subcategoria_nombre}`;
        }
        return record.cargo_nombre;
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado_validacion_general__nombre',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      align: 'center',
      fixed: 'right',
      width: 110,
      render: (_, record) => {
        return (
          <CustomButton
            block
            htmlType={'button'}
            type="danger"
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_SHOW}/${record.id}`}
              >
                Ajustar
              </Link>
            }
          />
        );
      },
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getAnnouncementInvite(),
      getEnrolled(),
      getAnnouncementPublic(),
      getValidationFixList(),
      getValidationFixListContract(),
      getPendingTDRApproved(),
      getPendingCTMApproved(),
      getPendingInsurance(),
      getPendingPostContractual(),
    ]);
    const modal = Modal.info({
      title: 'Recuerde',
      content:
        'Si Ud. está en una convocatoria recuerde actualizar y/o completar la información de registro por la opción "Perfil.',
      okText: 'Entendido',
      cancelText: 'No',
      onOk: () => {
        modal.destroy();
      },
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const getAnnouncementInvite = async () => {
    try {
      setIsLoadingInvite(true);
      const response = await filter('/');
      setAnnouncements(response.data.results);
      setIsLoadingInvite(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getAnnouncementPublic = async () => {
    try {
      setIsLoadingPublic(true);
      const response = await filterAnnouncement('/public/?');
      setAnnouncementsPublic(response.data);
      setIsLoadingPublic(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getEnrolled = async () => {
    try {
      setIsLoadingInfo(true);
      const response = await filterAnnouncementRol('/enrolled');
      setEnrolled(response.data);
      setIsLoadingInfo(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getValidationFixList = async () => {
    try {
      setIsLoadingFix(true);
      const response = await getAllValidationDetails();
      setValidationFixList(response.data.results);
      setIsLoadingFix(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getValidationFixListContract = async () => {
    try {
      const response = await getAllValidationContractDetails();
      setValidationFixListContract(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const getPendingTDRApproved = async () => {
    try {
      setIsLoadingTDR(true);
      const response = await getAllPendingTDRApproved();
      setPendingTDRApproved(response.data.results);
      setIsLoadingTDR(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPendingCTMApproved = async () => {
    try {
      setIsLoadingCTM(true);
      const response = await getAllPendingCTMApproved();
      setPendingCTMApproved(response.data.results);
      setIsLoadingCTM(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPendingInsurance = async () => {
    try {
      setIsLoadingInsurance(true);
      const response = await getAllPendingInsurance('filter[estado]=0');
      setPendingInsurance(response.data.results);
      setIsLoadingInsurance(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getPendingPostContractual = async () => {
    try {
      setIsLoadingPostContractual(true);
      const response = await getAllPendingPostContractual();
      setPendingPostContractual(response.data.results);
      setIsLoadingPostContractual(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Skeleton loading={isLoading} active>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Alert
        style={{ marginBottom: 15 }}
        message="Información importante"
        description="Asegúrese de contar con una buena conexión a internet para el óptimo funcionamiento de la plataforma."
        type="info"
        showIcon
      />
      <HomeIndicators />
      {pendingTDRApproved.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={3}>
              Términos de referencia (TDR) para aprobación
            </Title>
          }
        >
          <SpinLoad loading={isLoadingTDR}>
            <Table
              columns={columnsTDRApproved}
              dataSource={pendingTDRApproved}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Términos de referencia (TDR) para aprobación
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      )}
      {pendingCTMApproved.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={3}>
              Modificaciones Contractuales para aprobación
            </Title>
          }
        >
          <SpinLoad loading={isLoadingCTM}>
            <Table
              columns={columnsCTMApproved}
              dataSource={pendingCTMApproved}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de Modificaciones contractuales para aprobación
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      )}
      {pendingInsurance.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Pólizas pendientes</Title>}
        >
          <SpinLoad loading={isLoadingInsurance}>
            <Table
              columns={columnsInsurance}
              dataSource={pendingInsurance}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de pólizas por cargar/ajustar
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      )}
      {pendingPostContractual.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={3}>
              Terminación y Liquidación contractual para aprobación
            </Title>
          }
        >
          <SpinLoad loading={isLoadingPostContractual}>
            <Table
              columns={columnsPCApproved}
              dataSource={pendingPostContractual}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de terminaciones y liquidaciones contractuales para
                  aprobación
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      )}
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Convocatorias públicas</Title>}
      >
        <SpinLoad loading={isLoadingPublic}>
          <Table
            columns={columnsAnnouncementPublic}
            dataSource={announcementsPublic}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de convocatorias públicas
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
      <Card
        size={'small'}
        bordered={false}
        title={
          <Title level={3}>Convocatorias a las que ha sido invitado(a)</Title>
        }
      >
        <SpinLoad loading={isLoadingInvite}>
          <Table
            columns={columns}
            dataSource={announcements}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de convocatorias a las que ha sido invitado(a)
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Convocatorias aplicadas</Title>}
      >
        <SpinLoad loading={isLoadingInfo}>
          <Table
            columns={columnsAnnouncementRol}
            dataSource={enrolled}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de convocatorias aplicadas
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
      {validationFixList.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Documentos contractuales por ajustar</Title>}
        >
          <SpinLoad loading={isLoadingFix}>
            <Table
              rowKey="id"
              columns={columnsValidationInformationFix}
              dataSource={validationFixList}
              title={() => (
                <Paragraph className={'margin-5'} strong>
                  Listado de documentos contractuales por ajustar
                </Paragraph>
              )}
            />
          </SpinLoad>
        </Card>
      )}
      {validationFixListContract.length > 0 && (
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={3}>
              Documentos contractuales por ajustar para pago
            </Title>
          }
        >
          <Table
            rowKey="id"
            columns={columnsValidationInformationFixContract}
            dataSource={validationFixListContract}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de documentos contractuales por ajustar para pago
              </Paragraph>
            )}
          />
        </Card>
      )}
    </Skeleton>
  );
}
