import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`/client_cost_centers/?page[size]=1000`);
};

const get = async (id) => {
  return await httpClient.get(`/client_cost_centers/${id}`);
};

export {
  getAll,
  get,
}
