import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/announcement_selection/`, data);
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection/`);
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/announcement_selection/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`${process.env.REACT_APP_API_HOST}/announcement_selection/${id}/`, data);
};

const putDocument = async (data) => {
  return await httpClient.put(`${process.env.REACT_APP_API_HOST}/announcement_selection/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const remove = async (id) => {
  return await httpClient.delete(`${process.env.REACT_APP_API_HOST}/announcement_selection/${id}`);
};

const filter = async (query) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection/${query}`);
};

const counters = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/announcement_selection/${id}/counters/`);
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false
) => {
  let searchBy = '';

  if (search)
    searchBy = '&filter[search]=' + search

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement_selection/export` +
      `${query}&sort=${sortBy}` +
      `${searchBy}`,
      {
        responseType: 'blob',
      }
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/announcement_selection` +
      `${query}&page[number]=${page}&page[size]=${pageSize}` +
      `&sort=${sortBy}` +
      `${searchBy}`,
    );
};

export {
  post,
  getAll,
  get,
  put,
  patch,
  putDocument,
  remove,
  filter,
  counters,
  ajaxTable,
};
