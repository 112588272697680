import React, { useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { notification, Table, Popconfirm, Space, Typography } from 'antd';

import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import MESSAGES from '../../enums/Messages';

import {
  getAllByAnnouncement,
  remove,
} from '../../api/module/announcement_rol';

const { Paragraph } = Typography;

const AnnouncementTable = memo(
  ({ announcement_id, announcementType, announcementStatus }) => {
    const [dataSource, setDataSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getDataSource = () => {
      try {
        setIsLoading(true);
        getAllByAnnouncement(announcement_id).then((res) => {
          setDataSource(res.data.results);
          setIsLoading(false);
        });
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    useEffect(async () => {
      if (announcement_id) {
        getDataSource();
      }
    }, []);

    const deleteDataSource = useCallback(
      async (id) => {
        try {
          const response = await remove(id);
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
              duration: 2,
            });
            setDataSource(dataSource.filter((item) => item.id !== id));
          }
        } catch (err) {
          console.log(err);
          notification.error({
            message: MESSAGES.ERROR_MSG,
            description: MESSAGES.DESC_ERROR_DELETE_MSG,
            duration: 2,
          });
        }
      },
      [dataSource],
    );

    const columns = [
      {
        title: 'Cargo',
        dataIndex: 'cargo',
        key: 'cargo',
      },
      {
        title: 'Lugar labores',
        dataIndex: 'ciudad',
        render: (_, record) => {
          let workplace = ' ';
          workplace += record.pais_nombre !== null ? record.pais_nombre : '';
          workplace += record.departamento_nombre !== null ? ' ' + record.departamento_nombre : '';
          workplace += record.ciudad_nombre !== null ? ' ' + record.ciudad_nombre : '';

          return workplace;
        }
      },
      {
        title: 'Tiempo contrato',
        dataIndex: 'duracion',
        render: (_, record) =>
          dataSource.length >= 1 ? record.duracion + ' meses' : null,
      },
      {
        title: 'Total vacantes',
        dataIndex: 'total_vacantes',
        key: 'total_vacantes',
        align: 'right',
      },
      {
        title: 'ACCIÓN',
        dataIndex: 'accion',
        align: 'center',
        width: 200,
        render: (_, record) => {
          return dataSource.length >= 1 ? (
            <Space>
              {announcementStatus === false ? (
                <>
                  <CustomButton
                    type={'default'}
                    className={'ant-btn-info'}
                    htmlType="button"
                    text={
                      <Link
                        to={
                          announcement_id != null
                            ? `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/${record.id}/?announcement_id=${record.convocatoria}&edit=true`
                            : `${INTERNAL_LINKS.ANNOUNCEMENT_POSITION}/${announcementType}/${record.id}/?announcement_id=${record.convocatoria}&edit=false`
                        }
                      >
                        Editar
                      </Link>
                    }
                  />
                  <CustomButton
                    type={'default'}
                    htmlType="button"
                    danger
                    text={
                      <Popconfirm
                        title={MESSAGES.DELETE_CONFIRM_MSG}
                        onConfirm={() => deleteDataSource(record.id)}
                      >
                        Eliminar
                      </Popconfirm>
                    }
                  />
                </>
              ) : (
                <Paragraph>
                  No hay acciones disponibles porque la convocatoria ya fue
                  lanzada.
                </Paragraph>
              )}
            </Space>
          ) : null;
        },
      },
    ];

    return (
      <SpinLoad loading={isLoading}>
        <Table
          rowKey="id"
          scroll={{ x: 400 }}
          dataSource={dataSource}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Cargo/Rol para la convocatoria
            </Paragraph>
          )}
        />
      </SpinLoad>
    );
  },
);

export default AnnouncementTable;
