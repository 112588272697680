import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { getAll } from '../../api/module/test_icfes'

import './style.scss';

const SelectRolIcfes = ({
	className = '',
	onChange = () => { },
	id = 0,
	proceso_icfes
}) => {
	const [data, setData] = useState(null);

	const getRolIcfes = async () => {
		const response = await getAll();
		setData(response.results);
	}

	useEffect(() => {
		getRolIcfes();
	}, [])

	return (
		<>
			{
				data != null &&
				<>
					{
						(proceso_icfes != 0 && proceso_icfes != null) ?
							<Select
								labelInValue
								defaultValue={{ value: proceso_icfes }}
								className={className}
								onChange={onChange}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									data != null &&
									data.map(item => <Select.Option key={`select-rol-icfes-${item.id}`} value={item.id}>{item.nombre}</Select.Option>)
								}
							</Select>
							:
							<Select
								className={className}
								onChange={onChange}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									data != null &&
									data.map(item => <Select.Option key={`select-rol-icfes-${item.id}`} value={item.id}>{item.nombre}</Select.Option>)
								}
							</Select>
					}</>
			}

		</>
	);
};
export default SelectRolIcfes;
