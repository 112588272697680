import React, { useState, useEffect } from 'react';
import {
  notification,
  Typography,
  Skeleton,
  Descriptions,
  Table,
  Radio,
  Input,
  Form,
  Alert,
  Space,
  List,
  Popconfirm,
  Modal,
  Card,
} from 'antd';
import { CustomButton } from '../../components';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import MESSAGES from '../../enums/Messages';
import { get, sign as signDocuments } from '../../api/module/ctm_contractor';
import moment from 'moment';
import 'moment/locale/es';
import { postWord as post } from '../../api/module/template';
import PdfViewer from '../../components/PdfViewer';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import SpinLoad from '../../components/SpinLoad';
import { Link } from 'react-router-dom';

const breadcrumbItems = [
  {
    text: 'Modificaciones contractuales por Aprobar',
  },
];

const initModalState = {
  pdf: undefined,
  visible: false,
  key: 'no-key',
};

const { Title, Text, Paragraph } = Typography;

export default function CTMApprovedShow({ match, history }) {
  const [ctmData, setCTMData] = useState(undefined);
  const [initialValues, setInitialValues] = useState(undefined);
  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('Información importante');
  const [alertDescription, setAlertDescription] = useState(
    'Su proceso de modificación contractual no ha finalizado. En el menú de Documentos contractuales, se le informará el estado final de este proceso.',
  );

  const [modalState, setModalState] = useState(initModalState);
  const [contractState, setContractState] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveSteps, setSaveSteps] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData(id) {
      try {
        const res = await get(id);

        setCTMData(res.data);
        setInitialValues({
          doc_list: res.data.documentos,
          auth_list: res.data.autorizaciones,
        });

        if (res.data.estado_firma > 0) {
          setSaveSteps(true);

          if (res.data.estado_firma === 4) {
            setAlertType('success');
            setAlertMessage('Felicitaciones');
            setAlertDescription(
              'El proceso de contratación ha finalizado, revise su correo electrónico',
            );
          }
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData(match.params.id);
  }, [match.params.id]);

  const columns = [
    {
      title: 'Tipo de documento',
      render: (_, record) => {
        return record.plantilla_codigo != null
          ? record.plantilla_codigo + ' - ' + record.plantilla_nombre
          : record.plantilla_nombre;
      },
    },
    {
      title: 'Ver',
      key: 'view',
      align: 'center',
      width: 126,
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text="Visualizar"
            htmlType="button"
            loading={loadingPDF === record.plantilla}
            disabled={loadingPDF}
            onClick={async () => {
              setLoadingPDF(record.plantilla);
              try {
                const pdfData = await post({
                  template: record.tipo_plantilla,
                  person: ctmData.tercero,
                  term: ctmData.termino,
                  person_type: ctmData.tipo_persona,
                  modification: ctmData.id,
                  contract: ctmData.contrato,
                });
                const fileURL = pdfData.data.url;
                setModalState({
                  visible: true,
                  pdf: fileURL,
                  key: `pdf-${record.plantilla}`,
                });
              } catch (err) {
                console.log(err);
                notification.error({
                  message: MESSAGES.ERROR_MSG,
                  description: MESSAGES.DESC_ERROR_GET_MSG,
                  duration: 2,
                });
              }
              setLoadingPDF(undefined);
            }}
          />
        );
      },
    },
    {
      title: 'Aceptación del documento',
      key: 'acceptance',
      align: 'center',
      width: 144,
      render: (_, _record, index) => {
        return (
          <Form.Item
            noStyle
            name={[index, 'estado_aceptacion']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
    {
      title: 'Explique el motivo de su NO aceptación',
      key: 'motive',
      render: (_, _record, index) => {
        return <MotiveField index={index} />;
      },
    },
  ];

  async function onSubmit(values) {
    const documentos = values.doc_list;
    const autorizaciones = values.auth_list;

    setSaveLoading(true);
    try {
      await signDocuments(ctmData.id, {
        documentos: documentos,
        autorizaciones: autorizaciones,
      });

      if (contractState) {
        try {
          setSaveSteps(true);
          setTimeout(() => {
            history.push(
              `${INTERNAL_LINKS.RESUME_CONTRACT}/${ctmData.contrato}`,
            );
          }, 10000);
        } catch (exception) {}
      } else {
        Modal.info({
          title: 'Guardado',
          content: 'Su respuesta ha sido guardada.',
          onOk: () => history.push(INTERNAL_LINKS.HOME_ANNOUNCEMENT),
          onCancel: () => history.push(INTERNAL_LINKS.HOME_ANNOUNCEMENT),
        });
      }
    } catch (err) {
      console.log(err);
      let description_error;

      if (err.response.data.errors) {
        const errors = err.response.data.errors;
        description_error = errors.shift().detail;
      } else {
        description_error = 'No se pudieron firmar los documentos.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description_error,
        duration: 2,
      });
      console.log(err);
      setSaveLoading(false);
    }
  }

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Modificaciones contractuales por aprobar
      </Title>
      {ctmData ? (
        <>
          <Descriptions column={2} bordered layout="vertical">
            <Descriptions.Item label="Proyecto / Administración">
              {ctmData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/Rol">
              {ctmData.subcategoria_nombre
                ? `${ctmData.cargo_nombre} - ${ctmData.subcategoria_nombre}`
                : ctmData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Objeto del contrato" span={2}>
              {ctmData.objeto_contrato}
            </Descriptions.Item>
            <Descriptions.Item
              label="Fecha de Inicio Contratación INICIAL"
              style={{ textAlign: 'center' }}
            >
              {moment(ctmData.fecha_inicio).format('DD MMMM YYYY')}
            </Descriptions.Item>
            <Descriptions.Item
              label="Fecha de Terminación Contratación VIGENTE"
              style={{ textAlign: 'center' }}
            >
              {moment(ctmData.fecha_fin).format('DD MMMM YYYY')}
            </Descriptions.Item>
            <Descriptions.Item
              label="Valor total contrato VIGENTE"
              span={2}
              style={{ textAlign: 'center' }}
            >
              {ctmData.valor_total}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <CustomButton
            htmlType="button"
            text={
              <Link
                to={`${INTERNAL_LINKS.RESUME_CONTRACT}/${ctmData.contrato}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver históricos contractuales
              </Link>
            }
            style={{ float: 'right' }}
          />
        </>
      ) : (
        <Skeleton active loading={true} />
      )}
      <br />
      {ctmData ? (
        initialValues &&
        ctmData.estado_firma < 1 && (
          <Card
            size={'small'}
            bordered={false}
            title={<Title level={3}>Documentos por revisar</Title>}
          >
            <Form
              preserve={true}
              form={form}
              onValuesChange={(newV, V) => {
                if (newV.hasOwnProperty('doc_list')) {
                  const old = V.doc_list;
                  let makeTrue = false;
                  newV.doc_list.forEach((element, index) => {
                    if (element.showField === false) {
                      old[index] = { estado_aceptacion: true, motivo: null };
                      makeTrue = true;
                    } else {
                    }
                  });
                  if (makeTrue) form.setFieldsValue({ doc_list: old });
                }
              }}
              onFinish={onSubmit}
              initialValues={initialValues}
            >
              <Form.List name="doc_list">
                {() => (
                  <SpinLoad loading={!initialValues}>
                    <Table
                      rowKey="id"
                      scroll={{ x: 400 }}
                      dataSource={initialValues.doc_list}
                      columns={columns}
                      pagination={false}
                      title={() => (
                        <Paragraph className={'margin-5'} strong>
                          Listado de documentos
                        </Paragraph>
                      )}
                    />
                  </SpinLoad>
                )}
              </Form.List>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  for (let i = 0; i < prevValues.doc_list.length; i++) {
                    if (
                      curValues.doc_list[i].estado_aceptacion !==
                      prevValues.doc_list[i].estado_aceptacion
                    )
                      return true;
                  }
                  return false;
                }}
              >
                {({ getFieldValue }) => {
                  const docs = getFieldValue('doc_list');
                  if (
                    docs &&
                    docs.every(
                      (e) =>
                        e.estado_aceptacion && e.estado_aceptacion === true,
                    )
                  ) {
                    return (
                      <>
                        <Form.List name="auth_list">
                          {() => (
                            <AuthorizationList
                              dataSource={initialValues.auth_list}
                            />
                          )}
                        </Form.List>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) => {
                            for (
                              let i = 0;
                              i < prevValues.auth_list.length;
                              i++
                            ) {
                              if (
                                curValues.auth_list[i].estado_aceptacion !==
                                prevValues.auth_list[i].estado_aceptacion
                              )
                                return true;
                            }
                            return false;
                          }}
                        >
                          {({ getFieldValue }) => {
                            const auths = getFieldValue('auth_list');
                            if (
                              auths &&
                              auths.every(
                                (e) =>
                                  e.estado_aceptacion &&
                                  e.estado_aceptacion === true,
                              )
                            )
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <CustomButton
                                    type="primary"
                                    text="GUARDAR Y ACEPTAR MODIFICACIÓN CONTRACTUAL"
                                    htmlType="submit"
                                    loading={saveLoading}
                                  />
                                </div>
                              );
                            return (
                              <div style={{ textAlign: 'center' }}>
                                <Popconfirm
                                  onConfirm={() => {
                                    setContractState(false);
                                    form.submit();
                                  }}
                                  title={
                                    <Text>
                                      ¿Está seguro(a) de rechazar este proceso
                                      de contratación? Si tiene dudas por favor{' '}
                                      <ContactLink text="contáctenos" />
                                    </Text>
                                  }
                                  okText="Rechazar"
                                  okButtonProps={{ type: 'danger' }}
                                  cancelText="Cancelar"
                                >
                                  <CustomButton
                                    type="danger"
                                    text="GUARDAR Y RECHAZAR MODIFICACIÓN CONTRACTUAL"
                                    htmlType="button"
                                    loading={saveLoading}
                                  />
                                </Popconfirm>
                              </div>
                            );
                          }}
                        </Form.Item>
                      </>
                    );
                  }
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <Popconfirm
                        onConfirm={() => {
                          setContractState(false);
                          form.submit();
                        }}
                        title="¿Está seguro(a) de rechazar esta contratación?"
                        okText="Si"
                        cancelText="No"
                      >
                        <CustomButton
                          type="danger"
                          text="GUARDAR Y RECHAZAR MODIFICACIÓN CONTRACTUAL"
                          htmlType="button"
                          loading={saveLoading}
                        />
                      </Popconfirm>
                    </div>
                  );
                }}
              </Form.Item>
            </Form>
          </Card>
        )
      ) : (
        <Skeleton active loading={true} />
      )}
      {saveSteps && (
        <>
          <Alert
            message={alertMessage}
            description={alertDescription}
            type={alertType}
            showIcon
          />
        </>
      )}

      <PdfViewer
        pdf={modalState.pdf}
        visible={modalState.visible}
        onCancel={() => setModalState(initModalState)}
        isWord={true}
      />
    </>
  );
}

function MotiveField({ index }) {
  return (
    <Form.Item
      noStyle
      dependencies={[
        ['doc_list', index, 'estado_aceptacion'],
        ['doc_list', index, 'showField'],
      ]}
    >
      {({ getFieldValue }) => {
        if (getFieldValue(['doc_list', index, 'estado_aceptacion']) === false) {
          return (
            <>
              {getFieldValue(['doc_list', index, 'showField']) ||
              getFieldValue(['doc_list', index, 'motivo']) ? (
                <Form.Item
                  name={[index, 'motivo']}
                  rules={[
                    {
                      required: true,
                      message: 'Indique el motivo',
                    },
                  ]}
                >
                  <Input.TextArea rows={2} placeholder="Motivo" />
                </Form.Item>
              ) : (
                <Form.Item
                  name={[index, 'showField']}
                  rules={[
                    {
                      required: true,
                      message: 'Revise la alerta',
                    },
                  ]}
                >
                  <AlertInputField />
                </Form.Item>
              )}
            </>
          );
        }
        return undefined;
      }}
    </Form.Item>
  );
}

function AlertInputField({ onChange }) {
  return (
    <Alert
      message="Alerta"
      description={
        <Text>
          Al no aceptar la información puede implicar no continuar con el
          proceso de contratación. Si tiene dudas por favor{' '}
          <ContactLink text="contáctenos" />
          <br />
          <Space>
            <Text>¿Está seguro(a)?</Text>
            <CustomButton
              text="Sí"
              type="danger"
              htmlType="button"
              onClick={() => onChange(true)}
            />
            <CustomButton
              text="No"
              type="primary"
              htmlType="button"
              onClick={() => onChange(false)}
            />
          </Space>
        </Text>
      }
      type="info"
      showIcon
    />
  );
}

function ContactLink({ text }) {
  return (
    <a
      href="https://www.corpoeducacion.org.co/contacto/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
}

function AuthorizationList({ dataSource }) {
  function renderItem(item, index) {
    return (
      <List.Item>
        <List.Item.Meta
          title={item.nombre}
          description={
            <Space
              direction="vertical"
              style={{ width: '100%', color: 'black' }}
            >
              <div
                key={`authorize-${index}`}
                dangerouslySetInnerHTML={{ __html: item.descripcion }}
              />
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  rules={[
                    {
                      required: true,
                      message: 'Confirme o rechace la autorización',
                    },
                  ]}
                  name={[index, 'estado_aceptacion']}
                >
                  <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Form.Item
                noStyle
                dependencies={[['auth_list', index, 'estado_aceptacion']]}
              >
                {({ getFieldValue }) => {
                  if (
                    getFieldValue(['auth_list', index, 'estado_aceptacion']) ===
                    false
                  )
                    return (
                      <Alert
                        message="Alerta"
                        description={
                          <Text>
                            LEA ESPECIALMENTE BIEN ESTA PREGUNTA ANTES DE
                            CONTESTARLA. PUEDE IMPLICAR NO CONTINUAR CON EL
                            PROCESO DE MODIFICACIÓN CONTRACTUAL. Si tiene dudas
                            por favor <ContactLink text="contáctenos" />
                          </Text>
                        }
                        type="info"
                        showIcon
                      />
                    );
                  return undefined;
                }}
              </Form.Item>
            </Space>
          }
        />
      </List.Item>
    );
  }

  return (
    <>
      <Alert
        message="Información importante"
        description="Por favor lea atentamente la siguiente información"
        type="info"
        showIcon
      />
      <List renderItem={renderItem} dataSource={dataSource} />
    </>
  );
}
