import React, { useState } from 'react';
import {
  Space,
  notification,
  Typography,
  Popconfirm,
} from 'antd';

import CustomButton from '../../../../components/CustomButton';
import SpinLoad from '../../../../components/SpinLoad';
import MESSAGES from '../../../../enums/Messages';
import { post } from '../../../../api/module/announcement_email';
import AjaxTable from "../../../../components/AjaxTable";
import {ajaxTable as filterAjaxTable} from "../../../../api/module/announcement_preselection";

const { Paragraph } = Typography;

const Rechazados = ({
  dataSource,
  announcement_id,
  announcement_rol_id,
  loading,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero_data', 'notificacion_email'],
      key: ['tercero_data', 'notificacion_email'],
      align: 'center',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: ['tercero_data', 'nombre_completo'],
      key: ['tercero_data', 'nombre_completo'],
      align: 'center',
    },
    {
      title: 'Tipo Identificación',
      dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      key: ['tercero_data', 'tipo_identificacion_nombre'],
      align: 'center',
    },
    {
      title: 'Doc Identificación',
      dataIndex: ['tercero_data', 'numero_identificacion'],
      key: ['tercero_data', 'numero_identificacion'],
    },
    {
      title: 'Enviar Correo notificación',
      dataIndex: 'estado_preseleccion',
      key: 'estado_preseleccion',
      render: (_, record) => (
        <Space>
          <Popconfirm
            okText="Sí"
            cancelText="No"
            title={MESSAGES.MSG_SEND_EMAIL}
            onConfirm={(e) => {
              sendEmail(record.id);
            }}
          >
            <CustomButton
              block
              type="default"
              htmlType={'button'}
              className={"ant-btn-info"}
              loading={isLoading}
              text="Generar correo"
            />
          </Popconfirm>
          {record.estado_envio_rechazado && (
            <Paragraph style={{ marginBottom: 0 }}>Enviado</Paragraph>
          )}
        </Space>
      ),
    },
  ];

  const sendEmail = async (id) => {
    try {
      setIsLoading(true);
      let data = {
        convocatoria_tercero: id,
        convocatoria: announcement_id,
        estado_tercero: 13,
      };
      const response = await post(data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          window.location.reload();
          setIsLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setIsLoading(false);
    }
  };

  return (
    <SpinLoad loading={loading}>
      {dataSource != null && (
        <AjaxTable
          columns={columns}
          pageSize={10}
          tableTitle={'Listado de contratistas rechazados'}
          endpoint={filterAjaxTable}
          searchInput
          exportCSV
          reloadButton
          query={`/?filter[convocatoria_rol]=${announcement_rol_id}&filter[estado_tercero]=13`}
        />
      )}
    </SpinLoad>
  );
};

export default Rechazados;
