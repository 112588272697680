import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/project_positions/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_positions/`,
  );
};

const getRoleByProject = async (project_id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_positions/?filter[proyecto]=${project_id}&page[size]=1000`,
  );
};

const filterTDR = async (query) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_positions/with_validated_tdr${query}`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/project_positions/${id}/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/project_positions/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/project_positions/${id}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/project_positions/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/project_positions` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

export {
  post,
  getAll,
  getRoleByProject,
  get,
  put,
  remove,
  filterTDR,
  ajaxTable,
};
