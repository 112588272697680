import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Divider,
  Descriptions,
  Modal,
  notification,
  Form,
  Input,
  Popconfirm,
  Card,
  Typography,
} from 'antd';

import CustomButton from '../../components/CustomButton';
import { IsRole } from '../../utils/helpers/helpers';
import MESSAGES from '../../enums/Messages';

import { post, filter } from '../../api/module/announcement_preselection';
import {
  post as postReject,
  filter as filterReject,
} from '../../api/module/announcement_reject';

import './style.scss';
import { get as getDocumentUrl } from '../../api/module/document_repository';
import SpinLoad from '../../components/SpinLoad';

const { Title, Paragraph } = Typography;

const ButtonSection = ({
  initialValues,
  item,
  convocatoria_rol,
  rejected,
  refresh = () => {
    window.location.reload();
  },
  isPreview = false,
}) => {
  const [person_id] = useState(parseInt(window.localStorage['tercero']));
  const [has_applied, setApplied] = useState(null);
  const [superUser] = useState(IsRole(6));
  const [loadingApply, setLoadingApply] = useState(false);

  useEffect(() => {
    setLoadingApply(true);
    getApplication();
    setLoadingApply(false);
  }, []);

  const getApplication = async () => {
    let response = await filter(
      `?filter[tercero]=${person_id}&filter[convocatoria_rol]=${convocatoria_rol}`,
    );
    setApplied(true);

    if (response.data.results.length === 0) {
      setApplied(false);
    }
  };

  const sendConfirmation = async (id) => {
    setLoadingApply(true);
    try {
      const data = {
        convocatoria: initialValues.id,
        convocatoria_rol: id,
        tercero: person_id,
        estado_tercero: 10,
      };
      const response = await post(data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        setApplied(true);
        refresh();
      }
    } catch (err) {
      console.log(err);
      try {
        if (err.response.data.errors[0].code === 'unique') {
          Modal.error({
            title: 'Usted ya ha aplicado a este cargo previamente',
            content: (
              <>
                <Divider />
                <Paragraph>
                  Recuerde completar la información de registro por la opción
                  "Perfil".
                </Paragraph>
                <Paragraph>
                  Nos estaremos comunicando con usted por medio del correo
                  electrónico registrado. (Revise las bandejas de entrada de su
                  correo electrónico, incluyendo notificaciones, publicidad y
                  correo no deseado (spam))
                </Paragraph>
                <Paragraph>Gracias</Paragraph>
              </>
            ),
          });
          setApplied(true);
        } else throw err;
      } catch (err) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_SEND_CONFIRMATION_MSG,
          duration: 3,
        });
      }
    }
    setLoadingApply(false);
  };

  return (
    <>
      {!superUser && rejected === false && !isPreview && (
        <Descriptions column={3} bordered>
          <Descriptions.Item label="Aceptar y aplicar a cargo/rol" span={3}>
            <SpinLoad loading={has_applied === null}>
              {has_applied === false &&
                initialValues.estado_convocatoria === 8 && (
                  <Popconfirm
                    onConfirm={() => {
                      sendConfirmation(item.id);
                    }}
                    title=" Tenga en cuenta que usted está aceptando los requisitos de la presente convocatoria al aceptarla"
                    okText="Acepto"
                    cancelText="No Acepto"
                  >
                    <CustomButton
                      loading={loadingApply}
                      htmlType="button"
                      text="Aceptar y aplicar a este cargo/rol"
                      disabled={has_applied}
                    />
                  </Popconfirm>
                )}
            </SpinLoad>
            {has_applied && (
              <Alert
                message={'Usted ya ha aplicado a este cargo'}
                description={
                  'Recuerde completar la información de registro por la opción "Perfil", incluyendo los documentos requeridos en la convocatoria. Por favor revise constantemente las bandejas de entrada de su correo electrónico, incluyendo notificaciones, publicidad y correo no deseado (spam).'
                }
                type="info"
                showIcon
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

const AnnouncementShowForm = ({
  initialValues,
  initialValuesCargoRol,
  back,
  isPreview = false,
}) => {
  const [formReject] = Form.useForm();
  const [superUser] = useState(IsRole(6));
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [reject, setReject] = useState(false);
  const [isRejected, setIsRejected] = useState(null);
  const [hasApplied, setHasApplied] = useState(null);

  const periodOptions = {
    1: 'Mensual',
    2: 'Total contrato',
    3: 'Entrega de productos',
    4: 'Otro',
  };

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    await Promise.all([getReject(), getApplications()]);
  };

  const getApplications = async () => {
    let response = await filter(
      `?filter[tercero]=${window.localStorage['tercero']}&filter[convocatoria]=${initialValues.id}`,
    );

    if (response.data.results.length > 0) setHasApplied(true);
    else setHasApplied(false);
  };

  const announcementReject = async (data) => {
    try {
      setReject(true);
      data['tercero'] = parseInt(window.localStorage['tercero']);
      data['convocatoria'] = initialValues.id;
      let response = await postReject(data);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        setReject(false);
        notification.error({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        window.location.reload();
      }
    } catch (err) {
      setReject(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 3,
      });
    }
  };

  const getReject = async () => {
    let response = await filterReject(
      `?filter[tercero]=${parseInt(
        window.localStorage['tercero'],
      )}&filter[convocatoria]=${initialValues.id}`,
    );

    if (response.data.results.length === 0) setIsRejected(false);
    else setIsRejected(true);
  };

  const showDocument = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(documento_nombre);

    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Card
      size={'small'}
      bordered={false}
      title={
        <Title level={3} style={{ whiteSpace: 'normal' }}>
          {initialValues.nombre}
        </Title>
      }
      extra={
        <img
          src={initialValues.organizacion_logo}
          alt="Logo Organización"
          style={{ width: 'calc(250px - 20px)' }}
        />
      }
    >
      <Descriptions
        className="description-label-style"
        title="Información de la convocatoria"
        bordered
        column={1}
      >
        <Descriptions.Item label="Organización">
          {initialValues.organizacion_nombre}
          {initialValues.organizacion_sigla &&
            ` - ${initialValues.organizacion_sigla}`}
        </Descriptions.Item>
        <Descriptions.Item label="Proyecto, licitación, concurso u otro">
          {initialValues.nombre_proyecto}
        </Descriptions.Item>
        <Descriptions.Item label="Número de convocatoria">
          {initialValues.numero}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre de la convocatoria">
          {initialValues.nombre}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha inicio de la convocatoria">
          {initialValues.fecha_inicio.format('DD - MMMM - YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha fin de la convocatoria">
          {initialValues.fecha_fin.format('DD - MMMM - YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Descripción de la convocatoria">
          {initialValues.descripcion.split('\n').map((value, index) => (
            <>
              {value} <br />
            </>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Archivo anexo de la convocatoria">
          {initialValues.documento_anexo_nombre ? (
            <CustomButton
              block
              type="default"
              text="Ver"
              htmlType="button"
              loading={loadingDocument}
              onClick={() => {
                showDocument(initialValues.documento_anexo_nombre);
              }}
            />
          ) : (
            'Sin adjunto'
          )}
        </Descriptions.Item>
      </Descriptions>
      {initialValuesCargoRol.map((item, index) => (
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={5}>Cargo / Rol No. {index + 1}</Title>}
        >
          <Descriptions className="description-label-style" bordered column={1}>
            <Descriptions.Item label="Cargo / Rol">
              {item.cargo.split('\n').map((value, index) => (
                <>
                  {value} <br />
                </>
              ))}
            </Descriptions.Item>
            <Descriptions.Item
              label={`Remuneración ${
                item.remuneracion_variable != null ? 'variable' : 'fija'
              }`}
            >
              {item.remuneracion_variable != null
                ? item.remuneracion_variable.split('\n').map((value, index) => (
                    <>
                      {value} <br />
                    </>
                  ))
                : item.remuneracion_fija != null
                ? `$ ${item.remuneracion_fija}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ',',
                  )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Período">
              {periodOptions[item.periodo]}
            </Descriptions.Item>
            {item.periodo === 4 && (
              <Descriptions.Item label="¿Cuál?">
                {item.periodo_cual}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Tipo contrato">
              {item.tipo_contrato_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Duración del proyecto">
              {item.duracion} (Meses)
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            style={{ marginTop: 20 }}
            className="description-label-style"
            title="Lugar de realización de labores"
            bordered
            column={1}
          >
            <Descriptions.Item label="País">
              {item.pais_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Departamento">
              {item.departamento_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Ciudad">
              {item.ciudad_nombre}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            style={{ marginTop: 20 }}
            className="description-label-style"
            title="Requisitos"
            column={3}
            bordered
          >
            <Descriptions.Item label="Estudios" span={3}>
              {item.estudios.split('\n').map((value, index) => (
                <>
                  {value} <br />
                </>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo de experiencia laboral" span={3}>
              {item.tipo_experiencia_laboral.split('\n').map((value, index) => (
                <>
                  {value} <br />
                </>
              ))}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ width: '50px' }}
              label="Disponibilidad para viajar"
            >
              {item.disponibilidad_viajar ? 'Si' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Posibilidad de radicarse en otra ciudad durante el proyecto">
              {item.radicarse_otra_ciudad ? 'Si' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ width: '50px' }}
              label="Tiempo de experiencia laboral (meses)"
            >
              {item.tiempo_experiencia_laboral}{' '}
            </Descriptions.Item>
          </Descriptions>

          <ButtonSection
            rejected={isRejected}
            initialValues={initialValues}
            item={item}
            isPreview={isPreview}
            convocatoria_rol={item.id}
          />
        </Card>
      ))}
      {!isPreview && (
        <SpinLoad loading={isRejected === null}>
          {!superUser && isRejected === false && hasApplied === false && (
            <Form
              form={formReject}
              layout="vertical"
              onFinish={(data) => {
                announcementReject(data);
              }}
            >
              <Descriptions title="Rechazar convocatoria" bordered>
                <Descriptions.Item label="Motivo de rechazo">
                  <Form.Item name="motivo">
                    <Input.TextArea
                      placeholder={'Motivo de rechazo'}
                      rows={5}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="Sr usuario(a), si usted rechaza esta convocatoria recuerde que no podrá aplicar a esta nuevamente"
                      onConfirm={() => {
                        formReject.submit();
                      }}
                      okText="Sí"
                    >
                      <CustomButton loading={reject} text={'Guardar'} />
                    </Popconfirm>
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Form>
          )}
          {!superUser && isRejected && (
            <Alert
              message="Atención"
              description="La convocatoria ha sido rechazada."
              type="warning"
              showIcon
            />
          )}
        </SpinLoad>
      )}
      <CustomButton
        style={{ marginTop: 10 }}
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
        danger
      />
    </Card>
  );
};

AnnouncementShowForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

AnnouncementShowForm.defaultProps = {
  initialValues: {},
  loading: false,
  disabled: false,
};
export default AnnouncementShowForm;
