import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal,
  DatePicker,
  Typography,
  Form,
  Input,
  Row,
  Col,
  notification,
  Radio,
} from 'antd';

import MESSAGES from '../../enums/Messages';

import { postTemplateDocuments } from '../../api/module/templates';
import { get as getTemplateType } from '../../api/module/template_types';
import CustomUpload from '../../components/CustomUpload';

const { Title } = Typography;
const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const LoadTemplateForm = ({
  isModalVisible,
  setIsModalVisible,
  hiring_type_id,
  hiring_stage_id,
  person_type_id,
  contract_type_id,
  template_id,
  isUpdate,
  onRefresh,
}) => {
  const [form] = Form.useForm();
  const [templateCode, setTemplateCode] = useState(null);
  const [templateName, setTemplateName] = useState(null);

  const onFinish = async (data) => {
    try {
      let formData = new FormData();
      if (
        data.documento.fileList[0].type ==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        data.plantilla = data.documento.fileList[0].originFileObj;
        if (hiring_type_id !== 0) {
          data.tipo_contratacion = hiring_type_id;
        }
        if (hiring_stage_id !== 0) {
          data.etapa_contratacion = hiring_stage_id;
        }
        if (contract_type_id !== 0) {
          data.tipo_contrato = contract_type_id;
        }
        data.tipo_persona = person_type_id;
        data.tipo_plantilla = template_id;
        data.fecha = moment(data.fecha).format('YYYY-MM-DD');

        // formData.append('documento', data);
        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }
        await postTemplateDocuments(formData);
        await onRefresh();
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };
  const getTemplateTypeData = async () => {
    try {
      let response = await getTemplateType(template_id);

      if (person_type_id === 1) {
        setTemplateCode(response.data.codigo);
        setTemplateName(response.data.nombre);
      } else {
        setTemplateCode(response.data.codigo_pj);
        setTemplateName(response.data.nombre);
      }
    } catch (exception) {
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    await getTemplateTypeData();
  }, []);

  return (
    <>
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            {isUpdate ? 'Actualizar' : 'Agregar'} Plantilla
          </Title>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText={isUpdate ? 'Actualizar' : 'Crear'}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            form.setFieldsValue({ documento: '' });
            onFinish(values);
            setIsModalVisible(false);
          });
        }}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          validateMessages={validateMessages}
        >
          {!isUpdate && (
            <>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    label="Código"
                    name="codigo"
                    initialValue={templateCode}
                    rules={[{ required: false, message: 'es requerido' }]}
                  >
                    <Input placeholder="Código..." allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Fecha"
                    name="fecha"
                    rules={[{ required: false, message: 'es requerido' }]}
                  >
                    <DatePicker
                      disabled
                      defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                      format="YYYY-MM-DD"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Versión"
                    name="version"
                    initialValue={1}
                    rules={[
                      {
                        required: false,
                        message: 'es requerido',
                      },
                    ]}
                  >
                    <Input
                      type={'number'}
                      placeholder="Versión..."
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    name="nombre"
                    initialValue={templateName}
                    rules={[{ required: true, message: 'es requerido' }]}
                  >
                    <Input placeholder="Nombre..." allowClear />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <CustomUpload
            file_type={4}
            upload_type={2}
            name="documento"
            label="Documento"
            required={true}
          />
          <Form.Item
            label="¿El logo de este documento cambia según la organización ejecutora?"
            name="reemplazo_logo"
            rules={[{ required: true, message: 'es requerido' }]}
            // valuePropName="checked"
          >
            <Radio.Group>
              <Radio value={true}>Si</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoadTemplateForm;
