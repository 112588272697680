import httpClient from '../httpClient';

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/groups/`);
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/groups/${id}/`,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/groups/${id}/`,
  );
};

export { getAll, get, remove };
