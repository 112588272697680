import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import DocumentTypeForm from '../../../containers/DocumentTypeForm/index';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';

import { post, put, get } from '../../../api/module/document_types';

const DocumentTypesForms = ({ history, match }) => {
  const [documentTypeID, setDocumentTypeID] = useState(
    parseInt(match.params.id),
  );
  const [title, setTitle] = useState('Creación Tipo de Documento');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const save = async (data) => {
    try {
      let response;
      if (!documentTypeID) {
        setLoading(true);
        response = await post(data);
      } else {
        setLoading(true);
        response = await put(documentTypeID, data);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(INTERNAL_LINKS.DOCUMENT_TYPES);
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  // Get Data by ID
  const handleGetData = () => {
    try {
      if (documentTypeID) {
        get(documentTypeID).then((res) => setData(res.data));
        setTitle('Actualización Tipo de Documento');
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <>
      {data != null && (
        <DocumentTypeForm
          title={title}
          onFinish={save}
          initialValues={data}
          loading={loading}
        />
      )}
    </>
  );
};

export default DocumentTypesForms;
