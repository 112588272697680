import httpClient from '../httpClient';

const getProjects = async (params) => {
  return await httpClient.get('/term_document_validation_selectors/projects/', {
    params: params,
  });
};

const getRoles = async (params) => {
  return await httpClient.get('/term_document_validation_selectors/roles/', {
    params: params,
  });
};

const post = async (data) => {
  return await httpClient.post(`/term_document_validation/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_document_validation/${id}/`);
};

const info = async (id) => {
  return await httpClient.get(`/term_document_validation/${id}/info/`);
};

const filter = async (query, page, pageSize) => {
  return await httpClient.get(
    `/term_document_validation${query}&page[number]=${page}&page[size]=${pageSize}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = 'id',
  search = false,
  exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;

  if (exportCSV)
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_document_validation/export` +
        `${query}&sort=${sortBy}` +
        `${searchBy}`,
      {
        responseType: 'blob',
      },
    );
  else
    return await httpClient.get(
      `${process.env.REACT_APP_API_HOST}/term_document_validation` +
        `${query}&page[number]=${page}&page[size]=${pageSize}` +
        `&sort=${sortBy}` +
        `${searchBy}`,
    );
};

const getCounters = async (query) => {
  return await httpClient.get(`term_document_validation/counters${query}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/term_document_validation/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/term_document_validation/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_document_validation/${id}`);
};

const getValidationDetail = async (id) => {
  return await httpClient.get(
    `/term_document_validation/${id}/validation_detail/`,
  );
};

const getAllValidationDetails = async () => {
  return await httpClient.get(
    `/term_document_validation/validation_detail_list/`,
  );
};

const notify = async (id) => {
  return await httpClient.get(`/term_document_validation/${id}/notify/`);
};

export {
  post,
  get,
  filter,
  ajaxTable,
  getCounters,
  put,
  patch,
  remove,
  getValidationDetail,
  getAllValidationDetails,
  notify,
  getProjects,
  getRoles,
  info,
};
