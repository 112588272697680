import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  Select,
  Typography,
  Popconfirm,
  notification,
  Modal,
  Input,
  Form,
  Skeleton,
  List,
  Layout,
  Space,
  Row,
  Col,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import DocumentListViewer from '../../../components/DocumentListViewer';
import MESSAGES from '../../../enums/Messages';

import {
  getAllByValidation as getAllBasicByValidation,
  post as saveBasicValidation,
} from '../../../api/module/term_basic_validation';

import { info } from '../../../api/module/term_document_validation';
import { get as getDocUrl } from '../../../api/module/document_repository';
import { filter as filterStatus } from '../../../api/module/status_process';
import { getAllByValidation as getValidationHistory } from '../../../api/module/term_document_validation_history';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import {
  getAllByValidation as getAllTrainingByValidation,
  post as saveTrainingValidation,
} from '../../../api/module/term_training_validation';

import {
  getAllByValidation as getAllExperienceByValidation,
  post as saveExperienceValidation,
} from '../../../api/module/term_experience_validation';

import {
  getAllByValidation as getAllICFESByValidation,
  post as saveICFESValidation,
} from '../../../api/module/term_icfes_experience_validation';

import '../style.scss';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Validación de Documentos Precontractuales',
    to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}`,
  },
  {
    text: 'Validación Precontractual',
  },
];

const tabNameMap = {
  basicos: 'Básicos obligatorios',
  formacion: 'Educación/Formación',
  experiencia: 'Experiencia',
  icfes: 'Experiencia ICFES',
};

export default function DocumentValidationPrecontractualForm({ match }) {
  const [validationData, setValidationData] = useState(undefined);

  async function fetchData() {
    try {
      const validationId = match.params.validation_id;
      const res = await info(validationId);
      setValidationData(res.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.validation_id]);

  const clearAll = async () => {
    setValidationData(undefined);
    fetchData();
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Validación de Documentos Precontractuales
      </Title>
      {validationData && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions
            layout="vertical"
            title="Información del TDR"
            column={2}
            bordered
          >
            <Descriptions.Item label="Proyecto a revisar">
              {validationData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/rol a revisar">
              {validationData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.tercero.nombre_completo}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tercero.tipo_identificacion_nombre} - ${validationData.tercero.numero_identificacion}`}
            </Descriptions.Item>
            <Descriptions.Item label="Observación devolución por jurídico">
              <ReturnedObservations dataSource={validationData.vdc_lista} />
            </Descriptions.Item>
          </Descriptions>
          <ValidationModules
            personData={validationData.tercero}
            validationData={validationData}
            clearAll={clearAll}
          />
        </Space>
      )}
    </Content>
  );
}

function ReturnedObservations({ dataSource }) {
  return dataSource && dataSource.length > 0 ? (
    <List
      rowKey="id"
      dataSource={dataSource}
      renderItem={(item) => {
        return (
          <List.Item>
            <List.Item.Meta
              title={`Fecha y hora de la devolución: ${moment(
                item.updated_at,
              ).format('DD MMMM YYYY, hh:mm:ss')}`}
              description={
                item.observaciones_general
                  ? item.observaciones_general
                  : 'Sin observaciones'
              }
            />
          </List.Item>
        );
      }}
    />
  ) : (
    'Sin devoluciones'
  );
}

function ValidationModules({
  personData,
  validationData,
  clearAll = () => {},
}) {
  const [tab, setTab] = useState(undefined);
  const [selectOpt, setSelectOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState(undefined);

  const [docList, setDocList] = useState([]);
  const [validationHistory, setValidationHistory] = useState([]);
  const [changed, setChanged] = useState(false);

  const [isSaving, setSaving] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);

  async function fetchDataByType(fetchData = async () => {}, newTab) {
    try {
      const res = await fetchData(validationData.id, personData.tipo_persona);
      const docs = res.data.results;
      setDocList(docs);
      setTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
    }
  }

  const changeTab = async (newTab) => {
    setTabLoading(true);

    switch (newTab) {
      case 'basicos':
        await fetchDataByType(getAllBasicByValidation, 'basicos');
        break;
      case 'formacion':
        await fetchDataByType(getAllTrainingByValidation, 'formacion');
        break;
      case 'experiencia':
        await fetchDataByType(getAllExperienceByValidation, 'experiencia');
        break;
      case 'icfes':
        await fetchDataByType(getAllICFESByValidation, 'icfes');
        break;
      default:
        break;
    }

    setTabLoading(false);
  };

  const checkChanged = (newTab) => {
    if (changed) {
      Modal.confirm({
        title: MESSAGES.MSG_UNSAVED_CHANGES,
        icon: <ExclamationCircleOutlined />,
        content: MESSAGES.MSG_UNSAVED_MSG,
        okText: 'Si',
        cancelText: 'Volver',
        onOk: () => {
          changeTab(newTab);
          setChanged(false);
        },
      });
    } else {
      changeTab(newTab);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await filterStatus('/?filter[sigla.icontains]=TDR-VDP-M-');
        const states = res.data.results.map((x) => {
          return { label: x.nombre, value: x.id, sigla: x.sigla };
        });
        const resD = await filterStatus('/?filter[sigla.icontains]=TDR-VDP-D-');
        const statesD = resD.data.results.map((x) => {
          return { label: x.nombre, value: x.id, sigla: x.sigla };
        });
        const hist = await getValidationHistory(validationData.id);
        setValidationHistory(hist.data.results);
        setStateOpt(states);
        setSelectOpt(statesD);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [validationData]);

  const onFinish = async (values) => {
    setSaving(true);
    const data = values;
    data.validacion_documentos = validationData.id;
    try {
      setTabLoading(true);

      switch (tab) {
        case 'basicos':
          await saveBasicValidation(data);
          break;
        case 'formacion':
          await saveTrainingValidation(data);
          break;
        case 'experiencia':
          await saveExperienceValidation(data);
          break;
        case 'icfes':
          await saveICFESValidation(data);
          break;

        default:
          throw new Error('Modulo no encontrado');
      }

      setTabLoading(false);
      clearAll();
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
      setSaving(false);
    }
  };

  return stateOpt ? (
    <Content>
      <Descriptions
        layout={personData.tipo_persona === 2 ? 'horizontal' : 'vertical'}
        title="¿Qué documentos desea validar?"
        column={4}
        bordered
        labelStyle={{ display: 'block', textAlign: 'center' }}
        contentStyle={{ display: 'block', textAlign: 'center' }}
        style={{ marginTop: '15px' }}
      >
        <Descriptions.Item
          label={
            <CustomButton
              text="Básicos obligatorios"
              type={tab === 'basicos' ? 'primary' : 'default'}
              onClick={() => checkChanged('basicos')}
            />
          }
        >
          <Space direction={'vertical'}>
            <Text>Estado de revisión:</Text>
            <Text strong>
              {validationData.estado_validacion_basicos
                ? stateOpt.find(
                    (e) => e.value === validationData.estado_validacion_basicos,
                  ).label
                : 'Por revisar'}
            </Text>
          </Space>
        </Descriptions.Item>
        {personData.tipo_persona === 1 && (
          <>
            <Descriptions.Item
              label={
                <CustomButton
                  text="Educación/Formación"
                  type={tab === 'formacion' ? 'primary' : 'default'}
                  onClick={() => checkChanged('formacion')}
                />
              }
            >
              <Space direction={'vertical'}>
                <Text>Estado de revisión:</Text>
                <Text strong>
                  {validationData.estado_validacion_formacion
                    ? stateOpt.find(
                        (e) =>
                          e.value ===
                          validationData.estado_validacion_formacion,
                      ).label
                    : 'Por revisar'}
                </Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  text="Experiencia"
                  type={tab === 'experiencia' ? 'primary' : 'default'}
                  onClick={() => checkChanged('experiencia')}
                />
              }
            >
              <Space direction={'vertical'}>
                <Text>Estado de revisión:</Text>
                <Text strong>
                  {validationData.estado_validacion_experiencia
                    ? stateOpt.find(
                        (e) =>
                          e.value ===
                          validationData.estado_validacion_experiencia,
                      ).label
                    : 'Por revisar'}
                </Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <CustomButton
                  text="Experiencia ICFES"
                  type={tab === 'icfes' ? 'primary' : 'default'}
                  onClick={() => checkChanged('icfes')}
                />
              }
            >
              <Space direction={'vertical'}>
                <Text>Estado de revisión:</Text>
                <Text strong>
                  {validationData.estado_validacion_icfes
                    ? stateOpt.find(
                        (e) =>
                          e.value === validationData.estado_validacion_icfes,
                      ).label
                    : 'Por revisar'}
                </Text>
              </Space>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
      {(tab === 'experiencia' || tab === 'icfes') && (
        <Descriptions
          layout="vertical"
          column={1}
          bordered
          style={{ marginTop: '15px' }}
        >
          <Descriptions.Item label={'Requerimientos de experiencia'}>
            <b>
              {validationData.requerimiento_experiencia_tdr
                ? `Requerimiento proviene de TDR con identificador interno '${validationData.identificador_tdr}':`
                : `Requerimiento proviene de Equipo de trabajo '${validationData.cargo_nombre}':`}
            </b>
            <br />
            <br />
            {validationData.requerimiento_experiencia_tdr
              ? validationData.requerimiento_experiencia_tdr
              : validationData.requerimiento_experiencia}
          </Descriptions.Item>
        </Descriptions>
      )}
      {tab === 'formacion' && (
        <Descriptions
          layout="vertical"
          column={1}
          bordered
          style={{ marginTop: '15px' }}
        >
          <Descriptions.Item label={'Requerimientos de educación / formación'}>
            <b>
              {validationData.requerimiento_formacion_tdr
                ? `Requerimiento proviene de TDR con identificador interno '${validationData.identificador_tdr}':`
                : `Requerimiento proviene de Equipo de trabajo '${validationData.cargo_nombre}':`}
            </b>
            <br />
            <br />
            {validationData.requerimiento_formacion_tdr
              ? validationData.requerimiento_formacion_tdr
              : validationData.requerimiento_formacion}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Skeleton loading={tabLoading} active>
        {tab && (
          <DocViewerForm
            title={`${tabNameMap[tab]} / ${personData.nombre_completo} - ${personData.numero_identificacion}`}
            selectOpt={selectOpt}
            initialValues={{ ...validationData, doc_list: docList }}
            type={tab}
            stateOpt={stateOpt}
            onFinish={onFinish}
            setChanged={setChanged}
            history={validationHistory}
            disabled={
              validationData.estado_validacion_general
                ? validationData.estado_validacion_general !== 49
                : isSaving
            }
            isSaving={isSaving}
            onlyFullValid={stateOpt.find((x) => x.sigla === 'TDR-VDP-M-AC')}
            invalidStates={selectOpt
              .filter(
                (x) => x.sigla === 'TDR-VDP-D-NC' || x.sigla === 'TDR-VDP-D-SC',
              )
              .map((x) => x.value)}
            unloadedSelect={selectOpt.filter(
              (x) => x.sigla === 'TDR-VDP-D-NR' || x.sigla === 'TDR-VDP-D-SC',
            )}
            cargo={validationData.cargo}
          />
        )}
        {!tab && (
          <CustomButton
            style={{ marginTop: 10 }}
            type="default"
            htmlType="button"
            className={'back-button'}
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}/role=${validationData.cargo}`}
              >
                Volver
              </Link>
            }
          />
        )}
      </Skeleton>
    </Content>
  ) : (
    <Skeleton loading={true} />
  );
}

function DocViewerForm({
  title,
  selectOpt,
  initialValues,
  history = [],
  type = '',
  stateOpt,
  onFinish = () => {},
  setChanged = () => {},
  onlyFullValid,
  invalidStates = [],
  unloadedSelect,
  disabled = true,
  isSaving = false,
  cargo,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form, type]);
  return (
    <Form
      name={`docValidationViewer-${type}`}
      onFinish={onFinish}
      onFinishFailed={() =>
        Modal.error({
          title: 'Existen errores en el formulario',
          content:
            'Recuerde que los campos de validación de cada documento y el estado del modulo son obligatorios',
        })
      }
      initialValues={initialValues}
      form={form}
      onValuesChange={() => {
        setChanged(true);
      }}
      key={type}
    >
      <Form.List name="doc_list">
        {() => {
          return (
            <DocumentListViewer
              listTitle={title}
              docList={initialValues.doc_list}
              urlLoadFunc={async (record) => {
                const res = await getDocUrl(record.nombre_archivo);
                return res.data.url;
              }}
              extraColumnsLeft={[
                {
                  title: 'Documento',
                  dataIndex: 'documento',
                  width: '8em',
                },
              ]}
              extraColumnsRight={[
                {
                  title: 'Documento validado',
                  key: 'selector',
                  render: (_, record, index) => {
                    return (
                      <Form.Item
                        name={[index, 'estado_validacion']}
                        rules={[{ required: true }]}
                        isListField
                        noStyle
                      >
                        <Select
                          placeholder="Seleccione..."
                          options={
                            record.nombre_archivo ? selectOpt : unloadedSelect
                          }
                          disabled={disabled}
                        />
                      </Form.Item>
                    );
                  },
                },
              ]}
            />
          );
        }}
      </Form.List>
      <Descriptions layout="vertical" column={1} bordered>
        <Descriptions.Item
          label={`Observaciones del módulo ${tabNameMap[type]}`}
        >
          <Form.Item name={`observaciones_${type}`} noStyle>
            <TextArea
              placeholder="Observaciones"
              disabled={disabled}
              rows={4}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          label={`Historial de observaciones del módulo ${tabNameMap[type]}`}
        >
          <List
            rowKey="id"
            dataSource={history}
            renderItem={(item) => {
              let showState;
              if (item[`estado_validacion_${type}`]) {
                showState = stateOpt.find(
                  (e) => e.value === item[`estado_validacion_${type}`],
                );
                showState = showState.label;
              } else {
                return;
              }
              return (
                <List.Item>
                  <List.Item.Meta
                    title={`${moment(item.created_at).format(
                      'DD MMMM YYYY, hh:mm:ss',
                    )} - ${showState}`}
                    description={
                      item[`observaciones_${type}`]
                        ? item[`observaciones_${type}`]
                        : 'Sin observaciones'
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={12} style={{ marginTop: 15 }}>
        <Col span={24}>
          <Form.Item
            name={`estado_validacion_${type}`}
            label={
              <Text strong>
                'Estado de revision del módulo para este Cargo/Rol'
              </Text>
            }
            dependencies={['doc_list']}
            rules={[
              { required: true, message: 'Seleccione un estado!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value === onlyFullValid.value) {
                    let hasInvalid = false;
                    const dList = getFieldValue('doc_list');
                    for (const i of dList) {
                      if (
                        !i.estado_validacion ||
                        invalidStates.includes(i.estado_validacion)
                      ) {
                        hasInvalid = true;
                        break;
                      }
                    }

                    if (hasInvalid) {
                      return Promise.reject(
                        new Error(
                          `${onlyFullValid.label} requiere que todos los documentos sean validos!`,
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="Seleccione el estado..."
              options={stateOpt}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Space>
        {(!disabled || isSaving) && (
          <Popconfirm
            onConfirm={() => {
              form.submit();
            }}
            disabled={disabled}
            title={MESSAGES.MSG_SAVE_STATE}
          >
            <CustomButton
              type="primary"
              text="Guardar"
              htmlType="button"
              disabled={disabled}
              loading={isSaving}
            />
          </Popconfirm>
        )}
        <CustomButton
          type="default"
          htmlType="button"
          className={'back-button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}/role=${cargo}`}
            >
              Volver
            </Link>
          }
        />
      </Space>
    </Form>
  );
}
