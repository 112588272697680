import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BackTop, Button, Layout } from 'antd';

import INTERNAL_LINKS from '../../enums/InternalLinks';
import React from 'react';
import UserLoginForm from '../UserLoginForm';
import UserRecoverPasswordForm from '../UserRecoverPasswordForm';
import UserEmailForm from '../UserEmailForm';
import UserVerify from '../UserVerify';
import PublicAnnouncement from '../../pages/PublicAnnouncement';
import { Content } from 'antd/es/layout/layout';
import UserAuthorizeForm from '../UserAuthorizeForm';

import './style.scss';

export default class PublicLayout extends React.Component {
  render() {
    return (
      <Layout className={'public-layout'}>
        <Router>
          <Content className={'public-page-content'}>
            <Route
              exact
              component={() => {
                return <UserLoginForm />;
              }}
              path={INTERNAL_LINKS.HOME}
            />
            <Route
              exact
              component={(props) => {
                return <UserRecoverPasswordForm {...props} />;
              }}
              path={INTERNAL_LINKS.RECOVER_PASSWORD}
            />
            <Route
              exact
              component={() => {
                return <UserEmailForm />;
              }}
              path={INTERNAL_LINKS.RECOVER_EMAIL}
            />
            <Route
              exact
              component={() => {
                return <UserAuthorizeForm />;
              }}
              path={INTERNAL_LINKS.REGISTER}
            />
            <Route
              exact
              component={(props) => {
                return <UserVerify {...props} />;
              }}
              path={INTERNAL_LINKS.VERIFY}
            />
            <Route
              exact
              component={(props) => {
                return <PublicAnnouncement {...props} />;
              }}
              path={`${INTERNAL_LINKS.PUBLIC_ANNOUNCEMENT}/:id`}
            />
            <div className="footer">
              <br/>
              <br/>
              <p>
                &copy; {new Date().getFullYear()} Todos los derechos reservados
                Corpoeducación.
              </p>
            </div>
          </Content>
        </Router>
        <BackTop>
          <Button>Arriba</Button>
        </BackTop>
      </Layout>
    );
  }
}
