import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, Checkbox, notification, Alert, Modal } from 'antd';

import { CustomButton } from '../../../components';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { patch, post } from '../../../api/module/contract_modifications';
import { catchException } from '../../../utils/helpers/helpers';
import { CustomTooltip } from '../../../components/CustomTooltip';
import MESSAGES from '../../../enums/Messages';
import ModulesDraft from './ModulesDraft';

const { Title, Text } = Typography;
const ModulesList = ({ id = false, modules = [], onFinish }) => {
  const [selected, setSelected] = useState(
    modules.reduce((pv, cv) => {
      pv[cv.id] = cv.s;
      return pv;
    }, {}),
  );
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const onSelfChange = (e, id) => {
    if (alert) setAlert(false);
    if (id === 5 && e.target.checked)
      setSelected((s) => ({ ...s, 5: true, 2: true, 3: true }));
    else setSelected((s) => ({ ...s, [id]: e.target.checked }));
  };

  const onSelfFinish = async () => {
    try {
      setLoading(true);
      if (
        selected[7] &&
        !Object.entries({ ...selected, 7: false }).some((x) => x[1])
      ) {
        setAlert(true);
        throw Error('M7');
      }
      const toDelete = modules.reduce((pv, cv) => {
        if (cv.e && !selected[cv.id]) pv.push(cv.nombre);
        return pv;
      }, []);
      if (toDelete.length > 0) {
        Modal.confirm({
          title: 'Alerta',
          // width: '80%',
          content: (
            <>
              <br />
              Ud desmarcó la(s) opción(es):
              <br />
              <br />
              <ul>
                {toDelete.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
              <br />
              Al desmarcarla(s) se ELIMINARÁ la información
              registrada/modificada por Ud. u otros usuarios.
              <br />
              <br />
              ¿Desea eliminarla y continuar?
            </>
          ),
          okText: 'Si',
          onOk: async () => {
            try {
              await onFinish(selected);
            } catch (error) {
            } finally {
              setLoading(false);
            }
          },
          cancelText: 'No',
          onCancel: () => {
            setLoading(false);
          },
        });
      } else {
        await onFinish(selected);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const columns = [
    {
      title: 'Módulos',
      dataIndex: 'nombre',
      render: (text, record) => {
        return (
          <>
            {`${record.id}. ${text}`}
            <CustomTooltip title={record.info} />
          </>
        );
      },
    },
    {
      title: 'Módulos a modificar',
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => {
        return (
          <Checkbox
            defaultChecked={record.s}
            checked={selected[id]}
            disabled={(id === 2 || id === 3) && selected[5]}
            onChange={(e) => onSelfChange(e, id)}
          />
        );
      },
    },
    {
      title: 'Observaciones',
      key: 'observations',
      render: (_, record) => {
        if ((record.id === 2 || record.id === 3) && selected[5])
          return (
            <p style={{ color: 'GrayText' }}>
              Este ítem se marca automáticamente al seleccionar{' '}
              <b style={{ color: 'black' }}>5. Cambio de forma de pago.</b> Es
              requerido para procesos posteriores.
            </p>
          );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'e',
      render: (e, record) => {
        if (!record.s) return null;
        if (record.id === 2 || record.id === 3) {
          return (
            <>
              {e ? (
                <p className="modification-p-active">Actualizado.</p>
              ) : (
                <p className="modification-p-active">
                  Generado automáticamente por la plataforma. Ud puede
                  modificarlo en caso de requerirlo.
                </p>
              )}
            </>
          );
        }
        return (
          <>{e && <p className="modification-p-active">Actualizado.</p>}</>
        );
      },
    },
  ];
  return (
    <>
      <Title level={3}>Selección de modificaciones</Title>
      <Text>
        Seleccione el(los) módulo(s) que se requiere(n) modificar y luego de
        clic en el botón{' '}
        {id ? "'Realizar modificaciones'" : "'Iniciar modificaciones'"}
      </Text>
      <Table
        columns={columns}
        pagination={false}
        dataSource={modules}
        rowKey={'id'}
      />
      {alert && (
        <>
          <br />
          <Alert
            showIcon
            type="error"
            message="Error"
            description={
              <>
                El módulo <b>7. Documentos adicionales</b> no se puede
                seleccionar solo.
              </>
            }
          />
          <br />
        </>
      )}
      <div style={{ textAlign: 'center' }}>
        {id ? (
          <CustomButton
            text="Realizar modificaciones"
            loading={loading}
            onClick={onSelfFinish}
          />
        ) : (
          <CustomButton
            text="Iniciar modificaciones"
            onClick={onSelfFinish}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

const ModulesForm = ({ modules, modification_id, initialValues, onClose }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CustomButton
        onClick={onClose}
        danger={true}
        text="Seleccionar módulos"
      />
      <ModulesDraft
        modules={modules}
        modification_id={modification_id}
        initialValues={initialValues}
      />
    </div>
  );
};

const ModulesHome = ({ initialValues, setInitialValues, project_id }) => {
  const [showList, setShowList] = useState(!initialValues?.id ? true : false);
  let history = useHistory();

  const onFinishList = async (data) => {
    try {
      if (!initialValues?.id) {
        const res = await post({
          contrato: initialValues.contrato,
          modules: data,
        });
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${project_id}/${res.data.id}`,
        );
      } else {
        const res = await patch(initialValues.id, {
          modules: data,
        });
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setInitialValues((s) => ({ ...s, ...res.data }));
        setShowList(false);
      }
    } catch (error) {
      catchException(error);
      throw error;
    }
  };

  return (
    <>
      <br />
      {showList ? (
        <ModulesList
          id={initialValues?.id}
          modules={initialValues?.modules}
          onFinish={onFinishList}
        />
      ) : (
        <>
          <ModulesForm
            modules={initialValues?.modules}
            modification_id={initialValues?.id}
            initialValues={initialValues}
            onClose={() => setShowList(true)}
          />
        </>
      )}
    </>
  );
};

export default ModulesHome;
