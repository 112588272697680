import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/user_roles/`,
    data,
  );
};

const getListByGroup = async (group_id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/user_roles/`, {
    params: { grupo: group_id },
  });
};

const getAll = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/user_roles/`);
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/user_roles/${id}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/user_roles/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/user_roles/${id}`,
  );
};

export { post, getListByGroup, getAll, get, put, remove };
