import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  notification,
  Form,
  Space,
  Row,
  Col,
  Input,
  Typography,
  Card,
  Skeleton,
  Radio,
  InputNumber,
  Popconfirm,
} from 'antd';

import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import CustomButton from '../../../components/CustomButton';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  getModificationBudget,
  patchUpdateBudget,
} from '../../../api/module/request_mod_presupuestal';
import { IsRole } from '../../../utils/helpers/helpers';
import '../Form/style.scss';

const { Title } = Typography;

const ModificationBudget = () => {
  var { modification_id } = useParams();
  const [formModification] = Form.useForm();

  const [isLegal] = useState(IsRole(3));
  const [projectId, setPorjectId] = useState(0);
  const [value, setValue] = useState('aumento');
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [dataInfo, setDataInfo] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dataCalulated, setDataCalulated] = useState(null);
  const [dataModification, setDataModification] = useState({});
  const [atentionVisible, setAtentionVisible] = useState(false);
  let history = useHistory();

  const breadcrumbItems = [
    {
      text: 'Módulo Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}`,
    },
    {
      text: 'Formulario de Modificaciones Contractuales',
      to: `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${projectId}/${modification_id}`,
    },

    {
      text: 'Modificacion modulo presupuestal',
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        if (modification_id) {
          const response = await getModificationBudget(modification_id);
          if (response) {
            let resInfo = response.data.info ? response.data.info : {};
            let resModification = response.data.modification
              ? response.data.modification
              : {};
            let clausula = response.data.clausula ? response.data.clausula : '';
            let justificacion = response.data.justification
              ? response.data.justification
              : '';
            resInfo['clausula'] = clausula;
            resInfo['justificacion'] = justificacion;
            setDataInfo(resInfo);
            setDataModification(resModification);
            setLoading(false);
            setHasLoaded(true);
          }
        }
      } catch (err) {
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }

    fetchData();
  }, [modification_id]);

  useEffect(() => {
    if (hasLoaded) {
      formModification.setFieldsValue({
        tipo_operacion: dataModification.tipo_operacion,
        valor_disponible: dataModification.valor_disponible,
        valor_operacion: dataModification.valor_operacion,
        justification: dataInfo.justificacion,
        name_clause: dataInfo.clausula,
      });
      setPorjectId(dataInfo.proyecto_id);
      setValue(dataModification.tipo_operacion);
      let newCalulated = {};
      newCalulated['valor_total_modificado'] =
        dataModification.valor_total_modificado;
      setDataCalulated(newCalulated);
    }
  }, [hasLoaded, dataModification, formModification, dataInfo]);

  const onFinish = async (values) => {
    try {
      await formModification.validateFields();
      console.log('Form data:', values);
    } catch (error) {
      console.error('Form validation error:', error);
    }
  };

  const handleFinish = async () => {
    try {
      await formModification.validateFields();
      const values = formModification.getFieldsValue();
      sendFormModification(values);
    } catch (error) {
      if (error.errorFields.length === 0) {
        sendFormModification(error.values);
      }
    }
  };

  const atentionOk = () => {
    setAtentionVisible(false);
    handleFinish();
  };

  const confirmOk = () => {
    setAtentionVisible(true);
  };

  const popsCancel = () => {
    setAtentionVisible(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    formModification.validateFields();
  };

  function formatCurrency(number) {
    number = number ? number : 0;
    const formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter
      .format(number)
      .replace(/\./g, 'x')
      .replace(/,/g, '.')
      .replace(/x/g, ',');
  }

  const validateDisp = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    if (value < 0 || value > dataInfo.valor_total_vigente) {
      return Promise.reject(new Error('Debe ser igual o menor que el total'));
    }
    formModification.validateFields(['valor_operacion']);
    return Promise.resolve();
  };

  const validateOperation = (_, value) => {
    const maxDis = formModification.getFieldValue('valor_disponible');
    let maxDisponible = maxDis ? maxDis : 0;
    var newCalulated = {};
    const currentType = formModification.getFieldValue('tipo_operacion');
    if (currentType === 'disminucion') {
      if (value < 0 || value > maxDisponible) {
        newCalulated['valor_total_modificado'] = 0;
        setDataCalulated(newCalulated);
        return Promise.reject(
          new Error('Debe ser igual o menor que el disponible'),
        );
      } else {
        let valorModificado =
          parseFloat(dataInfo.valor_total_vigente) - parseFloat(value);
        newCalulated['valor_total_modificado'] = valorModificado;
        setDataCalulated(newCalulated);
        return Promise.resolve();
      }
    } else if (currentType === 'aumento') {
      if (value < 0) {
        newCalulated['valor_total_modificado'] = 0;
        setDataCalulated(newCalulated);
        return Promise.reject(
          new Error('Debe ser igual o menor que el disponible'),
        );
      } else {
        let valorModificado =
          parseFloat(dataInfo.valor_total_vigente) + parseFloat(value);
        newCalulated['valor_total_modificado'] = valorModificado;
        setDataCalulated(newCalulated);
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  const sendFormModification = async (inputs) => {
    setLoadingSend(true);
    const valor_disponible = inputs.valor_disponible
      ? inputs.valor_disponible
      : 0;
    const valor_operacion = inputs.valor_operacion ? inputs.valor_operacion : 0;
    const valor_total_modificado = dataCalulated.valor_total_modificado
      ? dataCalulated.valor_total_modificado
      : 0;
    const valor_total = dataInfo.valor_total_vigente
      ? dataInfo.valor_total_vigente
      : 0;
    const tipo_operacion = inputs.tipo_operacion ? inputs.tipo_operacion : '';

    const justification = inputs.justification ? inputs.justification : '';
    const clause = inputs.name_clause ? inputs.name_clause : '';

    const data = {
      valor_total: valor_total,
      valor_disponible: valor_disponible,
      valor_operacion: valor_operacion,
      valor_total_modificado: valor_total_modificado,
      tipo_operacion: tipo_operacion,
      justification: justification,
    };
    if (isLegal) {
      data['clausula'] = clause;
    }

    try {
      const response = await patchUpdateBudget(modification_id, data);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: 'Módulo actualizado correctamente.',
          duration: 2,
        });
      }
      setLoadingSend(false);
      setTimeout(() => {
        history.push(
          `${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`,
        );
      }, MESSAGES.TIME);
    } catch (error) {
      setLoadingSend(false);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <div className="box-title">
        <Title className={'text-uppercase'} level={2}>
          MODIFICACIÓN CONTRACTUAL PRESUPUESTAL
        </Title>
      </div>
      <Row align="middle" gutter={8}>
        <Col flex="auto">
          {dataInfo && (
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Skeleton loading={loading} active>
                <Descriptions
                  layout="horizontal"
                  column={2}
                  bordered
                  labelStyle={{ maxWidth: '5rem' }}
                  className="fade-in"
                >
                  <Descriptions.Item label="Contratista / Proveedor" span={2}>
                    <Row align="middle">
                      <Col flex="auto">{dataInfo.contratista_nombre}</Col>
                      <Col flex="0 0">
                        <CustomButton
                          htmlType="button"
                          text={
                            <Link
                              to={`${INTERNAL_LINKS.RESUME_CONTRACT_SEARCH}-detail/${dataInfo.id}/${dataInfo.proyecto_id}/${dataInfo.cargo_id}/`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver históricos contractuales
                            </Link>
                          }
                        />
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label="Cargo/Rol" span={2}>
                    {dataInfo.cargo_nombre}
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            </Space>
          )}

          <Form
            form={formModification}
            name="form_modificacion"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Card
                size={'small'}
                bordered={false}
                title={<Title level={3}>4. Ajuste presupuestal</Title>}
                style={{ padding: 0 }}
                headStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
              >
                {dataInfo && hasLoaded && isLegal && (
                  <Form.Item
                    label="Digite en letras el número de la cláusula del contrato a modificar"
                    name="name_clause"
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese el nombre de una cláusula',
                      },
                      {
                        max: 100,
                        message:
                          'El campo debe tener un máximo de 100 caracteres',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      allowClear
                      placeholder=""
                      name="name_clause"
                    />
                  </Form.Item>
                )}

                <Skeleton loading={loading} active>
                  {dataInfo && (
                    <Descriptions
                      layout="horizontal"
                      column={1}
                      bordered
                      size="small"
                      className="fade-in"
                    >
                      <Descriptions.Item
                        label="Valor total VIGENTE (incluye impuestos , tasas y contribuciones) "
                        style={{ maxWidth: '100px' }}
                      >
                        {formatCurrency(dataInfo.valor_total_vigente)}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Valor disponible por pagar del contrato antes de la modificación"
                        style={{ maxWidth: '100px' }}
                      >
                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          name="valor_disponible"
                          label={null}
                          rules={[
                            {
                              required: true,
                              message: 'Ingrese un valor',
                            },
                            {
                              validator: validateDisp,
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="000..."
                            style={{ width: '100%' }}
                            formatter={(value) =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Skeleton>
              </Card>
            </Space>

            <Space
              direction={'vertical'}
              style={{ width: '100%', marginTop: '20px' }}
              className="fade-in"
            >
              <Form.Item
                name="tipo_operacion"
                label="Por favor seleccione el tipo de modificación a realizar:"
                rules={[
                  {
                    required: true,
                    message: 'Debe seleccionar el tipo de operación',
                  },
                ]}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value="aumento">Aumento</Radio>
                  <Radio value="disminucion">Disminución</Radio>
                </Radio.Group>
              </Form.Item>

              <Skeleton
                loading={loading}
                active
                style={{ marginBottom: '20px' }}
              >
                {dataInfo && (
                  <Descriptions
                    layout="horizontal"
                    column={1}
                    bordered
                    size="small"
                    className="fade-in"
                  >
                    <Descriptions.Item
                      label="Digite el valor a adicionar o disminuir"
                      style={{ maxWidth: '100px' }}
                    >
                      <Form.Item
                        style={{ marginBottom: '0px' }}
                        name="valor_operacion"
                        label={null}
                        rules={[
                          {
                            required: true,
                            message: 'Ingrese un valor',
                          },
                          {
                            validator: validateOperation,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="000..."
                          style={{ width: '100%' }}
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Valor total contrato MODIFICADO (incluye impuestos,tasas y contribuciones)"
                      style={{ maxWidth: '100px' }}
                    >
                      {dataCalulated ? (
                        <span>
                          {formatCurrency(dataCalulated.valor_total_modificado)}
                        </span>
                      ) : (
                        <span>$0</span>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Skeleton>

              <Form.Item
                label="Justificación"
                name="justification"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa la Justificación',
                  },
                ]}
                style={{ marginTop: '20px' }}
              >
                <Input.TextArea allowClear />
              </Form.Item>

              {hasLoaded && (
                <Space>
                  <CustomButton
                    htmlType="button"
                    className={'back-button'}
                    type="default"
                    text={
                      <Link
                        to={`${INTERNAL_LINKS.CONTRACT_MODIFICATIONS}/${dataInfo.proyecto_id}/${modification_id}`}
                      >
                        Volver
                      </Link>
                    }
                  />

                  <Popconfirm
                    visible={atentionVisible}
                    placement="topLeft"
                    title={
                      <>
                        <p>
                          Esta modificación implica obligatoriamente cambiar el
                          módulo de forma de pago.
                        </p>
                        <p>Por favor seleccionelo para ajustarlo.</p>
                      </>
                    }
                    okText="Entendido"
                    cancelText="Cancelar"
                    onConfirm={atentionOk}
                    onCancel={popsCancel}
                  ></Popconfirm>
                  <CustomButton
                    loading={loadingSend}
                    text={
                      <Popconfirm
                        placement="topLeft"
                        title="¿Está seguro(a) de guardar los ajustes realizados?"
                        okText="Sí"
                        cancelText="No"
                        onConfirm={confirmOk}
                      >
                        {' '}
                        Guardar Modificación
                      </Popconfirm>
                    }
                  />
                </Space>
              )}
            </Space>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ModificationBudget;
