import React, { useEffect, useState, useReducer } from 'react';
import {
  Descriptions,
  Popconfirm,
  Select,
  Tabs,
  Row,
  Col,
  Typography,
  Space,
  notification,
  Badge,
  Modal,
  Layout,
} from 'antd';
import { Link } from 'react-router-dom';

import MESSAGES from '../../enums/Messages';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';

import { filter as filterStatus } from '../../api/module/status_process';
import {
  ajaxTable,
  getCounters,
  getProjects,
  getRoles,
  patch as patchVDP,
} from '../../api/module/term_document_validation';

import './style.scss';
import CustomSelector from '../../components/CustomSelector';
import AjaxTable from '../../components/AjaxTable';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const breadcrumbItems = [
  {
    text: 'Validación de Documentos Precontractuales',
  },
];

const initSelectors = {
  proyecto: undefined,
  cargo: undefined,
  firstLoad: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'project':
      return { ...initSelectors, proyecto: action.payload, firstLoad: false };
    case 'project_init':
      if (state.firstLoad)
        return {
          ...initSelectors,
          proyecto: action.payload?.value,
          firstLoad: false,
        };
      return state;
    case 'role':
      return {
        ...state,
        cargo: action.payload?.value,
        experiencia_requerida: action.payload?.experiencia_requerida,
        educacion_requerida: action.payload?.educacion_requerida,
        firstLoad: false,
      };
    case 'role_init':
      if (state.firstLoad)
        return {
          proyecto: action.payload?.proyecto,
          cargo: action.payload?.value,
          experiencia_requerida: action.payload?.experiencia_requerida,
          educacion_requerida: action.payload?.educacion_requerida,
          firstLoad: false,
        };
      return state;
    default:
      return initSelectors;
  }
}

function DocumentValidationContractualHomeSelectors({ match, setQuery }) {
  const [state, dispatch] = useReducer(reducer, initSelectors);

  useEffect(() => {
    if (!match.params.type || Object.values(state).some((value) => !!value)) {
      let q = '';
      if (q === '' && state.cargo) {
        q = `filter[cargo]=${state.cargo}`;
      }
      if (q === '' && state.proyecto) {
        q = `filter[proyecto]=${state.proyecto}`;
      }
      setQuery({ query: q, param: state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="box-description" key="vdc-selectors">
      {(!match.params.type ||
        (match.params.type === 'project' && state.firstLoad) ||
        state.proyecto) && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Proyecto a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChange={(value) =>
                dispatch({ type: 'project', payload: value })
              }
              initialValue={
                match.params.type && match.params.type === 'project'
                  ? parseInt(match.params.id)
                  : state.proyecto
              }
              onLoad={(values) => {
                console.log('p onLoad', values);
                if (match.params.type === 'project' && values?.value)
                  dispatch({
                    type: 'project_init',
                    payload: values,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el proyecto a revisar...',
              }}
              endpointFetch={async () => {
                return await getProjects();
              }}
              key={match.url}
            />
          </Col>
        </Row>
      )}
      {(state.proyecto ||
        (match.params.type === 'role' && state.firstLoad)) && (
        <Row className="box-selectors">
          <Col span={6}>
            <Title level={4}>Cargo/rol a revisar:</Title>
          </Col>
          <Col span={17} offset={0.5}>
            <CustomSelector
              onChangeData={(values) =>
                dispatch({ type: 'role', payload: values })
              }
              initialValue={
                match.params.type && match.params.type === 'role'
                  ? parseInt(match.params.id)
                  : state.cargo
              }
              onLoad={(values) => {
                if (match.params.type === 'role' && values?.value)
                  dispatch({
                    type: 'role_init',
                    payload: values,
                  });
              }}
              selectorProps={{
                style: { width: '100%' },
                placeholder: 'Seleccione el Cargo / Rol a revisar... ',
              }}
              endpointFetch={async () => {
                return await getRoles({
                  'filter[proyecto]': state.proyecto,
                });
              }}
              key={`rs-${state.proyecto}`}
            />
          </Col>
        </Row>
      )}
      {/* {state.educacion_requerida && (
        <Descriptions layout="vertical" column={1} bordered>
          <Descriptions.Item label={'Requerimientos de educación / formación'}>
            {state.educacion_requerida}
          </Descriptions.Item>
          <Descriptions.Item label={'Requerimientos de experiencia'}>
            {state.experiencia_requerida}
          </Descriptions.Item>
        </Descriptions>
      )} */}
    </div>
  );
}

const DocumentValidationPrecontractualHome = ({ match }) => {
  const [query, setQuery] = useState({ q: '', param: {} });

  const [counters, setCounters] = useState(undefined);
  const [stateOpt, setStateOpt] = useState([]);

  const [acceptableSubs, setAcceptableSubs] = useState([]);

  const [activeTab, setActiveTab] = useState('review');

  async function changeQuery(
    q = { query: '', param: undefined },
    newTab = undefined,
  ) {
    try {
      const res = await getCounters(`/?${q.query}`);
      if (q !== query) setQuery(q);
      setCounters(res.data.results);
      if (newTab) setActiveTab(newTab);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setCounters(undefined);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await filterStatus('/?filter[sigla.icontains]=TDR-VDP-G-');
        const resSub = await filterStatus(
          '/?filter[sigla.icontains]=TDR-VDP-M-',
        );
        let ac_restricted;
        let ai_restricted;
        const states = res.data.results.map((x) => {
          if (x.sigla === 'TDR-VDP-G-AC') ac_restricted = x.id;
          if (x.sigla === 'TDR-VDP-G-AI') ai_restricted = x.id;
          return { label: x.nombre, value: x.id, sigla: x.sigla };
        });
        const subStates = resSub.data.results.reduce((prev, x) => {
          prev[x.sigla] = x.id;
          return prev;
        }, {});
        setStateOpt(states);

        setAcceptableSubs({
          sub: subStates,
          restricted: { ac: ac_restricted, ai: ai_restricted },
        });
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }

    fetchData();
  }, []);

  const onStatusSave = (id, newState) => {
    async function putStatus(id, newState) {
      try {
        await patchVDP(id, { estado_validacion_general: newState });
        changeQuery(
          query,
          stateOpt.find((element) => element.value === newState).sigla,
        );
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    }
    putStatus(id, newState);
  };

  const extraColumnsToCheck = [
    {
      title: 'Validar Documentos',
      key: 'validate_button',
      render: (_, record) => (
        <CustomButton
          block
          type="primary"
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}-validar/${record.id}`}
            >
              Validar
            </Link>
          }
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => {
        let thisStates;
        if (record.tercero.tipo_persona_nombre === 'Natural')
          thisStates = [
            record.estado_validacion_basicos,
            record.estado_validacion_formacion,
            record.estado_validacion_experiencia,
            record.estado_validacion_icfes,
          ];
        else thisStates = [record.estado_validacion_basicos];
        const anyEmpty = thisStates.some((x) => !x);
        const fullyAcceptable = thisStates.every(
          (x) => acceptableSubs.sub['TDR-VDP-M-AC'] === x,
        );
        const onlyAJ = thisStates.some(
          (x) => x === acceptableSubs.sub['TDR-VDP-M-AI'],
        );
        return (
          <StateSelector
            // defState={record.estado_validacion_general}
            fullyAcceptable={fullyAcceptable}
            anyEmpty={anyEmpty}
            restrictedStates={acceptableSubs.restricted}
            onlyAJ={onlyAJ}
            onSave={(newStatus) => onStatusSave(record.id, newStatus)}
            options={stateOpt}
          />
        );
      },
    },
  ];

  const extraColumnsAdjust = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <>
          <CustomButton
            block
            type={'default'}
            htmlType={'button'}
            className={'ant-btn-info'}
            text={
              <Link
                to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL}/${record.id}`}
              >
                Generar Correo
              </Link>
            }
          />
          {record.estado_envio && 'Enviado'}
        </>
      ),
    },
    {
      title: 'Historial de correos',
      key: 'datetime',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL_EMAIL_HISTORY}/${record.id}`}
              target="_blank"
            >
              Historial
            </Link>
          }
        />
      ),
    },
  ];

  const extraColumnsApproved = [
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <CustomButton
          block
          type={'default'}
          htmlType={'button'}
          className={'ant-btn-info'}
          text={
            <Link
              to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PRECONTRACTUAL}-validar/${record.id}`}
            >
              Ver
            </Link>
          }
        />
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Validación de Documentos Precontractuales
      </Title>
      <DocumentValidationContractualHomeSelectors
        match={match}
        setQuery={changeQuery}
      />
      {query.query && counters && (
        <>
          <br />
          <CustomButton
            text="Actualizar registros"
            onClick={() => {
              window.location.reload();
            }}
          />
          <div className="box-table">
            <Tabs
              defaultActiveKey={'review'}
              activeKey={activeTab}
              onTabClick={(key, _) => {
                setActiveTab(key);
              }}
              destroyInactiveTabPane={true}
              type="card"
              key={query.query}
            >
              <TabPane
                tab={
                  <Paragraph>
                    Por revisar
                    <Badge showZero count={counters.review} />
                  </Paragraph>
                }
                key="review"
              >
                <ValidationTable
                  extraColumns={extraColumnsToCheck}
                  query={`/?${query.query}&filter[estado_validacion_general__isnull]=True`}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Ajustar información
                    <Badge showZero count={counters.incomplete} />
                  </Paragraph>
                }
                key="TDR-VDP-G-AI"
              >
                <ValidationTable
                  extraColumns={extraColumnsAdjust}
                  query={`/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDP-G-AI`}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados completo
                    <Badge showZero count={counters.approved} />
                  </Paragraph>
                }
                key="TDR-VDP-G-AC"
              >
                <ValidationTable
                  extraColumns={extraColumnsApproved}
                  query={`/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDP-G-AC`}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Aprobados parcial
                    <Badge showZero count={counters.partial} />
                  </Paragraph>
                }
                key="TDR-VDP-G-AP"
              >
                <ValidationTable
                  extraColumns={extraColumnsApproved}
                  query={`/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDP-G-AP`}
                />
              </TabPane>
              <TabPane
                tab={
                  <Paragraph>
                    Devuelto por jurídico
                    <Badge showZero count={counters.returned} />
                  </Paragraph>
                }
                key="returned"
              >
                <ValidationTable
                  extraColumns={extraColumnsToCheck}
                  query={`/?${query.query}&filter[estado_validacion_general__sigla]=TDR-VDC-G-D`}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Content>
  );
};

const ValidationTable = ({ extraColumns = [], query = '' }) => {
  const columns = [
    {
      title: 'Tipo persona',
      dataIndex: ['tercero', 'tipo_persona_nombre'],
      fixed: true,
    },
    {
      title: 'Nombre completo/Razón social',
      dataIndex: ['tercero', 'nombre_completo'],
      fixed: true,
    },
    {
      title: 'Tipo doc',
      dataIndex: ['tercero', 'tipo_identificacion_nombre'],
    },
    {
      title: 'Nro. Documento',
      dataIndex: ['tercero', 'numero_identificacion'],
    },
    {
      title: 'Teléfono',
      dataIndex: ['tercero', 'numero_celular'],
    },
    {
      title: 'Correo Electrónico',
      dataIndex: ['tercero', 'notificacion_email'],
    },
  ];

  return (
    <AjaxTable
      columns={[...columns, ...extraColumns]}
      pageSize={10}
      searchInput
      exportCSV={true}
      reloadButton
      tableTitle={'Listado de histórico de convocatorias'}
      endpoint={ajaxTable}
      query={query}
    />
  );
};

const StateSelector = ({
  defState = undefined,
  onSave = () => {},
  options = [],
  fullyAcceptable = false,
  onlyAJ = false,
  anyEmpty = true,
  restrictedStates,
}) => {
  const [state, setState] = useState(defState);

  function onSelfSave() {
    if (!fullyAcceptable && state === restrictedStates.ac) {
      Modal.warning({
        title: 'Estado inválido',
        content: 'El registro tiene estados diferentes a Aprobado completo.',
      });
      return;
    }
    if (fullyAcceptable && state !== restrictedStates.ac) {
      Modal.warning({
        title: 'Estado inválido',
        content:
          'El registro tiene todos sus estados en Aprobado completo, solo se puede `Aprobado completo',
      });
      return;
    }
    if (onlyAJ && state !== restrictedStates.ai) {
      Modal.warning({
        title: 'Estado inválido',
        content:
          'El registro tiene estados en Ajustar Información, solo se puede guardar `Ajustar Información`',
      });
      return;
    }
    if (anyEmpty) {
      Modal.confirm({
        title: 'Estado inválido',
        content: 'Hay módulos sin estado. ¿Desea continuar?',
        okText: 'Si',
        cancelText: 'No',
        onOk: () => onSave(state),
      });
      return;
    }
    onSave(state);
  }

  return (
    <Space direction="horizontal">
      <Select
        onChange={setState}
        defaultValue={defState}
        placeholder="Seleccione el estado..."
        options={options}
      />
      <Popconfirm
        onConfirm={() => {
          onSelfSave();
        }}
        title={MESSAGES.MSG_SAVE_STATE}
      >
        <CustomButton type="default" text="Guardar" disabled={!state} />
      </Popconfirm>
    </Space>
  );
};

export default DocumentValidationPrecontractualHome;
