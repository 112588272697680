import { Card, Table, Typography } from 'antd';
import React, { useState } from 'react';
import CustomButton from '../CustomButton';
import './style.scss';

const { Title, Paragraph } = Typography;

export default function DocumentListViewer({
  urlLoadFunc = async () => {},
  docList = [],
  listTitle,
  extraColumnsLeft = [],
  extraColumnsRight = [],
  pdfDataIndex = 'nombre_archivo',
}) {
  const [docUrl, setDocUrl] = useState(undefined);
  const [activeRecord, setActiveRecord] = useState(undefined);
  const urlLoader = async (record) => {
    let res = await urlLoadFunc(record);
    setDocUrl(`${res}#toolbar=0`);
    setActiveRecord(record);
  };
  const columns = [
    {
      title: 'Acciones',
      key: 'actions',
      width: '8em',
      align: 'center',
      overflowWrap: 'normal',
      wordBreak: 'normal',
      render: (_, record) => {
        if (record[pdfDataIndex])
          return (
            <CustomButton
              type="default"
              text="Ver"
              block
              className={'ant-btn-info'}
              onClick={() => {
                urlLoader(record);
              }}
              htmlType="button"
            />
          );
        else return 'Sin cargar';
      },
    },
  ];

  return (
    <Card
      size={'small'}
      bordered={false}
      title={<Title level={4}>{listTitle}</Title>}
    >
      <div className="viewer-container">
        <div className="viewer-sider">
          <Table
            loading={!docList}
            columns={[...extraColumnsLeft, ...columns, ...extraColumnsRight]}
            dataSource={docList}
            rowKey={(record) =>
              record.documento_id
                ? record.documento_id +
                  Math.random()
                    .toString(36)
                    .replace(/[^a-z]+/g, '')
                    .substr(0, 5)
                : Math.random()
                    .toString(36)
                    .replace(/[^a-z]+/g, '')
                    .substr(0, 5)
            }
            scroll={{ y: 'calc(80vh - 6em)', x: true }}
            pagination={false}
            rowClassName={(record, _) => {
              if (
                activeRecord &&
                record[pdfDataIndex] === activeRecord[pdfDataIndex]
              ) {
                return 'selected-row';
              } else return undefined;
            }}
          />
        </div>
        {docUrl ? (
          <iframe title="docViewer" className="viewer" src={docUrl} />
        ) : (
          <Paragraph className="viewer viewer-filler">
            Seleccione un documento para visualizar
          </Paragraph>
        )}
      </div>
    </Card>
  );
}
