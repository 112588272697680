import httpClient from '../httpClient';

const confirm = async (id) => {
  return await httpClient.put(`/term_resume_contract/${id}/confirm/`);
};

const reactivate = async (id) => {
  return await httpClient.put(`/term_resume_contract/${id}/reactivate/`);
};

const notify = async (id, data) => {
  return await httpClient.put(`/term_resume_contract/${id}/notify/`, data);
};

export { confirm, reactivate, notify };
