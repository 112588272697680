import React, { useState, useEffect, useRef } from 'react';
import { notification, Skeleton } from 'antd';
import moment from 'moment';

import { useQuery, IsRole } from '../../../utils/helpers/helpers';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import OtherCertificationsForm from '../../../containers/OtherCertificationsForm';
import PdfViewer from '../../../components/PdfViewer';

import { post, put, get } from '../../../api/module/resume_certifications';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const OtherCertifications = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [person_id] = useState(match.params.tercero_id);

  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const ids = {
    person_id: person_id,
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };

  const [coordinator] = useState(IsRole(2));
  const [assistant] = useState(IsRole(5));
  const [superUser] = useState(IsRole(6));
  const [contractor] = useState(IsRole(7));

  const [initialValues, setInitialValues] = useState(null);
  const [certification, setCertification] = useState(true);
  const otherCertificationRef = useRef(null);
  const [showPdf, setShowPdf] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showClick = async (documento_nombre) => {
    setIsLoadingDoc(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    setPdfUrl(response.data.url);
    setShowPdf(true);
    setIsLoadingDoc(false);
  };

  useEffect(() => {
    handleGetOtherCertifications();
  }, []);

  const handleGetOtherCertifications = () => {
    try {
      if (match.params.id) {
        get(match.params.id).then((res) => {
          res.data.fecha_terminacion = moment(res.data.fecha_terminacion);
          setInitialValues(res.data);
          setIsLoading(false);
          if (res.data.documento_nombre) {
            setCertification(false);
          }
        });
      } else {
        setIsLoading(false);
        setInitialValues({});
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      if (data.certificacion != undefined) {
        if (data.certificacion.fileList.length > 0) {
          if (data.certificacion.fileList[0].type == 'application/pdf') {
            data.certificacion = data.certificacion.fileList[0].originFileObj;
            let response;
            data.tercero = parseInt(match.params.tercero_id);
            data.fecha_terminacion =
              data['fecha_terminacion'].format('YYYY-MM-DD');

            const formData = new FormData();
            formData.append('certificacion', data);

            for (let prop in data) {
              if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
            }

            if (match.params.id) {
              response = await put(match.params.id, formData);
            } else {
              response = await post(formData);
            }
            if ([200, 201, 204].indexOf(response.status) > -1) {
              notification.success({
                message: MESSAGES.SUCCESS_MSG,
                description: MESSAGES.DESC_SUCCESS_MSG,
                duration: 2,
              });
              setTimeout(() => {
                history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${person_id}`, {
                  otherCertificationRef: otherCertificationRef,
                });
                setLoading(false);
              }, MESSAGES.TIME);
            }
          } else {
            notification.error({
              message: MESSAGES.ERROR_FILE_PDF,
              description: MESSAGES.DESC_ERROR_FILE_PDF,
              duration: 3,
            });
            setLoading(false);
          }
        } else {
          let response;
          data.tercero = parseInt(person_id);
          data.fecha_terminacion =
            data['fecha_terminacion'].format('YYYY-MM-DD');

          const formData = new FormData();
          formData.append('certificacion', data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }

          if (match.params.id) {
            response = await put(match.params.id, formData);
          } else {
            response = await post(formData);
          }
          if ([200, 201, 204].indexOf(response.status) > -1) {
            notification.success({
              message: MESSAGES.SUCCESS_MSG,
              description: MESSAGES.DESC_SUCCESS_MSG,
              duration: 2,
            });
            setTimeout(() => {
              history.goBack();
              setLoading(false);
            }, MESSAGES.TIME);
          }
        }
      } else {
        let response;
        data.tercero = parseInt(match.params.tercero_id);
        data.fecha_terminacion = data['fecha_terminacion'].format('YYYY-MM-DD');

        const formData = new FormData();
        formData.append('certificacion', data);

        for (let prop in data) {
          if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
        }

        if (match.params.id) {
          response = await put(match.params.id, formData);
        } else {
          response = await post(formData);
        }
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            history.goBack();
            setLoading(false);
          }, MESSAGES.TIME);
        }
      }
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += ` ${item.detail} - `;
        }
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
      setLoading(false);
    }
  };

  const back = () => {
    history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${person_id}`, {
      otherCertificationRef: otherCertificationRef,
    });
  };

  return (
    <>
      <Skeleton loading={isLoading}>
        <OtherCertificationsForm
          ids={ids}
          back={back}
          onFinish={onFinish}
          initialValues={initialValues}
          certification={certification}
          loading={loading}
          showClick={showClick}
          isLoadingDoc={isLoadingDoc}
        />
      </Skeleton>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
};

export default OtherCertifications;
