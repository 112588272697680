import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Layout,
} from 'antd';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import SelectTestIcfes from '../../components/SelectTestIcfes';
import SelectRolIcfes from '../../components/SelectRolIcfes';

const { Title } = Typography;
const { Content } = Layout;

const ExperiencieIcfesForm = ({
  ids,
  onFinish,
  initialValues,
  back,
  otrosProcesoIcfes,
  setOtrosProcesoIcfes,
  otrosRolIcfes,
  setOtrosRolIcfes,
  showClick,
  document,
  isLoadingDoc,
  isSave,
}) => {
  const breadcrumbItems = [
    {
      text: 'Proveedores y Consultores - Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSONS}`,
    },
    {
      text: 'Registro de Persona Natural',
      to: `${INTERNAL_LINKS.NATURAL_PERSON}/${ids.person_id}`,
    },
    {
      text: 'Experiencia ICFES',
    },
  ];

  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };

  useEffect(() => {
    if (initialValues.proceso_icfes === 24) {
      setOtrosProcesoIcfes(true);
    } else {
      setOtrosProcesoIcfes(false);
    }

    if (initialValues.rol_icfes === 17) {
      setOtrosRolIcfes(true);
    } else {
      setOtrosRolIcfes(false);
    }
  }, []);

  const onChangeProcesoIcfes = (value) => {
    if (value === 24) {
      setOtrosProcesoIcfes(true);
    } else {
      setOtrosProcesoIcfes(false);
    }
  };

  const onChangeRolIcfes = (value) => {
    if (value === 17) {
      setOtrosRolIcfes(true);
    } else {
      setOtrosRolIcfes(false);
    }
  };

  function disabledDate(current) {
    return current && current > moment().endOf('year');
  }

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Experiencia ICFES
      </Title>
      <Form
        name="nest-messages"
        layout="vertical"
        encType="multipart/form-data"
        initialValues={{ ...initialValues }}
        onFinish={onFinish}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="proceso_icfes"
              label="Prueba - Proceso en el que participó"
              rules={[
                {
                  required: true,
                  message:
                    'El campo Prueba - Proceso en el que participó es requerido',
                },
              ]}
            >
              {initialValues != null ? (
                <SelectTestIcfes
                  required={true}
                  onChange={onChangeProcesoIcfes}
                  proceso_icfes={initialValues.proceso_icfes}
                />
              ) : (
                <SelectTestIcfes
                  required={true}
                  onChange={onChangeProcesoIcfes}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {otrosProcesoIcfes === true && (
              <Form.Item
                label="Cuál?"
                name="proceso_icfes_otro"
                rules={[
                  {
                    required: true,
                    message: 'El campo Cuál? es requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="rol_icfes"
              label="Seleccione el Rol/Cargo que desempeñó"
              rules={[
                {
                  required: true,
                  message:
                    'El campo Seleccione el Rol/Cargo que desempeñó es requerido',
                },
              ]}
            >
              {initialValues != null ? (
                <SelectRolIcfes
                  required={true}
                  onChange={onChangeRolIcfes}
                  rol_icfes={initialValues.rol_icfes}
                />
              ) : (
                <SelectRolIcfes required={true} onChange={onChangeRolIcfes} />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {otrosRolIcfes === true && (
              <Form.Item
                label="Cuál?"
                name="rol_icfes_otro"
                rules={[
                  {
                    required: true,
                    message: 'El campo Cuál? es requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="anyo"
              label="Indique el año de participación"
              rules={[
                {
                  required: true,
                  message:
                    'El campo Indique el año de participación es requerido',
                },
              ]}
            >
              <DatePicker
                picker="year"
                disabledDate={disabledDate}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="semestre"
              label="Indique el semestre de participación"
              rules={[
                {
                  required: true,
                  message:
                    'El campo Indique el semestre de participación es requerido',
                },
              ]}
            >
              <Select>
                <Select.Option key="semestre-I" value={1}>
                  I
                </Select.Option>
                <Select.Option key="semestre-II" value={2}>
                  II
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} align={'middle'}>
          <Col span={20}>
            <CustomUpload
              upload_type={2}
              file_type={1}
              required={document}
              name="certificacion"
              label="Carga de certificado o acta de terminación y liquidación"
              tooltip="Tamaño del archivo máximo de 2MB en formato PDF si aplica"
            />
          </Col>
          <Col span={4}>
            {initialValues != null && initialValues.documento_nombre && (
              <CustomButton
                style={{ marginTop: 5 }}
                type="default"
                htmlType="button"
                text="Ver"
                loading={isLoadingDoc}
                onClick={() => {
                  showClick(initialValues.documento_nombre);
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Space>
                <CustomButton loading={isSave} text="Guardar" />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  onClick={back}
                  text="Volver"
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default ExperiencieIcfesForm;
