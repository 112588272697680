import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/terms/`,
    data,
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/terms/?page[size]=1000`,
  );
};

const getTermsByProject = async (projectId) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/terms/?filter[proyecto]=${projectId}`,
  );
};

const getTermsByRole = async (roleId) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/terms/list_selector/?filter[cargo]=${roleId}&page[size]=1000`,
  );
};

const filter = async (query) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/terms${query}`,
  );
};

const ajaxTable = async (
  query = '',
  page = 1,
  pageSize = 10,
  sortBy = '',
  search = false,
  _exportCSV = false,
) => {
  let searchBy = '';

  if (search) searchBy = '&filter[search]=' + search;
  if (sortBy) sortBy = '&sort=' + sortBy;

  return await httpClient.get(
    `/terms/?page[number]=${page}&page[size]=${pageSize}&${query}${sortBy}${searchBy}`,
  );
};

const duplicate = async (id, target_project) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/terms/${id}/duplicate/`,
    { proyecto: target_project },
  );
};

const get = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/terms/${id}/`);
};

const getResumeCounter = async (id) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/terms/${id}/resume_counter/`,
  );
};

const getChildrenCounter = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/terms/${id}/children_counters/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/terms/${id}/`,
    data,
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/terms/${id}`,
  );
};

const sendToContract = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/term_validation/${id}/`,
  );
};

const getTermAssign = async (id) => {
  return await httpClient.get(`/terms/${id}/term_assign/`);
};

const getTermCounters = async (projectId) => {
  return await httpClient.get(`/terms/counters/?filter[proyecto]=${projectId}`);
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  getTermsByProject,
  getTermsByRole,
  sendToContract,
  filter,
  duplicate,
  getResumeCounter,
  getTermAssign,
  getTermCounters,
  getChildrenCounter,
  ajaxTable,
};
