import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Space,
  PageHeader,
  Alert,
} from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';

import { CustomButton, CustomSelector } from '../../components';
import SelectPersonType from '../../components/SelectPersonType';
import SelectIdentifitiesType from '../../components/SelectIdentifitiesType';
import { validatorNumbers } from '../../utils/helpers/helpers';

import './style.scss';
import { Link } from 'react-router-dom';
import InternalLinks from '../../enums/InternalLinks';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { getAll as getPersonTypes } from '../../api/module/person_types';
import { getAll as getIDTypes } from '../../api/module/identities_types';

const NewUserRegisterForm = ({ save, loading, alert }) => {
  const [form] = Form.useForm();

  const showDVField = (value) => {
    if (value === 7 || value === 15) return true;
    return false;
  };

  const calculateDV = (newNit) => {
    let myNit = newNit;
    let vpri, x, y, z;

    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (let i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      x += y * vpri[z - i];
    }

    y = x % 11;
    let res = y > 1 ? 11 - y : y;
    return isNaN(res) ? undefined : res;
  };
  return (
    <Form
      name={'normal_register'}
      layout={'vertical'}
      scrollToFirstError={true}
      onFinish={save}
      onValuesChange={(new_v, v) => {
        if (v.tipo_identificacion && showDVField(v.tipo_identificacion)) {
          if (
            (v.tipo_persona === 2 && v.constituido_colombia) ||
            v.tipo_persona === 1
          ) {
            form.setFieldsValue({
              digito_verificacion: v.numero_identificacion
                ? calculateDV(v.numero_identificacion)
                : undefined,
              confirmar_digito_verificacion: v.nitConfirm
                ? calculateDV(v.nitConfirm)
                : undefined,
            });
          }
        }
      }}
      form={form}
    >
      <PageHeader
        className={'public-page-header'}
        title={'Creación de usuario'}
      />
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            label={'Tipo de persona'}
            name={'tipo_persona'}
            required
            rules={[
              {
                required: true,
                message: 'Elija el tipo de persona',
              },
            ]}
          >
            <CustomSelector
              endpointFetch={getPersonTypes}
              valueName={'id'}
              labelName={'nombre'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Tipo de identificación'}
            name={'tipo_identificacion'}
            required
            rules={[
              {
                required: true,
                message: 'Elija el tipo de identificación',
              },
            ]}
          >
            <CustomSelector
              endpointFetch={getIDTypes}
              valueName={'id'}
              labelName={'nombre'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item noStyle dependencies={['tipo_persona', 'tipo_identificacion']}>
        {({ getFieldValue }) => {
          const tipo_persona = getFieldValue('tipo_persona');
          const tipo_identificacion = getFieldValue('tipo_identificacion');
          const dvField = showDVField(tipo_identificacion);
          if (!tipo_persona || !tipo_identificacion) {
            return null;
          }
          if (tipo_persona === 2) {
            return (
              <>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="¿Está constituido en Colombia?"
                      name="constituido_colombia"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Elija si esta o no constituido en Colombia',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12} align="bottom">
                  <Form.Item noStyle dependencies={['constituido_colombia']}>
                    {({ getFieldValue }) =>
                      getFieldValue('constituido_colombia') === true ? (
                        <>
                          <Col flex="auto" order={1}>
                            <Form.Item
                              label="NIT o documento equivalente"
                              name="numero_identificacion"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Ingrese NIT o documento equivalente',
                                },
                                () => (dvField ? validatorNumbers(9)() : true),
                              ]}
                            >
                              <Input
                                allowClear
                                placeholder="NIT o documento equivalente"
                              />
                            </Form.Item>
                          </Col>
                          <Col flex="auto" order={3}>
                            <Form.Item
                              name="nitConfirm"
                              label="Confirme el NIT o documento"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Ingrese Confirme el NIT o documento',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue('numero_identificacion') ===
                                        value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error('El NIT no coincide!'),
                                    );
                                  },
                                }),
                                () => (dvField ? validatorNumbers(9)() : true),
                              ]}
                            >
                              <Input
                                placeholder="Reconfirmar el NIT o documento equivalente"
                                onPaste={(e) => e.preventDefault()}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        getFieldValue('constituido_colombia') === false && (
                          <>
                            <Col span={12} order={1}>
                              <Form.Item
                                label="Documento equivalente al NIT"
                                name="numero_identificacion"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Ingrese Documento equivalente al NIT',
                                  },
                                ]}
                              >
                                <Input
                                  allowClear
                                  placeholder="NIT o documento equivalente"
                                />
                              </Form.Item>
                            </Col>
                            <Col flex="auto" order={3}>
                              <Form.Item
                                name="nitConfirm"
                                label="Confirme el documento"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Ingrese Confirme el NIT o documento',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue(
                                          'numero_identificacion',
                                        ) === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error('El NIT no coincide!'),
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  placeholder="Confirmar el documento"
                                  onPaste={(e) => e.preventDefault()}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      )
                    }
                  </Form.Item>
                  <Form.Item noStyle dependencies={['constituido_colombia']}>
                    {({ getFieldValue }) => {
                      if (
                        getFieldValue('constituido_colombia') === true &&
                        dvField
                      ) {
                        return (
                          <>
                            <Col flex="0 1 3.5rem" order={2}>
                              <Form.Item
                                name="digito_verificacion"
                                label="DV"
                                tooltip="Dígito Verificación: Este dato se calcula según el tipo de identificación y documento"
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input placeholder="DV" disabled />
                              </Form.Item>
                            </Col>
                            <Col flex="0 1 3.5rem" order={4}>
                              <Form.Item
                                name="confirmar_digito_verificacion"
                                label="DV"
                                tooltip="Dígito Verificación: Este dato se calcula según el tipo de identificación y documento"
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input placeholder="DV" disabled />
                              </Form.Item>
                            </Col>
                          </>
                        );
                      }
                    }}
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Nombre / Razón social"
                      name="primer_nombre_razon_social"
                      rules={[
                        {
                          required: true,
                          message: 'Ingrese nombre razón social',
                        },
                      ]}
                    >
                      <Input placeholder="Nombre / Razón social" allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }
          if (tipo_persona === 1) {
            return (
              <>
                <Row gutter={[24, 0]} align="bottom">
                  <Col span={12}>
                    <Form.Item
                      label="Nombre(s) Completos"
                      name="primer_nombre_razon_social"
                      rules={[
                        {
                          required: true,
                          message: 'Ingrese nombre completo',
                        },
                      ]}
                    >
                      <Input placeholder="Nombre(s) completo(s)" allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Apellidos Completos"
                      name="primer_apellido"
                      rules={[
                        {
                          required: true,
                          message: 'Ingrese apellido',
                        },
                      ]}
                    >
                      <Input placeholder="Apellidos completos" allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]} align="bottom">
                  <Col flex="1 1 12rem" order={1}>
                    <Form.Item
                      label="Número de documento de identificación"
                      name="numero_identificacion"
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingrese su número de documento de identificación',
                        },
                        () => (dvField ? validatorNumbers(6)() : true),
                      ]}
                    >
                      <Input
                        placeholder="Número de documento de identificación"
                        allowClear
                        onChange={() => {
                          form.validateFields(['nitConfirm']);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 12rem" order={3}>
                    <Form.Item
                      label="Confirme el documento de identificación"
                      name="nitConfirm"
                      rules={[
                        {
                          required: true,
                          message:
                            'Confirme el número de documento de identificación',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue('numero_identificacion') === value
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'El número de documento no coincide!',
                            );
                          },
                        }),
                        () => (dvField ? validatorNumbers(6)() : true),
                      ]}
                    >
                      <Input
                        placeholder="Confirme el número de documento de identificación"
                        allowClear
                        onChange={() => {
                          form.validateFields(['numero_identificacion']);
                        }}
                        onPaste={(e) => e.preventDefault()}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    dependencies={[
                      'digito_verificacion',
                      'confirmar_digito_verificacion',
                    ]}
                  >
                    {() => {
                      if (dvField) {
                        return (
                          <>
                            <Col flex="0 1 3.5rem" order={2}>
                              <Form.Item
                                name="digito_verificacion"
                                label="DV"
                                tooltip="Dígito Verificación: Este dato se calcula según el tipo de identificación y documento"
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input placeholder="DV" disabled />
                              </Form.Item>
                            </Col>
                            <Col flex="0 1 3.5rem" order={4}>
                              <Form.Item
                                name="confirmar_digito_verificacion"
                                label="DV"
                                tooltip="Dígito Verificación: Este dato se calcula según el tipo de identificación y documento"
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input placeholder="DV" disabled />
                              </Form.Item>
                            </Col>
                          </>
                        );
                      }
                    }}
                  </Form.Item>
                </Row>
              </>
            );
          }
          return null;
        }}
      </Form.Item>

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label={'Correo electrónico'}
            name={'notificacion_email'}
            required
            rules={[
              {
                type: 'email',
                message: 'Ingrese un correo electrónico válido!',
              },
              {
                required: true,
                message: 'Ingrese su correo electrónico',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              type={'email'}
              placeholder={'Correo electrónico'}
              allowClear
              onChange={() => {
                form.validateFields(['notificacion_email_confirm']);
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
              onCopy={(e) => {
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Confirme el correo electrónico'}
            name={'notificacion_email_confirm'}
            required
            rules={[
              {
                type: 'email',
                message: 'Ingrese un correo electrónico válido!',
              },
              {
                required: true,
                message: 'Confirme su correo electrónico',
                type: 'email',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('notificacion_email') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('El Correo electrónico no coincide!');
                },
              }),
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              type={'email'}
              placeholder={'Confirme el correo electrónico'}
              allowClear
              onChange={() => {
                form.validateFields(['notificacion_email']);
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
              onCopy={(e) => {
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label={'Contraseña'}
            name={'password'}
            required
            rules={[
              {
                required: true,
                message: 'Ingrese una contraseña',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.length >= 7) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Ingrese mínimo 7 caracteres!');
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onChange={() => form.validateFields(['password_confirm'])}
              placeholder="Contraseña"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Confirme su contraseña'}
            name={'password_confirm'}
            rules={[
              {
                required: true,
                message: 'Confirme la contraseña',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('La contraseña no coincide!');
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onChange={() => form.validateFields(['password'])}
              placeholder="Confirme la contraseña"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form.Item
            name="recaptcha"
            rules={[
              {
                required: true,
                message: 'Realice la verificación de CAPTCHA',
              },
            ]}
          >
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col>
          <Form.Item className={'mb-0'}>
            <Space size={'small'}>
              <CustomButton text={'Crear usuario'} loading={loading} block />
              <Link to={InternalLinks.HOME}>
                <Button danger={true} className={'w-100'}>
                  Volver
                </Button>
              </Link>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      {alert.show && (
        <>
          <br></br>
          <Alert
            message={alert.message}
            description={alert.description}
            type={alert.type}
            showIcon
            action={
              <Space direction="vertical">
                <Link to={InternalLinks.HOME}>
                  {alert.icon && (
                    <Button size="small" type="primary">
                      OK
                    </Button>
                  )}
                </Link>
              </Space>
            }
          />
        </>
      )}
    </Form>
  );
};

export default NewUserRegisterForm;
