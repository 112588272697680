import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/document_types/`, data);
};

const getAll = async () => {
  return await httpClient.get(`/document_types/?page[size]=100`);
};

const get = async (id) => {
  return await httpClient.get(`/document_types/${id}`);
};

const put = async (id, data) => {
  return await httpClient.put(`/document_types/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/document_types/${id}`);
};
const filter = async (query) => {
  return await httpClient.get(`/document_types/${query}`);
};

export {
  post,
  getAll,
  get,
  put,
  remove,
  filter,
}
