import httpClient from '../httpClient';

const getIndicatorData = async (id) => {
  return await httpClient.get('/indicators/');
};

const indicatorsVDP = async (page, pageSize) => {
  return await httpClient.get(
    `/indicators/vdp_details/?page[number]=${page}&page[size]=${pageSize}`,
  );
};

export { getIndicatorData, indicatorsVDP };
