import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const BreadcrumbContainer = ({ items }) => {
  return (
    <Breadcrumb style={{marginBottom:20}}>
      <Breadcrumb.Item key={`breadcrumb-item--1`}>
        <Link to="/">Inicio</Link>
      </Breadcrumb.Item>
      {items.map((item, index) => {
        if (item.to) {
          return (
            <Breadcrumb.Item key={`breadcrumb-item-${index}`}>
              <Link key={`breadcrumb-link-${index}`} to={item.to}>
                {item.text}
              </Link>
            </Breadcrumb.Item>
          );
        } else {
          return (
            <Breadcrumb.Item key={`breadcrumb-item-${index}`}>
              {item.text}
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbContainer;
