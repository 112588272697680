import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(
    `${process.env.REACT_APP_API_HOST}/resume_documents/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const getAll = async () => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/resume_documents/`,
  );
};

const get = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/resume_documents/${id}/`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(
    `${process.env.REACT_APP_API_HOST}/resume_documents/${id}/`,
    data,
    {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    },
  );
};

const remove = async (id) => {
  return await httpClient.delete(
    `${process.env.REACT_APP_API_HOST}/resume_documents/${id}`,
  );
};

const filter = async (id) => {
  return await httpClient.get(
    `${process.env.REACT_APP_API_HOST}/resume_documents/latest/?filter[tercero]=${id}`,
  );
};

export { post, getAll, get, put, remove, filter };
