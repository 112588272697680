import React, { useState, useEffect } from 'react';
import {
  Badge,
  Typography,
  Tabs,
  notification,
  Table,
  Modal,
  Descriptions,
  Input,
  Select,
  Form,
  Popconfirm,
  Layout,
  Row,
  Space,
  Col,
} from 'antd';

//Component imports
import AjaxTable from '../../components/AjaxTable';
import CustomButton from '../../components/CustomButton';
import PdfViewer from '../../components/PdfViewer';

import BreadcrumbContainer from '../../containers/Breadcrumb';

import { reject as rejectVDD } from '../../api/module/term_director_validation_document';
import { getAll as getAllRoles } from '../../api/module/groups';
import { getUsersByGroup } from '../../api/module/user';

import {
  confirm as confirmResume,
  notify as notifyResume,
  reactivate as reactivateResume,
} from '../../api/module/term_resume_rejected';

import {
  confirm as confirmLegal,
  notify as notifyLegal,
} from '../../api/module/term_legal_rejected';

import {
  counters as getCounters,
  confirm as confirmDirector,
  notify as notifyDirector,
  reactivate as reactivateDirector,
  filter as filterDirectorRejected,
} from '../../api/module/term_director_rejected';

import { filter as filterResumeValidation } from '../../api/module/term_resume_contract';

import { filter as filterLegalValidation } from '../../api/module/term_legal_validation_document';

import {
  reject as rejectTRV,
  getAllByPersonAndTerm,
} from '../../api/module/term_resume_validation';
import { reject as rejectTLVD } from '../../api/module/term_legal_validation_document';
import { postWord as post } from '../../api/module/template';

import MESSAGES from '../../enums/Messages';

import './style.scss';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

const RejectTDR = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [loadingViewDocuments, setLoadingViewDocuments] = useState(null);
  const [loadingConfirmRejected, setLoadingConfirmRejected] = useState(null);
  const [loadingReactivateRejected, setLoadingReactivateRejected] =
    useState(null);
  const [dataSourceContractDocuments, setDataSourceContractDocuments] =
    useState([]);
  const [modalContractVisible, setModalContractVisible] = useState(false);
  const [modalEmailVisible, setModalEmailVisible] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [dataRoles, setDataRoles] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [form] = Form.useForm();
  const [resumeAjaxTable, setResumeAjaxTable] = useState(<></>);
  const [legalAjaxTable, setLegalAjaxTable] = useState(<></>);
  const [directorAjaxTable, setDirectorAjaxTable] = useState(<></>);

  const [resumeConfirmAjaxTable, setResumeConfirmAjaxTable] = useState(<></>);
  const [legalConfirmAjaxTable, setLegalConfirmAjaxTable] = useState(<></>);
  const [directorConfirmAjaxTable, setDirectorConfirmAjaxTable] = useState(
    <></>,
  );
  const [dateAjaxTable, setDateAjaxTable] = useState(<></>);
  const [counters, setCounters] = useState({
    date: 0,
    director: 0,
    director_confirm: 2,
    legal: 0,
    legal_confirm: 0,
    resume: 0,
    resume_confirm: 0,
  });

  const breadcrumbItems = [
    {
      text: 'Módulo de Rechazos TDR',
    },
  ];

  const initModalState = {
    pdf: undefined,
    visible: false,
    key: 'no-key',
  };
  const [modalPDFState, setModalPDFState] = useState(initModalState);

  const modalDocumentsColumns = [
    {
      title: 'Tipo de documentos',
      dataIndex: 'plantilla_nombre',
      width: 400,
    },
    {
      title: 'Ver',
      key: 'view',
      align: 'center',
      width: 126,
      render: (_, record) => (
        <CustomButton
          type="default"
          text="Visualizar"
          htmlType="button"
          onClick={async () => {
            const pdfUrl = await post({
              template: record.plantilla,
              person_type: record.tercero.tipo_persona,
            }).data.url;
            setModalPDFState({
              visible: true,
              pdf: pdfUrl,
              key: `pdf-${record.id}`,
            });
          }}
        />
      ),
    },
    {
      title: 'Aceptación del documento',
      key: 'acceptance',
      align: 'center',
      width: 144,
      render: (_, record) => {
        if (record.estado_aceptacion === true) {
          return 'Sí';
        } else if (record.estado_aceptacion === false) {
          return 'No';
        } else {
          return 'NA';
        }
      },
    },
    {
      title: 'Explique el motivo de su NO aceptación',
      dataIndex: 'motivo',
      key: 'motive',
    },
  ];

  const columns = [
    {
      title: 'Proyecto',
      dataIndex: ['termino', 'proyecto_nombre'],
      key: 'proyecto_nombre',
    },
    {
      title: 'Tipo de contrato',
      dataIndex: ['termino', 'tipo_contrato_texto'],
      key: 'tipo_contrato_texto',
    },
    {
      title: 'Área de Corpoeducación',
      dataIndex: ['termino', 'area_corpo_texto'],
      key: 'area_corpo_texto',
    },
    {
      title: 'Centro de costos',
      dataIndex: ['termino', 'centro_costo_nombre'],
      key: 'centro_costo_nombre',
    },
    {
      title: 'Cargo / Rol',
      dataIndex: ['termino', 'cargo_nombre'],
      key: 'cargo_nombre',
    },
    {
      title: 'Contratista',
      dataIndex: ['tercero', 'nombre_completo'],
      key: 'nombre_completo',
    },
    {
      title: 'Correo electrónico',
      dataIndex: ['tercero', 'notificacion_email'],
      key: 'notificacion_email',
    },
    {
      title: 'Teléfono',
      dataIndex: ['tercero', 'numero_celular'],
      key: 'numero_celular',
    },
    {
      title: 'Devolución hecha por',
      dataIndex: 'rechazado_por',
      key: 'rechazado_por',
    },
    {
      title: 'Fecha de devolución',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Motivo rechazo',
      dataIndex: 'motivo',
      key: 'motivo',
    },
  ];

  const resumeAdditionalColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            type={'default'}
            htmlType={'button'}
            loading={loadingViewDocuments === record.id}
            onClick={() => {
              viewDocuments({
                type: 0,
                personId: record.tercero.id,
                termId: record.termino.id,
                id: record.id,
              });
            }}
            text={'Ver'}
          />
          <Popconfirm
            title="Está seguro(a) de reactivar los TDR? Esta acción permitirá al contratista ver nuevamente los TDR para su aceptación."
            okText="Sí"
            cancelText="No"
            onConfirm={() => {
              reactivateReject({
                type: 0,
                id: record.id,
              });
            }}
          >
            <CustomButton
              block
              type={'default'}
              htmlType={'button'}
              className={'ant-btn-info'}
              loading={loadingReactivateRejected === record.id}
              text={'Reactivar TDR'}
            />
          </Popconfirm>
          <CustomButton
            block
            htmlType={'button'}
            onClick={() => {
              setEmailData({ ...record, type: 0 });
              notifyEmailModal();
            }}
            text={'Notificar creación TDR'}
          />
          <Popconfirm
            title="Está seguro(a) de confirmar el rechazo? Esta acción cerrará definitivamente este proceso de contratación."
            okText="Sí"
            cancelText="No"
            onConfirm={() => {
              confirmReject({
                type: 0,
                id: record.id,
              });
            }}
          >
            <CustomButton
              block
              danger
              type={'default'}
              htmlType={'button'}
              loading={loadingConfirmRejected === record.id}
              text={'Confirmación rechazo'}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const vdcAdditionalColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            htmlType={'button'}
            onClick={() => {
              setEmailData({ ...record, type: 1 });
              notifyEmailModal();
            }}
            text={'Notificar creación TDR'}
          />
          <Popconfirm
            title="Está seguro(a) de confirmar el rechazo? Esta acción cerrará definitivamente este proceso de contratación."
            okText="Sí"
            cancelText="No"
            onConfirm={() => {
              confirmReject({
                type: 1,
                id: record.id,
              });
            }}
          >
            <CustomButton
              block
              type={'default'}
              htmlType={'button'}
              danger
              text={'Confirmación rechazo'}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const dateAdditionalColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            htmlType={'button'}
            onClick={() => {
              setEmailData({ ...record, type: 2 });
              notifyEmailModal();
            }}
            text={'Notificar creación TDR'}
          />
          <Popconfirm
            title="Está seguro(a) de confirmar el rechazo? Esta acción cerrará definitivamente este proceso de contratación."
            okText="Sí"
            cancelText="No"
            onConfirm={() => {
              confirmReject({
                type: 2,
                id: record.id,
              });
            }}
          >
            <CustomButton
              block
              type={'default'}
              htmlType={'button'}
              loading={loadingConfirmRejected === record.id}
              danger
              text={'Confirmación rechazo'}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const directorAdditionalColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      render: (_, record) => (
        <Space direction={'vertical'}>
          <CustomButton
            block
            htmlType={'button'}
            onClick={() => {
              setEmailData({ ...record, type: 2 });
              notifyEmailModal();
            }}
            text={'Notificar creación TDR'}
          />
          <Popconfirm
            title="Está seguro(a) de confirmar el rechazo? Esta acción cerrará definitivamente este proceso de contratación."
            okText="Sí"
            cancelText="No"
            onConfirm={() => {
              confirmReject({
                type: 2,
                id: record.id,
              });
            }}
          >
            <CustomButton
              block
              type={'default'}
              htmlType={'button'}
              loading={loadingConfirmRejected === record.id}
              danger
              text={'Confirmación rechazo'}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getRolesData();
    getCountersData();

    generateDateAjaxTable();
    generateResumeAjaxTable();
    generateLegalAjaxTable();
    generateDirectorAjaxTable();
    generateDirectorConfirmAjaxTable();
    generateResumeConfirmAjaxTable();
    generateLegalConfirmAjaxTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDateAjaxTable = () => {
    setTimeout(() => {
      setDateAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = `filter[estado_validacion_tercero__sigla]=TDR-VDT-G-RF`;
            return await filterDirectorRejected(
              `?${thisQuery}`,
              page,
              pageSize,
            );
          }}
          columns={dateAdditionalColumns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos por fecha expirada
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateResumeAjaxTable = () => {
    setTimeout(() => {
      setResumeAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = ``;
            return await rejectTRV(`?${thisQuery}`, page, pageSize);
          }}
          columns={resumeAdditionalColumns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos por contratista
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateResumeConfirmAjaxTable = () => {
    setTimeout(() => {
      setResumeConfirmAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = `filter[estado_validacion__sigla]=TDR-VDD-G-RC`;
            return await filterResumeValidation(
              `?${thisQuery}`,
              page,
              pageSize,
            );
          }}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos confirmados por contratista
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateLegalAjaxTable = () => {
    setTimeout(() => {
      setLegalAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = ``;
            return await rejectTLVD(`?${thisQuery}`, page, pageSize);
          }}
          columns={vdcAdditionalColumns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos por jurídico VDC
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateLegalConfirmAjaxTable = () => {
    setTimeout(() => {
      setLegalConfirmAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = `filter[estado_validacion_general__sigla]=TDR-VDC-G-RC`;
            return await filterLegalValidation(`?${thisQuery}`, page, pageSize);
          }}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos confirmados por VDC
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateDirectorAjaxTable = () => {
    setTimeout(() => {
      setDirectorAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = ``;
            return await rejectVDD(`?${thisQuery}`, page, pageSize);
          }}
          columns={directorAdditionalColumns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos por director
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const generateDirectorConfirmAjaxTable = () => {
    setTimeout(() => {
      setDirectorConfirmAjaxTable(
        <AjaxTable
          endpointFetch={async (page, pageSize) => {
            const thisQuery = `filter[estado_validacion__sigla]=TDR-VDD-G-RC`;
            return await filterDirectorRejected(
              `?${thisQuery}`,
              page,
              pageSize,
            );
          }}
          columns={columns}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Rechazos confirmados por director
            </Paragraph>
          )}
        />,
      );
    }, 500);
  };

  const getCountersData = async () => {
    const response = await getCounters();
    setCounters(response.data.results);
  };

  const getRolesData = async () => {
    const response = await getAllRoles();
    setDataRoles(response.data.results);
  };

  const getUsersByGroupData = async (id) => {
    const response = await getUsersByGroup(id);
    setDataUsers(response.data.results);
  };

  const viewDocuments = async ({ type, personId, termId, id }) => {
    setLoadingViewDocuments(id);
    try {
      let response = null;
      if (type === 0) {
        response = await getAllByPersonAndTerm(personId, termId);
      }
      if ([200, 201, 204].indexOf(response.status) > -1) {
        setDataSourceContractDocuments(response.data.results);
        setModalContractVisible(true);
      }
      setLoadingViewDocuments(null);
    } catch (err) {
      let description = '';
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: MESSAGES.TIME_SECONDS,
      });
      setLoadingViewDocuments(null);
    }
  };

  const reactivateReject = async ({ type, id }) => {
    setLoadingReactivateRejected(id);
    try {
      let response;
      if (type === 0) {
        response = await reactivateResume(id);
      } else if (type === 2) {
        response = await reactivateDirector(id);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: MESSAGES.TIME_SECONDS,
        });
        if (type === 0) {
          generateResumeAjaxTable();
          generateResumeConfirmAjaxTable();
        } else if (type === 2) {
          generateDirectorAjaxTable();
          generateDirectorConfirmAjaxTable();
        }
        getCountersData();
      }
      setLoadingReactivateRejected(null);
    } catch (err) {
      let description = '';
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: MESSAGES.TIME_SECONDS,
      });
      setLoadingReactivateRejected(null);
    }
  };

  const confirmReject = async ({ type, id }) => {
    setLoadingConfirmRejected(id);
    try {
      let response;
      if (type === 0) {
        response = await confirmResume(id);
      } else if (type === 1) {
        response = await confirmLegal(id);
      } else if (type === 2) {
        response = await confirmDirector(id);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: MESSAGES.TIME_SECONDS,
        });
        if (type === 0) {
          generateResumeAjaxTable();
          generateResumeConfirmAjaxTable();
        } else if (type === 1) {
          generateLegalAjaxTable();
          generateLegalConfirmAjaxTable();
        } else if (type === 2) {
          generateDirectorAjaxTable();
          generateDirectorConfirmAjaxTable();
        }
      }
      getCountersData();
      setLoadingConfirmRejected(null);
    } catch (err) {
      let description = '';
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: MESSAGES.TIME_SECONDS,
      });
      setLoadingConfirmRejected(null);
    }
  };

  const notifyEmailModal = async () => {
    setModalEmailVisible(true);
  };

  const notifyEmailEvent = async ({ type, data }) => {
    try {
      let response;
      if (type === 0) {
        response = await notifyResume(emailData.id, data);
      } else if (type === 1) {
        response = await notifyLegal(emailData.id, data);
      } else if (type === 2) {
        response = await notifyDirector(emailData.id, data);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
          duration: MESSAGES.TIME_SECONDS,
        });
        if (type === 0) {
          generateResumeAjaxTable();
          generateResumeConfirmAjaxTable();
        } else if (type === 1) {
          generateLegalAjaxTable();
          generateLegalConfirmAjaxTable();
        } else if (type === 2) {
          generateDateAjaxTable();
          generateDirectorAjaxTable();
          generateDirectorConfirmAjaxTable();
        }
      }
      getCountersData();
      setLoadingConfirmRejected(null);
      setEmailData(null);
      setModalEmailVisible(false);
    } catch (err) {
      let description = '';
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: MESSAGES.TIME_SECONDS,
      });
      setLoadingConfirmRejected(null);
    }
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Rechazos TDR
      </Title>
      <Tabs
        type="card"
        defaultActiveKey={activeTab}
        onChange={(value) => {
          setActiveTab(value);
        }}
      >
        <TabPane
          tab={
            <Paragraph>
              Por Fecha Expirada
              <Badge showZero count={counters.date} />
            </Paragraph>
          }
          key="0"
        >
          {dateAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Por Contratista
              <Badge showZero count={counters.resume} />
            </Paragraph>
          }
          key="1"
        >
          {resumeAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Por jurídico VDC
              <Badge showZero count={counters.legal} />
            </Paragraph>
          }
          key="2"
        >
          {legalAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Por director
              <Badge showZero count={counters.director} />
            </Paragraph>
          }
          key="3"
        >
          {directorAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Confirmados Contratista
              <Badge showZero count={counters.resume_confirm} />
            </Paragraph>
          }
          key="4"
        >
          {resumeConfirmAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Confirmados VDC
              <Badge showZero count={counters.legal_confirm} />
            </Paragraph>
          }
          key="5"
        >
          {legalConfirmAjaxTable}
        </TabPane>
        <TabPane
          tab={
            <Paragraph>
              Confirmados Director
              <Badge showZero count={counters.director_confirm} />
            </Paragraph>
          }
          key="6"
        >
          {directorConfirmAjaxTable}
        </TabPane>
      </Tabs>
      <Modal
        width={800}
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Visualización de documentos
          </Title>
        }
        visible={modalContractVisible}
        onCancel={() => {
          setModalContractVisible(!modalContractVisible);
        }}
        cancelText="Cerrar"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          rowKey="id"
          columns={modalDocumentsColumns}
          dataSource={dataSourceContractDocuments}
          pagination={false}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Documentos
            </Paragraph>
          )}
        />
      </Modal>
      <Modal
        width={800}
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Notificar creación nuevos TDR
          </Title>
        }
        visible={modalEmailVisible}
        onCancel={() => {
          setModalEmailVisible(!modalEmailVisible);
          setEmailData(null);
          form.resetFields();
        }}
        onOk={() => {
          form.submit();
        }}
        cancelText="Cerrar"
        okText="Enviar correo"
      >
        {emailData && (
          <Space direction={'vertical'}>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Proyecto a revisar">
                {emailData.termino.proyecto_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Cargo/rol a revisar">
                {emailData.termino.cargo_nombre}
              </Descriptions.Item>
              <Descriptions.Item label="Nombres contratista">
                {emailData.tercero.nombre_completo}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo y número de documento">
                {emailData.tercero.tipo_identificacion_nombre} -
                {emailData.tercero.numero_identificacion}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered column={1} layout="vertical">
              <Descriptions.Item label="Motivo">
                {emailData.motivo}&nbsp;
              </Descriptions.Item>
            </Descriptions>
            <Form
              form={form}
              layout="vertical"
              onFinish={(data) => {
                notifyEmailEvent({ type: emailData.type, data });
              }}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Comentarios"
                    name="mensaje"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Seleccione el rol de la persona que revisará"
                    name="rol_responsable"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        getUsersByGroupData(value);
                      }}
                    >
                      {dataRoles.map((item) => (
                        <Select.Option
                          key={`rol-key-${item.id}`}
                          value={item.id}
                        >
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    label="Seleccione la persona que revisará"
                    name="responsable"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {dataUsers.map((item) => (
                        <Select.Option
                          key={`users-key-${item.id}`}
                          value={item.id}
                        >
                          {item.nombre_completo}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        )}
      </Modal>
      <PdfViewer
        pdf={modalPDFState.pdf}
        visible={modalPDFState.visible}
        onCancel={() => setModalPDFState(initModalState)}
        isWord={true}
      />
    </Content>
  );
};

export default RejectTDR;
