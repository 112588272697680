import httpClient from '../httpClient';

const postFile = async (data) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/cost_center_templates/`, data, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    }
  });
};

const getAllTemplates = async () => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/cost_center_templates/?page[size]=1000`);
};

const executeDetail = async (id) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/cost_center_templates/${id}/execute/`);
};

const getErrorsFile = async (id) => {
  return await httpClient.post(`${process.env.REACT_APP_API_HOST}/cost_center_templates/${id}/download_errors/`, null, {
    responseType: 'blob'
  });
};

export {
  postFile,
  getAllTemplates,
  executeDetail,
  getErrorsFile
}
