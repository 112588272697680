import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const SpinLoad = ({ loading, children }) => {

  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  return (
    <Spin spinning={loading} indicator={antIcon}>
      {children}
    </Spin>
  );
}

export default SpinLoad;
