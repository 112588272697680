import httpClient from '../httpClient';


const getModificationDocuments = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/modification_documents/${id}`);
};

const getCurrentDocuments = async (id) => {
  return await httpClient.get(`${process.env.REACT_APP_API_HOST}/modification_documents/${id}/current_documents`);
};

const patchRegisterDocs  = async (id,data) => {
  return await httpClient.patch(`${process.env.REACT_APP_API_HOST}/modification_documents/${id}/`, data);
};


export {
  getModificationDocuments,
  getCurrentDocuments,
  patchRegisterDocs
}