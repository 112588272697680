import React, { useState, useEffect } from 'react';
import {
  Table,
  Typography,
  notification,
  Row,
  Col,
  Alert,
  Layout,
  Card,
} from 'antd';

import BreadcrumbContainer from '../../../containers/Breadcrumb';
import CustomButton from '../../../components/CustomButton';
import SpinLoad from '../../../components/SpinLoad';
import MESSAGES from '../../../enums/Messages';

import {
  getAllTemplates,
  postFile,
  executeDetail,
  getErrorsFile,
} from '../../../api/module/cost_centre_template';

import './style.scss';
import CustomUpload from '../../../components/CustomUpload';
import Form from 'antd/lib/form';
import {
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  getAll as getAllCostCentre,
  patch as setStatus,
} from '../../../api/module/sub_cost_centers';

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const breadcrumbItems = [
  {
    text: 'Centro de Costos',
  },
];

export default function CostList() {
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCC, setDataSourceCC] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const saveFile = async (data) => {
    let formData = new FormData();
    try {
      if (
        data.centro_costo != null &&
        [data.centro_costo.fileList].length >= 1
      ) {
        if (data.centro_costo.file.status !== 'removed') {
          data.centro_costo = data.centro_costo.fileList[0].originFileObj;
        }
      } else {
        data.plantilla = '';
      }

      setLoadingFile(true);
      formData.append('centro_costo', data);

      for (let prop in data) {
        if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
      }
      let response = await postFile(formData);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          setLoadingFile(false);
        }, MESSAGES.TIME);

        await getTemplatesDataSource();
      }
    } catch (exception) {
      setLoadingFile(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudo enviar el archivo.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });
    }
  };

  const getTemplatesDataSource = async () => {
    try {
      setLoadingStatus(true);
      let response = await getAllTemplates();

      setDataSource(response.data.results);
      setLoadingStatus(false);
    } catch (exception) {
      setLoadingStatus(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const getCostCentreDataSource = async () => {
    try {
      setLoadingAction(true);
      let response = await getAllCostCentre();

      setDataSourceCC(response.data.results);
      setLoadingAction(false);
    } catch (exception) {
      setLoadingAction(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = 'No se pudieron obtener los registros.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  useEffect(async () => {
    await Promise.all([getTemplatesDataSource(), getCostCentreDataSource()]);
  }, []);

  const executeStatus = async (centre_id, status) => {
    try {
      const data = { estado: status };

      setLoadingAction(true);
      let response = await setStatus(centre_id, data);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });

        await getCostCentreDataSource();

        setLoadingAction(false);
      }
    } catch (exception) {
      setLoadingAction(false);
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description =
          'Ocurrió un error al cambiar el estado del centro de costo.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const downloadFile = async (record_id) => {
    try {
      let response = await getErrorsFile(record_id);

      const file = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  const executeFile = async (file_id) => {
    try {
      let response;

      setLoadingStatus(true);
      response = await executeDetail(file_id);

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });

        await getTemplatesDataSource();
        await getCostCentreDataSource();
      }
    } catch (exception) {
      setLoadingStatus(false);
      let description;
      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description =
          'Ocurrió un error al ejecutar el cargue de los centros de costo.';
      }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
    }
  };

  const columns = [
    {
      title: 'Nombre del archivo',
      dataIndex: 'nombre_archivo',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 220,
      render: (_, record) => (
        <Content>
          {record.estado_validacion === true &&
            record.estado_ejecucion === false && (
              <CustomButton
                onClick={() => executeFile(record.id)}
                block
                htmlType={'button'}
                text={
                  <>
                    <CaretRightOutlined /> Ejecutar
                  </>
                }
              />
            )}
          {record.estado_validacion === false && (
            <CustomButton
              type="default"
              htmlType={'button'}
              danger
              block
              onClick={() => downloadFile(record.id)}
              text={
                <>
                  <DownloadOutlined /> Descargar errores
                </>
              }
            />
          )}
          {record.estado_validacion === true &&
            record.estado_ejecucion === true && (
              <CustomButton
                type="default"
                htmlType={'button'}
                block
                text={
                  <>
                    <CheckOutlined /> <strong> Datos ya cargados </strong>
                  </>
                }
              />
            )}
        </Content>
      ),
    },
  ];

  const columnsCC = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'nombre',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      dataIndex: 'Acciones',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Content>
          {(record.estado === false || record.estado === null) && (
            <CustomButton
              onClick={() => executeStatus(record.id, true)}
              block
              text={
                <>
                  <CheckOutlined /> Activar
                </>
              }
            />
          )}
          {record.estado === true && (
            <CustomButton
              type="default"
              onClick={() => executeStatus(record.id, false)}
              danger
              block
              text={
                <>
                  <CloseOutlined /> Desactivar
                </>
              }
            />
          )}
        </Content>
      ),
    },
  ];

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de Centro de Costos
      </Title>
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={saveFile}
        autoComplete="off"
      >
        <Card
          size={'small'}
          bordered={false}
          title={
            <Title level={3}>1. Cargue de plantillas Centros de costos</Title>
          }
        >
          <Row gutter={12}>
            <Col span={24}>
              <Paragraph>
                A continuación podrá seleccionar el archivo para cargar los
                centros de costos.
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Alert
                style={{ marginBottom: 15 }}
                message="Importante"
                action={
                  <CustomButton
                    type={'default'}
                    className={'back-button'}
                    htmlType="button"
                    text={
                      <a
                        href={`${process.env.REACT_APP_API_DOMAIN}/static/download/Plantilla_Centro_Costo.xlsx`}
                        target="_blank"
                      >
                        Descargar plantilla
                      </a>
                    }
                  />
                }
                description={
                  <Paragraph style={{ marginBottom: 0 }}>
                    Para cargar Centros de costos, descargue la plantilla y
                    diligencie la información solicitada.
                    <br />A continuación seleccione el archivo y haga clic en el
                    botón "Cargar y verificar archivo".
                  </Paragraph>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <CustomUpload
                file_type={3}
                upload_type={2}
                name="centro_costo"
                label="Cargue archivo de centros de costos"
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <CustomButton
                loading={loadingFile}
                text="Cargar y verificar archivo"
              />
            </Col>
          </Row>
        </Card>
      </Form>
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>2. Verificación del archivo cargado</Title>}
      >
        <Paragraph>
          A continuación podrá verificar la carga de las plantillas de centros
          de costos.
        </Paragraph>
        <Paragraph>
          <strong>a).</strong> Si el archivo fue validado correctamente, por
          favor de clic en el botón <strong>"Ejecutar" </strong>
          para terminar el proceso de cargue de centro de costos.
        </Paragraph>
        <Paragraph>
          <strong>b).</strong> Si el archivo presentó algun error, por favor de
          clic en el botón <strong>"Descargar errores"</strong>, ajústelo y
          vuelva a cargarlo.
        </Paragraph>
        <SpinLoad loading={loadingStatus}>
          <Table
            rowKey="id"
            scroll={{ x: 400 }}
            dataSource={dataSource}
            columns={columns}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de Archivos cargados
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
      <Card
        size={'small'}
        bordered={false}
        title={
          <Title level={3}>3. Administración de los centros de costos</Title>
        }
      >
        <Paragraph>
          A continuación podrá activar o desactivar Centros de costo.{' '}
          <strong>
            Recuerde que si desactiva un centro de costos no podrá ser utilizado
            en ningún proceso de SIGA.
          </strong>
        </Paragraph>
        <SpinLoad loading={loadingAction}>
          <Table
            rowKey="id"
            scroll={{ x: 400 }}
            dataSource={dataSourceCC}
            columns={columnsCC}
            title={() => (
              <Paragraph className={'margin-5'} strong>
                Listado de Centros de costos
              </Paragraph>
            )}
          />
        </SpinLoad>
      </Card>
    </Content>
  );
}
