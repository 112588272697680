import React from 'react';
import { Empty, Table } from 'antd';

import SpinLoad from '../../components/SpinLoad';
import Paragraph from 'antd/lib/typography/Paragraph';

export const PersonSearchTable = ({
  dataSource,
  loading,
  rowSelection,
  personType = 1,
  emptyText,
}) => {
  const columns = [
    {
      title: 'Rol Actual',
      dataIndex: 'rol',
      key: 'rol',
    },
    {
      title: 'Nombres y Apellidos',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Tipo Documento',
      dataIndex: 'tipo_identificacion',
      key: 'tipo_documento',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'num_documento',
    },
    {
      title: 'Fecha de expedición',
      dataIndex: 'fecha_expedicion_documento',
      key: 'fecha_expedicion',
    },
    {
      title: 'Celular',
      dataIndex: 'numero_celular',
      key: 'celular',
    },
    {
      title: 'Dirección',
      dataIndex: 'notificacion_direccion',
      key: 'direccion',
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
  ];

  const legalColumns = [
    {
      title: 'Rol Actual',
      dataIndex: 'rol',
      key: 'rol',
    },
    {
      title: 'Razón social',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Nombre comercial',
      dataIndex: 'sigla_comercial',
      key: 'sigla_comercial',
    },
    {
      title: 'Nro. Documento',
      dataIndex: 'numero_identificacion',
      key: 'numero_identificacion',
    },
    {
      title: 'Tipo organizanización',
      dataIndex: 'tipo_organizacion',
      key: 'tipo_organizacion',
    },
    {
      title: 'Tipo doc Rep Legal',
      dataIndex: 'tipo_identificacion_representante',
      key: 'tipo_identificacion_representante',
    },
    {
      title: 'Doc Rep Legal',
      dataIndex: 'representante_numero_identificacion',
      key: 'representante_numero_identificacion',
    },
    {
      title: 'Nombre Rep Legal',
      dataIndex: 'representante_nombre_completo',
      key: 'representante_nombre_completo',
    },
    {
      title: 'País Notificación',
      dataIndex: 'pais',
      key: 'pais',
    },
    {
      title: 'Ciudad Notificación',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'notificacion_email',
      key: 'email',
    },
  ];

  return (
    <SpinLoad loading={loading}>
      <Table
        rowSelection={{ ...rowSelection }}
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={personType === 1 ? columns : legalColumns}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de contratistas para agregar
          </Paragraph>
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText == null ? 'No hay datos' : emptyText}
            />
          ),
        }}
      />
    </SpinLoad>
  );
};
