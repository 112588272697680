import React, { useState, useEffect, useReducer } from 'react';
import { Layout, notification } from 'antd';

import AdditionalDocumentsNaturalForm from '../../containers/AdditionalDocumentsNaturalForm/index';
import PdfViewer from '../../components/PdfViewer';
import MESSAGES from '../../enums/Messages';

import {
  post,
  filter as getDocumentByPerson,
} from '../../api/module/resume_documents';
import { getDocumentByInitial } from '../../api/module/help_upload';
import { get as getDocUrl } from '../../api/module/document_repository';

const { Content } = Layout;

export default function AdditionalDocumentsNatural({
  person_id,
  readOnly = false,
  setDocsComplete = () => {},
}) {
  const requiredDocs = [
    'documento_01',
    'documento_02',
    'documento_03',
    'documento_04',
    'documento_05',
    'documento_06',
    'documento_07',
    'documento_08',
    'documento_09',
    'documento_10',
    'documento_11',
    'documento_12',
    'documento_13',
    'documento_14',
    'documento_15',
  ];

  const [loading, setLoading] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfHelp, setPDFHelp] = useState('');
  const [loadingPDF, setLoadingPDF] = useState(false);

  const setDocs = (docList) => {
    let missing = requiredDocs;
    docList.forEach((element) => {
      const index = missing.indexOf(element.documento_tipo);
      if (index > -1) {
        missing.splice(index, 1);
      }
    });
    setDocsComplete(missing.length === 0);
    return {
      missing,
      documents: docList,
    };
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'saveDoc':
        let missing = state.missing;
        const index = missing.indexOf(action.payload.documento_tipo);
        if (index > -1) {
          missing.splice(index, 1);
          if (missing.length === 0) setDocsComplete(true);
        }
        return { missing, documents: [...state.documents, action.payload] };
      case 'reset':
        return setDocs(action.payload);
      default:
        throw new Error();
    }
  };

  const [docsState, dispatchDocs] = useReducer(reducer, {
    missing: requiredDocs,
  });

  const saveDocument = async (file, documentName) => {
    const formData = new FormData();
    try {
      setLoading(true);
      let response;
      if (file.fileList.length > 0) {
        if (file.fileList[0].type == 'application/pdf') {
          let data = file.fileList[0].originFileObj;
          data.tercero = parseInt(person_id);

          formData.append(documentName, data);

          for (let prop in data) {
            if (data.hasOwnProperty(prop)) formData.append(prop, data[prop]);
          }
          response = await post(formData);
        }
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
          setTimeout(() => {
            const res = response.data;
            dispatchDocs({ type: 'saveDoc', payload: res });
          }, MESSAGES.TIME);
        }
      }
      setLoading(false);
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_FILE_PDF;

      if (err.response !== undefined) {
        if (err.response.data.errors) {
          for (const item of err.response.data.errors) {
            description += ` ${item.detail} - `;
          }
        }
    }

      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 4,
      });

      setLoading(false);
    }
  };

  const getDocuments = async () => {
    try {
      setLoading(true);
      const response = await getDocumentByPerson(person_id);
      dispatchDocs({ type: 'reset', payload: response.data.results });
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const getHelpPDF = async () => {
    try {
      setLoading(true);
      const response = await getDocumentByInitial('PDF-RP');
      setPDFHelp(response.data.results.shift());
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_GET_MSG,
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (person_id) {
      getDocuments();
      getHelpPDF();
    }
  }, []);

  // const deleteDataSource = async (text) => {
  //   let doc = false;
  //   setLoading(true);
  //   for (let item of documents) {
  //     if (!doc) {
  //       if (item.documento_tipo == text) {
  //         doc = true;
  //         try {
  //           const response = await remove(item.id);
  //           if ([200, 201, 204].indexOf(response.status) > -1) {
  //             notification.success({
  //               message: MESSAGES.SUCCESS_MSG,
  //               description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
  //               duration: 2,
  //             });
  //             setTimeout(() => {
  //               setLoading(false);
  //               setDocuments(documents.filter((doc) => doc.id !== item.id));
  //             }, MESSAGES.TIME);
  //           }
  //         } catch (err) {
  //           console.log(err);
  //           setLoading(true);
  //           notification.error({
  //             message: MESSAGES.ERROR_MSG,
  //             description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
  //             duration: 2,
  //           });
  //         }
  //       }
  //     }
  //   }
  // };

  const showClick = async (filename, id) => {
    try {
      setLoadingPDF(id);
      setPdfUrl(null);
      let response = await getDocUrl(filename);
      setPdfUrl(response.data.url);
      setShowPdf(!showPdf);
      setLoadingPDF(false);
    } catch (err) {
      console.log(err);
      setLoadingPDF(false);
    }
  };

  return (
    <Content>
      <AdditionalDocumentsNaturalForm
        saveDocument={saveDocument}
        // deleteDataSource={deleteDataSource}
        person_id={person_id}
        documents={docsState.documents}
        loading={loading}
        showClick={showClick}
        readOnly={readOnly}
        pdfHelp={pdfHelp}
        loadingPDF={loadingPDF}
      />
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </Content>
  );
}
