import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Popconfirm,
  Typography,
  notification,
  Row,
  Form,
  Space,
  Col,
  Layout,
  Card,
  Alert,
  Collapse,
} from 'antd';
import CustomButton from '../../components/CustomButton';
import SpinLoad from '../../components/SpinLoad';
import BreadcrumbContainer from '../../containers/Breadcrumb';
import MESSAGES from '../../enums/Messages';
import RolesAssignmentSearchForm from '../../containers/RolesAssignmentSearchForm';
import RolesPermissionsForm from '../../containers/RolesPermissionsForm';
//Api Imports
import { post } from '../../api/module/contractor_search';
import {
  getAll as getAllGroups,
  get as getGroup,
} from '../../api/module/groups';
import {
  getListByGroup,
  put as updateStatus,
  remove as removeFromGroup,
  post as addToGroup,
} from '../../api/module/user_roles';
import { SelectUserGroup } from '../../components/SelectUserRole';
import UserRoleViewer from '../../components/UserRoleViewer';
import { PlusOutlined } from '@ant-design/icons';
import ProjectAssignmentModal from '../../components/ProjectAssignmentModal';
import UserManagement from './UserManagement';

const breadcrumbItems = [
  {
    text: 'Administración de Roles y Usuarios internos de SIGA',
  },
];

const { Title, Paragraph } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

const RolesAssignmentList = ({
  loading = false,
  group = null,
  dataSource = [],
  deleteDataSource = () => {},
  updateStatusDataSource = () => {},
}) => {
  const columns = [
    {
      title: 'Nombres',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      title: 'Email',
      dataIndex: 'notificacion_email',
      key: 'notificacion_email',
    },
    {
      title: 'Rol asignado',
      dataIndex: 'grupo_nombre',
    },
    {
      title: 'Estado colaborador',
      dataIndex: 'estado',
      key: 'estado',
      render: (value) => {
        return value === true ? 'Activo' : 'Inactivo';
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: 125,
      render: (_, record) => (
        <Space>
          <CustomButton
            type="default"
            htmlType={'button'}
            text="Ver"
            onClick={() => setUserData(record)}
          />
          {record.estado ? (
            <CustomButton
              htmlType={'button'}
              type={'default'}
              danger
              text={
                <Popconfirm
                  title={
                    <>
                      <Paragraph>
                        ¿Está seguro(a) de inactivar este usuario?
                      </Paragraph>
                      <Paragraph>
                        Recuerde al inactivarlo no se podrá visualizar en los
                        procesos posteriores
                      </Paragraph>
                    </>
                  }
                  okText="Si"
                  cancelText="No"
                  onConfirm={() => deleteDataSource(record.usuario_tercero)}
                >
                  Inactivar
                </Popconfirm>
              }
            />
          ) : (
            <CustomButton
              htmlType={'button'}
              type={'default'}
              className={'ant-btn-info'}
              text={
                <Popconfirm
                  title={
                    <>
                      <Paragraph>
                        ¿Está seguro(a) de activar este usuario?
                      </Paragraph>
                      <Paragraph>
                        Recuerde al activarlo se podrá visualizar en los
                        procesos posteriores
                      </Paragraph>
                    </>
                  }
                  okText="Si"
                  cancelText="No"
                  onConfirm={() => deleteDataSource(record.usuario_tercero)}
                >
                  Activar
                </Popconfirm>
              }
            />
          )}
          {group && group.nombre !== 'Consultor' && (
            <CustomButton
              type="default"
              htmlType={'button'}
              text="Asignar proyecto"
              onClick={() => setUserPJData(record)}
            />
          )}
        </Space>
      ),
    },
  ];

  const [userData, setUserData] = useState(null);
  const [userPjData, setUserPJData] = useState(null);

  const onCancel = () => {
    setUserData(null);
  };
  const onPJCancel = () => {
    setUserPJData(null);
  };

  return (
    <SpinLoad loading={loading}>
      <Table
        rowKey="id"
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        columns={columns}
        title={() => (
          <Paragraph className={'margin-5'} strong>
            Listado de Contratistas asignados al rol
          </Paragraph>
        )}
      />
      <UserRoleViewer
        visible={userData !== null}
        onCancel={onCancel}
        group={group}
        userData={userData}
      />
      {userPjData !== null && (
        <ProjectAssignmentModal
          visible={userPjData !== null}
          onCancel={onPJCancel}
          userData={userPjData}
        />
      )}
    </SpinLoad>
  );
};

const RolesAssignmentForm = ({ group = null }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataSelectSearch, setDataSelectSearch] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const getDataSource = () => {
    if (group) {
      setLoading(true);
      getListByGroup(group.id).then((res) => {
        setDataSource(res.data);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getDataSource();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelectSearch(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setDataSelectSearch(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setDataSelectSearch(selectedRows);
    },
  };
  const onFinishSearch = async (data) => {
    try {
      if (data.documento === '') {
        data.documento = undefined;
      }
      if (data.nombre === '') {
        data.nombre = undefined;
      }
      if (data.email === '') {
        data.email = undefined;
      }
      setLoadingSearch(true);
      data.tipo_persona = 0;
      data.termino = 0;
      const response = await post(data);
      setLoadingSearch(false);
      if (response.data.length > 0) {
        setDataSourceSearch(response.data);
      } else {
        setDataSourceSearch([]);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_SEACRH_MSG,
        duration: 2,
      });
    }
  };
  const deleteDataSource = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const response = await removeFromGroup(id);
        setLoading(false);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
        }
        setDataSource(response.data);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    },
    [dataSource, group],
  );
  const updateStatusDataSource = useCallback(
    async (id, new_status) => {
      try {
        setLoading(true);
        const response = await updateStatus(id, { activo: new_status });
        setLoading(false);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_MSG,
            duration: 2,
          });
        }
        setDataSource(response.data);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_POST_MSG,
          duration: 2,
        });
      }
    },
    [dataSource],
  );

  const addDataSource = useCallback(async () => {
    try {
      const user_list = dataSelectSearch.map((item) => item.tercero);
      setShowAlert(false);

      if (user_list.length < 1) {
        setShowAlert(true);
        return;
      }
      const data = {
        grupo: group.id,
        usuarios: user_list,
      };
      setLoading(true);
      const response = await addToGroup(data);
      setLoading(false);
      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
      }
      setDataSource(response.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: MESSAGES.DESC_ERROR_POST_MSG,
        duration: 2,
      });
    }
  }, [dataSource, dataSelectSearch]);

  return (
    <Card
      size={'small'}
      bordered={false}
      title={<Title level={3}>Asignar Usuarios</Title>}
    >
      <Paragraph>
        A continuación, realice la búsqueda de la persona a la que desea
        agregarle el rol. El usuario solo puede tener un rol asignado.
        <br />
        Recuerde que las personas deben estar ya inscritas en SIGA.
      </Paragraph>
      <RolesAssignmentSearchForm
        loading={loadingSearch}
        dataSource={dataSourceSearch}
        setDataSource={setDataSourceSearch}
        onFinish={onFinishSearch}
        rowSelection={rowSelection}
      />
      {dataSourceSearch.length > 0 && (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Row justify={'center'} gutter={12}>
            <CustomButton
              type={'default'}
              className={'ant-btn-info'}
              loading={loading}
              onClick={addDataSource}
              text={
                <>
                  <PlusOutlined /> Agregar contratista
                </>
              }
            />
          </Row>
          {showAlert && (
            <Alert
              message={'Seleccione un contratista'}
              description={
                'Por favor seleccione al menos un contratista para agregar.'
              }
              type="error"
              showIcon
            />
          )}
        </Space>
      )}
      <RolesAssignmentList
        loading={loading}
        dataSource={dataSource}
        updateStatusDataSource={updateStatusDataSource}
        deleteDataSource={deleteDataSource}
        group={group}
      />
    </Card>
  );
};

const RolesManagement = () => {
  const [formType, setFormType] = useState(null);
  const [groupDataSource, setGroupDataSource] = useState(null);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGroupsData = () => {
    getAllGroups().then((res) => setGroupDataSource(res.data.results));
  };

  useEffect(() => {
    getGroupsData();
  }, []);

  const setGroupData = (id) => {
    if (id && groupDataSource) {
      setLoading(true);
      getGroup(id).then((response) => {
        setGroup(response.data);
        setLoading(false);
      });
    } else {
      setGroup(null);
    }
  };

  const onClear = () => {
    setFormType(null);
    getGroupsData();
  };

  const onUpdate = () => {
    setGroupData(group.id);
  };

  useEffect(() => {
    if (formType && formType !== 'create') setFormType(null);
  }, [group]);

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Módulo de administración de roles y usuarios internos SIGA
      </Title>
      <Collapse>
        <Panel header="Administración de usuarios de SIGA" key="users">
          <UserManagement />
        </Panel>
        <Panel header="Administración de roles" key="roles">
          <Form name="nest-messages" layout="vertical" autoComplete="off">
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="rol"
                  label="Por favor, seleccione el Rol en SIGA"
                >
                  <SelectUserGroup
                    data={groupDataSource}
                    onChange={setGroupData}
                    loading={loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item name="action" label="¿Qué desea realizar?">
                  <Row
                    gutter={12}
                    justify={'space-between'}
                    style={{ marginTop: 10 }}
                  >
                    <CustomButton
                      type={formType === 'edit' ? 'primary' : 'default'}
                      text="Editar Rol"
                      onClick={() => setFormType('edit')}
                      disabled={!group}
                      loading={loading}
                    />
                    <CustomButton
                      type={formType === 'assign' ? 'primary' : 'default'}
                      text="Asignar Usuarios"
                      onClick={() => setFormType('assign')}
                      disabled={!group}
                      loading={loading}
                    />
                    <CustomButton
                      type={formType === 'duplicate' ? 'primary' : 'default'}
                      text="Duplicar Rol"
                      onClick={() => setFormType('duplicate')}
                      disabled={!group}
                      loading={loading}
                    />
                    <CustomButton
                      type={formType === 'create' ? 'primary' : 'default'}
                      text="Crear Nuevo Rol"
                      onClick={() => setFormType('create')}
                    />
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {formType === 'edit' && (
            <RolesPermissionsForm
              loading={loading}
              dataSource={group}
              onUpdate={onUpdate}
              onClose={() => setFormType(null)}
            />
          )}
          {formType === 'duplicate' && (
            <RolesPermissionsForm
              loading={loading}
              dataSource={group}
              creator={true}
              onClear={onClear}
              onClose={() => setFormType(null)}
            />
          )}
          {formType === 'create' && (
            <RolesPermissionsForm
              loading={loading}
              creator={true}
              onClear={onClear}
              onClose={() => setFormType(null)}
            />
          )}
          {formType === 'assign' && (
            <RolesAssignmentForm
              group={group}
              onClose={() => setFormType(null)}
            />
          )}
        </Panel>
      </Collapse>
    </Content>
  );
};

export default RolesManagement;
