import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { notification, Table, Popconfirm, Typography } from 'antd';

import MESSAGES from '../../../enums/Messages';
import { CustomButton } from '../../../components';
import SpinLoad from '../../../components/SpinLoad';

import { remove as delecteContract } from '../../../api/module/announcement_invite';

const { Paragraph } = Typography;

export const ListContractors = memo(
  ({ announcement_id, addedContractor, getAddedContractor, loadingChange, setLoadingChange }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
      setIsLoading(true);
      try {
        await Promise.all([getAddedContractor()]);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }, []);

    const deleteDataSource = useCallback(async (id) => {
      try {
        setIsLoading(true);
        setLoadingChange(true);
        const response = await delecteContract(id);
        if ([200, 201, 204].indexOf(response.status) > -1) {
          notification.success({
            message: MESSAGES.SUCCESS_MSG,
            description: MESSAGES.DESC_SUCCESS_DELETE_MSG,
            duration: 2,
          });
        }
        await getAddedContractor();
        setIsLoading(false);
        setLoadingChange(false);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_DELETE_MSG,
          duration: 2,
        });
      }
    }, []);

    const columns = [
      {
        title: 'Nombres',
        dataIndex: ['tercero', 'primer_nombre_razon_social'],
        key: ['tercero', 'primer_nombre_razon_social'],
      },
      {
        title: 'Apellidos',
        dataIndex: ['tercero', 'primer_apellido'],
        key: ['tercero', 'primer_apellido'],
      },
      {
        title: 'Tipo de documento',
        dataIndex: ['tercero_data', 'tipo_identificacion_nombre'],
      },
      {
        title: 'Número de documento',
        dataIndex: ['tercero', 'numero_identificacion'],
        key: ['tercero', 'numero_identificacion'],
      },
      {
        title: 'Fecha de expedición',
        dataIndex: ['tercero', 'fecha_expedicion_documento'],
        key: ['tercero', 'fecha_expedicion_documento'],
      },
      {
        title: 'Teléfono notificación',
        dataIndex: ['tercero', 'numero_celular'],
        key: ['tercero', 'numero_celular'],
      },
      {
        title: 'Otro teléfono',
        dataIndex: ['tercero', 'otros_numeros_telefonicos'],
        key: ['tercero', 'otros_numeros_telefonicos'],
      },
      {
        title: 'Dirección notificación',
        dataIndex: ['tercero', 'notificacion_direccion'],
        key: ['tercero', 'notificacion_direccion'],
      },
      {
        title: 'País notificación',
        dataIndex: ['tercero_data', 'notificacion_pais_nombre'],
      },
      {
        title: 'Departamento',
        dataIndex: ['tercero_data', 'notificacion_estado_nombre'],
      },
      {
        title: 'Ciudad',
        dataIndex: ['tercero_data', 'notificacion_ciudad_nombre'],
      },
      {
        title: 'Email notificación',
        dataIndex: ['tercero', 'notificacion_email'],
        key: ['tercero', 'notificacion_email'],
      },
      {
        title: 'Acciones',
        dataIndex: 'Acciones',
        fixed: 'right',
        width: 120,
        render: (_, record) =>
          addedContractor.length >= 1 ? (
            <CustomButton
              danger
              type={'default'}
              htmlType="button"
              text={
                <Popconfirm
                  title={MESSAGES.DELETE_CONFIRM_MSG}
                  onConfirm={() => deleteDataSource(record.id)}
                >
                  Eliminar
                </Popconfirm>
              }
            />
          ) : null,
      },
    ];

    return (
      <SpinLoad loading={isLoading || loadingChange}>
        <Table
          columns={columns}
          scroll={{ x: 2200 }}
          rowKey="id"
          dataSource={addedContractor}
          title={() => (
            <Paragraph className={'margin-5'} strong>
              Listado de Contratistas agregados
            </Paragraph>
          )}
        />
      </SpinLoad>
    );
  },
);

ListContractors.propTypes = {
  announcement_id: PropTypes.string,
  getAddedContractor: PropTypes.func,
  addedContractor: PropTypes.array,
};

ListContractors.defaultProps = {
  announcement_id: '',
  getAddedContractor: () => {},
  addedContractor: [],
};
