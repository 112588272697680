import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Alert, Button, PageHeader, Space } from "antd";
import { Link } from "react-router-dom";

import { verify, resend } from "../../api/module/user_verify";

import InternalLinks from "../../enums/InternalLinks";

import "./style.scss";
import Paragraph from "antd/es/typography/Paragraph";
import PublicHeader from "../PublicHeader";

export default class UserVerify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {
        show: false
      },
      resend: false
    }
  }
  async componentDidMount () {
    await this.userVerify();
  }

  async userVerify() {
    const { id, token } = this.props.match.params;
    const data = { "token": token }

    try {
      this.setState({ alert: {
          show: false
        }
      });

      const response = await verify(id, data);

      if (response.status === 200) {
        this.setState({ resend: false });
        this.setState({
          alert: {
            show: true,
            message: "Correo electrónico verificado!",
            description: "Su correo electrónico se ha validado correctamente",
            type: "success"
          }
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    } catch (exception) {
      this.setState({ loading: false });
      this.setState({ resend: true });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = "No se pudo validar su correo electrónico";
      }

      this.setState({
        alert: {
          show: true,
          message: "Error",
          description: description,
          type: "error"
        }
      });
    }
  }

  async resendEmail() {
    const { id } = this.props.match.params;

    try {
      this.setState({ alert: {
          show: false
        }
      });

      const response = await resend(id);

      if (response.status === 200) {
        this.setState({ resend: false });
        this.setState({
          alert: {
            show: true,
            message: "Correo electrónico enviado!",
            description: "Se ha reenviado el correo de verificación",
            type: "success"
          }
        });
      }
    } catch (exception) {
      this.setState({ loading: false });
      let description;

      if (exception.response.data.errors) {
        const errors = exception.response.data.errors;
        description = errors.shift().detail;
      } else {
        description = "No se pudo reenviar el correo electrónico";
      }

      this.setState({
        alert: {
          show: true,
          message: "Error",
          description: description,
          type: "error"
        }
      });
    }
  }

  render() {
    const alert = this.state.alert;
    const resend = this.state.resend;

    return (
      <Row justify={"center"} align={"top"}>
        <Col xs={22} sm={17} md={12} lg={9} xl={7} className={"form-container"}>
          <PublicHeader/>
          <PageHeader className={"public-page-header"} title={"Verificar correo electrónico"} />
          { !alert.show &&
            <Paragraph>Estamos verificando ...</Paragraph>
          }
          <Space direction="vertical" size="small" className="w-100">
            { alert.show &&
              <Alert
                message={alert.message}
                description={alert.description}
                type={alert.type}
                showIcon
              />
            }
            {resend &&
              <Button
                type={"primary"}
                onClick={() => {
                  this.resendEmail()
                }}
                htmlType={"button"}
                block>Reenviar correo</Button>
            }
            <Link to={InternalLinks.HOME}>
              <Button danger block>Volver</Button>
            </Link>
          </Space>
        </Col>
      </Row>
    );
  }
}
