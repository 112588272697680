import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { notification, Skeleton } from 'antd';

import { useQuery } from '../../../utils/helpers/helpers';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import MESSAGES from '../../../enums/Messages';
import PdfViewer from '../../../components/PdfViewer';

import { post, put } from '../../../api/module/resume_training';
import { filter as getEducationTypes } from '../../../api/module/education_types';
import { getAll as getKnowledgeAreas } from '../../../api/module/knowledge_areas';
import { filter as getEducationLevels } from '../../../api/module/education_levels';
import { getAll as getCountries } from '../../../api/module/countries';
import { get } from '../../../api/module/resume_training';
import EducationContractorsForm from '../../../containers/EducationContractorsForm/index';
import {get as getDocumentUrl} from "../../../api/module/document_repository";

const SortName = (data) => {
  return data.sort(function (a, b) {
    var nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
    var nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
};

export default function EducationContractors({ history, match }) {
  const [person_id] = useState(parseInt(match.params.tercero_id));

  let query = useQuery();
  const term_id = query.get('term_id');
  const project_id = query.get('project_id');
  const contract_id = query.get('contract_id');
  const ids = {
    person_id: person_id,
    term_id: term_id,
    project_id: project_id,
    contract_id: contract_id,
  };

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [educationTypes, setEducationTypes] = useState(null);
  const [knowledgeAreas, setKnowledgeAreas] = useState(null);
  const [educationLevels, setEducationLeves] = useState(null);
  const [countries, setCountries] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const educationRef = useRef(null);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const showClick = async (documento_nombre) => {
    setIsLoadingDoc(true);
    const response = await getDocumentUrl(
      documento_nombre,
    );
    setPdfUrl(response.data.url);
    setShowPdf(true);
    setIsLoadingDoc(false);
  };

  const save = async (data) => {
    try {
      let response;
      const formData = new FormData();
      setLoading(true);
      data.tercero = person_id;
      for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
          formData.append(prop, data[prop]);
        }
      }

      if (match.params.id) {
        response = await put(match.params.id, formData);
      } else {
        response = await post(formData);
      }

      if ([200, 201, 204].indexOf(response.status) > -1) {
        notification.success({
          message: MESSAGES.SUCCESS_MSG,
          description: MESSAGES.DESC_SUCCESS_MSG,
          duration: 2,
        });
        setTimeout(() => {
          history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${person_id}`, {
            educationRef: educationRef,
          });
          setLoading(false);
        }, MESSAGES.TIME);
      }
    } catch (err) {
      let description = MESSAGES.DESC_ERROR_POST_MSG;
      console.log(err);

      if (err.response.data.errors) {
        for (const item of err.response.data.errors) {
          description += `${item.detail} - `;
        }
      }
      notification.error({
        message: MESSAGES.ERROR_MSG,
        description: description,
        duration: 2,
      });
      setLoading(false);
    }
  };

  const onFinish = async (data) => {
    try {
      setLoading(true);
      if (data.fecha_terminacion) {
        data.fecha_terminacion = moment(data.fecha_terminacion).format(
          'YYYY-MM-DD',
        );
      }
      if (data.tarjeta != undefined) {
        if (data.tarjeta.fileList.length > 0) {
          if (data.tarjeta.fileList[0].type == 'application/pdf') {
            data.tarjeta = data.tarjeta.fileList[0].originFileObj;
          } else {
            notification.error({
              message: MESSAGES.ERROR_FILE_PDF,
              description: MESSAGES.DESC_ERROR_FILE_PDF,
              duration: 3,
            });
            setLoading(false);
            return;
          }
        }
      }
      if (data.certificacion && data.certificacion.fileList.length > 0) {
        if (data.certificacion.fileList[0].type == 'application/pdf') {
          data.certificacion = data.certificacion.fileList[0].originFileObj;
        } else {
          notification.error({
            message: MESSAGES.ERROR_FILE_PDF,
            description: MESSAGES.DESC_ERROR_FILE_PDF,
            duration: 3,
          });
          setLoading(false);
          return;
        }
      }
      save(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetEducationTypes = async () => {
    try {
      const response = await getEducationTypes('?filter[id.in]=1');
      setEducationTypes(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetKnowledgeAreas = async () => {
    try {
      const response = await getKnowledgeAreas();
      setKnowledgeAreas(SortName(response.data.results));
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetEducationLevels = async () => {
    try {
      const response = await getEducationLevels(
        '?filter[id.in]=1,4,5,6,7,8,9,10,11',
      );
      setEducationLeves(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetCountries = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetEducation = async () => {
    try {
      if (match.params.id) {
        const response = await get(match.params.id);
        if (response.data.fecha_terminacion) {
          response.data.fecha_terminacion = moment(
            response.data.fecha_terminacion,
          );
        }
        setInitialValues(response.data);
      } else {
        setInitialValues({ pais: 45 });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setIsLoading(true);
    await Promise.all([
      handleGetEducation(),
      handleGetEducationTypes(),
      handleGetKnowledgeAreas(),
      handleGetEducationLevels(),
      handleGetCountries(),
    ]);
    setIsLoading(false);
  };

  const semesters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const back = () => {
    history.push(`${INTERNAL_LINKS.NATURAL_PERSON}/${person_id}`, {
      educationRef: educationRef,
    });
  };

  return (
    <>
      <Skeleton loading={isLoading}>
        <EducationContractorsForm
          ids={ids}
          onFinish={onFinish}
          loading={loading}
          educationTypes={educationTypes}
          knowledgeAreas={knowledgeAreas}
          educationLevels={educationLevels}
          countries={countries}
          initialValues={initialValues}
          semesters={semesters}
          back={back}
          showClick={showClick}
          isLoadingDoc={isLoadingDoc}
        />
      </Skeleton>
      <PdfViewer
        pdf={pdfUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
    </>
  );
}
