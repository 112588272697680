import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import CustomButton from '../../components/CustomButton';
import INTERNAL_LINKS from '../../enums/InternalLinks';
import BreadcrumbContainer from '../../containers/Breadcrumb';

import { ajaxTable as filterAnnouncements } from '../../api/module/announcement';
import AjaxTable from '../../components/AjaxTable';

const { Title } = Typography;

const breadcrumbItems = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Histórico de convocatorias',
  },
];

const AnnouncementHistoryList = ({ history }) => {
  const [sortColumn, setSortColumn] = useState('-numero');
  const columns = [
    {
      title: 'Tipo convocatoria',
      dataIndex: 'tipo_convocatoria',
      key: 'tipo_convocatoria',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('tipo_convocatoria');
        if (sortOrder === 'descend') setSortColumn('-tipo_convocatoria');
      },
    },
    {
      title: 'Proyecto',
      dataIndex: 'nombre_proyecto',
      key: 'nombre_proyecto',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre_proyecto');
        if (sortOrder === 'descend') setSortColumn('-nombre_proyecto');
      },
    },
    {
      title: 'Organización',
      dataIndex: 'organizacion_nombre',
      key: 'organizacion_nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('organizacion_nombre');
        if (sortOrder === 'descend') setSortColumn('-organizacion_nombre');
      },
    },
    {
      title: 'No. convocatoria',
      dataIndex: 'numero',
      key: 'numero',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('numero');
        if (sortOrder === 'descend') setSortColumn('-numero');
      },
    },
    {
      title: 'Nombre convocatoria',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('nombre');
        if (sortOrder === 'descend') setSortColumn('-nombre');
      },
    },
    {
      title: 'Fecha inicio',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
      align: 'right',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_inicio');
        if (sortOrder === 'descend') setSortColumn('-fecha_inicio');
      },
    },
    {
      title: 'Fecha fin',
      dataIndex: 'fecha_fin',
      key: 'fecha_fin',
      align: 'right',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('fecha_fin');
        if (sortOrder === 'descend') setSortColumn('-fecha_fin');
      },
    },
    {
      title: 'Cantidad de Cargo / Rol',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('total');
        if (sortOrder === 'descend') setSortColumn('-total');
      },
    },
    {
      title: 'Estado convocatoria',
      dataIndex: 'estado_convocatoria_texto',
      key: 'estado_convocatoria_texto',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') setSortColumn('estado_convocatoria_texto');
        if (sortOrder === 'descend') setSortColumn('-estado_convocatoria_texto');
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'accion',
      fixed: 'right',
      width: 140,
      align: 'center',
      render: (_, record) => (
        <CustomButton
          type={'default'}
          className={'ant-btn-info'}
          htmlType="button"
          text={
            <Link
              to={`${INTERNAL_LINKS.ANNOUNCEMENT_SHOW}/${record.id}?history=true&preview=true`}
            >
              Visualizar
            </Link>
          }
        />
      ),
    },
  ];

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Histórico de convocatorias
      </Title>
      <AjaxTable
        columns={columns}
        pageSize={10}
        sortBy={sortColumn}
        searchInput
        exportCSV
        reloadButton
        tableTitle={'Listado de histórico de convocatorias'}
        endpoint={filterAnnouncements}
        query={'/?filter[estado_convocatoria.sigla]=CV-F'}
      />
      <CustomButton
        type={'default'}
        className={'back-button'}
        htmlType="button"
        onClick={() => back()}
        text="Volver"
      />
    </>
  );
};

export default AnnouncementHistoryList;
