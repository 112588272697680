import React from 'react';
import { Descriptions } from 'antd';

const AnnouncementSelectionValidationRestrictiveForm = ({
  onFinish,
  initialValues,
  name,
}) => {
  return (
    <Descriptions bordered column={2}>
      <Descriptions.Item label="Número de convocatoria">
        {initialValues.numero}
      </Descriptions.Item>
      <Descriptions.Item label="Cargo / Rol">
        {initialValues.cargo}
      </Descriptions.Item>
      <Descriptions.Item label="Nombre del preseleccionado">
        {initialValues.nombre_completo}
      </Descriptions.Item>
      <Descriptions.Item label="Tipo y número de documento del preseleccionado">
        {initialValues.tipo_identificacion_nombre} -{' '}
        {initialValues.numero_identificacion}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AnnouncementSelectionValidationRestrictiveForm;
