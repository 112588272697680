import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  Radio,
  Layout,
  Space,
  Card,
} from 'antd';
import { Link } from 'react-router-dom';
import { validatorNumbers } from '../../utils/helpers/helpers';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import CountryStateCity from '../../components/CountryStateCity';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import INTERNAL_LINKS from '../../enums/InternalLinks';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { Content } = Layout;

const OrganizationForm = ({
  organization_id,
  title,
  onFinish,
  initialValues,
  loading,
  organizations,
  organizationsTypes,
  identiesTypes,
  showCLick,
  organizationForm,
}) => {
  const [selectOrganizationType, setSelectOrganizationType] = useState(null);
  const [activeOrganizations, setAciveOrganizations] = useState(null);
  const [requiredCCB, setRequiredCCB] = useState(false);
  const [dvField, setDvField] = useState(false);
  const [minDV, setMinDV] = useState(6);
  const validateMessages = {
    required: 'El campo ${label} es requerido!',
  };
  const breadcrumbItems = [
    {
      text: 'Organizaciones ejecutoras',
      to: `${INTERNAL_LINKS.ORGANIZATIONS}`,
    },
    {
      text: 'Formulario de Organización',
    },
  ];

  /* useEffect(() => {
    titlRef && titlRef.current.scrollIntoView();
  }, [titlRef]); */

  useEffect(() => {
    organizations != null &&
      setAciveOrganizations(
        organizations.filter((res) => res.esta_activa === true),
      );
    if (initialValues !== null) {
      onChangeIdentificationType(initialValues.tipo_identidad);
      setSelectOrganizationType(initialValues.tipo_organizacion);

      if (initialValues.tipo_organizacion > 2 && initialValues.ccb_organizacion_nombre === null)
        setRequiredCCB(true);
    }
  }, [organizations]);

  const onChangeIdentificationType = (value) => {
    setDvField(false);
    if (value === 7) setMinDV(6);
    if (value === 15) setMinDV(9);

    if (value === 7 || value === 15) {
      setDvField(true);
    }
  };

  const validatorIdentification = (length) => {
    if (dvField) return validatorNumbers(length);

    return true;
  };

  const calcularDigitoVerificacion = (e) => {
    var myNit = e.target.value;
    var vpri, x, y, z;
    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ''); // Espacios
    myNit = myNit.replace(/,/g, ''); // Comas
    myNit = myNit.replace(/\./g, ''); // Puntos
    myNit = myNit.replace(/-/g, ''); // Guiones
    // Se valida el nit
    /*if (isNaN(myNit)) {
      message.warning("El nit/cédula '" + myNit + "' no es válido(a).");
      return '';
    }*/
    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      x += y * vpri[z - i];
    }

    y = x % 11;
    organizationForm.setFieldsValue({
      digito_verificacion: y > 1 ? 11 - y : y,
    });
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Paragraph>
        A continuación, diligencie el formulario "{title}".
        <br />
        Recuerde que los campos obligatorios están marcados con asterisco (*).
      </Paragraph>
      <Form
        form={organizationForm}
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        scrollToFirstError
        autoComplete="off"
        onValuesChange={(data) => {
          if (data.hasOwnProperty('pais')) {
            organizationForm.setFieldsValue({
              estado: null,
              ciudad: null,
            });
          }
          if (data.hasOwnProperty('estado')) {
            organizationForm.setFieldsValue({
              ciudad: null,
            });
          }
        }}
      >
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Datos de la organización ejecutora</Title>}
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label="Tipo de organización ejecutora"
                name="tipo_organizacion"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Tipo organización..."
                  onSelect={(value) => {
                    setSelectOrganizationType(value)
                    setRequiredCCB(false);

                    if (value > 2)
                      setRequiredCCB(true);
                  }}
                >
                  {organizationsTypes != null && (
                    <>
                      {organizationsTypes.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              {(selectOrganizationType === 1 ||
                selectOrganizationType === 2 ||
                initialValues.tipo_organizacion === 1 ||
                initialValues.tipo_organizacion === 2) && (
                <Form.Item label="Seleccione integrantes" name="integrante">
                  <Select
                    mode="tags"
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Integrantes..."
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activeOrganizations != null && (
                      <>
                        {activeOrganizations.map((item) => (
                          <Option
                            key={`active-organization-key-${item.id}`}
                            value={item.id}
                          >
                            {item.razon_social}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="razon_social"
                label="Razón social"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.length <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Solo puede ingresar 100 caracteres!',
                      );
                    },
                  }),
                ]}
              >
                <Input allowClear placeholder="Razón social..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sigla"
                label="Sigla o Nombre comercial"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || value.length <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Solo puede ingresar 100 caracteres!',
                      );
                    },
                  }),
                ]}
              >
                <Input allowClear placeholder="Sigla o Nombre comercial..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="tipo_identidad"
                label="Tipo de identificación"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Tipo de identificación"
                  onChange={onChangeIdentificationType}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {identiesTypes != null && (
                    <>
                      {identiesTypes.map((item) => (
                        <Option
                          key={`organization-identity-type-key-${item.id}`}
                          value={item.id}
                        >
                          {item.nombre}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="num_identificacion"
                label="Número de NIT"
                rules={[
                  {
                    required: true,
                  },
                  validatorIdentification(minDV),
                ]}
              >
                <Input
                  allowClear
                  onBlur={(e) => {
                    if (dvField) calcularDigitoVerificacion(e);
                  }}
                  placeholder="Número de NIT..."
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="digito_verificacion"
                label="DV"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || !isNaN(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Verífique el número de documento registrado!',
                      );
                    },
                  }),
                ]}
              >
                <Input
                  style={{ width: '60px' }}
                  allowClear
                  placeholder="DV"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <CountryStateCity
            required={true}
            countryKey="pais"
            stateKey="estado"
            cityKey="ciudad"
            countryLabel="País"
            stateLabel="Departamento"
            cityLabel="Ciudad"
            country={initialValues.pais}
            state={initialValues.estado}
            city={initialValues.ciudad}
          />
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="direccion"
                label="Dirección"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input allowClear placeholder="Dirección..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="barrio"
                label="Barrio"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input allowClear placeholder="Barrio..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="telefono"
                label="Teléfono"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                rules={[
                  {
                    required: true,
                  },
                  validatorNumbers(7),
                ]}
              >
                <Input allowClear placeholder="Teléfono..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="correo"
                label="Correo electrónico"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input allowClear placeholder="Correo electrónico..." />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          size={'small'}
          bordered={false}
          title={<Title level={3}>Datos del representante legal</Title>}
        >
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="representante_legal"
                label="Nombres y apellidos"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.length <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Solo puede ingresar 100 caracteres!',
                      );
                    },
                  }),
                ]}
              >
                <Input allowClear placeholder="Representante legal" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="representante_tipo_identificacion"
                label="Tipo de documento de identificación"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Tipo de identificación"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {identiesTypes != null && (
                    <>
                      {identiesTypes.map((item) => (
                        <Option
                          key={`identity-type-key-${item.id}`}
                          value={item.id}
                        >
                          {item.nombre}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="representante_numero_identificacion"
                label="Número de documento"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('representante_tipo_identificacion') !== 7
                      ) {
                        return Promise.resolve();
                      } else {
                        if (new RegExp('^[0-9]*$').test(value)) {
                          if (!value || 6 === 0 || value.length >= 6) {
                            return Promise.resolve();
                          } else
                            return Promise.reject(
                              new Error(`Ingrese mínimo ${6} números`),
                            );
                        }
                        return Promise.reject(
                          new Error(`Ingrese unicamente números`),
                        );
                      }
                    },
                  }),
                ]}
              >
                <Input allowClear placeholder="Número de documento" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="lugar_expedicion_documento_representante"
                label="Lugar de expedición del documento"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="Lugar de expedición del documento"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name="politicas"
                label="Digíte el link donde reposa la política de tratamiento de datos de la organización ejecutora"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="esta_activa"
                label="Organización activa"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group style={{ marginLeft: '2rem' }}>
                  <Radio value={1}>Si</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <CustomUpload
                label="Cargar logo de la organización ejecutora (Solo formatos JPG/PNG y que no superen los 2 MB)"
                name="logo_organizacion"
                file_type={2}
                upload_type={5}
                buttonLabel="Seleccionar archivo a cargar..."
                required={!initialValues.logo_organizacion_nombre}
              />
            </Col>
            {initialValues.logo_organizacion_nombre && (
              <Col span={12}>
                <Form.Item>
                  <img
                    alt={'Organización Logo'}
                    src={initialValues.logo_organizacion_nombre}
                    style={{ width: 'calc(300px - 30px)' }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={12} align={'middle'}>
            <Col span={20}>
              <CustomUpload
                name="documento_representante"
                label="Cargar documento de identidad del representante legal"
                file_type={1}
                upload_type={5}
                buttonLabel="Seleccionar archivo a cargar..."
                required={!initialValues.documento_representante_nombre}
              />
            </Col>
            <Col span={4}>
              <CustomButton
                block
                disabled={initialValues.documento_representante_nombre == null}
                style={{ marginTop: 5 }}
                text="Ver documento"
                htmlType="button"
                className={initialValues.documento_representante_nombre != null ? "ant-btn-info" : null}
                type="default"
                onClick={() => {
                  showCLick(initialValues.documento_representante_nombre);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align={'middle'}>
            <Col span={20}>
              <CustomUpload
                name="rut_organizacion"
                label="Cargar RUT actualizado de la organización ejecutora"
                file_type={1}
                upload_type={5}
                buttonLabel="Seleccionar archivo a cargar..."
                required={!initialValues.rut_organizacion_nombre}
              />
            </Col>
            <Col span={4}>
              <CustomButton
                block
                disabled={initialValues.rut_organizacion_nombre == null}
                style={{ marginTop: 5 }}
                text="Ver documento"
                htmlType="button"
                className={initialValues.rut_organizacion_nombre != null ? "ant-btn-info" : null}
                type="default"
                onClick={() => {
                  showCLick(initialValues.rut_organizacion_nombre);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align={'middle'}>
            <Col span={20}>
              <CustomUpload
                name="certificacion_organizacion"
                label="Cargar certificación bancaria de la organización ejecutora"
                file_type={1}
                upload_type={5}
                buttonLabel="Seleccionar archivo a cargar..."
              />
            </Col>
            <Col span={4}>
              <CustomButton
                block
                disabled={
                  initialValues.certificacion_organizacion_nombre == null
                }
                style={{ marginTop: 5 }}
                text="Ver documento"
                htmlType="button"
                className={initialValues.certificacion_organizacion_nombre != null ? "ant-btn-info" : null}
                type="default"
                onClick={() => {
                  showCLick(initialValues.certificacion_organizacion_nombre);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align={'middle'}>
            <Col span={20}>
              <CustomUpload
                name="ccb_organizacion"
                label="Cargar CCB de la organización ejecutora actualizado."
                file_type={1}
                upload_type={5}
                buttonLabel="Seleccionar archivo a cargar..."
                required={requiredCCB}
              />
            </Col>
            <Col span={4}>
              <CustomButton
                block
                disabled={initialValues.ccb_organizacion_nombre == null}
                style={{ marginTop: 5 }}
                text="Ver documento"
                htmlType="button"
                className={initialValues.ccb_organizacion_nombre != null ? "ant-btn-info" : null}
                type="default"
                onClick={() => {
                  showCLick(initialValues.ccb_organizacion_nombre);
                }}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Space>
                <CustomButton text="Guardar" loading={loading} />
                <CustomButton
                  type={'default'}
                  className={'back-button'}
                  htmlType="button"
                  text={<Link to={INTERNAL_LINKS.ORGANIZATIONS}> Volver </Link>}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    </Content>
  );
};

OrganizationForm.propTypes = {
  title: PropTypes.string,
  onFinish: PropTypes.func,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  organizations: PropTypes.array,
  identiesTypes: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.array,
  cities: PropTypes.array,
  organizationsTypes: PropTypes.array,
};

OrganizationForm.defaultProps = {
  title: '',
  onFinish: () => {},
  initialValues: {},
  loading: false,
  organizations: [],
  organizationsTypes: [],
  identiesTypes: [],
  countries: [],
  states: [],
  cities: [],
};
export default OrganizationForm;
