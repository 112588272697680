import httpClient from '../httpClient';

const post = async (data) => {
  return await httpClient.post(`/term_resume_authorize/`, data);
};

const get = async (id) => {
  return await httpClient.get(`/term_resume_authorize/${id}`);
};

const getAllBySelfAndTerm = async (term) => {
  return await httpClient.get(
    `/term_resume_authorize/?page[size]=1000&filter[tercero]=${window.localStorage['tercero']}&filter[termino]=${term}`,
  );
};

const put = async (id, data) => {
  return await httpClient.put(`/term_resume_authorize/${id}/`, data);
};

const patch = async (id, data) => {
  return await httpClient.patch(`/term_resume_authorize/${id}/`, data);
};

const remove = async (id) => {
  return await httpClient.delete(`/term_resume_authorize/${id}`);
};

const multiple = async (data) => {
  return await httpClient.post(`/term_resume_authorize/multiple/`, data);
};

export { post, get, getAllBySelfAndTerm, put, patch, remove, multiple };
