import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Radio,
  Tooltip,
  Form,
  Skeleton,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import BreadcrumbContainer from '../../containers/Breadcrumb';
import { AnnouncementOpen } from '../AnnouncementOpen';
import { AnnouncementClose } from '../AnnouncementClose';
import INTERNAL_LINKS from '../../enums/InternalLinks';

import { get } from '../../api/module/announcement';

import './style.scss';

const { Title } = Typography;

const breadcrumbItems = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Crear Convocatoria',
  },
];

const breadcrumbItemsEdit = [
  {
    text: 'Convocatoria',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT}`,
  },
  {
    text: 'Convocatorias en curso',
    to: `${INTERNAL_LINKS.ANNOUNCEMENT_PROGRESS}`,
  },
  {
    text: 'Editar Convocatoria',
  },
];

export default function AnnouncementNew({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [announcementType, setAnnouncementType] = useState(null);
  const [regulIcfes, setRegulIcfes] = useState(0);
  const [title, setTitle] = useState('CREAR CONVOCATORIA');

  useEffect(async () => {
    if (match.params.announcement_id) {
      await handleGet();
    }
  }, []);

  const handleGet = async (id) => {
    try {
      setTitle('EDITAR CONVOCATORIA');
      setIsLoading(true);
      let response;
      if (id) {
        response = await get(id);
      } else {
        response = await get(match.params.announcement_id);
      }
      if (response.data.fecha_inicio) {
        response.data.fecha_inicio = moment(response.data.fecha_inicio);
      }
      if (response.data.fecha_fin) {
        response.data.fecha_fin = moment(response.data.fecha_fin);
      }
      setInitialValues(response.data);
      setIsLoading(false);
      response.data.clase == true ? setRegulIcfes(0) : setRegulIcfes(1);
      response.data.tipo == true
        ? setAnnouncementType(1)
        : setAnnouncementType(0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {match.params.announcement_id ? (
        <BreadcrumbContainer items={breadcrumbItemsEdit} />
      ) : (
        <BreadcrumbContainer items={breadcrumbItems} />
      )}
      <Title className={'text-uppercase'} level={2}>
        {title}
      </Title>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Clase de convocatoria"
              rules={[
                {
                  required: true,
                  message:
                    'Es requerido que seleccione una clase de convocatoria',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => setRegulIcfes(e.target.value)}
                value={regulIcfes}
              >
                <Radio value={0}>Convocatoria regular</Radio>
                <Radio style={{ marginRight: '13px' }} value={1}>
                  Convocatoria tipo ICFES
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tipo de convocatoria"
              rules={[
                {
                  required: true,
                  message:
                    'Es requerido que seleccione una clase de convocatoria',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setAnnouncementType(e.target.value);
                }}
                value={announcementType}
              >
                <Radio value={1}>Abierta</Radio>
                <Radio style={{ marginRight: '13px' }} value={0}>
                  Cerrada{' '}
                  <Tooltip title="Es para proyecto existente">
                    <QuestionCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Skeleton loading={isLoading}>
        {announcementType == 1 && (
          <>
            {initialValues != null ? (
              <AnnouncementOpen
                initialValues={initialValues}
                isLoading={isLoading}
                announcementType={announcementType}
                announcement_id={match.params.announcement_id}
                regulIcfes={regulIcfes}
                handleGet={handleGet}
              />
            ) : (
              <AnnouncementOpen
                isLoading={isLoading}
                announcementType={announcementType}
                announcement_id={match.params.announcement_id}
                regulIcfes={regulIcfes}
                handleGet={handleGet}
              />
            )}
          </>
        )}
        {announcementType == 0 && (
          <>
            {initialValues != null ? (
              <AnnouncementClose
                initialValues={initialValues}
                isLoading={isLoading}
                announcementType={announcementType}
                announcement_id={match.params.announcement_id}
                regulIcfes={regulIcfes}
                handleGet={handleGet}
              />
            ) : (
              <AnnouncementClose
                isLoading={isLoading}
                announcementType={announcementType}
                announcement_id={match.params.announcement_id}
                regulIcfes={regulIcfes}
                handleGet={handleGet}
              />
            )}
          </>
        )}
      </Skeleton>
    </>
  );
}
