import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Form,
  Checkbox,
  Row,
  Col,
  Radio,
  Typography,
  Space,
  Card,
} from 'antd';

import { get as getDocUrl } from '../../api/module/document_repository';
import CustomButton from '../../components/CustomButton';
import CustomUpload from '../../components/CustomUpload';
import { DownloadOutlined } from '@ant-design/icons';
import { getErrorsFile } from '../../api/module/announcement';

import './style.scss';

const validateMessages = {
  required: 'El campo ${label} es requerido!',
};

const { Title, Paragraph } = Typography;

const AnnouncementTemplateForm = ({
  announcementType,
  saveTemplate,
  initialValues,
  setExternalUsers,
  externalUsers,
  setRegisteredUsers,
  registeredUsers,
  setUnknownUsers,
  unknownUsers,
  showCLick,
  isLoading,
  hasErrors,
  loading,
  formAnnouncement,
  fileWasUploaded,
}) => {
  const [form] = Form.useForm();
  const requiredRegistered = useCallback(() => {
    return !(
      (registeredUsers === true && externalUsers === false) ||
      externalUsers === true ||
      unknownUsers === true
    );
  }, [externalUsers, registeredUsers, unknownUsers]);

  const requiredExternal = useCallback(() => {
    return !(
      (registeredUsers === false && externalUsers === true) ||
      registeredUsers === true ||
      unknownUsers === true
    );
  }, [externalUsers, registeredUsers, unknownUsers]);

  const downloadFile = async (record_id) => {
    try {
      let response = await getErrorsFile(record_id);

      const file = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    form.validateFields(['dirigido_1']);
    form.validateFields(['dirigido_2']);
    form.validateFields(['dirigido_3']);
  }, [externalUsers, registeredUsers, unknownUsers]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(data) => saveTemplate(data)}
      initialValues={initialValues}
      autoComplete="off"
      validateMessages={validateMessages}
    >
      <Card
        size={'small'}
        bordered={false}
        title={<Title level={3}>Agregar usuarios</Title>}
      >
        {announcementType === 1 && (
          <>
            <Row gutter={12}>
              <Col span={12}>
                <Paragraph>¿A quién va dirigida?</Paragraph>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="dirigido_1"
                  rules={[
                    {
                      required: requiredRegistered(),
                      message: 'Campo requerido',
                    },
                  ]}
                >
                  <Checkbox
                    checked={registeredUsers}
                    onChange={() => {
                      setRegisteredUsers(!registeredUsers);
                      setUnknownUsers(false);
                    }}
                  >
                    Usuarios Registrados en SIGA
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dirigido_2"
                  rules={[
                    {
                      required: requiredExternal(),
                      message: 'Campo requerido',
                    },
                  ]}
                >
                  <Checkbox
                    checked={externalUsers}
                    onChange={() => {
                      setExternalUsers(!externalUsers);
                      setUnknownUsers(false);
                    }}
                  >
                    Usuarios Externos / Referenciados
                  </Checkbox>
                </Form.Item>
              </Col>
              {false && (
                <Col span={8}>
                  <Form.Item
                    name="dirigido_3"
                    rules={[
                      {
                        required:
                          registeredUsers == true ||
                          externalUsers == true ||
                          unknownUsers == true
                            ? false
                            : true,
                        message: 'requerido',
                      },
                    ]}
                  >
                    <Radio
                      checked={unknownUsers}
                      onChange={() => {
                        setUnknownUsers(!unknownUsers);
                        setRegisteredUsers(false);
                        setExternalUsers(false);
                      }}
                    >
                      Usuarios desconocidos
                    </Radio>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        )}
        {announcementType === 0 && (
          <Row gutter={12}>
            <Col span={24}>
              <Alert
                showIcon
                type="info"
                message={'Recuerde, usted puede'}
                description={
                  <Paragraph>
                    <strong>
                      1. Cargar usuarios externos / referenciados y/o usuarios ya registrados
                    </strong>{' '}
                    para hacer invitación y/o
                    <br />
                    <strong>
                      2. Seleccionar usuarios específicos registrados en SIGA
                    </strong>
                  </Paragraph>
                }
              />
            </Col>
            <Col span={24} style={{ marginTop: 30 }}>
              <Form.Item
                name="dirigida"
                onChange={() => setExternalUsers(!externalUsers)}
              >
                <Paragraph strong>
                  1. Cargar usuarios externos / referenciados y/o usuarios ya registrados.
                </Paragraph>
                <Checkbox checked={externalUsers}>
                  Usuarios Externos / Referenciados
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Card>
      {externalUsers === true && (
        <Space direction={'vertical'} style={{width: '100%'}}>
          <Row gutter={12}>
            {initialValues.documento_plantilla_url == null && (
              <Col span={24}>
                <Paragraph>
                  Para registrar{' '}
                  <strong>usuarios externos / referenciados y/o usuarios ya registrados</strong>, descargue
                  la plantilla que encuentra{' '}
                  <a
                    href={`${process.env.REACT_APP_API_DOMAIN}/static/download/Plantilla_Usuarios_Externos.xlsx`}
                    target="_blank"
                  >
                    aquí
                  </a>{' '}
                  y diligencie la información solicitada en el archivo.
                </Paragraph>
                <Paragraph>
                  Una vez diligenciada la plantilla, a continuación proceda a
                  cargarla, luego de clic en el botón "Cargar y verificar
                  archivo" y guardela.
                </Paragraph>
                <Alert
                  style={{marginBottom: 10}}
                  showIcon
                  type="warning"
                  message={<strong>En caso de presentar error en la carga del archivo</strong>}
                  description={
                    <>
                      <ul>
                        <li>Haga una copia del archivo</li>
                        <li>Realice las modificaciones pertinentes</li>
                        <li>Seleccione y cargue el archivo Excel ajustado</li>
                      </ul>
                      <Paragraph type={"danger"}>No haga una copia del archivo desde la ventana que se abre para seleccionar el
                        archivo a cargar</Paragraph>
                    </>
                }
                />
              </Col>
            )}
            {initialValues.documento_plantilla_url == null && (
              <Col span={24}>
                <CustomUpload
                  file_type={3}
                  upload_type={2}
                  name="plantilla"
                  label="Seleccione el archivo Excel diligenciado a partir de la plantilla sugerida"
                  buttonLabel="Seleccionar archivo a cargar"
                />
              </Col>
            )}
            {initialValues.documento_plantilla_url !== null && (
              <Col span={24} style={{ marginBottom: 10 }}>
                <Alert
                  showIcon
                  type="info"
                  message={'Importante'}
                  action={
                    <CustomButton
                      type={'default'}
                      className={'back-button'}
                      text={
                        <a
                          onClick={async () => {
                            const response = await getDocUrl(
                              initialValues.documento_plantilla_url,
                            );
                            window.open(response.data.url);
                          }}
                        >
                          Descargar plantilla cargada
                        </a>
                      }
                      htmlType="button"
                    />
                  }
                  description='La convocatoria actual ya cuenta con un archivo cargado, para descargar el archivo haga clic en el botón "Descargar plantilla cargada".'
                />
              </Col>
            )}
            <Col span={24}>
              <Space>
                {initialValues.documento_plantilla_url == null && (
                  <Space>
                    <CustomButton
                      text="Cargar y verificar archivo"
                      loading={isLoading}
                    />
                    {fileWasUploaded === true && (
                      <CustomButton
                        text="Guardar la plantilla cargada"
                        htmlType="button"
                        loading={loading}
                        onClick={() => formAnnouncement.submit()}
                      />
                    )}
                  </Space>
                )}
                {hasErrors && (
                  <CustomButton
                    danger
                    type="default"
                    htmlType="button"
                    onClick={() => downloadFile(initialValues.id)}
                    text={
                      <>
                        <DownloadOutlined /> Descargar errores
                      </>
                    }
                  />
                )}
              </Space>
            </Col>
          </Row>
        </Space>
      )}
    </Form>
  );
};

AnnouncementTemplateForm.propTypes = {
  saveTemplate: PropTypes.func,
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  showCLick: PropTypes.func,
  externalUsers: PropTypes.bool,
  fileWasUploaded: PropTypes.bool,
};

AnnouncementTemplateForm.defaultProps = {
  saveTemplate: () => {},
  initialValues: {},
  loading: false,
  hasErrors: false,
  showCLick: () => {},
  externalUsers: false,
  fileWasUploaded: false,
};

export default AnnouncementTemplateForm;
