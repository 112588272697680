import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  notification,
  Typography,
  Input,
  Form,
  Popconfirm,
  Alert,
  Layout,
  Space,
  Skeleton,
} from 'antd';
import BreadcrumbContainer from '../../../containers/Breadcrumb';
import { CustomButton } from '../../../components';
import { get as getSVDP } from '../../../api/module/term_document_contract_validation';
import { get as getStatus } from '../../../api/module/status_process';
import MESSAGES from '../../../enums/Messages';
import INTERNAL_LINKS from '../../../enums/InternalLinks';
import { Link } from 'react-router-dom';
import { getDocumentByInitial } from '../../../api/module/help_upload';
import { post } from '../../../api/module/term_document_contract_document_email';
import { get as getDocumentUrl } from '../../../api/module/document_repository';

const breadcrumbItems = [
  {
    text: 'Seguimiento Validación Documentación Parcial',
    to: `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}`,
  },
  {
    text: 'Generar Correo',
  },
];

const tabNameMap = {
  basicos: 'Básicos obligatorios',
  formacion: 'Educación/Formación',
  experiencia: 'Experiencia',
  icfes: 'Experiencia ICFES',
};

const { Title } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

export default function DocumentValidationPartialEmail({ match, history }) {
  const [validationData, setValidationData] = useState(undefined);
  const [statusData, setStatusData] = useState(undefined);
  const [pdfHelp, setPDFHelp] = useState(undefined);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      try {
        const validationId = match.params.validation_id;
        const res = await getSVDP(validationId);
        const statusD = await getStatus(res.data.estado_validacion_general);
        let mensaje = undefined;
        const response = await getDocumentByInitial('PDF-VPC');
        setPDFHelp(response.data.results.shift());
        setValidationData({ ...res.data, mensaje: mensaje });
        setStatusData(statusD.data);
      } catch (err) {
        console.log(err);
        notification.error({
          message: MESSAGES.ERROR_MSG,
          description: MESSAGES.DESC_ERROR_GET_MSG,
          duration: 2,
        });
      }
    }
    fetchData();
  }, [match.params.validation_id]);

  const onFinish = (values) => {
    async function postEmail(values) {
      try {
        setLoading(true);
        await post({
          validacion_documentos_contractuales: validationData.id,
          mensaje: values.mensaje,
        });
        history.push(
          `${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}/${validationData.termino.id}`,
        );
      } catch (err) {
        setLoading(false);
      }
    }
    postEmail(values);
  };

  const showHelp = async (documento_nombre) => {
    setLoadingDocument(true);
    const response = await getDocumentUrl(documento_nombre);
    window.open(response.data.url);

    setLoadingDocument(false);
  };

  return (
    <Content>
      <BreadcrumbContainer items={breadcrumbItems} />
      <Title className={'text-uppercase'} level={2}>
        Seguimiento Validación Documentación Parcial
      </Title>
      {validationData && statusData ? (
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Descriptions layout="vertical" column={2} bordered>
            <Descriptions.Item label="Proyecto a revisar">
              {validationData.proyecto_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Cargo/rol a revisar">
              {validationData.cargo_nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre completo/Razón social">
              {validationData.tercero.nombre_completo}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo y número de documento">
              {`${validationData.tercero.tipo_identificacion_nombre} - ${validationData.tercero.numero_identificacion}`}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Estado</b>}>
              {statusData.nombre}
            </Descriptions.Item>
          </Descriptions>
          <Form
            form={form}
            initialValues={validationData}
            onFinish={onFinish}
            key={`VDP_EMAIL_${match.params.validation_id}`}
          >
            <Space direction={'vertical'} style={{ width: '100%' }}>
              <Descriptions layout="vertical" column={1} bordered>
                <Descriptions.Item
                  label={`Observaciones del módulo ${tabNameMap.basicos}`}
                >
                  <Form.Item name={`observaciones_basicos`} noStyle>
                    <TextArea placeholder="Observaciones" rows={4} disabled />
                  </Form.Item>
                </Descriptions.Item>
                {validationData.tercero.tipo_persona_nombre === 'Natural' && (
                  <>
                    <Descriptions.Item
                      label={`Observaciones del módulo ${tabNameMap.formacion}`}
                    >
                      <Form.Item name={`observaciones_formacion`} noStyle>
                        <TextArea
                          placeholder="Observaciones"
                          rows={4}
                          disabled
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={`Observaciones del módulo ${tabNameMap.experiencia}`}
                    >
                      <Form.Item name={`observaciones_experiencia`} noStyle>
                        <TextArea
                          placeholder="Observaciones"
                          rows={4}
                          disabled
                        />
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={`Observaciones del módulo ${tabNameMap.icfes}`}
                    >
                      <Form.Item name={`observaciones_icfes`} noStyle>
                        <TextArea
                          placeholder="Observaciones"
                          rows={4}
                          disabled
                        />
                      </Form.Item>
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
              {pdfHelp && (
                <Alert
                  message="Nota"
                  action={
                    <CustomButton
                      type={'default'}
                      className={'back-button'}
                      htmlType="button"
                      loading={loadingDocument}
                      onClick={() => {
                        showHelp(pdfHelp.documento);
                      }}
                      text={'Descargar ayuda'}
                    />
                  }
                  description={
                    'Descargue la ayuda con la información orientadora sobre los posibles textos de correos.'
                  }
                  type="info"
                  showIcon
                />
              )}
              <Descriptions layout="vertical" column={1} bordered>
                <Descriptions.Item label={<b>Mensaje del correo</b>}>
                  <Form.Item
                    name="mensaje"
                    rules={[
                      { required: true, message: 'Se requiere un mensaje.' },
                    ]}
                  >
                    <TextArea placeholder="Mensaje" rows={6} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Space>
            <Space style={{ marginTop: 10 }}>
              <Popconfirm
                onConfirm={() => {
                  form.submit();
                }}
                title={MESSAGES.MSG_SEND_EMAIL}
              >
                <CustomButton type="primary" text="Guardar" loading={loading} />
              </Popconfirm>
              <CustomButton
                type="default"
                htmlType="button"
                className={'back-button'}
                text={
                  <Link
                    to={`${INTERNAL_LINKS.DOCUMENT_VALIDATION_PARTIAL}/${validationData.termino.id}`}
                  >
                    Volver
                  </Link>
                }
              />
            </Space>
          </Form>
        </Space>
      ) : (
        <Skeleton loading active />
      )}
    </Content>
  );
}
